import { useState, useEffect } from 'react';
import './InvoiceSummaryStatsComponent.scss';
import FormControlLabelComponent from '../../form-control-label/FormControlLabelComponent';
import moment from 'moment';
import InvoiceSummaryChartComponent from '../../charts/invoice-summary-chart/InvoiceSummaryChartComponent';
import { Skeleton } from '@mui/material';

interface InvoiceSummaryChartComponentProps {
  invoiceStatsData: any;
  loading: boolean;
}

const InvoiceSummaryStatsComponent = (
  props: InvoiceSummaryChartComponentProps,
) => {
  const { invoiceStatsData, loading } = props;
  const [showChart, setShowChart] = useState<number>(0);

  useEffect(() => {
    if (invoiceStatsData) {
      const value = invoiceStatsData?.reduce((total: number, stats: any) => {
        return (total += stats?.value);
      }, 0);
      setShowChart(value);
    }
  }, [invoiceStatsData]);
  return (
    <>
      {loading ? (
        <div className='invoice-summary-stats-component'>
          <Skeleton variant='rectangular' width={'100%'} height={'300px'} />
        </div>
      ) : (
        <div className='invoice-summary-stats-component'>
          <FormControlLabelComponent
            label={`Invoices Summary for ${moment()
              .subtract(1, 'months')
              .format('MMMM')} (Automated + Manual)`}
            isBold
            level={5}
          />
          {showChart !== 0 && (
            <div className='invoice-summary-chart-wrapper'>
              <InvoiceSummaryChartComponent data={invoiceStatsData} />
            </div>
          )}
          <div className='invoice-summary-stats-cards'>
            {invoiceStatsData?.map((item: any) => {
              let title =
                item?.name === 'Sent to Client' ? 'sent-to-client' : item?.name;
              return (
                <div
                  key={item?.name}
                  className={`invoice-summary-stats-card ${title}`}
                >
                  <span className='invoice-summary-stats-card-line'></span>
                  <div className='invoice-summary-stats-card-content'>
                    <div className='invoice-summary-stats-card-title'>
                      {item?.name}
                    </div>
                    <div className='invoice-summary-stats-card-count'>
                      {item.value}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceSummaryStatsComponent;
