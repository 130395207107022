import React, { useCallback } from 'react';
import './UtilityInvoiceComponent.scss';
import { ImageConfig } from '../../../../constants';
import HorizontalLineComponent from '../../horizontal-line/horizontal-line/HorizontalLineComponent';
import moment from 'moment';
import { Field, FieldArray, FieldProps } from 'formik';
import FormikSelectDropdownComponent from '../../form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikInputComponent from '../../form-controls/formik-input/FormikInputComponent';
import IconButtonComponent from '../../icon-button/IconButtonComponent';
import { CommonService } from '../../../services';

interface UtilityInvoiceComponentProps {
  invoiceDetails: any;
  addressDetails: any;
  category: string;
  utilityInvoiceType: string;
  values: any;
  setFieldValue: any;
}

const utilityInvoices = [
  {
    title: 'Water',
    code: 'water',
  },
  {
    title: 'DG',
    code: 'dg',
  },
  {
    title: 'Electricity',
    code: 'electricity',
  },
];

const utilityInvoiceList = [
  {
    title: 'EB Power',
    code: 'ebPower',
  },
  {
    title: 'DG Power',
    code: 'dgPower',
  },
  {
    title: 'Demand Charges',
    code: 'demandCharges',
  },
  {
    title: 'Electricity Duty',
    code: 'electricityDuty',
  },
  {
    title: 'Other Charges',
    code: 'otherCharges',
  },
];

const UtilityInvoiceComponent = (props: UtilityInvoiceComponentProps) => {
  const { invoiceDetails, addressDetails, values, setFieldValue } = props;

  const entityAbbreviation = useCallback(() => {
    const name = invoiceDetails?.billingEntity?.name;
    const formattedName = name
      ?.split(' ')
      .map((word: any) => word[0])
      .join('');
    return formattedName.toUpperCase();
  }, [invoiceDetails]);

  // Function for calcuating sub total
  const calculateItemTotalAmount = useCallback(
    (consumption: number, ratePerUnit: number, index: number) => {
      const subTotal = consumption * ratePerUnit;
      setFieldValue(
        `invoiceItemList.${index}.amount`,
        parseInt(subTotal.toFixed(2)),
      );
    },
    [setFieldValue],
  );

  const calculateSubTotalAmount = useCallback(() => {
    const result = values?.invoiceItemList?.reduce(
      (total: any, item: any) =>
        total + (item.consumption || 0) * (item.ratePerUnit || 0),
      0,
    );
    return result;
  }, [values]);

  return (
    <div className='utility-invoice-component'>
      <div className='utility-invoice-details-top-section'>
        <div className='new-invoice-details-image'>
          <img src={ImageConfig.NewPhoenixLogo} alt='phoenix-logo' />
        </div>
        <div className='flex-wrapper'>
          <div>
            <div className='new-invoice-top-text'>
              {invoiceDetails?.billingEntity?.gstin ? (
                <>{invoiceDetails?.billingEntity?.gstin}</>
              ) : (
                <>--</>
              )}
            </div>
            <div className='new-invoice-top-text'>
              {invoiceDetails?.billingEntity?.name ? (
                <>{invoiceDetails?.billingEntity?.name}</>
              ) : (
                <>--</>
              )}
            </div>
          </div>
          <div className='new-invoice-date'>
            <div>
              {entityAbbreviation()}/DN/{moment().format('MMM')}
            </div>
            <div>{moment().format('DD-MMM-YYYY')}</div>
          </div>
        </div>
      </div>
      <HorizontalLineComponent className='mrg-top-20 mrg-bottom-20' />
      <div className='utility-invoice-details-middle-section'>
        {invoiceDetails?.billingEntity?.addressDetails && (
          <div className='new-invoice-details-billing-wrapper'>
            <span className='new-invoice-details-heading'>Billed By</span>
            <div className='new-invoice-details-billing-info'>
              <p>
                {invoiceDetails?.billingEntity?.addressDetails?.addressLine1}
              </p>
              <p> {invoiceDetails?.billingEntity?.addressDetails?.city}</p>
              <p>
                {invoiceDetails?.billingEntity?.addressDetails?.state} ,{' '}
                {invoiceDetails?.billingEntity?.addressDetails?.pincode}
              </p>
              <p>{invoiceDetails?.billingEntity?.addressDetails?.country}</p>
            </div>
          </div>
        )}
        {addressDetails?.billingAddress && (
          <div className='new-invoice-details-billing-wrapper'>
            <span className='new-invoice-details-heading'>Billed To</span>
            <div className='new-invoice-details-billing-info'>
              <p>{addressDetails?.billingAddress?.addressLine1}</p>
              <p>{addressDetails?.billingAddress?.city}</p>
              <p>
                {addressDetails?.billingAddress?.state} ,{' '}
                {addressDetails?.billingAddress?.pincode}
              </p>
              <p>{addressDetails?.billingAddress?.country}</p>
            </div>
          </div>
        )}
        {invoiceDetails?.propertyDetails?.addressDetails && (
          <div className='new-invoice-details-billing-wrapper'>
            <span className='new-invoice-details-heading'>
              Place of Service
            </span>
            <div className='new-invoice-details-billing-info'>
              <p>
                {invoiceDetails?.propertyDetails?.addressDetails?.addressLine1}
              </p>
              <p>{invoiceDetails?.propertyDetails?.addressDetails?.city}</p>
              <p>
                {invoiceDetails?.propertyDetails?.addressDetails?.state} ,{' '}
                {invoiceDetails?.propertyDetails?.addressDetails?.pincode}
              </p>
              <p>{invoiceDetails?.propertyDetails?.addressDetails?.country}</p>
            </div>
          </div>
        )}
      </div>
      <HorizontalLineComponent className='mrg-bottom-20' />
      <div>
        <Field name={'invoiceName'}>
          {(field: FieldProps) => (
            <FormikSelectDropdownComponent
              label='Select Invoice'
              placeholder='Select'
              formikField={field}
              options={utilityInvoices}
              displayWith={(option) => option.title}
              valueExtractor={(option) => option.code}
              fullWidth
              required
              onUpdate={(value: any) => {
                if (value) {
                  const text = `Reimbursement of the ${value} consumption charges for the period ${moment(
                    invoiceDetails?.billingStartDate,
                  ).format('DD-MM-YYYY')} to ${moment(
                    invoiceDetails?.billingEndDate,
                  ).format('DD-MM-YYYY')}`;
                  setFieldValue('descriptionOfService', value ? text : '');
                }
              }}
            />
          )}
        </Field>
        <Field name='descriptionOfService'>
          {(field: FieldProps) => (
            <FormikInputComponent
              formikField={field}
              fullWidth
              label='Description of the Service'
            />
          )}
        </Field>
      </div>
      <div className='utility-invoice-details-table-wrapper'>
        <div className='utility-invoice-details-table-header'>
          <span>Item</span>
          <span>Description</span>
          <span>Units</span>
          <span>Consumption</span>
          <span>Rate Per Unit</span>
          <span>Amount</span>
        </div>
        <FieldArray
          name='invoiceItemList'
          render={(arrayHelpers: any) => (
            <>
              {values?.invoiceItemList?.map((item: any, index: any) => {
                return (
                  <div
                    key={index}
                    className='utility-invoice-details-table-body '
                  >
                    <div>
                      <Field name={`invoiceItemList.${index}.itemName`}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            className='invoice-fields'
                            placeholder='Select'
                            fullWidth
                            options={utilityInvoiceList}
                            displayWith={(option: any) => option.title}
                            valueExtractor={(option: any) => option.title}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name={`invoiceItemList.${index}.unitDesc`}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            className='invoice-fields'
                            formikField={field}
                            options={CommonService._staticData.InvoiceUnit}
                            displayWith={(option) => option.des}
                            valueExtractor={(option) => option.code}
                            fullWidth
                            required
                            searchable
                            searchMode='clientSide'
                            onUpdate={(value: any) => {
                              setFieldValue(
                                `invoiceItemList.${index}.unitDesc`,
                                value ? value : '',
                              );
                              if (value) {
                                const selectedUnitDes =
                                  CommonService._staticData.InvoiceUnit.find(
                                    (item: any) => item.code === value,
                                  );
                                if (selectedUnitDes) {
                                  setFieldValue(
                                    `invoiceItemList.${index}.unit`,
                                    selectedUnitDes.unit,
                                  );
                                }
                              } else {
                                setFieldValue(
                                  `invoiceItemList.${index}.unit`,
                                  '',
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name={`invoiceItemList.${index}.unit`}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            placeholder={''}
                            type={'text'}
                            formikField={field}
                            fullWidth={true}
                            readOnly
                            className='invoice-input-fields'
                          />
                        )}
                      </Field>
                    </div>

                    <div>
                      <Field name={`invoiceItemList.${index}.consumption`}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            placeholder={''}
                            type={'text'}
                            formikField={field}
                            fullWidth={true}
                            className='invoice-input-fields'
                            validationPattern={/^[0-9\b]+$/}
                            onChange={(value) => {
                              setFieldValue(
                                `invoiceItemList.${index}.consumption`,
                                value ? parseInt(value, 10) : '',
                              );
                              calculateItemTotalAmount(
                                value,
                                values?.invoiceItemList[index]?.ratePerUnit ||
                                  0,
                                index,
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name={`invoiceItemList.${index}.ratePerUnit`}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            placeholder={''}
                            type={'text'}
                            formikField={field}
                            fullWidth={true}
                            className='invoice-input-fields'
                            onChange={(value) => {
                              calculateItemTotalAmount(
                                values?.invoiceItemList[index]?.consumption,
                                value,
                                index,
                              );
                              setFieldValue(
                                `invoiceItemList.${index}.ratePerUnit`,
                                value ? parseInt(value, 10) : '',
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='flex-wrapper'>
                      <Field name={`invoiceItemList.${index}.amount`}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            placeholder={''}
                            type={'text'}
                            formikField={field}
                            fullWidth={true}
                            readOnly
                            className='invoice-input-fields'
                          />
                        )}
                      </Field>
                      <div className='flex-wrapper mrg-left-20'>
                        <IconButtonComponent
                          color='primary'
                          onClick={() => {
                            arrayHelpers.push({
                              itemName: '',
                              consumption: '',
                              ratePerUnit: '',
                              unit: '',
                              unitDesc: '',
                              amount: '',
                            });
                          }}
                        >
                          <ImageConfig.AddOutlinedIcon />
                        </IconButtonComponent>
                        {values?.invoiceItemList?.length > 1 && (
                          <IconButtonComponent
                            className='mrg-left-10'
                            color='error'
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          >
                            <ImageConfig.DeleteOutlineIcon />
                          </IconButtonComponent>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        />
        <div className='new-invoice-details-bottom-section'>
          <div className='new-invoice-details-info-header'>
            <span>Item</span>
            <span>Total Amount</span>
          </div>
          <div className='new-invoice-details-info-row-one'>
            <span>Total Amount to be paid</span>
            <>
              {values?.invoiceItemList[0]?.consumption &&
              values?.invoiceItemList[0]?.ratePerUnit ? (
                <span className='new-invoice-details-subtotal'>
                  {calculateSubTotalAmount()} Rs
                </span>
              ) : (
                <span className='new-invoice-details-subtotal'>--</span>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilityInvoiceComponent;
