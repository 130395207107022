import { IActionModel } from '../../shared/models/action.model';
import {
  GET_ALL_PROPERTY_LIST,
  GET_PROPERTY_FM_LIST,
  GET_PROPERTY_LIST_LITE,
  SET_ALL_PROPERTY_LIST,
  SET_PROPERTY_FM_LIST,
  SET_PROPERTY_LIST_LITE,
} from '../actions/property.action';
import { IUser } from '../../shared/models/user.model';

export interface IPropertyReducerState {
  propertyList: any[];
  isPropertyListLoading: boolean;
  isPropertyListLoaded: boolean;
  propertyFMsList: IUser[];
  isPropertyFMListLoading: boolean;
  isPropertyFMListLoaded: boolean;
  allPropertyList: any[];
}

const initialData: IPropertyReducerState = {
  propertyList: [],
  isPropertyListLoading: false,
  isPropertyListLoaded: false,
  propertyFMsList: [],
  isPropertyFMListLoading: false,
  isPropertyFMListLoaded: false,
  allPropertyList: [],
};

const PropertyReducer = (
  state = initialData,
  action: IActionModel,
): IPropertyReducerState => {
  switch (action.type) {
    case GET_PROPERTY_LIST_LITE:
      state = {
        ...state,
        isPropertyListLoading: true,
        isPropertyListLoaded: false,
      };
      return state;
    case SET_PROPERTY_LIST_LITE:
      state = {
        ...state,
        isPropertyListLoading: false,
        isPropertyListLoaded: true,
        propertyList: action.payload.propertyList,
      };
      return state;
    case GET_PROPERTY_FM_LIST:
      state = {
        ...state,
        isPropertyFMListLoading: true,
        isPropertyFMListLoaded: false,
      };
      return state;
    case SET_PROPERTY_FM_LIST:
      state = {
        ...state,
        isPropertyFMListLoading: false,
        isPropertyFMListLoaded: true,
        propertyFMsList: action.payload.propertyFMsList,
      };
      return state;
    case GET_ALL_PROPERTY_LIST:
      state = {
        ...state,
      };
      return state;
    case SET_ALL_PROPERTY_LIST:
      state = {
        ...state,
        allPropertyList: action.payload.allPropertyList,
      };
      return state;
    default:
      return state;
  }
};

export default PropertyReducer;
