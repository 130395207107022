import React, { useCallback, useEffect, useState } from 'react';
import './InvoicePreviewComponent.scss';
import TagComponent from '../../../../shared/components/tag/TagComponent';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import CardComponent from '../../../../shared/components/card/CardComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import DataLabelValueComponent from '../../../../shared/components/data-label-value/DataLabelValueComponent';
import TableComponent from '../../../../shared/components/table/TableComponent';
import { ITableColumn } from '../../../../shared/models/table.model';
import { CommonService } from '../../../../shared/services';
import { useNavigate } from 'react-router-dom';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import moment from 'moment';
import InvoiceInfoBoxComponent from '../../../../shared/components/invoice-info-box/InvoiceInfoBoxComponent';

interface InvoicePreviewComponentProps {
  invoiceDetails?: any;
  invoiceId?: string;
  prev: () => void;
  steps: any[];
  activeStepId: string;
  invoiceType: string;
}

const InvoiceDetailsGoodsAndServicesOne: ITableColumn[] = [
  {
    title: 'S.no',
    key: 'sno',
    dataIndex: 'sno',
    width: '50',
    render: (item: any) => {
      return <>{item?.SlNo || 1}</>;
    },
  },
  {
    title: 'Item Description',
    key: 'description',
    dataIndex: 'description',
    width: '120',
    render: (item: any) => {
      return <>{item?.PrdDesc || '--'}</>;
    },
  },
  {
    title: 'Is Service',
    key: 'isService',
    dataIndex: 'isService',
    width: '75',
    render: (item: any) => {
      return <>{item?.IsServc === 'Y' ? 'Yes' : 'No'}</>;
    },
  },
  {
    title: 'HSN Code',
    key: 'hsnCode',
    dataIndex: 'hsnCode',
    width: '80',
    render: (item: any) => {
      return <>{item?.HsnCd || 0}</>;
    },
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity',
    width: '70',
    render: (item: any) => {
      return <>{item?.Qty || 0}</>;
    },
  },
  {
    title: 'Unit',
    key: 'unit',
    dataIndex: 'unit',
    width: '50',
    render: (item: any) => {
      return <>{item?.Unit || ' '}</>;
    },
  },
  {
    title: 'Unit Price (Rs)',
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    width: '100',
    render: (item: any) => {
      return <>{item?.UnitPrice || 0}</>;
    },
  },
  {
    title: 'Taxable Amount (Rs)',
    key: 'taxableAmount',
    dataIndex: 'taxableAmount',
    width: '135',
    render: (item: any) => {
      return <>{item?.AssAmt || 0}</>;
    },
  },

  {
    title: 'Item Total',
    key: 'total',
    dataIndex: 'total',
    width: '80',
    render: (item: any) => {
      return <>{item?.TotAmt || 0}</>;
    },
  },
];

const InvoiceDetailsGoodsAndServicesTwo: ITableColumn[] = [
  {
    title: 'CGST Amt',
    key: 'cgstAmt',
    dataIndex: 'cgstAmt',
    width: '100',
    render: (item: any) => {
      return <>{item?.CgstAmt || 0}</>;
    },
  },
  {
    title: 'SGST Amt',
    key: 'sgstAmt',
    dataIndex: 'sgstAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.SgstAmt || 0}</>;
    },
  },
  {
    title: 'IGST Amt',
    key: 'igstAmt',
    dataIndex: 'igstAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.IgstAmt || 0}</>;
    },
  },
  {
    title: 'CESS Amt',
    key: 'consumption',
    dataIndex: 'cessAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.CesAmt || 0}</>;
    },
  },
  {
    title: 'State CESS',
    key: 'stateCess',
    dataIndex: 'stateCess',
    width: '100',
    render: (item: any) => {
      return <>{item?.StateCesAmt || 0}</>;
    },
  },
  {
    title: 'Discount',
    key: 'discount',
    dataIndex: 'discount',
    width: '70',
    render: (item: any) => {
      return <>{item?.Discount || 0}</>;
    },
  },

  {
    title: 'Other Charges',
    key: 'otherCharges',
    dataIndex: 'otherCharges',
    width: '100',
    render: (item: any) => {
      return <>{item?.OthChrg || 0}</>;
    },
  },
  {
    title: 'Total Inv.Amt',
    key: 'totalInvAmt',
    dataIndex: 'totalInvAmt',
    width: '120',
    render: (item: any) => {
      return <>{item?.TotItemVal || 0}</>;
    },
  },
];

const UtilityInvoiceItemListTable: ITableColumn[] = [
  {
    title: 'S.no',
    key: 'sno',
    dataIndex: 'sno',
    width: '40',
    render: (item: any, rowIndex: number) => {
      return <>{rowIndex + 1}</>;
    },
  },
  {
    title: 'Item',
    key: 'item',
    dataIndex: 'item',
    width: '150',
    render: (item: any) => {
      return <>{item?.itemName || '--'}</>;
    },
  },
  {
    title: 'UOM',
    key: 'uom',
    dataIndex: 'uom',
    width: '80',
    render: (item: any) => {
      return <>{item?.unitDesc || '--'}</>;
    },
  },
  {
    title: 'Consumption',
    key: 'consumption',
    dataIndex: 'consumption',
    width: '80',
    render: (item: any) => {
      return <>{item?.consumption || 0}</>;
    },
  },
  {
    title: 'Rate Per Unit',
    key: 'ratePerUnit',
    dataIndex: 'ratePerUnit',
    width: '100',
    render: (item: any) => {
      return <>{item?.ratePerUnit || 0}</>;
    },
  },
  {
    title: 'Total',
    key: 'amount',
    dataIndex: 'amount',
    width: '100',
    render: (item: any) => {
      return <>{item?.amount || 0}</>;
    },
  },
];

const InvoicePreviewComponent = (props: InvoicePreviewComponentProps) => {
  const { prev, invoiceDetails, invoiceId, invoiceType } = props;
  const navigate = useNavigate();
  const ItemList = invoiceDetails?.gstData?.ItemList;
  const [addressDetails, setAddressDetails] = useState<any>(undefined);

  const getDetails = useCallback(() => {
    const type = invoiceDetails?.billingTo;
    const payload = {};
    let apiCall: any;
    if (type === 'tenant') {
      apiCall = CommonService._tenants.GetTenantsDetailsAPICall(
        invoiceDetails?.tenantId,
        payload,
      );
    }
    if (type === 'amenity') {
      apiCall = CommonService._amenity.GetAmenityDetailsAPICall(
        invoiceDetails?.amenityId,
        payload,
      );
    }
    if (invoiceDetails) {
      apiCall
        .then((response: IAPIResponseType<any>) => {
          setAddressDetails(response?.data);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = useCallback(() => {
    if (invoiceId) {
      if (invoiceType === 'invoice') {
        CommonService._invoice
          .sendInvoiceAPICall(invoiceId)
          .then((response: IAPIResponseType<any>) => {
            if (
              response[Misc.API_RESPONSE_MESSAGE_KEY] ===
              'Invoice is Generated and Sent to client Successfully'
            ) {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'success',
              );
              navigate(
                CommonService._routeConfig.InvoiceDetailsRoute(invoiceId),
              );
            } else {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'error',
              );
            }
          })
          .catch((error: any) => {
            CommonService._alert.showToast(error, 'error');
          });
      } else {
        CommonService._alert.showToast(
          'Debit Note Created Successfully',
          'success',
        );
        navigate(CommonService._routeConfig.DebitNoteDetailsRoute(invoiceId));
      }
    }
  }, [invoiceId, navigate, invoiceType]);

  const invoiceConfirmation = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: 'Generate Invoice',
      image: ImageConfig.TickMarkIcon,
      confirmationDescription: 'Are you sure you want to generate GST Invoice?',
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: 'primary',
        text: 'Confirm',
        variant: 'contained',
      },
    }).then(() => {
      onSubmit();
    });
  }, [onSubmit]);

  const entityAddress = useCallback(() => {
    if (invoiceDetails?.billingEntity?.addressDetails) {
      const addressDetails = invoiceDetails?.billingEntity?.addressDetails;
      const address = [
        addressDetails.addressLine1,
        addressDetails.city,
        addressDetails.state,
        addressDetails.country,
        addressDetails.pincode,
      ]
        .filter((part) => part)
        .join(', ');

      return address;
    }
    return '';
  }, [invoiceDetails]);

  const entityAbbreviation = useCallback(() => {
    const name = invoiceDetails?.billingEntity?.name;
    const formattedName = name
      ?.split(' ')
      .map((word: any) => word[0])
      .join('');
    return formattedName.toUpperCase();
  }, [invoiceDetails]);

  const totalInvoiceItemAmount = useCallback((list: any[]) => {
    const totalAmount =
      list?.reduce((total: any, item: any) => {
        return total + parseInt(item?.amount || 0);
      }, 0) || 0;
    const result = CommonService.convertNumberToWords(totalAmount);
    return result;
  }, []);

  return (
    <div className='add-screen invoice-preview-component'>
      <div className='add-component-header'>
        <ButtonComponent
          variant='text'
          color='secondary'
          onClick={prev}
          prefixIcon={<ImageConfig.ArrowLeftIcon />}
        >
          Back
        </ButtonComponent>
        <div className='invoice-preview'>
          {invoiceType === 'invoice'
            ? 'Invoice Preview'
            : ' Debit Note Preview'}
        </div>
        <ButtonComponent
          type='submit'
          variant='contained'
          onClick={() => {
            if (invoiceType === 'invoice') {
              invoiceConfirmation();
            } else {
              onSubmit();
            }
          }}
        >
          Next
        </ButtonComponent>
      </div>
      <div className='add-component-content invoice-preview-content-holder'>
        {invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'nonGst' && (
            <CardComponent className='debit-note-header'>
              Debit Note
            </CardComponent>
          )}
        <div className='invoice-preview-top-section'>
          <div>
            <div className='invoice-preview-logo-wrapper'>
              <img
                src={ImageConfig.NewPhoenixLogo}
                alt='phoenix-logo'
                className='invoice-logo'
              />
            </div>
            <div className='invoice-preview-text'>
              {invoiceDetails?.billingEntity?.gstin ? (
                <>{invoiceDetails?.billingEntity?.gstin}</>
              ) : (
                <>--</>
              )}
            </div>
            <div className='invoice-preview-text'>
              {invoiceDetails?.billingEntity?.name ? (
                <>{invoiceDetails?.billingEntity?.name}</>
              ) : (
                <>--</>
              )}
            </div>
            <TagComponent
              label='pending'
              customBg='#FBFAE5'
              customTextColor='#636363'
              className={'invoice-tag'}
              showIndicator
            />
          </div>
          {invoiceDetails?.category === 'regularInvoice' && (
            <div className='invoice-preview-qrcode'></div>
          )}
        </div>
        {invoiceDetails?.category === 'regularInvoice' ||
        (invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'gst') ? (
          <CardComponent className='invoice-preview-card'>
            <FormControlLabelComponent
              label='e-invoice details'
              isBold={true}
              level={5}
            />
            <div className={'ts-row mrg-top-20'}>
              <div className={'ts-col-4'}>
                <DataLabelValueComponent label={'IRN Number:'}>
                  --
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-2'}>
                <DataLabelValueComponent label={'Ack No:'}>
                  --
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-2'}>
                <DataLabelValueComponent label={'Ack Date:'}>
                  --
                </DataLabelValueComponent>
              </div>
            </div>
          </CardComponent>
        ) : null}
        {invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'nonGst' && (
            <CardComponent className='invoice-preview-card'>
              <div className='d-flex-between'>
                <div>
                  <FormControlLabelComponent
                    label='Billing From'
                    level={5}
                    isBold
                  />
                  <div className='utility-entity-wrapper'>
                    <div className='utility-entity-name'>
                      {invoiceDetails?.billingEntity?.name ? (
                        <>{invoiceDetails?.billingEntity?.name}</>
                      ) : (
                        <>--</>
                      )}
                    </div>
                    <div>
                      {invoiceDetails?.billingEntity?.addressDetails ? (
                        <>{entityAddress()}</>
                      ) : (
                        <>--</>
                      )}
                    </div>
                  </div>
                </div>
                <div className='mrg-left-60'>
                  <FormControlLabelComponent label='Debit Note' level={5} />
                  <div className='text-lite'>
                    <div>
                      {entityAbbreviation()}/DN/{moment().format('MMM')}
                    </div>
                    <div className='mrg-top-5'>
                      {moment().format('DD-MM-YYYY')}
                    </div>
                  </div>
                </div>
              </div>
            </CardComponent>
          )}
        {invoiceDetails?.category === 'regularInvoice' ||
        (invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'gst') ? (
          <>
            <CardComponent>
              <div>
                <FormControlLabelComponent
                  label='e-invoice details'
                  isBold={true}
                  level={5}
                />
                <FormControlLabelComponent
                  label={`IGST applicable despite supplier and recipient located in same state: ${
                    ItemList?.IgstAmt === 0 ? 'No' : 'Yes'
                  }`}
                  isBold={true}
                  level={5}
                  className='mrg-top-20'
                />
                <div className={'ts-row mrg-top-20'}>
                  <div className={'ts-col-lg-3'}>
                    <DataLabelValueComponent label={'Supply Type Code'}>
                      {invoiceDetails?.supplyType ? (
                        <>{invoiceDetails?.supplyType}</>
                      ) : (
                        <> -- </>
                      )}
                    </DataLabelValueComponent>
                  </div>
                  <div className={'ts-col-lg-2'}>
                    <DataLabelValueComponent label={'Document No:'}>
                      {invoiceDetails?.gstData?.DocDtls?.No ? (
                        <div className='invoice-text-overflow'>
                          {invoiceDetails?.gstData?.DocDtls?.No}
                        </div>
                      ) : (
                        <>--</>
                      )}
                    </DataLabelValueComponent>
                  </div>
                  <div className={'ts-col-lg-2'}>
                    <DataLabelValueComponent label={'Place of Supply'}>
                      {invoiceDetails?.propertyDetails?.addressDetails
                        ?.state ? (
                        <>
                          {
                            invoiceDetails?.propertyDetails?.addressDetails
                              ?.state
                          }
                        </>
                      ) : (
                        <>--</>
                      )}
                    </DataLabelValueComponent>
                  </div>
                  <div className={'ts-col-lg-2'}>
                    <DataLabelValueComponent label={'Document Type'}>
                      {invoiceDetails?.gstData?.DocDtls?.Typ ? (
                        <>{invoiceDetails?.gstData?.DocDtls?.Typ}</>
                      ) : (
                        <>--</>
                      )}
                    </DataLabelValueComponent>
                  </div>
                  <div className={'ts-col-lg-3'}>
                    <DataLabelValueComponent label={'Document Date'}>
                      {invoiceDetails?.gstData?.DocDtls?.Dt ? (
                        <>{invoiceDetails?.gstData?.DocDtls?.Dt}</>
                      ) : (
                        <>--</>
                      )}
                    </DataLabelValueComponent>
                  </div>
                </div>
              </div>
            </CardComponent>
            <CardComponent>
              <div>
                <FormControlLabelComponent
                  label='Party Details'
                  isBold={true}
                  level={5}
                />
                <div className={'ts-row mrg-top-20'}>
                  <div className={'ts-col-lg-6'}>
                    <DataLabelValueComponent label={'Supplier:'}>
                      <div className='invoice-screen-party-detail'>
                        <p>
                          GSTN : {invoiceDetails?.gstData?.SellerDtls.Gstin}
                        </p>
                        <p>{invoiceDetails?.gstData?.SellerDtls?.LglNm}</p>
                        <p className='invoice-text-overflow'>
                          {invoiceDetails?.gstData?.SellerDtls?.Addr1},{' '}
                          {invoiceDetails?.gstData?.SellerDtls?.Loc}
                        </p>
                        <p>
                          {invoiceDetails?.gstData?.SellerDtls?.TrdNm},{' '}
                          {invoiceDetails?.gstData?.SellerDtls?.Pin}
                        </p>
                        <p>{invoiceDetails?.gstData?.SellerDtls?.Em}</p>
                      </div>
                    </DataLabelValueComponent>
                  </div>
                  <div className={'ts-col-lg-6'}>
                    <DataLabelValueComponent label={'Recipient:'}>
                      <div className='invoice-screen-party-detail'>
                        <p>
                          GSTIN : {invoiceDetails?.gstData?.BuyerDtls.Gstin}
                        </p>
                        <p>{invoiceDetails?.gstData?.BuyerDtls?.LglNm} </p>
                        <p className='invoice-text-overflow'>
                          {invoiceDetails?.gstData?.BuyerDtls?.Addr1} ,{' '}
                          {invoiceDetails?.gstData?.BuyerDtls?.Loc}
                        </p>
                        <p>
                          {invoiceDetails?.gstData?.BuyerDtls?.TrdNm} ,{' '}
                          {invoiceDetails?.gstData?.BuyerDtls?.Pin}
                        </p>
                        <p>{invoiceDetails?.gstData?.BuyerDtls?.Em}</p>
                      </div>
                    </DataLabelValueComponent>
                  </div>
                </div>
              </div>
            </CardComponent>
          </>
        ) : null}
        {invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'nonGst' && (
            <>
              <CardComponent>
                <div>
                  <FormControlLabelComponent
                    label='Party Details'
                    isBold={true}
                    level={5}
                  />
                  <div className={'ts-row mrg-top-20'}>
                    <div className={'ts-col-lg-6'}>
                      <DataLabelValueComponent label={'Billed To:'}>
                        <div className='invoice-screen-party-detail'>
                          <p>
                            GSTIN :{' '}
                            {addressDetails?.billingPreferences?.gstNumber ||
                              '--'}
                          </p>
                          <p>
                            PAN :
                            {addressDetails?.billingPreferences?.panNumber ||
                              '--'}
                          </p>
                          <p className='invoice-text-overflow'>
                            {addressDetails?.billingAddress?.addressLine1},{' '}
                            {addressDetails?.billingAddress?.city}
                          </p>
                          <p>
                            {addressDetails?.billingAddress?.state},{' '}
                            {addressDetails?.billingAddress?.pincode}
                          </p>
                        </div>
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-6'}>
                      <DataLabelValueComponent label={'Place of Service'}>
                        <div className='invoice-screen-party-detail'>
                          <p>{invoiceDetails?.propertyDetails?.name || '--'}</p>
                          <p className='invoice-text-overflow'>
                            {
                              invoiceDetails?.propertyDetails?.addressDetails
                                ?.addressLine1
                            }
                            ,{' '}
                            {
                              invoiceDetails?.propertyDetails?.addressDetails
                                ?.location
                            }
                          </p>
                          <p>
                            {
                              invoiceDetails?.propertyDetails?.addressDetails
                                ?.city
                            }
                          </p>
                          <p>
                            {
                              invoiceDetails?.propertyDetails?.addressDetails
                                ?.state
                            }
                            ,{' '}
                            {
                              invoiceDetails?.propertyDetails?.addressDetails
                                ?.pincode
                            }
                          </p>
                        </div>
                      </DataLabelValueComponent>
                    </div>
                  </div>
                </div>
              </CardComponent>
              <CardComponent>
                <FormControlLabelComponent
                  label={CommonService.capitalizeFirstLetterOfEachWord(
                    invoiceDetails?.invoiceName,
                  )}
                  isBold
                  level={5}
                />
                <div className='utility-invoice-service-desc'>
                  {invoiceDetails?.descriptionOfService ? (
                    <>{invoiceDetails?.descriptionOfService}</>
                  ) : (
                    <>--</>
                  )}
                </div>
                <CardComponent className={'mrg-top-20 e-invoice-details-card'}>
                  <TableComponent
                    noDataText={'No invoice details available.'}
                    className={'mrg-top-15 e-invoice-details-table'}
                    autoHeight={true}
                    columns={UtilityInvoiceItemListTable}
                    data={invoiceDetails?.invoiceItemList || []}
                  />
                </CardComponent>
                <div className='italic-text-lite text-right'>
                  {/* {CommonService.convertNumberToWords(
                )} */}
                  {totalInvoiceItemAmount(invoiceDetails?.invoiceItemList)}
                </div>
              </CardComponent>
              <CardComponent>
                <FormControlLabelComponent
                  label='Bank details for remittance'
                  isBold
                  level={5}
                />
                <CardComponent className='mrg-top-20'>
                  <InvoiceInfoBoxComponent
                    label={'Account Name'}
                    value={
                      invoiceDetails?.billingEntity?.name ? (
                        <>{invoiceDetails?.billingEntity?.name}</>
                      ) : (
                        <>--</>
                      )
                    }
                  />
                  <InvoiceInfoBoxComponent
                    label={'Bank Name'}
                    value={'Axis Bank Ltd'}
                  />
                  <InvoiceInfoBoxComponent
                    label={'Account Number'}
                    value={'9273819382571231'}
                  />
                  <InvoiceInfoBoxComponent
                    label={'RTGS Code'}
                    value={'YSU83838234'}
                  />
                  <InvoiceInfoBoxComponent
                    label={'Branch & Address'}
                    value={'Madhapur & Hyderabad'}
                  />
                </CardComponent>
                <div className='utility-note'>
                  Note: Interest @18% per annum shall be levied for the payments
                  made after 07 days from invoice.
                </div>
              </CardComponent>
              <CardComponent>
                <FormControlLabelComponent
                  label='Phoenix Tech Zone Private Limited'
                  isBold
                  level={5}
                />
                <div className='text-lite'>
                  UNIT NO.201 & 202, 2ND FLOOR, YS VIVEKA ENCLAVE, PLOT
                  NO.21-23, SURVEY NUMBER 1, KHAJAGUDA, HYDERABAD,
                  TELANGANA-500032, INDIA T:+91-40-23553351/61,
                  F:+91-40-23556660, WWW.PHOENIXINDIA.NET, CIN
                  NO.U45209TG2014PTC092624
                </div>
              </CardComponent>
              <div className='utility-note mrg-bottom-20'>
                Note: This is a electronically generated invoice thus does not
                contain any digital signature generated date :{' '}
                {moment().format('DD-MM-YYYY | hh:mm A')}
              </div>
            </>
          )}

        {invoiceDetails?.category === 'regularInvoice' ||
        (invoiceDetails?.category === 'utilityInvoice' &&
          invoiceDetails?.utilityInvoiceType === 'gst') ? (
          <>
            <CardComponent>
              <div>
                <FormControlLabelComponent
                  label='Details of Goods/Services'
                  isBold={true}
                  level={5}
                />
                <CardComponent className={'mrg-top-20 e-invoice-details-card'}>
                  <TableComponent
                    noDataText={'No invoice details available.'}
                    className={'mrg-top-15 e-invoice-details-table'}
                    autoHeight={true}
                    columns={InvoiceDetailsGoodsAndServicesOne}
                    data={ItemList}
                  />
                </CardComponent>
                <CardComponent className='e-invoice-details-card'>
                  <TableComponent
                    noDataText={'No invoice details available.'}
                    className={'mrg-top-15 e-invoice-details-table'}
                    autoHeight={true}
                    columns={InvoiceDetailsGoodsAndServicesTwo}
                    data={ItemList}
                  />
                </CardComponent>
              </div>
            </CardComponent>
            <CardComponent>
              <div>
                <FormControlLabelComponent
                  label='e-invoice details'
                  isBold={true}
                  level={5}
                />
                <div className={'mrg-top-20'}>
                  <div className='invoice-details-signature-wrapper'>
                    <div className='ts-row'>
                      <div className={'ts-col-lg-3'}>
                        <DataLabelValueComponent label={'Generated by'}>
                          --
                        </DataLabelValueComponent>
                      </div>
                      <div className={'ts-col-lg-3'}>
                        <DataLabelValueComponent label={'Print Date'}>
                          --
                        </DataLabelValueComponent>
                      </div>
                    </div>
                    <div className='invoice-details-signature'></div>
                  </div>
                </div>
              </div>
            </CardComponent>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default InvoicePreviewComponent;
