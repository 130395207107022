import './PropertyListComponent.scss';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import { ITableColumn } from '../../../shared/models/table.model';
import { CommonService } from '../../../shared/services';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface PropertyListComponentProps {}

const PropertyListComponent = (props: PropertyListComponentProps) => {
  const [count, setCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({
    search: '',
    // sort:{},
  });
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const PropertyListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
    return [
      {
        title: 'Property Name',
        key: 'property_name',
        dataIndex: 'name',
        fixed: 'left',
        // sortable: true,
        width: 300,
        render: (item: any) => {
          return (
            <div className={'property-details-wrapper'}>
              <AvatarComponent
                url={item?.profilePic?.url}
                size={'xs'}
                title={CommonService.getTwoLetterInitials(item?.name)}
              />
              <div className={'property-name-tower-wrapper'}>
                {(item?.name).length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.name}
                    showArrow={true}
                    position={'top'}
                    textColor={'#fff'}
                  >
                    <div className={'ellipses-for-table-data property-name'}>
                      {item?.name}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='property-name'>{item?.name || '-'}</div>
                )}
                <div className={'towers-count'}>
                  <span>Towers : </span>
                  {item?.campusDetails?.numberOfTowers || '0'}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: 'Entity Name',
        key: 'entity_name',
        dataIndex: 'name',
        dataType: 'text',
        // fixed: 'left',
        // sortable: true,
        width: 200,
        render: (item: any) => {
          return (
            <div className={'entity-details-wrapper'}>
              <div className={'entity-name-wrapper'}>
                {item?.billingEntityData?.name?.length > 20 ? (
                  <ToolTipComponent
                    tooltip={item?.billingEntityData?.name}
                    showArrow={true}
                    position={'top'}
                    textColor={'#fff'}
                  >
                    <div
                      className={
                        'ellipses-for-table-data billingEntityData-name text-transform-capitalize'
                      }
                    >
                      {item?.billingEntityData?.name ? (
                        <>{item?.billingEntityData?.name || ''}</>
                      ) : (
                        '--'
                      )}
                    </div>
                  </ToolTipComponent>
                ) : (
                  <div className='property-name'>
                    {item?.billingEntityData?.name ? (
                      <>
                        {CommonService.capitalizeFirstLetterOfEachWord(
                          item?.billingEntityData?.name || '',
                        )}
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Facility Manager',
        key: 'facility_manager',
        dataIndex: 'facility_manager',
        width: 200,
        align: 'left',
        render: (item: any) => {
          return (
            <>
              {(item?.fms || []).length > 0 ? (
                <>
                  {item?.fms?.length === 1 ? (
                    <div className='text-transform-capitalize'>
                      {item?.fms[0]?.name}{' '}
                    </div>
                  ) : (
                    <ToolTipComponent
                      // backgroundColor={'#fff'}
                      position={'right'}
                      textColor={'#FFFFFF'}
                      tooltip={
                        <>
                          {item?.fms.map((value1: any, index1: number) => {
                            return (
                              <div
                                className={
                                  'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                }
                                key={index1}
                              >
                                {value1.name}
                              </div>
                            );
                          })}
                        </>
                      }
                    >
                      <div className={'d-flex'}>
                        <ChipComponent
                          className={
                            'scheduled cursor-pointer text-transform-capitalize'
                          }
                          size={'small'}
                          color={'primary'}
                          label={
                            item?.fms[0].name +
                            ((item?.fms || []).length > 1
                              ? ' (+' + ((item?.fms || []).length - 1) + ')'
                              : '')
                          }
                        />
                      </div>
                    </ToolTipComponent>
                  )}
                </>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      },
      {
        title: 'FM Phone No.',
        key: 'phone',
        dataIndex: 'phone',
        render: (item: any) => {
          return (
            <>
              {(item?.fms || []).length > 0 ? (
                <>
                  {item?.fms?.length === 1 ? (
                    <>
                      {CommonService.convertPhoneNumber(
                        item?.fms[0]?.phoneNumber,
                      )}
                    </>
                  ) : (
                    <ToolTipComponent
                      position={'right'}
                      textColor={'#FFFFFF'}
                      tooltip={
                        <>
                          {item?.fms.map((value1: any, index1: number) => {
                            return (
                              <div
                                className={
                                  'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                }
                                key={index1}
                              >
                                {CommonService.convertPhoneNumber(
                                  value1.phoneNumber,
                                )}
                              </div>
                            );
                          })}
                        </>
                      }
                    >
                      <div className={'d-flex'}>
                        <ChipComponent
                          className={'scheduled cursor-pointer'}
                          size={'small'}
                          color={'primary'}
                          label={
                            CommonService.convertPhoneNumber(
                              item?.fms[0].phoneNumber,
                            ) +
                            ((item?.fms || []).length > 1
                              ? ' (+' + ((item?.fms || []).length - 1) + ')'
                              : '')
                          }
                        />
                      </div>
                    </ToolTipComponent>
                  )}
                </>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      },
      {
        title: 'Location',
        key: 'location',
        dataIndex: 'address',
        width: 180,
        render: (item: any) => {
          return (
            <>
              {item?.addressDetails?.location?.length > 50 ? (
                <ToolTipComponent
                  tooltip={<div>{item?.addressDetails?.city || '-'}</div>}
                  showArrow={true}
                  position={'top'}
                >
                  <div
                    className={
                      'ellipses-for-table-data text-transform-capitalize'
                    }
                  >
                    {item?.addressDetails?.city}
                  </div>
                </ToolTipComponent>
              ) : (
                <div className='text-transform-capitalize'>
                  {item?.addressDetails?.city || '-'}
                </div>
              )}
            </>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        fixed: 'right',
        //width: 100,
        render: (item: any) => {
          return (
            <>
              <TagComponent
                color={
                  item?.isActive
                    ? CommonService.getPropertyStatusTagColor('Checked In')
                    : CommonService.getPropertyStatusTagColor('Checked Out')
                }
                showIndicator={true}
                label={item?.isActive ? 'Active' : 'Inactive'}
              />
            </>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'actions',
        align: 'center',
        key: 'actions',
        width: 100,
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.PropertyDetails(item?._id)}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, []);

  // const handlePropertySort = useCallback((key: string, order: string) => {
  //     console.log(key, order);
  //     setFilterData((oldState) => {
  //         const newState = {...oldState};
  //         newState["sort"] = {
  //             key,
  //             order
  //         }
  //         return newState;
  //     });
  // }, []);

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._property
        .downloadPropertyListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'property_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Property List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'property-list-component list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Property</div>
            <div className='list-screen-data-count'>
              <TagComponent color={'primary'} label={JSON.stringify(count)} />
            </div>
          </div>
        </div>
        <div className='list-options'>
          <SearchComponent
            classWrapper={'search-component-wrapper'}
            placeholder='Search Property Name'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          <LinkComponent route={CommonService._routeConfig.AddPropertyRoute()}>
            <ButtonComponent
              color={'primary'}
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
            >
              Add New Property
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.PROPERTY_LIST.URL}
          method={APIConfig.PROPERTY_LIST.METHOD}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          noDataText={
            filterData.search
              ? 'Oops! It seems like there are no properties available for the name you have searched.'
              : 'No Properties have been added yet'
          }
          columns={PropertyListColumn}
        />
      </div>
    </div>
  );
};

export default PropertyListComponent;
