export const SET_INVOICE_DATE_RANGE_IN_FILTER =
  "SET_INVOICE_DATE_RANGE_IN_FILTER";
export const SET_INVOICE_DATEFILTERON_IN_FILTER =
  "SET_INVOICE_DATEFILTERON_IN_FILTER";
export const SET_INVOICE_FILTER_COUNT = "SET_INVOICE_FILTER_COUNT";

export const setInvoiceDateRangeInFilters = (dateRange: any) => {
  return {
    type: SET_INVOICE_DATE_RANGE_IN_FILTER,
    payload: {
      dateRange,
    },
  };
};

export const setInvoicedateFilterOnInFilters = (dateFilterOn: string) => {
  return {
    type: SET_INVOICE_DATEFILTERON_IN_FILTER,
    payload: {
      dateFilterOn,
    },
  };
};

export const setInvoiceFilterCount = (count: number) => {
  return {
    type: SET_INVOICE_FILTER_COUNT,
    payload: {
      count,
    },
  };
};
