import './UserAddScreen.scss';
import * as Yup from 'yup';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { ImageConfig, Misc } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';
import FormikSelectDropdownComponent from '../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
// @ts-ignore
// import userCSV from "../../../assets/templates/users.csv";
import { useNavigate, useParams } from 'react-router-dom';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import { getEntityListLite } from '../../../store/actions/entity.action';
import { getPropertyListLite } from '../../../store/actions/property.action';

interface IUserAddFormValues {
  propertyId: string;
  billingEntityId: string;
  roleCode: string;
  email: string;
  userCode: string;
  name: string;
  phoneNumber: string;
  gender: string;
}

const UserAddFormValidationSchema = Yup.object({
  roleCode: Yup.string().required('User Role is required'),
  propertyId: Yup.string().when('roleCode', {
    is: (roleCode: string) =>
      roleCode === 'fm' || roleCode === 'converz-security',
    then: Yup.string().required('Property is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  billingEntityId: Yup.string().when('roleCode', {
    is: (roleCode: string) => roleCode === 'em',
    then: Yup.string().required('Entity is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  userCode: Yup.string()
    .max(10, 'UserID should not exceed 10 characters')
    .required('User ID is required'),
  name: Yup.string()
    .min(3, 'Full Name should be at least 3 characters')
    .max(30, 'Full Name should not exceed 30 characters')
    .required('Full Name is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, 'Invalid phone number')
    .min(10, 'Phone number should be 10 digits')
    .required('Phone Number is required'),
  gender: Yup.string().when('$roleCode', {
    is: (roleCode: any) => roleCode !== 'em',
    then: Yup.string().required('Gender is required').nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  email: Yup.string()
    .email('Invalid email address')
    .when('roleCode', {
      is: (roleCode: string) => roleCode !== 'converz-security',
      then: Yup.string()
        .email('Invalid email address')
        .required('Email ID is required'),
      otherwise: Yup.string().email('Invalid email address'),
    }),
});

const UserAddFormInitialValues: IUserAddFormValues = {
  propertyId: '',
  billingEntityId: '',
  roleCode: '',
  email: '',
  userCode: '',
  name: '',
  phoneNumber: '',
  gender: '',
};

interface UserAddScreenProps {}

const UserAddScreen = (props: UserAddScreenProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { entityList } = useSelector(
    (state: IRootReducerState) => state.entity,
  );
  const { userRoleList } = useSelector(
    (state: IRootReducerState) => state.staticData,
  );
  const [userFormInitialValues, setUserFormInitialValues] =
    useState<IUserAddFormValues>(_.cloneDeep(UserAddFormInitialValues));
  const [isUserAddingInProgress, setIsUserAddingInProgress] = useState(false);
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );

  const { userId } = useParams();

  useEffect(() => {
    dispatch(getEntityListLite());
    dispatch(getPropertyListLite());
  }, [dispatch]);

  const [userDetails, setUserDetails] = useState<any>(null);
  const [isUserDetailsLoading, setIsUserDetailsLoading] =
    useState<boolean>(false);

  const onSubmit = useCallback(
    (values: any, { setErrors }: FormikHelpers<any>) => {
      setIsUserAddingInProgress(true);
      let apiCall: any = {};
      if (userDetails?._id) {
        apiCall = CommonService._user.editUserAPICall(values, userDetails?._id);
      } else {
        apiCall = CommonService._user.addUserAPICall(values);
      }
      apiCall
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          navigate(CommonService._routeConfig.UserListRoute());
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setIsUserAddingInProgress(false);
        });
    },
    [navigate, userDetails],
  );

  const handleBack = useCallback(() => {
    navigate(CommonService._routeConfig.UserListRoute());
  }, [navigate]);

  useEffect(() => {
    if (userDetails) {
      const user = {
        propertyId: userDetails?.propertyId,
        billingEntityId: userDetails?.billingEntityId,
        roleCode: userDetails?.roleDetails.code,
        email: userDetails?.email,
        userCode: userDetails?.userCode,
        name: userDetails?.name,
        phoneNumber: userDetails?.phoneNumber,
        gender: userDetails?.gender,
      };
      setUserFormInitialValues(user);
    } else {
      setUserFormInitialValues({
        propertyId: currentUser?.propertyId,
        billingEntityId: currentUser?.billingEntityId,
        roleCode: '',
        email: '',
        userCode: '',
        name: '',
        phoneNumber: '',
        gender: '',
      });
    }
  }, [userDetails, currentUser]);

  const getUserDetails = useCallback(() => {
    if (userId) {
      setIsUserDetailsLoading(true);
      const payload = {};
      CommonService._user
        .userDetailsAPICall(userId, payload)
        .then((response: IAPIResponseType<any>) => {
          setUserDetails(response.data);
        })
        .catch((error: any) => {})
        .finally(() => {
          setIsUserDetailsLoading(false);
        });
    }
  }, [userId]);

  useEffect((): void => {
    if (userId) {
      getUserDetails();
    }
  }, [getUserDetails, userId]);

  // const fetchPropertyFMList = useCallback((propertyId: string) => {
  //     dispatch(getPropertyFMList(propertyId));
  // }, [dispatch]);

  return (
    <div className={'t-form-screen user-add-screen'}>
      {isUserDetailsLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      ) : (
        <Formik
          validationSchema={UserAddFormValidationSchema}
          initialValues={userFormInitialValues}
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            validateForm,
            isValid,
            touched,
            errors,
            setFieldValue,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              validateForm();
            }, [validateForm, values]);
            return (
              <Form className='t-form' noValidate={true}>
                <div className='t-form-header user-add-screen-header'>
                  <div className='t-form-header-left'>
                    <ButtonComponent
                      variant={'text'}
                      color={'secondary'}
                      onClick={handleBack}
                      prefixIcon={<ImageConfig.ArrowLeftIcon />}
                    >
                      Exit
                    </ButtonComponent>
                  </div>
                  <div className='t-form-header-right'>
                    <ButtonComponent
                      type={'submit'}
                      isLoading={isUserAddingInProgress}
                      disabled={isUserAddingInProgress || !isValid}
                    >
                      Save
                    </ButtonComponent>
                  </div>
                </div>
                <div className='t-form-content user-add-screen-content'>
                  <FormControlLabelComponent
                    label={'Property Details'}
                    level={4}
                    isBold={true}
                  />
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'roleCode'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            options={userRoleList}
                            displayWith={(option: any) => option.role}
                            valueExtractor={(option: any) => option.code}
                            onUpdate={(value: any) => {
                              setFieldValue('email', '');
                              setFieldValue('billingEntityId', '');
                            }}
                            label={'User Role'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='ts-col-6'>
                      {(values?.roleCode === 'fm' ||
                        values?.roleCode === 'converz-security') && (
                        <Field name={'propertyId'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={propertyList}
                              displayWith={(option: any) => option.name}
                              valueExtractor={(option: any) => option._id}
                              label={'Property'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}
                      {values?.roleCode === 'em' && (
                        <Field name={'billingEntityId'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={entityList}
                              displayWith={(option: any) => option.name}
                              valueExtractor={(option: any) => option._id}
                              label={'Entity'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                  <Field name={'email'}>
                    {(field: FieldProps) => (
                      <>
                        {/*{(values?.roleCode === "fm") &&*/}
                        {/*    <FormikSelectDropdownComponent*/}
                        {/*        options={propertyFMsList?.filter((item: any) => !item.isAssigned)}*/}
                        {/*        displayWith={(option: any) => option?.email}*/}
                        {/*        valueExtractor={(option: any) => option?.email}*/}
                        {/*        label={'Email Id'}*/}
                        {/*        placeholder={'Select'}*/}
                        {/*        required={true}*/}
                        {/*        formikField={field}*/}
                        {/*        fullWidth={true}*/}
                        {/*    />*/}
                        {/*}*/}
                        <FormikInputComponent
                          label={'Email ID'}
                          placeholder={'Enter Email ID'}
                          required={
                            values?.roleCode !== 'converz-security'
                              ? true
                              : false
                          }
                          formikField={field}
                          fullWidth={true}
                        />
                      </>
                    )}
                  </Field>
                  <FormControlLabelComponent
                    label={'User Details'}
                    level={4}
                    isBold={true}
                  />
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'userCode'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'User ID'}
                            placeholder={'Example: PH-7398'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='ts-col-6'>
                      <Field name={'name'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Full Name'}
                            placeholder={'Example: Rahul Reddy'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className='ts-row'>
                    <div
                      className={` ${
                        values?.roleCode !== 'em' ? 'ts-col-6' : 'ts-col-12'
                      }`}
                    >
                      <Field name={'phoneNumber'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Phone Number'}
                            placeholder={'+91 00000 00000'}
                            type={'text'}
                            validationPattern={new RegExp(/^[5-9][0-9]{0,9}$/)}
                            maxlength={10}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                    {values?.roleCode !== 'em' && (
                      <div className='ts-col-6'>
                        <Field name={'gender'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={CommonService._staticData.GenderList}
                              label={'Gender'}
                              // placeholder={'Select'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                              displayWith={(option: any) => option.title}
                              valueExtractor={(option: any) => option.code}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default UserAddScreen;
