import {call, put, takeEvery} from "redux-saga/effects";
import {CommonService} from "../../shared/services";
import {GET_ALL_USERS_LIST, setAllUsersList} from "../actions/user.action";

function* getAllUsersList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._user.getUserListLite, {role: "provider"});
        yield put(setAllUsersList(resp?.data));
    } catch (error: any) {
        yield put(setAllUsersList([]));
    }
}

export default function* userSaga() {
    yield takeEvery(GET_ALL_USERS_LIST, getAllUsersList);
}
