import './PollTimingsComponent.scss';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikDatePickerComponent from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikTimePickerComponent from '../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import { CommonService } from '../../../../shared/services';
import moment from 'moment';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import { getPropertyListLite } from '../../../../store/actions/property.action';

interface PollTimingsComponentProps {
  pollsDetails?: any;
  prev: () => void;
  next: () => void;
  steps: any[];
  activeStepId: string;
}

const validationSchema = Yup.object({
  startDate: Yup.string().required('Start date is required').nullable(),
  endDate: Yup.string().required('End date is required').nullable(),
  startTime: Yup.string().required('Required'),
  endTime: Yup.string().required('Required'),
  allowedProperties: Yup.array()
    .of(Yup.string().required('Required').nullable())
    .min(1, 'At least one property is required')
    .nullable(),
  publishResultToUser: Yup.boolean(),
});

interface formInitialValuesFormSchema {
  startDate: any;
  endDate: any;
  startTime: any;
  endTime: any;
  allowedProperties: any[];
  publishResultToUser: boolean;
}

const formInitialValues: formInitialValuesFormSchema = {
  endDate: '',
  startDate: '',
  startTime: '',
  endTime: '',
  allowedProperties: [],
  publishResultToUser: false,
};

const PollTimingsComponent = (props: PollTimingsComponentProps) => {
  const { pollsDetails, prev, next, steps, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPropertyListLite());
  }, [dispatch]);

  useEffect(() => {
    if (pollsDetails) {
      setInitialValues({
        ...pollsDetails,
        startDate: pollsDetails.startDate,
        endDate: pollsDetails.endDate,
        startTime: pollsDetails.startTime
          ? moment(pollsDetails.startTime, 'HH:mm a')
          : '',
        endTime: pollsDetails.endTime
          ? moment(pollsDetails.endTime, 'HH:mm a')
          : '',
        publishResultToUser: pollsDetails?.publishResultToUser ? true : false,
        allowedProperties: pollsDetails?.allowedProperties || [],
      });
    }
  }, [pollsDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const payload = {
        ...values,
        startDate: moment(values?.startDate).format('YYYY-MM-DD'),
        endDate: moment(values?.endDate).format('YYYY-MM-DD'),
        startTime: moment(values.startTime, ['h:mm A']).format('HH:mm'),
        endTime: moment(values.endTime, ['h:mm A']).format('HH:mm'),
      };
      payload['startDateTime'] = payload.startDate + ` ${payload.startTime}`;
      payload['endDateTime'] = payload.endDate + ` ${payload.endTime}`;
      if (!payload.allowedProperties.length) {
        payload['allowedProperties'] = propertyList.map((property: any) => {
          return property._id;
        });
      }
      let apiCall: any = {};
      if (pollsDetails._id) {
        apiCall = CommonService._poll.editPollAPICall(
          pollsDetails._id,
          payload,
        );
      } else {
        apiCall = CommonService._poll.AddPollAPICall(payload);
      }

      apiCall
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next();
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [next, propertyList, pollsDetails],
  );

  return (
    <div className={'poll-timings-component'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'startDate'}>
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          label={'Start Date'}
                          placeholder={'DD/MM/YYYY'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          minDate={new Date()}
                          onChange={(date: any) => {
                            setFieldValue('endDate', null);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'startTime'}>
                      {(field: FieldProps) => (
                        <FormikTimePickerComponent
                          label={'Start Time'}
                          placeholder={'00:00'}
                          use12Hours={true}
                          format={'HH:mm'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'endDate'}>
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          label={'End Date'}
                          placeholder={'DD/MM/YYYY'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          minDate={new Date(values.startDate)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'endTime'}>
                      {(field: FieldProps) => (
                        <FormikTimePickerComponent
                          label={'End Time'}
                          placeholder={'00:00'}
                          use12Hours={true}
                          format={'HH:mm'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <FormControlLabelComponent
                  label={'Vote Permissions'}
                  level={3}
                  isBold={true}
                />
                <Field name={'allowedProperties'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      options={propertyList}
                      displayWith={(option: any) => option.name}
                      valueExtractor={(option: any) => option._id}
                      label={'Select the property(s) who can answer this poll'}
                      placeholder={'Select Property(s)'}
                      required={true}
                      multiple={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                {(pollsDetails?.answerType === 'Single Select' ||
                  pollsDetails?.answerType === 'Multiple Select') && (
                  <Field name={'publishResultToUser'}>
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        label={'Can the user see the poll results?'}
                        required={true}
                        formikField={field}
                        options={CommonService._staticData.yesNoOptions}
                      />
                    )}
                  </Field>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PollTimingsComponent;
