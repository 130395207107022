import { call, put, takeEvery } from 'redux-saga/effects';
import { CommonService } from '../../shared/services';
import {
  GET_ALL_TENANT_LIST,
  GET_TENANT_LIST_LITE,
  setAllTenantList,
  setTenantListLite,
} from '../actions/tenant.action';

function* getAllTenantList() {
  try {
    //@ts-ignore
    const resp = yield call(CommonService._tenants.GetTenantsListLiteAPICall);
    const tenantList = resp?.data;
    const allTenantList = tenantList.map((tenant: any) => {
      return {
        _id: tenant?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(tenant?.name),
        propertyId: tenant?.propertyId,
        tenantAdminDetails: tenant?.tenantAdminDetails,
      };
    });
    yield put(setAllTenantList(allTenantList));
  } catch (error: any) {
    yield put(setAllTenantList([]));
  }
}

function* getTenantListLite() {
  try {
    //@ts-ignore
    const resp = yield call(CommonService._tenants.GetTenantsListLiteAPICall, {
      isActive: true,
    });
    const tenantList = resp?.data;
    const tenantListLite = tenantList.map((tenant: any) => {
      return {
        _id: tenant?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(tenant?.name),
        propertyId: tenant?.propertyId,
        tenantAdminDetails: tenant?.tenantAdminDetails,
      };
    });
    yield put(setTenantListLite(tenantListLite));
  } catch (error: any) {
    yield put(setTenantListLite([]));
  }
}

export default function* tenantSaga() {
  yield takeEvery(GET_TENANT_LIST_LITE, getTenantListLite);
  yield takeEvery(GET_ALL_TENANT_LIST, getAllTenantList);
}
