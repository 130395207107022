import "./AddPropertyComponent.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { CommonService } from "../../../shared/services";
import { IAPIResponseType } from "../../../shared/models/api.model";
import PropertyDetailsComponent from "./property-details/PropertyDetailsComponent";
import CampusDetailsComponent from "./campus-details/CampusDetailsComponent";
// import TowerDetailsComponent from "./tower-details/TowerDetailsComponent";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";

const PROPERTY_DETAILS = "property-details";
const CAMPUS_DETAILS = "campus-details";
// const TOWER_DETAILS = "tower-details";
// const UPLOAD_IMAGE = "upload-profile";

const STEPS = [
  {
    title: "Property Details",
    id: PROPERTY_DETAILS,
  },
  {
    title: "Campus Details",
    id: CAMPUS_DETAILS,
  },
  // {
  //   title: "Tower Details",
  //   id: TOWER_DETAILS,
  // },
  // {
  //     title: 'Upload Profile',
  //     id: UPLOAD_IMAGE,
  // }
];

interface AddPropertyComponentProps {}

const AddPropertyComponent = (props: AddPropertyComponentProps) => {
  const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
  const [propertyId, setPropertyId] = useState<string>("");
  const [propertyDetails, setPropertyDetails] = useState<any>(undefined);
  const [isPropertyDetailsLoading, setIsPropertyDetailsLoading] =
    useState<boolean>(false);
  const location: any = useLocation();
  const path = location.pathname;

  const updateSteps = useCallback(
    (currentStepIndex: number) => {
      setCurrentStep(steps[currentStepIndex]);
      searchParams.set("step", STEPS[currentStepIndex].id);
      setSearchParams(searchParams);
      // setActiveStepId(steps[currentStepIndex].id);
    },
    [searchParams, setSearchParams, steps]
  );

  const getPropertyDetails = useCallback(() => {
    const payload = {
      towerDetailsRequired: true,
    };
    let propertyDetails: any = {};
    setIsPropertyDetailsLoading(true);
    CommonService._property
      .PropertyDetailsAPICall(propertyId, payload)
      .then((response: IAPIResponseType<any>) => {
        if (response?.data) {
          propertyDetails = response.data;
        }
        setPropertyDetails(propertyDetails);
        setIsPropertyDetailsLoading(false);
      })
      .catch((error: any) => {
        // setPropertyDetails(propertyDetails);
        setIsPropertyDetailsLoading(false);
      });
  }, [propertyId]);

  useEffect(() => {
    if (propertyId) {
      getPropertyDetails();
    }
  }, [getPropertyDetails, propertyId]);

  useEffect(() => {
    const step: any = searchParams.get("step");
    // const stepperIndex = steps.findIndex(stepItem => stepItem.id === step);
    // setActiveStepId(steps[stepperIndex]?.id);
    const propertyId = searchParams.get("propertyId");
    const stepIDS = steps.map(item => item.id);
    let stepIndex: number = 0;
    if (propertyId) {
      setPropertyId(propertyId);
      if (stepIDS.indexOf(step) > -1) {
        if (step !== PROPERTY_DETAILS) {
          stepIndex = stepIDS.indexOf(step);
        } else {
          stepIndex = 0;
        }
      } else {
        stepIndex = 0;
      }
    }
    setCurrentStep(steps[stepIndex]);
  }, [searchParams, steps]);

  const handlePrevious = useCallback(
    (currentStepIndex: number, currentStepId: string) => {
      if ([CAMPUS_DETAILS].includes(currentStepId)) {
        updateSteps(currentStepIndex);
      } else {
        // do nothing;
      }
    },
    [updateSteps]
  );

  const handleBack = useCallback(() => {
    if (path.includes("/property/add")) {
      navigate(CommonService._routeConfig.PropertyListRoute());
    } else {
      navigate(CommonService._routeConfig.PropertyDetails(propertyId));
    }
  }, [navigate, propertyId, path]);

  const handleNext = useCallback(
    (
      currentStepIndex: number,
      currentStepId: string,
      propertyId?: string,
      data?: any
    ) => {
      switch (currentStepId) {
        case PROPERTY_DETAILS:
          if (propertyId) {
            setPropertyId(propertyId);
            searchParams.set("propertyId", propertyId.toString());
            setSearchParams(searchParams);
            if (data) {
              setPropertyDetails((oldSate: any) => {
                return {
                  ...oldSate,
                  name: data.name,
                  billingEntityId: data.billingEntityId,
                  addressDetails: data.addressDetails,
                  image: data.image,
                };
              });
            }
          }
          updateSteps(currentStepIndex);
          break;

        case CAMPUS_DETAILS:
          if (data) {
            setPropertyDetails((oldSate: any) => {
              return {
                ...oldSate,
                campusDetails: data.campusDetails,
                towers:data.towers,
                defaultEmails: data.defaultEmails,
              };
            });
          }
          break;
        // case TOWER_DETAILS:
        //   handleBack();
        //   if (data) {
        //     setPropertyDetails((oldSate: any) => {
        //       return {
        //         ...oldSate,
        //         towers: data,
        //       };
        //     });
        //   }
        //   updateSteps(currentStepIndex);
        //   break;
        // case UPLOAD_IMAGE:
        //     handleBack();
        //     break;
        default:
          <></>;
      }
    },
    [searchParams, setSearchParams, updateSteps]
  );
  // handleBack

  const renderComponent = useCallback(() => {
    switch (currentStep && currentStep.id) {
      case PROPERTY_DETAILS:
        return (
          <PropertyDetailsComponent
            next={(propertyId: string, data: any) =>
              handleNext(1, PROPERTY_DETAILS, propertyId, data)
            }
            prev={handleBack}
            steps={steps}
            activeStepId={currentStep.id}
            propertyDetails={propertyDetails}
            mode={propertyDetails ? "edit" : "add"}
          />
        );
      case CAMPUS_DETAILS:
        return (
          <CampusDetailsComponent
            propertyId={propertyId}
            propertyDetails={propertyDetails}
            next={() => handleBack()}
            prev={() => handlePrevious(0, CAMPUS_DETAILS)}
            steps={steps}
            activeStepId={currentStep.id}
          />
        );
      // case TOWER_DETAILS:
      //   return (
      //     <TowerDetailsComponent
      //       propertyId={propertyId}
      //       propertyDetails={propertyDetails}
      //       next={(data: any) => handleNext(3, TOWER_DETAILS, undefined)}
      //       prev={() => handlePrevious(1, TOWER_DETAILS)}
      //       steps={steps}
      //       activeStepId={currentStep.id}
      //     />
      //   );
      // case UPLOAD_IMAGE:
      //     return <UploadProfileComponent
      //         propertyId={propertyId}
      //         propertyDetails={propertyDetails}
      //         next={() => handleNext(4, UPLOAD_IMAGE, undefined)}
      //         prev={() => handlePrevious(2, UPLOAD_IMAGE)}
      //         steps={steps}
      //         activeStepId={currentStep.id}
      //     />
      default:
        <></>;
    }
  }, [
    propertyId,
    currentStep,
    handleNext,
    handlePrevious,
    propertyDetails,
    handleBack,
    steps,
  ]);

  return (
    <div className={"add-property-component"}>
      {isPropertyDetailsLoading ? (
        <div className="h-v-center">
          <LoaderComponent type={"spinner"} />
        </div>
      ) : (
        <>{renderComponent()}</>
      )}
    </div>
  );
};

export default AddPropertyComponent;
