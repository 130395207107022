import "./TextAreaComponent.scss";
import {IInputFieldProps} from "../../../models/form-controls.model";
import {useCallback} from "react";
import {CommonService} from "../../../services";
import LabelComponent from "../../label/LabelComponent";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";

interface TextAreaComponentProps extends IInputFieldProps {
    name?: string;
    value?: string;
    errorMessage?: any;
    hasError?: boolean;
    maxlength?: number;
}

const TextAreaComponent = (props: TextAreaComponentProps) => {

    const {
        label,
        titleCase,
        errorMessage,
        hasError,
        className,
        inputProps,
        disabled,
        required,
        value,
        validationPattern,
        fullWidth,
        size,
        maxlength,
        onChange,
        onClick,
        suffix,
        prefix,
        labelTooltip,
        ...otherProps
    } = props;

    const inputSize = props.size || "medium";
    const type = props.type || "text";
    const placeholder = props.placeholder || label;
    const rows = props.rows || 3;


    const handleOnChange = useCallback((event: any) => {
        let nextValue = event.target.value;
        if (titleCase) {
            nextValue = CommonService.Capitalize(nextValue);
        }
        if (onChange) {
            if (validationPattern) {
                const reg = RegExp(validationPattern);
                if (nextValue === "" || reg.test(nextValue)) {
                    onChange(nextValue);
                } else {
                    // console.log(nextValue, reg, reg.test(nextValue), "regex failed");
                }
            } else {
                onChange(nextValue);
            }
        }
    }, [titleCase, validationPattern, onChange]);

    return (
        <div className={`text-area-component ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''}`}>
            {label && <LabelComponent title={label} required={required} tooltip={labelTooltip}/>}
            <textarea
                type={type}
                maxLength={maxlength}
                placeholder={placeholder}
                required={required}
                onClick={onClick}
                style={{ fontFamily: 'Arial', fontSize: '14px' }}
                rows={rows}
                value={value || ""}
                disabled={disabled}
                className={'text-area-field ' + (prefix ? ' has-prefix ': '') + (suffix ? ' has-suffix ': '') + (hasError ? "has-error" : '') + ' ' + className + ' ' + inputSize}
                // className={'text-area-field ' + (hasError ? "has-error" : '') + ' ' + className + ' ' + inputSize}
                onChange={(event) => {
                    handleOnChange(event);
                }}
                {...inputProps}
                {...otherProps}
            />
            <>
                {
                    prefix && <span className="text-area-prefix" onClick={onClick}>{prefix}</span>
                }
                {
                    suffix && <span className="text-area-suffix" onClick={onClick}>{suffix}</span>
                }
            </>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );

};

export default TextAreaComponent;
