import "./PollDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {useNavigate, useParams} from "react-router-dom";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
import FormControlLabelComponent from "../../../shared/components/form-control-label/FormControlLabelComponent";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import BadgeComponent from "../../../shared/components/badge/BadgeComponent";
import {Field, FieldProps, Form, Formik} from "formik";
import ModalComponent from "../../../shared/components/modal/ModalComponent";
import _ from "lodash";
import FormikTextAreaComponent from "../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import {ImageConfig, Misc} from "../../../constants";
import * as Yup from "yup";
import ReactApexChart from "react-apexcharts";
import {ApexOptions} from "apexcharts";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {useDispatch} from "react-redux";
import moment from "moment";
import CheckBoxComponent from "../../../shared/components/form-controls/check-box/CheckBoxComponent";

interface PollDetailsComponentProps {

}

const cancelPollInitialValue: any = {
    reasonForCancellation: ''
}

const validationSchema = Yup.object({
    reasonForCancellation: Yup.string().required('Reason is Required')
});

interface IChartConfig {
    series: any[],
    options: ApexOptions
}

const StarRatingMapping: any = {
    1: 1 + ' ⭐️  (Bad)',
    2: 2 + ' ⭐️  (Sad)',
    3: 3 + ' ⭐️  (Average)',
    4: 4 + ' ⭐️  (Good)',
    5: 5 + ' ⭐️  (Excellent)'
}

const EmojiMapping: any = {
    1: '💛 (Loved it)',
    2: '👍 (Liked it)',
    3: '👎 (Disliked it)',
    4: '☹️ (Disappointing)',
    5: '😡 (Hated it)'
}

const PollDetailsComponent = (props: PollDetailsComponentProps) => {

    const {pollId} = useParams();
    const [pollDetails, setPollDetails] = useState<any>(null);
    const [isPollDetailsLoading, setIsPollDetailsLoading] = useState<boolean>(false);
    const [isPollDetailsLoadingFailed, setIsPollDetailsLoadingFailed] = useState<boolean>(false);
    const [isPollDetailsLoaded, setIsPollDetailsLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pollStats, setPollStats] = useState<any>(null);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const [isPollStatsLoading, setIsPollStatsLoading] = useState<boolean>(false);
    const [isPollStatsLoadingFailed, setIsPollStatsLoadingFailed] = useState<boolean>(false);
    const [isPollStatsLoaded, setIsPollStatsLoaded] = useState<boolean>(false);
    const [isCancelPollModalOpen, setIsCancelPollModalOpen] = useState<boolean>(false)
    const [propertyCount, setPropertyCount] = useState<number>(0)
    const [cancelPollFormInitialValue] = useState<any>(_.cloneDeep(cancelPollInitialValue));
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [pollAnswersChartConfig, setPollAnswersChartConfig] = useState<IChartConfig>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: undefined
            },
            legend: {
                horizontalAlign: 'center',
                position: 'bottom',
            },
            labels: [],
        }
    });

    const transformPollAnswersCount = useCallback((pollDetails: any, pollStats: any) => {
        const answerStats: any = pollStats?.answerStats;
        const PollOptions: any = Object.keys(answerStats);
        const PollAnswersVotes: any = Object.values(answerStats).map((item: any) => item?.totalVotes);
        const PollAnswersPercentages: any = Object.values(answerStats).map((item: any) => item?.votePercentage);
        const type = pollDetails?.answerSubType || pollDetails?.answerType;
        if (type === 'Single Select') {
            const sortedAnswerStats = Object.entries(answerStats).sort((a: any, b: any) => b[1].totalVotes - a[1].totalVotes);
            const votesTemp = sortedAnswerStats.map((item: any) => item[1].totalVotes);
            let votes = votesTemp;
            let emptyVotes = votesTemp.every((item: any) => item === 0);
            if (emptyVotes) {
                votes.fill(100 / votes.length);
            }
            const pollsOptions = sortedAnswerStats.map((item: any) => item[0]);
            setPollAnswersChartConfig((prevState) => {
                return {
                    ...prevState,
                    series: votes,
                    options: {
                        ...prevState.options,
                        plotOptions: {
                            pie: {
                                ...prevState?.options?.plotOptions?.pie,
                                dataLabels: {
                                    offset: -30
                                }
                            }
                        },
                        colors: PollOptions.map((option: any, index: number) => {
                            if (votes[index] === Math.max(...votes) && index === 0) {
                                return '#0078F3'; // Highest value in blue
                            } else {
                                const maxCount = Math.max(...votes);
                                const minOpacity = 0.2;
                                const maxOpacity = 1;
                                const opacity = (votes[index] * index / maxCount) * (maxOpacity - minOpacity) + minOpacity;
                                return `rgba(155, 158, 178, ${opacity})`;
                            }
                        }),
                        dataLabels: {
                            formatter: function (val: any) {
                                const roundedPercentage = Math.round(val);
                                return emptyVotes ? '0 %' : roundedPercentage !== 0 ? roundedPercentage + '%' : '';
                            },
                            offsetX: -10,
                            offsetY: 0
                        },
                        labels: pollsOptions,
                        tooltip: {
                            custom: function ({series, seriesIndex}) {
                                const votes = emptyVotes ? 0 : series[seriesIndex];
                                return `<div class="custom-apex-chart-bar-graph-tooltip">
                                            <span class="custom-apex-chart-bar-graph-tooltip-label">Votes: ${votes}</span>
                                        </div>`;
                            }
                        }
                    }
                }
            });
        } else if (type === 'Multiple Select') {
            const series = [{
                data: PollAnswersPercentages
            }];
            const config: IChartConfig = {
                series: series,
                options: {
                    chart: {
                        type: 'bar',
                        height: 100,
                        width: "100%",
                        toolbar: {
                            show: false
                        },
                        fontFamily: 'Inter'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                            barHeight: 40,
                            horizontal: true,
                            columnWidth: '55%',
                        }
                    },
                    colors: ["#0078F3"],
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: PollOptions,
                        decimalsInFloat: 0,
                        max: 100,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            },
                            formatter(value: string): string | string[] {
                                return value + '%';
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: true,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            }
                        }
                    },
                    grid: {
                        show: false
                    },
                    tooltip: {
                        custom: (e) => {
                            const {series, seriesIndex, dataPointIndex} = e;
                            const percentage = Math.round(series[seriesIndex][dataPointIndex]);
                            const votes = PollAnswersVotes[dataPointIndex];
                            return `<div class="custom-apex-chart-bar-graph-tooltip">
                                        <div class="custom-apex-chart-bar-graph-tooltip-label">${percentage}% ( ${votes ? votes : 0} ${votes > 1 ? 'Votes' : 'Vote'} )</div>
                                  </div>`;
                        }
                    }
                }
            }
            setPollAnswersChartConfig(config);
        } else if (type === 'Stars' || type === 'Emoji’s') {
            const ratingsLabels = [];
            const ratingsValues = [];
            for (let i = 1; i <= 5; i++) {
                const label = type === 'Stars' ? StarRatingMapping[i] : EmojiMapping[i];
                ratingsLabels.push(label);
                ratingsValues.push(answerStats[i]?.votePercentage || 0.2);
            }
            const series = [{
                data: ratingsValues
            }];
            const config: IChartConfig = {
                series: series,
                options: {
                    chart: {
                        type: 'bar',
                        width: "100%",
                        toolbar: {
                            show: false
                        },
                        fontFamily: 'Inter'
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                            barHeight: 40,
                            horizontal: true,
                            columnWidth: '55%',
                        }
                    },
                    colors: ["#0078F3"],
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: ratingsLabels,
                        max: 100,
                        min: 0,
                        tickAmount: 10,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            },
                            formatter(value: string): string | string[] {
                                return value + '%';
                            }
                        }
                    },
                    yaxis: {
                        forceNiceScale: false,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            },
                            // formatter(value: number): string | string[] {
                            //     return '<img src="https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5b51754c628cd32ca1bede4d/d57d4d52-7c5a-4e54-92e0-cfdf76f56444/48" alt="Label Image" width="20" height="20" />';
                            // }
                        }
                    },
                    grid: {
                        show: false
                    },
                    tooltip: {
                        custom: (e) => {
                            const {series, seriesIndex, dataPointIndex} = e;
                            const percentage = Math.round(series[seriesIndex][dataPointIndex]);
                            const votes = pollStats?.answerStats[dataPointIndex + 1]?.totalVotes;
                            return `<div class="custom-apex-chart-bar-graph-tooltip">
                                        <div class="custom-apex-chart-bar-graph-tooltip-label">${percentage}% ( ${votes ? votes : 0} ${votes > 1 ? 'Votes' : 'Vote'} )</div>
                                  </div>`;
                        }
                    }
                }
            }
            setPollAnswersChartConfig(config);
        } else if (type === 'Numerical (1-10)' || type === 'Percentage (0-100%)') {
            const isPercentage = type === 'Percentage (0-100%)';
            const votingLabels = [];
            const votingValues = [];
            for (let i = 1; i <= (isPercentage ? 100 : 10); i++) {
                votingLabels.push((isPercentage ? ((i === 1 || i % 10 === 0) ? i + '%' : '') : i));
                votingValues.push(answerStats[i]?.totalVotes || 0);
            }
            const series = [{
                data: votingValues
            }];
            const config: IChartConfig = {
                series: series,
                options: {
                    chart: {
                        type: 'bar',
                        width: "100%",
                        toolbar: {
                            show: false  // Hide the toolbar/menu icon
                        }
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 0,
                        }
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: ["#0078F3"],
                    xaxis: {
                        min: 1,
                        // render all the labels
                        categories: votingLabels,
                        // tickAmount: 10,
                        labels: {
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            },
                            hideOverlappingLabels: false
                        }
                    },
                    yaxis: {
                        min: 0,
                        max: votingValues.reduce((a, b) => Math.max(a, b)) + 1,
                        forceNiceScale: true,
                        labels: {
                            formatter: (val) => {
                                return Math.floor(+val) + ''; // Convert to integer
                            },
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Inter'
                            }
                        },
                    },
                    grid: {
                        show: false
                    },
                    tooltip: {
                        custom: (e) => {
                            const {dataPointIndex} = e;
                            const percentage = Math.round(answerStats[dataPointIndex + 1]?.votePercentage);
                            const votes = pollStats?.answerStats[dataPointIndex + 1]?.totalVotes;
                            return `<div class="custom-apex-chart-bar-graph-tooltip">
                                        <div class="custom-apex-chart-bar-graph-tooltip-label">${votes ? votes : 0} ${votes > 1 ? 'Votes' : 'Vote'} (${percentage}%)</div>
                                </div>`;
                        }
                    }
                }
            }
            setPollAnswersChartConfig(config);
        } else {
        }
    }, []);

    const getPollStats = useCallback((pollDetails: any, propertyIds: any = []) => {
        // setIsPollStatsLoading(true);
        const payload = {propertyIds: propertyIds};
        if (pollId) {
            CommonService._poll.getPollStatsAPICall(pollId, payload)
                .then((response: IAPIResponseType<any>) => {
                    const data = response?.data;
                    setPollStats(data);
                    // if(selectAll) {
                    //     getPollStats.bind(null, pollDetails, data?.propertyStats.map((item: any) => item._id))
                    // }
                    // setCheckedItems(data?.propertyStats.filter((property: any) => property.isSelected).map((property: any) => property._id));
                    // setIsPollStatsLoaded(true);
                    setIsPollStatsLoadingFailed(false);

                    transformPollAnswersCount(pollDetails, data);
                }).catch((error: any) => {
                setIsPollStatsLoaded(false);
                setIsPollStatsLoadingFailed(true);
            }).finally(() => {
                setIsPollStatsLoading(false);
            })
        }
    }, [pollId, transformPollAnswersCount]);

    const getPropertyStats = useCallback((pollData: any) => {
        const payload = {};
        setIsPollStatsLoading(true);
        if (pollId) {
            CommonService._poll.getPollStatsAPICall(pollId, payload)
                .then((response: IAPIResponseType<any>) => {
                    const data = response?.data;
                    getPollStats(pollData, data?.propertyStats.map((item: any) => item._id))
                    setCheckedItems(data?.propertyStats.map((item: any) => item._id))
                    setPropertyCount(data?.propertyStats.length);
                    setIsPollStatsLoaded(true);
                    setIsPollStatsLoadingFailed(false);
                }).catch((error: any) => {
            })
        }
    }, [getPollStats, pollId]);

    useEffect(() => {
        dispatch(setCurrentNavParams('Poll Details', null, () => {
            navigate(CommonService._routeConfig.PollsRoute());
        }));
    }, [dispatch, navigate]);

    const getPollDetails = useCallback(() => {
        setIsPollDetailsLoading(true);
        const payload = {};
        if (pollId) {
            CommonService._poll.GetPollDetailsAPICall(pollId, payload)
                .then((response: IAPIResponseType<any>) => {
                    setPollDetails(response?.data);
                    setIsPollDetailsLoaded(true);
                    setIsPollDetailsLoadingFailed(false);
                    getPropertyStats(response?.data);
                }).catch((error: any) => {
                setIsPollDetailsLoaded(false);
                setIsPollDetailsLoadingFailed(true);
            }).finally(() => {
                setIsPollDetailsLoading(false);
            })
        }
    }, [pollId, getPropertyStats])

    const handleCancelPollModal = useCallback(() => {
        setIsCancelPollModalOpen(false)
    }, []);

    useEffect((): void => {
        if (pollId) {
            getPollDetails();
        }
    }, [getPollDetails, pollId]);

    const onSubmit = useCallback((values: any) => {
        if (pollId) {
            CommonService._poll.CancelPollAPICall(pollId, {...values})
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setIsCancelPollModalOpen(false);
                    getPollDetails();
                }).catch((error: any) => {
                CommonService.handleErrors(error, error);
                setIsCancelPollModalOpen(false);
            })
        }
    }, [pollId, getPollDetails]);


    const handleCheckboxChange = useCallback((id: string) => {
        setCheckedItems((prevCheckedItems) => {
            const isChecked = prevCheckedItems.includes(id);
            if (isChecked) {
                setSelectAll(false);
                return prevCheckedItems.filter((item) => item !== id);
            } else {
                return [...prevCheckedItems, id];
            }
        });
    }, []);

    useEffect(() => {
        getPollStats(pollDetails, checkedItems);
    }, [getPollStats, checkedItems, pollDetails]);

    return (
        <div className={'poll-details-component'}>
            <>
                {
                    isPollDetailsLoading && <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                }
                {
                    isPollDetailsLoadingFailed &&
                    <div className="h-v-center">
                        <StatusCardComponent title={'Failed to Load'}/>
                    </div>
                }
                {
                    isPollDetailsLoaded && <div className="details-screen">
                        <div className={'details-screen-header'}>
                            <div className="details-screen-title-with-status">
                                <div className={'details-screen-title-wrapper'}>
                                    <div className={'details-screen-title'}>{pollDetails?.title}</div>
                                    <div className={'details-screen-description'}>
                                        {(pollDetails.status === 'Completed' || pollDetails.status === 'Cancelled') ?
                                            <TagComponent label={pollDetails.status} color='default'/>
                                            : <>
                                                {pollDetails.isOngoingPoll ?
                                                    <TagComponent label={pollDetails.status} color='success'/> :
                                                    <TagComponent
                                                        label={`Begins on ${moment(pollDetails.startDate + " " + pollDetails.startTime).format('DD-MM-YYYY | hh:mm A')}`}
                                                        color='primary'/>}
                                            </>}
                                    </div>
                                </div>
                            </div>
                            {!(pollDetails.status === 'Completed' || pollDetails.status === 'Cancelled' || pollDetails.status === 'Ongoing') &&
                                <div className="details-screen-options">
                                    <ButtonComponent
                                        color={'error'}
                                        onClick={() => {
                                            setIsCancelPollModalOpen(true)
                                        }}
                                        variant={'outlined'}>
                                        Cancel Poll
                                    </ButtonComponent>
                                    {!pollDetails.isOngoingPoll && <LinkComponent
                                        route={CommonService._routeConfig.EditPollsRoute() + `?pollId=${pollId}&step=poll-details`}>
                                        <ButtonComponent color={'primary'}>
                                            Edit Poll Details
                                        </ButtonComponent>
                                    </LinkComponent>}
                                </div>}
                        </div>
                        <div className="details-screen-body">
                            <div className='ts-row'>
                                <div className='ts-col-lg-8'>
                                    <CardComponent>
                                        <FormControlLabelComponent level={4}
                                                                   className='pdd-top-10 pdd-bottom-10'
                                                                   label={pollDetails.question} isBold={true}/>
                                        {
                                            isPollStatsLoading && <div className="h-v-center">
                                                <LoaderComponent type={'spinner'}/>
                                            </div>
                                        }
                                        {
                                            isPollStatsLoadingFailed &&
                                            <div className="h-v-center">
                                                <StatusCardComponent title={'Failed to Load'}/>
                                            </div>
                                        }
                                        {
                                            isPollStatsLoaded && <div className="poll-stats-wrapper">
                                                <HorizontalLineComponent className='mrg-bottom-20'/>
                                                {
                                                    pollDetails?.answerType === 'Single Select' &&
                                                    <div className='d-flex ts-justify-content-center'>
                                                        {/*{*/}
                                                        {/*    pollAnswersChartConfig?.series?.length > 0 &&*/}
                                                        <ReactApexChart options={pollAnswersChartConfig?.options}
                                                                        series={pollAnswersChartConfig?.series}
                                                                        type="pie"
                                                                        width={480}
                                                        />
                                                        {/*}*/}
                                                        {/*{*/}
                                                        {/*    pollAnswersChartConfig?.series?.length === 0 &&*/}
                                                        {/*    <div className="pdd-top-20 pdd-bottom-20">*/}
                                                        {/*        <div>No Votes</div>*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </div>
                                                }
                                                {
                                                    (pollDetails?.answerType !== 'Single Select') && <div>
                                                        <ReactApexChart options={pollAnswersChartConfig?.options}
                                                                        series={pollAnswersChartConfig?.series}
                                                                        type={"bar"}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </CardComponent>
                                </div>
                                <div className='ts-col-lg-4'>
                                    <CardComponent>
                                        <FormControlLabelComponent level={4} label={'Stats'} isBold={true}/>
                                        {
                                            isPollStatsLoading && <div className="h-v-center">
                                                <LoaderComponent type={'spinner'}/>
                                            </div>
                                        }
                                        {
                                            isPollStatsLoadingFailed &&
                                            <div className="h-v-center">
                                                <StatusCardComponent title={'Failed to Load'}/>
                                            </div>
                                        }
                                        {
                                            isPollStatsLoaded && <div className="poll-stats-wrapper">
                                                <div className='ts-row'>
                                                    <div className={'total-view-vote-button ts-col'}>
                                                        <div>{'Total Votes'}</div>
                                                        <div className="mrg-left-10">
                                                            <BadgeComponent variant='filled' color={'primary'}>
                                                                {pollStats.totalVotes || 0}
                                                            </BadgeComponent>
                                                        </div>
                                                    </div>
                                                    <div className={'total-view-vote-button ts-col'}>
                                                        <div>{'Total Views'}</div>
                                                        <div className="mrg-left-10">
                                                            <BadgeComponent variant='filled' color={'primary'}>
                                                                {pollStats.viewCount || 0}
                                                            </BadgeComponent>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={'property-stats-header-wrapper'}>
                                                        <div className="property-stats-header">Property Stats</div>
                                                        {/*<div className="property-stats-sub-header" onClick={() => {*/}
                                                        {/*    setCheckedItems([]);*/}
                                                        {/*}}>Clear All*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    {pollStats?.propertyStats.length ? <>
                                                            <div className='select-all-block'>
                                                                <CheckBoxComponent
                                                                    className={'property-name-checkbox'}
                                                                    label={'Select All'}
                                                                    checked={selectAll || propertyCount === checkedItems.length}
                                                                    onChange={(val) => {
                                                                        if (val) {
                                                                            setCheckedItems(pollStats?.propertyStats.map((item: any) => item._id));
                                                                            setSelectAll(val);
                                                                        } else {
                                                                            setCheckedItems([]);
                                                                            setSelectAll(val);
                                                                        }
                                                                    }}/>
                                                            </div>
                                                            {pollStats?.propertyStats.map((item: any, index: number) => {
                                                                return (
                                                                    <div className='property-name-count-block' key={index}>
                                                                        <CheckBoxComponent
                                                                            className={'property-name-checkbox'}
                                                                            label={item.name}
                                                                            checked={item.isSelected}
                                                                            onChange={() => {
                                                                                handleCheckboxChange(item._id)
                                                                            }}/>
                                                                        <div>
                                                                            <BadgeComponent color={'primary'}>
                                                                                {item?.count < 10 ? '0' + item?.count : item?.count}
                                                                            </BadgeComponent>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            } </> :
                                                        <div className='display-center pdd-top-20'> No Data Found</div>

                                                    }
                                                </div>
                                            </div>
                                        }
                                    </CardComponent>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </>
            <ModalComponent isOpen={isCancelPollModalOpen}
                            closeOnBackDropClick={true}
                            className={'cancel-poll-modal'}>
                <Formik initialValues={cancelPollFormInitialValue}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}>
                    {({values, isValid, touched, errors, setFieldValue, validateForm}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [values, validateForm]);
                        return (
                            <Form className={'t-form'} noValidate={true}>
                                <div className={'d-flex ts-justify-content-center mrg-bottom-40'}>
                                    <img src={ImageConfig.CancelIcon} alt={'CancelIcon'}/>

                                </div>
                                <div className={'cancel-poll-heading'}>
                                    Cancel Poll
                                </div>
                                <div className={'cancel-poll-sub-heading'}>
                                    Are you sure you want to cancel this poll?
                                </div>
                                <div className={'ts-row mrg-bottom-20'}>
                                    <div className={'ts-col-lg-12'}>
                                        <Field name={'reasonForCancellation'}>
                                            {
                                                (field: FieldProps) => (
                                                    <div className={'full-width-textarea'}>
                                                        <FormikTextAreaComponent
                                                            formikField={field}
                                                            label={''}
                                                            fullWidth={true}
                                                            required={true}
                                                            placeholder={'Reason for cancellation'}/>
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    </div>
                                </div>
                                <div className={'ts-action display-flex ts-justify-content-center'}>
                                    <ButtonComponent id={"cancel_btn"} variant={'outlined'}
                                                     fullWidth={true}
                                                     onClick={handleCancelPollModal}>
                                        Cancel
                                    </ButtonComponent>
                                    &nbsp;
                                    <ButtonComponent id={"save_btn"} variant={'contained'}
                                                     fullWidth={true}
                                                     color={'error'}
                                                     type={'submit'}>
                                        Confirm
                                    </ButtonComponent>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </ModalComponent>
        </div>
    );

};

export default PollDetailsComponent;
