import "./VisitorDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../constants";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import TagComponent from "../../../shared/components/tag/TagComponent";
import moment from "moment";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";

interface VisitorDetailsComponentProps {
    visitor: any;
    onClose: () => void;
    selectedTab: string;
    setRefreshToken?: any;
    onActionSuccess: () => void;
    hereToVisitType: (type: any) => void;
}

const VisitorDetailsComponent = (props: VisitorDetailsComponentProps) => {
    const {visitor, onClose, selectedTab, setRefreshToken, onActionSuccess, hereToVisitType} = props;
    const [visitorDetails, setVisitorDetails] = useState<any>(null);
    const [isVisitorDetailsLoading, setIsVisitorDetailsLoading] = useState<boolean>(false);
    const [isVisitorDetailsLoadingFailed, setIsVisitorDetailsLoadingFailed] = useState<boolean>(false);
    const [isVisitorDetailsLoaded, setIsVisitorDetailsLoaded] = useState<boolean>(false);

    const getVisitorDetails = useCallback(() => {
        setIsVisitorDetailsLoading(true);
        const payload = {};
        CommonService._visitor.getVisitorDetails(visitor?._id, payload)
            .then((response: IAPIResponseType<any>) => {
                setVisitorDetails(response.data);
                hereToVisitType(response.data.hereToVisitFor)
                setIsVisitorDetailsLoaded(true);
                setIsVisitorDetailsLoadingFailed(false);
            }).catch((error: any) => {
            setIsVisitorDetailsLoaded(false);
            setIsVisitorDetailsLoadingFailed(true);
        }).finally(() => {
            setIsVisitorDetailsLoading(false);
        });
    }, [visitor,hereToVisitType]);

    useEffect(() => {
        getVisitorDetails()
    }, [getVisitorDetails]);

    const handleCancelRequest = useCallback(() => {
        CommonService._visitor.handleCancelRequest(visitor?._id, {})
            .then((response) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setRefreshToken();
                onActionSuccess();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || "Error deleting provider", "error");
        })
    }, [visitor, setRefreshToken, onActionSuccess]);

    const handleCheckInRequest = useCallback(() => {
        CommonService._visitor.handleCheckInRequest(visitor?._id, {})
            .then((response) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setRefreshToken();
                onActionSuccess();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || "Error Check In provider", "error");
        })
    }, [visitor, setRefreshToken, onActionSuccess]);

    const handleCheckOutRequest = useCallback(() => {
        CommonService._visitor.handleCheckOutRequest(visitor?._id, {})
            .then((response) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setRefreshToken();
                onActionSuccess();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || "Error Check out Request", "error");
        })
    }, [visitor, setRefreshToken, onActionSuccess]);

    const handleApproveRequest = useCallback(() => {
        CommonService._visitor.handleApproveRequest(visitor?._id, {})
            .then((response) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setRefreshToken();
                onActionSuccess();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || "Error Approving Request", "error");
        })
    }, [visitor, setRefreshToken, onActionSuccess]);

    const handleRejectRequest = useCallback(() => {
        CommonService._visitor.handleRejectRequest(visitor?._id, {})
            .then((response) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setRefreshToken();
                onActionSuccess();
            }).catch((error: any) => {
            CommonService._alert.showToast(error.error || "Error Rejecting Request", "error");
        })
    }, [visitor, setRefreshToken, onActionSuccess]);

    const onCancelRequest = useCallback(() => {
        onClose();
        CommonService.onConfirm(
            {
                confirmationTitle: "Cancel Request",
                image: ImageConfig.CancelRequest,
                confirmationDescription: `Are you sure you want to cancel this guest’s visit request?`,
                no: {
                    color: "default",
                    text: "Cancel",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: "Confirm",
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            handleCancelRequest();
        });
    }, [handleCancelRequest, onClose]);

    const onCheckInRequest = useCallback(() => {
        onClose();
        CommonService.onConfirm(
            {
                confirmationTitle: "Check In Guest",
                image: ImageConfig.ApproveRequest,
                confirmationDescription: `Are you sure you want to check In this guest?`,
                no: {
                    color: "default",
                    text: "Cancel",
                    variant: "outlined"
                },
                yes: {
                    color: "primary",
                    text: "Confirm",
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            handleCheckInRequest();
        })
    }, [handleCheckInRequest, onClose]);

    const onApproveRequest = useCallback(() => {
        onClose();
        CommonService.onConfirm(
            {
                confirmationTitle: "Approve Request",
                image: ImageConfig.ApproveRequest,
                confirmationDescription: `Are you sure you want to Approve this guest?`,
                no: {
                    color: "default",
                    text: "Cancel",
                    variant: "outlined"
                },
                yes: {
                    color: "primary",
                    text: "Confirm",
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            handleApproveRequest();
        })
    }, [handleApproveRequest, onClose]);

    const onCheckOutRequest = useCallback(() => {
        onClose();
        CommonService.onConfirm(
            {
                confirmationTitle: "Check-out Guest",
                image: ImageConfig.CheckOutIcon,
                confirmationDescription: `Are you sure you want to checkOut this guest?`,
                no: {
                    color: "default",
                    text: "Cancel",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: "Confirm",
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            handleCheckOutRequest();
        })
    }, [onClose, handleCheckOutRequest])

    const onRejectRequest = useCallback(() => {
        onClose();
        CommonService.onConfirm(
            {
                confirmationTitle: "Reject Request",
                image: ImageConfig.CancelRequest,
                confirmationDescription: `Are you sure you want to reject this guest?`,
                no: {
                    color: "default",
                    text: "Cancel",
                    variant: "outlined"
                },
                yes: {
                    color: "error",
                    text: "Confirm",
                    variant: "contained"
                }
            }
        ).then((result: any) => {
            handleRejectRequest();
        });
    }, [onClose, handleRejectRequest])


    return (
        <div className={'visitor-details-view'}>
            {
                isVisitorDetailsLoading && <div className="h-v-center">
                    <LoaderComponent type={'spinner'}/>
                </div>
            }
            {
                isVisitorDetailsLoadingFailed &&
                <div className="h-v-center">
                    <StatusCardComponent title={'Failed to Load'}/>
                </div>
            }
            {
                isVisitorDetailsLoaded && <>
                    <HorizontalLineComponent/>
                    <div className="drawer-content">
                        <div className='ts-row'>
                            {((visitorDetails?.status && visitorDetails?.status === 'Cancelled') || (visitorDetails?.status && visitorDetails?.status === 'Rejected')) &&
                                <div className='visitor-status-wrapper'>
                                    <DataLabelValueComponent label={''}>
                                        <TagComponent
                                            className={"tag-component-wrapper"}
                                            color={CommonService.getStatusTagColor(visitorDetails?.status)}
                                            showIndicator={true}
                                            label={visitorDetails?.status}/>
                                    </DataLabelValueComponent>
                                </div>
                            }
                        </div>
                        <div className='ts-row mrg-top-20'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Guest Name'}>
                                    {
                                        visitorDetails?.name && (visitorDetails?.name).length > 30 ?
                                            <ToolTipComponent
                                                tooltip={<div>
                                                    {visitorDetails?.name || '-'}
                                                </div>}
                                                showArrow={true}
                                                position={"top"}
                                            >
                                                <div className={"ellipses-for-table-data"}>
                                                    {visitorDetails?.name}
                                                </div>
                                            </ToolTipComponent> :
                                            <div>
                                                {visitorDetails?.name || '-'}
                                            </div>
                                    }
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Visiting Organisation'}>
                                    <div className={"ellipses-for-table-data"}>
                                        {
                                            // (visitorDetails?.hereToVisitFor).length > 10 ?
                                                <ToolTipComponent
                                                    tooltip={visitorDetails?.hereToVisitFor === 'property' ? 'Phoenix' : visitorDetails?.tenantDetails?.name ? visitorDetails?.tenantDetails?.name : '-'}
                                                    showArrow={true}
                                                    position={"top"}
                                                    // textColor={'#fff'}
                                                >
                                                    <div className={"ellipses-for-table-data"}>
                                                        {visitorDetails?.hereToVisitFor === 'property' ? 'Phoenix' : visitorDetails?.tenantDetails?.name ? visitorDetails?.tenantDetails?.name : '-'}
                                                    </div>
                                                </ToolTipComponent>
                                            // :
                                            //     <div className="property-name">
                                            //         {visitorDetails?.hereToVisitFor === 'property' ? 'Phoenix' : visitorDetails?.tenantDetails?.name ? visitorDetails?.tenantDetails?.name : '-' || '-'}
                                            //     </div>
                                        }
                                        {/*{visitorDetails?.hereToVisitFor === 'property' ? 'Phoenix' : visitorDetails?.tenantDetails?.name ? visitorDetails?.tenantDetails?.name : '-'}*/}
                                    </div>
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Purpose of Visit'}>
                                    {
                                        visitorDetails?.purposeOfVisit && (visitorDetails?.purposeOfVisit).length > 30 ?
                                            <ToolTipComponent
                                                tooltip={<div>
                                                    {visitorDetails?.purposeOfVisit || '-'}
                                                </div>}
                                                showArrow={true}
                                                position={"top"}
                                            >
                                                <div className={"ellipses-for-table-data"}>
                                                    {visitorDetails?.purposeOfVisit}
                                                </div>
                                            </ToolTipComponent> :
                                            <div>
                                                {visitorDetails?.purposeOfVisit || '-'}
                                            </div>
                                    }
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Host Name'}>
                                    {
                                        visitorDetails?.hereToVisitName && (visitorDetails?.hereToVisitName).length > 30 ?
                                            <ToolTipComponent
                                                tooltip={<div>
                                                    {visitorDetails?.hereToVisitName || '-'}
                                                </div>}
                                                showArrow={true}
                                                position={"top"}
                                            >
                                                <div className={"ellipses-for-table-data"}>
                                                    {visitorDetails?.hereToVisitName}
                                                </div>
                                            </ToolTipComponent> :
                                            <div>
                                                {visitorDetails?.hereToVisitName || '-'}
                                            </div>
                                    }
                                </DataLabelValueComponent>
                            </div>
                        </div>
                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Guest Phone Number'}>
                                    {visitorDetails?.phone || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Guest Organization'}>
                                    {
                                        visitorDetails?.organization && (visitorDetails?.organization).length > 30 ?
                                            <ToolTipComponent
                                                tooltip={<div>
                                                    {visitorDetails?.organization || '-'}
                                                </div>}
                                                showArrow={true}
                                                position={"top"}
                                            >
                                                <div className={"ellipses-for-table-data"}>
                                                    {visitorDetails?.organization}
                                                </div>
                                            </ToolTipComponent> :
                                            <div>
                                                {visitorDetails?.organization || '-'}
                                            </div>
                                    }
                                </DataLabelValueComponent>
                            </div>
                        </div>

                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Guest Email ID'}>
                                    {visitorDetails?.email || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Parking'}>
                                    {visitorDetails?.includeParkingSpace ? 'Yes' : 'No'}
                                </DataLabelValueComponent>
                            </div>
                        </div>

                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Visit Date and Time'}>
                                    {(visitorDetails?.date + ' | ' + visitorDetails?.time) || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Property'}>
                                    {visitorDetails?.propertyDetails?.name || '-'}
                                </DataLabelValueComponent>
                            </div>
                        </div>

                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Tower'}>
                                    {(visitorDetails?.propertyTowerDetails?.name) || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Floor'}>
                                    {visitorDetails?.floorNo || '-'}
                                </DataLabelValueComponent>
                            </div>
                        </div>

                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Vehicle Numbers'}>
                                    <div className="d-flex-wrap">
                                        {visitorDetails?.vehicleNo?.length ? visitorDetails?.vehicleNo?.map((item: any, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        {item && <TagComponent label={CommonService.convertUpperCase(item)}
                                                                               color={'primary'}/>}
                                                    </div>
                                                )
                                            }
                                        ) : <div>-</div>}
                                    </div>
                                </DataLabelValueComponent>
                            </div>
                        </div>

                        <div className='ts-row'>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Check-in Time'}>
                                    {visitorDetails?.checkIn ? moment.utc(visitorDetails?.checkIn).format('DD-MM-YYYY | hh:mm A') : 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                            <div className='ts-col'>
                                <DataLabelValueComponent label={'Check-out Time'}>
                                    {visitorDetails?.checkOut ? moment.utc(visitorDetails?.checkOut).format('DD-MM-YYYY | hh:mm A') : 'N/A'}
                                </DataLabelValueComponent>
                            </div>
                        </div>

                    </div>
                    <HorizontalLineComponent/>
                    {visitorDetails?.hereToVisitFor === 'property' && <div className="drawer-actions">
                        {selectedTab === 'requests' && <>
                            <ButtonComponent
                                color={'error'}
                                variant={'outlined'}
                                fullWidth={true}
                                prefixIcon={<ImageConfig.CancelRequestButtonIcon/>}
                                onClick={onRejectRequest}>
                                Reject Request
                            </ButtonComponent>

                            <ButtonComponent
                                fullWidth={true}
                                color={'primary'}
                                variant={'contained'}
                                prefixIcon={<ImageConfig.ApproveRequestButtonIcon/>}
                                onClick={onApproveRequest}>
                                Approve Request
                            </ButtonComponent>
                        </>
                        }

                        {selectedTab !== 'requests' && <>
                            {selectedTab === 'scheduled' && visitorDetails?.status !== 'Cancelled' ? <>
                                <ButtonComponent
                                    color={'error'}
                                    variant={'outlined'}
                                    fullWidth={true}
                                    prefixIcon={<ImageConfig.CancelRequestButtonIcon/>}
                                    onClick={onCancelRequest}>
                                    Cancel Request
                                </ButtonComponent>

                                <ButtonComponent
                                    fullWidth={true}
                                    color={'primary'}
                                    variant={'contained'}
                                    prefixIcon={<ImageConfig.ApproveRequestButtonIcon/>}
                                    onClick={onCheckInRequest}>
                                    {visitorDetails?.date !== moment(visitorDetails?.checkIn).format('yyyy-mm-dd') ? 'Early Check In' : 'Check In'}
                                </ButtonComponent>
                            </> : <>
                                {
                                    visitorDetails?.status === 'Yet to Arrive' && <>
                                        <ButtonComponent
                                            color={'error'}
                                            variant={'outlined'}
                                            fullWidth={true}
                                            prefixIcon={<ImageConfig.CancelRequestButtonIcon/>}
                                            onClick={onCancelRequest}>
                                            Cancel Request
                                        </ButtonComponent>

                                        <ButtonComponent
                                            fullWidth={true}
                                            color={'primary'}
                                            variant={'contained'}
                                            prefixIcon={<ImageConfig.ApproveRequestButtonIcon/>}
                                            onClick={onCheckInRequest}>
                                            Check In
                                        </ButtonComponent>
                                    </>
                                }
                                {visitorDetails?.status === 'Checked In' && <>
                                    <ButtonComponent
                                        fullWidth={true}
                                        color={'error'}
                                        variant={'contained'}
                                        prefixIcon={<ImageConfig.ApproveRequestButtonIcon/>}
                                        onClick={onCheckOutRequest}>
                                        Check Out
                                    </ButtonComponent>
                                </>
                                }
                            </>
                            }
                        </>}
                    </div>}
                </>
            }
        </div>
    );

};

export default VisitorDetailsComponent;
