import './TenantTowerInfoComponent.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import * as Yup from 'yup';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import TowerDetailsComponent from '../../../../shared/components/tower-details/TowerDetailsComponent';
import LoaderComponent from '../../../../shared/components/loader/LoaderComponent';
import DrawerComponent from '../../../../shared/components/drawer/DrawerComponent';
import HorizontalLineComponent from '../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import ButtonGroupComponent from '../../../../shared/components/button-group/ButtonGroupComponent';
import TableComponent from '../../../../shared/components/table/TableComponent';
import { ITableColumn } from '../../../../shared/models/table.model';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import StatusCardComponent from '../../../../shared/components/status-card/StatusCardComponent';

interface EventPerformerInfoComponentProps {
  tenantDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  tenantId: string;
  activeStepId: string;
}

const tableType = [
  {
    title: 'Office Floors',
    id: 'officeFloor',
  },
  {
    title: 'Basements',
    id: 'basement',
  },
  {
    title: 'Hard Options',
    id: 'hardOption',
  },
];

const towerBreakdownSchema = Yup.object().shape({
  type: Yup.string().when('isDrawerOpen', {
    is: true,
    then: Yup.string().required('Type is required'),
    otherwise: Yup.string().notRequired(),
  }),
  number: Yup.string().when('isDrawerOpen', {
    is: true,
    then: Yup.string().required('Number is required').nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  occupiedArea: Yup.string().when('isDrawerOpen', {
    is: true,
    then: Yup.string()
      .test(
        'is-less-than-equal-to-vacant',
        'Occupied area must not exceed vacant area',
        function (value) {
          const vacantArea = this.parent.vacantArea;
          return (
            value !== undefined &&
            vacantArea !== undefined &&
            value <= vacantArea
          );
        },
      )
      .required('Occupied area is required')
      .min(1, 'Occupied area cannot be zero')
      .nullable(),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  isHardArea: Yup.string().when('isDrawerOpen', {
    is: true,
    then: Yup.string().required('Hard Option is required'),
    otherwise: Yup.string().notRequired(),
  }),
});

const validationSchema = Yup.object().shape({
  totalAvailableAreaInProperty: Yup.number(),
  towerBreakdown: Yup.array()
    .of(towerBreakdownSchema)
    .test(
      'tower-validations-if-selected',
      'Please select a tower if any tower is selected',
      function (value) {
        return value
          ? value.every((tower: any) => {
              if (tower?.isTowerSelected) {
                try {
                  towerBreakdownSchema.validateSync(tower);
                  return true;
                } catch (e) {
                  return false;
                }
              }
              return true;
            })
          : true;
      },
    )
    .test(
      'at-least-one-tower-selected',
      'At least one tower must be selected',
      function (value) {
        if (!value) return false;
        return value.some((tower: any) => tower.isTowerSelected);
      },
    )
    .test(
      'breakdown-length',
      'If a tower is selected, its breakdown must contain at least one entry',
      function (value) {
        if (!value) return true; // Skip if no towers
        return value.every((tower: any) => {
          if (tower.isTowerSelected) {
            return tower?.breakdown && tower?.breakdown?.length > 0;
          }
          return true; // Skip validation if tower is not selected
        });
      },
    ),
});

const initialValues = {
  totalAvailableAreaInProperty: '',
  towerBreakdown: [
    {
      towerId: '',
      isTowerSelected: false,
      isTotalFloorOccupied: false,
      totalArea: '',
      totalOccupiedArea: '',
      totalOccupiedAreaInTower: '',
      breakdown: [],
      type: '',
      number: '',
      title: '',
      vacantArea: '',
      occupiedArea: '',
      isHardArea: '',
      isDrawerOpen: false,
    },
  ],
  towerSelectedForDetails: '',
};

const TenantTowerInfoComponent = (props: EventPerformerInfoComponentProps) => {
  const { tenantDetails, prev, next, steps, tenantId, activeStepId } = props;
  const [towersInitialData, setTowersInitialData] =
    useState<any>(initialValues);
  const [propertyTowerDetails, setPropertyTowerDetails] = useState<any>([]);
  const [isTowersListLoading, setIsTowersListLoading] =
    useState<boolean>(false);
  const [openOccupancyDrawer, setOpenOccupanyDrawer] = useState<boolean>(false);
  const [selectedOccupanyTable, setSelectedOccupanyTable] = useState<
    'officeFloor' | 'basement' | 'hardOption' | any
  >('');
  const [towerList, setTowerList] = useState<any>([]);
  const [towerDetails, setTowerDetails] = useState<any>(null);
  const [towerOccupanyDetails, setTowerOccupanyDetails] = useState<any>([]);

  useEffect(() => {
    if (propertyTowerDetails) {
      const towers = propertyTowerDetails?.towers?.map((tower: any) => ({
        title: tower?.name,
        code: tower?._id,
      }));
      setTowerList(towers);
    }
  }, [propertyTowerDetails]);

  const handleInitialValuesConstruction = useCallback(
    (propertyTowers: any) => {
      const updatedTowers = propertyTowers?.towers?.map(
        (propertyTower: any) => {
          const tenantTower = tenantDetails?.towers?.towerBreakdown?.find(
            (tenantTower: any) => tenantTower?.towerId === propertyTower?._id,
          );
          const combinedBreakdown = [
            ...(tenantTower?.basementBreakdown || []),
            ...(tenantTower?.floorBreakdown || []),
            ...(tenantTower?.hardOptionBreakdown || []),
          ];
          if (tenantTower) {
            return {
              towerId: propertyTower?._id,
              name: propertyTower?.name,
              isTowerSelected: tenantTower?.isTowerSelected,
              isTotalTowerOccupied: tenantTower?.isTotalTowerOccupied,
              totalArea: tenantTower?.totalArea,
              totalLeasableArea: tenantTower?.totalLeasableArea,
              totalVacantArea: tenantTower?.totalVacantArea,
              floorList: propertyTower?.officeFloors,
              basementList: propertyTower?.basements,
              breakdown: combinedBreakdown?.map((item: any) => ({
                number: item.number,
                vacantArea: item.vacantArea,
                occupiedArea: item.occupiedArea,
                isHardArea: item.isHardArea,
                type: item.type,
                id: item.id,
                title: item.title,
              })),
              type: '',
              number: '',
              title: '',
              vacantArea: '',
              occupiedArea: '',
              isHardArea: '',
              isDrawerOpen: false,
            };
          } else {
            return {
              towerId: propertyTower?._id,
              name: propertyTower?.name,
              isTowerSelected: false,
              isTotalTowerOccupied: false,
              totalArea: propertyTower?.floorLeasableArea,
              totalLeasableArea: propertyTower?.floorLeasableArea,
              totalVacantArea: '',
              floorList: propertyTower?.officeFloors,
              basementList: propertyTower?.basements,
              breakdown: [],
              type: '',
              number: '',
              title: '',
              vacantArea: '',
              occupiedArea: '',
              isHardArea: '',
              isDrawerOpen: false,
            };
          }
        },
      );
      setTowersInitialData({
        totalAvailableAreaInProperty:
          propertyTowers?.totalAvailableAreaInProperty,
        towerBreakdown: updatedTowers,
      });
    },
    [tenantDetails],
  );

  const getTowerDetailsHandler = useCallback((propertyId: any) => {
    const payload = {};
    setIsTowersListLoading(true);
    CommonService._tenants
      .GetTowerDetailsAPICall(propertyId, payload)
      .then((response: IAPIResponseType<any>) => {
        const towers = response?.data;
        setPropertyTowerDetails(towers);
      })
      .catch((error: any) => {
        CommonService._alert.showToast(
          error[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      })
      .finally(() => {
        setIsTowersListLoading(false);
        // setSubmitting(false);
      });
  }, []);

  useEffect(() => {
    if (tenantDetails?.propertyId) {
      getTowerDetailsHandler(tenantDetails?.propertyId);
    }
  }, [getTowerDetailsHandler, tenantDetails?.propertyId]);

  useEffect(() => {
    if (tenantDetails) {
      handleInitialValuesConstruction(propertyTowerDetails);
    }
  }, [tenantDetails, propertyTowerDetails, handleInitialValuesConstruction]);

  const calculateTotalAvailableArea = useCallback((values: any) => {
    let totalPropertyAreaInAllTowers = values?.towerBreakdown?.reduce(
      (totalPropertyArea: any, tower: any) => {
        const floorArea = (tower?.floorList || []).reduce(
          (sum: number, floor: any) => sum + (floor.vacantArea || 0),
          0,
        );
        const basementArea = (tower?.basementList || []).reduce(
          (sum: number, basement: any) => sum + (basement.vacantArea || 0),
          0,
        );
        return totalPropertyArea + floorArea + basementArea;
      },
      0,
    );

    values?.towerBreakdown?.forEach((tower: any) => {
      if (tower.isTotalTowerOccupied) {
        totalPropertyAreaInAllTowers -= tower.totalArea;
      }
    });
    return totalPropertyAreaInAllTowers;
  }, []);

  const onSubmit = useCallback(
    (values: any, formikHelpers: FormikHelpers<any>) => {
      formikHelpers.setSubmitting(true);
      const payload = {
        totalAvailableAreaInProperty: calculateTotalAvailableArea(values),
        towerBreakdown: values?.towerBreakdown?.map((tower: any) => {
          const { floorList, basementList, breakdown, ...restTower } = tower;
          const floorBreakdown = (breakdown || [])
            .filter(
              (item: any) => item.type === 'officeFloor' && !item.isHardArea,
            )
            .map((item: any) => ({
              number: item.number,
              occupiedArea: item.occupiedArea,
              vacantArea: item.vacantArea,
              type: item.type,
              isHardArea: item.isHardArea,
              title: item.title,
              id: item.id,
            }));

          const basementBreakdown = (breakdown || [])
            .filter((item: any) => item.type === 'basement' && !item.isHardArea)
            .map((item: any) => ({
              number: item.number,
              occupiedArea: item.occupiedArea,
              vacantArea: item.vacantArea,
              type: item.type,
              isHardArea: item.isHardArea,
              title: item.title,
              id: item.id,
            }));

          const hardOptionBreakdown = (breakdown || [])
            .filter((item: any) => item.isHardArea)
            .map((item: any) => ({
              number: item.number,
              occupiedArea: item.occupiedArea,
              vacantArea: item.vacantArea,
              type: item.type,
              isHardArea: item.isHardArea,
              title: item.title,
              id: item.id,
            }));

          return {
            ...restTower,
            floorBreakdown,
            basementBreakdown,
            hardOptionBreakdown,
          };
        }),
        propertyId: tenantDetails?.propertyId,
      };

      CommonService._tenants
        .AddTowerDetailsAPICall(tenantId, payload)
        .then((response: IAPIResponseType<any>) => {
          next(response.data);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
    [tenantDetails, tenantId, next, calculateTotalAvailableArea],
  );

  const handleOccupancyDetailsColumn = useMemo<ITableColumn[]>(() => {
    return [
      {
        title:
          selectedOccupanyTable === 'officeFloor'
            ? 'Floor'
            : selectedOccupanyTable === 'basement'
            ? 'Basement'
            : selectedOccupanyTable === 'hardOption'
            ? 'Hard Option'
            : 'Floor',
        key: selectedOccupanyTable,
        dataIndex: selectedOccupanyTable,
        render: (item: any) => {
          return <div>{item.title}</div>;
        },
      },
      {
        title: 'Area Occupied',
        key: 'areaOccupied',
        dataIndex: 'areaOccupied',
        render: (item: any) => {
          return <div>{item.occupiedArea + ' sqft'}</div>;
        },
      },
    ];
  }, [selectedOccupanyTable]);

  const handleFilterData = useCallback((values: any, towerId: string) => {
    if (towerId) {
      const getTowerBreakdown = values?.towerBreakdown.find(
        (tower: any) => tower.towerId === towerId,
      );
      const breakdown = getTowerBreakdown?.breakdown.map((item: any) => ({
        type: item.type,
        number: item.number,
        occupiedArea: item.occupiedArea,
        isHardArea: item.isHardArea,
        title: item.title,
      }));
      setTowerDetails(breakdown);
    } else {
      setTowerDetails([]);
    }
  }, []);

  const handleTowerOccupanyDetails = useCallback(
    (type: string) => {
      let filterData: any = [];
      if (type === 'officeFloor') {
        filterData = towerDetails?.filter(
          (item: any) => item.type === type && !item.isHardArea,
        );
      }
      if (type === 'basement') {
        filterData = towerDetails?.filter(
          (item: any) => item.type === type && !item.isHardArea,
        );
      }
      if (type === 'hardOption') {
        filterData = towerDetails?.filter((item: any) => item.isHardArea);
      }
      setTowerOccupanyDetails(filterData);
    },
    [towerDetails],
  );

  const handleDrawerClose = useCallback((setFieldValue: any) => {
    setOpenOccupanyDrawer(false);
    setFieldValue(`towerSelectedForDetails`, '');
    setTowerOccupanyDetails([]);
    setSelectedOccupanyTable('');
  }, []);

  return (
    <div className={'tower-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={towersInitialData}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          isSubmitting,
          setFieldValue,
          touched,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                <ButtonComponent
                  variant={'text'}
                  color={'secondary'}
                  onClick={prev}
                  prefixIcon={<ImageConfig.ArrowLeftIcon />}
                >
                  Back
                </ButtonComponent>
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  type={'submit'}
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              {isTowersListLoading ? (
                <div className='h-v-center'>
                  <LoaderComponent type='spinner' />
                </div>
              ) : (
                <>
                  {values?.towerBreakdown?.length > 0 ? (
                    <div className='add-component-content'>
                      <div className='total-area-wrapper'>
                        <div className='total-area-occupied-wrapper'>
                          <div className='total-area-occupied-wrapper-title'>
                            Total area Available in the property
                          </div>

                          <div className='total-area-occupied-wrapper-value'>
                            {calculateTotalAvailableArea(values)}
                            &nbsp;sq.ft
                          </div>
                        </div>
                        <ButtonComponent
                          variant='outlined'
                          type='button'
                          onClick={() => setOpenOccupanyDrawer(true)}
                        >
                          Occupancy Overview
                        </ButtonComponent>
                      </div>
                      <div className='towers-wrapper'>
                        <FieldArray
                          name={'towerBreakdown'}
                          render={(arrayHelpers) => (
                            <div>
                              {values?.towerBreakdown?.map(
                                (tower: any, index: number) => (
                                  <TowerDetailsComponent
                                    key={index}
                                    index={index}
                                    setFieldValue={setFieldValue}
                                    tower={tower}
                                    type={'tenant'}
                                  />
                                ),
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  ) : (
                    <StatusCardComponent title='Please add towers in the selected property' />
                  )}
                </>
              )}
              <DrawerComponent
                isOpen={openOccupancyDrawer}
                closeOnBackDropClick={false}
                closeOnEsc={false}
                showClose
                onClose={handleDrawerClose.bind(null, setFieldValue)}
                className='drawer-medium-width'
                title='Occupancy Details'
              >
                <div>
                  <HorizontalLineComponent className='mrg-top-20' />
                  <div className='flex-wrapper'>
                    <Field name='towerSelectedForDetails'>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          formikField={field}
                          placeholder='Select Tower'
                          className='mrg-top-20'
                          options={towerList}
                          displayWith={(option: any) => option.title}
                          valueExtractor={(option: any) => option.code}
                          onUpdate={(value) => {
                            handleFilterData(values, value);
                          }}
                        />
                      )}
                    </Field>
                    <ButtonGroupComponent
                      buttons={tableType}
                      selected={selectedOccupanyTable}
                      onChange={(value: any) => {
                        setSelectedOccupanyTable(value);
                        handleTowerOccupanyDetails(value);
                      }}
                    />
                  </div>
                  <div className='mrg-top-10'>
                    <TableComponent
                      className={'occupany-table'}
                      data={towerOccupanyDetails}
                      columns={handleOccupancyDetailsColumn}
                      noDataText={
                        towerOccupanyDetails?.length === 0
                          ? 'No data'
                          : 'Select Tower'
                      }
                      autoHeight={true}
                    />
                  </div>
                </div>
              </DrawerComponent>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TenantTowerInfoComponent;
