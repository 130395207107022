import './AddTenantsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import TenantShiftTimingsComponent from './tenant-shift-timings/TenantShiftTimingsComponent';
import TenantDetailsComponent from './tenant-details/TenantDetailsComponent';
// import TenantGalleryComponent from "./tenant-gallery/TenantGalleryComponent";
import TenantTowerInfoComponent from './tenant-towers/TenantTowerInfoComponent';
import BillingPreferenceComponent from './billing-preference/BillingPreferenceComponent';

interface AddTenantsComponentProps {}

const TENANT_DETAILS = 'tenant-details';
const TOWER_DETAILS = 'tower-details';
const SHIFT_TIMINGS = 'shift-timings';
const BILLING_PREFERENCES = 'billing-preferences';

const STEPS = [
  {
    title: 'Tenant Details',
    id: TENANT_DETAILS,
  },
  {
    title: 'Tower Details',
    id: TOWER_DETAILS,
  },
  {
    title: 'Shift Timings',
    id: SHIFT_TIMINGS,
  },
  {
    title: 'Billing Preferences',
    id: BILLING_PREFERENCES,
  },
];

const AddTenantsComponent = (props: AddTenantsComponentProps) => {
  const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
  const params = useParams();
  const [tenantId, setTenantId] = useState<any>(params.tenantId);
  const [tenantDetails, setTenantDetails] = useState<any>(undefined);
  const [isTenantDetailsLoading, setIsTenantDetailsLoading] =
    useState<boolean>(false);
  const location: any = useLocation();
  const path = location.pathname;

  const updateSteps = useCallback(
    (currentStepIndex: number) => {
      setCurrentStep(steps[currentStepIndex]);
      searchParams.set('step', steps[currentStepIndex].id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, steps],
  );

  const getTenantDetails = useCallback(() => {
    const payload = {};
    setIsTenantDetailsLoading(true);
    CommonService._tenants
      .GetTenantsDetailsAPICall(tenantId, payload)
      .then((response: IAPIResponseType<any>) => {
        setTenantDetails(response?.data);
        setIsTenantDetailsLoading(false);
      })
      .catch((error: any) => {
        // setTenantDetails(tenantDetails);
        setIsTenantDetailsLoading(false);
      });
  }, [tenantId]);

  useEffect(() => {
    if (tenantId) {
      getTenantDetails();
    }
  }, [getTenantDetails, tenantId]);

  useEffect(() => {
    const step: any = searchParams.get('step');
    const tenantId = searchParams.get('tenantId');
    if (tenantId) setTenantId(tenantId);
    const stepIDS = steps.map((item) => item.id);
    let stepIndex: number = 0;
    if (stepIDS.indexOf(step) > -1) {
      if (step !== TENANT_DETAILS) {
        stepIndex = stepIDS.indexOf(step);
      } else {
        stepIndex = 0;
      }
    } else {
      stepIndex = 0;
    }
    setCurrentStep(steps[stepIndex]);
  }, [searchParams, steps]);

  const handlePrevious = useCallback(
    (currentStepIndex: number, currentStepId: string) => {
      if (
        [TOWER_DETAILS, SHIFT_TIMINGS, BILLING_PREFERENCES].includes(
          currentStepId,
        )
      ) {
        updateSteps(currentStepIndex);
      } else {
        // do nothing;
      }
    },
    [updateSteps],
  );

  const handleBack = useCallback(() => {
    if (path.includes('/tenant/add')) {
      navigate(CommonService._routeConfig.TenantsRoute());
    } else {
      navigate(CommonService._routeConfig.TenantDetails(tenantId));
    }
  }, [navigate, tenantId, path]);

  const handleNext = useCallback(
    (currentStepIndex: number, currentStepId: string, data?: any) => {
      switch (currentStepId) {
        case TENANT_DETAILS:
          if (data && data._id) {
            setTenantId(data?._id);
            searchParams.set('tenantId', data._id.toString());
            setSearchParams(searchParams);
            setTenantDetails((oldSate: any) => {
              const newState = {
                ...oldSate,
                name: data?.name,
                tenantCode: data?.tenantCode,
                isSezZone: data?.isSezZone,
                igst: data?.igst,
                propertyId: data?.propertyId,
                signupFlow: data?.signupFlow,
                domain: data?.domain,
                numberOfSlotsOccupied: data?.numberOfSlotsOccupied,
                image: data.image,
                placeOfService: data.placeOfService,
                isBillingAddressSameAsPlaceOfService:
                  data.isBillingAddressSameAsPlaceOfService,
                billingAddress: data.billingAddress,
                adminDetails: data.adminDetails,
              };
              return newState;
            });
          }
          getTenantDetails();
          updateSteps(currentStepIndex);
          break;

        case TOWER_DETAILS:
          if (data) {
            getTenantDetails();
            setTenantDetails((oldSate: any) => {
              return {
                ...oldSate,
                towers: data.towers,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case SHIFT_TIMINGS:
          if (data) {
            setTenantDetails((oldSate: any) => {
              return {
                ...oldSate,
                isSameEveryDay: data?.isSameEveryDay,
                timings: data?.timings,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case BILLING_PREFERENCES:
          getTenantDetails();
          handleBack();
          break;
        default:
          <></>;
      }
    },
    [updateSteps, handleBack, searchParams, getTenantDetails, setSearchParams],
  );

  const renderComponent = useCallback(() => {
    switch (currentStep && currentStep.id) {
      case TENANT_DETAILS:
        return (
          <TenantDetailsComponent
            next={(data: any) => handleNext(1, TENANT_DETAILS, data)}
            prev={handleBack}
            steps={steps}
            tenantId={tenantId}
            activeStepId={currentStep.id}
            tenantDetails={tenantDetails}
          />
        );
      case TOWER_DETAILS:
        return (
          <TenantTowerInfoComponent
            tenantDetails={tenantDetails}
            next={(data: any) => handleNext(2, TOWER_DETAILS, data)}
            prev={() => handlePrevious(0, TOWER_DETAILS)}
            steps={steps}
            tenantId={tenantId}
            activeStepId={currentStep.id}
          />
        );
      case SHIFT_TIMINGS:
        return (
          <TenantShiftTimingsComponent
            tenantDetails={tenantDetails}
            next={(data) => handleNext(3, SHIFT_TIMINGS, data)}
            prev={() => handlePrevious(1, SHIFT_TIMINGS)}
            steps={steps}
            tenantId={tenantId}
            activeStepId={currentStep.id}
          />
        );
      case BILLING_PREFERENCES:
        return (
          <BillingPreferenceComponent
            tenantDetails={tenantDetails}
            next={() => handleBack()}
            prev={() => handlePrevious(2, BILLING_PREFERENCES)}
            steps={steps}
            activeStepId={currentStep.id}
          />
        );
      default:
        <></>;
    }
  }, [
    currentStep,
    handleNext,
    handlePrevious,
    tenantDetails,
    handleBack,
    steps,
    tenantId,
  ]);

  return (
    <div className={'add-polls-component add-screen'}>
      {isTenantDetailsLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      ) : (
        <>{renderComponent()}</>
      )}
    </div>
  );
};

export default AddTenantsComponent;
