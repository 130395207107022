import "./RadioButtonGroupComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {IRadioButtonGroupProps} from "../../../models/form-controls.model";
import LabelComponent from "../../label/LabelComponent";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import RadioButtonComponent from "../radio-button/RadioButton";

interface RadioButtonGroupComponentProps extends IRadioButtonGroupProps {
    value?: any;
    errorMessage?: any;
    hasError?: boolean;
    disabledOptions?: any[]; // Add new prop for disabled options
}

const RadioButtonGroupComponent = (props: RadioButtonGroupComponentProps) => {

    let {
        options,
        name,
        label,
        className,
        hasError,
        errorMessage,
        onChange,
        disabled,
        id,
        required,
        disabledOptions,
    } = props;
    const [value, setValue] = useState(props.value);

    const defaultDisplayWith = useCallback((item: any) => item?.title || '', []);
    const defaultValueExtractor = useCallback((item: any) => item?.code, []);
    const defaultKeyExtractor = useCallback((item: any) => item?._id, []);
    const displayWith = props.displayWith || defaultDisplayWith;
    const valueExtractor = props.valueExtractor || defaultValueExtractor;
    const keyExtractor = props.keyExtractor || defaultKeyExtractor;
    const direction = props.direction || "row";

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    return (<div className={`radio-group-component ${className} ${direction}`}>
            {label && <LabelComponent title={label} required={required}/>}
            <div className={`radio-option-list`}>
                {
                    (options && options?.length > 0) && options.map((option: any) => {
                        const isOptionDisabled = disabledOptions && disabledOptions.includes(valueExtractor(option)); // Check if option should be disabled
                        return <RadioButtonComponent
                            key={keyExtractor(option)}
                            value={valueExtractor(option)}
                            checked={valueExtractor(option) === value}
                            required={required}
                            hasError={hasError}
                            id={id}
                            name={name}
                            disabled={disabled || isOptionDisabled}
                            label={displayWith(option)}
                            onChange={(value: any) => {
                                let selectedValue: any = value;
                                if (typeof valueExtractor(option) === "boolean") {
                                    selectedValue = selectedValue === 'true';
                                }
                                if (onChange) {
                                    onChange(selectedValue);
                                }
                            }}
                        />
                    })
                }
            </div>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    )
}
export default RadioButtonGroupComponent;
