import moment from 'moment';
import React, { useMemo, useState } from 'react';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { CommonService } from '../../../shared/services';
import { APIConfig, ImageConfig } from '../../../constants';
import { ITableColumn } from '../../../shared/models/table.model';
import TagComponent from '../../../shared/components/tag/TagComponent';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import CheckBoxComponent from '../../../shared/components/form-controls/check-box/CheckBoxComponent';

interface DebitNoteListComponentProps {
  selectedTab: string;
  filterData: any;
  selectedDebitNoteIds: any[];
  debitNoteList: any[];
  handleMultipleDebitNoteSelect: () => void;
  handleSingleDebitNoteSelect: (debitNoteId: string) => void;
  handlePageNumberChange: (pageNumber: number) => void;
}

const DebitNoteListComponent = (props: DebitNoteListComponentProps) => {
  const {
    selectedTab,
    filterData,
    selectedDebitNoteIds,
    debitNoteList,
    handleMultipleDebitNoteSelect,
    handleSingleDebitNoteSelect,
    handlePageNumberChange,
  } = props;
  const [refreshToken] = useState<number>(Math.random() * 10);

  const ApprovedDebitNoteListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: (
          <div className='mrg-top-10'>
            <CheckBoxComponent
              onChange={handleMultipleDebitNoteSelect}
              checked={
                debitNoteList?.length > 0
                  ? selectedDebitNoteIds?.length === debitNoteList?.length
                  : false
              }
            />
          </div>
        ),
        key: 'checkbox',
        dataIndex: 'checkbox',
        width: 80,
        render: (item: any) => {
          if (item?._id) {
            return (
              <div className='mrg-top-10'>
                <CheckBoxComponent
                  checked={selectedDebitNoteIds.includes(item?._id)}
                  onChange={() => handleSingleDebitNoteSelect(item?._id)}
                />
              </div>
            );
          }
        },
      },
      {
        title: 'Debit Note No',
        key: 'debitNoteNo',
        dataIndex: 'debitNoteNo',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.gstData?.DocDtls?.No ? (
                <div>{item?.gstData?.DocDtls?.No}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title:
          filterData?.billingTo === 'amenity' ? 'Amenity Name' : 'Tenant Name',
        key: 'tenantName',
        dataIndex: 'tenantName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {filterData?.billingTo === 'tenant' ? (
                <div className='invoice-text-wrapper'>
                  {item?.tenant?.name.length > 25
                    ? item?.tenant?.name.slice(0, 25) + '...'
                    : item?.tenant?.name ?? '--'}
                </div>
              ) : (
                <div className='invoice-text-wrapper'>
                  {item?.amenity?.name.length > 25
                    ? item?.amenity?.name.slice(0, 25) + '...'
                    : item?.amenity?.name ?? '--'}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'propertyName',
        dataIndex: 'propertyName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.property?.name ? (
                <div className='invoice-text-wrapper'>
                  {item?.property?.name.length > 25
                    ? item?.property?.name.slice(0, 25) + '...'
                    : item?.property?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Name',
        key: 'invoiceName',
        dataIndex: 'invoiceName',
        width: '150',
        render: (item: any) => {
          const invoiceName = item?.gstData?.ItemList[0]?.PrdDesc;
          return (
            <div>
              {item?.gstData?.ItemList[0]?.PrdDesc ? (
                <div className='invoice-text-wrapper'>
                  {invoiceName.length > 15 ? (
                    <div>
                      <ToolTipComponent
                        position={'right'}
                        textColor={'#FFFFFF'}
                        tooltip={<>{invoiceName}</>}
                      >
                        <div className='d-flex'>
                          <ChipComponent
                            className={'scheduled cursor-pointer'}
                            size={'small'}
                            color={'primary'}
                            label={invoiceName.slice(0, 18)}
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  ) : (
                    invoiceName
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Generated On',
        key: 'invoiceGeneratedOn',
        dataIndex: 'invoiceGeneratedOn',
        width: '200',
        render: (item: any) => {
          return (
            <div>
              {item?.generatedDate ? (
                <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Approved On',
        key: 'approvedOn',
        dataIndex: 'approvedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.approvedDate ? (
                <div>{moment(item.approvedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Due Date',
        key: 'invoicedueDate',
        dataIndex: 'invoicedueDate',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoiceDueDate ? (
                <div>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.totalAmount ? (
                <div>{item?.totalAmount}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'actions',
        align: 'center',
        dataIndex: 'actions',
        width: '80',
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.DebitNoteDetailsRoute(
                  item?._id,
                )}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, [
    filterData,
    handleMultipleDebitNoteSelect,
    handleSingleDebitNoteSelect,
    selectedDebitNoteIds,
    debitNoteList,
  ]);

  const SentToClientDebitNoteListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Debit Note No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.gstData?.DocDtls?.No ? (
                <div>{item?.gstData?.DocDtls?.No}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Payment Status',
        key: 'paymentStatus',
        dataIndex: 'paymentStatus',
        width: '120',
        render: (item: any) => {
          return (
            <div>
              {item?.paymentStatus ? (
                <TagComponent
                  customBg={
                    item?.paymentStatus === 'paid' ? '#D7FFD3' : '#FBFAE5'
                  }
                  customTextColor={'#636363'}
                  showIndicator={true}
                  label={item?.paymentStatus}
                  className={'mrg-top-10'}
                />
              ) : (
                <>--</>
              )}
            </div>
          );
        },
      },
      {
        title:
          filterData?.billingTo === 'amenity' ? 'Amenity Name' : 'Tenant Name',
        key: 'tenantName',
        dataIndex: 'tenantName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {filterData?.billingTo === 'tenant' ? (
                <div className='invoice-text-wrapper'>
                  {item?.tenant?.name.length > 25
                    ? item?.tenant?.name.slice(0, 25) + '...'
                    : item?.tenant?.name ?? '--'}
                </div>
              ) : (
                <div className='invoice-text-wrapper'>
                  {item?.amenity?.name.length > 25
                    ? item?.amenity?.name.slice(0, 25) + '...'
                    : item?.amenity?.name ?? '--'}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'propertyName',
        dataIndex: 'propertyName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.property?.name ? (
                <div className='invoice-text-wrapper'>
                  {item?.property?.name.length > 25
                    ? item?.property?.name.slice(0, 25) + '...'
                    : item?.property?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Name',
        key: 'invoiceName',
        dataIndex: 'invoiceName',
        width: '150',
        render: (item: any) => {
          const invoiceName = item?.gstData?.ItemList[0]?.PrdDesc;
          return (
            <div>
              {item?.gstData?.ItemList[0]?.PrdDesc ? (
                <div className='invoice-text-wrapper'>
                  {invoiceName.length > 15 ? (
                    <div>
                      <ToolTipComponent
                        position={'right'}
                        textColor={'#FFFFFF'}
                        tooltip={<>{invoiceName}</>}
                      >
                        <div className='d-flex'>
                          <ChipComponent
                            className={'scheduled cursor-pointer'}
                            size={'small'}
                            color={'primary'}
                            label={invoiceName.slice(0, 18)}
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  ) : (
                    invoiceName
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Generated On',
        key: 'invoiceGeneratedOn',
        dataIndex: 'invoiceGeneratedOn',
        width: '200',
        render: (item: any) => {
          return (
            <div>
              {item?.generatedDate ? (
                <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Approved On',
        key: 'approvedOn',
        dataIndex: 'approvedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.approvedDate ? (
                <div>{moment(item.approvedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Due Date',
        key: 'invoicedueDate',
        dataIndex: 'invoicedueDate',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoiceDueDate ? (
                <div>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.totalAmount ? (
                <div>{item?.totalAmount}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Paid On',
        key: 'invoicePaidOn',
        dataIndex: 'invoicePaidOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoicePaidDate ? (
                <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'actions',
        align: 'center',
        dataIndex: 'actions',
        width: '80',
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.DebitNoteDetailsRoute(
                  item?._id,
                )}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, [filterData]);

  const OverdueDebitNoteListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Debit Note No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.gstData?.DocDtls?.No ? (
                <div>{item?.gstData?.DocDtls?.No}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title:
          filterData?.billingTo === 'amenity' ? 'Amenity Name' : 'Tenant Name',
        key: 'tenantName',
        dataIndex: 'tenantName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {filterData?.billingTo === 'tenant' ? (
                <div className='invoice-text-wrapper'>
                  {item?.tenant?.name.length > 25
                    ? item?.tenant?.name.slice(0, 25) + '...'
                    : item?.tenant?.name ?? '--'}
                </div>
              ) : (
                <div className='invoice-text-wrapper'>
                  {item?.amenity?.name.length > 25
                    ? item?.amenity?.name.slice(0, 25) + '...'
                    : item?.amenity?.name ?? '--'}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'propertyName',
        dataIndex: 'propertyName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.property?.name ? (
                <div className='invoice-text-wrapper'>
                  {item?.property?.name.length > 25
                    ? item?.property?.name.slice(0, 25) + '...'
                    : item?.property?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Name',
        key: 'invoiceName',
        dataIndex: 'invoiceName',
        width: '150',
        render: (item: any) => {
          const invoiceName = item?.gstData?.ItemList[0]?.PrdDesc;
          return (
            <div>
              {item?.gstData?.ItemList[0]?.PrdDesc ? (
                <div className='invoice-text-wrapper'>
                  {invoiceName.length > 15 ? (
                    <div>
                      <ToolTipComponent
                        position={'right'}
                        textColor={'#FFFFFF'}
                        tooltip={<>{invoiceName}</>}
                      >
                        <div className='d-flex'>
                          <ChipComponent
                            className={'scheduled cursor-pointer'}
                            size={'small'}
                            color={'primary'}
                            label={invoiceName.slice(0, 18)}
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  ) : (
                    invoiceName
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Generated On',
        key: 'invoiceGeneratedOn',
        dataIndex: 'invoiceGeneratedOn',
        width: '200',
        render: (item: any) => {
          return (
            <div>
              {item?.generatedDate ? (
                <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Approved On',
        key: 'approvedOn',
        dataIndex: 'approvedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.approvedDate ? (
                <div>{moment(item.approvedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Due Date',
        key: 'invoicedueDate',
        dataIndex: 'invoicedueDate',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoiceDueDate ? (
                <div>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.totalAmount ? (
                <div>{item?.totalAmount}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Paid On',
        key: 'invoicePaidOn',
        dataIndex: 'invoicePaidOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoicePaidDate ? (
                <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Payment Status',
        key: 'paymentStatus',
        dataIndex: 'paymentStatus',
        width: '120',
        render: (item: any) => {
          return (
            <div>
              {item?.paymentStatus ? (
                <TagComponent
                  customBg={
                    item?.paymentStatus === 'paid'
                      ? '#D7FFD3'
                      : item?.paymentStatus === 'pending'
                      ? '#FBFAE5'
                      : item?.paymentStatus === 'cancelled'
                      ? '#FBE5E5'
                      : ''
                  }
                  customTextColor={'#636363'}
                  showIndicator={true}
                  label={item?.paymentStatus}
                  className={'mrg-top-10'}
                />
              ) : (
                <>--</>
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'actions',
        align: 'center',
        dataIndex: 'actions',
        width: '80',
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.DebitNoteDetailsRoute(
                  item?._id,
                )}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, [filterData]);

  const CancelledDebitNoteListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Debit Note No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.gstData?.DocDtls?.No ? (
                <div>{item?.gstData?.DocDtls?.No}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title:
          filterData?.billingTo === 'amenity' ? 'Amenity Name' : 'Tenant Name',
        key: 'tenantName',
        dataIndex: 'tenantName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {filterData?.billingTo === 'tenant' ? (
                <div className='invoice-text-wrapper'>
                  {item?.tenant?.name.length > 25
                    ? item?.tenant?.name.slice(0, 25) + '...'
                    : item?.tenant?.name ?? '--'}
                </div>
              ) : (
                <div className='invoice-text-wrapper'>
                  {item?.amenity?.name.length > 25
                    ? item?.amenity?.name.slice(0, 25) + '...'
                    : item?.amenity?.name ?? '--'}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'propertyName',
        dataIndex: 'propertyName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.property?.name ? (
                <div className='invoice-text-wrapper'>
                  {item?.property?.name.length > 25
                    ? item?.property?.name.slice(0, 25) + '...'
                    : item?.property?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Name',
        key: 'invoiceName',
        dataIndex: 'invoiceName',
        width: '150',
        render: (item: any) => {
          const invoiceName = item?.gstData?.ItemList[0]?.PrdDesc;
          return (
            <div>
              {item?.gstData?.ItemList[0]?.PrdDesc ? (
                <div className='invoice-text-wrapper'>
                  {invoiceName.length > 15 ? (
                    <div>
                      <ToolTipComponent
                        position={'right'}
                        textColor={'#FFFFFF'}
                        tooltip={<>{invoiceName}</>}
                      >
                        <div className='d-flex'>
                          <ChipComponent
                            className={'scheduled cursor-pointer'}
                            size={'small'}
                            color={'primary'}
                            label={invoiceName.slice(0, 18)}
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  ) : (
                    invoiceName
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Generated On',
        key: 'invoiceGeneratedOn',
        dataIndex: 'invoiceGeneratedOn',
        width: '200',
        render: (item: any) => {
          return (
            <div>
              {item?.generatedDate ? (
                <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Approved On',
        key: 'approvedOn',
        dataIndex: 'approvedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.approvedDate ? (
                <div>{moment(item.approvedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Due Date',
        key: 'invoicedueDate',
        dataIndex: 'invoicedueDate',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoiceDueDate ? (
                <div>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Paid On',
        key: 'invoicePaidOn',
        dataIndex: 'invoicePaidOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoicePaidDate ? (
                <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'actions',
        align: 'center',
        dataIndex: 'actions',
        width: '80',
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.DebitNoteDetailsRoute(
                  item?._id,
                )}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, [filterData]);

  const HistoryListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Debit Note No',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.gstData?.DocDtls?.No ? (
                <div>{item?.gstData?.DocDtls?.No}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title:
          filterData?.billingTo === 'amenity' ? 'Amenity Name' : 'Tenant Name',
        key: 'tenantName',
        dataIndex: 'tenantName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {filterData?.billingTo === 'tenant' ? (
                <div className='invoice-text-wrapper'>
                  {item?.tenant?.name.length > 25
                    ? item?.tenant?.name.slice(0, 25) + '...'
                    : item?.tenant?.name ?? '--'}
                </div>
              ) : (
                <div className='invoice-text-wrapper'>
                  {item?.amenity?.name.length > 25
                    ? item?.amenity?.name.slice(0, 25) + '...'
                    : item?.amenity?.name ?? '--'}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'propertyName',
        dataIndex: 'propertyName',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.property?.name ? (
                <div className='invoice-text-wrapper'>
                  {item?.property?.name.length > 25
                    ? item?.property?.name.slice(0, 25) + '...'
                    : item?.property?.name}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Name',
        key: 'invoiceName',
        dataIndex: 'invoiceName',
        width: '150',
        render: (item: any) => {
          const invoiceName = item?.gstData?.ItemList[0]?.PrdDesc;
          return (
            <div>
              {item?.gstData?.ItemList[0]?.PrdDesc ? (
                <div className='invoice-text-wrapper'>
                  {invoiceName.length > 15 ? (
                    <div>
                      <ToolTipComponent
                        position={'right'}
                        textColor={'#FFFFFF'}
                        tooltip={<>{invoiceName}</>}
                      >
                        <div className='d-flex'>
                          <ChipComponent
                            className={'scheduled cursor-pointer'}
                            size={'small'}
                            color={'primary'}
                            label={invoiceName.slice(0, 18)}
                          />
                        </div>
                      </ToolTipComponent>
                    </div>
                  ) : (
                    invoiceName
                  )}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Generated On',
        key: 'invoiceGeneratedOn',
        dataIndex: 'invoiceGeneratedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.generatedDate ? (
                <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Approved On',
        key: 'approvedOn',
        dataIndex: 'approvedOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.approvedDate ? (
                <div>{moment(item.approvedDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Due Date',
        key: 'invoicedueDate',
        dataIndex: 'invoicedueDate',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoiceDueDate ? (
                <div>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Total Amount',
        key: 'totalAmount',
        dataIndex: 'totalAmount',
        width: '180',
        render: (item: any) => {
          return (
            <div>
              {item?.totalAmount ? (
                <div>{item?.totalAmount}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Debit Note Paid On',
        key: 'invoicePaidOn',
        dataIndex: 'invoicePaidOn',
        width: '150',
        render: (item: any) => {
          return (
            <div>
              {item?.invoicePaidDate ? (
                <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: '120',
        align: 'center',
        render: (item: any) => {
          return (
            <div>
              {item?.paymentStatus ? (
                <TagComponent
                  customBg={
                    item?.status === 'cancelled'
                      ? '#FBE5E5'
                      : item?.paymentStatus === 'paid'
                      ? '#D7FFD3'
                      : item?.paymentStatus === 'pending'
                      ? '#FBFAE5'
                      : ''
                  }
                  customTextColor={'#636363'}
                  showIndicator={true}
                  label={
                    item?.status === 'cancelled'
                      ? 'Cancelled'
                      : item?.paymentStatus
                  }
                />
              ) : (
                <>--</>
              )}
            </div>
          );
        },
      },
      {
        title: 'Action',
        key: 'actions',
        align: 'center',
        dataIndex: 'actions',
        width: '80',
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <LinkComponent
                route={CommonService._routeConfig.InvoiceDetailsRoute(
                  item?._id,
                )}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
            );
          }
        },
      },
    ];
  }, [filterData]);
  return (
    <div className='invoices-list-component'>
      <div className='list-table-wrapper'>
        <TableWrapperComponent
          url={
            selectedTab === 'history'
              ? APIConfig.DEBITNOTE_HISTORY.URL
              : APIConfig.DEBITNOTE_LIST.URL
          }
          method={
            selectedTab === 'history'
              ? APIConfig.DEBITNOTE_HISTORY.METHOD
              : APIConfig.DEBITNOTE_LIST.METHOD
          }
          id={'invoices-list'}
          isPaginated={true}
          refreshToken={refreshToken}
          extraPayload={filterData}
          handlePageNumber={handlePageNumberChange}
          columns={
            selectedTab === 'approved'
              ? ApprovedDebitNoteListTableColumns
              : selectedTab === 'sent_to_client'
              ? SentToClientDebitNoteListTableColumns
              : selectedTab === 'overdue'
              ? OverdueDebitNoteListTableColumns
              : selectedTab === 'cancelled'
              ? CancelledDebitNoteListTableColumns
              : selectedTab === 'history'
              ? HistoryListTableColumns
              : []
          }
          noDataText={
            filterData?.search
              ? 'No such Debit note exist'
              : filterData?.propertyIds
              ? 'No Debit note available for selected Property(s)'
              : filterData?.tenantIds
              ? 'No Debit note available for selected Tenant(s)'
              : filterData?.amenityIds
              ? 'No Debit note available for selected Amenity(s)'
              : filterData?.paymentStatus
              ? 'No Debit note available for selected status'
              : filterData?.invoiceType
              ? 'No Debit note available for selected invoice type'
              : filterData?.startDate && filterData?.dateFilterOn
              ? 'No Debit note available for selected filter'
              : 'No Debit note as of now'
          }
        />
      </div>
    </div>
  );
};

export default DebitNoteListComponent;
