import "./UserBulkUploadScreen.scss";
import * as Yup from "yup";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../constants";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import FormControlLabelComponent from "../../../shared/components/form-control-label/FormControlLabelComponent";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";
import FilePreviewThumbnailComponent
    from "../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent";
import ErrorComponent from "../../../shared/components/error/ErrorComponent";
// @ts-ignore
import userCSV from "../../../assets/templates/user_bulk_upload.csv";
import {useNavigate} from "react-router-dom";

interface IUserBulkUploadFormValues {
    propertyId: string;
    file: any;
}

const UserBulkUploadFormValidationSchema = Yup.object({
    propertyId: Yup.string().required("Property is required"),
    file: Yup.mixed().required()
});

const UserBulkUploadFormInitialValues: IUserBulkUploadFormValues = {
    propertyId: "",
    file: undefined
}

interface UserBulkUploadScreenProps {

}

const UserBulkUploadScreen = (props: UserBulkUploadScreenProps) => {

    const navigate = useNavigate();
    const {propertyList} = useSelector((state: IRootReducerState) => state.property);
    const [userBulkUploadFormInitialValues] = useState<IUserBulkUploadFormValues>(_.cloneDeep(UserBulkUploadFormInitialValues));
    const [isUserBulkUploadingInProgress, setIsUserBulkUploadingInProgress] = useState(false);

    const onSubmit = useCallback((values: any, {setErrors}: FormikHelpers<any>) => {
        setIsUserBulkUploadingInProgress(true);
        const formData = CommonService.getFormDataFromJSON(values);
        CommonService._user.bulkUploadUsersAPICall(formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                navigate(CommonService._routeConfig.UserListRoute());
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setIsUserBulkUploadingInProgress(false);
        });
    }, [navigate]);

    const handleUserBulkUploadTemplateDownload = useCallback(() => {
        CommonService.downloadFile(userCSV, 'users-template.csv', 'csv');
    }, []);

    const handleBack = useCallback(() => {
        navigate(CommonService._routeConfig.UserListRoute());
    }, [navigate]);

    return (
        <div className={'t-form-screen user-bulk-upload-screen'}>
            <Formik
                validationSchema={UserBulkUploadFormValidationSchema}
                initialValues={userBulkUploadFormInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, validateForm, isValid, touched, errors, setFieldValue}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form" noValidate={true}>
                            <div className="t-form-header user-bulk-upload-screen-header">
                                <div className="t-form-header-left">
                                    <ButtonComponent
                                        variant={'text'}
                                        color={'secondary'}
                                        onClick={handleBack}
                                        prefixIcon={<ImageConfig.ArrowLeftIcon/>}
                                    >
                                        Exit
                                    </ButtonComponent>
                                </div>
                                <div className="t-form-header-right">
                                    <ButtonComponent
                                        variant={'outlined'}
                                        prefixIcon={<ImageConfig.DownloadIcon/>}
                                        onClick={handleUserBulkUploadTemplateDownload}>
                                        Download Bulk Upload Template
                                    </ButtonComponent>
                                    <ButtonComponent
                                        type={"submit"}
                                        isLoading={isUserBulkUploadingInProgress}
                                        disabled={isUserBulkUploadingInProgress || !isValid}
                                    >
                                        Save
                                    </ButtonComponent>
                                </div>
                            </div>
                            <div className="t-form-content user-bulk-upload-screen-content">
                                <Field name={'propertyId'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikSelectDropdownComponent
                                                options={propertyList}
                                                displayWith={(option: any) => option.name}
                                                valueExtractor={(option: any) => option._id}
                                                label={'Property'}
                                                placeholder={'Select Property'}
                                                required={true}
                                                formikField={field}
                                                fullWidth={true}
                                            />
                                        )
                                    }
                                </Field>
                                <div>
                                    <FormControlLabelComponent label={"Upload Document"} level={4} isBold={true}/>
                                    <>
                                        {
                                            (!values.file) && <>
                                                <FilePickerComponent maxFileCount={1}
                                                                     height={"191px"}
                                                                     onFilesDrop={(acceptedFiles) => {
                                                                         if (acceptedFiles && acceptedFiles.length > 0) {
                                                                             const file = acceptedFiles[0];
                                                                             setFieldValue('file', file);
                                                                         }
                                                                     }}
                                                                     uploadDescription={"(upload only one file)"}
                                                                     acceptedFilesText={"CSV files only"}
                                                                     acceptedFileTypes={["csv"]}
                                                />
                                                {
                                                    (_.get(touched, "file") && !!(_.get(errors, "file"))) &&
                                                    <ErrorComponent
                                                        errorText={(_.get(errors, "file"))}/>
                                                }
                                            </>
                                        }
                                    </>
                                    <>
                                        {
                                            (values.file) && <>
                                                <FilePreviewThumbnailComponent
                                                    file={values.file}
                                                    onRemove={() => {
                                                        setFieldValue('file', '');
                                                    }}
                                                />
                                            </>
                                        }
                                    </>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
           <div className={'note-class'}>*Note:Bulk upload is not applicable for entity managers.</div>
        </div>
    );

};

export default UserBulkUploadScreen;


