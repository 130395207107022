import "./AmenityTimingDetailsComponent.scss";
import {useCallback, useEffect, useState} from "react";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../constants";
import StepperComponent from "../../../../shared/components/stepper/StepperComponent";
import * as Yup from "yup";
import FormikCheckBoxComponent
    from "../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import FormikTimePickerComponent
    from "../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";


interface AmenityTimingDetailsComponentProps {
    amenityDetails?: any;
    prev: () => void;
    next: (data: any) => void;
    steps: any[];
    amenityId: string,
    activeStepId: string;
}

const timesValidationSchema = Yup.object({
    is_selected: Yup.boolean(),
    from: Yup.string().when(['is_selected', 'isSameEveryDay'], {
        is: (is_selected: boolean, isSameEveryDay: boolean) => !isSameEveryDay && is_selected,
        then: Yup.string()
            .required('From time is required')
            .test('is-valid-time-range', 'To time must be ahead of From time', function (fromTime) {
                const toTime = this.parent.to; // Access the "to" field value
                if (!fromTime || !toTime) {
                    // If either field is not filled, validation is handled by required checks
                    return true;
                }
                // Compare the time values, assuming they are in HH:mm format
                return fromTime < toTime;
            }),
    }),
    to: Yup.string().when(['is_selected', 'isSameEveryDay'], {
        is: (is_selected: boolean, isSameEveryDay: boolean) => !isSameEveryDay && is_selected,
        then: Yup.string()
            .required('To time is required')
            .test('is-valid-time-range', 'To time must be ahead of From time', function (toTime) {
                const fromTime = this.parent.from; // Access the "from" field value
                if (!fromTime || !toTime) {
                    // If either field is not filled, validation is handled by required checks
                    return true;
                }
                // Compare the time values, assuming they are in HH:mm format
                return fromTime < toTime;
            }),
    }),
});

const validationSchema = Yup.object().shape({
    isSameEveryDay: Yup.boolean(),
    from: Yup.string().when('isSameEveryDay', {
        is: true,
        then: Yup.string()
            .required('From time is required')
            .test('is-valid-time-range', 'To time must be ahead of From time', function (fromTime) {
                const toTime = this.parent.to; // Access the "to" field value
                if (!fromTime || !toTime) {
                    // If either field is not filled, validation is handled by required checks
                    return true;
                }
                // Compare the time values, assuming they are in HH:mm format
                return fromTime < toTime;
            }),
    }),
    to: Yup.string().when('isSameEveryDay', {
        is: true,
        then: Yup.string()
            .required('To time is required')
            .test('is-valid-time-range', 'To time must be ahead of From time', function (toTime) {
                const fromTime = this.parent.from; // Access the "from" field value
                if (!fromTime || !toTime) {
                    // If either field is not filled, validation is handled by required checks
                    return true;
                }
                // Compare the time values, assuming they are in HH:mm format
                return fromTime < toTime;
            }),
    }),
    timings: Yup.array().of(timesValidationSchema)
        .test('is-timings-selected', 'Select at least one day and time range', function (timings: any) {
            const {isSameEveryDay} = this.parent;
            if (!isSameEveryDay) {
                return timings.some((timing: any) => timing.is_selected);
            }
            return true;
        }),
});

const weekList = [
    {from: '', to: '', day: 'Sunday', dayId: 0, is_selected: false},
    {from: '', to: '', day: 'Monday', dayId: 1, is_selected: false},
    {from: '', to: '', day: 'Tuesday', dayId: 2, is_selected: false},
    {from: '', to: '', day: 'Wednesday', dayId: 3, is_selected: false},
    {from: '', to: '', day: 'Thursday', dayId: 4, is_selected: false},
    {from: '', to: '', day: 'Friday', dayId: 5, is_selected: false},
    {from: '', to: '', day: 'Saturday', dayId: 6, is_selected: false},
];

interface formInitialValuesFormSchema {
    isSameEveryDay: boolean,
    from: string,
    to: string,
    timings: { from: string, to: string, day: string, dayId: number, is_selected: boolean }[],
}

const formInitialValues: formInitialValuesFormSchema = {
    isSameEveryDay: false,
    from: '',
    to: '',
    timings: weekList,
}

const tempWeekList = [
    {from: '', to: '', day: 'Sunday', dayId: 0, is_selected: false},
    {from: '', to: '', day: 'Monday', dayId: 1, is_selected: false},
    {from: '', to: '', day: 'Tuesday', dayId: 2, is_selected: false},
    {from: '', to: '', day: 'Wednesday', dayId: 3, is_selected: false},
    {from: '', to: '', day: 'Thursday', dayId: 4, is_selected: false},
    {from: '', to: '', day: 'Friday', dayId: 5, is_selected: false},
    {from: '', to: '', day: 'Saturday', dayId: 6, is_selected: false},
];

const AmenityTimingDetailsComponent = (props: AmenityTimingDetailsComponentProps) => {
    const {amenityDetails, prev, next, steps, activeStepId, amenityId} = props;

    const [initialValues, setInitialValues] = useState<formInitialValuesFormSchema>(formInitialValues);

    useEffect(() => {
        if (amenityDetails) {
            if (amenityDetails?.isSameEveryDay) {
                setInitialValues({
                    isSameEveryDay: true,
                    from: amenityDetails?.timings?.from,
                    to: amenityDetails?.timings?.to,
                    timings: tempWeekList,
                });
            }
            else{
                const dayMap: any = {};

                amenityDetails?.timings?.forEach((record: any) => {
                    dayMap[record.day] = record;
                });


                const timingsList = tempWeekList.map((day) => {
                    if (dayMap[day.day]) {
                        day.from = dayMap[day.day].from;
                        day.to = dayMap[day.day].to;
                        day.is_selected = true;
                        day.dayId = dayMap[day.day].dayId;
                    }
                    return day;
                });
                setInitialValues({
                    isSameEveryDay: false,
                    from: '',
                    to: '',
                    timings: timingsList,
                });
            }
        }
    }, [amenityDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        let payload: any = {};

        if (values?.isSameEveryDay) {
            payload = {
                isSameEveryDay: values?.isSameEveryDay,
                from: values?.from,
                to: values?.to,
            }
            delete values.timings;
        } else {
            const daysSelected = Object.values(values?.timings);
            const selectedDayTimings = daysSelected.filter((day: any) => day.is_selected);
            const finalTimings = selectedDayTimings.map((day: any) => {
                return {
                    day: day.day,
                    from: day.from,
                    to: day.to,
                }
            });
            payload = {
                isSameEveryDay: values?.isSameEveryDay,
                timings: finalTimings,
            }

        }
        CommonService._amenity.AddTimingsDetails(amenityId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                next(response.data);
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [amenityId, next]);


    return (
        <div className={'amenity-timings-component'}>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={false}
                enableReinitialize={true}
                validateOnMount={false}
                onSubmit={onSubmit}
            >
                {({values, errors, isValid, setFieldValue, isSubmitting}) => {
                    return (
                        <Form className="t-form" noValidate={true}>
                            {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                            <div className="add-component-header">
                                {
                                    <ButtonComponent
                                        variant={'text'}
                                        color={'secondary'}
                                        onClick={prev}
                                        prefixIcon={<ImageConfig.ArrowLeftIcon/>}
                                    >
                                        Back
                                    </ButtonComponent>
                                }
                                <div>
                                    <StepperComponent activeStepId={activeStepId} steps={steps}/>
                                </div>

                                <ButtonComponent
                                    disabled={isSubmitting || !isValid}
                                    isLoading={isSubmitting}
                                    type={'submit'}
                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                >
                                    Next
                                </ButtonComponent>
                            </div>

                            <div className="add-component-content">
                                <Field name={'isSameEveryDay'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikCheckBoxComponent
                                                className={'same-every-day-checkbox'}
                                                formikField={field}
                                                label={'Same Every day'}
                                                onChange={(isChecked: any) => {
                                                    if (isChecked) {
                                                        setFieldValue('timings', weekList);
                                                    } else {
                                                        setFieldValue('from', '');
                                                        setFieldValue('to', '');
                                                    }
                                                }}
                                            />

                                        )
                                    }
                                </Field>
                                {values?.isSameEveryDay && <>
                                    <div className="ts-row amenity-timings-form-wrapper">
                                        <div className="ts-col-12 ts-col-lg-6">
                                            <Field name={'from'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikTimePickerComponent
                                                            label={'From'}
                                                            use12Hours={true}
                                                            format={'HH:mm'}
                                                            // disabled={true}
                                                            placeholder={'Select'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                        <div className="ts-col-12 ts-col-lg-6">
                                            <Field name={'to'}>
                                                {
                                                    (field: FieldProps) => (
                                                        <FormikTimePickerComponent
                                                            label={'To'}
                                                            use12Hours={true}
                                                            format={'HH:mm'}
                                                            // disabled={true}
                                                            placeholder={'Select'}
                                                            required={true}
                                                            formikField={field}
                                                            fullWidth={true}
                                                        />
                                                    )
                                                }
                                            </Field>
                                        </div>
                                    </div>

                                </>}
                                {!values?.isSameEveryDay && <>
                                    <FieldArray
                                        name={'timings'}
                                        render={(arrayHelpers) => (
                                            <>
                                                {values?.timings && values?.timings?.map((day: any, index: any) => {
                                                    // const isSelected = values?.timings[index]?.day.length > 0;
                                                    // if (isSelected) {
                                                    //     isFromTimeGreaterThanToTime(day.dayId, values)
                                                    // }
                                                    return (
                                                        <div>
                                                            <div className="ts-row amenity-timings-form-wrapper">
                                                                <div className="ts-col-12 ts-col-sm-4">
                                                                    <Field
                                                                        name={`timings.${index}.is_selected`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikCheckBoxComponent
                                                                                    label={day.day}
                                                                                    formikField={field}
                                                                                    // // value={day}
                                                                                    // // checked={selectedDays[index] ? !!selectedDays[index] : false}
                                                                                    // onChange={(isChecked: any) => {
                                                                                    //     if (isChecked) {
                                                                                    //         // setFieldValue(`timings.${day?.dayId}`, {
                                                                                    //         //     day: day?.day,
                                                                                    //         //     from: '',
                                                                                    //         //     to: ''
                                                                                    //         // });
                                                                                    //         setFieldValue(`timings.${day?.dayId}.day`, day.day);
                                                                                    //         setFieldValue(`timings.${day?.dayId}.from`, '');
                                                                                    //         setFieldValue(`timings.${day?.dayId}.to`, '');
                                                                                    //     } else {
                                                                                    //         // setFieldValue(`timings.${day?.dayId}`, undefined);
                                                                                    //         setFieldValue(`timings.${day?.dayId}.day`, '');
                                                                                    //         setFieldValue(`timings.${day?.dayId}.from`, '');
                                                                                    //         setFieldValue(`timings.${day?.dayId}.to`, '');
                                                                                    //     }
                                                                                    // }}
                                                                                />

                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-12  ts-col-sm-4">
                                                                    <Field name={`timings.${index}.from`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikTimePickerComponent
                                                                                    label={'From'}
                                                                                    use12Hours={true}
                                                                                    format={'HH:mm'}
                                                                                    disabled={!values?.timings[index]?.is_selected}
                                                                                    placeholder={'Select'}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-12 ts-col-sm-4">
                                                                    <Field name={`timings.${index}.to`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikTimePickerComponent
                                                                                    label={'To'}
                                                                                    use12Hours={true}
                                                                                    format={'HH:mm'}
                                                                                    disabled={!values?.timings[index]?.is_selected}
                                                                                    placeholder={'Select'}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>

                                                                    {/*<div className="ts-row">*/}
                                                                    {/*    <div*/}
                                                                    {/*        className="ts-col d-flex">*/}
                                                                    {/*        {(values?.timings[index]?.to && values?.timings[index]?.from) && (values?.timings[index]?.from > values?.timings[index]?.to) && <>*/}
                                                                    {/*            <ErrorComponent*/}
                                                                    {/*                errorText={'End time should be greater than start time'}/>*/}
                                                                    {/*        </>}*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        )}
                                    />
                                </>}
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );

};

export default AmenityTimingDetailsComponent;
