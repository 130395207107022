import './EventPerformerInfoComponent.scss';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import * as Yup from 'yup';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import { CommonService } from '../../../../shared/services';
import AccordionComponent from '../../../../shared/components/accordion/AccordionComponent';
import FormikTextAreaComponent from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import { CloseIcon } from '../../../../constants/ImageConfig';
import { IAPIResponseType } from '../../../../shared/models/api.model';

interface EventPerformerInfoComponentProps {
  eventDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  eventId: string;
  activeStepId: string;
}

const performerDetailsObject = {
  performerName: '',
  about: '',
  image: null,
  isImageExists: false,
  isImageDeleted: false,
};

export interface IPerformerOptions {
  performerName: string;
  about: string;
  image: any;
  isImageExists: any;
  isImageDeleted: any;
}

const validationSchema = Yup.object().shape({
  isPerformerExists: Yup.boolean().required('Required'),
  performers: Yup.mixed().when('isPerformerExists', {
    is: true,
    then: Yup.array(
      Yup.object({
        performerName: Yup.string()
          .min(3, 'performer name should be at least 3 characters')
          .max(30, 'performer name cannot exceed 30 characters')
          .required('performer name is required'),
        about: Yup.string()
          .min(100, 'performer details should be at least 100')
          .max(500, 'performer details cannot exceed 500')
          .required('performer details is required'),
        image: Yup.mixed(),
      }),
    ),
    otherwise: Yup.array(
      Yup.object({
        performerName: Yup.string()
          .min(3, 'performer name should be at least 3 characters')
          .max(50, 'performer name cannot exceed 50 characters'),
        about: Yup.string()
          .min(100, 'performer details should be at least 100')
          .max(500, 'performer details cannot exceed 100'),
        image: Yup.mixed(),
      }),
    ).notRequired(),
  }),
});

interface formInitialValuesFormSchema {
  isPerformerExists?: boolean;
  performers?: IPerformerOptions[];
}

const formInitialValues: formInitialValuesFormSchema = {
  isPerformerExists: false,
  performers: [performerDetailsObject],
};

const EventPerformerInfoComponent = (
  props: EventPerformerInfoComponentProps,
) => {
  const { eventDetails, prev, next, steps, eventId, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const [profilePicUploadRefs, setProfilePicUploadRefs] = useState<any[]>([
    createRef(),
  ]);

  useEffect(() => {
    if (eventDetails) {
      const performersCount = eventDetails?.performers
        ? eventDetails?.performers.length
        : 0;
      const initialRefs = Array(performersCount)
        .fill(null)
        .map(() => createRef());

      setInitialValues({
        isPerformerExists: eventDetails?.isPerformerExists,
        performers:
          eventDetails?.performers && eventDetails?.performers.length > 0
            ? eventDetails?.performers?.map((item: any) => {
                return {
                  ...item,
                  isImageExists: !!item.image,
                };
              })
            : [performerDetailsObject],
      });

      if (initialRefs.length > 0) {
        setProfilePicUploadRefs(initialRefs); // Set the initial refs based on performersCount
      } else {
        const currentRef = createRef();
        setProfilePicUploadRefs([currentRef]);
      }
    }
  }, [eventDetails]);

  const onSubmit = useCallback(
    (values: any) => {
      const formData = new FormData();
      formData.append('isPerformerExists', values.isPerformerExists);
      formData.append('eventId', eventId);

      if (values.isPerformerExists && values.performers) {
        values.performers.forEach((val: any, index: any) => {
          if (val._id) {
            formData.append(`performers[${index}]._id`, val._id);
          }
          if (val.image) {
            formData.append(`performers[${index}].image`, val.image);
          }
          formData.append(`performers[${index}].about`, val.about);
          formData.append(
            `performers[${index}].performerName`,
            val.performerName,
          );
          formData.append(
            `performers[${index}].isImageExists`,
            val.isImageExists,
          );
          formData.append(
            `performers[${index}].isImageDeleted`,
            val.isImageDeleted,
          );
        });
      }

      CommonService._events
        .AddEventPerformersAPICall(formData)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next(response.data);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        });
    },
    [eventId, next],
  );

  return (
    <div className={'more-info-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, validateForm, isValid, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Back
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  // isLoading={}
                  disabled={!isValid}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <div className='scroll-content'>
                  <div className={'row-wrapper-style'}>
                    <div className={'row-style'}>
                      <FormControlLabelComponent
                        label={'Performer Details'}
                        level={5}
                        isBold={true}
                      />
                      <div className={'optional-text align option'}>
                        (Optional)
                      </div>
                    </div>
                  </div>
                  <Field name={'isPerformerExists'}>
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        label={' Do this event have a performer?'}
                        required={true}
                        formikField={field}
                        options={CommonService._staticData.yesNoOptions}
                        onChange={(event: any) => {
                          if (event === false) {
                            setFieldValue('performers', []);
                          } else {
                            setFieldValue('performers', [
                              performerDetailsObject,
                            ]);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {values.isPerformerExists && (
                    <FieldArray
                      name='performers'
                      render={(arrayHelpers) => (
                        <div className='field-array-container'>
                          {values?.performers &&
                            values?.performers?.map(
                              (item: any, index: number) => {
                                return (
                                  <div key={index} className='field-array-item'>
                                    <AccordionComponent
                                      title={`Performer ${index + 1}`}
                                      isExpand={true}
                                      isAccordionOpen={true}
                                      actions={
                                        values?.performers &&
                                        values?.performers.length > 1 && (
                                          <div
                                            className='accordion-icon-action'
                                            onClick={(e: any) => {
                                              e.stopPropagation();
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            <ImageConfig.WarnIcon />
                                          </div>
                                        )
                                      }
                                    >
                                      <Field
                                        name={`performers[${index}].performerName`}
                                      >
                                        {(field: FieldProps) => (
                                          <FormikInputComponent
                                            label={'Name of the Performer'}
                                            placeholder={'Ex : John Doe'}
                                            type={'text'}
                                            required={true}
                                            formikField={field}
                                            fullWidth={true}
                                          />
                                        )}
                                      </Field>
                                      <Field
                                        name={`performers[${index}].about`}
                                      >
                                        {(field: FieldProps) => (
                                          <FormikTextAreaComponent
                                            label={'About the performer'}
                                            placeholder={
                                              'Write the performer description here'
                                            }
                                            required={true}
                                            formikField={field}
                                            fullWidth={true}
                                          />
                                        )}
                                      </Field>

                                      {values?.performers &&
                                        values?.performers.length > 0 &&
                                        !!values.performers[index]?.image && (
                                          <>
                                            {/*{JSON.stringify(values.performers[index]?.image)}*/}
                                            <div className='image-preview'>
                                              <div
                                                className='close-btn'
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  if (
                                                    profilePicUploadRefs[index]
                                                      ?.current
                                                  ) {
                                                    profilePicUploadRefs[
                                                      index
                                                    ].current.value = null;
                                                  }
                                                  setFieldValue(
                                                    `performers[${index}].image`,
                                                    null,
                                                  );
                                                  setFieldValue(
                                                    `performers[${index}].isImageExists`,
                                                    false,
                                                  );
                                                  setFieldValue(
                                                    `performers[${index}].isImageDeleted`,
                                                    true,
                                                  );
                                                }}
                                              >
                                                <CloseIcon />
                                              </div>
                                              {typeof values?.performers[index]
                                                .image === 'string' && (
                                                <img
                                                  src={
                                                    values?.performers[index]
                                                      ?.image
                                                  }
                                                  alt=''
                                                />
                                              )}
                                              {typeof values?.performers[index]
                                                .image !== 'string' && (
                                                <img
                                                  src={URL.createObjectURL(
                                                    values?.performers[index]
                                                      ?.image,
                                                  )}
                                                  alt=''
                                                />
                                              )}
                                            </div>
                                          </>
                                        )}
                                      <div className='upload-gallery-profile-upload-btn'>
                                        <div>
                                          <input
                                            ref={profilePicUploadRefs[index]}
                                            type='file'
                                            accept='image/jpeg, image/jpg, image/png'
                                            className={'display-none'}
                                            onChange={(event: any) => {
                                              event.preventDefault();
                                              if (
                                                event.target.files &&
                                                event.target.files.length > 0
                                              ) {
                                                setFieldValue(
                                                  `performers[${index}].image`,
                                                  event.target.files[0],
                                                );
                                                setFieldValue(
                                                  `performers[${index}].isImageExists`,
                                                  true,
                                                );
                                                setFieldValue(
                                                  `performers[${index}].isImageDeleted`,
                                                  false,
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                        <ButtonComponent
                                          color={'primary'}
                                          variant={'outlined'}
                                          prefixIcon={
                                            <ImageConfig.FileUploadBtnIcon />
                                          }
                                          onClick={() => {
                                            if (
                                              profilePicUploadRefs &&
                                              profilePicUploadRefs[index]
                                            ) {
                                              profilePicUploadRefs[
                                                index
                                              ]?.current?.click();
                                            }
                                          }}
                                        >
                                          Upload Performer Image
                                        </ButtonComponent>
                                      </div>
                                    </AccordionComponent>

                                    {values?.performers &&
                                      index ===
                                        values?.performers?.length - 1 && (
                                        <ButtonComponent
                                          color={'primary'}
                                          variant={'outlined'}
                                          prefixIcon={
                                            <ImageConfig.AddOutlinedIcon />
                                          }
                                          onClick={() => {
                                            arrayHelpers.push(
                                              performerDetailsObject,
                                            );
                                            const currentRef = createRef();
                                            setProfilePicUploadRefs(
                                              (prevRefs: any) => {
                                                return [
                                                  ...prevRefs,
                                                  currentRef,
                                                ];
                                              },
                                            );
                                          }}
                                          className={'add-more-tower-button'}
                                        >
                                          Add Performer
                                        </ButtonComponent>
                                      )}
                                  </div>
                                );
                              },
                            )}
                        </div>
                      )}
                    />
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventPerformerInfoComponent;
