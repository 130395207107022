import './EntityDetailsAddComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import * as Yup from 'yup';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import SwitchComponent from '../../../../shared/components/form-controls/switch/SwitchComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import FilePreviewThumbnailComponent from '../../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';

interface EntityDetailsAddComponentProps {
  next: (propertyId: string, data: any) => void;
  prev: () => void;
  steps: any;
  entityDetails: any;
  mode: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Entity Name should be at least 3 characters')
    .max(50, 'Entity Name should not exceed 50 characters')
    .required('Entity Name is required'),
  shortName: Yup.string()
    .min(3, 'Entity Short Name should be at least 3 characters')
    .max(50, 'Entity Short Name should not exceed 50 characters')
    .required('Entity Short Name is required'),
  gstin: Yup.string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'Invalid GST Number',
    )
    .required('GST Number is required'),
  pan: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
    .required('PAN Number is required'),
  clientId: Yup.string()
    .min(5, 'Client ID should be at least 5 characters')
    .max(50, 'Client ID should not exceed 50 characters')
    .required('Client ID is required'),
  clientSecret: Yup.string()
    .min(5, 'Client Secret should be at least 5 characters')
    .max(50, 'Client Secret should not exceed 50 characters')
    .required('Client Secret is required'),
  email: Yup.string().email('Invalid email').required('Email ID is required'),
  phoneNumber: Yup.string()
    .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
    .min(10, 'Phone number should be 10 digits')
    .required('Phone number is required'),
  addressDetails: Yup.object({
    addressLine1: Yup.string()
      .min(3, 'Address should be at least 3 characters')
      .max(50, 'Address should not exceed 50 characters')
      .required('Address line is required'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode should have a total of 6 characters')
      .max(6, 'Pincode should not exceed 6 characters')
      .required('Pincode is required'),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string()
      .min(1, 'City should be at least 1 character')
      .max(30, 'City should not exceed 30 characters')
      .required('City is required'),
    country: Yup.string()
      .min(1, 'Country should be at least 1 character')
      .max(30, 'Country should not exceed 30 characters')
      .required('Country is required'),
  }),
  adminDetails: Yup.array().of(
    Yup.object().shape({
      userCode: Yup.string()
        .min(5, 'User Code should be at least 5 characters')
        .max(50, 'User Code should not exceed 50 characters')
        .required('User Code is required'),
      name: Yup.string()
        .min(5, 'Name should be at least 5 characters')
        .max(50, 'Name should not exceed 50 characters')
        .required('Name is required'),
      phoneNumber: Yup.string()
        .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
        .min(10, 'Phone number should be 10 digits')
        .required('Phone number is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email ID is required'),
    }),
  ),
  emailCCList: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid email address'),
    }),
  ),
});

const editValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Entity Name should be at least 3 characters')
    .max(50, 'Entity Name should not exceed 50 characters')
    .required('Entity Name is required'),
  shortName: Yup.string()
    .min(3, 'Entity Short Name should be at least 3 characters')
    .max(50, 'Entity Short Name should not exceed 50 characters')
    .required('Entity Short Name is required'),
  gstin: Yup.string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'Invalid GST Number',
    )
    .required('GST Number is required'),
  pan: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
    .required('PAN Number is required'),
  clientId: Yup.string()
    .min(5, 'Client ID should be at least 5 characters')
    .max(50, 'Client ID should not exceed 50 characters')
    .required('Client ID is required'),
  clientSecret: Yup.string()
    .min(5, 'Client Secret should be at least 5 characters')
    .max(50, 'Client Secret should not exceed 50 characters')
    .required('Client Secret is required'),
  email: Yup.string().email('Invalid email').required('Email ID is required'),
  phoneNumber: Yup.string()
    .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
    .min(10, 'Phone number should be 10 digits')
    .required('Phone number is required'),
  addressDetails: Yup.object({
    addressLine1: Yup.string()
      .min(3, 'Address should be at least 3 characters')
      .max(50, 'Address should not exceed 50 characters')
      .required('Address line is required'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode should have a total of 6 characters')
      .max(6, 'Pincode should not exceed 6 characters')
      .required('Pincode is required'),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string()
      .min(1, 'City should be at least 1 character')
      .max(30, 'City should not exceed 30 characters')
      .required('City is required'),
    country: Yup.string()
      .min(1, 'Country should be at least 1 character')
      .max(30, 'Country should not exceed 30 characters')
      .required('Country is required'),
  }),
  adminDetails: Yup.array().of(
    Yup.object().shape({
      userCode: Yup.string()
        .min(5, 'User Code should be at least 5 characters')
        .max(50, 'User Code should not exceed 50 characters')
        .required('User Code is required'),
      name: Yup.string()
        .min(5, 'Name should be at least 5 characters')
        .max(50, 'Name should not exceed 50 characters')
        .required('Name is required'),
      phoneNumber: Yup.string()
        .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
        .min(10, 'Phone number should be 10 digits')
        .required('Phone number is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email ID is required'),
    }),
  ),
  emailCCList: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid email address'),
    }),
  ),
});

interface formInitialValuesFormSchema {
  name: string;
  shortName: string;
  addressDetails: {
    addressLine1: string;
    pincode: any;
    state: string;
    city: string;
    country: string;
  };
  adminDetails: [
    {
      userCode: string;
      name: string;
      phoneNumber: string;
      email: string;
    },
  ];
  emailCCList: [
    {
      email: string;
    },
  ];
  gstin: string;
  pan: string;
  clientId: string;
  clientSecret: string;
  email: string;
  phoneNumber: string;
  showPhoenixLogo: boolean;
  showEntityLogo: boolean;
  entityLogo?: any;
}

const formInitialValues: formInitialValuesFormSchema = {
  name: '',
  shortName: '',
  addressDetails: {
    addressLine1: '',
    pincode: '',
    state: '',
    city: '',
    country: 'India',
  },
  adminDetails: [
    {
      userCode: '',
      name: '',
      phoneNumber: '',
      email: '',
    },
  ],
  emailCCList: [
    {
      email: '',
    },
  ],
  gstin: '',
  pan: '',
  clientId: '',
  clientSecret: '',
  email: '',
  phoneNumber: '',
  showPhoenixLogo: true,
  showEntityLogo: false,
  entityLogo: '',
};

const EntityDetailsAddComponent = (props: EntityDetailsAddComponentProps) => {
  const { next, prev, entityDetails, mode } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);

  useEffect(() => {
    // entityDetails && setInitialValues(entityDetails);
    if (entityDetails) {
      const fetchImage = async () => {
        let imageDetails: any = {};
        if (entityDetails?.entityLogo?.url) {
          const image = await CommonService.generateBlobFileFromUrl(
            entityDetails?.entityLogo?.url,
            entityDetails?.entityLogo?.filename,
            entityDetails?.entityLogo?.mimetype,
          );
          imageDetails = {
            image: image,
          };
        }
        const basicDetails = {
          name: entityDetails?.name,
          shortName: entityDetails?.shortName,
          gstin: entityDetails?.gstin,
          pan: entityDetails?.pan,
          clientId: entityDetails?.clientId,
          clientSecret: entityDetails?.clientSecret,
          email: entityDetails?.email,
          phoneNumber: entityDetails?.phoneNumber,
          showPhoenixLogo: entityDetails?.showPhoenixLogo,
          showEntityLogo: entityDetails?.showEntityLogo,
          entityLogo: imageDetails.image ? imageDetails.image : '',
          addressDetails: {
            addressLine1: entityDetails?.addressDetails?.addressLine1,
            pincode: entityDetails?.addressDetails?.pincode,
            state: entityDetails?.addressDetails?.state,
            city: entityDetails?.addressDetails?.city,
            country: entityDetails?.addressDetails?.country,
            stateCode: entityDetails?.addressDetails?.stateCode,
          },
          adminDetails: entityDetails?.adminDetails?.map((admin: any) => ({
            _id: admin?._id,
            email: admin?.email,
            name: admin?.name,
            userCode: admin?.userCode,
            phoneNumber: admin?.phoneNumber,
          })),
          emailCCList: entityDetails?.emailCCList?.map((list: any) => ({
            email: list?.email,
          })),
        };
        setInitialValues(basicDetails);
      };
      fetchImage();
    } else {
      setInitialValues({
        name: '',
        shortName: '',
        addressDetails: {
          addressLine1: '',
          pincode: '',
          state: '',
          city: '',
          country: 'India',
        },
        adminDetails: [
          {
            userCode: '',
            name: '',
            phoneNumber: '',
            email: '',
          },
        ],
        emailCCList: [
          {
            email: '',
          },
        ],
        gstin: '',
        pan: '',
        clientId: '',
        clientSecret: '',
        email: '',
        phoneNumber: '',
        showPhoenixLogo: true,
        showEntityLogo: false,
        entityLogo: '',
      });
    }
  }, [entityDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const payload = {
        ...values,
      };
      if (entityDetails?.entityLogo === payload?.entityLogo) {
        delete payload.entityLogo;
      }
      const formData = CommonService.getFormDataFromJSON(payload);
      if (mode === 'edit') {
        CommonService._entity
          .EditEntityAPICall(entityDetails._id, formData)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data._id, response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        CommonService._entity
          .AddEntityAPICall(formData)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data._id, undefined);
            // dispatch(getEntityListLite());
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [next, mode, entityDetails],
  );

  const handleDeleteAdminDetails = useCallback(
    (adminId: any, arrayHelpers: any) => {
      CommonService._user
        .deleteUserAPICall(adminId)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          arrayHelpers.remove(adminId);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        })
        .finally(() => {});
    },
    [],
  );

  return (
    <div className={'entity-details-add-component add-screen'}>
      <Formik
        validationSchema={
          mode === 'edit' ? editValidationSchema : validationSchema
        }
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          isValid,
          isSubmitting,
          setFieldValue,
          validateForm,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form scroll-content' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div className={'entity-details-title'}>Entity Details</div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Save
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <Field name={'name'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Entity Name'}
                      placeholder={'Example: Phoenix infra'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      validationPattern={new RegExp(/^[aA-zZ\s]+$/)}
                    />
                  )}
                </Field>
                <Field name={'shortName'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Entity Short Form'}
                      placeholder={'Example: PIPL'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      validationPattern={new RegExp(/^[aA-zZ\s]+$/)}
                    />
                  )}
                </Field>
                <div className='entity-phoenix-logo-toggle-wrapper'>
                  <SwitchComponent
                    checked={values.showPhoenixLogo}
                    onChange={() =>
                      setFieldValue('showPhoenixLogo', !values.showPhoenixLogo)
                    }
                    label='Attach Phoenix logo on invoices'
                    labelBold={true}
                  />
                </div>

                <FormControlLabelComponent
                  label={'GST Details'}
                  level={4}
                  isBold={true}
                />

                {mode !== 'edit' && (
                  <div className={'note-with-link-class'}>
                    Make sure you have completed the sandbox testing of the
                    entity before onboarding.
                    <a
                      className={'underline-text-button'}
                      href={
                        'https://einv-apisandbox.nic.in/einvapiclient/RegistrationForm.aspx'
                      }
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Click here to check
                    </a>
                  </div>
                )}
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'gstin'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'GSTIN Number'}
                          placeholder={'Enter gstin'}
                          type={'text'}
                          required={true}
                          isCapitalize={true}
                          formikField={field}
                          fullWidth={true}
                          // validationPattern={new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/)}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'pan'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'PAN Number'}
                          placeholder={'Enter PAN'}
                          type={'text'}
                          isCapitalize={true}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'clientId'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Client ID'}
                          placeholder={'Enter client id'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'clientSecret'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Client Secret'}
                          placeholder={'enter client secret'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'email'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Registered Email ID'}
                          placeholder={'example@xyz.com'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'phoneNumber'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Registered Phone Number'}
                          placeholder={'+91 00000 00000'}
                          required={true}
                          type={'text'}
                          validationPattern={new RegExp(/^[5-9][0-9]{0,9}$/)}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <FormControlLabelComponent
                  label={'Address Details'}
                  level={4}
                  isBold={true}
                />
                <Field name={'addressDetails.addressLine1'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Address Line'}
                      placeholder={'Example: Road No. 1, Financial District'}
                      type={'text'}
                      // maxlength={50}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.city'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'City'}
                          placeholder={'Example: Hyderabad'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={
                            new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.state'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          formikField={field}
                          label={'State'}
                          placeholder={'Example: Telangana'}
                          fullWidth={true}
                          required={true}
                          searchMode={'clientSide'}
                          searchable={true}
                          isClearable={true}
                          options={CommonService._staticData.indianStatesGST}
                          displayWith={(option) => option.state}
                          valueExtractor={(option) => option.state}
                          onUpdate={(value: any) => {
                            if (value) {
                              const selectedState =
                                CommonService._staticData.indianStatesGST.find(
                                  (item: any) => item.state === value,
                                );
                              setFieldValue(
                                'addressDetails.state',
                                selectedState?.state,
                              );
                              setFieldValue(
                                'addressDetails.stateCode',
                                selectedState?.statecode,
                              );
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.country'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Country'}
                          placeholder={'Ex : India'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.pincode'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Pincode'}
                          placeholder={'Ex: 500004'}
                          // type={"number"}
                          required={true}
                          validationPattern={/^[0-9\b]+$/}
                          maxlength={6}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                {/* {mode !== 'edit' && (
                  <>
                    <FormControlLabelComponent
                      label={'Admin Details'}
                      level={4}
                      isBold={true}
                    />
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'adminDetails.userCode'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'User ID'}
                              placeholder={'Example: PH-7398'}
                              type={'text'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-6'>
                        <Field name={'adminDetails.name'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'Full Name'}
                              placeholder={'Example: Rahul Reddy'}
                              type={'text'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'adminDetails.phoneNumber'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'Phone Number'}
                              placeholder={'Example: +91 xxxxx xxxxx'}
                              type={'text'}
                              validationPattern={
                                new RegExp(/^[5-9][0-9]{0,9}$/)
                              }
                              maxlength={10}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-6'>
                        <Field name={'adminDetails.email'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'Email ID'}
                              placeholder={'Enter Email ID'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </>
                )} */}
                <FormControlLabelComponent
                  label={'Admin Details'}
                  level={4}
                  isBold={true}
                />
                <FieldArray
                  name='adminDetails'
                  render={(arrayHelpers) => (
                    <>
                      {values?.adminDetails &&
                        values?.adminDetails.map((item: any, index: any) => {
                          return (
                            <div
                              className='admin-details-wrapper mrg-top-10'
                              key={index}
                            >
                              <Field name={`adminDetails.${index}.userCode`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'User ID'}
                                    placeholder={'Ex : PH-7398'}
                                    type={'text'}
                                    required={true}
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.name`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Full Name'}
                                    placeholder={'Ex : Rahul Reddy'}
                                    type={'text'}
                                    required={true}
                                    formikField={field}
                                    fullWidth={true}
                                    validationPattern={
                                      new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.phoneNumber`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Phone Number'}
                                    placeholder={'+91 00000 00000'}
                                    type={'text'}
                                    validationPattern={
                                      new RegExp(/^[5-9][0-9]{0,9}$/)
                                    }
                                    maxlength={10}
                                    required={true}
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.email`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Email ID'}
                                    placeholder={'Enter Email ID'}
                                    required={true}
                                    disabled={
                                      mode === 'edit' &&
                                      entityDetails &&
                                      entityDetails?.adminDetails[index]?._id
                                    }
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <div className='admin-details-wrapper'>
                                <IconButtonComponent
                                  color='primary'
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                >
                                  <ImageConfig.AddOutlinedIcon />
                                </IconButtonComponent>
                                {values?.adminDetails?.length > 1 && (
                                  <IconButtonComponent
                                    color='error'
                                    onClick={() => {
                                      if (item?._id) {
                                        handleDeleteAdminDetails(
                                          item?._id,
                                          arrayHelpers,
                                        );
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  >
                                    <ImageConfig.DeleteOutlineIcon />
                                  </IconButtonComponent>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                />
                <FormControlLabelComponent
                  label='Add email Id(s) to be  CC’d to.'
                  level={4}
                  isBold={true}
                />
                <FieldArray
                  name='emailCCList'
                  render={(arrayHelpers) => (
                    <>
                      {values?.emailCCList &&
                        values?.emailCCList?.map((item: any, index: any) => {
                          return (
                            <div
                              key={index}
                              className='admin-details-wrapper mrg-top-10'
                            >
                              <Field name={`emailCCList.${index}.email`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Email ID'}
                                    placeholder={'Enter Email'}
                                    type={'text'}
                                    formikField={field}
                                    fullWidth={true}
                                    required={
                                      values?.emailCCList[index]?.email
                                        ?.length > 0
                                    }
                                  />
                                )}
                              </Field>
                              <div className='admin-details-wrapper'>
                                <IconButtonComponent
                                  color='primary'
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                >
                                  <ImageConfig.AddOutlinedIcon />
                                </IconButtonComponent>
                                {values?.emailCCList?.length > 1 && (
                                  <IconButtonComponent
                                    color='error'
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <ImageConfig.DeleteOutlineIcon />
                                  </IconButtonComponent>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                />
                <div className='mrg-top-10 mrg-bottom-20'>
                  <div className={'enitity-logo-text-wrapper'}>
                    <div className={'entity-logo-header'}>
                      Upload Entity Logo
                    </div>
                    <div className={'optional-text mrg-top-2'}>Optional</div>
                  </div>
                  {!values?.entityLogo && (
                    <FilePickerComponent
                      multiple={false}
                      uploadDescription='(upload only one file)'
                      acceptedFileTypes={['png', 'jpeg', 'jpg']}
                      showDropZone={true}
                      acceptedFilesText={
                        '.jpg, .png, .jpeg max file size 3.0MB'
                      }
                      maxFileSizeInMB={3}
                      maxFileCount={1}
                      onFilesDrop={(acceptedFiles) => {
                        setFieldValue('entityLogo', acceptedFiles[0]);
                        setFieldValue('showEntityLogo', true);
                      }}
                    />
                  )}
                  {values?.entityLogo && (
                    <FilePreviewThumbnailComponent
                      file={values.entityLogo}
                      onRemove={() => {
                        setFieldValue('entityLogo', '');
                        setFieldValue('showEntityLogo', false);
                      }}
                    />
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EntityDetailsAddComponent;
