import './PollDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import * as Yup from 'yup';

interface PollDetailsComponentProps {
  pollsDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
}

const validationSchema = Yup.object({
  title: Yup.string()
    .min(3, 'Poll Title should be at least 3 characters')
    .max(30, 'Poll Title should not exceed 30 characters')
    .required('Poll Title is required'),
  question: Yup.string()
    .min(10, 'Poll  Question should be at least 10 characters')
    .max(100, 'Poll Question should not exceed 100 characters')
    .required('Poll Question is required'),
});

interface formInitialValuesFormSchema {
  title: string;
  question: string;
}

const formInitialValues: formInitialValuesFormSchema = {
  title: '',
  question: '',
};

const PollDetailsComponent = (props: PollDetailsComponentProps) => {
  const { pollsDetails, prev, next, steps, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);

  useEffect(() => {
    if (pollsDetails) {
      setInitialValues({
        ...pollsDetails,
        title: pollsDetails.title,
        question: pollsDetails.question,
      });
    }
  }, [pollsDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const payload = {
        ...values,
      };
      next(payload);
    },
    [next],
  );

  return (
    <div className={'poll-details-component'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <Field name={'title'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={
                        'Poll Title (This is the title that users will see on the Wall)'
                      }
                      placeholder={'Example: Diwali Celebrations'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>

                <Field name={'question'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={
                        'Poll Question (This is the prompt or question users will respond to)'
                      }
                      placeholder={
                        'Are you on campus to celebrate Diwali this year?'
                      }
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PollDetailsComponent;
