import './AmenitiesScreen.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import { ITableColumn } from '../../../shared/models/table.model';
import TagComponent from '../../../shared/components/tag/TagComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { CommonService } from '../../../shared/services';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface AmenitiesScreenProps {}

const MODULE = 'AMENITY';

const AmenitiesScreen = (props: AmenitiesScreenProps) => {
  // const navigation = useNavigate();
  const [count, setCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({});
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  // const toggleAmenity = useCallback((item: any) => {
  //     CommonService._amenity.toggleAmenityAPICall(item._id).then((res) => {
  //         CommonService._communications.TableWrapperRefreshSubject.next({
  //             moduleName: MODULE,
  //         });
  //     }).catch((err) => {
  //     });
  // }, []);

  const Columns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Amenity Operator brand',
        key: 'name',
        dataIndex: 'name',
        render: (item: any) => {
          return (
            <div className={'amenity-name text-transform-capitalize'}>
              {item?.name || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'property',
        dataIndex: 'property',
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.propertyDetails?.name || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Amenity Type',
        key: 'amenity-type',
        dataIndex: 'Amenity Type',
        render: (item: any) => {
          return <>{item?.type || '-'}</>;
        },
      },
      {
        title: 'Amenity Admin',
        key: 'amenity-admin',
        dataIndex: 'Amenity Admin',
        render: (item: any) => {
          return (
            <div>
              {(item?.admindetails || []).length > 0 ? (
                <>
                  {item?.admindetails?.length === 1 ? (
                    <div className='text-transform-capitalize'>
                      {item?.admindetails[0]?.adminName}
                    </div>
                  ) : (
                    <ToolTipComponent
                      // backgroundColor={'#fff'}
                      position={'right'}
                      textColor={'#FFFFFF'}
                      tooltip={
                        <>
                          {item?.admindetails?.map(
                            (value1: any, index1: number) => {
                              return (
                                <div
                                  className={
                                    'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                  }
                                  key={index1}
                                >
                                  {value1.adminName}
                                </div>
                              );
                            },
                          )}
                        </>
                      }
                    >
                      <div className={'d-flex'}>
                        <ChipComponent
                          className={
                            'scheduled cursor-pointer text-transform-capitialize'
                          }
                          size={'small'}
                          color={'primary'}
                          label={
                            item?.admindetails[0].adminName +
                            ((item?.admindetails || []).length > 1
                              ? ' (+' +
                                ((item?.admindetails || []).length - 1) +
                                ')'
                              : '')
                          }
                        />
                      </div>
                    </ToolTipComponent>
                  )}
                </>
              ) : (
                'N/A'
              )}
            </div>
          );
        },
      },
      {
        title: 'Phone Number',
        key: 'phone',
        dataIndex: 'phone',
        width: 140,
        render: (item: any) => {
          return (
            <div>
              {(item?.admindetails || []).length > 0 ? (
                <>
                  {item?.admindetails?.length === 1 ? (
                    <>
                      {CommonService.convertPhoneNumber(
                        item?.admindetails[0]?.phoneNumber,
                      )}
                    </>
                  ) : (
                    <ToolTipComponent
                      // backgroundColor={'#fff'}
                      position={'right'}
                      textColor={'#FFFFFF'}
                      tooltip={
                        <>
                          {item?.admindetails.map(
                            (value1: any, index1: number) => {
                              return (
                                <div
                                  className={
                                    'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                  }
                                  key={index1}
                                >
                                  {CommonService.convertPhoneNumber(
                                    value1.phoneNumber,
                                  )}
                                </div>
                              );
                            },
                          )}
                        </>
                      }
                    >
                      <div className={'d-flex'}>
                        <ChipComponent
                          className={'scheduled cursor-pointer'}
                          size={'small'}
                          color={'primary'}
                          label={
                            CommonService.convertPhoneNumber(
                              item?.admindetails[0].phoneNumber,
                            ) +
                            ((item?.admindetails || []).length > 1
                              ? ' (+' +
                                ((item?.admindetails || []).length - 1) +
                                ')'
                              : '')
                          }
                        />
                      </div>
                    </ToolTipComponent>
                  )}
                </>
              ) : (
                'N/A'
              )}
            </div>
          );
        },
      },

      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 150,
        align: 'center',
        render: (item: any) => {
          return (
            <div>
              <TagComponent
                color={item?.isActive ? 'success' : 'danger'}
                showIndicator={true}
                label={item?.isActive ? 'Active' : 'Inactive'}
              />
            </div>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        fixed: 'right',
        width: 70,
        render: (item: any) => {
          return (
            <>
              <LinkComponent
                route={CommonService._routeConfig.AmenityViewRoute(item._id)}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </LinkComponent>
              &nbsp;&nbsp;
            </>
          );
        },
      },
    ];
  }, []);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._amenity
        .downloadAmenityListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data) {
            CommonService.downloadFile(
              response?.data?.url,
              'amenity_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Amenity List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'amenities-screen list-screen amenities-list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Amenity</div>
            <div className='list-screen-data-count'>
              <TagComponent color={'primary'} label={count + ' Amenities'} />
            </div>
          </div>
        </div>
        <div className='list-options'>
          <SearchComponent
            placeholder='Search amenity brand'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          <LinkComponent route={CommonService._routeConfig.AmenityAddRoute()}>
            <ButtonComponent
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
              color={'primary'}
            >
              Add New Amenity
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.GET_AMENITIES_PAGINATED_LIST.URL}
          method={APIConfig.GET_AMENITIES_PAGINATED_LIST.METHOD}
          id={'amenities'}
          moduleName={MODULE}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          columns={Columns}
          noDataText={
            filterData.search
              ? 'No such amenity exists'
              : 'No Amenities have been added yet'
          }
        />
      </div>
    </div>
  );
};

export default AmenitiesScreen;
