import "./AddPollsComponent.scss";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import AnswerDetailsComponent from "./answer-details/AnswerDetailsComponent";
import PollDetailsComponent from "./poll-details/PollDetailsComponent";
import PollTimingsComponent from "./poll-timings/PollTimingsComponent";

interface AddPollsComponentProps {

}

const POLL_DETAILS = 'poll-details';
const ANSWER_DETAILS = 'answer-details';
const POLL_TIMINGS = 'poll-timings';

const STEPS = [
    {
        title: 'Poll Details',
        id: POLL_DETAILS,
    },
    {
        title: 'Answer Details',
        id: ANSWER_DETAILS,
    },
    {
        title: 'Poll Timings',
        id: POLL_TIMINGS,
    },
];


const AddPollsComponent = (props: AddPollsComponentProps) => {
    const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
    const navigate = useNavigate();
    // const [activeStepId, setActiveStepId] = useState<string>(steps[0].id);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
    const [pollId, setPollId] = useState<any>("");
    const [pollsDetails, setPollDetails] = useState<any>(undefined);
    const [isPollDetailsLoading, setIsPollDetailsLoading] = useState<boolean>(false);
    const location: any = useLocation();
    const path = location.pathname;

    const updateSteps = useCallback((currentStepIndex: number) => {
         setCurrentStep(steps[currentStepIndex]);
        searchParams.set("step", steps[currentStepIndex].id);
        setSearchParams(searchParams);
        // setActiveStepId(steps[currentStepIndex].id);
    }, [searchParams, setSearchParams, steps]);

    const getPollDetails = useCallback(() => {
        const payload = {};
        let pollsDetails: any = {};
        setIsPollDetailsLoading(true);
        CommonService._poll.GetPollDetailsAPICall(pollId, payload)
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    pollsDetails = response.data;
                }
                setPollDetails(pollsDetails);
                setIsPollDetailsLoading(false);
            })
            .catch((error: any) => {
                // setPollDetails(pollsDetails);
                setIsPollDetailsLoading(false);
            });
    }, [pollId]);

    useEffect(() => {
        if (pollId) {
            getPollDetails();
        }
    }, [getPollDetails, pollId])

    useEffect(() => {
        const step: any = searchParams.get("step");
        // const stepperIndex = steps.findIndex(stepItem => stepItem.id === step);
        // setActiveStepId(steps[stepperIndex]?.id);
        const pollId = searchParams.get("pollId");
        setPollId(pollId);
        const stepIDS = steps.map(item => item.id);
        let stepIndex: number = 0;
        if (stepIDS.indexOf(step) > -1) {
            if (step !== POLL_DETAILS) {
                stepIndex = stepIDS.indexOf(step);
            } else {
                stepIndex = 0;
            }
        } else {
            stepIndex = 0;
        }
        setCurrentStep(steps[stepIndex]);
    }, [searchParams, steps]);

    const handlePrevious = useCallback((currentStepIndex: number, currentStepId: string) => {
        if ([ANSWER_DETAILS, POLL_TIMINGS].includes(currentStepId)) {
            updateSteps(currentStepIndex);
        } else {
            // do nothing;
        }
    }, [updateSteps]);

    const handleBack = useCallback(() => {
        if (path.includes('/poll/add')) {
            navigate(CommonService._routeConfig.PollsListRoute());
        } else {
            navigate(CommonService._routeConfig.PollsDetails(pollId));
        }
    }, [navigate, pollId,path])

    const handleNext = useCallback((currentStepIndex: number, currentStepId: string, data?: any) => {
        switch (currentStepId) {
            case POLL_DETAILS :
                setPollDetails((oldSate: any) => {
                    return {
                        ...oldSate,
                        title: data.title,
                        question: data.question
                    }
                })
                updateSteps(currentStepIndex);
                break;

            case ANSWER_DETAILS:
                if (data) {
                    setPollDetails((oldSate: any) => {
                        return {
                            ...oldSate,
                            answerType: data.answerType,
                            answerSubType: data.answerSubType,
                            answerOptions: data.answerOptions
                        }
                    })
                }
                updateSteps(currentStepIndex);
                break;
            case POLL_TIMINGS:
                handleBack();
                break;
            default:
                <></>
        }
    }, [updateSteps, handleBack]);

    const renderComponent = useCallback(() => {
        switch (currentStep && currentStep.id) {
            case POLL_DETAILS:
                return <PollDetailsComponent
                    next={(data: any) => handleNext(1, POLL_DETAILS, data)}
                    prev={handleBack}
                    steps={steps}
                    activeStepId={currentStep.id}
                    pollsDetails={pollsDetails}
                />
            case ANSWER_DETAILS:
                return <AnswerDetailsComponent
                    pollsDetails={pollsDetails}
                    next={(data: any) => handleNext(2, ANSWER_DETAILS, data)}
                    prev={() => handlePrevious(0, ANSWER_DETAILS)}
                    steps={steps}
                    activeStepId={currentStep.id}
                />
            case POLL_TIMINGS:
                return <PollTimingsComponent
                    pollsDetails={pollsDetails}
                    next={() => handleNext(3, POLL_TIMINGS, undefined)}
                    prev={() => handlePrevious(1, POLL_TIMINGS)}
                    steps={steps}
                    activeStepId={currentStep.id}
                />
            default:
                <></>
        }
    }, [currentStep, handleNext, handlePrevious, pollsDetails, handleBack, steps]);

    return (
        <div className={'add-polls-component add-screen'}>
            {
                isPollDetailsLoading ?
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div> : <>
                        {renderComponent()}
                    </>
            }
        </div>
    );

};

export default AddPollsComponent;