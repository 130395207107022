import React, { useCallback, useEffect, useState } from 'react';
import './ForecastingStatsComponent.scss';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import BarChartComponent from '../../../shared/components/charts/bar-chart/BarChartComponent';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface ForecastingStatsComponentProps {
  entityList: any;
  propertyList: any;
  tenantList: any;
  amenityList: any;
}

const yearOptions = [
  {
    title: '3 Years',
    id: 3,
  },
  {
    title: '5 Years',
    id: 5,
  },
  {
    title: '7 Years',
    id: 7,
  },
];

const invoiceCategoryType = [
  {
    title: 'Tenant',
    id: 'tenant',
  },
  {
    title: 'Amenity',
    id: 'amenity',
  },
];

const ForecastingStatsComponent = (props: ForecastingStatsComponentProps) => {
  const { entityList, propertyList, tenantList, amenityList } = props;
  const [updatedPropertyList, setUpdatedPropertyList] = useState<any[]>([]);
  const [updatedTenantList, setUpdatedTenantList] = useState<any[]>([]);
  const [updatedAmenityList, setUpdatedAmenityList] = useState<any[]>([]);
  const [forecastLoading, setForecastLoading] = useState<boolean>(false);
  const [forecastData, setForecastData] = useState<any[]>([]);
  const [type, setType] = useState<string>('');
  const [entityId, setEntityId] = useState<string>('');
  const [propertyId, setPropertyId] = useState<string>('');
  const [tenantId, setTenantId] = useState<string>('');
  const [amenityId, setAmenityId] = useState<string>('');
  const [towerId, setTowerId] = useState<string>('');
  const [floor, setFloor] = useState<any>({});
  const [year, setYear] = useState<string>('');
  const [towerList, setTowerList] = useState<any[]>([]);
  const [floorList, setFloorList] = useState<any[]>([]);

  const getForecastStats = useCallback(() => {
    setForecastLoading(true);
    let payload: any = {
      type: type,
      entityId: entityId,
      propertyId: propertyId,
      tenantId: tenantId,
      amenityId: amenityId,
      towerId: towerId,
      floor: floor,
      year: year,
    };
    CommonService._dashboard
      .InvoiceForecastStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setForecastData(response?.data);
      })
      .catch(() => {
        CommonService._alert.showToast('Failed to load forecast data', 'error');
      })
      .finally(() => {
        setForecastLoading(false);
      });
  }, [entityId, propertyId, tenantId, amenityId, towerId, floor, year, type]);

  // useEffect(() => {
  //   if (
  //     type &&
  //     entityId &&
  //     propertyId &&
  //     (tenantId || amenityId) &&
  //     towerId &&
  //     Object.keys(floor).length &&
  //     year
  //   ) {
  //     getForecastStats();
  //   }
  // }, [
  //   getForecastStats,
  //   entityId,
  //   propertyId,
  //   tenantId,
  //   amenityId,
  //   towerId,
  //   floor,
  //   year,
  //   type,
  // ]);

  useEffect(() => {
    getForecastStats();
  }, [getForecastStats]);

  const getTowerList = useCallback((id: string, type: string) => {
    let apiCall: any;
    let payload: any = {};
    if (type === 'tenant') {
      apiCall = CommonService._tenants.GetTenantsDetailsAPICall(id, payload);
    } else {
      apiCall = CommonService._amenity.GetAmenityDetailsAPICall(id, payload);
    }
    apiCall
      .then((response: IAPIResponseType<any>) => {
        const towers = response?.data?.towers?.towerBreakdown?.map(
          (tower: any) => ({
            name: tower?.name,
            id: tower?.towerId,
            floors:
              tower.floorBreakdown.length === 0
                ? []
                : tower.floorBreakdown.map((floor: any) => ({
                    name: 'Office Floor - ' + floor.title,
                    id: floor.id,
                    data: {
                      type: 'officeFloor',
                      id: floor.id,
                    },
                  })),
            basements:
              tower.basementBreakdown.length === 0
                ? []
                : tower.basementBreakdown.map((basement: any) => ({
                    name: 'Basement - ' + basement.title,
                    id: basement.id,
                    data: {
                      type: 'basement',
                      id: basement.id,
                    },
                  })),
            hardOption:
              tower.hardOptionBreakdown.length === 0
                ? []
                : tower.hardOptionBreakdown.map((hardOption: any) => ({
                    name: 'Hard Option - ' + hardOption.title,
                    id: hardOption.id,
                    data: {
                      type: 'hardOption',
                      id: hardOption.id,
                    },
                  })),
          }),
        );
        setTowerList(towers);
      })
      .catch(() => {
        CommonService._alert.showToast('Failed to get floor list', 'error');
      });
  }, []);

  const getFloorList = useCallback(
    (towerId: any) => {
      const data = towerList?.find((tower: any) => tower.id === towerId);
      const combinedFloors = [
        ...data.floors,
        ...data.basements,
        ...data.hardOption,
      ];
      setFloorList(combinedFloors);
    },
    [towerList],
  );

  return (
    <div className='forecasting-stats-component'>
      <div className='forecasting-stats-wrapper'>
        <div className=''>
          <FormControlLabelComponent
            label='Invoice Forecasting'
            isBold
            level={4}
            className='mrg-top-10 nowrap'
          />
          <div className='mrg-top-20'>
            <div className='forecasting-dropdowns-container'>
              <div className=''>
                <SelectDropdownComponent
                  placeholder='Type'
                  options={invoiceCategoryType}
                  value={type}
                  fullWidth
                  displayWith={(option: any) => option.title}
                  valueExtractor={(option: any) => option.id}
                  onUpdate={(value) => {
                    setType(value ? value : '');
                    setEntityId('');
                    setTenantId('');
                    setAmenityId('');
                    setPropertyId('');
                    setTowerId('');
                    setFloor({});
                    setYear('');
                  }}
                />
              </div>
              <div className=''>
                <SelectDropdownComponent
                  placeholder='Entity'
                  options={entityList}
                  value={entityId}
                  fullWidth
                  disabled={!type}
                  displayWith={(option: any) => option.name}
                  valueExtractor={(option: any) => option._id}
                  onUpdate={(value) => {
                    setEntityId(value ? value : '');
                    const updatedPropertyList = propertyList?.filter(
                      (property: any) => property.billingEntityId === value,
                    );
                    setUpdatedPropertyList(updatedPropertyList);
                    setPropertyId('');
                    setTenantId('');
                    setAmenityId('');
                    setTowerId('');
                    setFloor({});
                    setYear('');
                  }}
                />
              </div>
              <div className=''>
                <SelectDropdownComponent
                  placeholder='Property'
                  options={updatedPropertyList}
                  value={propertyId}
                  disabled={!entityId}
                  fullWidth
                  displayWith={(option: any) => option.name}
                  valueExtractor={(option: any) => option._id}
                  onUpdate={(value) => {
                    setPropertyId(value ? value : '');
                    if (type === 'tenant') {
                      const updatedTenantList = tenantList?.filter(
                        (tenant: any) => tenant.propertyId === value,
                      );
                      setUpdatedTenantList(updatedTenantList);
                    }
                    if (type === 'amenity') {
                      const updatedAmenityList = amenityList?.filter(
                        (amenity: any) => amenity.propertyId === value,
                      );
                      setUpdatedAmenityList(updatedAmenityList);
                    }
                    setTenantId('');
                    setAmenityId('');
                    setTowerId('');
                    setFloor({});
                    setYear('');
                  }}
                />
              </div>

              {type === 'tenant' && (
                <div className=''>
                  <SelectDropdownComponent
                    placeholder='Tenant'
                    options={updatedTenantList}
                    value={tenantId}
                    disabled={!propertyId}
                    fullWidth
                    displayWith={(option: any) => option.name}
                    valueExtractor={(option: any) => option._id}
                    onUpdate={(value) => {
                      setTenantId(value ? value : '');
                      if (value) {
                        getTowerList(value, 'tenant');
                      }
                      setAmenityId('');
                      setTowerId('');
                      setFloor({});
                      setYear('');
                    }}
                  />
                </div>
              )}
              {type === 'amenity' && (
                <div className=''>
                  <SelectDropdownComponent
                    placeholder='Amenity'
                    options={updatedAmenityList}
                    value={amenityId}
                    disabled={!propertyId}
                    fullWidth
                    displayWith={(option: any) => option.name}
                    valueExtractor={(option: any) => option._id}
                    onUpdate={(value) => {
                      setAmenityId(value ? value : '');
                      if (value) {
                        getTowerList(value, 'amenity');
                      }
                      setTenantId('');
                      setTowerId('');
                      setFloor({});
                      setYear('');
                    }}
                  />
                </div>
              )}
              <div>
                <SelectDropdownComponent
                  placeholder='Tower'
                  options={towerList}
                  value={towerId}
                  disabled={type === 'tenant' ? !tenantId : !amenityId}
                  fullWidth
                  displayWith={(option: any) => option.name}
                  valueExtractor={(option: any) => option.id}
                  onUpdate={(value) => {
                    setTowerId(value ? value : '');
                    if (value) {
                      getFloorList(value);
                    }
                    setFloor({});
                    setYear('');
                  }}
                />
              </div>
              <div>
                <SelectDropdownComponent
                  placeholder='Floor'
                  options={floorList}
                  disabled={!towerId}
                  value={floor}
                  fullWidth
                  displayWith={(option: any) => option.name}
                  valueExtractor={(option: any) => option.data}
                  onUpdate={(value) => {
                    setFloor(value ? value : {});
                    setYear('');
                  }}
                />
              </div>
              <div>
                <SelectDropdownComponent
                  placeholder='Year'
                  options={yearOptions}
                  fullWidth
                  disabled={!Object.keys(floor).length}
                  displayWith={(option: any) => option.title}
                  valueExtractor={(option: any) => option.id}
                  value={year}
                  onUpdate={(value) => {
                    setYear(value ? value : '');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {entityId &&
        propertyId &&
        (tenantId || amenityId) &&
        towerId &&
        Object.keys(floor).length &&
        year ? (
          <div className='forecasting-chart-wrapper'>
            <BarChartComponent
              apiResponse={forecastData}
              loading={forecastLoading}
            />
          </div>
        ) : (
          <div className='forecasting-loading-wrapper'>
            Select all the filters to get forecasting data.
          </div>
        )} */}
        <div className='forecasting-chart-wrapper'>
          <BarChartComponent
            apiResponse={forecastData}
            loading={forecastLoading}
          />
        </div>
        <div className='d-flex mrg-top-20'>
          <div className='forcasting-legend'>
            <div className='forcasting-legend-dotone'></div>
            <div>Rent</div>
          </div>
          <div className='forcasting-legend'>
            <div className='forcasting-legend-dottwo'></div>
            <div>Building CAM</div>
          </div>
          <div className='forcasting-legend'>
            <div className='forcasting-legend-dotthree'></div>
            <div>Campus CAM</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForecastingStatsComponent;
