import './AddAnnouncementComponent.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { CommonService } from '../../../shared/services';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import * as Yup from 'yup';
import FormikSelectDropdownComponent from '../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikTextAreaComponent from '../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FilePickerComponent from '../../../shared/components/file-picker/FilePickerComponent';
import { CloseIcon } from '../../../constants/ImageConfig';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { BuildingMaintenanceAndOperations } from '../../../shared/services/common.service';

interface AddAnnouncementComponentProps {
  type?: string;
}

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Announcement Type is required').nullable(),
  poll: Yup.mixed().when('type', {
    is: 'Poll',
    then: Yup.mixed().required('Poll is required').nullable(),
  }),
  amenity: Yup.mixed().when('type', {
    is: 'Amenity',
    then: Yup.mixed().required('Amenity is required').nullable(),
  }),
  event: Yup.mixed().when('type', {
    is: 'Events',
    then: Yup.mixed().required('Event is required').nullable(),
  }),
  property: Yup.mixed().when('type', {
    is: BuildingMaintenanceAndOperations,
    then: Yup.mixed().required('Property is required').nullable(),
  }),
  properties: Yup.array().when('type', (type, schema) => {
    if (type === 'General' || type === 'Default') {
      return schema
        .required('Properties is required')
        .min(1, 'Properties is required')
        .nullable();
    } else return schema;
  }),
  title: Yup.string()
    .min(10, 'Announcement title should be at least 10 characters')
    .max(80, 'Announcement title should not exceed 80 characters')
    .required('Announcement title is required'),
  description: Yup.string()
    .required('Announcement Description is required')
    .max(500, 'Announcement Description should not exceed 500 characters'),
  image: Yup.mixed(),
});

interface formInitialValuesFormSchema {
  type: string;
  poll?: any;
  event?: any;
  property?: any;
  properties?: any[];
  amenity?: any;
  title: string;
  description: string;
  image?: any;
  isImageRemoved: boolean;
}

const formInitialValues: formInitialValuesFormSchema = {
  type: '',
  title: '',
  description: '',
  image: undefined,
  isImageRemoved: false,
};

const AddAnnouncementComponent = (props: AddAnnouncementComponentProps) => {
  const navigate = useNavigate();
  const [isAddAnnouncementLoading] = useState<boolean>(false);
  const { announcementId }: any = useParams();
  // const path = location.pathname;
  const [announcementDetails, setAnnouncementDetails] = useState<any>(null);
  const getAnnouncementDetails = useCallback((announcementId: string) => {
    CommonService._wall
      .getAnnouncement(announcementId)
      .then((response: IAPIResponseType<any>) => {
        setAnnouncementDetails(response.data || []);
      })
      .catch((error: any) => {
        setAnnouncementDetails(null);
      });
  }, []);

  useEffect(() => {
    if (announcementId) {
      getAnnouncementDetails(announcementId);
    }
  }, [announcementId, getAnnouncementDetails]);

  const [announcementTypes, setAnnouncementTypes] = useState<any[]>([]);

  const getAnnouncementTypes = useCallback(() => {
    CommonService._wall
      .getAnnouncementTypes()
      .then((response: IAPIResponseType<any>) => {
        setAnnouncementTypes(response.data || []);
      })
      .catch((error: any) => {
        setAnnouncementTypes([]);
      });
  }, []);

  useEffect(() => {
    getAnnouncementTypes();
  }, [getAnnouncementTypes]);

  const handleBack = useCallback(() => {
    navigate(
      CommonService._routeConfig.AnnouncementListRoute() +
        '?tab=' +
        (props.type === 'Default' ? 'default' : 'all'),
    );
  }, [navigate, props]);

  const addAnnouncement = useCallback(
    (
      values: formInitialValuesFormSchema,
      helpers: FormikHelpers<formInitialValuesFormSchema>,
    ) => {
      const payload: any = {};
      payload['type'] = values.type;
      payload['title'] = values.title;
      payload['isDefault'] = values.type === 'Default';
      payload['description'] = values.description;
      payload['isImageRemoved'] = values.isImageRemoved;
      if (values.type === 'Poll') {
        payload['linkedTo'] = values.poll;
      } else if (values.type === 'Amenity') {
        payload['linkedTo'] = values.amenity;
      } else if (values.type === 'Events') {
        payload['linkedTo'] = values.event;
      } else if (values.type === 'General' || values.type === 'Default') {
        payload['properties'] = values.properties;
      } else if (values.type === BuildingMaintenanceAndOperations) {
        payload['properties'] = [values.property];
      }

      const formData = CommonService.getFormDataFromJSON(payload);
      if (values.image) {
        formData.set('image', values.image);
      }

      if (announcementDetails) {
        CommonService._wall
          .editAnnouncement(announcementDetails._id, formData)
          .then((response: IAPIResponseType<any>) => {
            helpers.setSubmitting(false);
            handleBack();
            CommonService._alert.showToast(response.message, 'success');
          })
          .catch((error: any) => {
            helpers.setSubmitting(false);
            CommonService._alert.showToast(error.error, 'error');
          });
      } else {
        CommonService._wall
          .addAnnouncement(formData)
          .then((response: IAPIResponseType<any>) => {
            // setAddAnnuncement(propertyDetails);
            helpers.setSubmitting(false);
            handleBack();
            CommonService._alert.showToast(response.message, 'success');
          })
          .catch((error: any) => {
            helpers.setSubmitting(false);
            // CommonService._alert.showToast(error.error, 'error');
            if (error.errors) {
              for (let key in error.errors) {
                if (key === 'properties') {
                  CommonService._alert.showToast(error.errors[key], 'error');
                  //     // helpers.setFieldError('image', error.errors[key]);
                }
              }
              CommonService.handleErrors(error.errors, helpers);
            } else if (error.error) {
              CommonService._alert.showToast(error.error, 'error');
            }
          });
      }
    },
    [handleBack, announcementDetails],
  );

  const [propertyList, setPropertyList] = useState<any[]>([]);
  const getPropertyList = useCallback((searchText: string) => {
    CommonService._property
      .GetPropertyListLiteAPICall({ search: searchText })
      .then((response: IAPIResponseType<any>) => {
        setPropertyList(response.data || []);
      })
      .catch((error: any) => {
        setPropertyList([]);
      });
  }, []);

  useEffect(() => {
    getPropertyList('');
  }, [getPropertyList]);

  const [pollList, setPollList] = useState<any[]>([]);
  const getPollList = useCallback(() => {
    const payload = { statuses: 'Ongoing,Upcoming,Completed' };
    CommonService._poll
      .GetPollListAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setPollList(response.data || []);
      })
      .catch((error: any) => {
        setPollList([]);
      });
  }, []);

  useEffect(() => {
    getPollList();
  }, [getPollList]);

  const [amenitiesList, setAmenitiesList] = useState<any[]>([]);
  const getAmenitiesList = useCallback(() => {
    CommonService._amenity
      .GetAmenitiesList()
      .then((response: IAPIResponseType<any>) => {
        setAmenitiesList(response.data || []);
      })
      .catch((error: any) => {
        setAmenitiesList([]);
      });
  }, []);

  useEffect(() => {
    getAmenitiesList();
  }, [getAmenitiesList]);

  const [eventsList, setEventsList] = useState<any[]>([]);
  const getEventsList = useCallback(() => {
    CommonService._events
      .GetEventsList()
      .then((response: IAPIResponseType<any>) => {
        setEventsList(response.data || []);
      })
      .catch((error: any) => {
        setAmenitiesList([]);
      });
  }, []);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  return (
    <div className={'add-announcement-component'}>
      {isAddAnnouncementLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      ) : (
        <>
          <div className={'add-announcement-details add-screen'}>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                ...formInitialValues,
                title: announcementDetails?.title || '',
                description: announcementDetails?.description || '',
                type: announcementDetails?.type || '',
                ...(announcementDetails?.type &&
                announcementDetails?.type === 'Amenity'
                  ? { amenity: announcementDetails.linkedTo }
                  : {}),
                ...(announcementDetails?.type &&
                announcementDetails?.type === 'Poll'
                  ? { poll: announcementDetails.linkedTo }
                  : {}),
                ...(announcementDetails?.type &&
                announcementDetails?.type === 'Events'
                  ? { event: announcementDetails.linkedTo }
                  : {}),
                ...(announcementDetails?.type &&
                announcementDetails?.type === BuildingMaintenanceAndOperations
                  ? {
                      property:
                        (announcementDetails?.propertyDetails || []).length > 0
                          ? (announcementDetails?.propertyDetails || [])[0]._id
                          : null,
                    }
                  : {
                      properties:
                        (announcementDetails?.propertyDetails || []).map(
                          (property: any) => property._id,
                        ) || [],
                    }),
                ...(props.type ? { type: props.type } : {}),
              }}
              validateOnChange={true}
              validateOnBlur={false}
              enableReinitialize={true}
              validateOnMount={true}
              onSubmit={addAnnouncement}
            >
              {({
                values,
                errors,
                validateForm,
                isValid,
                setFieldValue,
                isSubmitting,
              }) => {
                return (
                  <Form className='t-form scroll-content' noValidate={true}>
                    {/* <FormDebuggerComponent
                      values={values}
                      errors={errors}
                      showDebugger={false}
                    /> */}
                    <div className='add-component-header'>
                      {
                        <ButtonComponent
                          variant={'text'}
                          color={'secondary'}
                          onClick={handleBack}
                          prefixIcon={<ImageConfig.ArrowLeftIcon />}
                        >
                          Exit
                        </ButtonComponent>
                      }
                      <div className='add-layout-header-text'>
                        Announcement Details
                      </div>
                      <ButtonComponent
                        disabled={isSubmitting || !isValid}
                        isLoading={isSubmitting}
                        type={'submit'}
                      >
                        Save
                      </ButtonComponent>
                    </div>

                    <div className='add-component-content'>
                      {values.type !== 'Default' && (
                        <Field name={'type'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              label={'Announcement Type'}
                              isClearable={false}
                              options={announcementTypes}
                              displayWith={(option) => option.title}
                              valueExtractor={(option) => option.code}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}

                      {values.type && values.type === 'Amenity' && (
                        <Field name={'amenity'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              label={'Select Amenity'}
                              url={APIConfig.GET_AMENITIES_LIST.URL}
                              method={APIConfig.GET_AMENITIES_LIST.METHOD}
                              options={amenitiesList}
                              dataListKey={'data'}
                              searchMode={'serverSide'}
                              placeholder={'Select Amenity'}
                              searchable={true}
                              isClearable={false}
                              displayWith={(option) => option.name}
                              valueExtractor={(option) => option._id}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}

                      {values.type && values.type === 'Events' && (
                        <Field name={'event'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              label={'Select Event'}
                              url={APIConfig.EVENTS_LIST.URL}
                              method={APIConfig.EVENTS_LIST.METHOD}
                              searchMode={'serverSide'}
                              options={eventsList}
                              placeholder={'Select Event'}
                              searchable={true}
                              dataListKey={'data'}
                              isClearable={false}
                              displayWith={(option) => option.name}
                              valueExtractor={(option) => option._id}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}
                      {values.type && values.type === 'Poll' && (
                        <Field name={'poll'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              label={'Select Poll'}
                              url={APIConfig.GET_POLL_LIST.URL}
                              method={APIConfig.GET_POLL_LIST.METHOD}
                              searchMode={'serverSide'}
                              placeholder={'Select Poll'}
                              options={pollList}
                              extraPayload={{
                                statuses: 'Ongoing,Upcoming,Completed',
                              }}
                              dataListKey={'data'}
                              // // defaultData={pollList}
                              searchable={true}
                              isClearable={false}
                              displayWith={(option) => option.title}
                              valueExtractor={(option) => option._id}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}

                      <Field name={'title'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Announcement Title'}
                            placeholder={'Enter Announcement Title'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>

                      <Field name={'description'}>
                        {(field: FieldProps) => (
                          <FormikTextAreaComponent
                            label={'Announcement Description'}
                            placeholder={
                              'Enter the announcement description here'
                            }
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                      <br />

                      {((values.type && values.type === 'General') ||
                        (values.type && values.type === 'Default')) && (
                        <Field name={'properties'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              label={'Select Property(s)'}
                              searchMode={'clientSide'}
                              searchable={true}
                              options={propertyList}
                              placeholder={'Select Property(s)'}
                              isClearable={false}
                              displayWith={(option) => option.name}
                              valueExtractor={(option) => option._id}
                              required={true}
                              multiple={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      )}

                      {values.type &&
                        values.type === BuildingMaintenanceAndOperations && (
                          <Field name={'property'}>
                            {(field: FieldProps) => (
                              <FormikSelectDropdownComponent
                                label={'Select Property'}
                                searchMode={'clientSide'}
                                searchable={true}
                                options={propertyList}
                                placeholder={'Select Property'}
                                isClearable={false}
                                displayWith={(option) => option.name}
                                valueExtractor={(option) => option._id}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        )}

                      <div className={'flex-wrapper'}>
                        <FormControlLabelComponent label={'Upload Image'} />
                        <div className={'optional-text'}>Optional</div>
                      </div>
                      {!!announcementDetails &&
                        announcementDetails.image &&
                        !values.image && (
                          <div className='image-preview'>
                            <div
                              className='close-btn'
                              onClick={(event) => {
                                event.preventDefault();
                                setAnnouncementDetails({
                                  ...announcementDetails,
                                  image: null,
                                });
                                setFieldValue('isImageRemoved', true);
                              }}
                            >
                              <CloseIcon />
                            </div>
                            <img src={announcementDetails.image.url} alt='' />
                          </div>
                        )}

                      {!!values.image && (
                        <div className='image-preview'>
                          <div
                            className='close-btn'
                            onClick={(event) => {
                              event.preventDefault();
                              setFieldValue('image', undefined);
                            }}
                          >
                            <CloseIcon />
                          </div>
                          <img src={URL.createObjectURL(values.image)} alt='' />
                        </div>
                      )}
                      <FilePickerComponent
                        acceptedFileTypes={['png', 'jpeg', 'jpg']}
                        showDropZone={true}
                        onFilesDrop={(acceptedFiles) => {
                          setFieldValue('image', acceptedFiles[0]);
                        }}
                        acceptedFilesText={'(Upload Only one image)'}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </div>
  );
};

export default AddAnnouncementComponent;
