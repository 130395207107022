import './BillingPreferenceComponent.scss';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { ImageConfig, Misc } from '../../../../constants';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikDatePickerComponent from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import moment from 'moment';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikCheckBoxComponent from '../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent';
import AddBillingCardComponent from '../../../../shared/components/add-billing-card/AddBillingCardComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';
import FormikSwitchComponent from '../../../../shared/components/form-controls/formik-switch/FormikSwitchComponent';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';

interface BillingPreferenceComponentProps {
  tenantDetails?: any;
  prev?: () => void;
  next?: () => void;
  steps: any[];
  activeStepId: string;
}

const floorValidationSchema = Yup.object().shape({
  floorNumber: Yup.number().required('Floor Number is required'),
  isFloorSelected: Yup.boolean().required('Floor selection is required'),
  areaOccupied: Yup.number().required('Area Occupied is required'),
  ratePerSft: Yup.number().when('isFloorSelected', {
    is: true,
    then: Yup.number().required('Rate Per SFT is required'),
  }),
});

const towerPricingBreakdownValidationSchema = Yup.object().shape({
  towerId: Yup.string().required('Tower ID is required'),
  towerName: Yup.string().required('Tower Name is required'),
  floors: Yup.array().of(floorValidationSchema),
});

const escalationValidationSchema = Yup.object().shape({
  rateOfEscalation: Yup.number().required('Rate Of Escalation is required'),
  escalationsStartsWith: Yup.string().required(
    'Escalations Starts With is required',
  ),
  escalationCustomDate: Yup.string().when('escalationsStartsWith', {
    is: 'custom',
    then: Yup.string().required('Escalation Custom Date is required'),
  }),
  recurrentSpan: Yup.number().required('Recurrent Span is required'),
});

const rentCamValidationSchema = Yup.object().shape({
  startsFrom: Yup.string().required('Starts From is required'),
  totalOccupiedAreaWithoutHardFloors: Yup.number().required(
    'Total Occupied Area Without Hard Options is required',
  ),
  ratePerSft: Yup.number().required('Rate Per SFT is required'),
  isHardFloorsEnabled: Yup.boolean().required(
    'Is Hard Options Enabled is required',
  ),
  hardFloorTowerPricingBreakdown: Yup.array().when('isHardFloorsEnabled', {
    is: true, // only validate the array if isHardFloorsEnabled is true
    then: Yup.array().of(towerPricingBreakdownValidationSchema).required(),
    otherwise: Yup.array(),
  }),
  isEscalationsEnabled: Yup.boolean().required(
    'Is Escalations Enabled is required',
  ),
  escalation: Yup.object().when('isEscalationsEnabled', {
    is: true, // only apply the following schema if isEscalationsEnabled is true
    then: escalationValidationSchema.required(),
    otherwise: Yup.object(),
  }),
  isEscalationAppliedToHardFloors: Yup.boolean().required(
    'Is Escalation Applied To Hard Options is required',
  ),
});

const validationSchema = Yup.object({
  gstNumber: Yup.string()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'Invalid GST Number',
    )
    .required('GST Number is required'),
  panNumber: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
    .required('PAN Number is required'),
  leaseCommencementDate: Yup.string().required(
    'Lease Commencement Date is required',
  ),
  rentCommencementDate: Yup.string().required(
    'Rent Commencement Date is required',
  ),
  invoiceDueDateDuration: Yup.string()
    .required('Invoice Due Date Duration is required')
    .nullable(),
  isRentSelected: Yup.boolean(),
  isCamSelected: Yup.boolean(),
  isSignageSelected: Yup.boolean(),
  isSecurityDepositSelected: Yup.boolean(),
  isParkingSelected: Yup.boolean(),
  billingComponents: Yup.object().shape({
    rent: Yup.object().when('isRentSelected', {
      is: true,
      then: rentCamValidationSchema,
      otherwise: Yup.object(),
    }),

    cam: Yup.object().when('isCamSelected', {
      is: true,
      then: rentCamValidationSchema,
      otherwise: Yup.object(),
    }),
    signageAmount: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .typeError('Signage Amount must be a number'),
    securityDeposit: Yup.number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .typeError('Security Deposit Amount must be a number'),
    parking: Yup.object().when('isParkingSelected', {
      is: true,
      then: Yup.object().shape({
        numberOfSlots: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value))
          .typeError('Number of slots must be a number')
          .required('Number of slots is required'),
        perSlotPrice: Yup.number()
          .nullable()
          .transform((value) => (isNaN(value) ? null : value))
          .typeError('Per slot price must be a number')
          .required('Per slot price is required'),
        additionalParkingSlots: Yup.array().when(
          'enableAdditionalParkingSlots',
          {
            is: true,
            then: Yup.array().of(
              Yup.object().shape({
                numberOfSlots: Yup.number()
                  .nullable()
                  .transform((value) => (isNaN(value) ? null : value))
                  .typeError('Number of slots must be a number')
                  .required('Number of slots is required'),
                perSlotPrice: Yup.number()
                  .nullable()
                  .transform((value) => (isNaN(value) ? null : value))
                  .typeError('Per slot price must be a number')
                  .required('Per slot price is required'),
                invoiceStartDate: Yup.string().required(
                  'Invoice start date is required',
                ),
              }),
            ),
          },
        ),
      }),
    }),
  }),
});

interface EscalationTowerSchema {
  number: string;
  towerName: string;
  rateOfEscalation: string;
  escalationsStartsWith: string;
  escalationCustomDate: any;
  recurrentSpan: string;
}

interface EscalationSchema {
  towers: [
    {
      towerId: '';
      towerName: '';
    },
  ];
  escalationList: EscalationTowerSchema[];
  id: '';
  number: '';
  towerName: '';
  rateOfEscalation: '';
  escalationsStartsWith: '';
  escalationCustomDate: '';
  recurrentSpan: '';
}

interface TowerSchema {
  id: string;
  number: string;
  areaOccupied: string;
  ratePerSft: string;
  rentStartFrom: string;
  rentStartFromDate: string;
  isSelected: boolean;
  escalationApplied: boolean;
}

interface RentCamSchema {
  towers: [
    {
      towerId: string;
      towerName: string;
      officeFloors: TowerSchema[];
      basements: TowerSchema[];
      hardOptions: TowerSchema[];
    },
  ];
  isEscalationsEnabled: boolean;
  escalations: EscalationSchema;
}

interface BillingComponentsSchema {
  rent: RentCamSchema;
  cam: RentCamSchema;
  campusCam: RentCamSchema;
  signageAmount: number;
  securityDeposit: number;
  parking: {
    numberOfSlots: string;
    perSlotPrice: string;
    enableAdditionalParkingSlots: boolean;
    additionalParkingSlots: [
      {
        numberOfSlots: string;
        perSlotPrice: string;
        invoiceStartDate: any;
      },
    ];
  };
}

interface formInitialValuesFormSchema {
  gstNumber: string;
  panNumber: string;
  leaseCommencementDate: string;
  rentCommencementDate: string;
  invoiceDueDateDuration: string;
  isRentSelected: boolean;
  isCamSelected: boolean;
  isCampusCamSelected: boolean;
  isSignageSelected: boolean;
  isSecurityDepositSelected: boolean;
  isParkingSelected: boolean;
  billingComponents: BillingComponentsSchema;
}

const formInitialValues: formInitialValuesFormSchema = {
  gstNumber: '',
  panNumber: '',
  leaseCommencementDate: '',
  rentCommencementDate: '',
  invoiceDueDateDuration: '',
  isRentSelected: false,
  isCamSelected: false,
  isCampusCamSelected: false,
  isSignageSelected: false,
  isSecurityDepositSelected: false,
  isParkingSelected: false,
  billingComponents: {
    rent: {
      towers: [
        {
          towerId: '',
          towerName: '',
          officeFloors: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
          basements: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],

          hardOptions: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
        },
      ],

      isEscalationsEnabled: false,
      escalations: {
        towers: [
          {
            towerId: '',
            towerName: '',
          },
        ],
        escalationList: [],
        id: '',
        number: '',
        towerName: '',
        rateOfEscalation: '',
        escalationsStartsWith: '',
        escalationCustomDate: '',
        recurrentSpan: '',
      },
    },
    cam: {
      towers: [
        {
          towerId: '',
          towerName: '',
          officeFloors: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
          basements: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],

          hardOptions: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
        },
      ],
      isEscalationsEnabled: false,
      escalations: {
        towers: [
          {
            towerId: '',
            towerName: '',
          },
        ],
        escalationList: [],
        id: '',
        number: '',
        towerName: '',
        rateOfEscalation: '',
        escalationsStartsWith: '',
        escalationCustomDate: '',
        recurrentSpan: '',
      },
    },
    campusCam: {
      towers: [
        {
          towerId: '',
          towerName: '',
          officeFloors: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
          basements: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],

          hardOptions: [
            {
              id: '',
              number: '',
              areaOccupied: '',
              ratePerSft: '',
              rentStartFrom: '',
              rentStartFromDate: '',
              isSelected: false,
              escalationApplied: false,
            },
          ],
        },
      ],
      isEscalationsEnabled: false,
      escalations: {
        towers: [
          {
            towerId: '',
            towerName: '',
          },
        ],
        escalationList: [],
        id: '',
        number: '',
        towerName: '',
        rateOfEscalation: '',
        escalationsStartsWith: '',
        escalationCustomDate: '',
        recurrentSpan: '',
      },
    },
    signageAmount: 0,
    securityDeposit: 0,
    parking: {
      numberOfSlots: '',
      perSlotPrice: '',
      enableAdditionalParkingSlots: false,
      additionalParkingSlots: [
        {
          numberOfSlots: '',
          perSlotPrice: '',
          invoiceStartDate: '',
        },
      ],
    },
  },
};

const dueDateInvoicesOptions = [
  {
    label: '10 Days from invoice start Date',
    value: 10,
  },
  {
    label: '15 Days from invoice start Date',
    value: 15,
  },
];

const BillingPreferenceComponent = (props: BillingPreferenceComponentProps) => {
  const { tenantDetails, prev, next, steps, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const [towerList, setTowerList] = useState<any>([]);

  useEffect(() => {
    if (tenantDetails) {
      const towers = tenantDetails?.towers?.towerBreakdown?.map(
        (tower: any) => ({
          id: tower.towerId,
          title: tower.name,
        }),
      );
      setTowerList(towers);
    }
  }, [tenantDetails]);

  const combineEscalationList = useCallback((towers: any, towersData: any) => {
    const selectedTowerIds = towersData.map((tower: any) => tower.towerId);

    const filteredEscalationList = towers
      .flatMap((tower: any) => {
        const { officeFloors = [], basements = [], hardOptions = [] } = tower;

        const addTowerName = (item: any) => ({
          ...item,
          towerName: tower.towerName,
        });

        const updatedOfficeFloors = officeFloors.map(addTowerName);
        const updatedBasements = basements.map(addTowerName);
        const updatedHardOptions = hardOptions.map(addTowerName);

        return [
          ...updatedOfficeFloors,
          ...updatedBasements,
          ...updatedHardOptions,
        ];
      })
      .filter((escalation: any) =>
        selectedTowerIds.includes(escalation.towerId),
      );

    return filteredEscalationList;
  }, []);

  useEffect(() => {
    let rentDefaults = {};
    let camDefaults = {};
    let campusCamDefaults = {};
    let towersData = [];
    if (tenantDetails?.towers) {
      towersData = tenantDetails?.towers?.towerBreakdown?.map((tower: any) => {
        const officeFloors =
          CommonService.generateUniqueTitleForEscalationTower(
            tower?.floorBreakdown || [],
            'F',
          );
        const basements = CommonService.generateUniqueTitleForEscalationTower(
          tower?.basementBreakdown || [],
          'B',
        );
        const hardOptions = CommonService.generateUniqueTitleForEscalationTower(
          tower?.hardOptionBreakdown || [],
          'H',
        );
        return {
          towerId: tower.towerId,
          towername: tower.name,
          officeFloors,
          basements,
          hardOptions,
        };
      });

      rentDefaults = {
        towers: towersData,
        isEscalationsEnabled: false,
        escalations: {
          towers: towersData.map((tower: any) => ({
            towerId: tower.towerId,
            towerName: tower.towername,
          })),
          escalationList: [],
        },
      };

      camDefaults = {
        towers: towersData,
        isEscalationsEnabled: false,
        escalations: {
          towers: towersData?.map((tower: any) => ({
            towerId: tower.towerId,
            towerName: tower.towername,
          })),
          escalationList: [],
        },
      };
      campusCamDefaults = {
        towers: towersData,
        isEscalationsEnabled: false,
        escalations: {
          towers: towersData?.map((tower: any) => ({
            towerId: tower.towerId,
            towerName: tower.towername,
          })),
          escalationList: [],
        },
      };
    }

    // Adding extra officeFloors / basements / hardoptions
    const mergeAdditionalData = (billingTowers: any[], towersData: any[]) => {
      const mergedTowers = towersData?.map((tower: any) => {
        const billingTower = billingTowers?.find(
          (bt: any) => bt.towerId === tower.towerId,
        );

        if (billingTower) {
          return {
            ...billingTower,
            towerName: tower?.towerName,
            officeFloors: mergeSubComponents(
              billingTower?.officeFloors,
              tower?.officeFloors,
            ),
            basements: mergeSubComponents(
              billingTower?.basements,
              tower?.basements,
            ),
            hardOptions: mergeSubComponents(
              billingTower?.hardOptions,
              tower?.hardOptions,
            ),
          };
        }
        return tower;
      });

      return mergedTowers;
    };

    const mergeSubComponents = (
      billingComponents: any,
      towerComponents: any,
    ) => {
      let mergedComponents = [...billingComponents];

      const towerComponentIds = towerComponents.map(
        (component: any) => component.id,
      );

      mergedComponents = mergedComponents.filter((component) =>
        towerComponentIds.includes(component.id),
      );

      towerComponents.forEach((component: any) => {
        if (!mergedComponents.some((c) => c.id === component.id)) {
          mergedComponents.push(component);
        }
      });

      return mergedComponents;
    };

    if (
      tenantDetails?.billingPreferences &&
      Object.keys(tenantDetails?.billingPreferences).length > 0
    ) {
      const { billingPreferences } = tenantDetails;
      const updatedValues = {
        ...billingPreferences,
        leaseCommencementDate: billingPreferences?.leaseCommencementDate || '',
        rentCommencementDate: billingPreferences?.rentCommencementDate || '',
        billingComponents: {
          rent: {
            isEscalationsEnabled:
              billingPreferences?.billingComponents?.rent?.isEscalationsEnabled,
            towers: mergeAdditionalData(
              billingPreferences.billingComponents.rent.towers,
              towersData,
            ),
            escalations: {
              towers:
                tenantDetails?.towers?.towerBreakdown?.map((tower: any) => ({
                  towerId: tower.towerId,
                  towerName: tower.name,
                })) || [],
              escalationList: combineEscalationList(
                billingPreferences?.billingComponents?.rent?.escalations
                  ?.towers,
                towersData,
              ),
              id: '',
              number: '',
              towerName: '',
              rateOfEscalation: '',
              escalationsStartsWith: '',
              escalationCustomDate: '',
              recurrentSpan: '',
            },
          },
          cam: {
            isEscalationsEnabled:
              billingPreferences?.billingComponents?.cam?.isEscalationsEnabled,
            towers: mergeAdditionalData(
              billingPreferences?.billingComponents?.cam?.towers,
              towersData,
            ),
            escalations: {
              towers:
                tenantDetails?.towers?.towerBreakdown?.map((tower: any) => ({
                  towerId: tower.towerId,
                  towerName: tower.name,
                })) || [],
              escalationList: combineEscalationList(
                billingPreferences?.billingComponents?.cam?.escalations?.towers,
                towersData,
              ),
              id: '',
              number: '',
              towerName: '',
              rateOfEscalation: '',
              escalationsStartsWith: '',
              escalationCustomDate: '',
              recurrentSpan: '',
            },
          },
          campusCam: {
            isEscalationsEnabled:
              billingPreferences?.billingComponents?.campusCam
                ?.isEscalationsEnabled,
            towers: mergeAdditionalData(
              billingPreferences?.billingComponents?.campusCam?.towers,
              towersData,
            ),
            escalations: {
              towers:
                tenantDetails?.towers?.towerBreakdown?.map((tower: any) => ({
                  towerId: tower.towerId,
                  towerName: tower.name,
                })) || [],
              escalationList: combineEscalationList(
                billingPreferences?.billingComponents?.campusCam?.escalations
                  ?.towers,
                towersData,
              ),
              id: '',
              number: '',
              towerName: '',
              rateOfEscalation: '',
              escalationsStartsWith: '',
              escalationCustomDate: '',
              recurrentSpan: '',
            },
          },
          signageAmount:
            billingPreferences?.billingComponents?.signageAmount || '',
          securityDeposit:
            billingPreferences?.billingComponents?.securityDeposit || '',
          parking: {
            numberOfSlots: tenantDetails?.numberOfParkingSpacesOccupied || '',
            perSlotPrice:
              billingPreferences?.billingComponents?.parking?.perSlotPrice,
            enableAdditionalParkingSlots:
              billingPreferences?.billingComponents?.parking
                ?.enableAdditionalParkingSlots,
            additionalParkingSlots:
              billingPreferences?.billingComponents?.parking
                ?.additionalParkingSlots,
          },
        },
      };
      setInitialValues(updatedValues);
    } else {
      setInitialValues({
        ...formInitialValues,
        billingComponents: {
          rent: {
            ...formInitialValues.billingComponents.rent,
            ...rentDefaults,
          },
          cam: {
            ...formInitialValues.billingComponents.cam,
            ...camDefaults,
          },

          campusCam: {
            ...formInitialValues.billingComponents.campusCam,
            ...campusCamDefaults,
          },
          signageAmount: 0,
          securityDeposit: 0,
          parking: {
            numberOfSlots: tenantDetails?.numberOfParkingSpacesOccupied || '',
            perSlotPrice: '',
            enableAdditionalParkingSlots: false,
            additionalParkingSlots: [
              {
                numberOfSlots: '',
                perSlotPrice: '',
                invoiceStartDate: '',
              },
            ],
          },
        },
      });
    }
  }, [tenantDetails, combineEscalationList]);

  const transformEscalations = (billingComponent: any) => {
    const { towers, escalationList } = billingComponent?.escalations;
    towers.forEach((tower: any) => {
      tower.officeFloors = [];
      tower.basements = [];
      tower.hardOptions = [];
    });

    escalationList?.forEach((escalation: any) => {
      const tower = towers.find((t: any) => t.towerId === escalation?.towerId);
      if (tower) {
        const escalationData = {
          id: escalation?.id,
          title: escalation?.title,
          towerName: escalation?.towerName,
          towerId: escalation?.towerId,
          type: escalation?.type,
          number: escalation?.number,
          occupiedArea: escalation?.occupiedArea,
          ratePerSft: escalation?.ratePerSft,
          rateOfEscalation: escalation?.rateOfEscalation,
          escalationsStartsWith: escalation?.escalationsStartsWith,
          recurrentSpan: escalation?.recurrentSpan,
          escalationCustomDate: escalation?.escalationCustomDate || '',
        };

        if (escalation.type === 'officeFloor') {
          tower.officeFloors = tower.officeFloors || [];
          tower.officeFloors.push(escalationData);
        } else if (escalation.type === 'basement') {
          tower.basements = tower.basements || [];
          tower.basements.push(escalationData);
        } else if (escalation.type === 'hardOption') {
          tower.hardOptions = tower.hardOptions || [];
          tower.hardOptions.push(escalationData);
        }
      }
    });
    delete billingComponent.escalations.towerName;
    delete billingComponent.escalations.towerId;
    delete billingComponent.escalations.recurrentSpan;
    delete billingComponent.escalations.rateOfEscalation;
    delete billingComponent.escalations.number;
    delete billingComponent.escalations.type;
    delete billingComponent.escalations.escalationStartsWith;
    delete billingComponent.escalations.escalationCustomDate;
    delete billingComponent.escalations.id;
    delete billingComponent.escalations.escalationList;
  };

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const tenantId = tenantDetails?._id || '';
      const payload = { ...values };
      payload.leaseCommencementDate = moment(
        payload.leaseCommencementDate,
      ).format('YYYY-MM-DD');
      payload.rentCommencementDate = moment(
        payload.rentCommencementDate,
      ).format('YYYY-MM-DD');

      if (payload.isRentSelected) {
        transformEscalations(payload.billingComponents.rent);
      }

      if (payload.isCamSelected) {
        transformEscalations(payload.billingComponents.cam);
      }

      if (payload.isCampusCamSelected) {
        transformEscalations(payload.billingComponents.campusCam);
      }
      CommonService._tenants
        .AddBillingDetailsAPICall(tenantId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next && next();
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [next, tenantDetails],
  );

  return (
    <div className={'billing-preference-step add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          touched,
          isValid,
          setFieldValue,
          isSubmitting,
          resetForm,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>
                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  // isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                <div className=''>
                  <div className='grid-container'>
                    <Field name={'gstNumber'} label={'GST Number'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'GST Number'}
                          placeholder={'Enter GST Number'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field name={'panNumber'} label={'PAN Number'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'PAN Number'}
                          placeholder={'Enter PAN Number'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field
                      name={'leaseCommencementDate'}
                      label={'Lease Commencement Date'}
                    >
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          formikField={field}
                          label={'Lease Commencement Date'}
                          placeholder={'Select Date'}
                          required={true}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field
                      name={'rentCommencementDate'}
                      label={'Rent Commencement Date'}
                    >
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          formikField={field}
                          label={'Rent Commencement Date'}
                          placeholder={'Select Date'}
                          required={true}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                  <Field
                    name={'invoiceDueDateDuration'}
                    label={'Invoice Due Date Duration'}
                  >
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        formikField={field}
                        label={'Invoice Due Date Duration'}
                        placeholder={'Select Invoice Due Date Duration'}
                        required={true}
                        fullWidth={true}
                        options={dueDateInvoicesOptions}
                      />
                    )}
                  </Field>
                  <div className='section-header'>
                    Select Billing Components
                  </div>
                  <Field name={'isRentSelected'}>
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={'Rent'}
                          formikField={field}
                        />
                      );
                    }}
                  </Field>
                  {/*if rent is checked than show the rent card*/}
                  {values.isRentSelected && (
                    <div className='section-header'>
                      <AddBillingCardComponent
                        billingObject={values.billingComponents.rent}
                        setFieldValue={setFieldValue}
                        type={'rent'}
                        towersForBilling={tenantDetails?.towersForBilling}
                        towerList={towerList}
                        towerDetails={tenantDetails?.towers?.towerBreakdown}
                        values={values}
                      />
                    </div>
                  )}
                  <Field name={'isCamSelected'}>
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={'Building CAM'}
                          formikField={field}
                        />
                      );
                    }}
                  </Field>

                  {values.isCamSelected && (
                    <div className='section-header'>
                      <AddBillingCardComponent
                        billingObject={values.billingComponents.cam}
                        setFieldValue={setFieldValue}
                        type={'cam'}
                        towersForBilling={tenantDetails?.towersForBilling}
                        towerList={towerList}
                        towerDetails={tenantDetails?.towers?.towerBreakdown}
                        values={values}
                      />
                    </div>
                  )}

                  <Field name={'isCampusCamSelected'}>
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={'Campus CAM'}
                          formikField={field}
                        />
                      );
                    }}
                  </Field>

                  {values.isCampusCamSelected && (
                    <div className='section-header'>
                      <AddBillingCardComponent
                        billingObject={values.billingComponents.campusCam}
                        setFieldValue={setFieldValue}
                        type={'campusCam'}
                        towersForBilling={tenantDetails?.towersForBilling}
                        towerList={towerList}
                        towerDetails={tenantDetails?.towers?.towerBreakdown}
                        values={values}
                      />
                    </div>
                  )}
                  <Field name={'isSignageSelected'}>
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={'Signage'}
                          formikField={field}
                        />
                      );
                    }}
                  </Field>
                  {values.isSignageSelected && (
                    <div className='billing-subsection-wrapper'>
                      <Field name={'billingComponents.signageAmount'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Signage Amount'}
                            placeholder={'Enter Signage Amount'}
                            validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                            onChange={(value: any) => {
                              setFieldValue(
                                'billingComponents.signageAmount',
                                parseInt(value),
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <div className='security-deposit-wrapper'>
                    <Field name={'isSecurityDepositSelected'}>
                      {(field: FieldProps) => {
                        return (
                          <FormikCheckBoxComponent
                            label={'Security Deposit'}
                            formikField={field}
                          />
                        );
                      }}
                    </Field>
                    <p className='billing-preference-security-deposit-text'>
                      (This invoice won't be generated automatically.)
                    </p>
                  </div>
                  {values.isSecurityDepositSelected && (
                    <div className='billing-subsection-wrapper'>
                      <Field name={'billingComponents.securityDeposit'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Security Deposit'}
                            placeholder={'Enter Security Deposit'}
                            validationPattern={new RegExp(/^[0-9]*$/)}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                            onChange={(value: any) => {
                              setFieldValue(
                                'billingComponents.securityDeposit',
                                parseInt(value),
                              );
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  <Field name={'isParkingSelected'}>
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={'Parking'}
                          formikField={field}
                        />
                      );
                    }}
                  </Field>
                  {values.isParkingSelected && (
                    <div className='billing-subsection-wrapper'>
                      <div className='ts-row'>
                        <div className='ts-col-6'>
                          <Field
                            name={'billingComponents.parking.numberOfSlots'}
                            label={'Number of Slots'}
                          >
                            {(field: FieldProps) => (
                              <FormikInputComponent
                                label={'Number of Slots'}
                                placeholder={'Enter Number of Slots'}
                                validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                                required={true}
                                formikField={field}
                                disabled={true}
                                fullWidth={true}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    'billingComponents.parking.numberOfSlots',
                                    value ? parseInt(value) : 0,
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className='ts-col-6'>
                          <Field
                            name={'billingComponents.parking.perSlotPrice'}
                            label={'Per Slot Price'}
                          >
                            {(field: FieldProps) => (
                              <FormikInputComponent
                                label={'Per Slot Price'}
                                placeholder={' Enter Per Slot Price'}
                                validationPattern={new RegExp(/^[0-9]*$/)}
                                required={true}
                                disabled={
                                  !values.billingComponents.parking
                                    .numberOfSlots
                                }
                                formikField={field}
                                fullWidth={true}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    'billingComponents.parking.perSlotPrice',
                                    value ? parseInt(value) : 0,
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <div>
                        <div className='additional-slot-wrapper'>
                          <Field name='billingComponents.parking.enableAdditionalParkingSlots'>
                            {(field: FieldProps) => (
                              <FormikSwitchComponent
                                label='Additional Slots'
                                formikField={field}
                                labelPlacement='start'
                              />
                            )}
                          </Field>
                        </div>
                        {values?.billingComponents?.parking
                          ?.enableAdditionalParkingSlots && (
                          <FieldArray
                            name={
                              'billingComponents.parking.additionalParkingSlots'
                            }
                            render={(arrayHelpers: any) => (
                              <>
                                {values?.billingComponents?.parking?.additionalParkingSlots?.map(
                                  (item: any, index: any) => {
                                    return (
                                      <div
                                        key={index}
                                        className='admin-details-wrapper mrg-top-10'
                                      >
                                        <Field
                                          name={`billingComponents.parking.additionalParkingSlots[${index}].numberOfSlots`}
                                        >
                                          {(field: FieldProps) => (
                                            <FormikInputComponent
                                              label={'Additional Slots'}
                                              placeholder={
                                                'Enter Number of Slots'
                                              }
                                              validationPattern={
                                                new RegExp(/^[0-9]*$/)
                                              }
                                              required={true}
                                              formikField={field}
                                              fullWidth={true}
                                              onChange={(value: any) => {
                                                setFieldValue(
                                                  `billingComponents.parking.additionalParkingSlots[${index}].numberOfSlots`,
                                                  value ? parseInt(value) : 0,
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <Field
                                          name={`billingComponents.parking.additionalParkingSlots[${index}].perSlotPrice`}
                                        >
                                          {(field: FieldProps) => (
                                            <FormikInputComponent
                                              label={'Per Slot Price'}
                                              placeholder={
                                                ' Enter Per Slot Price'
                                              }
                                              validationPattern={
                                                new RegExp(/^[0-9]*$/)
                                              }
                                              required={true}
                                              formikField={field}
                                              fullWidth={true}
                                              onChange={(value: any) => {
                                                setFieldValue(
                                                  `billingComponents.parking.additionalParkingSlots[${index}].perSlotPrice`,
                                                  value ? parseInt(value) : 0,
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <Field
                                          name={`billingComponents.parking.additionalParkingSlots.${index}.invoiceStartDate`}
                                        >
                                          {(field: FieldProps) => (
                                            <FormikDatePickerComponent
                                              formikField={field}
                                              label='Invoice Start Date'
                                              fullWidth
                                              minDate={new Date()}
                                              placeholder='Select'
                                            />
                                          )}
                                        </Field>
                                        <div className='admin-details-wrapper'>
                                          <IconButtonComponent
                                            color='primary'
                                            onClick={() => {
                                              arrayHelpers.push('');
                                            }}
                                          >
                                            <ImageConfig.AddOutlinedIcon />
                                          </IconButtonComponent>
                                          {values?.billingComponents?.parking
                                            ?.additionalParkingSlots?.length >
                                            1 && (
                                            <IconButtonComponent
                                              color='error'
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              <ImageConfig.DeleteIcon />
                                            </IconButtonComponent>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </>
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BillingPreferenceComponent;
