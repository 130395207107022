import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const GetDashboardStatistics = () => {
  //@ts-ignore
  return ApiService[APIConfig.GET_DASHBOARD_STATISTICS.METHOD](
    APIConfig.GET_DASHBOARD_STATISTICS.URL,
  );
};

const OccupanyStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_OCCUPANY_STATS.METHOD](
    APIConfig.GET_OCCUPANY_STATS.URL,
    payload,
  );
};

const TowerStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_TOWER_STATS.METHOD](
    APIConfig.GET_TOWER_STATS.URL,
    payload,
  );
};

const TenantStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_TENANT_STATS.METHOD](
    APIConfig.GET_TENANT_STATS.URL,
    payload,
  );
};

const InvoiceStatsAPICall = () => {
  //@ts-ignore
  return ApiService[APIConfig.GET_INVOICE_STATISTICS.METHOD](
    APIConfig.GET_INVOICE_STATISTICS.URL,
  );
};

const AutomatedInvoiceStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.AUTOMATED_INVOICE_STATS.METHOD](
    APIConfig.AUTOMATED_INVOICE_STATS.URL,
    payload,
  );
};

const ManualInvoiceStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.MANUAL_INVOICE_STATS.METHOD](
    APIConfig.MANUAL_INVOICE_STATS.URL,
    payload,
  );
};

const InvoiceNotGeneratedListAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.INVOICE_NOT_GENERATED_STATS.METHOD](
    APIConfig.INVOICE_NOT_GENERATED_STATS.URL,
    payload,
  );
};

const InvoiceForecastStatsAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.FORECAST_STATS.METHOD](
    APIConfig.FORECAST_STATS.URL,
    payload,
  );
};

const DashboardService = {
  GetDashboardStatistics,
  InvoiceStatsAPICall,
  OccupanyStatsAPICall,
  TowerStatsAPICall,
  TenantStatsAPICall,
  AutomatedInvoiceStatsAPICall,
  ManualInvoiceStatsAPICall,
  InvoiceNotGeneratedListAPICall,
  InvoiceForecastStatsAPICall,
};

export default DashboardService;
