import { call, put, takeEvery } from 'redux-saga/effects';
import { CommonService } from '../../shared/services';
import {
  GET_ALL_PROPERTY_LIST,
  GET_PROPERTY_FM_LIST,
  GET_PROPERTY_LIST_LITE,
  setAllPropertyList,
  setPropertyFMList,
  setPropertyListLite,
} from '../actions/property.action';

function* getAllPropertiesList() {
  try {
    // @ts-ignore
    const resp = yield call(
      CommonService._property.GetPropertyListLiteAPICall,
      { isActive: true, getTowers: true },
    );
    const propertyList = resp?.data;
    const propertyListLite = propertyList.map((property: any) => {
      return {
        _id: property?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(property?.name),
        campusDetails: property?.campusDetails,
        towers: property?.towers,
        billingEntityId: property?.billingEntityId,
      };
    });
    yield put(setPropertyListLite(propertyListLite));
  } catch (error: any) {
    yield put(setPropertyListLite([]));
  }
}
function* getAllProperties() {
  try {
    // @ts-ignore
    const resp = yield call(
      CommonService._property.GetPropertyListLiteAPICall,
      {},
    );
    const propertyList = resp?.data?.map((property: any) => {
      return {
        _id: property?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(property?.name),
        campusDetails: property?.campusDetails,
        towers: property?.towers,
        isActive: property?.isActive,
        billingEntityId: property?.billingEntityId,
      };
    });
    yield put(setAllPropertyList(propertyList));
  } catch (error: any) {
    yield put(setAllPropertyList([]));
  }
}

function* getPropertyFMsList(action: any) {
  try {
    // @ts-ignore
    const resp = yield call(
      CommonService._property.GetPropertyFMList,
      action?.payload?.propertyId,
    );
    yield put(setPropertyFMList(resp?.data));
  } catch (error: any) {
    yield put(setPropertyFMList([]));
  }
}

export default function* propertySaga() {
  yield takeEvery(GET_PROPERTY_LIST_LITE, getAllPropertiesList);
  yield takeEvery(GET_ALL_PROPERTY_LIST, getAllProperties);
  yield takeEvery(GET_PROPERTY_FM_LIST, getPropertyFMsList);
}
