import "./FormikSelectDropdownComponent.scss";
import React, { useCallback, useEffect, useState } from "react";
import { FieldProps } from "formik";
import SelectDropdownComponent from "../select-dropdown/SelectDropdownComponent";
import _ from "lodash";
import { ISelectDropdownProps } from "../../../models/form-controls.model";

export interface FormikSelectDropdownComponentProps
  extends ISelectDropdownProps {
  formikField: FieldProps;
}

const FormikSelectDropdownComponent = (
  props: FormikSelectDropdownComponentProps
) => {
  const { formikField, onUpdate, ...otherProps } = props;

  const { field, form } = formikField;
  const { name, value } = field;
  const { setFieldTouched, touched, errors, setFieldValue } = form;
  const hasError = _.get(touched, name) && !!_.get(errors, name);
  const [formControlValue, setFormControlValue] = useState(value);

  const onValueChange = useCallback(
    (value: any) => {
      let tempValue = value;
      setFormControlValue(tempValue);
      setFieldValue(name, tempValue);
      setFieldTouched(name);
      if (onUpdate) {
        onUpdate(tempValue);
      }
    },
    [name, onUpdate, setFieldTouched, setFieldValue, setFormControlValue]
  );

  const onBlur = useCallback(
    (value: any) => {
      setFieldTouched(name);
    },
    [name, setFieldTouched]
  );

  useEffect(() => {
    setFormControlValue(value || "");
  }, [value]);

  return (
    // @ts-ignore
    <SelectDropdownComponent
      id={name}
      value={formControlValue}
      hasError={hasError}
      errorMessage={hasError && _.get(errors, name)}
      onSelectUpdate={onBlur}
      onUpdate={newValue => {
        onValueChange(newValue);
      }}
      {...otherProps}
    />
  );
};

export default FormikSelectDropdownComponent;
