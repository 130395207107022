import "./ButtonComponent.scss";
import {CSSProperties, useCallback} from "react";

interface ButtonComponentProps {
    type?: "button" | "submit" | "reset";
    variant?: 'contained' | 'outlined' | 'link' | 'text';
    color?: 'primary' | 'secondary' | 'error' | 'default' | 'info' | 'warning' ;
    size?: 'small' | 'medium';
    className?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    prefixIcon?: any;
    suffixIcon?: any;
    isLoading?: boolean;
    id?: string;
    onClick?: (event: any) => void;
    style?: CSSProperties;
}

const ButtonComponent = (props: React.PropsWithChildren<ButtonComponentProps>) => {

    const {
        className,
        fullWidth,
        disabled,
        isLoading,
        prefixIcon,
        suffixIcon,
        onClick,
        children,
        ...otherProps
    } = props;
    const type = props.type || "button";
    const color = props.color || "primary";
    const variant = props.variant || "contained";
    const size = props.size || "medium";

    const handleOnClick = useCallback((event: any) => {
        if (onClick) {
            onClick(event);
        }
    }, [onClick]);

    return <button type={type}
                   className={`button-component component ${variant} ${disabled ? 'disabled' : ''} ${isLoading ? 'loading' : ''} ${fullWidth ? 'fullWidth' : ''} ${size} ${className} ${color}`}
                   disabled={disabled}
                   onClick={handleOnClick}
                   data-testid={props?.id}
                   {...otherProps}>
        {
            <span className={"button-contents"}>
                {prefixIcon && <span className={"button-prefix"}>{prefixIcon}</span>}
                <span className={"button-text"}>{children}</span>
                {suffixIcon && <span className={"button-suffix"}>{suffixIcon}</span>}
            </span>
        }
    </button>

};

export default ButtonComponent;
