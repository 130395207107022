import './AmenityGalleryDetailsComponent.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import CardComponent from '../../../../shared/components/card/CardComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import { CloseIcon } from '../../../../constants/ImageConfig';

interface AmenityGalleryDetailsComponentProps {
  amenityDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  amenityId: string;
  activeStepId: string;
}

const AmenityGalleryDetailsComponent = (
  props: AmenityGalleryDetailsComponentProps,
) => {
  const { amenityDetails, prev, next, steps, activeStepId, amenityId } = props;

  const [profilePic, setProfilePic] = useState<any>(null);
  const [mobileRepPic, setMobileRepPic] = useState<any>(null);
  const [galleryLocalFiles, setGalleryLocalFiles] = useState<any[]>([]);
  const [galleryFiles, setGalleryFiles] = useState<any[]>([]);
  // const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goNext, setGoNext] = useState<any>(false);
  const getAllGalleryImages = useCallback((amenityId: string) => {
    CommonService._amenity
      .getAmenityGalleryListAPICall(amenityId)
      .then((response: IAPIResponseType<any>) => {
        setGalleryFiles(response.data.docs);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    if (amenityDetails?.profilePic && amenityDetails?.mobilePic) {
      setGoNext(true);
    } else if (profilePic && mobileRepPic) {
      setGoNext(true);
    }
  }, [amenityDetails, profilePic, mobileRepPic]);

  const uploadImages = useCallback(
    (
      amenityId: string,
      galleryList: any,
      cb: (status: boolean, resp: any) => void,
    ) => {
      const payload = new FormData();
      payload.append('amenityId', amenityId);
      galleryList.forEach((file: any) => {
        payload.append('image', file);
      });

      CommonService._amenity
        .uploadAmenityGalleryAPICall(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          // next(response.data);
          setGalleryLocalFiles([]);
          getAllGalleryImages(amenityId);
          cb(true, response);
        })
        .catch((error: any) => {
          getAllGalleryImages(amenityId);
          cb(false, error);
        });
    },
    [getAllGalleryImages],
  );

  const uploadProfileImages = useCallback(
    (
      amenityId: string,
      profilePic: any,
      cb: (status: boolean, resp: any) => void,
    ) => {
      // setIsSubmitting(true);
      const payload = new FormData();
      payload.append('amenityId', amenityId);
      payload.append('image', profilePic);
      CommonService._amenity
        .uploadAmenityProfilePicAPICall(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          setProfilePic(null);
          cb(true, response);
        })
        .catch((error: any) => {
          cb(false, error);
        });
    },
    [],
  );

  const uploadMobileImage = useCallback(
    (
      amenityId: string,
      mobilePic: any,
      cb: (status: boolean, resp: any) => void,
    ) => {
      // setIsSubmitting(true);
      const payload = new FormData();
      payload.append('amenityId', amenityId);
      payload.append('image', mobilePic);
      CommonService._amenity
        .uploadAmenityMobilePicAPICall(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          setMobileRepPic(null);
          cb(true, response);
        })
        .catch((error: any) => {
          cb(false, error);
        });
    },
    [],
  );

  const removeGalleryImage = useCallback(
    (amenityId: string, imageId: string) => {
      CommonService._amenity
        .removeAmenityGalleryImageAPICall(amenityId, { imageId })
        .then((response: IAPIResponseType<any>) => {
          getAllGalleryImages(amenityId);
        })
        .catch((error: any) => {});
    },
    [getAllGalleryImages],
  );

  useEffect(() => {
    getAllGalleryImages(amenityId);
  }, [getAllGalleryImages, amenityId]);
  const profilePicUploadRef = useRef<HTMLInputElement>(null);
  const mobileRepPicUploadRef = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    const uploadProfilePromise = new Promise((resolve, reject) => {
      if (profilePic) {
        uploadProfileImages(amenityId, profilePic, (status, resp) => {
          if (status) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        });
      } else {
        resolve({});
      }
    });
    const uploadMobilePromise = new Promise((resolve, reject) => {
      if (mobileRepPic) {
        uploadMobileImage(amenityId, mobileRepPic, (status, resp) => {
          if (status) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        });
      } else {
        resolve({});
      }
    });
    const uploadGalleryPromise = new Promise((resolve, reject) => {
      if (galleryLocalFiles.length > 0) {
        uploadImages(amenityId, galleryLocalFiles, (status, resp) => {
          if (status) {
            resolve(resp.data);
          } else {
            reject(resp);
          }
        });
      } else {
        resolve({});
      }
    });
    Promise.all([
      uploadProfilePromise,
      uploadMobilePromise,
      uploadGalleryPromise,
    ])
      .then((values) => {
        // next(values[0] || values[1]);
        next(values);
        setIsSubmitting(false);
      })
      .catch((error) => {
        setIsSubmitting(false);
      });
  }, [
    uploadProfileImages,
    uploadMobileImage,
    next,
    amenityId,
    profilePic,
    mobileRepPic,
    uploadImages,
    galleryLocalFiles,
  ]);

  return (
    <div className={'amenity-gallery-component'}>
      <div className='add-component-header'>
        {
          <ButtonComponent
            variant={'text'}
            color={'secondary'}
            onClick={prev}
            prefixIcon={<ImageConfig.ArrowLeftIcon />}
          >
            Back
          </ButtonComponent>
        }
        <div>
          <StepperComponent activeStepId={activeStepId} steps={steps} />
        </div>

        <ButtonComponent
          // disabled={
          //   !profilePic &&
          //   (!amenityDetails?.profilePic || !mobileRepPic) &&
          //   !amenityDetails?.mobilePic &&
          //   !mobileRepPic
          // }
          disabled={!goNext}
          isLoading={isSubmitting}
          type={'button'}
          onClick={() => {
            onSubmit();
          }}
          suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
        >
          Next
        </ButtonComponent>
      </div>
      <div className='upload-gallery-wrapper'>
        <CardComponent className={'upload-gallery-card'}>
          {amenityDetails?.profilePic && !profilePic && (
            <div
              className='upload-gallery-profile'
              style={{
                backgroundImage:
                  'url(' +
                  (amenityDetails?.profilePic
                    ? amenityDetails?.profilePic.url
                    : ImageConfig.PlaceHolderImage) +
                  ')',
              }}
            ></div>
          )}
          {amenityDetails?.profilePic && profilePic && (
            <div
              className='upload-gallery-profile'
              style={{
                backgroundImage:
                  'url(' +
                  (profilePic
                    ? URL.createObjectURL(profilePic)
                    : ImageConfig.PlaceHolderImage) +
                  ')',
              }}
            ></div>
          )}
          {/*{*/}
          {/*    !amenityDetails?.profilePic && <div*/}
          {/*        className="upload-gallery-profile"*/}
          {/*        style={{backgroundImage: 'url(' + (profilePic ? URL.createObjectURL(profilePic) : ImageConfig.PlaceHolderImage) + ')'}}>*/}
          {/*    </div>*/}
          {/*}*/}
          {!amenityDetails?.profilePic && (
            <div
              className='upload-gallery-profile'
              style={{
                backgroundImage:
                  'url(' +
                  (profilePic
                    ? URL.createObjectURL(profilePic)
                    : ImageConfig.PlaceHolderImage) +
                  ')',
              }}
            ></div>
          )}
          <div className='upload-gallery-profile-text'>
            <FormControlLabelComponent
              isBold={true}
              label={'Upload logo of the amenity'}
            />
            <div className='upload-gallery-sub-text'>
              Upload type allowed png, jpg, jpeg
            </div>
          </div>
          <div className='upload-gallery-profile-upload-btn'>
            <div className='d-none'>
              <input
                ref={profilePicUploadRef}
                type='file'
                accept='image/jpeg, image/jpg, image/png'
                onChange={(event) => {
                  event.preventDefault();
                  if (event.target.files && event.target.files.length > 0) {
                    setProfilePic(event.target.files[0]);
                  }
                }}
              />
            </div>
            <ButtonComponent
              color={
                profilePic || amenityDetails?.profilePic ? 'error' : 'primary'
              }
              onClick={() => {
                profilePicUploadRef.current?.click();
              }}
            >
              {profilePic || amenityDetails?.profilePic ? 'Change' : 'Upload'}{' '}
            </ButtonComponent>
          </div>
        </CardComponent>

        <div className={'upload-gallery-details-header'}>
          Upload Mobile Representation Image
        </div>
        <div className='mobile-representation-image-holder'>
          <div className='mobile-representation-image-text mrg-bottom-20'>
            This image will be uploaded as a banner in the tenant experience
            mobile application. Ensure that images uploaded here are in 9:16
            aspect ratio of HD resolution.
          </div>
          <div className='d-none'>
            <input
              ref={mobileRepPicUploadRef}
              type='file'
              accept='image/jpeg, image/jpg, image/png'
              onChange={(event) => {
                event.preventDefault();
                if (event.target.files && event.target.files.length > 0) {
                  setMobileRepPic(event.target.files[0]);
                }
              }}
            />
          </div>
          {amenityDetails?.mobilePic && !mobileRepPic && (
            <div
              className='upload-gallery-profile'
              style={{
                backgroundImage:
                  'url(' +
                  (amenityDetails?.mobilePic
                    ? amenityDetails?.mobilePic.url
                    : ImageConfig.PlaceHolderImage) +
                  ')',
              }}
            ></div>
          )}
          {mobileRepPic && (
            <div
              className='upload-gallery-profile'
              style={{
                backgroundImage:
                  'url(' +
                  (mobileRepPic
                    ? URL.createObjectURL(mobileRepPic)
                    : ImageConfig.PlaceHolderImage) +
                  ')',
              }}
            ></div>
          )}
          <ButtonComponent
            className={'mrg-top-20'}
            color={mobileRepPic ? 'error' : 'primary'}
            onClick={() => {
              if (mobileRepPic) {
                setMobileRepPic(null);
              } else {
                mobileRepPicUploadRef.current?.click();
              }
            }}
          >
            {mobileRepPic
              ? 'Remove Image'
              : 'Upload Mobile Representation Image'}{' '}
          </ButtonComponent>
        </div>

        {galleryFiles.length > 0 && (
          <div className={'mrg-top-20'}>
            {/*<FormControlLabelComponent label={'Upload Gallery'}/>*/}
            <div className='gallery-image-holder'>
              {galleryFiles.map((file: any, index: number) => {
                return (
                  <div key={index} className='image-preview-design'>
                    <img src={file.url} alt='' />
                    <div className='text'>{file.name}</div>
                    <div
                      className='close-btn'
                      onClick={(event) => {
                        event.preventDefault();
                        removeGalleryImage(amenityId, file._id);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <br />
        <div className={'row-wrapper'}>
          <div className={'upload-gallery-details-header'}>Upload Gallery</div>
          <div className={'optional-text'}>Optional</div>
        </div>
        <FilePickerComponent
          // maxFileSizeInMB={3}
          multiple={true}
          maxFileCount={10}
          acceptedFileTypes={['png', 'jpeg', 'jpg']}
          showDropZone={true}
          onFilesDrop={(acceptedFiles) => {
            setGalleryLocalFiles((prevState) => [
              ...prevState,
              ...acceptedFiles,
            ]);
          }}
          id={'gallery'}
          // acceptedFilesText={'.jpg, .png, .jpeg max file size 3.0MB'}
        />
        <div className='gallery-image-holder'>
          {galleryLocalFiles.length > 0 &&
            galleryLocalFiles.map((file: any, index: number) => {
              return (
                <div key={index} className='image-preview-design'>
                  <img src={URL.createObjectURL(file)} alt='' />
                  <div className='text'>{file.name}</div>
                  <div
                    className='close-btn'
                    onClick={(event) => {
                      event.preventDefault();
                      setGalleryLocalFiles(
                        galleryLocalFiles.filter((item: any, i) => i !== index),
                      );
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AmenityGalleryDetailsComponent;
