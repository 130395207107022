import "./EventGalleryComponent.scss";
import {useCallback, useEffect, useState} from "react";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../../constants";
import StepperComponent from "../../../../shared/components/stepper/StepperComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import FilePickerComponent from "../../../../shared/components/file-picker/FilePickerComponent";
import {CloseIcon} from "../../../../constants/ImageConfig";
import CheckBoxComponent from "../../../../shared/components/form-controls/check-box/CheckBoxComponent";

interface EventGalleryComponentProps {
    eventDetails?: any;
    prev: () => void;
    next: () => void;
    steps: any[];
    eventId: string,
    activeStepId: string;
}


const EventGalleryComponent = (props: EventGalleryComponentProps) => {
    const {prev, next, steps, activeStepId, eventId} = props;


    const [galleryFiles, setGalleryFiles] = useState<any[]>([]);
    const getAllGalleryImages = useCallback((eventId: string) => {
        const payload = {
            eventId: eventId,
        }
        CommonService._events.getEventGalleryImagesAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setGalleryFiles(response.data.docs);
            })
            .catch((error: any) => {
            });
    }, []);

    // console.log(profilePic, 'profilePic');

    const uploadImages = useCallback((file: any) => {
        const payload = new FormData();
        payload.append('image', file);

        CommonService._events.uploadEventGalleryAPICall(eventId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                // next(response.data);
                // setGalleryLocalFiles([]);
                getAllGalleryImages(eventId);
                // cb(true, response);
            })
            .catch((error: any) => {
                getAllGalleryImages(eventId);
                // cb(false, error);
            })
    }, [getAllGalleryImages, eventId]);

    const setDefaultPicHandler = useCallback((imageId: string) => {
        // const payload = {
        //     imageId: imageId,
        // }
        CommonService._events.setDefaultEventGalleryImageAPICall(eventId, imageId)
            .then((response: IAPIResponseType<any>) => {
                getAllGalleryImages(eventId);
            })
            .catch((error: any) => {
            });
    }, [eventId, getAllGalleryImages]);


    const removeGalleryImage = useCallback((eventId: string, imageId: string) => {
        CommonService._events.removeEventGalleryImageAPICall(eventId, imageId)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getAllGalleryImages(eventId);
            })
            .catch((error: any) => {
            });
    }, [getAllGalleryImages]);

    useEffect(() => {
        getAllGalleryImages(eventId);
    }, [getAllGalleryImages, eventId]);

    const onSubmit = useCallback(
        () => {
            // setIsSubmitting(true);
            // setIsSubmitting(false);
            next();
        },
        [next]);

    return (
        <div className={'event-gallery-component'}>
            <div className="add-component-header">
                {
                    <ButtonComponent
                        variant={'text'}
                        color={'secondary'}
                        onClick={prev}
                        prefixIcon={<ImageConfig.ArrowLeftIcon/>}
                    >
                        Back
                    </ButtonComponent>
                }
                <div>
                    <StepperComponent activeStepId={activeStepId} steps={steps}/>
                </div>

                <ButtonComponent
                    disabled={galleryFiles.length === 0}
                    type={'button'}
                    onClick={() => {
                        onSubmit();
                    }}
                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                >
                    Next
                </ButtonComponent>
            </div>
            <div className="upload-gallery-wrapper">
                {galleryFiles.length > 0 && <div className={'mrg-top-20'}>
                    {/*<FormControlLabelComponent label={'Upload Gallery'}/>*/}
                    <div className="gallery-image-holder">
                        {galleryFiles.map((file: any, index: number) => {
                            return (<div key={index} className="image-preview-design " style={{borderWidth: 1}}>
                                <img src={file.url} alt=""/>
                                <div className={"image-preview-design-options"}>
                                    <CheckBoxComponent checked={file?.isDefaultPic} onChange={() => {
                                        setDefaultPicHandler(file._id);
                                    }}/>
                                    {!file?.isDefaultPic && <div className="close-button" onClick={event => {
                                        event.preventDefault();
                                        removeGalleryImage(eventId, file._id);
                                    }}>

                                        <CloseIcon/>
                                    </div>}
                                </div>
                            </div>)
                        })}
                    </div>
                </div>}
                <br/>
                {/*<FormControlLabelComponent label={'Upload Gallery (optional)'}/>*/}
                <div className={'upload-gallery-details-header'}>Upload Gallery</div>
                <FilePickerComponent
                    // maxFileSizeInMB={3}
                    multiple={true}
                    maxFileCount={10}
                    acceptedFileTypes={['png', 'jpeg', 'jpg']}
                    showDropZone={true}
                    onFilesDrop={acceptedFiles => {
                        // setGalleryFiles(prevState => ([...prevState, ...acceptedFiles]));

                        uploadImages(acceptedFiles[0]);
                    }}
                    id={'gallery'}
                    // acceptedFilesText={'.jpg, .png, .jpeg max file size 3.0MB'}
                />
                {/*<div className="gallery-image-holder">*/}
                {/*    {galleryLocalFiles.length > 0 && galleryLocalFiles.map((file: any, index: number) => {*/}
                {/*        return (<div key={index} className="image-preview-design">*/}
                {/*            <img src={URL.createObjectURL(file)} alt=""/>*/}
                {/*            <div className={"action-buttons-wrapper"}>*/}
                {/*                <CheckBoxComponent checked={(selectedImage === index)} onChange={() => {*/}
                {/*                    setSelectedImage(index)*/}
                {/*                }}/>*/}
                {/*                <div className="close-btn" onClick={event => {*/}
                {/*                    event.preventDefault();*/}
                {/*                    setGalleryLocalFiles(galleryLocalFiles.filter((item: any, i) => i !== index));*/}
                {/*                }}>*/}
                {/*                    <CloseIcon/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>)*/}
                {/*    })}*/}
                {/*</div>*/}
            </div>
        </div>
    );

};

export default EventGalleryComponent;
