import React from "react";
import { Outlet } from "react-router-dom";

interface InvoicesComponentProps {}

const InvoicesComponent = (props: InvoicesComponentProps) => {
  return <Outlet />;
};

export default InvoicesComponent;
