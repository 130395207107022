import {
  ADD_AMENITY,
  ADD_ANNOUNCEMENT,
  ADD_DEFAULT_ANNOUNCEMENT,
  ADD_PROPERTY,
  ADD_VISITOR,
  EDIT_VISITOR,
  AMENITY,
  ANNOUNCEMENT,
  ANNOUNCEMENT_LIST,
  COMING_SOON_ROUTE,
  DASHBOARD,
  EDIT_AMENITY,
  EDIT_ANNOUNCEMENT,
  EDIT_DEFAULT_ANNOUNCEMENT,
  EDIT_PROPERTY,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
  POLLS,
  POLLS_ADD,
  POLLS_DETAILS,
  EVENT_DETAILS,
  POLLS_EDIT,
  POLLS_LIST,
  EVENTS,
  EVENT_ADD,
  EVENTS_LIST,
  FEEDBACK_LIST,
  PROPERTY,
  PROPERTY_DETAILS,
  PROPERTY_lIST,
  TEST_ROUTE,
  USER_ADD,
  USER_BULK_UPLOAD,
  USER_EDIT,
  USER_LIST,
  VIEW_AMENITY,
  VISITOR_lIST,
  EDIT_EVENT,
  TENANTS,
  TENANT_LIST,
  TENANT_ADD,
  EDIT_TENANT,
  TENANT_DETAILS,
  ENTITY,
  ENTITY_ADD,
  ENTITY_DETAILS,
  ENTITY_LIST,
  ENTITY_EDIT,
  INVOICE_LIST,
  CREATE_INVOICE,
  INVOICE_DETAILS,
  VERIFY_INVOICE,
  EDIT_BILLING_PREFERENCES,
  EDIT_AMENITY_BILLING,
  DEBITNOTE_LIST,
  DEBITNOTE_DETAILS,
} from '../../constants/RoutesConfig';

const DefaultRoute = () => {
  return '/';
};

const LoginRoute = () => {
  return LOGIN_ROUTE;
};

const NotFoundRoute = () => {
  return NOT_FOUND_ROUTE;
};

const TestRoute = () => {
  return TEST_ROUTE;
};

const ComingSoonRoute = () => {
  return COMING_SOON_ROUTE;
};

const Dashboard = () => {
  return DASHBOARD;
};

const EntityRoute = () => {
  return ENTITY;
};
const PropertyRoute = () => {
  return PROPERTY;
};

const AnnouncementRoute = () => {
  return ANNOUNCEMENT;
};

const AmenityRoute = () => {
  return AMENITY;
};
const AmenityAddRoute = () => {
  return ADD_AMENITY;
};
const AmenityEditRoute = (id: string) => {
  return EDIT_AMENITY + '/' + id;
};

const AmenityViewRoute = (id: string) => {
  return VIEW_AMENITY + '/' + id;
};

const AddAnnouncementRoute = () => {
  return ADD_ANNOUNCEMENT;
};
const AddDefaultAnnouncementRoute = () => {
  return ADD_DEFAULT_ANNOUNCEMENT;
};

const EditAnnouncementRoute = (announcementId: string) => {
  return EDIT_ANNOUNCEMENT + '/' + announcementId;
};
const EditDefaultAnnouncementRoute = (announcementId: string) => {
  return EDIT_DEFAULT_ANNOUNCEMENT + '/' + announcementId;
};
const AnnouncementListRoute = () => {
  return ANNOUNCEMENT_LIST;
};

const PropertyListRoute = () => {
  return PROPERTY_lIST;
};

const VisitorListRoute = () => {
  return VISITOR_lIST;
};

const PropertyDetails = (id: string | undefined) => {
  return PROPERTY_DETAILS + '/' + id;
};

const AddVisitorRoute = () => {
  return ADD_VISITOR;
};

const EditVisitorRoute = (id: any) => {
  return EDIT_VISITOR + '/' + id;
};

const AddPropertyRoute = () => {
  return ADD_PROPERTY;
};

const EditPropertyRoute = () => {
  return EDIT_PROPERTY;
};

const AddEntityRoute = () => {
  return ENTITY_ADD;
};

const EditEntityRoute = () => {
  return ENTITY_EDIT;
};
const UserListRoute = () => {
  return USER_LIST;
};

const UserAddRoute = () => {
  return USER_ADD;
};

const UserEditRoute = (id: string) => {
  return USER_EDIT + '/' + id;
};

const UserBulkUpload = () => {
  return USER_BULK_UPLOAD;
};

const PollsRoute = () => {
  return POLLS;
};

const EventsRoute = () => {
  return EVENTS;
};

const TenantsRoute = () => {
  return TENANTS;
};

const EventsListRoute = () => {
  return EVENTS_LIST;
};

const TenantsListRoute = () => {
  return TENANT_LIST;
};

const FeedbackRoute = () => {
  return FEEDBACK_LIST;
};

const PollsListRoute = () => {
  return POLLS_LIST;
};

const AddPollsRoute = () => {
  return POLLS_ADD;
};

const AddEventsRoute = () => {
  return EVENT_ADD;
};

const AddTenantsRoute = () => {
  return TENANT_ADD;
};

const EditPollsRoute = () => {
  return POLLS_EDIT;
};

const PollsDetails = (id: string) => {
  return POLLS_DETAILS + '/' + id;
};

const EventDetails = (id: string) => {
  return EVENT_DETAILS + '/' + id;
};

const TenantDetails = (id: string) => {
  return TENANT_DETAILS + '/' + id;
};

const EventEditRoute = (id: string) => {
  return EDIT_EVENT + '/' + id;
};

const TenantEditRoute = (id: string) => {
  return EDIT_TENANT + '/' + id;
};

const EntityDetailsRoute = (id: string) => {
  return ENTITY_DETAILS + '/' + id;
};

const EntityListRoute = () => {
  return ENTITY_LIST;
};

// INVOICE

const InvoicesListRoute = () => {
  return INVOICE_LIST;
};

const InvoiceDetailsRoute = (id: string | undefined) => {
  return INVOICE_DETAILS + '/' + id;
};

const VerifyInvoiceRoute = (id: string | undefined) => {
  return VERIFY_INVOICE + '/' + id;
};

const CreateInvoiceRoute = () => {
  return CREATE_INVOICE;
};

//DEBIT NOTE

const DebitNoteListRoute = () => {
  return DEBITNOTE_LIST;
};

const DebitNoteDetailsRoute = (id: string | undefined) => {
  return DEBITNOTE_DETAILS + '/' + id;
};

const editBillingPreferenceRoute = (id: string | undefined) => {
  return EDIT_BILLING_PREFERENCES + '/' + id;
};
const editAmenityBillingRoute = (id: string | undefined) => {
  return EDIT_AMENITY_BILLING + '/' + id;
};

const RouteConfigService = {
  DefaultRoute,
  LoginRoute,
  NotFoundRoute,
  TestRoute,
  ComingSoonRoute,
  Dashboard,
  PropertyRoute,
  AnnouncementRoute,
  AmenityRoute,
  AmenityAddRoute,
  AmenityViewRoute,
  AmenityEditRoute,
  AddAnnouncementRoute,
  AddDefaultAnnouncementRoute,
  EditAnnouncementRoute,
  EditDefaultAnnouncementRoute,
  AnnouncementListRoute,
  PropertyListRoute,
  VisitorListRoute,
  PropertyDetails,
  AddVisitorRoute,
  EditVisitorRoute,
  AddPropertyRoute,
  UserListRoute,
  UserAddRoute,
  UserEditRoute,
  UserBulkUpload,
  EditPropertyRoute,
  PollsListRoute,
  AddPollsRoute,
  AddEventsRoute,
  AddTenantsRoute,
  PollsDetails,
  EventDetails,
  TenantDetails,
  EventEditRoute,
  TenantEditRoute,
  EditPollsRoute,
  PollsRoute,
  EventsRoute,
  TenantsRoute,
  EventsListRoute,
  TenantsListRoute,
  FeedbackRoute,
  EntityRoute,
  AddEntityRoute,
  EntityDetailsRoute,
  EntityListRoute,
  EditEntityRoute,
  InvoicesListRoute,
  CreateInvoiceRoute,
  InvoiceDetailsRoute,
  VerifyInvoiceRoute,
  editBillingPreferenceRoute,
  editAmenityBillingRoute,
  DebitNoteListRoute,
  DebitNoteDetailsRoute,
};

export default RouteConfigService;
