import {Outlet} from "react-router-dom";

interface PropertyComponentProps {

}

const PropertyComponent = (props: PropertyComponentProps) => {

    return (
        <Outlet/>
    );

};

export default PropertyComponent;
