import './VisitorEditComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import * as Yup from 'yup';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { ImageConfig, Misc } from '../../../constants';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikRadioButtonGroupComponent from '../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import FormikDatePickerComponent from '../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikTimePickerComponent from '../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';
import FormikSelectDropdownComponent from '../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import moment from 'moment';

export interface VisitorEditComponentProps {
  visitor: any;
  onClose: () => void;
  selectedTab: string;
  setRefreshToken?: any;
  onActionSuccess: () => void;
}

interface IAddVisitorFormValues {
  includeParkingSpace: boolean;
  name: string;
  email: string;
  phone: string;
  purposeOfVisit: string;
  hereToVisitName: string;
  typeOfVisit: string;
  organization: string;
  date: any;
  time: any;
  vehicleNo: string;
  propertyId: string;
  towerId: string;
  floorNo: string;
}

const AddVisitorFormValidationSchema = Yup.object({
  includeParkingSpace: Yup.boolean().required('Required'),
  name: Yup.string()
    .min(3, 'Guest Name should be at least 3 characters')
    .max(50, 'Guest Name should not exceed 50 characters')
    .required('Guest Name is required'),
  phone: Yup.string()
    .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
    .required('Guest phone number is required'),
  purposeOfVisit: Yup.string()
    .min(3, 'Purpose of visit should be at least 3 characters')
    .max(50, 'Purpose of visit should not exceed 50 characters')
    .required('Purpose of visit is required'),
  hereToVisitName: Yup.string()
    .min(3, 'Host Name should be at least 3 characters')
    .max(50, 'Host Name should not exceed 50 characters')
    .required('Host Name is required'),
  typeOfVisit: Yup.string().required('Required'),
  organization: Yup.string()
    .max(50, 'Organization should not exceed 50 characters')
    .required('Guest Organisation is required'),
  date: Yup.string().required('Visit date is required').nullable(),
  time: Yup.string().required('Required'),
  propertyId: Yup.string().required('Required'),
  towerId: Yup.string().nullable(),
  floorNo: Yup.string().nullable(),
});

const VisitorEditComponent = (props: VisitorEditComponentProps) => {
  const { visitor, onClose, setRefreshToken } = props;
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );

  const AddVisitorFormInitialValues: IAddVisitorFormValues = {
    includeParkingSpace: false,
    name: '',
    email: '',
    phone: '',
    purposeOfVisit: '',
    hereToVisitName: '',
    typeOfVisit: 'Planned',
    organization: '',
    date: '',
    time: '',
    vehicleNo: '',
    propertyId: currentUser?.propertyId ? currentUser.propertyId : '',
    towerId: '',
    floorNo: '',
  };

  const [initialValues, setInitialValues] = useState<IAddVisitorFormValues>(
    AddVisitorFormInitialValues,
  );
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const [visitorDetails, setVisitorDetails] = useState<any | null>(null);
  const getVisitorDetails = useCallback(() => {
    const payload = {};
    CommonService._visitor
      .getVisitorDetails(visitor._id, payload)
      .then((response: IAPIResponseType<any>) => {
        setVisitorDetails(response.data);
      })
      .catch((error: any) => {})
      .finally(() => {});
  }, [visitor]);

  useEffect(() => {
    getVisitorDetails();
  }, [getVisitorDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors }: FormikHelpers<any>) => {
      // setIsAddVisitorInProgress(true);
      const payload = {
        ...values,
        date: moment(values?.date).format('YYYY-MM-DD'),
        time: moment(values.time, ['h:mm A']).format('HH:mm'),
        vehicleNo:
          typeof values.vehicleNo === 'string'
            ? values.vehicleNo
            : values.vehicleNo.join(),
      };
      CommonService._vms
        .EditVisitorAPICall(visitor._id, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          setRefreshToken();
          onClose();
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {});
    },
    [setRefreshToken, visitor, onClose],
  );

  useEffect(() => {
    if (visitorDetails) {
      setInitialValues({
        includeParkingSpace: visitorDetails?.includeParkingSpace,
        name: visitorDetails?.name,
        email: visitorDetails?.email,
        phone: visitorDetails?.phone,
        purposeOfVisit: visitorDetails?.purposeOfVisit,
        hereToVisitName: visitorDetails?.hereToVisitName,
        typeOfVisit: visitorDetails?.typeOfVisit,
        organization: visitorDetails?.organization,
        date: visitorDetails?.date,
        time: visitorDetails?.time ? visitorDetails?.time : '',
        vehicleNo: visitorDetails?.vehicleNo,
        propertyId: visitorDetails?.propertyId,
        towerId: visitorDetails?.towerId,
        floorNo: visitorDetails?.floorNo,
      });
    }
  }, [visitorDetails]);

  return (
    <div className='visitor-edit-details add-screen'>
      <div className={'t-form-screen'}>
        <Formik
          validationSchema={AddVisitorFormValidationSchema}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, validateForm, isValid, setFieldValue }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              validateForm();
            }, [validateForm, values]);
            return (
              <Form className='t-form' noValidate={true}>
                <div className='add-visitor-component-content'>
                  <div>
                    <Field name={`name`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Guest Name'}
                          placeholder={'Enter Guest Name'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field name={`phone`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Guest Phone Number'}
                          placeholder={'+91 00000 00000'}
                          type={'text'}
                          validationPattern={/^[0-9\b]+$/}
                          maxlength={10}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field name={'email'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Guest Email ID'}
                          placeholder={'Enter Guest Email ID'}
                          type={'text'}
                          required={false}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field name={'purposeOfVisit'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Purpose of Visit'}
                          placeholder={'Example: Came for a business meet'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>

                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'hereToVisitName'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'Host Name'}
                              placeholder={'Example: Mr. XYZ'}
                              type={'text'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>

                      <div className='ts-col-6'>
                        <Field name={'organization'}>
                          {(field: FieldProps) => (
                            <FormikInputComponent
                              label={'Guest Organisation'}
                              placeholder={'Example: Google'}
                              type={'text'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'date'}>
                          {(field: FieldProps) => (
                            <FormikDatePickerComponent
                              label={'Visit Date'}
                              minDate={new Date()}
                              placeholder={'Select Date'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-6'>
                        <Field name={'time'}>
                          {(field: FieldProps) => (
                            <FormikTimePickerComponent
                              label={'Visit Time'}
                              placeholder={'Select Time'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <Field name={'vehicleNo'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Vehicle Numbers'}
                          placeholder={'Example: TS76YD3456, AP98JA7484'}
                          labelTooltip={'Enter comma separated vehicle numbers'}
                          type={'text'}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <Field name={'includeParkingSpace'}>
                      {(field: FieldProps) => (
                        <FormikRadioButtonGroupComponent
                          label={'Include Parking Space?'}
                          required={true}
                          formikField={field}
                          options={CommonService._staticData.yesNoOptions}
                        />
                      )}
                    </Field>
                    <FormControlLabelComponent
                      label={'Property Details'}
                      level={3}
                      isBold={true}
                    />
                    <FormControlLabelComponent
                      label={
                        'Please enter the details of the property the visitor(s) is visiting to.'
                      }
                      level={5}
                    />
                    <Field name={'propertyId'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          options={propertyList}
                          displayWith={(option: any) => option.name}
                          valueExtractor={(option: any) => option._id}
                          onUpdate={(option: any) => {
                            setFieldValue('towerId', '');
                            setFieldValue('floorNo', '');
                          }}
                          label={'Property'}
                          placeholder={'Select Property'}
                          // required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'towerId'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={
                                propertyList.find(
                                  (property) =>
                                    property._id === values.propertyId,
                                )?.towers
                              }
                              displayWith={(option: any) => option.name}
                              valueExtractor={(option: any) => option._id}
                              disabled={!values.propertyId}
                              onUpdate={(option: any) => {
                                setFieldValue('floorNo', '');
                              }}
                              label={'Tower'}
                              placeholder={'Select Tower'}
                              required={false}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-6'>
                        <Field name={'floorNo'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={
                                propertyList
                                  ?.find(
                                    (property) =>
                                      property?._id === values.propertyId,
                                  )
                                  ?.towers.find(
                                    (tower: any) =>
                                      tower?._id === values.towerId,
                                  )?.floors
                              }
                              label={'Floor'}
                              displayWith={(option: any) => option}
                              valueExtractor={(option: any) => option}
                              placeholder={'Select Floor'}
                              disabled={!values.towerId}
                              required={false}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'drawer-actions'}>
                  <ButtonComponent
                    color={'error'}
                    variant={'outlined'}
                    fullWidth={true}
                    prefixIcon={<ImageConfig.CancelRequestButtonIcon />}
                    onClick={onClose}
                  >
                    Cancel
                  </ButtonComponent>

                  <ButtonComponent
                    fullWidth={true}
                    color={'primary'}
                    type={'submit'}
                    variant={'contained'}
                    prefixIcon={<ImageConfig.ApproveRequestButtonIcon />}
                  >
                    Confirm
                  </ButtonComponent>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default VisitorEditComponent;
