import "./feedbackListScreen.scss";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentNavParams} from "../../store/actions/navigation.action";
import {APIConfig, ImageConfig} from "../../constants";
import {ITableColumn} from "../../shared/models/table.model";
import TagComponent from "../../shared/components/tag/TagComponent";
import TableWrapperComponent from "../../shared/components/table-wrapper/TableWrapperComponent";
import moment from "moment";
import ModalComponent from "../../shared/components/modal/ModalComponent";
import DataLabelValueComponent from "../../shared/components/data-label-value/DataLabelValueComponent";
import SelectDropdownComponent from "../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import {IRootReducerState} from "../../store/reducers";

interface FeedbackListScreenProps {

}

const MODULE = 'feedback';

const FeedbackListScreen = (props: FeedbackListScreenProps) => {

    const [count, setCount] = useState<number>(0);
    const {propertyList} = useSelector((state: IRootReducerState) => state.property);
    const [feedbackData, setFeedbackData] = useState<any>({});
    const [openModal, setOpenModal] = useState<boolean>(false);

        const [filterData, setFilterData] = useState<any>({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentNavParams(' ') as any);
    }, [dispatch]);

    const handleDataLoad = useCallback((data: any) => {
        setCount(data?.total);
    }, []);

    const Columns: ITableColumn[] = useMemo(() => {
        return [
            {
                title: "Property",
                key: "property",
                dataIndex: "id",
                fixed: "left",
                width: 250,
                render: (item: any) => {
                    return <div className={'text-transform-capitalize'}>{item?.propertyDetails?.name || '-'}</div>
                }
            },
            {
                title: "Timestamp",
                key: "timestamp",
                dataIndex: "name",
                width: 250,
                fixed: "left",
                render: (item: any) => {
                    return <>{moment.utc(item?.createdAt).format('hh:mm A | DD-MM-YYYY') || '-'}</>
                }
            },
            {
                title: "Feedback",
                key: "feedback",
                dataIndex: "property",
                render: (item: any) => {
                    return <div className={"feedback-text-wrapper"}>{item.feedback.length > 60 ? item.feedback.slice(0, 60) + '...' : item.feedback  || 'N/A'}</div>
                }
            },
            {
                title: "Action",
                dataIndex: "actions",
                key: "actions",
                width: 100,
                align: 'center',
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div onClick={
                            () => {
                                setFeedbackData(item);
                                setOpenModal(true);
                            }
                        }><ImageConfig.ArrowRightOutlinedIcon/></div>
                    }
                }
            }
        ]
    }, []);

    return (
        <div className={'feedback-list-screen list-screen'}>
            <div className="list-screen-header-wrapper">
                <div className="list-screen-header">
                    <div className="list-screen-header-title-and-count">
                        <div className="list-screen-header-title">Feedbacks</div>
                        <div className="list-screen-data-count">
                            <TagComponent
                                color={'primary'}
                                label={count.toString() + ' Feedbacks'}/>
                        </div>
                    </div>
                </div>
                <div className={'user-list-dropdown'}>
                    <SelectDropdownComponent
                        placeholder={'Property'}
                        // searchMode={'clientSide'}
                        // searchable={true}
                        options={propertyList}
                        isClearable={false}
                        displayWith={option => option.name}
                        valueExtractor={option => option._id}
                        // required={true}
                        multiple={true}
                        value={filterData.propertyIds}
                        // fullWidth={true}
                        onUpdate={(value: any) => {
                            setFilterData({
                                ...filterData,
                                propertyIds: value,
                                page: 1
                            });
                        }}
                    />
                </div>
            </div>
            <div className="list-screen-content">
                <TableWrapperComponent
                    url={APIConfig.FEEDBACK_LIST.URL}
                    method={APIConfig.FEEDBACK_LIST.METHOD}
                    id={"users"}
                    moduleName={MODULE}
                    onDataLoad={handleDataLoad}
                    isPaginated={true}
                    extraPayload={filterData}
                    columns={Columns}
                    noDataText={filterData ? 'No feedback has been shared yet' : 'No feedback have been shared yet'}
                />
            </div>
            {openModal &&
                    <ModalComponent
                        className={'feedback-modal-popup'}
                        isOpen={openModal}
                        title={'Feedback'}
                        modalTitle={'modal-title'}
                        modalCloseClassName={'modal-close-x-wrapper'}
                        closeOnBackDropClick={true}
                        closeOnEsc={true} onClose={() => {
                        setOpenModal(false);
                    }}
                        showClose={true}
                    >
                        <div className={'horizontal-line'}/>
                        <div className="ts-row">
                            <div className={'ts-col-lg-6'}>
                                <DataLabelValueComponent label={'Timestamp'}>
                                    {moment.utc(feedbackData?.createdAt).format('hh:mm A | DD-MM-YYYY') || '-'}
                                </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-6 property-label-wrapper-style'}>
                                <DataLabelValueComponent label={'Property'}>
                                    {feedbackData?.propertyDetails?.name || '-'}
                                </DataLabelValueComponent>
                            </div>
                        </div>
                            <div>
                                <DataLabelValueComponent label={'Feedback'}>
                                    <div className={"text-box-wrapper"}>{feedbackData?.feedback || '-'}</div>
                                </DataLabelValueComponent>
                            </div>
                    </ModalComponent>
                }
        </div>
    );
};

export default FeedbackListScreen;
