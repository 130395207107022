import { useCallback, useEffect, useState } from 'react';
import './InvoiceDetailsScreen.scss';
import CardComponent from '../../../shared/components/card/CardComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import { ImageConfig, Misc } from '../../../constants';
import TableComponent from '../../../shared/components/table/TableComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { ITableColumn } from '../../../shared/models/table.model';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import moment from 'moment';
import QRCode from 'react-qr-code';
import ModalComponent from '../../../shared/components/modal/ModalComponent';
import TextAreaComponent from '../../../shared/components/form-controls/text-area/TextAreaComponent';
import FilePickerComponent from '../../../shared/components/file-picker/FilePickerComponent';
import FilePreviewThumbnailComponent from '../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent';

interface InvoiceDetailsScreenProps {}

const InvoiceDetailsProductListOne: ITableColumn[] = [
  {
    title: 'S.no',
    key: 'sno',
    dataIndex: 'sno',
    width: '50',
    render: (item: any) => {
      return <>{item?.SlNo || '1'}</>;
    },
  },
  {
    title: 'Item Description',
    key: 'description',
    dataIndex: 'description',
    width: '120',
    render: (item: any) => {
      return <>{item?.PrdDesc || '-'}</>;
    },
  },
  {
    title: 'Is Service',
    key: 'isService',
    dataIndex: 'isService',
    width: '75',
    render: (item: any) => {
      return <>{item?.IsServc === 'Y' ? 'Yes' : 'No' || '-'}</>;
    },
  },
  {
    title: 'HSN Code',
    key: 'hsnCode',
    dataIndex: 'hsnCode',
    width: '80',
    render: (item: any) => {
      return <>{item?.HsnCd || '-'}</>;
    },
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity',
    width: '70',
    render: (item: any) => {
      return <>{item?.Qty || 0}</>;
    },
  },
  {
    title: 'Unit',
    key: 'unit',
    dataIndex: 'unit',
    width: '50',
    render: (item: any) => {
      return <>{item?.Unit || '--'}</>;
    },
  },
  {
    title: 'Unit Price (Rs)',
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    width: '100',
    render: (item: any) => {
      return <>{item?.UnitPrice || 0}</>;
    },
  },
  {
    title: 'Taxable Amount (Rs)',
    key: 'taxableAmount',
    dataIndex: 'taxableAmount',
    width: '135',
    render: (item: any) => {
      return <>{item?.AssAmt || 0}</>;
    },
  },

  {
    title: 'Item Total',
    key: 'total',
    dataIndex: 'total',
    width: '80',
    render: (item: any) => {
      return <>{item?.TotAmt || '-'}</>;
    },
  },
];

const InvoiceDetailsProductListTwo: ITableColumn[] = [
  {
    title: 'CGST Amt',
    key: 'cgstAmt',
    dataIndex: 'cgstAmt',
    width: '100',
    render: (item: any) => {
      return <>{item?.CgstAmt || 0}</>;
    },
  },
  {
    title: 'SGT Amt',
    key: 'sgtAmt',
    dataIndex: 'sgtAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.SsgtAmt || 0}</>;
    },
  },
  {
    title: 'IGST Amt',
    key: 'igstAmt',
    dataIndex: 'igstAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.IgstAmt || 0}</>;
    },
  },
  {
    title: 'CESS Amt',
    key: 'cessAmt',
    dataIndex: 'cessAmt',
    width: '80',
    render: (item: any) => {
      return <>{item?.CesAmt || 0}</>;
    },
  },
  {
    title: 'State CESS',
    key: 'stateCess',
    dataIndex: 'stateCess',
    width: '100',
    render: (item: any) => {
      return <>{item?.StateCesAmt || 0}</>;
    },
  },
  {
    title: 'Discount',
    key: 'discount',
    dataIndex: 'discount',
    width: '70',
    render: (item: any) => {
      return <>{item?.Discount || 0}</>;
    },
  },

  {
    title: 'Other Charges',
    key: 'otherCharges',
    dataIndex: 'otherCharges',
    width: '100',
    render: (item: any) => {
      return <>{item?.OthChrg || 0}</>;
    },
  },
  {
    title: 'Total Inv.Amt',
    key: 'totalInvAmt',
    dataIndex: 'totalInvAmt',
    width: '120',
    render: (item: any) => {
      return <>{item?.TotItemVal || 0}</>;
    },
  },
];

const InvoiceDetailsScreen = (props: InvoiceDetailsScreenProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { invoiceId } = useParams();
  const navigateBack = location?.state?.cameFrom;
  const [invoiceDetails, setInvoiceDetails] = useState<any>(null);
  const [isInvoiceDetailsLoading, setIsInvoiceDetailsLoading] =
    useState<boolean>(false);
  const [isInvoiceDetailsLoaded, setIsInvoiceDetailsLoaded] =
    useState<boolean>(false);
  const [isInvoiceDetailsLoadingFailed, setIsInvoiceDetailsLoadingFailed] =
    useState<boolean>(false);
  const [gstData, setGstData] = useState<any>(null);
  const [openCancelInvoiceModel, setOpenCancelInvoiceModel] =
    useState<boolean>(false);
  const [reasonForCancellation, setReasonForCancellation] =
    useState<any>(undefined);
  const [openMarkAsPaidModal, setOpenMarkAsPaidModal] =
    useState<boolean>(false);
  const [markAsPaidFile, setMarkAsPaidFile] = useState<any>('');
  const [invoiceGeneratedDateExpiry, setInvoiceGeneratedDateExpiry] =
    useState<boolean>(false);
  const [isInvoiceHandlerLoading, setIsInvoiceHandlerLoading] =
    useState<boolean>(false);
  const [isInvoiceDownloadLoading, setIsInvoiceDownloadLoading] =
    useState<boolean>(false);
  const [qrcode, setQrCode] = useState<string>('');

  useEffect(() => {
    if (invoiceDetails) {
      setGstData(invoiceDetails.gstData);
    }
  }, [invoiceDetails, setGstData]);

  useEffect(() => {
    if (invoiceDetails) {
      dispatch(
        setCurrentNavParams(invoiceDetails?.status, 'invoice', () => {
          navigate(CommonService._routeConfig.InvoicesListRoute());
        }),
      );
    }
    if (navigateBack === 'tenant') {
      dispatch(
        setCurrentNavParams(invoiceDetails?.status, 'invoice', () => {
          navigate(
            CommonService._routeConfig.TenantDetails(invoiceDetails?.tenantId),
          );
        }),
      );
    }
    if (navigateBack === 'amenity') {
      dispatch(
        setCurrentNavParams(invoiceDetails?.status, 'invoice', () => {
          navigate(
            CommonService._routeConfig.AmenityViewRoute(
              invoiceDetails?.amenityId,
            ),
          );
        }),
      );
    }
  }, [dispatch, navigate, invoiceDetails, navigateBack]);

  const getInvoiceDetails = useCallback(() => {
    setIsInvoiceDetailsLoading(true);
    const payload: any = {};
    if (invoiceId) {
      CommonService._invoice
        .GetInvoiceDetailsAPICall(invoiceId, payload)
        .then((response: IAPIResponseType<any>) => {
          const invoiceDetails = response?.data;
          setQrCode(invoiceDetails?.gstInvoiceDetails?.SignedQRCode);
          setInvoiceDetails(invoiceDetails);
          setReasonForCancellation(invoiceDetails?.reasonForCancellation);
          setIsInvoiceDetailsLoaded(true);
          setIsInvoiceDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsInvoiceDetailsLoaded(false);
          setIsInvoiceDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsInvoiceDetailsLoading(false);
        });
    }
  }, [invoiceId]);

  useEffect(() => {
    getInvoiceDetails();
  }, [getInvoiceDetails]);

  const invoiceGeneratedDateExpiryHandler = useCallback(() => {
    if (invoiceDetails) {
      const generatedDateObj = new Date(invoiceDetails?.generatedDate);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - generatedDateObj.getTime();
      const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
      if (timeDifference > twentyFourHoursInMilliseconds) {
        setInvoiceGeneratedDateExpiry(true);
      }
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (invoiceDetails) {
      invoiceGeneratedDateExpiryHandler();
    }
  }, [invoiceDetails, invoiceGeneratedDateExpiryHandler]);

  const cancelInvoiceHandler = useCallback(() => {
    setOpenCancelInvoiceModel(false);
    setIsInvoiceHandlerLoading(true);
    if (invoiceId) {
      const payload = {
        reasonForCancellation: reasonForCancellation,
      };
      CommonService._invoice
        .cancelInvoiceAPICall(invoiceId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(response.message, 'success');
          if (response.success) {
            window.scroll(0, 0);
            setIsInvoiceHandlerLoading(false);
            getInvoiceDetails();
          }
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error.error, 'error');
        })
        .finally(() => {
          setOpenCancelInvoiceModel(false);
          setIsInvoiceHandlerLoading(false);
        });
    }
  }, [reasonForCancellation, invoiceId, getInvoiceDetails]);

  const markAsPaidConfirmation = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: 'Mark as Paid',
      image: ImageConfig.TickMarkIcon,
      confirmationDescription:
        'Are you sure you want to mark the invoice as paid?',
      no: {
        color: 'default',
        text: 'No',
        variant: 'outlined',
      },
      yes: {
        color: 'primary',
        text: 'Yes',
        variant: 'contained',
      },
    }).then(() => {
      setOpenMarkAsPaidModal(true);
    });
  }, []);

  const markAsPaidHandler = useCallback(() => {
    setOpenCancelInvoiceModel(false);
    setIsInvoiceHandlerLoading(true);

    const payload = {
      image: markAsPaidFile,
    };
    const formData = CommonService.getFormDataFromJSON(payload);
    if (invoiceId && markAsPaidFile) {
      CommonService._invoice
        .markAsPaidInvoiceAPICall(invoiceId, formData)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          if (response.success) {
            window.scrollTo(0, 0);
            getInvoiceDetails();
            setIsInvoiceHandlerLoading(false);
          }
          setMarkAsPaidFile('');
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
        })
        .finally(() => {
          setOpenMarkAsPaidModal(false);
          setIsInvoiceHandlerLoading(false);
          setMarkAsPaidFile('');
        });
    }
  }, [markAsPaidFile, invoiceId, getInvoiceDetails]);

  const handleInvoiceDownload = useCallback(async () => {
    let payload: any;
    if (invoiceDetails?.invoicePDF) {
      setIsInvoiceDownloadLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        CommonService.downloadFile(
          invoiceDetails?.invoicePDF?.url,
          invoiceDetails?.invoicePDF?.name,
        );
      } catch (err) {
        console.log('ERROR WHILE DOWNLOADNG INVOICE', err);
        setIsInvoiceDownloadLoading(false);
      } finally {
        setIsInvoiceDownloadLoading(false);
      }
    } else {
      setIsInvoiceDownloadLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await CommonService._invoice
        .GetInvoiceDetailsAPICall(invoiceDetails?._id, payload)
        .then(async (response: IAPIResponseType<any>) => {
          const invoiceDetails = response?.data;
          setIsInvoiceDownloadLoading(false);
          if (invoiceDetails?.invoicePDF) {
            CommonService.downloadFile(
              invoiceDetails?.invoicePDF?.url,
              invoiceDetails?.invoicePDF?.name,
            );
          }
        })
        .catch((error: any) => {
          setIsInvoiceDownloadLoading(false);
        })
        .finally(() => {
          setIsInvoiceDownloadLoading(false);
        });
    }
  }, [invoiceDetails]);

  const InvoiceDetailsHeader = useCallback(
    (status: string, paymentStatus: string) => {
      const downloadInvoiceButton = (
        <ButtonComponent
          onClick={handleInvoiceDownload}
          type='button'
          variant='outlined'
          disabled={isInvoiceDownloadLoading}
        >
          {isInvoiceDownloadLoading ? 'Downloading...' : 'Download Invoice'}
        </ButtonComponent>
      );

      let headerContent = null;

      switch (status) {
        case 'draft':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='details-screen-title-with-status'>
                <div className='invoice-details-header-wrapper'>
                  <div className='details-screen-title'>Invoice Details</div>
                  <TagComponent
                    label='draft'
                    color='default'
                    showIndicator
                    className='invoice-details-tag'
                  />
                </div>
              </div>
              <div className='details-screen-options'>
                <ButtonComponent
                  color='error'
                  variant='outlined'
                  onClick={() => setOpenCancelInvoiceModel(true)}
                >
                  {/* Delete Invoice */}
                  Cancel Invoice
                </ButtonComponent>
                <LinkComponent
                  route={CommonService._routeConfig.VerifyInvoiceRoute(
                    invoiceId,
                  )}
                >
                  <ButtonComponent color='primary' variant='contained'>
                    Approve GST Invoice
                  </ButtonComponent>
                </LinkComponent>
              </div>
            </div>
          );
          break;

        case 'approved':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-details-paid'>
                <div className='invoice-screen-title'>Invoice Details</div>
                <TagComponent
                  label={status}
                  customBg={status === 'approved' ? '#D7FFD3' : '#FBFAE5'}
                  customTextColor='#636363'
                  showIndicator
                  className='invoice-details-paid-tag'
                />
              </div>
              {/* {downloadInvoiceButton} */}
            </div>
          );
          break;

        case 'sent_to_client':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-details-paid'>
                <div className='invoice-screen-title'>Invoice Details</div>
                <TagComponent
                  label={paymentStatus}
                  customBg={paymentStatus === 'paid' ? '#D7FFD3' : '#FBFAE5'}
                  customTextColor='#636363'
                  showIndicator
                  className='invoice-details-paid-tag'
                />
              </div>
              {downloadInvoiceButton}
            </div>
          );
          break;

        case 'overdue':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-screen-title'>Invoice Details</div>
              {downloadInvoiceButton}
            </div>
          );
          break;

        case 'cancelled':
          headerContent = (
            <div className='details-screen-header invoice-details-header-cancelled'>
              <div className='invoice-screen-cancelled-header-left'>
                <div className='invoice-details-header-wrapper'>
                  <div className='details-screen-title'>Invoice Details</div>
                  <TagComponent
                    label='Cancelled'
                    customBg='#FBE5E5'
                    customTextColor='#636363'
                    showIndicator
                    className='invoice-details-tag'
                  />
                </div>
                {reasonForCancellation && <div>{reasonForCancellation}</div>}
              </div>
              {status === 'cancelled' && invoiceDetails?.invoicePDF && (
                <>{downloadInvoiceButton}</>
              )}
            </div>
          );
          break;

        default:
          break;
      }

      return headerContent;
    },
    [
      invoiceId,
      reasonForCancellation,
      handleInvoiceDownload,
      isInvoiceDownloadLoading,
      invoiceDetails,
    ],
  );

  const InvoiceDetailsFooter = useCallback(
    (status: string, paymentStatus: string) => {
      const isApproved = status === 'approved';
      const isOverdue = status === 'overdue';
      const isSentToClient = status === 'sent_to_client';
      const isPaid = paymentStatus === 'paid';
      const showCancelInvoiceButton =
        (isSentToClient || isApproved) &&
        !invoiceGeneratedDateExpiry &&
        !isPaid;
      const showMarkAsPaidButton = !isPaid && !isApproved;
      const showText = !invoiceGeneratedDateExpiry && !isPaid;

      return (
        <div className='invoice-screen-footer'>
          {(isApproved || isOverdue || isSentToClient) && (
            <>
              {showText && (
                <div className='invoice-screen-footer-text'>
                  <p>
                    To proceed with cancellation, kindly ensure that the invoice
                    is Cancelled within
                  </p>
                  <p>
                    24 hours from its generation date. You can check the invoice
                    in the GST Invoices tab in the home screen.
                  </p>
                </div>
              )}
              <div className='invoice-screen-footer-buttons'>
                {showCancelInvoiceButton && (
                  <ButtonComponent
                    color='error'
                    variant='outlined'
                    className='invoice-screen-footer-button'
                    onClick={() => setOpenCancelInvoiceModel(true)}
                  >
                    Cancel Invoice
                  </ButtonComponent>
                )}
                {showMarkAsPaidButton && (
                  <ButtonComponent
                    color='primary'
                    variant='contained'
                    className='invoice-screen-footer-button'
                    onClick={markAsPaidConfirmation}
                  >
                    Mark as paid
                  </ButtonComponent>
                )}
              </div>
            </>
          )}
        </div>
      );
    },
    [markAsPaidConfirmation, invoiceGeneratedDateExpiry],
  );

  return (
    <div className='invoice-details-screen'>
      {isInvoiceHandlerLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type='spinner' />
        </div>
      ) : (
        <>
          {isInvoiceDetailsLoading && (
            <div className='h-v-center'>
              <LoaderComponent type='spinner' />
            </div>
          )}
          {isInvoiceDetailsLoadingFailed && (
            <div className='h-v-center'>
              <StatusCardComponent title='Failed to Load' />
            </div>
          )}
          {isInvoiceDetailsLoaded && (
            <div className='details-screen'>
              <CardComponent className='details-screen invoice-details-content-wrapper '>
                {InvoiceDetailsHeader(
                  invoiceDetails?.status,
                  invoiceDetails?.paymentStatus,
                )}
                <HorizontalLineComponent className='mrg-bottom-10' />

                <div>
                  <div
                    id='invoice-content'
                    className='details-screen-body invoice-details-body'
                  >
                    <CardComponent className='invoice-category'>
                      {invoiceDetails?.category === 'regularInvoice'
                        ? 'Regular Invoice'
                        : 'Utility Invoice'}
                    </CardComponent>
                    <div className='invoice-details-top-section'>
                      <div>
                        <img
                          src={ImageConfig.NewPhoenixLogo}
                          alt='phoenix-logo'
                          className='inovoice-details-image'
                        />
                        <div className='invoice-details-title'>
                          <span>
                            {invoiceDetails?.billingEntity?.gstin ? (
                              <>{invoiceDetails?.billingEntity?.gstin}</>
                            ) : (
                              <> -- </>
                            )}
                          </span>
                          <span>
                            {invoiceDetails?.billingEntity?.name ? (
                              <>{invoiceDetails?.billingEntity?.name}</>
                            ) : (
                              <>--</>
                            )}
                          </span>
                        </div>
                      </div>
                      {qrcode ? (
                        <QRCode value={qrcode} size={145} />
                      ) : (
                        <div className='invoice-details-qrcode'></div>
                      )}
                    </div>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='e-invoice details'
                          isBold={true}
                          level={5}
                        />
                        <div className={'ts-row mrg-top-20'}>
                          <div className={'ts-col-4'}>
                            <DataLabelValueComponent label={'IRN Number:'}>
                              {invoiceDetails?.gstInvoiceDetails?.Irn ? (
                                <div className='invoice-details-text'>
                                  {invoiceDetails?.gstInvoiceDetails?.Irn}
                                </div>
                              ) : (
                                <> -- </>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Ack No:'}>
                              {invoiceDetails?.gstInvoiceDetails?.AckNo ? (
                                <>{invoiceDetails?.gstInvoiceDetails?.AckNo}</>
                              ) : (
                                <> -- </>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Ack Date:'}>
                              {invoiceDetails?.gstInvoiceDetails?.AckDt ? (
                                <>{invoiceDetails?.gstInvoiceDetails?.AckDt}</>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='e-invoice details'
                          isBold={true}
                          level={5}
                        />
                        <FormControlLabelComponent
                          label={`IGST applicable despite supplier and recipient located in same state: ${
                            gstData?.ItemList?.IgstAmt === 0 ? 'No' : 'Yes'
                          }`}
                          isBold={true}
                          level={5}
                          className='mrg-top-20'
                        />
                        <div className={'ts-row mrg-top-20'}>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Supply Type Code'}>
                              {gstData?.TranDtls?.SupTyp ? (
                                <>{gstData?.TranDtls?.SupTyp}</>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Document No:'}>
                              {gstData?.DocDtls?.No ? (
                                <div className='invoice-text-overflow'>
                                  {gstData?.DocDtls?.No}
                                </div>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Place of Supply'}>
                              {invoiceDetails?.propertyDetails?.addressDetails
                                ?.state ? (
                                <>
                                  {
                                    invoiceDetails?.propertyDetails
                                      ?.addressDetails?.state
                                  }
                                </>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-2'}>
                            <DataLabelValueComponent label={'Document Type'}>
                              {gstData?.DocDtls?.Typ ? (
                                <>{gstData?.DocDtls?.Typ}</>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-3'}>
                            <DataLabelValueComponent label={'Document Date'}>
                              {gstData?.DocDtls?.Dt ? (
                                <>{gstData?.DocDtls?.Dt}</>
                              ) : (
                                <>--</>
                              )}
                            </DataLabelValueComponent>
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='Party Details'
                          isBold={true}
                          level={5}
                        />
                        <div className={'ts-row mrg-top-20'}>
                          <div className={'ts-col-lg-6'}>
                            <DataLabelValueComponent label={'Supplier:'}>
                              <div className='invoice-screen-party-detail'>
                                <p>GSTN : {gstData?.SellerDtls.Gstin}</p>
                                <p>{gstData?.SellerDtls?.LglNm}</p>
                                <p className='invoice-text-overflow'>
                                  {gstData?.SellerDtls?.Addr1},{' '}
                                  {gstData?.SellerDtls?.Loc}
                                </p>
                                <p>
                                  {gstData?.SellerDtls?.TrdNm},{' '}
                                  {gstData?.SellerDtls?.Pin}
                                </p>
                                <p>{gstData?.SellerDtls?.Em}</p>
                              </div>
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-6'}>
                            <DataLabelValueComponent label={'Recipient:'}>
                              <div className='invoice-screen-party-detail'>
                                <p>GSTIN : {gstData?.BuyerDtls.Gstin}</p>
                                <p>{gstData?.BuyerDtls?.LglNm} </p>
                                <p className='invoice-text-overflow'>
                                  {gstData?.BuyerDtls?.Addr1} ,{' '}
                                  {gstData?.BuyerDtls?.Loc}
                                </p>
                                <p>
                                  {gstData?.BuyerDtls?.TrdNm} ,{' '}
                                  {gstData?.BuyerDtls?.Pin}
                                </p>
                                <p>{gstData?.BuyerDtls?.Em}</p>
                              </div>
                            </DataLabelValueComponent>
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='Details of Goods/Services'
                          isBold={true}
                          level={5}
                        />
                        <CardComponent
                          className={'mrg-top-20 e-invoice-details-card'}
                        >
                          <TableComponent
                            noDataText={'No invoice details available.'}
                            className={'mrg-top-15 e-invoice-details-table'}
                            autoHeight={true}
                            columns={InvoiceDetailsProductListOne}
                            data={invoiceDetails?.gstData?.ItemList}
                          />
                        </CardComponent>
                        <CardComponent className='e-invoice-details-card'>
                          <TableComponent
                            noDataText={'No invoice details available.'}
                            className={'mrg-top-15 e-invoice-details-table'}
                            autoHeight={true}
                            columns={InvoiceDetailsProductListTwo}
                            data={invoiceDetails?.gstData?.ItemList}
                          />
                        </CardComponent>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='e-invoice details'
                          isBold={true}
                          level={5}
                        />
                        <div className={'mrg-top-20'}>
                          <div className='invoice-details-signature-wrapper'>
                            <div className='ts-row invoice-details-last-section'>
                              <div className={'ts-col-lg-6'}>
                                <DataLabelValueComponent label={'Generated by'}>
                                  {invoiceDetails?.billingEntity?.gstin ? (
                                    <>{invoiceDetails?.billingEntity?.gstin}</>
                                  ) : (
                                    <> -- </>
                                  )}
                                </DataLabelValueComponent>
                              </div>
                              <div className={'ts-col-lg-6'}>
                                <DataLabelValueComponent label={'Print Date'}>
                                  {invoiceDetails?.generatedDate ? (
                                    <>
                                      {moment(
                                        invoiceDetails?.generatedDate,
                                      ).format('DD-MM-YYYY')}
                                    </>
                                  ) : (
                                    <>--</>
                                  )}
                                </DataLabelValueComponent>
                              </div>
                            </div>
                            <div className='invoice-details-signature'>
                              <img
                                src={ImageConfig.InvoiceSignature}
                                alt='invoice-signature'
                                width={'100%'}
                                height={'100%'}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                  <div className='invoice-details-attachment-wrapper'>
                    {invoiceDetails?.attachments?.length > 0 && (
                      <CardComponent className='invoice-details-attachment-card'>
                        <div>
                          <FormControlLabelComponent
                            label='Attachments'
                            isBold={true}
                            level={5}
                          />
                        </div>
                        <div className='invoice-attachments'>
                          {invoiceDetails?.attachments?.map((file: any) => (
                            <div className='invoice-pdf'>
                              <div
                                key={file.url}
                                className='invoice-details-attachment'
                              >
                                <img
                                  src={ImageConfig.PDFIcon}
                                  alt='pdf-icon'
                                  width={30}
                                  height={30}
                                />
                                <div>{file.name}</div>
                                <div
                                  onClick={() =>
                                    CommonService.downloadFile(
                                      file.url,
                                      file.name,
                                    )
                                  }
                                  className='invoice-pdf-download'
                                >
                                  <ImageConfig.DownloadInvoiceIcon />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardComponent>
                    )}
                  </div>
                  <div className='invoice-details-attachment-wrapper'>
                    {invoiceDetails?.receiptFile?.url && (
                      <CardComponent className='invoice-details-attachment-card'>
                        <div>
                          <FormControlLabelComponent
                            label='Receipt File'
                            isBold={true}
                            level={5}
                          />
                        </div>
                        <div className='invoice-attachments'>
                          <div className='invoice-attachment-image-wrapper'>
                            <img
                              src={invoiceDetails?.receiptFile?.url}
                              alt={invoiceDetails?.receiptFile?.filename}
                            />
                            <div className='invoice-attachment-image-button'>
                              <div
                                onClick={() =>
                                  CommonService.downloadFile(
                                    invoiceDetails?.receiptFile?.url,
                                    invoiceDetails?.receiptFile?.filename,
                                  )
                                }
                                className='invoice-pdf-download'
                              >
                                <ImageConfig.DownloadInvoiceIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardComponent>
                    )}
                  </div>
                </div>
              </CardComponent>
              {InvoiceDetailsFooter(
                invoiceDetails?.status,
                invoiceDetails?.paymentStatus,
              )}

              {/* MODEL FOR CANCELLATION */}
              <ModalComponent
                className='confirmation-modal'
                isOpen={openCancelInvoiceModel}
                onClose={() => setOpenCancelInvoiceModel(false)}
                id='confirmation-popup'
                closeOnEsc={true}
                closeOnBackDropClick={true}
                direction='down'
                size='xs'
                showClose={false}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => setOpenCancelInvoiceModel(false)}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='error'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={
                        reasonForCancellation === undefined ||
                        reasonForCancellation.length === 0
                      }
                      onClick={cancelInvoiceHandler}
                    >
                      Confirm
                    </ButtonComponent>
                  </>
                }
              >
                <div className='confirmation-dialog-container'>
                  <>
                    {
                      <div className='confirmation-dialog-image-container'>
                        <div className={'confirmation-dialog-image'}>
                          <img
                            src={ImageConfig.CancelIcon}
                            alt={'CancelIcon'}
                            style={{ height: 40, width: 37 }}
                          />
                        </div>
                      </div>
                    }
                    <div className='confirmation-dialog-content-wrapper'>
                      <div className={'confirmation-dialog-title'}>
                        Cancel Invoice
                      </div>
                      <div className='confirmation-dialog-sub-description'>
                        Are you sure you want to cancel the invoice generated?
                      </div>
                      <div>
                        <TextAreaComponent
                          placeholder='Reason for cancellation*'
                          fullWidth={true}
                          maxlength={500}
                          value={reasonForCancellation}
                          onChange={(value) => {
                            setReasonForCancellation(value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </ModalComponent>
              {/* MODEL FOR MARK AS PAID */}
              <ModalComponent
                isOpen={openMarkAsPaidModal}
                onClose={() => setOpenMarkAsPaidModal(false)}
                closeOnEsc={true}
                closeOnBackDropClick={true}
                direction='down'
                size='lg'
                showClose={false}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => setOpenMarkAsPaidModal(false)}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='primary'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={markAsPaidFile.length === 0}
                      onClick={markAsPaidHandler}
                    >
                      Save
                    </ButtonComponent>
                  </>
                }
              >
                <div>
                  <FormControlLabelComponent
                    label='Upload Transaction Details'
                    isBold={true}
                    level={5}
                    className='mrg-bottom-10'
                  />
                  <div className='invoice-details-file-upload'>
                    {markAsPaidFile ? (
                      <FilePreviewThumbnailComponent
                        file={markAsPaidFile}
                        onRemove={() => setMarkAsPaidFile('')}
                      />
                    ) : (
                      <FilePickerComponent
                        maxFileSizeInMB={3}
                        maxFileCount={1}
                        height={'402px'}
                        uploadDescription='(upload only one file)'
                        acceptedFileTypes={['png', 'jpeg', 'jpg']}
                        showDropZone={true}
                        acceptedFilesText='.jpg, .png, .jpeg max file size 3.0MB'
                        onFilesDrop={(acceptedFile, rejectedFile) => {
                          setMarkAsPaidFile(acceptedFile[0]);
                        }}
                      />
                    )}
                  </div>
                </div>
              </ModalComponent>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InvoiceDetailsScreen;
