import "./AddEntityComponent.scss";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import EntityDetailsAddComponent from "./entity-details-add/EntityDetailsAddComponent";

interface AddEntityComponentProps {

}

const ENTITY_DETAILS = 'entity-details';

const STEPS = [
    {
        title: 'Entity Details',
        id: ENTITY_DETAILS,
    },
];

const AddEntityComponent = (props: AddEntityComponentProps) => {

    const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
    const navigate = useNavigate();
    // const [activeStepId, setActiveStepId] = useState<string>(steps[0].id);
    const [searchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
    const [entityId, setEntityId] = useState<string>("");
    const [entityDetails, setEntityDetails] = useState<any>(undefined);
    const [isEntityDetailsLoading, setIsEntityDetailsLoading] = useState<boolean>(false);
    const location: any = useLocation();
    const path = location.pathname;

    // const updateSteps = useCallback((currentStepIndex: number) => {
    //     setCurrentStep(steps[currentStepIndex]);
    //     searchParams.set("step", STEPS[currentStepIndex].id);
    //     setSearchParams(searchParams);
    //     setActiveStepId(steps[currentStepIndex].id);
    // }, [searchParams, setSearchParams, steps]);

    const getEntityDetails = useCallback(() => {
        const payload = {
            towerDetailsRequired: true
        };
        let entityDetails: any = {};
        setIsEntityDetailsLoading(true);
        CommonService._entity.GetEntityAPICall(entityId, payload)
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    entityDetails = response.data;
                }
                setEntityDetails(entityDetails);
                setIsEntityDetailsLoading(false);
            })
            .catch((error: any) => {
                // setEntityDetails(entityDetails);
                setIsEntityDetailsLoading(false);
            });
    }, [entityId]);

    useEffect(() => {
        if (entityId) {
            getEntityDetails();
        }
    }, [getEntityDetails, entityId])

    useEffect(() => {
        const step: any = searchParams.get("step");
        // const stepperIndex = steps.findIndex(stepItem => stepItem.id === step);
        // setActiveStepId(steps[stepperIndex]?.id);
        const entityId = searchParams.get("entityId");
        const stepIDS = steps.map(item => item.id);
        let stepIndex: number = 0;
        if (entityId) {
            setEntityId(entityId);
            if (stepIDS.indexOf(step) > -1) {
                if (step !== ENTITY_DETAILS) {
                    stepIndex = stepIDS.indexOf(step);
                } else {
                    stepIndex = 0;
                }
            } else {
                stepIndex = 0;
            }
        }
        setCurrentStep(steps[stepIndex]);
    }, [searchParams, steps]);

    const handleBack = useCallback(() => {
        if (path.includes('/entity/add')) {
            navigate(CommonService._routeConfig.EntityRoute());
        } else {
            navigate(CommonService._routeConfig.EntityDetailsRoute(entityId));
        }
    }, [navigate, entityId, path])

    const handleNext = useCallback((currentStepIndex: number, currentStepId: string, entityId?: string, data?: any) => {
        switch (currentStepId) {
            case ENTITY_DETAILS :
                handleBack();
                break;
            default:
                <></>
        }
    }, [handleBack]);

    const renderComponent = useCallback(() => {
        switch (currentStep && currentStep.id) {
            case ENTITY_DETAILS:
                return <EntityDetailsAddComponent
                    next={(entityId: string, data: any) => handleNext(1, ENTITY_DETAILS, entityId, data)}
                    prev={handleBack}
                    steps={steps}
                    entityDetails={entityDetails}
                    mode={entityDetails ? 'edit' : 'add'}
                />
            default:
                <></>
        }
    }, [currentStep, handleNext, entityDetails, handleBack, steps]);


    return (
        <div className={'add-entity-component'}>
            {
                isEntityDetailsLoading ?
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div> : <>
                        {renderComponent()}
                    </>

            }

        </div>
    );

};

export default AddEntityComponent;