import './EventViewScreen.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { CommonService } from '../../../shared/services';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import * as Yup from 'yup';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import moment from 'moment';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ModalComponent from '../../../shared/components/modal/ModalComponent';
import FilePickerComponent from '../../../shared/components/file-picker/FilePickerComponent';
import { CloseIcon } from '../../../constants/ImageConfig';
import FormikTextAreaComponent from '../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { ImageConfig } from '../../../constants';
import TextAreaComponent from '../../../shared/components/form-controls/text-area/TextAreaComponent';
import BadgeComponent from '../../../shared/components/badge/BadgeComponent';

interface EventViewScreenProps {}

// const MODULE = 'Events';

const EventDetailsSteps = [
  {
    title: 'Event Details',
    id: 'eventDetails',
  },
  {
    title: 'Gallery',
    id: 'gallery',
  },
];

const EventViewScreen = (props: EventViewScreenProps) => {
  const location = useLocation();
  const { eventId } = useParams();
  const [currentTab, setCurrentTab] = useState<'eventDetails' | 'gallery'>(
    'eventDetails',
  );
  const [searchParams] = useSearchParams();
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [isEventDetailsLoading, setIsEventDetailsLoading] =
    useState<boolean>(false);
  const [isEventDetailsLoadingFailed, setIsEventDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isEventDetailsLoaded, setIsEventDetailsLoaded] =
    useState<boolean>(false);
  const [showEventPublish, setShowEventAmenityPublish] =
    useState<boolean>(false);
  const [openCancelEventModal, setOpenCancelEventModal] =
    useState<boolean>(false);
  const [cancelEventReasonText, setCancelEventReasonText] =
    useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const galleryPageNumRef = useRef<number>(0);
  const totalGalleryResultsRef = useRef<number>(0);
  // const galleryPageSizeRef = useRef<number>(10);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'Announcement title should be at least 10 characters')
      .max(80, 'Announcement title should not exceed 80 characters')
      .required('Announcement title is required'),
    description: Yup.string()
      .required('Announcement Description is required')
      .max(500, 'Announcement Description should not exceed 500 characters'),
    image: Yup.mixed(),
  });

  const getEventDetails = useCallback(() => {
    setIsEventDetailsLoading(true);
    const payload = {
      eventStatsRequired: true,
    };
    if (eventId) {
      CommonService._events
        .GetEventDetailsAPICall(eventId, payload)
        .then((response: IAPIResponseType<any>) => {
          setEventDetails(response.data);
          setIsEventDetailsLoaded(true);
          setIsEventDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsEventDetailsLoaded(false);
          setIsEventDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsEventDetailsLoading(false);
        });
    }
  }, [eventId]);

  useEffect((): void => {
    getEventDetails();
  }, [getEventDetails]);

  const [eventGallery, setEventGallery] = useState([]);
  const getAmenityGallery = useCallback((eventId: string, payload = {}) => {
    if (eventId) {
      const payloadVal = {
        eventId: eventId,
      };
      CommonService._events
        .getEventGalleryImagesAPICall(payloadVal)
        .then((response: IAPIResponseType<any>) => {
          setEventGallery(response.data.docs);
          totalGalleryResultsRef.current = response?.data?.total;
          galleryPageNumRef.current = response?.data?.page - 1;
        })
        .catch((error: any) => {})
        .finally(() => {});
    }
  }, []);

  const cancelEventHandler = useCallback(() => {
    if (eventId) {
      const payload = { reasonForCancellation: cancelEventReasonText };
      CommonService._events
        .cancelEventAPICall(eventId, payload)
        .then((response: IAPIResponseType<any>) => {
          // setOpenCancelEventModal(false);
          CommonService._alert.showToast(response.message, 'success');
          getEventDetails();
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error.error, 'error');
        })
        .finally(() => {
          setOpenCancelEventModal(false);
        });
    }
  }, [cancelEventReasonText, eventId, getEventDetails]);

  // const handleGalleryPageNumberChange = useCallback((event: unknown, newPage: number) => {
  //     galleryPageNumRef.current = newPage;
  //     if (eventId != null) {
  //         getAmenityGallery(eventId, {});
  //     }
  // }, [getAmenityGallery, eventId]);

  // const handleGalleryPageSizeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //     galleryPageSizeRef.current = +event.target.value;
  //     galleryPageNumRef.current = 0;
  //     if (eventId != null) {
  //         getAmenityGallery(eventId, {});
  //     }
  // }, [getAmenityGallery, eventId]);

  useEffect((): void => {
    if (eventId != null) {
      getAmenityGallery(eventId, { page: 1 });
    }
  }, [getAmenityGallery, eventId]);

  const handleTabChange = useCallback((value: any) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentStep = searchParams.get('currentStep');
    if (currentStep) {
      setCurrentTab(currentStep as 'eventDetails' | 'gallery');
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('currentStep', currentTab);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  }, [currentTab, location]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = EventDetailsSteps.map((item: any) => {
      return item.id;
    });
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'eventDetails';
      }
    } else {
      currentTab = 'eventDetails';
    }
    setCurrentTab(currentTab);
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      setCurrentNavParams('Event Details', null, () => {
        navigate(CommonService._routeConfig.EventsRoute());
      }),
    );
  }, [dispatch, navigate]);

  const handleBack = useCallback(() => {
    setShowEventAmenityPublish(false);
  }, []);
  const addAnnouncement = useCallback(
    (values: any, helpers: FormikHelpers<any>) => {
      const payload: any = {};
      payload['type'] = values.type;
      payload['title'] = values.title;
      payload['isDefault'] = false;
      payload['description'] = values.description;
      payload['linkedTo'] = values.linkedTo;

      const formData = CommonService.getFormDataFromJSON(payload);
      if (values.image) {
        formData.set('image', values.image);
      }
      CommonService._wall
        .addAnnouncement(formData)
        .then((response: IAPIResponseType<any>) => {
          // setAddAnnuncement(propertyDetails);
          helpers.setSubmitting(false);
          handleBack();
          CommonService._alert.showToast(response.message, 'success');
        })
        .catch((error: any) => {
          helpers.setSubmitting(false);
          CommonService._alert.showToast(error.error, 'error');
        });
    },
    [handleBack],
  );

  return (
    <div className={'event-view-screen '}>
      {openCancelEventModal && (
        <ModalComponent
          className={'confirmation-modal'}
          isOpen={openCancelEventModal}
          onClose={() => {
            setOpenCancelEventModal(false);
          }}
          id={'confirmation-popup'}
          closeOnEsc={true}
          closeOnBackDropClick={true}
          direction={'down'}
          size={'xs'}
          showClose={false}
          modalFooter={
            <>
              {
                <>
                  <ButtonComponent
                    onClick={() => {
                      setOpenCancelEventModal(false);
                    }}
                    color={'default'}
                    variant={'outlined'}
                    id={'no-btn'}
                    className={'pdd-left-60 pdd-right-60'}
                  >
                    {'Cancel'}
                  </ButtonComponent>
                  &nbsp;&nbsp;&nbsp;
                </>
              }
              <ButtonComponent
                disabled={cancelEventReasonText.length === 0}
                onClick={() => {
                  cancelEventHandler();
                }}
                color={'error'}
                variant={'contained'}
                id={'yes-btn'}
                className={'pdd-left-60 pdd-right-60'}
              >
                {'Confirm'}
              </ButtonComponent>
            </>
          }
        >
          <div className='confirmation-dialog-container'>
            <>
              {
                <div className='confirmation-dialog-image-container'>
                  <div className={'confirmation-dialog-image'}>
                    <img
                      src={ImageConfig.CancelIcon}
                      alt={'CancelIcon'}
                      style={{ height: 40, width: 37 }}
                    />
                  </div>
                </div>
              }
              <div className='confirmation-dialog-content-wrapper'>
                <div className={'confirmation-dialog-title'}>
                  {'Cancel Event'}
                </div>
                <div className='confirmation-dialog-sub-description'>
                  {'Are you sure you want to cancel the selected event?'}
                </div>
                <TextAreaComponent
                  fullWidth={true}
                  value={cancelEventReasonText}
                  onChange={(value) => {
                    setCancelEventReasonText(value);
                  }}
                />
              </div>
              <div></div>
            </>
          </div>
        </ModalComponent>
      )}
      {showEventPublish && (
        <ModalComponent
          className={'amenity-view-publish'}
          closeOnBackDropClick={true}
          closeOnEsc={true}
          onClose={() => {
            setShowEventAmenityPublish(false);
          }}
          isOpen={showEventPublish}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              title: '',
              description: '',
              image: null,
              type: 'Events',
              linkedTo: eventId,
            }}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={addAnnouncement}
          >
            {({
              values,
              errors,
              validateForm,
              isValid,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form className='t-form scroll-content ' noValidate={true}>
                  {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                  <div className='add-component-header '>
                    <div className='add-layout-header-text'>
                      Publish Announcement
                    </div>
                  </div>

                  <div className='add-component-content'>
                    <Field name={'title'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Announcement Title'}
                          placeholder={'Enter Announcement Title'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>

                    <Field name={'description'}>
                      {(field: FieldProps) => (
                        <FormikTextAreaComponent
                          label={'Announcement Description'}
                          placeholder={
                            'Enter the announcement description here'
                          }
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <br />

                    <div className={'row-wrapper'}>
                      <FormControlLabelComponent label={'Upload Image'} />
                      <div className={'optional-text'}>Optional</div>
                    </div>

                    {!!values.image && (
                      <div className='image-preview'>
                        <div
                          className='close-btn'
                          onClick={(event) => {
                            event.preventDefault();
                            setFieldValue('image', undefined);
                          }}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(values.image)} alt='' />
                      </div>
                    )}
                    <FilePickerComponent
                      acceptedFileTypes={['png', 'jpeg', 'jpg']}
                      showDropZone={true}
                      onFilesDrop={(acceptedFiles) => {
                        setFieldValue('image', acceptedFiles[0]);
                      }}
                      acceptedFilesText={'(Upload Only one image)'}
                    />
                  </div>
                  <div className='d-flex ts-justify-content-center mrg-bottom-20'>
                    <ButtonComponent
                      variant={'outlined'}
                      type={'button'}
                      color={'secondary'}
                      onClick={handleBack}
                      // suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                    >
                      Cancel
                    </ButtonComponent>
                    &nbsp;&nbsp;
                    <ButtonComponent
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      type={'submit'}
                      // suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                    >
                      Confirm
                    </ButtonComponent>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalComponent>
      )}
      {isEventDetailsLoading && (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      )}
      {isEventDetailsLoadingFailed && (
        <div className='h-v-center'>
          <StatusCardComponent title={'Failed to Load'} />
        </div>
      )}

      {isEventDetailsLoaded && (
        <div className='details-screen'>
          <CardComponent>
            <div className={'details-screen-header'}>
              <div className='details-screen-title-with-image-wrapper'>
                <div className={'details-screen-image'}>
                  <AvatarComponent
                    type='circle'
                    url={eventDetails?.defaultPic?.url}
                    title={CommonService.getTwoLetterInitials(
                      eventDetails?.name,
                    )}
                    size={'xl'}
                  />
                </div>
                <div className={'details-screen-title-wrapper'}>
                  <div className={'details-screen-title'}>
                    {CommonService.capitalizeFirstLetterOfEachWord(
                      eventDetails?.name || '',
                    ) || 'N/A'}{' '}
                  </div>
                  <div className={'details-screen-description'}>
                    {eventDetails.location?.name}
                  </div>
                </div>
              </div>
              <div className='details-screen-options'>
                {/*<div className={'ts-align-items-center bordered'}>*/}
                <TagComponent
                  className={'tag-component-position-wrapper'}
                  color={CommonService.getEventStatusTagColor(
                    eventDetails?.status,
                  )}
                  showIndicator={true}
                  label={eventDetails?.status}
                />
                {/*</div>*/}
                {eventDetails?.status === 'Upcoming' && (
                  <ButtonComponent
                    color={'error'}
                    onClick={(event) => {
                      setOpenCancelEventModal(true);
                    }}
                    variant={'outlined'}
                  >
                    Cancel Event
                  </ButtonComponent>
                )}
                <ButtonComponent
                  color={'primary'}
                  onClick={(event) => {
                    setShowEventAmenityPublish(true);
                    // CommonService._alert.showToast('Coming Soon', 'info');
                  }}
                  variant={'outlined'}
                >
                  Publish Announcement
                </ButtonComponent>
                {eventId && eventDetails?.status === 'Upcoming' && (
                  <LinkComponent
                    route={CommonService._routeConfig.EventEditRoute(eventId)}
                  >
                    <ButtonComponent color={'primary'}>
                      Edit Event Details
                    </ButtonComponent>
                  </LinkComponent>
                )}
              </div>
            </div>

            <br />
            <div className={'details-screen-header'}>
              <ButtonGroupComponent
                selected={currentTab}
                onChange={(value) => handleTabChange(value)}
                buttons={EventDetailsSteps}
              />
              <div className='ts-row pdd-right-5'>
                <div className={'total-view-vote-button ts-col'}>
                  <div>{'Interested'}</div>
                  <div className='mrg-left-10'>
                    <BadgeComponent variant='filled' color={'primary'}>
                      {eventDetails?.interactions || 0}
                    </BadgeComponent>
                  </div>
                </div>
                <div className={'total-view-vote-button ts-col'}>
                  <div>{'Views'}</div>
                  <div className='mrg-left-10'>
                    <BadgeComponent variant='filled' color={'primary'}>
                      {eventDetails?.viewCount || 0}
                    </BadgeComponent>
                  </div>
                </div>
              </div>
            </div>

            {currentTab === 'eventDetails' && (
              <div className=' mrg-top-20'>
                {eventDetails?.status === 'Cancelled' && (
                  <CardComponent className={'card-component-bgColor'}>
                    <div className='ts-row ts-no-gutters'>
                      <div className={'ts-col-lg-12'}>
                        <DataLabelValueComponent
                          label={'Reason for Cancellation'}
                        >
                          {eventDetails?.reasonForCancellation || '-'}
                        </DataLabelValueComponent>
                      </div>

                      <div className={'ts-col-lg-12'}>
                        <DataLabelValueComponent label={'Cancelled On'}>
                          {moment(eventDetails?.cancelledDate).format(
                            'Do MMM YYYY | hh:mma',
                          ) || '-'}
                        </DataLabelValueComponent>
                      </div>
                    </div>
                  </CardComponent>
                )}

                <CardComponent>
                  <div className='ts-row ts-no-gutters'>
                    <div className={'ts-col-lg-12'}>
                      <DataLabelValueComponent
                        label={'Event Description'}
                        childrenClassName={'value-text-style'}
                      >
                        {eventDetails?.description || '-'}
                      </DataLabelValueComponent>
                    </div>
                    {eventDetails?.agenda && (
                      <div className={'ts-col-lg-12'}>
                        <DataLabelValueComponent
                          label={'Event Agenda'}
                          childrenClassName={'value-text-style'}
                        >
                          {eventDetails?.agenda || '-'}
                        </DataLabelValueComponent>
                      </div>
                    )}
                  </div>
                  <div className={'ts-row'}>
                    <div className={'ts-col-lg-6'}>
                      <DataLabelValueComponent label={'Event Date and Time'}>
                        {`${moment(eventDetails?.date).format(
                          'Do MMM, YYYY',
                        )} | ${CommonService.getFormattedTime(
                          eventDetails?.time,
                        )}` || '-'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-6'}>
                      <DataLabelValueComponent label={'Event Duration'}>
                        {`${Math.floor(eventDetails?.duration / 60)}:${
                          eventDetails?.duration % 60
                        } Hrs` || '-'}
                      </DataLabelValueComponent>
                    </div>
                  </div>
                  <div className='mrg-top-15 mrg-bottom-15'>
                    <HorizontalLineComponent />
                  </div>
                  <div>
                    <FormControlLabelComponent
                      className={'pdd-bottom-15'}
                      label={'Location Details'}
                      level={4}
                      isBold={true}
                    />
                  </div>
                  <div className={'ts-row'}>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Location'}>
                        {eventDetails?.location?.name || '-'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Event can be Attended'}>
                        {/*{eventDetails?.location?.name || '-'}*/}
                        {(eventDetails?.properties || []).length > 0 && (
                          <div className={'property-tool-tip-style'}>
                            <ToolTipComponent
                              // backgroundColor={'#fff'}
                              position={'right'}
                              textColor={'#FFFFFF'}
                              tooltip={
                                <>
                                  {eventDetails?.properties.map(
                                    (value1: any, index1: number) => {
                                      return (
                                        <div
                                          className={
                                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                          }
                                          key={index1}
                                        >
                                          {value1.name}
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              }
                            >
                              <div className={'d-flex'}>
                                <ChipComponent
                                  className={'scheduled cursor-pointer'}
                                  size={'small'}
                                  color={'primary'}
                                  label={
                                    eventDetails?.properties[0].name +
                                    ((eventDetails?.properties || []).length > 1
                                      ? ' (+' +
                                        ((eventDetails?.properties || [])
                                          .length -
                                          1) +
                                        ')'
                                      : '')
                                  }
                                />
                              </div>
                            </ToolTipComponent>
                          </div>
                        )}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Event Price'}>
                        {eventDetails?.bookingDetails?.fee
                          ? eventDetails?.bookingDetails?.fee
                          : 'Free' || '-'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Remind Before'}>
                        {eventDetails?.bookingDetails?.selectReminder
                          ? `${eventDetails?.bookingDetails?.selectReminder}hrs`
                          : '-'}
                      </DataLabelValueComponent>
                    </div>
                    {eventDetails?.bookingDetails?.isBookingRequired && (
                      <>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent
                            label={'Booking Start Date and End Date'}
                          >
                            {`${moment(
                              eventDetails?.bookingDetails?.startDate,
                            ).format('Do MMM, YYYY')} | ${moment(
                              eventDetails?.bookingDetails?.endDate,
                            ).format('Do MMM, YYYY')}` || '-'}
                          </DataLabelValueComponent>
                        </div>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent
                            label={'Booking Timings Everyday'}
                          >
                            {`${CommonService.getFormattedTime(
                              eventDetails?.bookingDetails?.startTime,
                            )} - ${CommonService.getFormattedTime(
                              eventDetails?.bookingDetails?.endTime,
                            )}` || '-'}
                          </DataLabelValueComponent>
                        </div>
                      </>
                    )}
                  </div>
                </CardComponent>

                {eventDetails?.performers &&
                  eventDetails?.performers.length > 0 && (
                    <>
                      <FormControlLabelComponent
                        label={'Performer Details'}
                        level={4}
                        isBold={true}
                      />
                      {eventDetails?.performers &&
                        eventDetails?.performers.map(
                          (value: any, index: number) => {
                            return (
                              <>
                                <CardComponent>
                                  <div
                                    className={
                                      'details-screen-title-with-image-wrapper'
                                    }
                                  >
                                    <div className={'details-screen-image'}>
                                      <AvatarComponent
                                        type='circle'
                                        url={value?.image}
                                        title={CommonService.getTwoLetterInitials(
                                          value?.performerName,
                                        )}
                                        size={'sm'}
                                      />
                                    </div>
                                    <div
                                      className={'details-screen-title-wrapper'}
                                    >
                                      <div className={'details-screen-title'}>
                                        {value?.performerName}
                                      </div>
                                    </div>
                                  </div>
                                  <div key={index} className='ts-no-gutters'>
                                    <div className={'list-header-style'}>
                                      {'Performer Description'}
                                    </div>
                                    <div className={'list-description-style'}>
                                      {value?.about || '-'}
                                    </div>
                                  </div>
                                </CardComponent>
                              </>
                            );
                          },
                        )}
                    </>
                  )}
                {eventDetails?.termsAndConditions &&
                  eventDetails?.termsAndConditions !== '' && (
                    <>
                      <FormControlLabelComponent
                        label={'T&c'}
                        level={4}
                        isBold={true}
                      />
                      <CardComponent>
                        <div className='ts-no-gutters'>
                          {eventDetails?.termsAndConditions
                            ? eventDetails?.termsAndConditions
                            : ''}
                        </div>
                      </CardComponent>
                    </>
                  )}
                {eventDetails?.eventFAQ &&
                  eventDetails?.eventFAQ.length > 0 && (
                    <>
                      <FormControlLabelComponent
                        label={"FAQ's"}
                        isBold={true}
                      />
                      <CardComponent>
                        {eventDetails?.eventFAQ &&
                          eventDetails?.eventFAQ.map(
                            (value: any, index: number) => {
                              return (
                                <div key={index} className='ts-no-gutters'>
                                  <div className={'list-header-style'}>
                                    {value?.question}
                                  </div>
                                  <div className={'list-description-style'}>
                                    {value?.description || '-'}
                                  </div>
                                </div>
                              );
                            },
                          )}
                      </CardComponent>
                    </>
                  )}
              </div>
            )}

            {currentTab === 'gallery' && (
              <div className=' mrg-top-20'>
                <CardComponent className={'ts-row gallery-image-holder'}>
                  {eventGallery.length === 0 && (
                    <StatusCardComponent
                      title={'No gallery images added yet'}
                    />
                  )}
                  {eventGallery &&
                    eventGallery.map((value: any, index) => {
                      return (
                        <div
                          key={index}
                          className={'gallery-image'}
                          style={{ backgroundImage: 'url(' + value.url + ')' }}
                        ></div>
                      );
                    })}
                </CardComponent>
              </div>
            )}
          </CardComponent>
        </div>
      )}
    </div>
  );
};

export default EventViewScreen;
