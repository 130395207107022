import React, { useCallback, useEffect, useState } from 'react';
import './InvoiceStatsComponent.scss';
import InvoiceSummaryStatsComponent from '../../../shared/components/dashboard/invoice-summary-stats/InvoiceSummaryStatsComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import moment from 'moment';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import DonutChartComponent from '../../../shared/components/charts/donut/DonutChartComponent';
import InvoiceNotGeneratedCardComponent from '../../../shared/components/dashboard/invoice-not-generated-card/InvoiceNotGeneratedCardComponent';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { Skeleton } from '@mui/material';

interface InvoiceStatsComponentProps {
  entityList: any;
  propertyList: any;
  tenantList: any;
  amenityList: any;
}

const invoiceCategoryType = [
  {
    title: 'Tenant',
    id: 'tenant',
  },
  {
    title: 'Amenity',
    id: 'amenity',
  },
];
const invoiceCreationType = [
  {
    title: 'Manual',
    id: 'manual',
  },
  {
    title: 'Automated',
    id: 'automated',
  },
];
const InvoiceStatsComponent = (props: InvoiceStatsComponentProps) => {
  const { entityList, propertyList, tenantList, amenityList } = props;
  const [invoiceStatsLoading, setInvoiceStatsLoading] =
    useState<boolean>(false);
  const [invoiceStatsData, setInvoiceStatsData] = useState<any[]>([]);
  // const [invoiceTotalData, setInvoiceTotalData] = useState<any[]>([]);
  const [automatedInvoiceStatsLoading, setAutomatedInvoiceStatsLoading] =
    useState<boolean>(false);
  const [automatedInvoiceData, setAutomatedInvoiceData] = useState<any[]>([]);
  const [manualInvoiceStatsLoading, setManualInvoiceStatsLoading] =
    useState<boolean>(false);
  const [manualInvoiceData, setManualInvoiceData] = useState<any[]>([]);
  const [invoiceNotGeneratedListLoading, setInvoiceNotGeneratedListLoading] =
    useState<boolean>(false);
  const [invoiceNotGeneratedList, setInvoiceNotGeneratedList] = useState<any[]>(
    [],
  );

  const [automatedType, setAutomatedType] = useState<any>('');
  const [automatedEntityId, setAutomatedEntityId] = useState<any>('');
  const [automatedPropertyId, setAutomatedPropertyId] = useState<any>('');
  const [automatedTenantId, setAutomatedTenantId] = useState<any>('');
  const [automatedAmenityId, setAutomatedAmenityId] = useState<any>('');
  const [automatedPropertyList, setAutomatedPropertyList] = useState<any[]>([]);
  const [automatedTenantList, setAutomatedTenantList] = useState<any[]>([]);
  const [automatedAmenityList, setAutomatedAmenityList] = useState<any[]>([]);

  const [manualType, setManualType] = useState<any>('');
  const [manualEntityId, setManualEntityId] = useState<any>('');
  const [manualPropertyId, setManualPropertyId] = useState<any>('');
  const [manualTenantId, setManualTenantId] = useState<any>('');
  const [manualAmenityId, setManualAmenityId] = useState<any>('');
  const [manualPropertyList, setManualPropertyList] = useState<any[]>([]);
  const [manualTenantList, setManualTenantList] = useState<any[]>([]);
  const [manualAmenityList, setManualAmenityList] = useState<any[]>([]);

  const [notGeneratedType, setNotGeneratedType] = useState<any>('');
  const [notGeneratedEntityId, setNotGeneratedEntityId] = useState<any>('');
  const [invoiceType, setInvoiceType] = useState<string>('');

  const getInvoiceStats = useCallback(() => {
    setInvoiceStatsLoading(true);
    CommonService._dashboard
      .InvoiceStatsAPICall()
      .then((response: IAPIResponseType<any>) => {
        setInvoiceStatsData(response?.data?.invoiceStats);
        // setInvoiceTotalData(response?.data?.dashboardData);
        setInvoiceStatsLoading(false);
      })
      .catch((error) => {
        setInvoiceStatsLoading(false);
        CommonService._alert.showToast('Failed to get invoice stats', 'error');
      })
      .finally(() => {
        setInvoiceStatsLoading(false);
      });
  }, []);

  useEffect(() => {
    getInvoiceStats();
  }, [getInvoiceStats]);

  const getAutomatedInvoiceStats = useCallback(() => {
    setAutomatedInvoiceStatsLoading(true);
    let payload: any = {
      type: automatedType,
      entityId: automatedEntityId,
      propertyId: automatedPropertyId,
      tenantId: automatedTenantId,
      amenityId: automatedAmenityId,
    };
    CommonService._dashboard
      .AutomatedInvoiceStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setAutomatedInvoiceData(response?.data);
      })
      .catch(() => {
        CommonService._alert.showToast(
          'Failed to get automated invoice stats',
          'error',
        );
      })
      .finally(() => {
        setAutomatedInvoiceStatsLoading(false);
      });
  }, [
    automatedEntityId,
    automatedPropertyId,
    automatedTenantId,
    automatedAmenityId,
    automatedType,
  ]);

  useEffect(() => {
    getAutomatedInvoiceStats();
  }, [getAutomatedInvoiceStats]);

  const getManualInvoiceStats = useCallback(() => {
    setManualInvoiceStatsLoading(true);
    let payload: any = {
      type: manualType,
      entityId: manualEntityId,
      propertyId: manualPropertyId,
      tenantId: manualTenantId,
      amenityId: manualAmenityId,
    };
    CommonService._dashboard
      .ManualInvoiceStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setManualInvoiceData(response?.data);
      })
      .catch(() => {
        CommonService._alert.showToast(
          'Failed to get manual invoice stats',
          'error',
        );
      })
      .finally(() => {
        setManualInvoiceStatsLoading(false);
      });
  }, [
    manualType,
    manualEntityId,
    manualPropertyId,
    manualTenantId,
    manualAmenityId,
  ]);

  useEffect(() => {
    getManualInvoiceStats();
  }, [getManualInvoiceStats]);

  const getInvoiceNotGeneratedList = useCallback(() => {
    setInvoiceNotGeneratedListLoading(true);
    let payload: any = {
      type: notGeneratedType,
      entityId: notGeneratedEntityId,
      invoiceType: invoiceType,
    };
    CommonService._dashboard
      .InvoiceNotGeneratedListAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setInvoiceNotGeneratedList(response?.data);
      })
      .catch(() => {
        CommonService._alert.showToast(
          'Failed to get invoice not generated stats',
          'error',
        );
      })
      .finally(() => {
        setInvoiceNotGeneratedListLoading(false);
      });
  }, [notGeneratedType, notGeneratedEntityId, invoiceType]);

  useEffect(() => {
    getInvoiceNotGeneratedList();
  }, [getInvoiceNotGeneratedList]);

  return (
    <div className='invoice-stats-component'>
      {/* <div className='invoice-stats-card-wrapper'>
        {invoiceStatsLoading ? (
          Array(5)
            .fill(null)
            .map((_, index) => (
              <div key={index} className='invoice-stats-card'>
                <Skeleton
                  variant='rectangular'
                  width={'180px'}
                  height={'15px'}
                  className='invoice-stats-title'
                />
                <Skeleton
                  variant='rectangular'
                  width={'50px'}
                  height={'15px'}
                  className='invoice-stats-count'
                />
              </div>
            ))
        ) : (
          <>
            {invoiceTotalData?.map((stats: any, index: number) => (
              <div key={index} className='invoice-stats-card'>
                <div className='invoice-stats-title'>{stats?.title}</div>
                <div className='invoice-stats-count'>{stats?.value}</div>
              </div>
            ))}
          </>
        )}
      </div> */}
      <div className='invoice-summary-stats-chart-wrapper'>
        <InvoiceSummaryStatsComponent
          invoiceStatsData={invoiceStatsData}
          loading={invoiceStatsLoading}
        />
      </div>
      <div className='invoice-stats-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label={`Automated invoices for ${moment()
              .subtract(1, 'months')
              .format('MMMM')}`}
            isBold
            level={5}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Type'
              options={invoiceCategoryType}
              value={automatedType}
              displayWith={(option: any) => option.title}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setAutomatedType(value ? value : '');
                setAutomatedEntityId('');
                setAutomatedPropertyId('');
                setAutomatedTenantId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={automatedEntityId}
              disabled={!automatedType}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setAutomatedEntityId(value ? value : '');
                const updatedPropertyList = propertyList?.filter(
                  (list: any) => list.billingEntityId === value,
                );
                setAutomatedPropertyList(updatedPropertyList);
                setAutomatedPropertyId('');
                setAutomatedTenantId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Property'
              options={automatedPropertyList}
              value={automatedPropertyId}
              disabled={!automatedEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setAutomatedPropertyId(value ? value : '');
                setAutomatedTenantId('');
                setAutomatedAmenityId('');
                if (automatedType === 'tenant') {
                  const updatedTenantList = tenantList?.filter(
                    (list: any) => list.propertyId === value,
                  );
                  setAutomatedTenantList(updatedTenantList);
                }
                if (automatedType === 'amenity') {
                  const updatedAmenityList = amenityList?.filter(
                    (list: any) => list.propertyId === value,
                  );
                  setAutomatedAmenityList(updatedAmenityList);
                }
              }}
            />
            {automatedType === 'tenant' && (
              <SelectDropdownComponent
                placeholder='Tenant'
                options={automatedTenantList}
                value={automatedTenantId}
                disabled={!automatedPropertyId}
                displayWith={(option: any) => option.name}
                valueExtractor={(option: any) => option._id}
                onUpdate={(value) => {
                  setAutomatedTenantId(value ? value : '');
                }}
              />
            )}
            {automatedType === 'amenity' && (
              <SelectDropdownComponent
                placeholder='Amenity'
                options={automatedAmenityList}
                value={automatedAmenityId}
                disabled={!automatedPropertyId}
                displayWith={(option: any) => option.name}
                valueExtractor={(option: any) => option._id}
                onUpdate={(value) => {
                  setAutomatedAmenityId(value ? value : '');
                }}
              />
            )}
          </div>
        </div>
        <div className='invoice-chart-wrapper'>
          <DonutChartComponent
            apiResponse={automatedInvoiceData}
            loading={automatedInvoiceStatsLoading}
            type='invoice'
          />
        </div>
        <div className='invoice-chart-legends'>
          <div className='invoice-chart-legend'>
            <div className='invoice-chart-dotone'></div>
            <div>To be Generated</div>
          </div>
          <div className='invoice-chart-legend'>
            <div className='invoice-chart-dottwo'></div>
            <div>Generated</div>
          </div>
        </div>
      </div>
      <div className='invoice-stats-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label={`Utility invoices for ${moment()
              .subtract(1, 'months')
              .format('MMMM')}`}
            isBold
            level={5}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Type'
              options={invoiceCategoryType}
              value={manualType}
              displayWith={(option: any) => option.title}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setManualType(value ? value : '');
                setManualEntityId('');
                setManualPropertyId('');
                setManualTenantId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={manualEntityId}
              disabled={!manualType}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setManualEntityId(value ? value : '');
                setManualPropertyId('');
                setManualTenantId('');
                const updatedPropertyList = propertyList?.filter(
                  (list: any) => list.billingEntityId === value,
                );
                setManualPropertyList(updatedPropertyList);
              }}
            />
            <SelectDropdownComponent
              placeholder='Property'
              options={manualPropertyList}
              value={manualPropertyId}
              disabled={!manualEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setManualPropertyId(value ? value : '');
                setManualTenantId('');
                setManualAmenityId('');
                if (manualType === 'tenant') {
                  const updatedTenantList = tenantList?.filter(
                    (list: any) => list.propertyId === value,
                  );
                  setManualTenantList(updatedTenantList);
                }
                if (manualType === 'amenity') {
                  const updatedAmenityList = amenityList?.filter(
                    (list: any) => list.propertyId === value,
                  );
                  setManualAmenityList(updatedAmenityList);
                }
              }}
            />
            {manualType === 'tenant' && (
              <SelectDropdownComponent
                placeholder='Tenant'
                options={manualTenantList}
                value={manualTenantId}
                disabled={!manualPropertyId}
                displayWith={(option: any) => option.name}
                valueExtractor={(option: any) => option._id}
                onUpdate={(value) => {
                  setManualTenantId(value ? value : '');
                }}
              />
            )}
            {manualType === 'amenity' && (
              <SelectDropdownComponent
                placeholder='Amenity'
                options={manualAmenityList}
                value={manualAmenityId}
                disabled={!manualPropertyId}
                displayWith={(option: any) => option.name}
                valueExtractor={(option: any) => option._id}
                onUpdate={(value) => {
                  setManualAmenityId(value ? value : '');
                }}
              />
            )}
          </div>
        </div>
        <div className='invoice-chart-wrapper'>
          <DonutChartComponent
            apiResponse={manualInvoiceData}
            loading={manualInvoiceStatsLoading}
            type='invoice'
          />
        </div>
        <div className='invoice-chart-legends'>
          <div className='invoice-chart-legend'>
            <div className='invoice-chart-dotone'></div>
            <div>To be generated</div>
          </div>
          <div className='invoice-chart-legend'>
            <div className='invoice-chart-dottwo'></div>
            <div>Generated</div>
          </div>
        </div>
      </div>
      <div className='invoice-not-generated-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label={`Invoice Not Generated For`}
            isBold
            level={5}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={notGeneratedEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value: any) => {
                setNotGeneratedEntityId(value ? value : '');
                setNotGeneratedType('');
                setInvoiceType('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Type'
              options={invoiceCategoryType}
              value={notGeneratedType}
              displayWith={(option: any) => option.title}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setNotGeneratedType(value ? value : '');
                setInvoiceType('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Invoice Type'
              options={invoiceCreationType}
              value={invoiceType}
              displayWith={(option: any) => option.title}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setInvoiceType(value ? value : '');
              }}
            />
          </div>
        </div>
        <div className='invoice-not-generated-cards'>
          {invoiceNotGeneratedListLoading ? (
            Array(5)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant='rectangular'
                  height={'480px'}
                  className='invoice-loading-card'
                />
              ))
          ) : (
            <>
              {invoiceNotGeneratedList?.map((item: any, index: number) => (
                <InvoiceNotGeneratedCardComponent
                  key={index}
                  data={item.list}
                  title={item.componentName}
                  type={notGeneratedType}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceStatsComponent;
