import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const getAnnouncementPaginatedList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ANNOUNCEMENT_PAGINATED_LIST.METHOD](APIConfig.ANNOUNCEMENT_PAGINATED_LIST.URL, payload);
}

const deleteAnnouncement = (announcement_id: string) => {
    // @ts-ignore
    return ApiService[APIConfig.ANNOUNCEMENT_DELETE.METHOD](APIConfig.ANNOUNCEMENT_DELETE.URL(announcement_id));
}

const markAnnouncementAsResolved = (announcement_id: string) => {
    // @ts-ignore
    return ApiService[APIConfig.MARK_ANNOUNCEMENT_RESOLVED.METHOD](APIConfig.MARK_ANNOUNCEMENT_RESOLVED.URL(announcement_id));
}

const getAnnouncementTypes = () => {
    // @ts-ignore
    return ApiService[APIConfig.GET_ANNOUNCEMENT_TYPES.METHOD](APIConfig.GET_ANNOUNCEMENT_TYPES.URL);
}

const getAnnouncement = (announcementId:string) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_ANNOUNCEMENT.METHOD](APIConfig.GET_ANNOUNCEMENT.URL(announcementId));
}

const addAnnouncement = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_ANNOUNCEMENT.METHOD](APIConfig.ADD_ANNOUNCEMENT.URL, payload);
}

const editAnnouncement = (announcementId:string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_ANNOUNCEMENT.METHOD](APIConfig.EDIT_ANNOUNCEMENT.URL(announcementId), payload);
}




const WallService = {
    getAnnouncementPaginatedList,
    markAnnouncementAsResolved,
    deleteAnnouncement,
    getAnnouncementTypes,
    addAnnouncement,
    editAnnouncement,
    getAnnouncement
}

export default WallService;
