import { IActionModel } from "../../shared/models/action.model";
import {
  SET_INVOICE_DATE_RANGE_IN_FILTER,
  SET_INVOICE_DATEFILTERON_IN_FILTER,
  SET_INVOICE_FILTER_COUNT,
} from "../actions/invoice.action";

export interface IInvoiceReducerState {
  dateRange: any;
  dateFilterOn: string;
  count: number;
}

const INITIAL_STATE: IInvoiceReducerState = {
  dateRange: [null, null],
  dateFilterOn: "",
  count: 0,
};

const invoiceReducer = (state = INITIAL_STATE, action: IActionModel) => {
  switch (action.type) {
    case SET_INVOICE_DATE_RANGE_IN_FILTER:
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case SET_INVOICE_DATEFILTERON_IN_FILTER:
      return {
        ...state,
        dateFilterOn: action.payload.dateFilterOn,
      };
    case SET_INVOICE_FILTER_COUNT:
      return {
        ...state,
        count: action.payload.count,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
