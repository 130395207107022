import './EntityDetailsComponent.scss';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import { CommonService } from '../../../shared/services';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import React, { useCallback, useEffect, useState } from 'react';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { ITableColumn } from '../../../shared/models/table.model';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import { IRootReducerState } from '../../../store/reducers';
import { getAllPropertyList } from '../../../store/actions/property.action';
import TableComponent from '../../../shared/components/table/TableComponent';

interface EntityDetailsComponentProps {}

const EntityManagerList: ITableColumn[] = [
  {
    title: 'Entity Admin',
    key: 'name',
    dataIndex: 'name',
  },
  { title: 'Entity User ID', key: 'userCode', dataIndex: 'userCode' },
  { title: 'Entity Email', key: 'email', dataIndex: 'email' },
  {
    title: 'EM Phone No.',
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    render: (item: any) => {
      return (
        <div>
          {item.phoneNumber ? (
            <>{CommonService.convertPhoneNumber(item?.phoneNumber)}</>
          ) : (
            <>--</>
          )}
        </div>
      );
    },
  },
  {
    title: 'Status',
    key: 'status',
    // align: 'center',
    render: (item: any) => {
      if (item?._id) {
        // return item.isActive ? <ChipComponent color={'success'} label={'Active'}/> :
        //     <ChipComponent color={'error'} label={'Inactive'}/>
        return (
          <TagComponent
            color={item?.isActive ? 'success' : 'danger'}
            showIndicator={true}
            label={item?.isActive ? 'Active' : 'Inactive'}
          />
        );
      }
    },
  },
];
// const propertiesList: ITableColumn[] = [
//     {
//         title: 'Property Name',
//         key: 'name',
//         dataIndex: 'name',
//     },
//     {
//         title: 'Property Code',
//         key: 'code',
//         dataIndex: 'code',
//     },
//     {
//         title: 'Status',
//         key: 'status',
//         // align: 'center',
//         render: (item: any) => {
//             if (item?._id) {
//                 // return item.isActive ? <ChipComponent color={'success'} label={'Active'}/> :
//                 //     <ChipComponent color={'error'} label={'Inactive'}/>
//                 return <TagComponent
//                     color={item?.isActive ? CommonService.getPropertyStatusTagColor('Checked In') : CommonService.getPropertyStatusTagColor('Checked Out')}
//                     showIndicator={true}
//                     label={item?.isActive ? 'Active' : 'Inactive'}/>
//             }
//         }
//     },
// ]

const EntityDetailsComponent = (props: EntityDetailsComponentProps) => {
  const { entityId } = useParams();

  const [entityDetails, setEntityDetails] = useState<any>(null);
  const [isEntityDetailsLoading, setIsEntityDetailsLoading] =
    useState<boolean>(false);
  const [isEntityDetailsLoadingFailed, setIsEntityDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isEntityDetailsLoaded, setIsEntityDetailsLoaded] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [entityFilterData] = useState({
    roleCode: 'em',
    billingEntityId: entityId,
  });
  const [entityProperties, setEntityProperties] = useState<any>([]);
  const { allPropertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );

  useEffect(() => {
    dispatch(getAllPropertyList());
  }, [dispatch]);

  useEffect(() => {
    if (entityId && allPropertyList) {
      const filterList = allPropertyList.filter(
        (property: any) => property.billingEntityId === entityId,
      );
      setEntityProperties(filterList);
    }
  }, [entityId, allPropertyList]);

  // const [propertiesFilterData] = useState({
  //     billingEntityId: entityId
  // })

  const getEntityDetails = useCallback(() => {
    setIsEntityDetailsLoading(true);
    const payload = {};
    if (entityId) {
      CommonService._entity
        .GetEntityAPICall(entityId, payload)
        .then((response: IAPIResponseType<any>) => {
          setEntityDetails(response.data);
          setIsEntityDetailsLoaded(true);
          setIsEntityDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsEntityDetailsLoaded(false);
          setIsEntityDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsEntityDetailsLoading(false);
        });
    }
  }, [entityId]);

  useEffect((): void => {
    getEntityDetails();
  }, [getEntityDetails]);

  useEffect(() => {
    dispatch(
      setCurrentNavParams('Entity Details', null, () => {
        navigate(CommonService._routeConfig.EntityRoute());
      }),
    );
  }, [dispatch, navigate]);

  const handleActivateInactivateEntity = useCallback(() => {
    if (entityId) {
      CommonService._entity
        .ToggleEntityServiceAPICall(entityId, {
          isActive: !entityDetails?.isActive,
        })
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          dispatch(getAllPropertyList());
          setEntityDetails((prevState: any) => {
            return {
              ...prevState,
              isActive: !prevState.isActive,
            };
          });
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error?.error, 'error');
        });
    }
  }, [entityId, entityDetails, dispatch]);

  const onEntityActivateDeactivate = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: `${
        entityDetails?.isActive ? 'Deactivate' : 'Activate'
      }  Entity`,
      image: entityDetails?.isActive
        ? ImageConfig.EntityDeactivateIcon
        : ImageConfig.EntityActivateIcon,
      confirmationDescription: `Are you sure you want to ${
        entityDetails?.isActive ? 'deactivate' : 'activate'
      }  the
 entity?`,
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: 'error',
        text: 'Confirm',
        variant: 'contained',
      },
    }).then((result: any) => {
      handleActivateInactivateEntity();
    });
  }, [handleActivateInactivateEntity, entityDetails]);

  const generateAddress = useCallback((address: any) => {
    let temp = '';
    temp = temp + address?.addressLine1;
    temp = temp + ', ' + address?.city;
    temp = temp + ', ' + address?.state;
    temp = temp + ', ' + address?.pincode;
    temp = temp + ', ' + address?.country;
    return temp;
  }, []);

  const handleShowPhoenixLogoToggle = useCallback(() => {
    const payload = {
      showPhoenixLogo: !entityDetails?.showPhoenixLogo,
    };
    if (entityId) {
      CommonService._entity
        .ToggleShowPhoenixLogoAPICall(entityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          setEntityDetails((prevState: any) => {
            return {
              ...prevState,
              showPhoenixLogo: !prevState.showPhoenixLogo,
            };
          });
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error?.error, 'error');
        });
    }
  }, [entityId, entityDetails]);

  const onShowPhoenixLogoToggle = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: `${
        entityDetails?.showPhoenixLogo ? 'Hide' : 'Show'
      } Phoenix Logo`,
      confirmationDescription: `Are you sure you want to ${
        entityDetails?.showPhoenixLogo ? 'hide' : 'show'
      } phoenix logo on invoices`,
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: entityDetails?.showPhoenixLogo ? 'error' : 'primary',
        text: 'Yes',
        variant: 'contained',
      },
    }).then((result: any) => {
      handleShowPhoenixLogoToggle();
    });
  }, [handleShowPhoenixLogoToggle, entityDetails]);

  const handleShowEntityLogoToggle = useCallback(() => {
    const payload = {
      showEntityLogo: !entityDetails?.showEntityLogo,
    };
    if (entityId) {
      CommonService._entity
        .ToggleShowEntityLogoAPICall(entityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          setEntityDetails((prevState: any) => {
            return {
              ...prevState,
              showEntityLogo: !prevState.showEntityLogo,
            };
          });
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error?.error, 'error');
        });
    }
  }, [entityId, entityDetails]);

  const onShowEntityLogoToggle = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: `${
        entityDetails?.showEntityLogo ? 'Hide' : 'Show'
      } Entity Logo`,
      confirmationDescription: `Are you sure you want to ${
        entityDetails?.showEntityLogo ? 'hide' : 'show'
      } entity logo on invoices`,
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: entityDetails?.showEntityLogo ? 'error' : 'primary',
        text: 'Yes',
        variant: 'contained',
      },
    }).then((result: any) => {
      handleShowEntityLogoToggle();
    });
  }, [entityDetails, handleShowEntityLogoToggle]);

  const handlePropertyLink = useCallback(
    (propertyId: any) => {
      navigate(CommonService._routeConfig.PropertyDetails(propertyId), {
        state: {
          entityId: entityId,
        },
      });
    },
    [navigate, entityId],
  );

  return (
    <div className={'entity-details-component'}>
      {isEntityDetailsLoading && (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      )}
      {isEntityDetailsLoadingFailed && (
        <div className='h-v-center'>
          <StatusCardComponent title={'Failed to Load'} />
        </div>
      )}
      {isEntityDetailsLoaded && (
        <div className='details-screen'>
          <CardComponent>
            <div className={'details-screen-header'}>
              <div className='details-screen-title-with-image-wrapper'>
                <div className={'details-screen-image'}>
                  <AvatarComponent
                    type='circle'
                    title={CommonService.getTwoLetterInitials(
                      entityDetails?.name,
                    )}
                    url={entityDetails?.profilePic?.url}
                    size={'xl'}
                  />
                </div>
                <div className={'details-screen-title-wrapper'}>
                  <div className={'details-screen-title'}>
                    {CommonService.capitalizeFirstLetterOfEachWord(
                      entityDetails?.name || '',
                    ) || 'N/A'}{' '}
                    ({entityDetails?.shortName})
                  </div>
                  <div className={'details-screen-description'}>
                    {generateAddress(entityDetails?.addressDetails)}
                  </div>
                </div>
              </div>
              <div className='details-screen-options'>
                <ButtonComponent
                  color={'error'}
                  onClick={onEntityActivateDeactivate}
                  variant={'outlined'}
                >
                  {entityDetails?.isActive
                    ? 'Deactivate Entity'
                    : 'Activate Entity'}
                </ButtonComponent>
                <LinkComponent
                  route={
                    CommonService._routeConfig.EditEntityRoute() +
                    `?entityId=${entityId}`
                  }
                >
                  <ButtonComponent color={'primary'}>
                    Edit Entity Details
                  </ButtonComponent>
                </LinkComponent>
              </div>
            </div>
            <HorizontalLineComponent className='mrg-top-20 mrg-bottom-20' />
            <FormControlLabelComponent
              label={'GST Details'}
              level={4}
              isBold={true}
              className='mrg-bottom-10'
            />
            <div className={'ts-row'}>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'GSTIN'}>
                  {entityDetails?.gstin || '-'}
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'PAN'}>
                  {entityDetails?.pan || '-'}
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'Registered Email ID'}>
                  {entityDetails?.email?.length > 20 ? (
                    <ToolTipComponent
                      tooltip={<div>{entityDetails?.email || '-'}</div>}
                      showArrow={true}
                      position={'top'}
                    >
                      <div className={'ellipses-for-details-data'}>
                        {entityDetails?.email || '-'}
                      </div>
                    </ToolTipComponent>
                  ) : (
                    <div>{entityDetails?.email || '-'}</div>
                  )}
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'Registered Phone Number'}>
                  {CommonService.convertPhoneNumber(
                    entityDetails?.phoneNumber,
                  ) || '-'}
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'Client Secret'}>
                  {entityDetails?.clientSecret || '-'}
                </DataLabelValueComponent>
              </div>
              <div className={'ts-col-lg-3'}>
                <DataLabelValueComponent label={'Client ID'}>
                  {entityDetails?.clientId || '-'}
                </DataLabelValueComponent>
              </div>
            </div>
            <HorizontalLineComponent className='mrg-top-10 mrg-bottom-10' />
            <div>
              <SwitchComponent
                checked={entityDetails?.showPhoenixLogo}
                onChange={onShowPhoenixLogoToggle}
                labelBold={true}
                label='Attach Phoenix logo on invoices'
              />
            </div>
            <HorizontalLineComponent className='mrg-top-10 mrg-bottom-10' />
            {entityDetails?.entityLogo && (
              <div>
                <SwitchComponent
                  checked={entityDetails?.showEntityLogo}
                  onChange={onShowEntityLogoToggle}
                  labelBold={true}
                  label='Attach Entity logo on invoices'
                />
                <CardComponent className='mrg-top-10'>
                  <img
                    className='image-wrapper'
                    src={entityDetails?.entityLogo?.url}
                    alt='entity_logo'
                  />
                </CardComponent>
              </div>
            )}

            {/* <div className='list-screen mrg-bottom-20 mrg-top-20'>
              <div className='list-screen-header-wrapper'>
                <FormControlLabelComponent
                  label={'Entity Admin Details'}
                  level={4}
                  isBold={true}
                />
              </div>
              <div>
                <TableComponent
                  noDataText={'No Admin details added yet'}
                  className={''}
                  autoHeight={true}
                  columns={[
                    {
                      title: 'Admin Name',
                      key: 'name',
                      dataIndex: 'name',
                      render: (item: any) => {
                        return <>{item?.name || '-'}</>;
                      },
                    },
                    {
                      title: 'User Code',
                      key: 'userCode',
                      dataIndex: 'userCode',
                      render: (item: any) => {
                        return <>{item?.userCode || '-'}</>;
                      },
                    },
                    {
                      title: 'Phone Number',
                      key: 'phoneNumber',
                      dataIndex: 'phoneNumber',
                      render: (item: any) => {
                        return <>{item?.phoneNumber || '-'}</>;
                      },
                    },
                    {
                      title: 'Email Id',
                      key: 'emailId',
                      dataIndex: 'emailId',
                      render: (item: any) => {
                        return <>{item?.email || '--'}</>;
                      },
                    },
                  ]}
                  data={
                    entityDetails?.adminDetails
                      ? entityDetails?.adminDetails
                      : []
                  }
                />
              </div>
            </div> */}

            <div className='list-screen'>
              <div className='list-screen-header-wrapper'>
                <FormControlLabelComponent
                  label={'Entity Admin'}
                  level={4}
                  isBold={true}
                />
              </div>
              <div>
                <TableWrapperComponent
                  url={APIConfig.USER_LIST_LITE.URL}
                  method={APIConfig.USER_LIST_LITE.METHOD}
                  isPaginated={false}
                  noDataText={
                    'No Entity Admin have been assigned to this Entity'
                  }
                  autoHeight={false}
                  extraPayload={entityFilterData}
                  columns={EntityManagerList}
                />
              </div>
            </div>
            <div className='list-screen mrg-bottom-20 mrg-top-20'>
              <div className='list-screen-header-wrapper'>
                <FormControlLabelComponent
                  label={'Email Id(s) to be  CC’d to.'}
                  level={4}
                  isBold={true}
                />
              </div>
              <div>
                <TableComponent
                  noDataText={"No Email's are added to CC "}
                  className={''}
                  autoHeight={true}
                  columns={[
                    {
                      title: 'Email Id',
                      key: 'emailId',
                      dataIndex: 'emailId',
                      render: (item: any) => {
                        return <>{item?.email || '--'}</>;
                      },
                    },
                  ]}
                  data={
                    entityDetails?.emailCCList ? entityDetails?.emailCCList : []
                  }
                />
              </div>
            </div>
            <div className='mrg-top-20'>
              <FormControlLabelComponent
                label='Properties'
                isBold
                level={3}
                className='mrg-bottom-20'
              />
              <TableComponent
                columns={[
                  {
                    title: 'Property Names',
                    key: 'propertyName',
                    dataIndex: 'propertyName',
                    render: (item: any, rowIndex: number) => {
                      return (
                        <div
                          className='property-link'
                          onClick={() => handlePropertyLink(item?._id)}
                        >
                          <div className='d-flex'>
                            <TagComponent
                              color={
                                item?.isActive
                                  ? CommonService.getPropertyStatusTagColor(
                                      'Checked In',
                                    )
                                  : CommonService.getPropertyStatusTagColor(
                                      'Checked Out',
                                    )
                              }
                              showIndicator={true}
                              label={item?.isActive ? 'Active' : 'Inactive'}
                            />
                            <div className='mrg-left-10 mrg-top-2'>
                              {item?.name}
                            </div>
                          </div>
                          <div>
                            <ImageConfig.RightArrow />
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
                data={entityProperties}
                autoHeight={true}
                noDataText='No properties in this Entity'
              />
            </div>
            {/*<div className="list-screen">*/}
            {/*    <div className="list-screen-header-wrapper">*/}
            {/*        <FormControlLabelComponent label={'Properties'} level={4}*/}
            {/*                                   isBold={true}/>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <TableWrapperComponent url={APIConfig.USER_LIST_LITE.URL}*/}
            {/*                               method={APIConfig.USER_LIST_LITE.METHOD}*/}
            {/*                               isPaginated={false}*/}
            {/*                               noDataText={'No properties found.'}*/}
            {/*                               autoHeight={true}*/}
            {/*                               extraPayload={propertiesFilterData}*/}
            {/*                               columns={propertiesList}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </CardComponent>
        </div>
      )}
    </div>
  );
};

export default EntityDetailsComponent;
