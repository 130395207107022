import './TenantViewScreen.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CommonService } from '../../../shared/services';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import moment from 'moment';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import { ImageConfig, Misc } from '../../../constants';
import TableComponent from '../../../shared/components/table/TableComponent';
import AccordionComponent from '../../../shared/components/accordion/AccordionComponent';
import { ITableColumn } from '../../../shared/models/table.model';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import PlainAccordionComponent from '../../../shared/components/plain-accordion/PlainAccordionComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';

interface TenantViewScreenProps {}

const MODULE = 'Tenants';

const buttonGroupSteps = [
  {
    title: 'Basic Details',
    id: 'basic-details',
  },
  {
    title: 'Invoices',
    id: 'invoices',
  },
];

const TenantViewScreen = (props: TenantViewScreenProps) => {
  const { tenantId } = useParams();
  const [tenantDetails, setTenantDetails] = useState<any>(null);
  const [isEventDetailsLoading, setIsEventDetailsLoading] =
    useState<boolean>(false);
  const [isEventDetailsLoadingFailed, setIsEventDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isEventDetailsLoaded, setIsEventDetailsLoaded] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<'basic-details' | 'invoices'>(
    'basic-details',
  );
  const [isInvoiceListLoading, setIsInvoiceListLoading] =
    useState<boolean>(false);
  const [isInvoiceListLoaded, setIsInvoiceListLoaded] =
    useState<boolean>(false);
  const [isInvoiceListLoadingFailed, setIsInvoiceListLoadingFailed] =
    useState<boolean>(false);
  const [invoiceList, setInvoiceList] = useState<any>(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addressDetails, setAddressDetails] = useState<any>(undefined);
  const updateSteps = useCallback(
    (currentStep: string) => {
      searchParams.set('tab', currentStep);
      setSearchParams(searchParams);
      // @ts-ignore
      setCurrentTab(currentStep);
    },
    [searchParams, setSearchParams],
  );
  const [selectedTowerCategories, setSelectedTowerCategories] = useState<any>(
    {},
  );
  const [selectedRentTable, setSelectedRentTable] = useState<any>({});
  const [selectedCamTable, setSelectedCamTable] = useState<any>({});
  const [selectedCampusCamTable, setSelectedCampusCamTable] = useState<any>({});

  const getTenantDetails = useCallback(() => {
    setIsEventDetailsLoading(true);
    const payload = {};
    if (tenantId) {
      CommonService._tenants
        .GetTenantsDetailsAPICall(tenantId, payload)
        .then((response: IAPIResponseType<any>) => {
          setTenantDetails(response.data);
          const placeOfService = response?.data?.placeOfService;
          const billingAddress = response?.data?.billingAddress;

          const addressDetails = [
            {
              addressType: 'Place Of Service',
              address:
                placeOfService?.addressLine1 +
                ', ' +
                placeOfService?.city +
                ', ' +
                placeOfService?.state +
                ', ' +
                placeOfService?.pincode,
            },
            {
              addressType: 'Billing Address',
              address:
                billingAddress?.addressLine1 +
                ', ' +
                billingAddress?.city +
                ', ' +
                billingAddress?.state +
                ', ' +
                billingAddress?.pincode,
            },
          ];
          setAddressDetails(addressDetails);
          setIsEventDetailsLoaded(true);
          setIsEventDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsEventDetailsLoaded(false);
          setIsEventDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsEventDetailsLoading(false);
        });
    }
  }, [tenantId]);

  useEffect((): void => {
    getTenantDetails();
  }, [getTenantDetails]);

  const getInvoicesList = useCallback(() => {
    let payload: any = {
      tenantIds: [tenantId],
      filter: {
        status: 'draft',
      },
      page: 1,
    };
    setIsInvoiceListLoading(true);
    CommonService._invoice
      .getHistoryInvoiceAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setInvoiceList(response?.data?.docs);
        setIsInvoiceListLoaded(true);
        setIsInvoiceListLoading(false);
      })
      .catch((error: any) => {
        setIsInvoiceListLoadingFailed(true);
        setIsInvoiceListLoaded(false);
        CommonService._alert.showToast(error, 'error');
      })
      .finally(() => {
        setIsInvoiceListLoadingFailed(false);
        setIsInvoiceListLoading(false);
      });
  }, [tenantId]);

  useEffect(() => {
    if (currentTab === 'invoices') {
      getInvoicesList();
    }
  }, [getInvoicesList, currentTab]);

  const toggleTenant = useCallback(
    (item: any) => {
      CommonService._tenants
        .toggleTenantAPICall(item._id)
        .then((response) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          CommonService._communications.TableWrapperRefreshSubject.next({
            moduleName: MODULE,
          });
          getTenantDetails();
        })
        .catch((error) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        });
    },
    [getTenantDetails],
  );

  const onTenantActivateDeactivate = useCallback(
    (item: any) => {
      CommonService.onConfirm({
        confirmationTitle: `${
          item?.isActive ? 'Deactivate' : 'Activate'
        }  Tenant`,
        image: item?.isActive
          ? ImageConfig.CancelIcon
          : ImageConfig.ApproveRequest,
        confirmationDescription: `Are you sure you want to ${
          item?.isActive ? 'deactivate' : 'activate'
        }  the selected tenant?`,
        no: {
          color: 'default',
          text: 'Cancel',
          variant: 'outlined',
        },
        yes: {
          color: 'error',
          text: 'Confirm',
          variant: 'contained',
        },
      }).then((result: any) => {
        toggleTenant(item);
      });
    },
    [toggleTenant],
  );

  useEffect(() => {
    dispatch(
      setCurrentNavParams('Tenant Details', null, () => {
        navigate(CommonService._routeConfig.TenantsRoute());
      }),
    );
  }, [dispatch, navigate]);

  const TowerTab = useCallback(
    (tower: any, type?: string) => [
      {
        title: 'Office Floors',
        id: 'officeFloors',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.floorBreakdown?.length
              : tower?.officeFloors?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
      {
        title: 'Basements',
        id: 'basements',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.basementBreakdown?.length
              : tower?.basements?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
      {
        title: 'Hard Option',
        id: 'hardOptions',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.hardOptionBreakdown?.length
              : tower?.hardOptions?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
    ],
    [],
  );

  const TowerColumns = useMemo(
    () =>
      (selectedTowerCategory: any): ITableColumn[] =>
        [
          {
            title:
              selectedTowerCategory === 'officeFloors'
                ? 'Floor'
                : selectedTowerCategory === 'basements'
                ? 'Basement'
                : selectedTowerCategory === 'hardOptions'
                ? 'Hard Option'
                : '',
            key: 'floorNumber',
            dataIndex: 'floorNumber',
            width: 205,
            render: (item: any) => {
              return <>{item?.title}</>;
            },
          },
          {
            title: 'Leased Area',
            key: 'occupiedArea',
            dataIndex: 'occupiedArea',
            render: (item: any) => {
              return <>{item?.occupiedArea || 0} sqft</>;
            },
          },
        ],
    [],
  );

  const InvoiceListTableColumn: ITableColumn[] = [
    {
      title: 'Invoice No',
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.gstData?.DocDtls?.No ? (
              <div>{item?.gstData?.DocDtls?.No}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Invoice Generated On',
      key: 'invoiceGeneratedOn',
      dataIndex: 'invoiceGeneratedOn',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.generatedDate ? (
              <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Invoice Due Date',
      key: 'invoiceDueDate',
      dataIndex: 'invoiceDueDate',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.invoiceDueDate ? (
              <>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
    },

    {
      title: 'Total Amount',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.totalAmount ? <div>{item?.totalAmount}</div> : <div>-</div>}
          </div>
        );
      },
    },
    {
      title: 'Invoice Paid On',
      key: 'invoicePaidOn',
      dataIndex: 'invoicePaidOn',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.invoicePaidDate ? (
              <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.paymentStatus ? (
              <TagComponent
                customBg={
                  item?.status === 'cancelled'
                    ? '#FBE5E5'
                    : item?.paymentStatus === 'paid'
                    ? '#D7FFD3'
                    : item?.paymentStatus === 'pending'
                    ? '#FBFAE5'
                    : ''
                }
                customTextColor={'#636363'}
                showIndicator={true}
                label={
                  item?.status === 'cancelled'
                    ? 'Cancelled'
                    : item?.paymentStatus
                }
              />
            ) : (
              <>--</>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 60,
      render: (item: any) => {
        if (item?._id) {
          return (
            <div className='invoice-list-action-tablecolumn'>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(
                    CommonService._routeConfig.InvoiceDetailsRoute(item?._id),
                    {
                      state: {
                        cameFrom: 'tenant',
                      },
                    },
                  );
                }}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </div>
            </div>
          );
        }
      },
    },
  ];

  const TowerTableColumn = useMemo(
    () =>
      (selectedTable: any): ITableColumn[] =>
        [
          {
            title:
              selectedTable === 'officeFloors'
                ? 'Floor'
                : selectedTable === 'basements'
                ? 'Basement'
                : selectedTable === 'hardOptions' && 'Hard Option',
            key: 'number',
            dataIndex: 'number',
            width: 100,
            render: (item: any) => {
              return <div>{item.title}</div>;
            },
          },
          {
            title: 'Area Occupied',
            key: 'areaOccupied',
            dataIndex: 'areaOccupied',
            width: 150,
            render: (item: any) => {
              return <div>{item.occupiedArea + ' sft' || '--'}</div>;
            },
          },
          {
            title: 'Previous Value',
            key: 'previousValue',
            dataIndex: 'previousValue',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item?.previousValue ? (
                    <>{'Rs. ' + item.previousValue + ' /-' || '0'}</>
                  ) : (
                    '0'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Escalation Percentage',
            key: 'percentage',
            dataIndex: 'percentage',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.rateOfEscalation ? (
                    <>{item.rateOfEscalation + '%'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Current Value',
            key: 'ratePerSft',
            dataIndex: 'ratePerSft',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.ratePerSft ? (
                    <>{'Rs ' + item.ratePerSft + ' /-' || '--'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          // {
          //   title: 'Current Value',
          //   key: 'currentValue',
          //   dataIndex: 'currentValue',
          //   width: 120,
          //   render: (item: any) => {
          //     return <div>{'Rs. ' + item.currentValue + ' /-' || '0'}</div>;
          //   },
          // },
          {
            title: 'Escalation',
            key: 'escalation',
            dataIndex: 'escalation',
            width: 120,
            render: (item: any) => {
              return (
                <div>
                  {item.escalationStatus === 'applied' && (
                    <TagComponent
                      className={'mrg-top-10'}
                      label={item.escalationStatus}
                      showIndicator
                      customBg='#E6F3E9'
                      customTextColor='#45C962'
                    />
                  )}
                  {item.escalationStatus === 'upcoming' && (
                    <TagComponent
                      className={'mrg-top-10'}
                      label={item.escalationStatus}
                      showIndicator
                      customBg='#FBF1E5'
                      customTextColor='#FF5630'
                    />
                  )}
                </div>
              );
            },
          },
          {
            title: 'Escalation Applied On',
            key: 'escalationAppliedOn',
            dataIndex: 'escalationAppliedOn',
            width: 150,
            align: 'center',
            render: (item: any) => {
              return (
                <div>
                  {item.lastEscalationDate ? (
                    <>{moment(item.lastEscalationDate).format('DD-MM-YYYY')}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Next Escalation On',
            key: 'nextEscalationOn',
            dataIndex: 'nextEscalationOn',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.nextEscalationDate ? (
                    <>{moment(item.nextEscalationDate).format('DD-MM-YYYY')}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Updated Value',
            key: 'updatedValue',
            dataIndex: 'updatedValue',
            width: 120,
            render: (item: any) => {
              return (
                <div>
                  {item.updatedValue ? (
                    <>{'Rs. ' + item.updatedValue + ' /-' || '0'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
        ],
    [],
  );

  return (
    <div className={'event-view-screen '}>
      {isEventDetailsLoading && (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      )}
      {isEventDetailsLoadingFailed && (
        <div className='h-v-center'>
          <StatusCardComponent title={'Failed to Load'} />
        </div>
      )}

      {isEventDetailsLoaded && (
        <div className='details-screen'>
          <CardComponent>
            <div className={'details-screen-header header-component-padding'}>
              <div className='details-screen-title-with-image-wrapper'>
                <div className={'details-screen-image'}>
                  <AvatarComponent
                    type='circle'
                    url={tenantDetails?.profilePic?.url}
                    title={CommonService.getTwoLetterInitials(
                      tenantDetails?.name,
                    )}
                    size={'xl'}
                  />
                </div>
                <div className={'details-screen-title-wrapper'}>
                  <div className={'details-screen-title'}>
                    {CommonService.capitalizeFirstLetterOfEachWord(
                      tenantDetails?.name || '',
                    ) || 'N/A'}
                  </div>
                  <div className={'details-screen-description'}>
                    {tenantDetails.propertyDetails?.name}
                  </div>
                </div>
              </div>
              <div className='details-screen-options'>
                {/*<div className={'ts-align-items-center bordered'}>*/}
                <SwitchComponent
                  color={'primary'}
                  onChange={onTenantActivateDeactivate.bind(
                    null,
                    tenantDetails,
                  )}
                  checked={tenantDetails?.isActive}
                  label={
                    !tenantDetails?.isActive
                      ? 'Activate Tenant'
                      : 'Deactivate Tenant'
                  }
                />
                {tenantDetails.website && (
                  <ButtonComponent
                    color={'primary'}
                    onClick={(event) => {
                      window.open(tenantDetails.website, '_blank');
                    }}
                    variant={'outlined'}
                  >
                    View Website
                  </ButtonComponent>
                )}
                {tenantId && (
                  <LinkComponent
                    route={CommonService._routeConfig.TenantEditRoute(tenantId)}
                  >
                    <ButtonComponent color={'primary'}>
                      Edit Tenant Details
                    </ButtonComponent>
                  </LinkComponent>
                )}
              </div>
            </div>
            <br />
            <HorizontalLineComponent className='pdd-top-20' />
            <br />

            <div className='pdd-bottom-20 tenant-button-group-wrapper'>
              <ButtonGroupComponent
                height={40}
                selected={currentTab}
                onChange={(value: any) => {
                  updateSteps(value);
                }}
                buttons={buttonGroupSteps}
              />
            </div>

            {currentTab === 'basic-details' && (
              <div>
                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Tower Details'}
                    isBold={true}
                    className={'mrg-left-20'}
                    level={4}
                  />
                  {tenantDetails?.towers?.towerBreakdown ? (
                    <div className='towers-list-wrapper'>
                      {tenantDetails?.towers?.towerBreakdown?.map(
                        (item: any, index: number) => {
                          const selectedTowerCategory =
                            selectedTowerCategories[item.name] ||
                            'officeFloors';
                          return (
                            <div
                              key={index}
                              className='pdd-left-25 pdd-right-25'
                            >
                              <AccordionComponent key={index} title={item.name}>
                                {item?.isTowerSelected &&
                                  (!item?.isTotalTowerOccupied ? (
                                    <div className='flex-col-wrapper'>
                                      <div>
                                        <ButtonGroupComponent
                                          buttons={TowerTab(
                                            item,
                                            'towerDetails',
                                          )}
                                          selected={selectedTowerCategory}
                                          onChange={(value: any) => {
                                            setSelectedTowerCategories(
                                              (prevState: any) => ({
                                                ...prevState,
                                                [item.name]: value,
                                              }),
                                            );
                                          }}
                                        />
                                      </div>
                                      <TableComponent
                                        data={
                                          selectedTowerCategory ===
                                          'officeFloors'
                                            ? item?.floorBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : selectedTowerCategory ===
                                              'basements'
                                            ? item?.basementBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : selectedTowerCategory ===
                                              'hardOptions'
                                            ? item?.hardOptionBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : []
                                        }
                                        noDataText={
                                          selectedTowerCategory ===
                                          'officeFloors'
                                            ? 'No floors are added to this tower'
                                            : selectedTowerCategory ===
                                              'basements'
                                            ? 'No basements are added to this tower'
                                            : selectedTowerCategory ===
                                              'hardOptions'
                                            ? 'No hard options are added to this tower'
                                            : 'No data'
                                        }
                                        // columns={TowerColumns}
                                        columns={TowerColumns(
                                          selectedTowerCategory,
                                        )}
                                        className={'tower-table-wrapper'}
                                      />
                                    </div>
                                  ) : (
                                    <div>Occupied full tower</div>
                                  ))}
                              </AccordionComponent>
                            </div>
                          );
                        },
                      )}
                    </div>
                  ) : (
                    <StatusCardComponent title='No Tower details present' />
                  )}
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Preferences'}
                    isBold={true}
                    level={4}
                    className={'mrg-left-20'}
                  />
                  <div
                    className={'ts-row row-wrapper-elements-style mrg-left-10'}
                  >
                    <div className={'ts-col-lg-4'}>
                      <DataLabelValueComponent
                        label={'Which Sign up flow does the tenant prefer?'}
                      >
                        {tenantDetails?.signupFlow || '-'}
                      </DataLabelValueComponent>
                    </div>
                    {tenantDetails?.domain && (
                      <div className={'ts-col-lg-4'}>
                        <DataLabelValueComponent label={'Domain'}>
                          {tenantDetails?.domain || '-'}
                        </DataLabelValueComponent>
                      </div>
                    )}
                    <div className={'ts-col-lg-4'}>
                      {/*<DataLabelValueComponent label={'Can an employee raise visitor tickets?'}>*/}
                      {/*    {tenantDetails?.enableVms ? 'Yes' : 'No' || '-'}*/}
                      {/*</DataLabelValueComponent>*/}
                    </div>
                  </div>
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Address Details'}
                    isBold={true}
                    level={4}
                    className={'mrg-left-20'}
                  />
                  <TableComponent
                    noDataText={'No Admin details added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Address Type',
                        key: 'addressType',
                        dataIndex: 'addressType',
                        width: '40',
                        render: (item: any) => {
                          return <>{item?.addressType || '-'}</>;
                        },
                      },
                      {
                        title: 'Address',
                        key: 'address',
                        dataIndex: 'address',
                        width: '300',
                        render: (item: any) => {
                          return <>{item?.address || '-'}</>;
                        },
                      },
                    ]}
                    data={addressDetails}
                  />
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Admin Details'}
                    isBold={true}
                    className={'mrg-left-20'}
                    level={4}
                  />
                  <TableComponent
                    noDataText={'No Admin details added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Admin Name',
                        key: 'name',
                        dataIndex: 'name',
                        width: 100,
                        render: (item: any) => {
                          return (
                            <div className='text-transform-capitalize'>
                              {item?.name || '-'}
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Phone Number',
                        key: 'phoneNumber',
                        dataIndex: 'phoneNumber',
                        width: 50,
                        render: (item: any) => {
                          return (
                            <>
                              {CommonService.convertPhoneNumber(
                                item?.phoneNumber,
                              ) || '-'}
                            </>
                          );
                        },
                      },
                      {
                        title: 'Email Type',
                        key: 'emailType',
                        dataIndex: 'emailType',
                        width: 20,
                        render: (item: any) => {
                          return (
                            <div className='text-transform-capitalize'>
                              {item?.emailType || '--'}
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Designation',
                        key: 'designation',
                        dataIndex: 'designation',
                        width: 60,
                        render: (item: any) => {
                          return (
                            <div className='text-transform-capitalize'>
                              {item?.designation || '--'}
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Email Id',
                        key: 'emailId',
                        dataIndex: 'emailId',
                        render: (item: any) => {
                          return <>{item?.email || '--'}</>;
                        },
                      },
                    ]}
                    data={
                      tenantDetails?.adminDetails
                        ? tenantDetails?.adminDetails
                        : []
                    }
                  />
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Timings'}
                    isBold={true}
                    className={'mrg-left-20'}
                    level={4}
                  />
                  <TableComponent
                    noDataText={'No Timings added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Day',
                        key: 'day',
                        dataIndex: 'day',
                      },
                      {
                        title: 'Timings',
                        key: 'timings',
                        width: '80%',
                        dataIndex: 'timings',
                        render: (item: any) => {
                          return (
                            <>
                              {moment(item.from, 'hh:mm').format('hh:mm A') +
                                ' - ' +
                                moment(item.to, 'hh:mm').format('hh:mm A')}
                            </>
                          );
                        },
                      },
                      {
                        title: '',
                        key: 'actions',
                        dataIndex: 'actions',
                      },
                    ]}
                    data={
                      tenantDetails.isSameEveryDay
                        ? [
                            {
                              day: 'All Days',
                              from: tenantDetails?.timings.from,
                              to: tenantDetails?.timings.to,
                            },
                          ]
                        : tenantDetails?.timings || []
                    }
                  />
                </CardComponent>

                <div>
                  <FormControlLabelComponent
                    label='Billing Details'
                    isBold
                    level={5}
                  />
                  <div className={'ts-row mrg-top-20'}>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'GST Number'}>
                        {tenantDetails?.billingPreferences?.gstNumber || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Pan Number'}>
                        {tenantDetails?.billingPreferences?.panNumber || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Rent Commencement Date'}>
                        {tenantDetails?.billingPreferences
                          ?.rentCommencementDate || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent
                        label={'Lease Commencement Date'}
                      >
                        {tenantDetails?.billingPreferences
                          ?.leaseCommencementDate || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                  </div>
                  <HorizontalLineComponent className='mrg-bottom-20' />
                </div>

                <div className='tenant-billing-preferences'>
                  <FormControlLabelComponent
                    label={'Billing Preferences'}
                    isBold={true}
                    level={5}
                  />
                  <LinkComponent
                    route={CommonService._routeConfig.editBillingPreferenceRoute(
                      tenantId,
                    )}
                  >
                    <ButtonComponent
                      disabled={!tenantDetails?.billingPreferences}
                    >
                      Edit Billing Preferences
                    </ButtonComponent>
                  </LinkComponent>
                </div>

                <PlainAccordionComponent
                  title={`Rent`}
                  isExpand={true}
                  isAccordionOpen={true}
                >
                  <div>
                    <div className='list-screen-header-wrapper'>
                      {!tenantDetails?.billingPreferences?.isRentSelected ? (
                        <div className='no-data-found'>No Rent data found</div>
                      ) : (
                        <>
                          {tenantDetails?.billingPreferences?.billingComponents?.rent?.towers?.map(
                            (tower: any, index: number) => {
                              const selectedTable =
                                selectedRentTable[tower?.towername] ||
                                'officeFloors';
                              const tableButtons = TowerTab(tower);
                              return (
                                <AccordionComponent
                                  key={tower?.towerId}
                                  title={tower?.towername || 'N/A'}
                                >
                                  <ButtonGroupComponent
                                    buttons={tableButtons}
                                    selected={selectedTable}
                                    onChange={(value: any) =>
                                      setSelectedRentTable(
                                        (prevState: any) => ({
                                          ...prevState,
                                          [tower.towername]: value,
                                        }),
                                      )
                                    }
                                  />
                                  <div className='mrg-top-20'>
                                    <TableComponent
                                      data={
                                        selectedTable === 'officeFloors'
                                          ? tower?.officeFloors?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'basements'
                                          ? tower?.basements?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'hardOptions'
                                          ? tower?.hardOptions?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : []
                                      }
                                      columns={TowerTableColumn(selectedTable)}
                                      noDataText={
                                        selectedTable === 'officeFloors'
                                          ? 'No floors are added to this tower'
                                          : selectedTable === 'basements'
                                          ? 'No basements are added to this tower'
                                          : selectedTable === 'hardOptions'
                                          ? 'No hard options are added to this tower'
                                          : 'No data'
                                      }
                                      className={'tower-table-wrapper'}
                                    />
                                  </div>
                                </AccordionComponent>
                              );
                            },
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </PlainAccordionComponent>

                <PlainAccordionComponent
                  title={`Building CAM`}
                  isExpand={true}
                  isAccordionOpen={false}
                >
                  <div>
                    <div className='list-screen-header-wrapper'>
                      {!tenantDetails?.billingPreferences?.isCamSelected ? (
                        <div className='no-data-found'>
                          No Building CAM data found
                        </div>
                      ) : (
                        <>
                          {tenantDetails?.billingPreferences?.billingComponents?.cam?.towers?.map(
                            (tower: any, index: number) => {
                              const selectedTable =
                                selectedCamTable[tower?.towername] ||
                                'officeFloors';
                              const tableButtons = TowerTab(tower);
                              return (
                                <AccordionComponent
                                  key={tower?.towerId}
                                  title={tower?.towername || 'N/A'}
                                >
                                  <ButtonGroupComponent
                                    buttons={tableButtons}
                                    selected={selectedTable}
                                    onChange={(value: any) =>
                                      setSelectedCamTable((prevState: any) => ({
                                        ...prevState,
                                        [tower.towername]: value,
                                      }))
                                    }
                                  />
                                  <div className='mrg-top-20'>
                                    <TableComponent
                                      data={
                                        selectedTable === 'officeFloors'
                                          ? tower?.officeFloors.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'basements'
                                          ? tower?.basements?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'hardOptions'
                                          ? tower?.hardOptions?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : []
                                      }
                                      columns={TowerTableColumn(selectedTable)}
                                      noDataText={
                                        selectedTable === 'officeFloors'
                                          ? 'No floors are added to this tower'
                                          : selectedTable === 'basements'
                                          ? 'No basements are added to this tower'
                                          : selectedTable === 'hardOptions'
                                          ? 'No hard options are added to this tower'
                                          : 'No data'
                                      }
                                      className={'tower-table-wrapper'}
                                    />
                                  </div>
                                </AccordionComponent>
                              );
                            },
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </PlainAccordionComponent>

                <PlainAccordionComponent
                  title={`Campus CAM`}
                  isExpand={true}
                  isAccordionOpen={false}
                >
                  <div>
                    <div className='list-screen-header-wrapper'>
                      {!tenantDetails?.billingPreferences
                        ?.isCampusCamSelected ? (
                        <div className='no-data-found'>
                          No Campus CAM data found
                        </div>
                      ) : (
                        <>
                          {tenantDetails?.billingPreferences?.billingComponents?.campusCam?.towers?.map(
                            (tower: any, index: number) => {
                              const selectedTable =
                                selectedCampusCamTable[tower?.towername] ||
                                'officeFloors';
                              const tableButtons = TowerTab(tower);
                              return (
                                <AccordionComponent
                                  key={tower?.towerId}
                                  title={tower?.towername || 'N/A'}
                                >
                                  <ButtonGroupComponent
                                    buttons={tableButtons}
                                    selected={selectedTable}
                                    onChange={(value: any) =>
                                      setSelectedCampusCamTable(
                                        (prevState: any) => ({
                                          ...prevState,
                                          [tower.towername]: value,
                                        }),
                                      )
                                    }
                                  />
                                  <div className='mrg-top-20'>
                                    <TableComponent
                                      data={
                                        selectedTable === 'officeFloors'
                                          ? tower?.officeFloors.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'basements'
                                          ? tower?.basements?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : selectedTable === 'hardOptions'
                                          ? tower?.hardOptions?.filter(
                                              (item: any) =>
                                                item?.isSelected === true,
                                            )
                                          : []
                                      }
                                      columns={TowerTableColumn(selectedTable)}
                                      noDataText={
                                        selectedTable === 'officeFloors'
                                          ? 'No floors are added to this tower'
                                          : selectedTable === 'basements'
                                          ? 'No basements are added to this tower'
                                          : selectedTable === 'hardOptions'
                                          ? 'No hard options are added to this tower'
                                          : 'No data'
                                      }
                                      className={'tower-table-wrapper'}
                                    />
                                  </div>
                                </AccordionComponent>
                              );
                            },
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </PlainAccordionComponent>

                <PlainAccordionComponent
                  title={'Parking'}
                  isExpand={true}
                  isAccordionOpen={false}
                >
                  {tenantDetails?.billingPreferences?.isParkingSelected ? (
                    <div>
                      <div className={'ts-row'}>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent
                            label={'Total Parking Slots'}
                          >
                            {tenantDetails?.billingPreferences
                              ?.billingComponents?.parking?.numberOfSlots ||
                              '0'}
                          </DataLabelValueComponent>
                        </div>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent label={'Price per slot'}>
                            Rs.
                            {tenantDetails?.billingPreferences
                              ?.billingComponents?.parking?.perSlotPrice ||
                              '0'}{' '}
                            /-
                          </DataLabelValueComponent>
                        </div>
                        <div className={'ts-col'} />
                      </div>
                      {tenantDetails?.billingPreferences?.billingComponents
                        ?.parking?.enableAdditionalParkingSlots && (
                        <>
                          <FormControlLabelComponent
                            label={'Additional Slots'}
                            isBold={true}
                            level={6}
                          />
                          <TableComponent
                            noDataText={'No Additional Slots'}
                            className={'mrg-top-15'}
                            autoHeight={true}
                            columns={[
                              {
                                title: 'Parking Slots',
                                key: 'additional-slot',
                                dataIndex: 'additional-slot',
                                render: (item: any) => {
                                  return <>{item?.numberOfSlots || '-'}</>;
                                },
                              },
                              {
                                title: 'Price Per Slot',
                                key: 'pricePerSlot',
                                dataIndex: 'pricePerSlot',
                                render: (item: any) => {
                                  return <>{item?.perSlotPrice || '-'}</>;
                                },
                              },
                              {
                                title: 'Invoice Start Date',
                                key: 'invoiceStartDate',
                                dataIndex: 'invoiceStartDate',
                                render: (item: any) => {
                                  return (
                                    <>
                                      {moment(item.invoiceStartDate).format(
                                        'DD-MM-YYYY',
                                      ) || '--'}
                                    </>
                                  );
                                },
                              },
                            ]}
                            data={
                              tenantDetails?.billingPreferences
                                ?.billingComponents?.parking
                                ?.additionalParkingSlots
                            }
                          />
                        </>
                      )}
                    </div>
                  ) : (
                    <div className='no-data-found'>No Parking Selected</div>
                  )}
                </PlainAccordionComponent>

                <PlainAccordionComponent
                  title={'Signage'}
                  isExpand={true}
                  isAccordionOpen={false}
                >
                  <>
                    {tenantDetails?.billingPreferences?.isSignageSelected ? (
                      <div className={'ts-row'}>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent label={'Price'}>
                            Rs.
                            {tenantDetails?.billingPreferences
                              ?.billingComponents?.signageAmount || '0'}{' '}
                            /-
                          </DataLabelValueComponent>
                        </div>
                        <div className={'ts-col'} />
                      </div>
                    ) : (
                      <div className='no-data-found'>No Signage Selected</div>
                    )}
                  </>
                </PlainAccordionComponent>

                <PlainAccordionComponent
                  title={'Security Deposit'}
                  isExpand={true}
                  isAccordionOpen={false}
                >
                  <>
                    {tenantDetails?.billingPreferences
                      ?.isSecurityDepositSelected ? (
                      <div className={'ts-row'}>
                        <div className={'ts-col-lg-3'}>
                          <DataLabelValueComponent label={'Price'}>
                            Rs.
                            {tenantDetails?.billingPreferences
                              ?.billingComponents?.securityDeposit || '0'}{' '}
                            /-
                          </DataLabelValueComponent>
                        </div>
                        <div className={'ts-col'} />
                      </div>
                    ) : (
                      <div className='no-data-found'>
                        No Security Deposit Selected
                      </div>
                    )}
                  </>
                </PlainAccordionComponent>
              </div>
            )}

            {currentTab === 'invoices' && (
              <>
                {isInvoiceListLoading && (
                  <div className='h-v-center'>
                    <LoaderComponent type={'spinner'} />
                  </div>
                )}
                {isInvoiceListLoadingFailed && (
                  <div className='h-v-center'>
                    <StatusCardComponent title={'Failed to Load'} />
                  </div>
                )}
                {isInvoiceListLoaded && (
                  <CardComponent>
                    <FormControlLabelComponent
                      label='Invoice List'
                      isBold={true}
                      level={4}
                      className='mrg-bottom-20'
                    />
                    <TableComponent
                      columns={InvoiceListTableColumn}
                      data={invoiceList}
                      noDataText='No Invoices in this tenant'
                    />
                  </CardComponent>
                )}
              </>
            )}
          </CardComponent>
        </div>
      )}
    </div>
  );
};

export default TenantViewScreen;

// <DrawerComponent
// isOpen={openEscalationChartDrawer}
// showClose
// onClose={() => setOpenEscalationChartDrawer(false)}
// direction="right"
// title="Billing Component Details"
// >
// <TenantEscalationDrawer
//   selectedEscalationChart={selectedEscalationChart}
//   tenantDetails={tenantDetails}
//   tenantId={tenantId}
// />
// </DrawerComponent>
