import './DatePickerComponent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import LabelComponent from '../../label/LabelComponent';
import { IDatePickerProps } from '../../../models/form-controls.model';
import ErrorTextComponent from '../../error-text/ErrorTextComponent';
import { ImageConfig } from '../../../../constants';

interface DatePickerComponentProps extends IDatePickerProps {
  name?: string;
  value?: string;
  hasError?: boolean;
  errorMessage?: any;
}

const DatePickerComponent = (props: DatePickerComponentProps) => {
  const {
    className,
    hasError,
    required,
    fullWidth,
    disabled,
    label,
    minDate,
    maxDate,
    onChange,
    placeholder,
    errorMessage,
  } = props;

  const showMonthDropdown = props.showMonthDropdown || true;
  const showYearDropdown = props.showYearDropdown || true;
  const peekNextMonth = props.peekNextMonth || true;
  const dropdownMode = props.dropdownMode || 'select';

  const format = props.format ? props.format : 'MM-dd-y';
  const clearable = props.clearable !== undefined ? props.clearable : false;
  const [value, setValue] = useState<any>(props.value);

  const handleDatePickerOnChange = useCallback(
    (value: any) => {
      setValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const handleTimePickerClose = useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);

  useEffect(() => {
    setValue(props.value ? new Date(props.value) : null);
  }, [props.value]);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleTimePickerOpenClose = useCallback(() => {
    setIsDatePickerOpen(!isDatePickerOpen);
  }, [isDatePickerOpen]);

  return (
    <div
      className={`date-picker-component ${fullWidth ? 'fullWidth' : ''} ${
        disabled ? 'disabled' : ''
      } ${hasError ? 'has-error' : ''}`}
    >
      {label && <LabelComponent title={label || ''} required={required} />}
      <div className={'date-picker-container'}>
        <DatePicker
          portalId='root-portal'
          className={`date-picker-field ${className}`}
          placeholderText={placeholder}
          selected={value}
          value={value}
          minDate={minDate}
          onCalendarOpen={handleTimePickerOpenClose}
          onCalendarClose={handleTimePickerClose}
          maxDate={maxDate}
          disabled={disabled}
          open={isDatePickerOpen}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          peekNextMonth={peekNextMonth}
          dropdownMode={dropdownMode}
          selectsRange={false}
          onClickOutside={handleTimePickerClose}
          onSelect={handleTimePickerClose}
          dateFormat={format}
          onChange={(update) => {
            handleDatePickerOnChange(update);
          }}
          popperProps={{
            strategy: 'fixed',
          }}
          isClearable={clearable}
        />
        <ImageConfig.CalendarIcon
          className='date-picker-icon'
          onClick={() => !disabled && handleTimePickerOpenClose()}
        />
      </div>
      {errorMessage && hasError && <ErrorTextComponent error={errorMessage} />}
    </div>
  );
};

export default DatePickerComponent;
