import React from "react";
import "./InvoiceInfoBoxComponent.scss";

interface InvoiceInfoBoxComponentProps {
  label: string;
  value: any;
  required?: boolean;
}

const InvoiceInfoBoxComponent = (props: InvoiceInfoBoxComponentProps) => {
  const { label, value, required } = props;

  return (
    <div
      className={`invoice-info-component ${
        required === false && "ts-justify-content-between"
      }`}
    >
      <div className="invoice-info-key">
        {label}
        {required && <span> *</span>}
      </div>
      <div className="invoice-info-value">
        {value ? <>{value}</> : <>{value === 0 ? <>0</> : <> -- </>}</>}
      </div>
    </div>
  );
};

export default InvoiceInfoBoxComponent;
