import './TenantDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import * as Yup from 'yup';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useSelector, useDispatch } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import FilePreviewThumbnailComponent from '../../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent';
import BadgeComponent from '../../../../shared/components/badge/BadgeComponent';
import { getPropertyListLite } from '../../../../store/actions/property.action';
import FormikCheckBoxComponent from '../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';
import ModalComponent from '../../../../shared/components/modal/ModalComponent';
import InputComponent from '../../../../shared/components/form-controls/input/InputComponent';

interface TenantDetailsComponentProps {
  tenantDetails?: any;
  tenantId?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
}

const validationSchema = Yup.object({
  propertyId: Yup.string().required('Property is required'),
  isSezZone: Yup.string().required('Sez Zone is required').nullable(),
  igst: Yup.string()
    .when('isSezZone', {
      is: 'true',
      then: Yup.string().required('IGST is required'),
      otherwise: Yup.string().notRequired(),
    })
    .nullable(),
  name: Yup.string()
    .min(3, 'Tenant company should be at least 3 characters')
    .max(50, 'Tenant company should not exceed 50 characters')
    .required('Tenant company is required'),
  tenantCode: Yup.string()
    .min(3, 'Tenant ID should be at least 3 characters')
    .max(30, 'Tenant ID should not exceed 30 characters')
    .required('Tenant ID is required'),
  signupFlow: Yup.string(),
  domain: Yup.string().when('signupFlow', {
    is: 'Email',
    then: Yup.string().required('Domain address is required'),
    // .matches(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid domain address'),
    otherwise: Yup.string().notRequired(),
  }),
  availableSlots: Yup.number(),
  totalSlots: Yup.number(),
  numberOfParkingSpacesOccupied: Yup.string().when('step', {
    is: 'add',
    then: (schema) =>
      schema
        .required('Number of slots occupied is required')
        .test(
          'is-valid-number-or-empty',
          'Number of slots occupied must be a valid number or empty',
          function (value) {
            // If the field is empty, pass the validation
            if (value === undefined || value === '') {
              return true;
            }
            const numValue = Number(value);
            // Proceed with the validation only if the value is a number
            return !isNaN(numValue) && isFinite(numValue);
          },
        )
        .test(
          'is-less-than-available-slots',
          'Number of slots occupied cannot be greater than available slots',
          function (value) {
            // Skip this test if the value is empty
            if (value === undefined || value === '') {
              return true;
            }
            const availableSlots = this.parent.availableSlots || 0;
            return value <= availableSlots;
          },
        ),
    otherwise: (schema) => schema.notRequired(),
  }),

  additionalParkingSpaceOccupied: Yup.string().when(
    'isAdditionalSlotRequired',
    {
      is: true,
      then: (schema) =>
        schema
          .required('Number of slots occupied is required')
          .test(
            'is-valid-number-or-empty',
            'Number of slots occupied must be a valid number or empty',
            function (value) {
              // If the field is empty, pass the validation
              if (value === undefined || value === '') {
                return true;
              }
              const numValue = Number(value);
              // Proceed with the validation only if the value is a number
              return !isNaN(numValue) && isFinite(numValue);
            },
          )
          .test(
            'is-less-than-available-slots',
            'Number of slots occupied cannot be greater than available slots',
            function (value) {
              // Skip this test if the value is empty
              if (value === undefined || value === '') {
                return true;
              }
              const availableSlots = this.parent.availableSlots || 0;
              return value <= availableSlots;
            },
          ),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
  placeOfService: Yup.object({
    addressLine1: Yup.string()
      .min(3, 'Address should be at least 3 characters')
      .max(50, 'Address should not exceed 50 characters')
      .required('Address line is required'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode should have a total of 6 characters')
      .max(6, 'Pincode should not exceed 6 characters')
      .required('Pincode is required'),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string()
      .min(1, 'City should be at least 1 character')
      .max(30, 'City should not exceed 30 characters')
      .required('City is required'),
    country: Yup.string()
      .min(1, 'Country should be at least 1 character')
      .max(30, 'Country should not exceed 30 characters')
      .required('Country is required'),
  }),
  billingAddress: Yup.object().when('isBillingAddressSameAsPlaceOfService', {
    is: false,
    then: Yup.object({
      addressLine1: Yup.string()
        .min(3, 'Address should be at least 3 characters')
        .max(50, 'Address should not exceed 50 characters')
        .required('Address line is required'),
      pincode: Yup.string()
        .matches(
          /^[1-9][0-9]{5}$/,
          'Pincode should have a total of 6 characters',
        )
        .max(6, 'Pincode should not exceed 6 characters')
        .required('Pincode is required'),
      state: Yup.string().required('State is required').nullable(),
      city: Yup.string()
        .min(1, 'City should be at least 1 character')
        .max(30, 'City should not exceed 30 characters')
        .required('City is required'),
      country: Yup.string()
        .min(1, 'Country should be at least 1 character')
        .max(30, 'Country should not exceed 30 characters')
        .required('Country is required'),
    }),
  }),
  adminDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Admin name is required')
        .min(3, 'Admin name should be at least 3 characters'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
      emailType: Yup.string().required('Email type is required').nullable(),
      designation: Yup.string().required('Designation is required'),
      phoneNumber: Yup.number()
        .required('Phone number is required')
        .typeError('Invalid Phone number'),
    }),
  ),
});

interface formInitialValuesFormSchema {
  name: string;
  isSezZone: string;
  igst: number;
  tenantCode: string;
  propertyId: string;
  domain: string;
  signupFlow: string;
  // website: string;
  numberOfParkingSpacesOccupied: string;
  additionalParkingSpaceOccupied: string;
  isAdditionalSlotRequired: boolean;
  step: string;
  availableSlots?: number;
  placeOfService: {
    addressLine1: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    pincode: string;
  };
  isBillingAddressSameAsPlaceOfService: boolean;
  billingAddress: {
    addressLine1: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    pincode: string;
  };
  adminDetails: [
    {
      name: string;
      email: string;
      emailType: string;
      designation: string;
      phoneNumber: string;
    },
  ];
  image: any;
}

const formInitialValues: formInitialValuesFormSchema = {
  name: '',
  isSezZone: '',
  igst: 0,
  tenantCode: '',
  propertyId: '',
  signupFlow: 'Email',
  step: 'add',
  domain: '',
  // website: "",
  image: '',
  numberOfParkingSpacesOccupied: '',
  additionalParkingSpaceOccupied: '',
  isAdditionalSlotRequired: false,
  availableSlots: 0,
  placeOfService: {
    addressLine1: '',
    city: '',
    state: '',
    stateCode: '',
    country: 'India',
    pincode: '',
  },
  isBillingAddressSameAsPlaceOfService: false,
  billingAddress: {
    addressLine1: '',
    city: '',
    state: '',
    stateCode: '',
    country: '',
    pincode: '',
  },
  adminDetails: [
    {
      name: '',
      email: '',
      emailType: '',
      designation: '',
      phoneNumber: '',
    },
  ],
};

//igst select dropdown options
const igstOptions = [
  {
    code: '0',
    title: '0%',
  },
  {
    code: '18',
    title: '18%',
  },
];

const emailType = [
  {
    code: 'main',
    title: 'Main',
  },
  {
    code: 'invoiceCC',
    title: 'Invoice CC',
  },
];

const TenantDetailsComponent = (props: TenantDetailsComponentProps) => {
  const { prev, next, steps, activeStepId, tenantId, tenantDetails } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );
  const dispatch = useDispatch();
  const [openWarningModal, setOpenWarningModal] = useState(false);

  useEffect(() => {
    dispatch(getPropertyListLite());
  }, [dispatch]);

  const calculateAvailableSlots = useCallback(
    (propertyId: string) => {
      let ParkingSpaces;
      const property = propertyList.find(
        (property: any) => property._id === propertyId,
      );
      if (property?.campusDetails?.numberOfRemainingParkingSpaces) {
        ParkingSpaces = property?.campusDetails?.numberOfRemainingParkingSpaces;
      }
      return ParkingSpaces;
    },
    [propertyList],
  );

  useEffect(() => {
    if (tenantDetails) {
      const fetchImage = async () => {
        let imageDetails: any = {};
        if (tenantDetails?.profilePic?.url) {
          const image = await CommonService.generateBlobFileFromUrl(
            tenantDetails?.profilePic?.url,
            tenantDetails?.profilePic?.name,
            tenantDetails?.profilePic?.mimetype,
          );
          imageDetails = {
            image: image,
          };
        }
        // Set the initial values only after fetching the image
        const basicDetails = {
          name: tenantDetails?.name,
          isSezZone: tenantDetails?.isSezZone.toString(),
          igst: tenantDetails?.isSezZone && tenantDetails?.igst.toString(),
          tenantCode: tenantDetails?.tenantCode,
          propertyId: tenantDetails?.propertyId,
          signupFlow: tenantDetails?.signupFlow,
          domain: tenantDetails?.domain,
          step: 'edit',
          availableSlots:
            calculateAvailableSlots(tenantDetails?.propertyId) || 0,
          numberOfParkingSpacesOccupied:
            tenantDetails?.numberOfParkingSpacesOccupied,
          additionalParkingSpaceOccupied: '',
          isAdditionalSlotRequired: false,
          image: imageDetails.image ? imageDetails.image : '',
          placeOfService: {
            addressLine1: tenantDetails?.placeOfService?.addressLine1,
            city: tenantDetails?.placeOfService?.city,
            state: tenantDetails?.placeOfService?.state,
            stateCode: tenantDetails?.placeOfService?.stateCode,
            country: tenantDetails?.placeOfService?.country,
            pincode: tenantDetails?.placeOfService?.pincode,
          },
          isBillingAddressSameAsPlaceOfService:
            tenantDetails?.isBillingAddressSameAsPlaceOfService,
          billingAddress: {
            addressLine1: tenantDetails?.billingAddress?.addressLine1,
            city: tenantDetails?.billingAddress?.city,
            state: tenantDetails?.billingAddress?.state,
            stateCode: tenantDetails?.billingAddress?.stateCode,
            country: tenantDetails?.billingAddress?.country,
            pincode: tenantDetails?.billingAddress?.pincode,
          },
          adminDetails: tenantDetails.adminDetails?.map((admin: any) => ({
            _id: admin._id,
            email: admin.email,
            name: admin.name,
            emailType: admin.emailType,
            designation: admin.designation,
            phoneNumber: admin.phoneNumber,
          })),
        };
        setInitialValues(basicDetails);
      };
      fetchImage();
    } else {
      setInitialValues({
        name: '',
        isSezZone: '',
        igst: 0,
        tenantCode: '',
        propertyId: '',
        availableSlots: 0,
        domain: '',
        signupFlow: 'Email',
        step: 'add',
        numberOfParkingSpacesOccupied: '',
        additionalParkingSpaceOccupied: '',
        isAdditionalSlotRequired: false,
        image: '',
        placeOfService: {
          addressLine1: '',
          city: '',
          state: '',
          stateCode: '',
          country: 'India',
          pincode: '',
        },
        isBillingAddressSameAsPlaceOfService: false,
        billingAddress: {
          addressLine1: '',
          city: '',
          state: '',
          stateCode: '',
          country: '',
          pincode: '',
        },
        adminDetails: [
          {
            name: '',
            email: '',
            emailType: '',
            designation: '',
            phoneNumber: '',
          },
        ],
      });
    }
  }, [tenantDetails, calculateAvailableSlots]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const payload = {
        ...values,
        signupFlow: 'Email',
        numberOfParkingSpacesOccupied:
          values?.numberOfParkingSpacesOccupied +
          values?.additionalParkingSpaceOccupied,
        additionalParkingSpaceOccupied: '',
      };
      // delete payload.availableSlots;
      const formData = CommonService.getFormDataFromJSON(payload);
      let apiCall: any = {};
      if (tenantId) {
        apiCall = CommonService._tenants.editTenantDetailsAPICall(
          tenantId,
          formData,
        );
      } else {
        apiCall = CommonService._tenants.AddTenantAPICall(formData);
      }
      if (tenantId) {
        apiCall
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        apiCall
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [next, tenantId],
  );

  const handleDeleteAdminDetails = useCallback(
    (adminId: any, arrayHelpers: any) => {
      CommonService._tenants
        .deleteTenantAdminDetailsAPICall(tenantId, adminId)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          arrayHelpers.remove(adminId);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        })
        .finally(() => {});
    },
    [tenantId],
  );

  return (
    <div className={'tenant-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          isSubmitting,
          setFieldValue,
          submitForm,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>
                <ButtonComponent
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                  disabled={!isValid}
                  onClick={() => {
                    if (!tenantDetails?._id) {
                      setOpenWarningModal(true);
                    } else {
                      submitForm();
                    }
                  }}
                >
                  Next
                </ButtonComponent>
              </div>
              <ModalComponent
                className='confirmation-modal'
                isOpen={openWarningModal}
                onClose={() => setOpenWarningModal(false)}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => {
                        setOpenWarningModal(false);
                      }}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='primary'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      onClick={() => {
                        submitForm();
                        setOpenWarningModal(false);
                      }}
                    >
                      Confirm
                    </ButtonComponent>
                  </>
                }
              >
                <div className='confirmation-dialog-container'>
                  <div className='confirmation-dialog-content-wrapper'>
                    <div className={'confirmation-dialog-title'}>
                      Finalize Property Selection
                    </div>
                    <div className='confirmation-dialog-sub-description'>
                      Property once selected cannot be edited. Are you sure you
                      want to proceed?
                    </div>
                  </div>
                </div>
              </ModalComponent>
              <div className='add-component-content'>
                <>
                  <Field name={'propertyId'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        disabled={
                          currentUser?.roleDetails?.code === 'fm' || tenantId
                        }
                        options={propertyList}
                        displayWith={(option: any) => option.name}
                        valueExtractor={(option: any) => option._id}
                        label={'Select Property'}
                        placeholder={'Select Property'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        onUpdate={(value: any) => {
                          if (value) {
                            const availableSlots =
                              calculateAvailableSlots(value);
                            setFieldValue('availableSlots', availableSlots);
                          }
                        }}
                      />
                    )}
                  </Field>
                  <Field name={'isSezZone'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        options={CommonService._staticData.customYesNoOptions}
                        label={'SEZ'}
                        placeholder={'SEZ'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        displayWith={(option: any) => option.title}
                        valueExtractor={(option: any) => option.code}
                      />
                    )}
                  </Field>
                  {/*if the isSezZone is true, then show the IGST input*/}
                  {values.isSezZone === 'true' && (
                    <Field name={'igst'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          options={igstOptions}
                          label={'IGST'}
                          placeholder={'IGST'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          displayWith={(option: any) => option.title}
                          valueExtractor={(option: any) => option.code}
                        />
                      )}
                    </Field>
                  )}
                  <Field name={'name'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Tenant company'}
                        placeholder={'Example: Google'}
                        type={'text'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <Field name={'tenantCode'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Tenant ID'}
                        placeholder={'Example: PH-AQ12345'}
                        type={'text'}
                        maxlength={10}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <FormControlLabelComponent
                    className='pdd-bottom-10'
                    label={'Place of service'}
                    level={6}
                    isBold={true}
                  />
                  <Field name={'placeOfService.addressLine1'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Address Line'}
                        placeholder={'Example: Road No. 1, Financial District'}
                        type={'text'}
                        // maxlength={50}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'placeOfService.city'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'City'}
                            placeholder={'Example: Hyderabad'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                            validationPattern={
                              new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className='ts-col-6'>
                      <Field name={'placeOfService.state'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label={'State'}
                            placeholder={'Example: Telangana'}
                            fullWidth={true}
                            required={true}
                            searchMode={'clientSide'}
                            searchable={true}
                            isClearable={true}
                            options={CommonService._staticData.indianStatesGST}
                            displayWith={(option) => option.state}
                            valueExtractor={(option) => option.state}
                            onUpdate={(value: any) => {
                              if (value) {
                                const selectedState =
                                  CommonService._staticData.indianStatesGST.find(
                                    (item) => item.state === value,
                                  );
                                setFieldValue(
                                  'placeOfService.state',
                                  selectedState?.state,
                                );
                                setFieldValue(
                                  'placeOfService.stateCode',
                                  selectedState?.statecode,
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'placeOfService.country'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Country'}
                            placeholder={'Ex: India'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>

                    <div className='ts-col-6'>
                      <Field name={'placeOfService.pincode'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Pincode'}
                            placeholder={'Ex: 500004'}
                            // type={"number"}
                            required={true}
                            validationPattern={/^[0-9\b]+$/}
                            maxlength={6}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <FormControlLabelComponent
                    className='pdd-bottom-10'
                    label={'Billing Address'}
                    level={6}
                    isBold={true}
                  />
                  <Field name='isBillingAddressSameAsPlaceOfService'>
                    {(field: FieldProps) => (
                      <FormikCheckBoxComponent
                        label='Same as place of service'
                        formikField={field}
                        size={'small'}
                        disabled={
                          values?.placeOfService?.addressLine1.length === 0 ||
                          values?.placeOfService?.city.length === 0 ||
                          values?.placeOfService?.country.length === 0 ||
                          values?.placeOfService?.pincode.length === 0 ||
                          values?.placeOfService?.state.length === 0
                        }
                        onChange={(value: boolean) => {
                          setFieldValue(
                            'billingAddress.addressLine1',
                            value ? values?.placeOfService?.addressLine1 : '',
                          );
                          setFieldValue(
                            'billingAddress.city',
                            value ? values?.placeOfService?.city : '',
                          );
                          setFieldValue(
                            'billingAddress.state',
                            value ? values?.placeOfService?.state : '',
                          );
                          setFieldValue(
                            'billingAddress.stateCode',
                            value ? values?.placeOfService?.stateCode : '',
                          );
                          setFieldValue(
                            'billingAddress.pincode',
                            value ? values?.placeOfService?.pincode : '',
                          );
                          setFieldValue(
                            'billingAddress.country',
                            value ? values?.placeOfService?.country : '',
                          );
                        }}
                      />
                    )}
                  </Field>
                  <Field name={'billingAddress.addressLine1'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Address Line'}
                        placeholder={'Example: Road No. 1, Financial District'}
                        type={'text'}
                        // maxlength={50}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        readOnly={values?.isBillingAddressSameAsPlaceOfService}
                      />
                    )}
                  </Field>
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'billingAddress.city'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'City'}
                            placeholder={'Example: Hyderabad'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                            validationPattern={
                              new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                            }
                            readOnly={
                              values?.isBillingAddressSameAsPlaceOfService
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <div className='ts-col-6'>
                      <Field name={'billingAddress.state'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label={'State'}
                            placeholder={'Example: Telangana'}
                            fullWidth={true}
                            required={true}
                            searchMode={'clientSide'}
                            searchable={true}
                            isClearable={true}
                            disabled={
                              values?.isBillingAddressSameAsPlaceOfService
                            }
                            options={CommonService._staticData.indianStatesGST}
                            displayWith={(option) => option.state}
                            valueExtractor={(option) => option.state}
                            onUpdate={(value: any) => {
                              if (value) {
                                const selectedState =
                                  CommonService._staticData.indianStatesGST.find(
                                    (item) => item.state === value,
                                  );
                                setFieldValue(
                                  'billingAddress.state',
                                  selectedState?.state,
                                );
                                setFieldValue(
                                  'billingAddress.stateCode',
                                  selectedState?.statecode,
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'billingAddress.country'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Country'}
                            placeholder={'Ex: India'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                            readOnly={
                              values?.isBillingAddressSameAsPlaceOfService
                            }
                          />
                        )}
                      </Field>
                    </div>

                    <div className='ts-col-6'>
                      <Field name={'billingAddress.pincode'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Pincode'}
                            placeholder={'Ex: 500004'}
                            required={true}
                            validationPattern={/^[0-9\b]+$/}
                            maxlength={6}
                            formikField={field}
                            fullWidth={true}
                            readOnly={
                              values?.isBillingAddressSameAsPlaceOfService
                            }
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <Field name={'signupFlow'}>
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        label={'Which sign up flow does the tenant prefer?'}
                        required={false}
                        disabled={true}
                        formikField={field}
                        valueExtractor={(option: any) => option.code}
                        displayWith={(option: any) => option.title}
                        keyExtractor={(option: any) => option.code}
                        options={CommonService._staticData.signUpOptions}
                      />
                    )}
                  </Field>
                  <Field name={'domain'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Domain address'}
                        placeholder={'Enter domain address'}
                        type={'text'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>

                  <div className='d-flex'>
                    <FormControlLabelComponent
                      className='pdd-bottom-10'
                      label={'Parking Details'}
                      level={6}
                      isBold={true}
                    />{' '}
                    &nbsp;
                    <BadgeComponent>
                      {values?.availableSlots || 0} Available Slots
                    </BadgeComponent>
                  </div>
                  {values?.step === 'add' ? (
                    <Field name={'numberOfParkingSpacesOccupied'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'No of Slots Occupied'}
                          placeholder={'EX: 20'}
                          type={'text'}
                          disabled={
                            !values.propertyId ||
                            (values?.step === 'edit' &&
                              values?.availableSlots === 0)
                          }
                          validationPattern={new RegExp(/^[0-9]*$/)}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          onChange={(value: any) => {
                            setFieldValue(
                              `numberOfParkingSpacesOccupied`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  ) : (
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <InputComponent
                          label='Parking slots occupied in this tenant'
                          value={values?.numberOfParkingSpacesOccupied || '0'}
                          fullWidth
                          readOnly
                          disabled
                        />
                      </div>
                      <div className='ts-col-6'>
                        {values?.isAdditionalSlotRequired ? (
                          <div className='d-flex'>
                            <Field name={'additionalParkingSpaceOccupied'}>
                              {(field: FieldProps) => (
                                <FormikInputComponent
                                  label={'No of new slots to be occupied'}
                                  placeholder={'EX: 20'}
                                  type={'text'}
                                  disabled={!values.propertyId}
                                  validationPattern={
                                    new RegExp(/^[1-9][0-9]*$/)
                                  }
                                  required={true}
                                  formikField={field}
                                  fullWidth={true}
                                  onChange={(value: any) => {
                                    setFieldValue(
                                      `additionalParkingSpaceOccupied`,
                                      value ? parseInt(value) : '',
                                    );
                                  }}
                                />
                              )}
                            </Field>
                            <IconButtonComponent
                              className='mrg-left-10 mrg-top-35'
                              color='error'
                              onClick={() => {
                                setFieldValue(
                                  'isAdditionalSlotRequired',
                                  false,
                                );
                                setFieldValue(
                                  'additionalParkingSpaceOccupied',
                                  '',
                                );
                              }}
                            >
                              <ImageConfig.DeleteOutlineIcon />
                            </IconButtonComponent>
                          </div>
                        ) : (
                          <ButtonComponent
                            className='mrg-top-25'
                            fullWidth
                            variant='outlined'
                            prefixIcon={<ImageConfig.AddOutlinedIcon />}
                            onClick={() => {
                              setFieldValue('isAdditionalSlotRequired', true);
                            }}
                            disabled={values?.availableSlots === 0}
                          >
                            Add Extra Parking Space
                          </ButtonComponent>
                        )}
                      </div>
                    </div>
                  )}
                  <FormControlLabelComponent
                    className='pdd-bottom-10'
                    label={'Admin Details'}
                    level={6}
                    isBold={true}
                  />
                  <FieldArray
                    name='adminDetails'
                    render={(arrayHelpers) => (
                      <>
                        {values?.adminDetails &&
                          values?.adminDetails.map((item: any, index: any) => {
                            return (
                              <div
                                className='admin-details-wrapper'
                                key={index}
                              >
                                <Field name={`adminDetails.${index}.name`}>
                                  {(field: FieldProps) => (
                                    <FormikInputComponent
                                      label={'Admin Name'}
                                      placeholder={'Enter Name'}
                                      type={'text'}
                                      formikField={field}
                                      fullWidth={true}
                                      validationPattern={
                                        new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`adminDetails.${index}.phoneNumber`}
                                >
                                  {(field: FieldProps) => (
                                    <FormikInputComponent
                                      label={'Phone Number'}
                                      placeholder={'+91 00000 00000'}
                                      type={'text'}
                                      validationPattern={
                                        new RegExp(/^[5-9][0-9]{0,9}$/)
                                      }
                                      formikField={field}
                                      fullWidth={true}
                                    />
                                  )}
                                </Field>
                                <Field name={`adminDetails.${index}.emailType`}>
                                  {(field: FieldProps) => (
                                    <FormikSelectDropdownComponent
                                      formikField={field}
                                      fullWidth={true}
                                      label='Email Type'
                                      placeholder='Select'
                                      options={emailType}
                                      displayWith={(option: any) =>
                                        option.title
                                      }
                                    />
                                  )}
                                </Field>
                                <Field
                                  name={`adminDetails.${index}.designation`}
                                >
                                  {(field: FieldProps) => (
                                    <FormikInputComponent
                                      label={'Designation'}
                                      placeholder={'Ex : Manager'}
                                      type={'text'}
                                      formikField={field}
                                      fullWidth={true}
                                    />
                                  )}
                                </Field>
                                <Field name={`adminDetails.${index}.email`}>
                                  {(field: FieldProps) => (
                                    <FormikInputComponent
                                      label={'Email'}
                                      placeholder={'Enter Email'}
                                      type={'text'}
                                      formikField={field}
                                      fullWidth={true}
                                    />
                                  )}
                                </Field>
                                <div className='admin-details-wrapper'>
                                  <IconButtonComponent
                                    color='primary'
                                    onClick={() => {
                                      arrayHelpers.push('');
                                    }}
                                  >
                                    <ImageConfig.AddOutlinedIcon />
                                  </IconButtonComponent>
                                  {values?.adminDetails?.length > 1 && (
                                    <IconButtonComponent
                                      color='error'
                                      onClick={() => {
                                        if (item?._id) {
                                          handleDeleteAdminDetails(
                                            item?._id,
                                            arrayHelpers,
                                          );
                                        } else {
                                          arrayHelpers.remove(index);
                                        }
                                      }}
                                    >
                                      <ImageConfig.DeleteOutlineIcon />
                                    </IconButtonComponent>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )}
                  />
                  <div className={'row-wrapper'}>
                    <FormControlLabelComponent
                      label={'Upload Image'}
                      isBold={true}
                      level={4}
                    />
                    <div className={'optional-text'}>Optional</div>
                  </div>

                  <div className='upload-property-image'>
                    {!values.image && (
                      <div className={'mrg-top-10 height-90'}>
                        {!values.image && (
                          <>
                            <FilePickerComponent
                              maxFileCount={1}
                              height={'90%'}
                              id={'sc_upload_btn'}
                              onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                if (acceptedFiles && acceptedFiles.length > 0) {
                                  const file = acceptedFiles[0];
                                  setFieldValue('image', file);
                                }
                              }}
                              acceptedFileTypes={['png', 'jpg', 'jpeg']}
                              uploadDescription={'(upload only one file)'}
                            />
                          </>
                        )}
                      </div>
                    )}
                    <>
                      {values.image && (
                        <>
                          <FilePreviewThumbnailComponent
                            file={values.image}
                            onRemove={() => {
                              setFieldValue('image', '');
                            }}
                          />
                        </>
                      )}
                    </>
                  </div>
                </>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TenantDetailsComponent;
