import './OperationDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import * as Yup from 'yup';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';

interface OperationDetailsComponentProps {
  amenityDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
  amenityId: any;
}

const validationSchema = Yup.object({
  adminDetails: Yup.array().of(
    Yup.object().shape({
      adminName: Yup.string()
        .required('Admin name is required')
        .min(3, 'Admin name should be at least 3 characters'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email address'),
      emailType: Yup.string().required('Email type is required').nullable(),
      designation: Yup.string().required('Designation is required'),
      phoneNumber: Yup.number()
        .required('Phone number is required')
        .typeError('Invalid Phone number'),
    }),
  ),
});

const emailType = [
  {
    code: 'main',
    title: 'Main',
  },
  {
    code: 'invoiceCC',
    title: 'Invoice CC',
  },
];

interface formInitialValuesFormSchema {
  adminDetails: [
    {
      adminName: string;
      email: string;
      emailType: string;
      designation: string;
      phoneNumber: string;
    },
  ];
}

const formInitialValues: formInitialValuesFormSchema = {
  adminDetails: [
    {
      adminName: '',
      email: '',
      emailType: '',
      designation: '',
      phoneNumber: '',
    },
  ],
};

const OperationDetailsComponent = (props: OperationDetailsComponentProps) => {
  const { amenityDetails, prev, next, steps, activeStepId, amenityId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);

  useEffect(() => {
    if (amenityDetails && amenityDetails?.adminDetails?.length > 0) {
      const basicDetails = {
        adminDetails: amenityDetails?.adminDetails?.map((admin: any) => ({
          adminName: admin?.adminName,
          phoneNumber: admin?.phoneNumber,
          emailType: admin?.emailType,
          designation: admin?.designation,
          email: admin?.email,
          _id: admin?._id,
        })),
      };
      setInitialValues(basicDetails);
    } else {
      setInitialValues(formInitialValues);
    }
  }, [amenityDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const payload = {
        ...values,
      };
      CommonService._amenity
        .addAmenityOperatorDetails(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next(response.data);
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [amenityId, next],
  );

  const handleDeleteAdminDetails = useCallback(
    (adminId: any, arrayHelpers: any) => {
      CommonService._amenity
        .deleteAmenityAdminDetailsAPICall(amenityId, adminId)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          arrayHelpers.remove(adminId);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        })
        .finally(() => {});
    },
    [amenityId],
  );

  return (
    <div className={'operation-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Back
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <FormControlLabelComponent
                  className='pdd-bottom-10'
                  label={'Amenity Admin Details'}
                  level={5}
                  isBold={true}
                />
                <FieldArray
                  name='adminDetails'
                  render={(arrayHelpers: any) => (
                    <>
                      {values?.adminDetails &&
                        values?.adminDetails?.map((item: any, index: any) => {
                          return (
                            <div className='admin-details-wrapper' key={index}>
                              <Field name={`adminDetails.${index}.adminName`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Admin Name'}
                                    placeholder={'Enter Name'}
                                    type={'text'}
                                    formikField={field}
                                    fullWidth={true}
                                    validationPattern={
                                      new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                                    }
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.phoneNumber`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Phone Number'}
                                    placeholder={'+91 00000 00000'}
                                    type={'text'}
                                    validationPattern={
                                      new RegExp(/^[5-9][0-9]{0,9}$/)
                                    }
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.emailType`}>
                                {(field: FieldProps) => (
                                  <FormikSelectDropdownComponent
                                    formikField={field}
                                    fullWidth={true}
                                    label='Email Type'
                                    placeholder='Select'
                                    options={emailType}
                                    displayWith={(option: any) => option.title}
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.designation`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Designation'}
                                    placeholder={'Ex : Manager'}
                                    type={'text'}
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <Field name={`adminDetails.${index}.email`}>
                                {(field: FieldProps) => (
                                  <FormikInputComponent
                                    label={'Email'}
                                    placeholder={'abc@gmail.com'}
                                    type={'text'}
                                    formikField={field}
                                    fullWidth={true}
                                  />
                                )}
                              </Field>
                              <div className='admin-details-wrapper'>
                                <IconButtonComponent
                                  color='primary'
                                  onClick={() => {
                                    arrayHelpers.push('');
                                  }}
                                >
                                  <ImageConfig.AddOutlinedIcon />
                                </IconButtonComponent>
                                {values?.adminDetails?.length > 1 && (
                                  <IconButtonComponent
                                    color='error'
                                    onClick={() => {
                                      if (item?._id) {
                                        handleDeleteAdminDetails(
                                          item?._id,
                                          arrayHelpers,
                                        );
                                      } else {
                                        arrayHelpers.remove(index);
                                      }
                                    }}
                                  >
                                    <ImageConfig.DeleteOutlineIcon />
                                  </IconButtonComponent>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OperationDetailsComponent;
