import './AnswerDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import * as Yup from 'yup';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';

interface AnswerDetailsComponentProps {
  pollsDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
}

const AnswerOptionsObj1 = {
  value: '',
  title: 'option 1',
};

const AnswerOptionsObj2 = {
  value: '',
  title: 'option 2',
};

export interface IAnswerOptions {
  title: string;
  value: string;
}

const emojisArray = [
  <ImageConfig.LoveEmoji />,
  <ImageConfig.LikeEmoji />,
  <ImageConfig.DisLikeEmoji />,
  <ImageConfig.SadEmoji />,
  <ImageConfig.DisSatisfiedEmoji />,
];

const validationSchema = Yup.object().shape({
  answerType: Yup.string().required('Answer Type is required').nullable(),
  answerSubType: Yup.string().when('answerType', {
    is: (answerType: any) =>
      answerType === 'Linear Scale' || answerType === 'Rating',
    then: Yup.string().required('Vote type is Required').nullable(),
    otherwise: Yup.string().nullable(),
  }),
  answerOptions: Yup.array().when('answerType', {
    is: (answerType: any) =>
      answerType === 'Single Select' || answerType === 'Multiple Select',
    then: Yup.array()
      .min(2, 'At least 2 options are required')
      .test(
        'unique-values',
        'Options must have unique values',
        function (options: IAnswerOptions[] | undefined) {
          if (!options) {
            return false;
          }
          const values = options.map((option) => option.value);
          const uniqueValues = new Set(values);
          return values.length === uniqueValues.size;
        },
      )
      .of(
        Yup.object().shape({
          value: Yup.string().required('Option value is required'),
        }),
      )
      .required('Required'),
    otherwise: Yup.array().nullable(),
  }),
});

interface formInitialValuesFormSchema {
  answerType: string;
  answerSubType: string;
  answerOptions: IAnswerOptions[];
}

const formInitialValues: formInitialValuesFormSchema = {
  answerType: '',
  answerSubType: '',
  answerOptions: [AnswerOptionsObj1, AnswerOptionsObj2],
};

const AnswerDetailsComponent = (props: AnswerDetailsComponentProps) => {
  const { pollsDetails, prev, next, steps, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const { poleTypes } = useSelector(
    (state: IRootReducerState) => state.staticData,
  );

  useEffect(() => {
    if (pollsDetails) {
      setInitialValues({
        ...pollsDetails,
        answerType: pollsDetails.answerType,
        answerSubType: pollsDetails.answerSubType,
        answerOptions: pollsDetails?.answerOptions || [
          AnswerOptionsObj1,
          AnswerOptionsObj2,
        ],
      });
    }
  }, [pollsDetails]);

  const onSubmit = useCallback(
    (values: any) => {
      const payload = {
        ...values,
      };
      if (
        payload.answerType === 'Linear Scale' ||
        payload.answerType === 'Rating'
      ) {
        delete payload.answerOptions;
      }

      if (
        payload.answerType === 'Single Select' ||
        payload.answerType === 'Multiple Select'
      ) {
        delete payload.answerSubType;
      }

      if (payload.answerOptions) {
        payload.answerOptions = payload.answerOptions.map(
          (option: IAnswerOptions, index: any) => {
            return {
              value: option.value,
              title: `option ${index + 1}`,
            };
          },
        );
      }

      next(payload);
    },
    [next],
  );

  return (
    <div className={'answer-details-component'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, validateForm, isValid, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={!isValid}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <Field name={'answerType'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      options={poleTypes.answerTypes}
                      displayWith={(option: any) => option}
                      valueExtractor={(option: any) => option}
                      label={'Answer Type'}
                      placeholder={'Select Answer Type'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      isClearable={false}
                    />
                  )}
                </Field>
                {(values.answerType === 'Single Select' ||
                  values.answerType === 'Multiple Select') && (
                  <>
                    <div className='scroll-content'>
                      <FieldArray
                        name='answerOptions'
                        render={(arrayHelpers) => (
                          <>
                            {values?.answerOptions?.map((item, index) => (
                              <div className='ts-row' key={index}>
                                <div className='ts-col'>
                                  <Field name={`answerOptions[${index}].value`}>
                                    {(field: FieldProps) => (
                                      <FormikInputComponent
                                        label={`Option ${index + 1}`}
                                        placeholder='Enter your poll options here'
                                        type='text'
                                        formikField={field}
                                        fullWidth={true}
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className='ts-col-1 d-flex align-items-center'>
                                  <IconButtonComponent
                                    className='form-helper-icon'
                                    color='secondary'
                                    onClick={() => {
                                      arrayHelpers.push({
                                        value: '',
                                        title: `option ${
                                          values?.answerOptions?.length + 1
                                        }`,
                                      });
                                    }}
                                  >
                                    <ImageConfig.AddOutlinedIcon />
                                  </IconButtonComponent>
                                  {index > 1 && (
                                    <IconButtonComponent
                                      className='form-helper-icon mrg-left-15'
                                      color='error'
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                    >
                                      <ImageConfig.DeleteOutlineIcon />
                                    </IconButtonComponent>
                                  )}
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}

                {values?.answerType === 'Linear Scale' && (
                  <>
                    <div className='ts-row ts-align-items-center'>
                      <div className='ts-col-5'>
                        <Field name={'answerSubType'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={poleTypes.LinearTypes}
                              displayWith={(option: any) => option}
                              valueExtractor={(option: any) => option}
                              label={'Vote Type'}
                              placeholder={'Select Vote Type'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-7' />
                    </div>
                  </>
                )}

                {values?.answerType === 'Rating' && (
                  <>
                    <div className='ts-row ts-align-items-center'>
                      <div className='ts-col-5'>
                        <Field name={'answerSubType'}>
                          {(field: FieldProps) => (
                            <FormikSelectDropdownComponent
                              options={poleTypes.Rating}
                              displayWith={(option: any) => option}
                              valueExtractor={(option: any) => option}
                              label={'Vote Type'}
                              placeholder={'Select Vote Type'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-2' />
                      <div className='ts-col-5 d-flex ts-justify-content-around'>
                        {values.answerSubType === 'Stars' && (
                          <>
                            {' '}
                            {Array(5)
                              .fill(<ImageConfig.StarRatingIcon />)
                              .map((item, index) => (
                                <span key={index}>{item}</span>
                              ))}
                          </>
                        )}
                        {values.answerSubType === 'Emoji’s' && (
                          <>
                            {' '}
                            {emojisArray.map((item: any, index) => (
                              <span key={index}>{item}</span>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AnswerDetailsComponent;
