import React, { useEffect, useRef, useState } from 'react';
import './BarChartComponent.scss';
import ReactECharts from 'echarts-for-react';
import { Skeleton } from '@mui/material';

interface BarChartComponentProps {
  apiResponse: any;
  loading: boolean;
}

const BarChartComponent = (props: BarChartComponentProps) => {
  const { apiResponse, loading } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateChartSize = () => {
      if (chartRef.current) {
        setChartSize({
          width: chartRef.current.clientWidth,
          height: chartRef.current.clientHeight,
        });
      }
    };

    window.addEventListener('resize', updateChartSize);
    updateChartSize();

    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  const option = {
    legend: {
      show: false,
    },
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        const { seriesName, value } = params;
        const seriesIndex = params.seriesIndex + 1;
        const seriesValue = value[seriesIndex];
        return `
            <div>
              <div style="font-weight:700 ; color: #a0aec0; width:100px">${seriesName}</div>
              <div style="font-weight: 700; font-size: 20px; color: #2d3748;">${seriesValue.toLocaleString()}</div>
            </div>
        `;
      },
      borderColor: '#fff',
    },
    dataset: {
      source: apiResponse,
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [
      {
        type: 'bar',
        name: 'Rent',
        itemStyle: {
          borderRadius: [6, 6, 0, 0],
          color: '#0478f0',
        },
      },
      {
        type: 'bar',
        name: 'Building CAM',
        itemStyle: {
          borderRadius: [6, 6, 0, 0],
          color: '#f0e106',
        },
      },
      {
        type: 'bar',
        name: 'Campus CAM',
        itemStyle: {
          borderRadius: [6, 6, 0, 0],
          color: '#47cfea',
        },
      },
    ],
  };
  return (
    <div className='bar-chart-component' ref={chartRef}>
      {loading ? (
        <Skeleton variant='rectangular' width={'100%'} height={'400px'} />
      ) : (
        <ReactECharts
          option={option}
          style={{ height: chartSize.height, width: chartSize.width }}
        />
      )}
    </div>
  );
};

export default BarChartComponent;
