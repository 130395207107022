import { combineReducers } from 'redux';
import AccountReducer, { IAccountReducerState } from './account.reducer';
import NavigationReducer, {
  INavigationReducerState,
} from './navigation.reducer';
import StaticDataReducer, {
  IStaticDataReducerState,
} from './static-data.reducer';
import UserReducer, { IUserReducerState } from './user.reducer';
import PropertyReducer, { IPropertyReducerState } from './property.reducer';
import EntityReducer, { IEntityReducerState } from './entity.reducer';
import TenantReducer, { ITenantReducerState } from './tenant.reducer';
import invoiceReducer, { IInvoiceReducerState } from './invoice.reducer';
import amenityReducer, { IAmenityReducerState } from './amenity.reducer';

export interface IRootReducerState {
  account: IAccountReducerState;
  navigation: INavigationReducerState;
  staticData: IStaticDataReducerState;
  user: IUserReducerState;
  property: IPropertyReducerState;
  entity: IEntityReducerState;
  tenant: ITenantReducerState;
  invoice: IInvoiceReducerState;
  amenity: IAmenityReducerState;
}

const rootReducer = combineReducers({
  account: AccountReducer,
  navigation: NavigationReducer,
  staticData: StaticDataReducer,
  user: UserReducer,
  property: PropertyReducer,
  entity: EntityReducer,
  tenant: TenantReducer,
  invoice: invoiceReducer,
  amenity: amenityReducer,
});

export default rootReducer;
