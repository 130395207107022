import "./DateRangePickerComponent.scss";
import React, { useCallback, useEffect, useState } from "react";

import { IDateRangePickerProps } from "../../../models/form-controls.model";
import LabelComponent from "../../label/LabelComponent";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ImageConfig } from "../../../../constants";

interface DateRangePickerComponentProps extends IDateRangePickerProps {}

const DateRangePickerComponent = (props: DateRangePickerComponentProps) => {
  const {
    name,
    label,
    hasError,
    errorMessage,
    inline,
    className,
    fullWidth,
    onChange,
    minDate,
    maxDate,
    required,
    placeholder,
  } = props;

  const showMonthDropdown = props.showMonthDropdown || true;
  const showYearDropdown = props.showYearDropdown || true;
  const peekNextMonth = props.peekNextMonth || true;
  const dropdownMode = props.dropdownMode || "select";
  const clearable = props.clearable !== undefined ? props.clearable : false;
  const format = props.format ? props.format : "dd/MM/yyyy";
  const [dateRange, setDateRange] = useState<any[]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleChange = useCallback(
    (newValue: any | null) => {
      setDateRange(newValue);
      if (onChange) onChange(newValue);
    },
    [onChange]
  );

  const handleTimePickerOpen = useCallback(() => {
    setIsDatePickerOpen(true);
  }, []);

  const handleTimePickerClose = useCallback(() => {
    setIsDatePickerOpen(false);
  }, []);

  useEffect(() => {
    setDateRange([
      props.startDate ? props.startDate : null,
      props.endDate ? props.endDate : null,
    ]);
  }, [props.startDate, props.endDate]);

  return (
    <div
      className={`date-range-picker-component ${fullWidth ? "fullWidth" : ""}`}
    >
      {label && <LabelComponent title={label} required={required} />}
      <DatePicker
        className={`date-range-picker-field ${className}`}
        placeholderText={placeholder}
        selectsRange={true}
        name={name}
        inline={inline}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        showIcon={false}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        peekNextMonth={peekNextMonth}
        dropdownMode={dropdownMode}
        dateFormat={format}
        open={isDatePickerOpen}
        onCalendarOpen={handleTimePickerOpen}
        onCalendarClose={handleTimePickerClose}
        onChange={update => {
          handleChange(update);
        }}
        isClearable={clearable}
      />
      {clearable && !startDate && !endDate && (
        <ImageConfig.CalendarDatePicker
          className="date-picker-icon"
          onClick={handleTimePickerOpen}
        />
      )}
      {errorMessage && hasError && <ErrorTextComponent error={errorMessage} />}
    </div>
  );
};

export default DateRangePickerComponent;
