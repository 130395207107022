import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { CommonService } from '../../../shared/services';
import { ImageConfig, Misc } from '../../../constants';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import FilePickerComponent from '../../../shared/components/file-picker/FilePickerComponent';
import FilePreviewThumbnailComponent from '../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import ModalComponent from '../../../shared/components/modal/ModalComponent';
import TextAreaComponent from '../../../shared/components/form-controls/text-area/TextAreaComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import TableComponent from '../../../shared/components/table/TableComponent';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import moment from 'moment';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { ITableColumn } from '../../../shared/models/table.model';
import InvoiceInfoBoxComponent from '../../../shared/components/invoice-info-box/InvoiceInfoBoxComponent';

const UtilityInvoiceItemListTable: ITableColumn[] = [
  {
    title: 'S.no',
    key: 'sno',
    dataIndex: 'sno',
    width: '40',
    render: (item: any, rowIndex: number) => {
      return <>{rowIndex + 1}</>;
    },
  },
  {
    title: 'Item',
    key: 'item',
    dataIndex: 'item',
    width: '150',
    render: (item: any) => {
      return <>{item?.itemName || '--'}</>;
    },
  },
  {
    title: 'UOM',
    key: 'uom',
    dataIndex: 'uom',
    width: '80',
    render: (item: any) => {
      return <>{item?.unitDesc || '--'}</>;
    },
  },
  {
    title: 'Consumption',
    key: 'consumption',
    dataIndex: 'consumption',
    width: '80',
    render: (item: any) => {
      return <>{item?.consumption || 0}</>;
    },
  },
  {
    title: 'Rate Per Unit',
    key: 'ratePerUnit',
    dataIndex: 'ratePerUnit',
    width: '100',
    render: (item: any) => {
      return <>{item?.ratePerUnit || 0}</>;
    },
  },
  {
    title: 'Total',
    key: 'amount',
    dataIndex: 'amount',
    width: '100',
    render: (item: any) => {
      return <>{item?.amount || 0}</>;
    },
  },
];

const DebitNoteDetailsComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { debitNoteId } = useParams();
  const [debitNoteDetails, setDebitNoteDetails] = useState<any>(null);
  const [isDebitNoteDetailsLoading, setIsDebitNoteDetailsLoading] =
    useState<boolean>(false);
  const [isDebitNoteDetailsLoaded, setIsDebitNoteDetailsLoaded] =
    useState<boolean>(false);
  const [isDebitNoteDetailsLoadingFailed, setIsDebitNoteDetailsLoadingFailed] =
    useState<boolean>(false);
  const [openCancelDebitNoteModel, setOpenCancelDebitNoteModel] =
    useState<boolean>(false);
  const [reasonForCancellation, setReasonForCancellation] =
    useState<any>(undefined);
  const [openMarkAsPaidModal, setOpenMarkAsPaidModal] =
    useState<boolean>(false);
  const [markAsPaidFile, setMarkAsPaidFile] = useState<any>('');
  const [debitNoteGeneratedDateExpiry, setDebitNoteGeneratedDateExpiry] =
    useState<boolean>(false);
  const [isDebitNoteHandlerLoading, setIsDebitNoteHandlerLoading] =
    useState<boolean>(false);
  const [isDebitNoteDownloadLoading, setIsDebitNoteDownloadLoading] =
    useState<boolean>(false);
  const [addressDetails, setAddressDetails] = useState<any>(undefined);

  const getDetails = useCallback(() => {
    const type = debitNoteDetails?.billingTo;
    const payload = {};
    let apiCall: any;
    if (type === 'tenant') {
      apiCall = CommonService._tenants.GetTenantsDetailsAPICall(
        debitNoteDetails?.tenantId,
        payload,
      );
    }
    if (type === 'amenity') {
      apiCall = CommonService._amenity.GetAmenityDetailsAPICall(
        debitNoteDetails?.amenityId,
        payload,
      );
    }
    if (debitNoteDetails) {
      apiCall
        .then((response: IAPIResponseType<any>) => {
          setAddressDetails(response?.data);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
        });
    }
  }, [debitNoteDetails]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  useEffect(() => {
    if (debitNoteDetails) {
      dispatch(
        setCurrentNavParams(debitNoteDetails?.status, 'debitNote', () => {
          navigate(CommonService._routeConfig.DebitNoteListRoute());
        }),
      );
    }
  }, [dispatch, navigate, debitNoteDetails]);

  const getDebitNoteDetails = useCallback(() => {
    setIsDebitNoteDetailsLoading(true);
    const payload: any = {};
    if (debitNoteId) {
      CommonService._debitnote
        .GetDebitNoteDetailsAPICall(debitNoteId, payload)
        .then((response: IAPIResponseType<any>) => {
          const debitNoteDetails = response?.data;
          setDebitNoteDetails(debitNoteDetails);
          setReasonForCancellation(debitNoteDetails?.reasonForCancellation);
          setIsDebitNoteDetailsLoaded(true);
          setIsDebitNoteDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsDebitNoteDetailsLoaded(false);
          setIsDebitNoteDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsDebitNoteDetailsLoading(false);
        });
    }
  }, [debitNoteId]);

  useEffect(() => {
    getDebitNoteDetails();
  }, [getDebitNoteDetails]);

  const debitNoteGeneratedDateExpiryHandler = useCallback(() => {
    if (debitNoteDetails) {
      const generatedDateObj = new Date(debitNoteDetails?.generatedDate);
      const currentDate = new Date();
      const timeDifference = currentDate.getTime() - generatedDateObj.getTime();
      const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
      if (timeDifference > twentyFourHoursInMilliseconds) {
        setDebitNoteGeneratedDateExpiry(true);
      }
    }
  }, [debitNoteDetails]);

  useEffect(() => {
    if (debitNoteDetails) {
      debitNoteGeneratedDateExpiryHandler();
    }
  }, [debitNoteDetails, debitNoteGeneratedDateExpiryHandler]);

  const cancelDebitNoteHandler = useCallback(() => {
    setOpenCancelDebitNoteModel(false);
    setIsDebitNoteHandlerLoading(true);
    if (debitNoteId) {
      const payload = {
        reasonForCancellation: reasonForCancellation,
      };
      CommonService._debitnote
        .cancelDebitNoteAPICall(debitNoteId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(response.message, 'success');
          if (response.success) {
            window.scroll(0, 0);
            setIsDebitNoteHandlerLoading(false);
            getDebitNoteDetails();
          }
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error.error, 'error');
        })
        .finally(() => {
          setOpenCancelDebitNoteModel(false);
          setIsDebitNoteHandlerLoading(false);
        });
    }
  }, [reasonForCancellation, debitNoteId, getDebitNoteDetails]);

  const markAsPaidConfirmation = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: 'Mark as Paid',
      image: ImageConfig.TickMarkIcon,
      confirmationDescription:
        'Are you sure you want to mark the debit note as paid?',
      no: {
        color: 'default',
        text: 'No',
        variant: 'outlined',
      },
      yes: {
        color: 'primary',
        text: 'Yes',
        variant: 'contained',
      },
    }).then(() => {
      setOpenMarkAsPaidModal(true);
    });
  }, []);

  const markAsPaidHandler = useCallback(() => {
    setOpenCancelDebitNoteModel(false);
    setIsDebitNoteHandlerLoading(true);

    const payload = {
      image: markAsPaidFile,
    };
    const formData = CommonService.getFormDataFromJSON(payload);
    if (debitNoteId && markAsPaidFile) {
      CommonService._debitnote
        .markAsPaidDebitNoteAPICall(debitNoteId, formData)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          if (response.success) {
            window.scroll(0, 0);
            getDebitNoteDetails();
            setIsDebitNoteHandlerLoading(false);
          }
          setMarkAsPaidFile('');
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
        })
        .finally(() => {
          setOpenMarkAsPaidModal(false);
          setIsDebitNoteHandlerLoading(false);
          setMarkAsPaidFile('');
        });
    }
  }, [markAsPaidFile, debitNoteId, getDebitNoteDetails]);

  const handleDebitNoteDownload = useCallback(async () => {
    let payload: any;
    if (debitNoteDetails?.invoicePDF) {
      setIsDebitNoteDownloadLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        CommonService.downloadFile(
          debitNoteDetails?.invoicePDF?.url,
          debitNoteDetails?.invoicePDF?.name,
        );
      } catch (err) {
        console.log('ERROR WHILE DOWNLOADNG DEBITNOTE', err);
        setIsDebitNoteDownloadLoading(false);
      } finally {
        setIsDebitNoteDownloadLoading(false);
      }
    } else {
      setIsDebitNoteDownloadLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await CommonService._debitnote
        .GetDebitNoteDetailsAPICall(debitNoteDetails?._id, payload)
        .then(async (response: IAPIResponseType<any>) => {
          const debitNoteDetails = response?.data;
          setIsDebitNoteDownloadLoading(false);
          if (debitNoteDetails?.invoicePDF) {
            CommonService.downloadFile(
              debitNoteDetails?.invoicePDF?.url,
              debitNoteDetails?.invoicePDF?.name,
            );
          }
        })
        .catch((error: any) => {
          setIsDebitNoteDownloadLoading(false);
        })
        .finally(() => {
          setIsDebitNoteDownloadLoading(false);
        });
    }
  }, [debitNoteDetails]);

  const DebitNoteDetailsHeader = useCallback(
    (status: string, paymentStatus: string) => {
      const downloadDebitNoteButton = (
        <ButtonComponent
          onClick={handleDebitNoteDownload}
          type='button'
          variant='outlined'
          disabled={isDebitNoteDownloadLoading}
        >
          {isDebitNoteDownloadLoading ? 'Downloading...' : 'Download Invoice'}
        </ButtonComponent>
      );

      let headerContent = null;

      switch (status) {
        case 'approved':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-details-paid'>
                <div className='invoice-screen-title'>Debit Note Details</div>
                <TagComponent
                  label={status}
                  customBg={status === 'approved' ? '#D7FFD3' : '#FBFAE5'}
                  customTextColor='#636363'
                  showIndicator
                  className='invoice-details-paid-tag'
                />
              </div>
              {/* {downloadInvoiceButton} */}
            </div>
          );
          break;

        case 'sent_to_client':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-details-paid'>
                <div className='invoice-screen-title'>Debit Note Details</div>
                <TagComponent
                  label={paymentStatus}
                  customBg={paymentStatus === 'paid' ? '#D7FFD3' : '#FBFAE5'}
                  customTextColor='#636363'
                  showIndicator
                  className='invoice-details-paid-tag'
                />
              </div>
              {downloadDebitNoteButton}
            </div>
          );
          break;

        case 'overdue':
          headerContent = (
            <div className='details-screen-header invoice-details-header'>
              <div className='invoice-screen-title'>Debit Note Details</div>
              {downloadDebitNoteButton}
            </div>
          );
          break;

        case 'cancelled':
          headerContent = (
            <div className='details-screen-header invoice-details-header-cancelled'>
              <div className='invoice-screen-cancelled-header-left'>
                <div className='invoice-details-header-wrapper'>
                  <div className='details-screen-title'>Debit Note Details</div>
                  <TagComponent
                    label='Cancelled'
                    customBg='#FBE5E5'
                    customTextColor='#636363'
                    showIndicator
                    className='invoice-details-tag'
                  />
                </div>
                {reasonForCancellation && <div>{reasonForCancellation}</div>}
              </div>
              {status === 'cancelled' && debitNoteDetails?.invoicePDF && (
                <>{downloadDebitNoteButton}</>
              )}
            </div>
          );
          break;

        default:
          break;
      }

      return headerContent;
    },
    [
      reasonForCancellation,
      handleDebitNoteDownload,
      isDebitNoteDownloadLoading,
      debitNoteDetails,
    ],
  );

  const DebitNoteDetailsFooter = useCallback(
    (status: string, paymentStatus: string) => {
      const isApproved = status === 'approved';
      const isOverdue = status === 'overdue';
      const isSentToClient = status === 'sent_to_client';
      const isPaid = paymentStatus === 'paid';
      const showCancelInvoiceButton =
        (isSentToClient || isApproved) &&
        !debitNoteGeneratedDateExpiry &&
        !isPaid;
      const showMarkAsPaidButton = !isPaid && !isApproved;

      return (
        <div className='invoice-screen-footer'>
          {(isApproved || isOverdue || isSentToClient) && (
            <>
              <div className='invoice-screen-footer-buttons'>
                {showCancelInvoiceButton && (
                  <ButtonComponent
                    color='error'
                    variant='outlined'
                    className='invoice-screen-footer-button'
                    onClick={() => setOpenCancelDebitNoteModel(true)}
                  >
                    Cancel Debit Note
                  </ButtonComponent>
                )}
                {showMarkAsPaidButton && (
                  <ButtonComponent
                    color='primary'
                    variant='contained'
                    className='invoice-screen-footer-button'
                    onClick={markAsPaidConfirmation}
                  >
                    Mark as paid
                  </ButtonComponent>
                )}
              </div>
            </>
          )}
        </div>
      );
    },
    [markAsPaidConfirmation, debitNoteGeneratedDateExpiry],
  );

  const entityAbbreviation = useCallback(() => {
    const name = debitNoteDetails?.billingEntity?.name || '';
    const formattedName = name
      .split(' ')
      .filter(Boolean)
      .map((word: string) => word[0])
      .join('');
    return formattedName.toUpperCase();
  }, [debitNoteDetails]);

  const entityAddress = useCallback(() => {
    if (debitNoteDetails?.billingEntity?.addressDetails) {
      const addressDetails = debitNoteDetails?.billingEntity?.addressDetails;
      const address = [
        addressDetails.addressLine1,
        addressDetails.city,
        addressDetails.state,
        addressDetails.country,
        addressDetails.pincode,
      ]
        .filter((part) => part)
        .join(', ');

      return address;
    }
    return '';
  }, [debitNoteDetails]);

  const totalInvoiceItemAmount = useCallback((list: any[]) => {
    const totalAmount =
      list?.reduce((total: any, item: any) => {
        return total + parseInt(item?.amount || 0);
      }, 0) || 0;
    const result = CommonService.convertNumberToWords(totalAmount);
    return result;
  }, []);

  return (
    <div className='invoice-details-screen'>
      {isDebitNoteHandlerLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type='spinner' />
        </div>
      ) : (
        <>
          {isDebitNoteDetailsLoading && (
            <div className='h-v-center'>
              <LoaderComponent type='spinner' />
            </div>
          )}
          {isDebitNoteDetailsLoadingFailed && (
            <div className='h-v-center'>
              <StatusCardComponent title='Failed to Load' />
            </div>
          )}
          {isDebitNoteDetailsLoaded && (
            <div className='details-screen'>
              <CardComponent className='details-screen invoice-details-content-wrapper '>
                {DebitNoteDetailsHeader(
                  debitNoteDetails?.status,
                  debitNoteDetails?.paymentStatus,
                )}
                <HorizontalLineComponent className='mrg-bottom-10' />
                <div>
                  <CardComponent className='debit-note-header'>
                    Debit Note
                  </CardComponent>
                  <div
                    id='invoice-content'
                    className='details-screen-body invoice-details-body'
                  >
                    <div className='invoice-details-top-section'>
                      <div>
                        <img
                          src={ImageConfig.NewPhoenixLogo}
                          alt='phoenix-logo'
                          className='inovoice-details-image'
                        />
                        <div className='invoice-details-title'>
                          <span>
                            {debitNoteDetails?.billingEntity?.gstin ? (
                              <>{debitNoteDetails?.billingEntity?.gstin}</>
                            ) : (
                              <> -- </>
                            )}
                          </span>
                          <span>
                            {debitNoteDetails?.billingEntity?.name ? (
                              <>{debitNoteDetails?.billingEntity?.name}</>
                            ) : (
                              <>--</>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <CardComponent className='invoice-details-card'>
                      <div className='d-flex-between'>
                        <div>
                          <FormControlLabelComponent
                            label='Billing From'
                            level={5}
                            isBold
                          />
                          <div className='utility-entity-wrapper'>
                            <div className='utility-entity-name'>
                              {debitNoteDetails?.billingEntity?.name ? (
                                <>{debitNoteDetails?.billingEntity?.name}</>
                              ) : (
                                <>--</>
                              )}
                            </div>
                            <div>
                              {debitNoteDetails?.billingEntity
                                ?.addressDetails ? (
                                <>{entityAddress()}</>
                              ) : (
                                <>--</>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='mrg-left-60'>
                          <FormControlLabelComponent
                            label='Debit Note'
                            level={5}
                          />
                          <div className='text-lite'>
                            <div>
                              {entityAbbreviation()}/DN/
                              {moment(debitNoteDetails?.generatedDate).format(
                                'MMM',
                              )}
                            </div>
                            <div className='mrg-top-5'>
                              {moment(debitNoteDetails?.generatedDate).format(
                                'DD-MM-YYYY',
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardComponent>

                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label='Party Details'
                          isBold={true}
                          level={5}
                        />
                        <div className={'ts-row mrg-top-20'}>
                          <div className={'ts-col-lg-6'}>
                            <DataLabelValueComponent label={'Billed To:'}>
                              <div className='invoice-screen-party-detail'>
                                <p>
                                  GSTIN :{' '}
                                  {addressDetails?.billingPreferences
                                    ?.gstNumber || '--'}
                                </p>
                                <p>
                                  PAN :
                                  {addressDetails?.billingPreferences
                                    ?.panNumber || '--'}
                                </p>
                                <p className='invoice-text-overflow'>
                                  {addressDetails?.billingAddress?.addressLine1}
                                  , {addressDetails?.billingAddress?.city}
                                </p>
                                <p>
                                  {addressDetails?.billingAddress?.state},{' '}
                                  {addressDetails?.billingAddress?.pincode}
                                </p>
                              </div>
                            </DataLabelValueComponent>
                          </div>
                          <div className={'ts-col-lg-6'}>
                            <DataLabelValueComponent label={'Place of Service'}>
                              <div className='invoice-screen-party-detail'>
                                <p>
                                  {debitNoteDetails?.propertyDetails?.name ||
                                    '--'}
                                </p>
                                <p className='invoice-text-overflow'>
                                  {
                                    debitNoteDetails?.propertyDetails
                                      ?.addressDetails?.addressLine1
                                  }
                                  ,{' '}
                                  {
                                    debitNoteDetails?.propertyDetails
                                      ?.addressDetails?.location
                                  }
                                </p>
                                <p>
                                  {
                                    debitNoteDetails?.propertyDetails
                                      ?.addressDetails?.city
                                  }
                                </p>
                                <p>
                                  {
                                    debitNoteDetails?.propertyDetails
                                      ?.addressDetails?.state
                                  }
                                  ,{' '}
                                  {
                                    debitNoteDetails?.propertyDetails
                                      ?.addressDetails?.pincode
                                  }
                                </p>
                              </div>
                            </DataLabelValueComponent>
                          </div>
                        </div>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <div>
                        <FormControlLabelComponent
                          label={CommonService.capitalizeFirstLetterOfEachWord(
                            debitNoteDetails?.invoiceName || '',
                          )}
                          isBold
                          level={5}
                        />
                        <div className='utility-invoice-service-desc'>
                          {debitNoteDetails?.descriptionOfService ? (
                            <>{debitNoteDetails?.descriptionOfService}</>
                          ) : (
                            <>--</>
                          )}
                        </div>
                        <CardComponent
                          className={'mrg-top-20 e-invoice-details-card'}
                        >
                          <TableComponent
                            noDataText={'No debit note details available.'}
                            className={'mrg-top-15 e-invoice-details-table'}
                            autoHeight={true}
                            columns={UtilityInvoiceItemListTable}
                            data={debitNoteDetails?.invoiceItemList}
                          />
                        </CardComponent>
                        <div className='italic-text-lite text-right'>
                          {totalInvoiceItemAmount(
                            debitNoteDetails?.invoiceItemList,
                          )}
                        </div>
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <FormControlLabelComponent
                        label='Bank details for remittance'
                        isBold
                        level={5}
                      />
                      <CardComponent className='mrg-top-20'>
                        <div className='bank-details'>
                          <InvoiceInfoBoxComponent
                            label={'Account Name'}
                            value={
                              debitNoteDetails?.billingEntity?.name ? (
                                <>{debitNoteDetails?.billingEntity?.name}</>
                              ) : (
                                <>--</>
                              )
                            }
                          />
                          <InvoiceInfoBoxComponent
                            label={'Bank Name'}
                            value={'Axis Bank Ltd'}
                          />
                          <InvoiceInfoBoxComponent
                            label={'Account Number'}
                            value={'9273819382571231'}
                          />
                          <InvoiceInfoBoxComponent
                            label={'RTGS Code'}
                            value={'YSU83838234'}
                          />
                          <InvoiceInfoBoxComponent
                            label={'Branch & Address'}
                            value={'Madhapur & Hyderabad'}
                          />
                        </div>
                      </CardComponent>
                      <div className='utility-note'>
                        Note: Interest @18% per annum shall be levied for the
                        payments made after 07 days from invoice.
                      </div>
                    </CardComponent>
                    <CardComponent className='invoice-details-card'>
                      <FormControlLabelComponent
                        label='Phoenix Tech Zone Private Limited'
                        isBold
                        level={5}
                      />
                      <div className='text-lite'>
                        UNIT NO.201 & 202, 2ND FLOOR, YS VIVEKA ENCLAVE, PLOT
                        NO.21-23, SURVEY NUMBER 1, KHAJAGUDA, HYDERABAD,
                        TELANGANA-500032, INDIA T:+91-40-23553351/61,
                        F:+91-40-23556660, WWW.PHOENIXINDIA.NET, CIN
                        NO.U45209TG2014PTC092624
                      </div>
                    </CardComponent>
                    <div className='utility-note mrg-bottom-20'>
                      Note: This is a electronically generated invoice thus does
                      not contain any digital signature generated date :{' '}
                      {moment(debitNoteDetails?.generatedDate).format(
                        'DD-MM-YYYY | hh:mm A',
                      )}
                    </div>
                  </div>
                  <div className='invoice-details-attachment-wrapper'>
                    {debitNoteDetails?.attachments?.length > 0 && (
                      <CardComponent className='invoice-details-attachment-card'>
                        <div>
                          <FormControlLabelComponent
                            label='Attachments'
                            isBold={true}
                            level={5}
                          />
                        </div>
                        <div className='invoice-attachments'>
                          {debitNoteDetails?.attachments?.map((file: any) => (
                            <div className='invoice-pdf'>
                              <div
                                key={file.url}
                                className='invoice-details-attachment'
                              >
                                <img
                                  src={ImageConfig.PDFIcon}
                                  alt='pdf-icon'
                                  width={30}
                                  height={30}
                                />
                                <div>{file.name}</div>
                                <div
                                  onClick={() =>
                                    CommonService.downloadFile(
                                      file.url,
                                      file.name,
                                    )
                                  }
                                  className='invoice-pdf-download'
                                >
                                  <ImageConfig.DownloadInvoiceIcon />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardComponent>
                    )}
                  </div>
                  <div className='invoice-details-attachment-wrapper'>
                    {debitNoteDetails?.receiptFile?.url && (
                      <CardComponent className='invoice-details-attachment-card'>
                        <div>
                          <FormControlLabelComponent
                            label='Receipt File'
                            isBold={true}
                            level={5}
                          />
                        </div>
                        <div className='invoice-attachments'>
                          <div className='invoice-attachment-image-wrapper'>
                            <img
                              src={debitNoteDetails?.receiptFile?.url}
                              alt={debitNoteDetails?.receiptFile?.filename}
                            />
                            <div className='invoice-attachment-image-button'>
                              <div
                                onClick={() =>
                                  CommonService.downloadFile(
                                    debitNoteDetails?.receiptFile?.url,
                                    debitNoteDetails?.receiptFile?.filename,
                                  )
                                }
                                className='invoice-pdf-download'
                              >
                                <ImageConfig.DownloadInvoiceIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardComponent>
                    )}
                  </div>
                </div>
              </CardComponent>
              {DebitNoteDetailsFooter(
                debitNoteDetails?.status,
                debitNoteDetails?.paymentStatus,
              )}

              {/* MODEL FOR CANCELLATION */}
              <ModalComponent
                className='confirmation-modal'
                isOpen={openCancelDebitNoteModel}
                onClose={() => setOpenCancelDebitNoteModel(false)}
                id='confirmation-popup'
                closeOnEsc={true}
                closeOnBackDropClick={true}
                direction='down'
                size='xs'
                showClose={false}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => setOpenCancelDebitNoteModel(false)}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='error'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={
                        reasonForCancellation === undefined ||
                        reasonForCancellation.length === 0
                      }
                      onClick={cancelDebitNoteHandler}
                    >
                      Confirm
                    </ButtonComponent>
                  </>
                }
              >
                <div className='confirmation-dialog-container'>
                  <>
                    {
                      <div className='confirmation-dialog-image-container'>
                        <div className={'confirmation-dialog-image'}>
                          <img
                            src={ImageConfig.CancelIcon}
                            alt={'CancelIcon'}
                            style={{ height: 40, width: 37 }}
                          />
                        </div>
                      </div>
                    }
                    <div className='confirmation-dialog-content-wrapper'>
                      <div className={'confirmation-dialog-title'}>
                        Cancel Debit Note
                      </div>
                      <div className='confirmation-dialog-sub-description'>
                        Are you sure you want to cancel the debit note
                        generated?
                      </div>
                      <div>
                        <TextAreaComponent
                          placeholder='Reason for cancellation*'
                          fullWidth={true}
                          maxlength={500}
                          value={reasonForCancellation}
                          onChange={(value) => {
                            setReasonForCancellation(value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                </div>
              </ModalComponent>
              {/* MODEL FOR MARK AS PAID */}
              <ModalComponent
                isOpen={openMarkAsPaidModal}
                onClose={() => setOpenMarkAsPaidModal(false)}
                closeOnEsc={true}
                closeOnBackDropClick={true}
                direction='down'
                size='lg'
                showClose={false}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => setOpenMarkAsPaidModal(false)}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='primary'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={markAsPaidFile.length === 0}
                      onClick={markAsPaidHandler}
                    >
                      Save
                    </ButtonComponent>
                  </>
                }
              >
                <div>
                  <FormControlLabelComponent
                    label='Upload Transaction Details'
                    isBold={true}
                    level={5}
                    className='mrg-bottom-10'
                  />
                  <div className='invoice-details-file-upload'>
                    {markAsPaidFile ? (
                      <FilePreviewThumbnailComponent
                        file={markAsPaidFile}
                        onRemove={() => setMarkAsPaidFile('')}
                      />
                    ) : (
                      <FilePickerComponent
                        maxFileSizeInMB={3}
                        maxFileCount={1}
                        height={'402px'}
                        uploadDescription='(upload only one file)'
                        acceptedFileTypes={['png', 'jpeg', 'jpg']}
                        showDropZone={true}
                        acceptedFilesText='.jpg, .png, .jpeg max file size 3.0MB'
                        onFilesDrop={(acceptedFile, rejectedFile) => {
                          setMarkAsPaidFile(acceptedFile[0]);
                        }}
                      />
                    )}
                  </div>
                </div>
              </ModalComponent>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DebitNoteDetailsComponent;
