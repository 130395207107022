import { IActionModel } from '../../shared/models/action.model';
import { IUser } from '../../shared/models/user.model';
import {
  GET_ALL_ENTITY_LIST,
  // GET_ENTITY_FM_LIST,
  GET_ENTITY_LIST_LITE,
  SET_ALL_ENTITY_LIST,
  // SET_ENTITY_FM_LIST,
  SET_ENTITY_LIST_LITE,
} from '../actions/entity.action';

export interface IEntityReducerState {
  entityList: any[];
  allEntityList: any[];
  isEntityListLoading: boolean;
  isEntityListLoaded: boolean;
  entityFMsList: IUser[];
  isEntityFMListLoading: boolean;
  isEntityFMListLoaded: boolean;
}

const initialData: IEntityReducerState = {
  entityList: [],
  allEntityList: [],
  isEntityListLoading: false,
  isEntityListLoaded: false,
  entityFMsList: [],
  isEntityFMListLoading: false,
  isEntityFMListLoaded: false,
};

const EntityReducer = (
  state = initialData,
  action: IActionModel,
): IEntityReducerState => {
  switch (action.type) {
    case GET_ENTITY_LIST_LITE:
      state = {
        ...state,
        isEntityListLoading: true,
        isEntityListLoaded: false,
      };
      return state;
    case SET_ENTITY_LIST_LITE:
      state = {
        ...state,
        isEntityListLoading: false,
        isEntityListLoaded: true,
        entityList: action.payload.entityList,
      };
      return state;
    case GET_ALL_ENTITY_LIST:
      state = {
        ...state,
      };
      return state;
    case SET_ALL_ENTITY_LIST:
      state = {
        ...state,
        allEntityList: action.payload.allEntityList,
      };
      return state;
    // case GET_ENTITY_FM_LIST:
    //     state = {
    //         ...state,
    //         isEntityFMListLoading: true,
    //         isEntityFMListLoaded: false,
    //     };
    //     return state;
    // case SET_ENTITY_FM_LIST:
    //     state = {
    //         ...state,
    //         isEntityFMListLoading: false,
    //         isEntityFMListLoaded: true,
    //         entityFMsList: action.payload.entityFMsList
    //     };
    //     return state;
    default:
      return state;
  }
};

export default EntityReducer;
