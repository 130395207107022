import { useCallback, useEffect, useState } from 'react';
import './InvoiceVerifyGSTComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { CommonService } from '../../../shared/services';
import CardComponent from '../../../shared/components/card/CardComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import InvoiceInfoBoxComponent from '../../../shared/components/invoice-info-box/InvoiceInfoBoxComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { IRootReducerState } from '../../../store/reducers';
import { ImageConfig } from '../../../constants';
import { INVOICE_LIST } from '../../../constants/RoutesConfig';

interface InvoiceVerifyGSTComponentProps {}

const InvoiceVerifyGSTComponent = (props: InvoiceVerifyGSTComponentProps) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInvoiceDetailsLoading, setIsInvoiceDetailsLoading] =
    useState<boolean>(false);
  const [isInvoiceDetailsLoaded, setIsInvoiceDetailsLoaded] =
    useState<boolean>(false);
  const [isInvoiceDetailsLoadingFailed, setIsInvoiceDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isInvoiceVerifying, setIsInvoiceVerifying] = useState<boolean>(false);
  const [isInvoiceVerified, setIsInvoiceVerified] = useState<boolean>(false);
  const [isInvoiceVerifyingFailed, setIsInvoiceVerifyingFailed] =
    useState<boolean>(false);
  const [gstDetails, setGstDetails] = useState<any>(undefined);
  const [productDetails, setProductDetails] = useState<any>(undefined);
  const { onNavigateBack } = useSelector(
    (state: IRootReducerState) => state.navigation,
  );

  useEffect(() => {
    dispatch(
      setCurrentNavParams('Invoice Verification', null, () => {
        navigate(CommonService._routeConfig.InvoiceDetailsRoute(invoiceId));
      }),
    );
  }, [dispatch, navigate, invoiceId]);

  const handleNavigateBack = useCallback(() => {
    if (onNavigateBack) {
      onNavigateBack();
    }
  }, [onNavigateBack]);

  const getInvoiceDetails = useCallback(() => {
    setIsInvoiceDetailsLoading(true);
    const payload: any = {};
    if (invoiceId) {
      CommonService._invoice
        .GetInvoiceDetailsAPICall(invoiceId, payload)
        .then((response: IAPIResponseType<any>) => {
          const invoiceDetails = response?.data;
          setGstDetails(invoiceDetails?.gstData);
          setProductDetails(invoiceDetails?.gstData?.ItemList[0]);
          setIsInvoiceDetailsLoaded(true);
          setIsInvoiceDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsInvoiceDetailsLoaded(false);
          setIsInvoiceDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsInvoiceDetailsLoading(false);
        });
    }
  }, [invoiceId]);

  useEffect(() => {
    getInvoiceDetails();
  }, [getInvoiceDetails]);

  const handleInvoiceVerification = useCallback(() => {
    setIsInvoiceVerifying(true);
    if (invoiceId) {
      CommonService._invoice
        .approveInvoiceAPICall(invoiceId)
        .then((response: IAPIResponseType<any>) => {
          setIsInvoiceVerified(true);
          setIsInvoiceVerifyingFailed(false);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
          setIsInvoiceVerifyingFailed(true);
          setIsInvoiceVerified(false);
        })
        .finally(() => {
          setIsInvoiceVerifying(false);
          setIsInvoiceVerifyingFailed(false);
        });
    }
  }, [invoiceId]);

  const invoiceVerificationConfirmation = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: 'Approve Invoice',
      image: ImageConfig.TickMarkIcon,
      confirmationDescription: 'Are you sure you want to approve GST Invoice ?',
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: 'primary',
        text: 'Confirm',
        variant: 'contained',
      },
    }).then(() => {
      handleInvoiceVerification();
    });
  }, [handleInvoiceVerification]);

  const Header = () => {
    return (
      <div className='invoice-verify-gst-header'>
        <div className='invoice-verify-gst-header-key'>Key</div>
        <div className='invoice-verify-gst-header-value'>Value</div>
      </div>
    );
  };

  return (
    <div className='invoice-verify-component'>
      {isInvoiceVerifying && (
        <div className='invoice-verify-loading-wrapper'>
          <span className='invoice-verify-loading-text'>
            Please wait as we are approving your GST Invoice...
          </span>
        </div>
      )}
      {isInvoiceVerified && (
        <div className='invoice-verify-loading-wrapper'>
          <span className='invoice-verify-loading-text'>
            Yayy!! the GST invoice is successfully approved.
          </span>
          <LinkComponent
            route={CommonService._routeConfig.InvoiceDetailsRoute(invoiceId)}
          >
            <ButtonComponent color='secondary' variant='contained'>
              View Invoice
            </ButtonComponent>
          </LinkComponent>
        </div>
      )}
      {isInvoiceVerifyingFailed && (
        <div className='invoice-verify-loading-wrapper'>
          <StatusCardComponent title='Invoice Verification Failed !!' />
          <Link to={INVOICE_LIST}>
            <ButtonComponent color='secondary'>Go Back to home</ButtonComponent>
          </Link>
        </div>
      )}

      {!isInvoiceVerifying &&
        !isInvoiceVerifyingFailed &&
        !isInvoiceVerified && (
          <>
            {isInvoiceDetailsLoading && (
              <div className='h-v-center'>
                <LoaderComponent type='spinner' />
              </div>
            )}
            {isInvoiceDetailsLoadingFailed && (
              <div className='h-v-center'>
                <StatusCardComponent title='Failed to Load' />
              </div>
            )}
            {isInvoiceDetailsLoaded && (
              <>
                <CardComponent>
                  <div className='invoice-verify-title'>Verify GST Data</div>
                  <div className='details-screen-body'>
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Supply Type Code'}
                      value={gstDetails?.TranDtls?.SupTyp}
                    />

                    <FormControlLabelComponent
                      label='Document Details'
                      level={4}
                      isBold
                    />
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Document Type'}
                      value={gstDetails?.DocDtls?.Typ}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Document Number'}
                      value={gstDetails?.DocDtls?.No}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Document Date(DD/MM/YYY)'}
                      value={gstDetails?.DocDtls?.Dt}
                    />

                    <FormControlLabelComponent
                      label='Buyer Details'
                      level={4}
                      isBold
                    />
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Buyer GSTIN'}
                      value={gstDetails?.BuyerDtls?.Gstin}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Legal Name'}
                      value={gstDetails?.BuyerDtls?.LglNm}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Trade Name'}
                      value={gstDetails?.BuyerDtls?.TrdNm}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer POS'}
                      value={gstDetails?.BuyerDtls?.Pos}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Addr1'}
                      required
                      value={gstDetails?.BuyerDtls?.Addr1}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Addr2'}
                      value={gstDetails?.BuyerDtls?.Addr2}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Location'}
                      value={gstDetails?.BuyerDtls?.Loc}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer Pin Code'}
                      value={gstDetails?.BuyerDtls?.Pin}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Buyer State Code'}
                      value={gstDetails?.BuyerDtls?.Stcd}
                    />

                    <FormControlLabelComponent
                      label='Seller Details'
                      level={4}
                      isBold
                    />
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Seller GSTIN'}
                      value={gstDetails?.SellerDtls?.Gstin}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Legal Name'}
                      value={gstDetails?.SellerDtls?.LglNm}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Trade Name'}
                      value={gstDetails?.SellerDtls?.TrdNm}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller POS'}
                      value={gstDetails?.SellerDtls?.Pos}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Addr1'}
                      required
                      value={gstDetails?.SellerDtls?.Addr1}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Addr2'}
                      value={gstDetails?.SellerDtls?.Addr2}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Location'}
                      value={gstDetails?.SellerDtls?.Loc}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller Pin Code'}
                      value={gstDetails?.SellerDtls?.Pin}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Seller State Code'}
                      value={gstDetails?.SellerDtls?.Stcd}
                    />

                    <FormControlLabelComponent
                      label='Product Details'
                      level={4}
                      isBold
                    />
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Sl.No'}
                      required
                      value={productDetails?.SlNo}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Product Description'}
                      value={productDetails?.PrdDesc}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Is Service'}
                      required
                      value={productDetails?.IsServc === 'Y' ? 'Yes' : 'No'}
                    />
                    <InvoiceInfoBoxComponent
                      label={'HSN code'}
                      required
                      value={productDetails?.HsnCd}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Quantity'}
                      value={productDetails?.Qty}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Free Quantity'}
                      value={productDetails?.FreeQty}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Unit'}
                      value={productDetails?.Unit}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'Unit Price'}
                      required
                      value={productDetails?.UnitPrice}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Discount'}
                      value={productDetails?.Discount}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Taxable Value'}
                      value={productDetails?.AssAmt}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'GST Rate(%)'}
                      value={productDetails?.GstRt || 0 + '%'}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'Sgst Amt(Rs)'}
                      value={productDetails?.SgstAmt}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Cgst Amt(Rs)'}
                      value={productDetails?.CgstAmt}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Igst Amt(Rs)'}
                      value={productDetails?.IgstAmt}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Cess Rate(%)'}
                      value={productDetails?.CesRt || 0 + '%'}
                    />
                    <InvoiceInfoBoxComponent
                      label={'Item Total'}
                      value={productDetails?.TotItemVal}
                      required
                    />
                    <InvoiceInfoBoxComponent
                      label={'Total Invoice value'}
                      value={gstDetails?.ValDtls?.TotInvVal}
                      required
                    />

                    <FormControlLabelComponent
                      label='Value Details'
                      level={4}
                      isBold
                    />
                    <Header />
                    <InvoiceInfoBoxComponent
                      label={'Total Invoice value'}
                      value={gstDetails?.ValDtls?.TotInvVal}
                      required
                    />
                  </div>
                </CardComponent>
                <div className='invoice-verify-button-wrapper'>
                  <ButtonComponent
                    onClick={handleNavigateBack}
                    variant='outlined'
                    color='default'
                  >
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    type='submit'
                    onClick={invoiceVerificationConfirmation}
                  >
                    Verify Details and Approve GST invoice
                  </ButtonComponent>
                </div>
              </>
            )}
          </>
        )}
    </div>
  );
};

export default InvoiceVerifyGSTComponent;
