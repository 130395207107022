import './propertyDetailsScreen.scss';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { CommonService } from '../../../shared/services';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { ITableColumn } from '../../../shared/models/table.model';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { useDispatch } from 'react-redux';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { getPropertyListLite } from '../../../store/actions/property.action';
import AccordionComponent from '../../../shared/components/accordion/AccordionComponent';
import IconButtonComponent from '../../../shared/components/icon-button/IconButtonComponent';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import TableComponent from '../../../shared/components/table/TableComponent';

interface PropertyDetailsScreenComponentComponentProps {}

const PropertyDetailsSteps = [
  {
    title: 'Tower Details',
    id: 'towerDetails',
  },
  {
    title: 'Tenants',
    id: 'tenants',
  },
  {
    title: 'Amenities',
    id: 'amenities',
  },
];

const TenantsListColumn: ITableColumn[] = [
  {
    title: 'Tenant ID',
    key: 'tenantId',
    dataIndex: 'name',
    // fixed: 'left',
    width: 150,
    render: (item: any) => {
      return (
        <div>
          {item?.tenantCode ? <div>{item?.tenantCode}</div> : <div>-</div>}
        </div>
      );
    },
  },
  {
    title: 'Tenant Company',
    key: 'name',
    dataIndex: 'name',
    // fixed: 'left',
    // sortable: true,
    width: 150,
    render: (item: any) => {
      return <div>{item?.name ? <div>{item?.name}</div> : <div>-</div>}</div>;
    },
  },
  {
    title: 'Tenant Admin',
    key: 'admin',
    dataIndex: 'name',
    // fixed: 'left',
    // sortable: true,
    width: 200,
    render: (item: any) => {
      return (
        <div>
          {/*{item?.admin ? <div>{item?.admin?.name}</div> : <div>-</div>}*/}
          {(item?.adminDetails || []).length > 0 && (
            <ToolTipComponent
              // backgroundColor={'#fff'}
              position={'right'}
              textColor={'#FFFFFF'}
              tooltip={
                <>
                  {item?.adminDetails.map((value1: any, index1: number) => {
                    return (
                      <div
                        className={
                          'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                        }
                        key={index1}
                      >
                        {value1.name}
                      </div>
                    );
                  })}
                </>
              }
            >
              <div className={'d-flex'}>
                <ChipComponent
                  className={'scheduled cursor-pointer'}
                  size={'small'}
                  color={'primary'}
                  label={
                    item?.adminDetails[0].name +
                    ((item?.adminDetails || []).length > 1
                      ? ' (+' + ((item?.adminDetails || []).length - 1) + ')'
                      : '')
                  }
                />
              </div>
            </ToolTipComponent>
          )}
        </div>
      );
    },
  },
  {
    title: 'Tenant Admin Phone No.',
    key: 'phone',
    dataIndex: 'name',
    // fixed: 'left',
    // sortable: true,
    width: 150,
    render: (item: any) => {
      return (
        <div>
          {(item?.adminDetails || []).length > 0 && (
            <ToolTipComponent
              position={'right'}
              textColor={'#FFFFFF'}
              tooltip={
                <>
                  {item?.adminDetails.map((value1: any, index1: number) => {
                    return (
                      <div
                        className={
                          'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                        }
                        key={index1}
                      >
                        {CommonService.convertPhoneNumber(value1.phoneNumber)}
                      </div>
                    );
                  })}
                </>
              }
            >
              <div className={'d-flex'}>
                <ChipComponent
                  className={'scheduled cursor-pointer'}
                  size={'small'}
                  color={'primary'}
                  label={
                    CommonService.convertPhoneNumber(
                      item?.adminDetails[0].phoneNumber,
                    ) +
                    ((item?.adminDetails || []).length > 1
                      ? ' (+' + ((item?.adminDetails || []).length - 1) + ')'
                      : '')
                  }
                />
              </div>
            </ToolTipComponent>
          )}
        </div>
      );
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    width: 200,
    render: (item: any) => {
      return (
        <>
          <TagComponent
            color={CommonService.getTenantStatusTagColor(item?.isActive)}
            showIndicator={true}
            label={item?.isActive === true ? 'Active' : 'Inactive'}
          />
        </>
      );
    },
  },
];

const USER_LIST_MODULE = 'USER_LIST_MODULE';
const PropertyDetailsScreen = (
  props: PropertyDetailsScreenComponentComponentProps,
) => {
  const location = useLocation();
  const { propertyId } = useParams();
  const [currentTab, setCurrentTab] = useState<
    'towerDetails' | 'tenants' | 'amenities'
  >('towerDetails');
  const [searchParams] = useSearchParams();
  const [propertyDetails, setPropertyDetails] = useState<any>(null);
  const [isPropertyDetailsLoading, setIsPropertyDetailsLoading] =
    useState<boolean>(false);
  const [isPropertyDetailsLoadingFailed, setIsPropertyDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isPropertyDetailsLoaded, setIsPropertyDetailsLoaded] =
    useState<boolean>(false);
  const [isTowersListLoading, setIsTowersListLoading] =
    useState<boolean>(false);
  const [propertyTowerList, setPropertyTowerList] = useState<any>(null);
  const [towerStats, setTowerStats] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentTableOption, setCurrentTableOption] = useState<any>({});
  const entityId = location?.state?.entityId;

  // useEffect(() => {
  //   dispatch(
  //     setCurrentNavParams('Entity Details', null, () => {
  //       navigate(CommonService._routeConfig.EntityDetailsRoute('id'));
  //     }),
  //   );
  // }, []);

  const TowerTab = useCallback(
    (tower: any) => [
      {
        title: 'Office Floors',
        id: 'officeFloors',
        badge: {
          text: tower?.officeFloors?.length || '0',
          color: 'primary',
        },
      },
      {
        title: 'Basements',
        id: 'basements',
        badge: {
          text: tower?.basements?.length || '0',
          color: 'primary',
        },
      },
    ],
    [],
  );

  const TowerColumns = useMemo(
    () =>
      (selectedTable: any): ITableColumn[] =>
        [
          {
            title: selectedTable === 'basements' ? 'Basement' : 'Floor',
            key: 'floorNumber',
            dataIndex: 'floorNumber',
            width: 100,
            render: (item: any) => {
              return (
                <>
                  {selectedTable === 'basements'
                    ? item?.basementTitle
                    : item?.floorTitle}
                </>
              );
            },
          },
          {
            title:
              selectedTable === 'basements'
                ? 'Total leasable area of the basements'
                : 'Total leasable area of the floor',
            key: 'leasableArea',
            dataIndex: 'leasableArea',
            width: 250,
            render: (item: any) => {
              return <>{item?.leasableArea | 0} sqft</>;
            },
          },
          {
            title: 'leased Area',
            key: 'occupiedArea',
            dataIndex: 'occupiedArea',
            render: (item: any) => {
              return <>{item?.occupiedArea | 0} sqft</>;
            },
          },
          {
            title:
              selectedTable === 'basements'
                ? 'Vacant Area in the Basement'
                : 'Vacant Area in the Floor',
            key: 'vacantArea',
            dataIndex: 'vacantArea',
            render: (item: any) => {
              return <>{item?.vacantArea | 0} sqft</>;
            },
          },
        ],
    [],
  );

  const AmenityListColumn: ITableColumn[] = [
    {
      title: 'Amenity Name',
      key: 'name',
      dataIndex: 'name',
      render: (item: any) => {
        return <div className={'amenity-name'}>{item?.name || 'N/A'}</div>;
      },
    },
    {
      title: 'Amenity Type',
      key: 'amenity-type',
      dataIndex: 'Amenity Type',
      render: (item: any) => {
        return <>{item?.type || '-'}</>;
      },
    },
    {
      title: 'Amenity Admin',
      key: 'amenity-admin',
      dataIndex: 'Amenity Admin',
      render: (item: any) => {
        return (
          <div>
            {(item?.admindetails || []).length > 0 ? (
              <ToolTipComponent
                position={'right'}
                textColor={'#FFFFFF'}
                tooltip={
                  <>
                    {item?.admindetails?.map((value1: any, index1: number) => {
                      return (
                        <div
                          className={
                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                          }
                          key={index1}
                        >
                          {value1.adminName}
                        </div>
                      );
                    })}
                  </>
                }
              >
                <div className={'d-flex'}>
                  <ChipComponent
                    className={'scheduled cursor-pointer'}
                    size={'small'}
                    color={'primary'}
                    label={
                      item?.admindetails[0].adminName +
                      ((item?.admindetails || []).length > 1
                        ? ' (+' + ((item?.admindetails || []).length - 1) + ')'
                        : '')
                    }
                  />
                </div>
              </ToolTipComponent>
            ) : (
              'N/A'
            )}
          </div>
        );
      },
    },
    {
      title: 'Phone Number',
      key: 'phone',
      dataIndex: 'phone',
      width: 140,
      render: (item: any) => {
        return (
          <div>
            {(item?.admindetails || []).length > 0 ? (
              <ToolTipComponent
                position={'right'}
                textColor={'#FFFFFF'}
                tooltip={
                  <>
                    {item?.admindetails.map((value1: any, index1: number) => {
                      return (
                        <div
                          className={
                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                          }
                          key={index1}
                        >
                          {CommonService.convertPhoneNumber(value1.phoneNumber)}
                        </div>
                      );
                    })}
                  </>
                }
              >
                <div className={'d-flex'}>
                  <ChipComponent
                    className={'scheduled cursor-pointer'}
                    size={'small'}
                    color={'primary'}
                    label={
                      CommonService.convertPhoneNumber(
                        item?.admindetails[0].phoneNumber,
                      ) +
                      ((item?.admindetails || []).length > 1
                        ? ' (+' + ((item?.admindetails || []).length - 1) + ')'
                        : '')
                    }
                  />
                </div>
              </ToolTipComponent>
            ) : (
              'N/A'
            )}
          </div>
        );
      },
    },

    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: (item: any) => {
        return (
          <div>
            <TagComponent
              color={item?.isActive ? 'success' : 'danger'}
              showIndicator={true}
              label={item?.isActive ? 'Active' : 'Inactive'}
            />
          </div>
        );
      },
    },
    // {
    //     title: "Action",
    //     dataIndex: "actions",
    //     key: "actions",
    //     align: 'center',
    //     fixed: 'right',
    //     width: 70,
    //     render: (item: any) => {
    //         return <>
    //             <LinkComponent
    //                 route={CommonService._routeConfig.AmenityViewRoute(item._id)}>
    //                 {/*<div onClick={() => {dispatch(setNavigationView(propertyId))}}>*/}
    //                     <ImageConfig.ArrowRightOutlinedIcon/>
    //                 {/*</div>*/}
    //             </LinkComponent>&nbsp;&nbsp;
    //         </>
    //     }
    // }
  ];

  const [facilityFilterData] = useState({
    roleCode: 'fm',
    propertyId: propertyId,
  });

  const deleteFM = useCallback((item: any) => {
    CommonService._user
      .deleteUserAPICall(item._id)
      .then((response: IAPIResponseType<any>) => {
        CommonService._alert.showToast(
          response[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
        CommonService._communications.TableWrapperRefreshSubject.next({
          moduleName: USER_LIST_MODULE,
        });
      })
      .catch((error: any) => {
        CommonService._alert.showToast(
          error?.error || 'Error repeating last medical intervention',
          'error',
        );
      });
  }, []);

  const getTowerDetailsHandler = useCallback((propertyId: any) => {
    const payload = {};
    setIsTowersListLoading(true);
    CommonService._tenants
      .GetTowerDetailsAPICall(propertyId, payload)
      .then((response: IAPIResponseType<any>) => {
        const towersObj = response.data;
        setTowerStats({
          totalAvailableAreaInProperty: towersObj?.totalAvailableAreaInProperty,
          occupiedBasementsCount: towersObj?.occupiedBasementsCount,
          vacantBasementsCount: towersObj?.vacantBasementsCount,
          occupiedFloorsCount: towersObj?.occupiedFloorsCount,
          vacantFloorsCount: towersObj?.vacantFloorsCount,
          totalOccupiedAreaInProperty: towersObj?.totalOccupiedAreaInProperty,
          totalOccupiedParkingSlots: towersObj?.totalOccupiedParkingSlots,
          totalAvailableParkingSlots: towersObj?.totalAvailableParkingSlots,
        });
        setPropertyTowerList(towersObj.towers);
      })

      .catch((error: any) => {
        CommonService._alert.showToast(
          error[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      })
      .finally(() => {
        setIsTowersListLoading(false);
        // setSubmitting(false);
      });
  }, []);

  useEffect(() => {
    if (propertyId) {
      getTowerDetailsHandler(propertyId);
    }
  }, [getTowerDetailsHandler, propertyId]);

  const handleFacilityManagerDelete = useCallback(
    (item: any) => {
      CommonService.onConfirm({
        confirmationTitle: 'Remove FM',
        image: ImageConfig.WarnUnLink,
        confirmationDescription:
          'Are you sure you want to remove the FM from the selected property?',
        no: {
          color: 'default',
          text: 'Cancel',
          variant: 'outlined',
        },
        yes: {
          color: 'error',
          text: 'Confirm',
          variant: 'contained',
        },
      }).then((result: any) => {
        deleteFM(item);
      });
    },
    [deleteFM],
  );

  const FacilityManagerList: ITableColumn[] = [
    {
      title: 'Facility Manager',
      key: 'name',
      dataIndex: 'name',
      render: (item: any) => {
        return (
          <>
            {item?.name ? (
              <>{CommonService.capitalizeFirstLetterOfEachWord(item?.name)} </>
            ) : (
              'N/A'
            )}
          </>
        );
      },
    },
    {
      title: 'FM Phone No.',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      render: (item: any) => {
        return (
          <>
            {item?.phoneNumber ? (
              <>{CommonService.convertPhoneNumber(item?.phoneNumber)}</>
            ) : (
              '--'
            )}
          </>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      // align: 'center',
      render: (item: any) => {
        if (item?._id) {
          // return item.isActive ? <ChipComponent color={'success'} label={'Active'}/> :
          //     <ChipComponent color={'error'} label={'Inactive'}/>
          return (
            <IconButtonComponent
              color={'secondary'}
              onClick={() => {
                handleFacilityManagerDelete(item);
              }}
            >
              <ImageConfig.UnLinkIcon />
            </IconButtonComponent>
          );
        }
      },
    },
  ];

  const getPropertyDetails = useCallback(() => {
    setIsPropertyDetailsLoading(true);
    const payload = {};
    if (propertyId) {
      CommonService._property
        .PropertyDetailsAPICall(propertyId, payload)
        .then((response: IAPIResponseType<any>) => {
          setPropertyDetails(response.data);
          setIsPropertyDetailsLoaded(true);
          setIsPropertyDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsPropertyDetailsLoaded(false);
          setIsPropertyDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsPropertyDetailsLoading(false);
        });
    }
  }, [propertyId]);

  useEffect((): void => {
    getPropertyDetails();
  }, [getPropertyDetails]);

  const handleTabChange = useCallback((value: any) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentStep = searchParams.get('currentStep');
    if (currentStep) {
      setCurrentTab(currentStep as 'towerDetails' | 'tenants' | 'amenities');
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('currentStep', currentTab);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  }, [currentTab, location]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = PropertyDetailsSteps.map((item: any) => {
      return item.id;
    });
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'towerDetails';
      }
    } else {
      currentTab = 'towerDetails';
    }
    setCurrentTab(currentTab);
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      setCurrentNavParams('Property Details', null, () => {
        navigate(CommonService._routeConfig.PropertyRoute());
      }),
    );
  }, [dispatch, navigate]);

  useEffect(() => {
    if (entityId) {
      dispatch(
        setCurrentNavParams('Entity Details', null, () => {
          navigate(CommonService._routeConfig.EntityDetailsRoute(entityId));
        }),
      );
    }
  }, [entityId, dispatch, navigate]);

  const handleActivateInactivateProperty = useCallback(() => {
    if (propertyId) {
      CommonService._property
        .TogglePropertyServiceAPICall(propertyId, {})
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          getPropertyDetails();
          dispatch(getPropertyListLite());
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error?.error || 'Error repeating last medical intervention',
            'error',
          );
        });
    }
  }, [propertyId, getPropertyDetails, dispatch]);

  const onPropertyActivateDeactivate = useCallback(() => {
    CommonService.onConfirm({
      confirmationTitle: `${
        propertyDetails?.isActive ? 'Deactivate' : 'Activate'
      }  Property`,
      image: propertyDetails?.isActive
        ? ImageConfig.PropertyDeactivateIcon
        : ImageConfig.PropertyActivateIcon,
      confirmationDescription: `Are you sure you want to ${
        propertyDetails?.isActive ? 'deactivate' : 'activate'
      }  the 
 property?`,
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: 'error',
        text: 'Confirm',
        variant: 'contained',
      },
    }).then((result: any) => {
      handleActivateInactivateProperty();
    });
  }, [handleActivateInactivateProperty, propertyDetails]);

  return (
    <>
      {isPropertyDetailsLoading && (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      )}
      {isPropertyDetailsLoadingFailed && (
        <div className='h-v-center'>
          <StatusCardComponent title={'Failed to Load'} />
        </div>
      )}
      {isPropertyDetailsLoaded && (
        <div className='details-screen'>
          <CardComponent className='property-details-view-wrapper'>
            <div className={'details-screen-header'}>
              <div className='details-screen-title-with-image-wrapper'>
                <div className={'details-screen-image'}>
                  <AvatarComponent
                    type='circle'
                    title={CommonService.getTwoLetterInitials(
                      propertyDetails?.name,
                    )}
                    url={propertyDetails?.profilePic?.url}
                    size={'xl'}
                  />
                </div>
                <div className={'details-screen-title-wrapper'}>
                  <div className={'details-screen-title'}>
                    {CommonService.capitalizeFirstLetterOfEachWord(
                      propertyDetails?.name || '',
                    ) || 'N/A'}
                  </div>
                  <div className={'details-screen-description'}>
                    {propertyDetails?.addressDetails?.addressLine1?.length >
                    50 ? (
                      <ToolTipComponent
                        tooltip={
                          <div>
                            {propertyDetails?.addressDetails?.addressLine1 ||
                              '-'}
                          </div>
                        }
                        showArrow={true}
                        position={'top'}
                      >
                        <div className={'ellipses-for-details-data'}>
                          {propertyDetails?.addressDetails?.addressLine1}
                        </div>
                      </ToolTipComponent>
                    ) : (
                      <div>{propertyDetails?.addressDetails?.addressLine1}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='details-screen-options'>
                <SwitchComponent
                  color={'primary'}
                  onChange={onPropertyActivateDeactivate}
                  checked={propertyDetails?.isActive}
                  label={
                    propertyDetails?.isActive
                      ? 'Deactivate Property'
                      : 'Activate Property'
                  }
                />
                <LinkComponent
                  route={propertyDetails?.addressDetails?.mapLink}
                  behaviour={'redirect'}
                >
                  <ButtonComponent
                    color={'primary'}
                    prefixIcon={<ImageConfig.LocationLinkIcon />}
                    variant='outlined'
                  >
                    View Location
                  </ButtonComponent>
                </LinkComponent>
                <LinkComponent
                  route={
                    CommonService._routeConfig.EditPropertyRoute() +
                    `?propertyId=${propertyId}&step=property-details`
                  }
                >
                  <ButtonComponent color={'primary'}>
                    Edit Property Details
                  </ButtonComponent>
                </LinkComponent>
              </div>
            </div>
            <HorizontalLineComponent className='mrg-top-20 mrg-bottom-20' />
            <>
              <div className={'ts-row'}>
                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'No of Towers'}>
                    {propertyDetails?.campusDetails?.numberOfTowers || '0'}
                  </DataLabelValueComponent>
                </div>
                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'Total Office Floors'}>
                    {propertyDetails?.campusDetails?.numberOfFloors || '0'}
                  </DataLabelValueComponent>
                </div>
                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'Total Basement Floors'}>
                    {propertyDetails?.campusDetails?.numberOfBasements || '0'}
                  </DataLabelValueComponent>
                </div>
                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'Total Parking Slots'}>
                    {propertyDetails?.campusDetails?.totalParkingSlots || '0'}
                  </DataLabelValueComponent>
                </div>
              </div>
              <div className={'ts-row'}>
                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'No of Access Points'}>
                    {propertyDetails?.campusDetails?.numberOfAccessPoints ||
                      '-'}
                  </DataLabelValueComponent>
                </div>

                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'Location'}>
                    {propertyDetails?.addressDetails?.location || '-'}
                  </DataLabelValueComponent>
                </div>

                <div className={'ts-col-lg-3'}>
                  <DataLabelValueComponent label={'Entity'}>
                    {propertyDetails?.billingEntityData?.name || '-'}
                  </DataLabelValueComponent>
                </div>
              </div>
            </>
            <HorizontalLineComponent className='mrg-bottom-20' />
            <>
              <AccordionComponent
                title={`Stats`}
                isExpand={true}
                isAccordionOpen={true}
              >
                <>
                  {/*<FormControlLabelComponent label={'Stats'} level={5}*/}
                  {/*                           isBold={true}/>*/}
                  <div className={'ts-row'}>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Floors Occupied'}>
                        {towerStats?.occupiedFloorsCount || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Vacant Floors'}>
                        {towerStats?.vacantFloorsCount || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Basements Occupied'}>
                        {towerStats?.occupiedBasementsCount || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Vacant Basements'}>
                        {towerStats?.vacantBasementsCount || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Total Area Occupied'}>
                        {towerStats?.totalOccupiedAreaInProperty || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Total Vacant Area'}>
                        {towerStats?.totalAvailableAreaInProperty || '0'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Parking Slots Occupied'}>
                        {towerStats?.totalOccupiedParkingSlots || '0'}
                      </DataLabelValueComponent>
                    </div>

                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Vacant Parking Slots'}>
                        {towerStats?.totalAvailableParkingSlots || '0'}
                      </DataLabelValueComponent>
                    </div>
                  </div>
                </>
              </AccordionComponent>
            </>
            <HorizontalLineComponent className='mrg-bottom-20' />
            <div className='list-screen'>
              <AccordionComponent
                title={`Facility Managers`}
                isExpand={true}
                isAccordionOpen={true}
              >
                <div>
                  <TableWrapperComponent
                    url={APIConfig.USER_LIST_LITE.URL}
                    method={APIConfig.USER_LIST_LITE.METHOD}
                    isPaginated={false}
                    noDataText={
                      'No Facility Manager have been assigned to this property'
                    }
                    autoHeight={true}
                    extraPayload={facilityFilterData}
                    moduleName={USER_LIST_MODULE}
                    columns={FacilityManagerList}
                  />
                </div>
              </AccordionComponent>
            </div>
          </CardComponent>

          <ButtonGroupComponent
            selected={currentTab}
            onChange={(value) => handleTabChange(value)}
            buttons={PropertyDetailsSteps}
          />
          {currentTab === 'towerDetails' && (
            <div className='list-screen mrg-top-20'>
              {isTowersListLoading && (
                <div className='h-v-center'>
                  <LoaderComponent type={'spinner'} />
                </div>
              )}
              {!isTowersListLoading &&
                propertyTowerList &&
                propertyTowerList.length === 0 && (
                  <div className='h-v-center'>
                    <StatusCardComponent
                      title={'No Towers are added to this property'}
                    />
                  </div>
                )}
              {!isTowersListLoading &&
                propertyTowerList &&
                propertyTowerList.length > 0 && (
                  <div className='list-screen-header-wrapper'>
                    {propertyTowerList?.map((item: any, index: number) => {
                      const selectedTable =
                        currentTableOption[item.name] || 'officeFloors';
                      return (
                        <AccordionComponent key={index} title={item.name}>
                          <>
                            <div>
                              <ButtonGroupComponent
                                selected={selectedTable}
                                buttons={TowerTab(item)}
                                onChange={(value) =>
                                  setCurrentTableOption((prev: any) => ({
                                    ...prev,
                                    [item.name]: value,
                                  }))
                                }
                              />
                            </div>
                            <div className='mrg-top-10'>
                              <TableComponent
                                data={
                                  selectedTable === 'basements'
                                    ? item?.basements
                                    : selectedTable === 'officeFloors' &&
                                      item?.officeFloors
                                }
                                noDataText={'No floors are added to this tower'}
                                columns={TowerColumns(selectedTable)}
                              />
                            </div>
                          </>
                        </AccordionComponent>
                      );
                    })}
                  </div>
                )}
              {/*<div className="list-screen-header-wrapper">*/}
              {/*    <FormControlLabelComponent label={'Tower Details'} level={4} isBold={true}/>*/}
              {/*</div>*/}
              {/*<TableWrapperComponent url={APIConfig.GET_TOWER_DETAILS.URL(propertyDetails?._id)}*/}
              {/*                       method={APIConfig.GET_TOWER_DETAILS.METHOD}*/}
              {/*                       autoHeight={true}*/}
              {/*                       noDataText={'No towers are added to this property'}*/}
              {/*                       isPaginated={true}*/}
              {/*                       columns={TowerDetailsColumn}/>*/}
            </div>
          )}

          {currentTab === 'tenants' && (
            <div className='list-screen mrg-top-20'>
              <div className='list-screen-header-wrapper'>
                <FormControlLabelComponent
                  label={'Tenants'}
                  level={4}
                  isBold={true}
                />
              </div>
              {/*<div className="list-screen-content">*/}
              <TableWrapperComponent
                url={APIConfig.TENANT_LIST.URL}
                method={APIConfig.TENANT_LIST.METHOD}
                // onDataLoad={handleDataLoad}
                isPaginated={true}
                extraPayload={{ propertyId: propertyId }}
                noDataText={'Please add tenants in the Tenant Module'}
                columns={TenantsListColumn}
              />
              {/*</div>*/}
            </div>
          )}

          {currentTab === 'amenities' && (
            <div className='list-screen mrg-top-20'>
              <div className='list-screen-header-wrapper'>
                <FormControlLabelComponent
                  label={'Amenities'}
                  level={4}
                  isBold={true}
                />
              </div>
              <TableWrapperComponent
                url={APIConfig.GET_AMENITIES_PAGINATED_LIST.URL}
                method={APIConfig.GET_AMENITIES_PAGINATED_LIST.METHOD}
                id={'amenities'}
                // moduleName={MODULE}
                // onDataLoad={handleDataLoad}
                isPaginated={true}
                extraPayload={{ propertyId: propertyId }}
                columns={AmenityListColumn}
                noDataText={'Please add amenities in the Amenity Module'}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PropertyDetailsScreen;
