import "./HeaderComponent.scss";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import {ImageConfig} from "../../../../constants";
import IconButtonComponent from "../../icon-button/IconButtonComponent";
import {useCallback} from "react";

interface HeaderComponentProps {

}

const HeaderComponent = (props: HeaderComponentProps) => {
    const {currentNavParams, onNavigateBack} = useSelector((state: IRootReducerState) => state.navigation);
    const {currentUser} = useSelector((state: IRootReducerState) => state?.account);
    const title = currentNavParams?.title;

    const handleNavigateBack = useCallback(() => {
        if (onNavigateBack) {
            onNavigateBack();
        }
    }, [onNavigateBack]);

    return (
        <div className="header-component">
            <div className="header-title-nav-back">
                {
                    onNavigateBack &&
                    <IconButtonComponent
                        color={"default"}
                        onClick={handleNavigateBack}
                        className={"header-nav-back"}
                        id={"header-back-nav-btn"}
                    >
                        <ImageConfig.NavigateBack/>
                    </IconButtonComponent>
                }
                <div className="header-title mrg-left-10">
                    {!!onNavigateBack ? "Back" : title}
                </div>
            </div>
            <div className="header-options">
                <div className="header-option header-option__profile">
                    <div className="header-option__profile__avatar">
                        <img src={ImageConfig.ProfileAvatar} alt={'Profile Avatar'}/>
                    </div>
                    <div className="header-option__profile__meta">
                        <div className="header-option__profile__meta__name">{currentUser?.name || "User"}</div>
                        <div className="header-option__profile__meta__email">{currentUser?.email}</div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default HeaderComponent;
