import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import NotFoundScreen from '../screens/not-found/notFoundScreen';
import AuthLayout from '../layouts/auth-layout/AuthLayout';
import TestScreen from '../screens/test/TestScreen';
import DesignSystemScreen from '../screens/design-system/DesignSystemScreen';
import LoginScreen from '../screens/auth/login/LoginScreen';
import AppLayout from '../layouts/app-layout/AppLayout';
import ComingSoonScreen from '../screens/coming-soon/ComingSoonScreen';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../store/reducers';
import { CommonService } from '../shared/services';

import {
  ADD_AMENITY,
  ADD_ANNOUNCEMENT,
  ADD_DEFAULT_ANNOUNCEMENT,
  ADD_PROPERTY,
  ADD_VISITOR,
  AMENITY,
  AMENITY_LIST,
  ANNOUNCEMENT,
  ANNOUNCEMENT_LIST,
  COMING_SOON_ROUTE,
  DASHBOARD,
  DESIGN_SYSTEM_ROUTE,
  EDIT_AMENITY,
  EDIT_ANNOUNCEMENT,
  EDIT_DEFAULT_ANNOUNCEMENT,
  EDIT_EVENT,
  EDIT_PROPERTY,
  EVENT_ADD,
  EVENT_DETAILS,
  EVENTS,
  EVENTS_LIST,
  FEEDBACK_LIST,
  LOGIN_ROUTE,
  NOT_FOUND_ROUTE,
  POLLS,
  POLLS_ADD,
  POLLS_DETAILS,
  POLLS_EDIT,
  POLLS_LIST,
  PROPERTY,
  PROPERTY_DETAILS,
  PROPERTY_lIST,
  TENANTS,
  TEST_ROUTE,
  USER_ADD,
  USER_BULK_UPLOAD,
  USER_EDIT,
  USER_LIST,
  VIEW_AMENITY,
  VISITOR_lIST,
  TENANT_LIST,
  TENANT_DETAILS,
  TENANT_ADD,
  EDIT_TENANT,
  EDIT_VISITOR,
  ENTITY,
  ENTITY_LIST,
  ENTITY_DETAILS,
  ENTITY_ADD,
  ENTITY_EDIT,
  INVOICE,
  INVOICE_LIST,
  INVOICE_DETAILS,
  CREATE_INVOICE,
  VERIFY_INVOICE,
  EDIT_BILLING_PREFERENCES,
  EDIT_AMENITY_BILLING,
  DEBITNOTE,
  DEBITNOTE_LIST,
  DEBITNOTE_DETAILS,
} from '../constants/RoutesConfig';

import PropertyListComponent from '../screens/property/property-list/PropertyListComponent';
import DashboardScreen from '../screens/dashboard/DashboardScreen';
import AddVisitorScreen from '../screens/vms/add-visitor/AddVisitorScreen';
import AddLayout from '../layouts/add-layout/AddLayout';
import VmsListLayoutComponent from '../screens/vms/vms-list-layout/VmsListLayoutComponent';
import PropertyDetailsScreen from '../screens/property/property-details/propertyDetailsScreen';
import AddPropertyComponent from '../screens/property/add-property/AddPropertyComponent';
import UserBulkUploadScreen from '../screens/user/user-bulk-upload/UserBulkUploadScreen';
import UserAddScreen from '../screens/user/user-add/UserAddScreen';
import UserListScreen from '../screens/user/user-list/UserListScreen';
import PollsListComponent from '../screens/polls/polls-list/PollsListComponent';
import AddPollsComponent from '../screens/polls/add-polls/AddPollsComponent';
import PollDetailsComponent from '../screens/polls/poll-details/PollDetailsComponent';
import PropertyComponent from '../screens/property/PropertyComponent';
import PollsComponent from '../screens/polls/PollsComponent';
import WallRouterComponent from '../screens/wall/WallRouterComponent';
import AnnouncementsScreen from '../screens/wall/announcements/AnnouncementsScreen';
import AddAnnouncementComponent from '../screens/wall/add-announcement/AddAnnouncementComponent';
import AmenitiesScreen from '../screens/amenity/amenities/AmenitiesScreen';
import AddAmenityComponent from '../screens/amenity/add-amenity/AddAmenityComponent';
import AmenityViewScreen from '../screens/amenity/amenities-view/AmenityViewScreen';
import FeedbackListScreen from '../screens/feedback/feedbackListScreen';
import EventsComponent from '../screens/events/EventsComponent';
import EventsListComponent from '../screens/events/events-list/EventsListComponent';
import AddEventsComponent from '../screens/events/add-event/AddEventsComponent';
import EventViewScreen from '../screens/events/event-view/EventViewScreen';
import TenantsComponent from '../screens/tenant/TenantsComponent';
import TenantViewScreen from '../screens/tenant/tenant-view/TenantViewScreen';
import AddTenantsComponent from '../screens/tenant/add-tenant/AddTenantsComponent';
import TenantsListComponent from '../screens/tenant/tenants-list/TenantsListComponent';
import { ENV } from '../constants';
import EntityComponent from '../screens/entity/EntityComponent';
import EntityListComponent from '../screens/entity/entity-list/EntityListComponent';
import AddEntityComponent from '../screens/entity/add-entity/AddEntityComponent';
import EntityDetailsComponent from '../screens/entity/entity-details/EntityDetailsComponent';
import InvoicesComponent from '../screens/invoices/InvoicesComponent';
import InvoicesListLayoutComponent from '../screens/invoices/invoices-list-layout/InvoicesListLayoutComponent';
import InvoiceCreateComponent from '../screens/invoices/invoice-create/InvoiceCreateComponent';
import InvoiceDetailsScreen from '../screens/invoices/invoice-details/InvoiceDetailsScreen';
import InvoiceVerifyGSTComponent from '../screens/invoices/invoice-verfiy-gst/InvoiceVerifyGSTComponent';
import ViewLayout from '../layouts/view-layout/ViewLayout';
import EditBillingPreferencesComponent from '../screens/tenant/tenant-view/edit-billing-preferences/EditBillingPreferencesComponent';
import AmenityBillingEditComponent from '../screens/amenity/amenities-view/amenity-billing-edit/AmenityBillingEditComponent';
import DebitNoteComponent from '../screens/debit-note/DebitNoteComponent';
import DebitNoteListLayoutComponent from '../screens/debit-note/debit-note-list-layout/DebitNoteListLayoutComponent';
import DebitNoteDetailsComponent from '../screens/debit-note/debit-note-details/DebitNoteDetailsComponent';

const ProtectedRoute = (props: React.PropsWithChildren<any>) => {
  const { children } = props;
  const { token } = useSelector((state: IRootReducerState) => state.account);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      navigate(
        '/login?returnUrl=' +
          encodeURIComponent(location.pathname + location.search),
      );
    }
  }, [token, navigate, location]);

  return children;
};

const UnProtectedRoute = (props: React.PropsWithChildren<any>) => {
  const { children } = props;
  const { token } = useSelector((state: IRootReducerState) => state.account);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let returnUrl = CommonService._routeConfig.DefaultRoute();
    if (!!token) {
      const query = CommonService.parseQueryString(location.search);
      if (Object.keys(query).includes('returnUrl')) {
        returnUrl = query.returnUrl;
      }
      navigate(returnUrl);
    }
  }, [token, navigate, location]);

  return children;
};

export interface NavigatorProps {}

const Navigator = (props: NavigatorProps) => {
  return (
    <>
      <Routes>
        <Route element={<AppLayout />}>
          {/* <Route index element={<Navigate to={PROPERTY_lIST} />} /> */}
          <Route index element={<Navigate to={DASHBOARD} />} />
          <Route
            path={DASHBOARD}
            element={
              <ProtectedRoute>
                <DashboardScreen />
              </ProtectedRoute>
            }
          />
          <Route path={ENTITY} element={<EntityComponent />}>
            <Route index element={<Navigate to={ENTITY_LIST} />} />
            <Route
              path={ENTITY_LIST}
              element={
                <ProtectedRoute>
                  <EntityListComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path={ENTITY_DETAILS + '/:entityId'}
              element={
                <ProtectedRoute>
                  <EntityDetailsComponent />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={PROPERTY} element={<PropertyComponent />}>
            <Route index element={<Navigate to={PROPERTY_lIST} />} />
            <Route
              path={PROPERTY_lIST}
              element={
                <ProtectedRoute>
                  <PropertyListComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path={PROPERTY_DETAILS + '/:propertyId'}
              element={
                <ProtectedRoute>
                  <PropertyDetailsScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={ANNOUNCEMENT} element={<WallRouterComponent />}>
            <Route index element={<Navigate to={ANNOUNCEMENT_LIST} />} />
            <Route
              path={ANNOUNCEMENT_LIST}
              element={
                <ProtectedRoute>
                  <AnnouncementsScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={AMENITY} element={<WallRouterComponent />}>
            <Route index element={<Navigate to={AMENITY_LIST} />} />
            <Route
              path={AMENITY_LIST}
              element={
                <ProtectedRoute>
                  <AmenitiesScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={VIEW_AMENITY + '/:amenityId'}
              element={
                <ProtectedRoute>
                  <AmenityViewScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={POLLS} element={<PollsComponent />}>
            <Route index element={<Navigate to={POLLS_LIST} />} />
            <Route
              path={POLLS_LIST}
              element={
                <ProtectedRoute>
                  <PollsListComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path={POLLS_DETAILS + '/:pollId'}
              element={
                <ProtectedRoute>
                  <PollDetailsComponent />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={EVENTS} element={<EventsComponent />}>
            <Route index element={<Navigate to={EVENTS_LIST} />} />
            <Route
              path={EVENTS_LIST}
              element={
                <ProtectedRoute>
                  <EventsListComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path={EVENT_DETAILS + '/:eventId'}
              element={
                <ProtectedRoute>
                  <EventViewScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={TENANTS} element={<TenantsComponent />}>
            <Route index element={<Navigate to={TENANT_LIST} />} />
            <Route
              path={TENANT_LIST}
              element={
                <ProtectedRoute>
                  <TenantsListComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path={TENANT_DETAILS + '/:tenantId'}
              element={
                <ProtectedRoute>
                  <TenantViewScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path={VISITOR_lIST}
            element={
              <ProtectedRoute>
                <VmsListLayoutComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path={USER_LIST}
            element={
              <ProtectedRoute>
                <UserListScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path={FEEDBACK_LIST}
            element={
              <ProtectedRoute>
                <FeedbackListScreen />
              </ProtectedRoute>
            }
          />

          <Route path={INVOICE} element={<InvoicesComponent />}>
            <Route index element={<Navigate to={INVOICE_LIST} />} />
            <Route
              path={INVOICE_LIST}
              element={
                <ProtectedRoute>
                  <InvoicesListLayoutComponent />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={DEBITNOTE} element={<DebitNoteComponent />}>
            <Route index element={<Navigate to={DEBITNOTE_LIST} />} />
            <Route
              path={DEBITNOTE_LIST}
              element={
                <ProtectedRoute>
                  <DebitNoteListLayoutComponent />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route element={<AddLayout />}>
          <Route
            path={ENTITY_ADD}
            element={
              <ProtectedRoute>
                <AddEntityComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path={ENTITY_EDIT}
            element={
              <ProtectedRoute>
                <AddEntityComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path={ADD_VISITOR}
            element={
              <ProtectedRoute>
                <AddVisitorScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path={EDIT_VISITOR + '/:visitorId'}
            element={
              <ProtectedRoute>
                <AddVisitorScreen />
              </ProtectedRoute>
            }
          />

          <Route
            path={ADD_PROPERTY}
            element={
              <ProtectedRoute>
                <AddPropertyComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_PROPERTY}
            element={
              <ProtectedRoute>
                <AddPropertyComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_AMENITY + '/:amenityId'}
            element={
              <ProtectedRoute>
                <AddAmenityComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_AMENITY_BILLING + '/:id'}
            element={
              <ProtectedRoute>
                <AmenityBillingEditComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_EVENT + '/:eventId'}
            element={
              <ProtectedRoute>
                <AddEventsComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_TENANT + '/:tenantId'}
            element={
              <ProtectedRoute>
                <AddTenantsComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_BILLING_PREFERENCES + '/:id'}
            element={
              <ProtectedRoute>
                <EditBillingPreferencesComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={ADD_AMENITY}
            element={
              <ProtectedRoute>
                <AddAmenityComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={ADD_ANNOUNCEMENT}
            element={
              <ProtectedRoute>
                <AddAnnouncementComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path={EDIT_ANNOUNCEMENT + '/:announcementId'}
            element={
              <ProtectedRoute>
                <AddAnnouncementComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={ADD_DEFAULT_ANNOUNCEMENT}
            element={
              <ProtectedRoute>
                <AddAnnouncementComponent type={'Default'} />
              </ProtectedRoute>
            }
          />

          <Route
            path={EDIT_DEFAULT_ANNOUNCEMENT + '/:announcementId'}
            element={
              <ProtectedRoute>
                <AddAnnouncementComponent type={'Default'} />
              </ProtectedRoute>
            }
          />
          <Route
            path={USER_BULK_UPLOAD}
            element={
              <ProtectedRoute>
                <UserBulkUploadScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path={USER_ADD}
            element={
              <ProtectedRoute>
                <UserAddScreen />
              </ProtectedRoute>
            }
          />

          <Route
            path={USER_EDIT + '/:userId'}
            element={
              <ProtectedRoute>
                <UserAddScreen />
              </ProtectedRoute>
            }
          />

          <Route
            path={POLLS_ADD}
            element={
              <ProtectedRoute>
                <AddPollsComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={EVENT_ADD}
            element={
              <ProtectedRoute>
                <AddEventsComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={TENANT_ADD}
            element={
              <ProtectedRoute>
                <AddTenantsComponent />
              </ProtectedRoute>
            }
          />

          <Route
            path={POLLS_EDIT}
            element={
              <ProtectedRoute>
                <AddPollsComponent />
              </ProtectedRoute>
            }
          />

          {/* INVOICE */}
          <Route
            path={CREATE_INVOICE}
            element={
              <ProtectedRoute>
                <InvoiceCreateComponent />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route element={<ViewLayout />}>
          <Route
            path={INVOICE_DETAILS + '/:invoiceId'}
            element={
              <ProtectedRoute>
                <InvoiceDetailsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path={VERIFY_INVOICE + '/:invoiceId'}
            element={
              <ProtectedRoute>
                <InvoiceVerifyGSTComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path={DEBITNOTE_DETAILS + '/:debitNoteId'}
            element={
              <ProtectedRoute>
                <DebitNoteDetailsComponent />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={COMING_SOON_ROUTE} element={<ComingSoonScreen />} />
        <Route element={<AuthLayout />}>
          <Route
            index
            element={
              <UnProtectedRoute>
                <LoginScreen />
              </UnProtectedRoute>
            }
          />
          <Route
            path={LOGIN_ROUTE}
            element={
              <UnProtectedRoute>
                <LoginScreen />
              </UnProtectedRoute>
            }
          />
        </Route>
        {ENV.ENV_MODE === 'dev' && (
          <Route path={TEST_ROUTE} element={<TestScreen />} />
        )}

        <Route path={DESIGN_SYSTEM_ROUTE} element={<DesignSystemScreen />} />
        <Route path={NOT_FOUND_ROUTE} element={<NotFoundScreen />} />
        <Route path='*' element={<Navigate to={NOT_FOUND_ROUTE} />} />
      </Routes>
    </>
  );
};

export default Navigator;
