import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const getTodayVisitorCount = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_TODAY_VISITOR_COUNT.METHOD](APIConfig.GET_TODAY_VISITOR_COUNT.URL, payload);
}

const getTenantList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_TENANT_LIST.METHOD](APIConfig.GET_TENANT_LIST.URL, payload);
}

const getVisitorDetails = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_VISITOR_DETAILS.METHOD](APIConfig.GET_VISITOR_DETAILS.URL(visitor_id), payload);
}

const visitorListAPICall = () => {
    // @ts-ignore
    return ApiService[APIConfig.VISITORS_LIST.METHOD](APIConfig.VISITORS_LIST.URL);
}

const handleApproveRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.APPROVE_REQUEST.METHOD](APIConfig.APPROVE_REQUEST.URL(visitor_id), payload);
}

const handleRejectRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.REJECT_REQUEST.METHOD](APIConfig.REJECT_REQUEST.URL(visitor_id), payload);
}

const handleCancelRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CANCEL_REQUEST.METHOD](APIConfig.CANCEL_REQUEST.URL(visitor_id), payload);
}

const handleCheckInRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CHECK_IN_REQUEST.METHOD](APIConfig.CHECK_IN_REQUEST.URL(visitor_id), payload);
}

const handleCheckOutRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CHECK_OUT_REQUEST.METHOD](APIConfig.CHECK_OUT_REQUEST.URL(visitor_id), payload);
}

const handleResendRequest = (visitor_id: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.RESEND_REQUEST.METHOD](APIConfig.RESEND_REQUEST.URL(visitor_id), payload);
}
const downloadVisitorCSV = (payload: any) => {
    return ApiService[APIConfig.DOWNLOAD_VISITOR_CSV.METHOD](APIConfig.DOWNLOAD_VISITOR_CSV.URL, payload);
}

const VisitorService = {
    getTodayVisitorCount,
    getTenantList,
    getVisitorDetails,
    visitorListAPICall,
    handleApproveRequest,
    handleRejectRequest,
    handleCancelRequest,
    handleCheckInRequest,
    handleCheckOutRequest,
    handleResendRequest,
    downloadVisitorCSV
}

export default VisitorService;
