// AUTH ROUTES
const LOGIN_ROUTE = '/login';

// NON-AUTH ROUTES
const TEST_ROUTE = '/test';
const NOT_FOUND_ROUTE = '/not-found';
const DESIGN_SYSTEM_ROUTE = '/ds';
const COMING_SOON_ROUTE = '/coming-soon';
const DASHBOARD = '/dashboard';

const ENTITY = '/entity';

const ENTITY_LIST = '/entity/list';
const ENTITY_ADD = '/entity/add';
const ENTITY_EDIT = '/entity/edit';
const ENTITY_DETAILS = '/entity/details';

const PROPERTY = '/property';
const AMENITY = '/amenity';
const AMENITY_LIST = '/amenity/list';
const ADD_AMENITY = '/amenity/add';
const EDIT_AMENITY = '/amenity/edit';
const VIEW_AMENITY = '/amenity/view';
const ANNOUNCEMENT = '/announcement';
const ADD_ANNOUNCEMENT = '/announcement/add';
const ADD_DEFAULT_ANNOUNCEMENT = '/announcement/addDefault';
const EDIT_ANNOUNCEMENT = '/announcement/edit';
const EDIT_DEFAULT_ANNOUNCEMENT = '/announcement/editDefault';
const ANNOUNCEMENT_LIST = '/announcement/list';
const PROPERTY_lIST = '/property/list';
const ADD_PROPERTY = '/property/add';
const EDIT_PROPERTY = '/property/edit';
const PROPERTY_DETAILS = '/property/details';
const VISITOR_lIST = '/vms/visitor-list';
const ADD_VISITOR = '/vms/add-visitor';
const EDIT_VISITOR = '/vms/edit-visitor';
const USER_LIST = '/user/list';
const USER_ADD = '/user/add';
const USER_EDIT = '/user/edit';
const USER_BULK_UPLOAD = '/user/bulk-upload';
const POLLS = '/poll';
const EVENTS = '/event';
const TENANTS = '/tenant';
const TENANT_LIST = '/tenant/list';
const TENANT_DETAILS = '/tenant/details';
const TENANT_ADD = '/tenant/add';
const EVENTS_LIST = '/event/list';
const EVENT_ADD = '/event/add';
const FEEDBACK_LIST = '/feedback/list';
const POLLS_LIST = '/poll/list';
const POLLS_ADD = '/poll/add';
const POLLS_EDIT = '/poll/edit';
const POLLS_DETAILS = '/poll/details';
const EVENT_DETAILS = '/event/details';
const EDIT_EVENT = '/event/edit';
const EDIT_TENANT = '/tenant/edit';
const EDIT_BILLING_PREFERENCES = '/tenant/billing/edit';
const EDIT_AMENITY_BILLING = '/amenity/billing/edit';

// INVOICES
const INVOICE = '/invoice';
const INVOICE_LIST = '/invoice/list';
const CREATE_INVOICE = '/invoice/create';
const INVOICE_DETAILS = '/invoice/details';
const VERIFY_INVOICE = '/invoice/verify';

//DEBIT NOTE
const DEBITNOTE = '/debitnote';
const DEBITNOTE_LIST = '/debitnote/list';
const DEBITNOTE_DETAILS = '/debitnote/details';

const GUEST_ROUTES = [NOT_FOUND_ROUTE, DESIGN_SYSTEM_ROUTE, LOGIN_ROUTE];

export {
  LOGIN_ROUTE,
  COMING_SOON_ROUTE,
  NOT_FOUND_ROUTE,
  GUEST_ROUTES,
  TEST_ROUTE,
  DESIGN_SYSTEM_ROUTE,
  DASHBOARD,
  PROPERTY_lIST,
  VISITOR_lIST,
  PROPERTY_DETAILS,
  ADD_VISITOR,
  EDIT_VISITOR,
  AMENITY,
  AMENITY_LIST,
  ADD_AMENITY,
  VIEW_AMENITY,
  EDIT_AMENITY,
  ANNOUNCEMENT,
  ADD_ANNOUNCEMENT,
  ADD_DEFAULT_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT,
  EDIT_DEFAULT_ANNOUNCEMENT,
  ANNOUNCEMENT_LIST,
  ADD_PROPERTY,
  EDIT_PROPERTY,
  USER_LIST,
  USER_ADD,
  USER_EDIT,
  USER_BULK_UPLOAD,
  POLLS_LIST,
  POLLS_ADD,
  POLLS_DETAILS,
  EVENT_DETAILS,
  EDIT_EVENT,
  EDIT_TENANT,
  POLLS_EDIT,
  PROPERTY,
  POLLS,
  EVENTS,
  TENANTS,
  TENANT_LIST,
  TENANT_DETAILS,
  TENANT_ADD,
  EVENTS_LIST,
  EVENT_ADD,
  FEEDBACK_LIST,
  ENTITY,
  ENTITY_LIST,
  ENTITY_ADD,
  ENTITY_EDIT,
  ENTITY_DETAILS,
  INVOICE,
  INVOICE_LIST,
  INVOICE_DETAILS,
  VERIFY_INVOICE,
  CREATE_INVOICE,
  EDIT_BILLING_PREFERENCES,
  EDIT_AMENITY_BILLING,
  DEBITNOTE,
  DEBITNOTE_LIST,
  DEBITNOTE_DETAILS,
};
