import { ApiService } from '../index';
import { APIConfig } from '../../../constants';
import moment from 'moment';

const today = moment();
const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const yesNoOptions = [
  {
    title: 'Yes',
    code: true,
  },
  {
    title: 'No',
    code: false,
  },
];

const customYesNoOptions = [
  {
    title: 'Yes',
    code: 'true',
  },
  {
    title: 'No',
    code: 'false',
  },
];

const remainderTimeOptions = [
  {
    name: '24 hrs',
    code: '24',
  },
  {
    name: '6 hrs',
    code: '6',
  },
  {
    name: '1 hrs',
    code: '1',
  },
];

const signUpOptions = [
  {
    title: 'UID',
    code: 'UID',
  },
  {
    title: 'Email Address',
    code: 'Email',
  },
];

const resultOptions = [
  {
    title: 'Positive',
    code: 'Positive',
  },
  {
    title: 'Negative',
    code: 'Negative',
  },
];

const visitorTypeOptions = [
  {
    title: 'Planned',
    code: 'Planned',
  },
  {
    title: 'Un Planned',
    code: 'UnPlanned',
  },
];

const visitTypeOptions = [
  {
    title: 'Tenant',
    code: 'tenant',
  },
  {
    title: 'Property',
    code: 'property',
  },
];

const GenderList = [
  {
    title: 'Male',
    code: 'male',
  },
  {
    title: 'Female',
    code: 'female',
  },
  {
    title: 'Others',
    code: 'others',
  },
];

const EventCategoryList = [
  {
    title: 'Music',
    code: 'music',
  },
  {
    title: 'Arts',
    code: 'arts',
  },
  {
    title: 'Fitness',
    code: 'fitness',
  },
  {
    title: 'Private Club',
    code: 'club',
  },
  {
    title: 'Festivals',
    code: 'festivals',
  },
];

const InvoiceType = [
  {
    title: 'Automated',
    code: 'automated',
  },
  {
    title: 'Manual',
    code: 'manual',
  },
];

const ApprovedInvoiceStatus = [
  {
    title: 'Paid',
    code: 'paid',
  },
  {
    title: 'Pending',
    code: 'pending',
  },
];

const InvoiceSupplyType = [
  'B2B',
  'B2C',
  'SEZWP',
  'SEZWOP',
  'EXPWP',
  'EXPWOP',
  'DEXP',
];

const unitsOfCare = [1, 2, 3, 4, 5, 6, 7, 8];

const indianStatesGST = [
  { state: 'Andhra Pradesh', statecode: '37', code: 'andhrapradesh' },
  {
    state: 'Andaman and Nicobar Islands',
    statecode: '35',
    code: 'andamanandnicobarislands',
  },
  { state: 'Arunachal Pradesh', statecode: '12', code: 'arunachalpradesh' },
  { state: 'Assam', statecode: '18', code: 'assam' },
  { state: 'Bihar', statecode: '10', code: 'bihar' },
  { state: 'Chandigarh', statecode: '04', code: 'chandigarh' },
  { state: 'Chhattisgarh', statecode: '22', code: 'chhattisgarh' },
  { state: 'Delhi', statecode: '07', code: 'delhi' },
  { state: 'Goa', statecode: '30', code: 'goa' },
  { state: 'Gujarat', statecode: '24', code: 'gujarat' },
  { state: 'Haryana', statecode: '06', code: 'haryana' },
  { state: 'Himachal Pradesh', statecode: '02', code: 'himachalpradesh' },
  { state: 'Jammu and Kashmir', statecode: '01', code: 'jammuandkashmir' },
  { state: 'Jharkhand', statecode: '20', code: 'jharkhand' },
  { state: 'Karnataka', statecode: '29', code: 'karnataka' },
  { state: 'Kerala', statecode: '32', code: 'kerala' },
  { state: 'Madhya Pradesh', statecode: '23', code: 'madhyapradesh' },
  { state: 'Maharashtra', statecode: '27', code: 'maharashtra' },
  { state: 'Manipur', statecode: '14', code: 'manipur' },
  { state: 'Meghalaya', statecode: '17', code: 'meghalaya' },
  { state: 'Mizoram', statecode: '15', code: 'mizoram' },
  { state: 'Nagaland', statecode: '13', code: 'nagaland' },
  { state: 'Odisha', statecode: '21', code: 'odisha' },
  { state: 'Punjab', statecode: '03', code: 'punjab' },
  { state: 'Rajasthan', statecode: '08', code: 'rajasthan' },
  { state: 'Sikkim', statecode: '11', code: 'sikkim' },
  { state: 'Tamil Nadu', statecode: '33', code: 'tamilnadu' },
  { state: 'Telangana', statecode: '36', code: 'telangana' },
  { state: 'Tripura', statecode: '16', code: 'tripura' },
  { state: 'Uttar Pradesh', statecode: '09', code: 'uttarpradesh' },
  { state: 'Uttarakhand', statecode: '05', code: 'uttarakhand' },
  { state: 'West Bengal', statecode: '19', code: 'westbengal' },
  {
    state: 'Dadra and Nagar Haveli and Daman and Diu',
    statecode: '26',
    code: 'dadranagarhavelianddamandiu',
  },
  { state: 'Lakshadweep', statecode: '31', code: 'lakshadweep' },
  { state: 'Puducherry', statecode: '34', code: 'puducherry' },
];

const InvoiceUnit = [
  { unit: 'BAG', des: 'BAGS', code: 'bags' },
  { unit: 'BAL', des: 'BALE', code: 'bale' },
  { unit: 'BDL', des: 'BUNDLES', code: 'bundles' },
  { unit: 'BKL', des: 'BUCKLES', code: 'buckles' },
  { unit: 'BOU', des: 'BILLION OF UNITS', code: 'billion of units' },
  { unit: 'BOX', des: 'BOX', code: 'box' },
  { unit: 'BTL', des: 'BOTTLES', code: 'bottles' },
  { unit: 'BUN', des: 'BUNCHES', code: 'bunches' },
  { unit: 'CAN', des: 'CANS', code: 'cans' },
  { unit: 'CBM', des: 'CUBIC METERS', code: 'cubic meters' },
  { unit: 'CCM', des: 'CUBIC CENTIMETERS', code: 'cubic centimeters' },
  { unit: 'CMS', des: 'CENTIMETERS', code: 'centimeters' },
  { unit: 'CTN', des: 'CARTONS', code: 'cartons' },
  { unit: 'DOZ', des: 'DOZENS', code: 'dozens' },
  { unit: 'DRM', des: 'DRUMS', code: 'drums' },
  { unit: 'GGK', des: 'GREAT GROSS', code: 'great gross' },
  { unit: 'GMS', des: 'GRAMMES', code: 'grammes' },
  { unit: 'GRS', des: 'GROSS', code: 'gross' },
  { unit: 'GYD', des: 'GROSS YARDS', code: 'gross yards' },
  { unit: 'KGS', des: 'KILOGRAMS', code: 'kilograms' },
  { unit: 'KLR', des: 'KILOLITRE', code: 'kilolitre' },
  { unit: 'KME', des: 'KILOMETRE', code: 'kilometre' },
  { unit: 'LTR', des: 'LITRES', code: 'litres' },
  { unit: 'MLS', des: 'MILLI LITRES', code: 'milli litres' },
  { unit: 'MLT', des: 'MILILITRE', code: 'mililitre' },
  { unit: 'MTR', des: 'METERS', code: 'meters' },
  { unit: 'MTS', des: 'METRIC TON', code: 'metric ton' },
  { unit: 'NOS', des: 'NUMBERS', code: 'numbers' },
  { unit: 'OTH', des: 'OTHERS', code: 'others' },
  { unit: 'PAC', des: 'PACKS', code: 'packs' },
  { unit: 'PCS', des: 'PIECES', code: 'pieces' },
  { unit: 'PRS', des: 'PAIRS', code: 'pairs' },
  { unit: 'QTL', des: 'QUINTAL', code: 'quintal' },
  { unit: 'ROL', des: 'ROLLS', code: 'rolls' },
  { unit: 'SET', des: 'SETS', code: 'sets' },
  { unit: 'SQF', des: 'SQUARE FEET', code: 'square feet' },
  { unit: 'SQM', des: 'SQUARE METERS', code: 'square meters' },
  { unit: 'SQY', des: 'SQUARE YARDS', code: 'square yards' },
  { unit: 'TBS', des: 'TABLETS', code: 'tablets' },
  { unit: 'TGM', des: 'TEN GROSS', code: 'ten gross' },
  { unit: 'THD', des: 'THOUSANDS', code: 'thousands' },
  { unit: 'TON', des: 'TONNES', code: 'tonnes' },
  { unit: 'TUB', des: 'TUBES', code: 'tubes' },
  { unit: 'UGS', des: 'US GALLONS', code: 'us gallons' },
  { unit: 'UNT', des: 'UNITS', code: 'units' },
  { unit: 'YDS', des: 'YARDS', code: 'yards' },
];

const parkingLevelOptions = [
  { title: 'Property Level', code: 'propertyLevel' },
  { title: 'Tower Level', code: 'towerLevel' },
];

const floorDetailsOptions = [
  { title: 'Office Floors', id: 'officeFloors' },
  { title: 'Basements', id: 'basements' },
];

const towerOptions = [
  { title: 'Office Floors', id: 'officeFloors' },
  { title: 'Basements', id: 'basements' },
  { title: 'Hard Options', id: 'hardOptions' },
];

const floorTypeOptions = [
  { title: 'Office Floor', code: 'officeFloor' },
  { title: 'Basement', code: 'basement' },
];

// const

const getConsultationDurationList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.CONSULTATION_DURATION_LIST.METHOD](
    APIConfig.CONSULTATION_DURATION_LIST.URL,
    payload,
  );
};

const getGenderList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GENDER_LIST.METHOD](
    APIConfig.GENDER_LIST.URL,
    payload,
  );
};

const getPhoneTypeList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.PHONE_TYPE_LIST.METHOD](
    APIConfig.PHONE_TYPE_LIST.URL,
    payload,
  );
};

const getLanguageList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.LANGUAGE_LIST.METHOD](
    APIConfig.LANGUAGE_LIST.URL,
    payload,
  );
};

const getEmployeeStatusList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EMPLOYMENT_STATUS_LIST.METHOD](
    APIConfig.EMPLOYMENT_STATUS_LIST.URL,
    payload,
  );
};

const getRelationshipStatusList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.RELATIONSHIP_LIST.METHOD](
    APIConfig.RELATIONSHIP_LIST.URL,
    payload,
  );
};

const getUserRoleList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_ROLE_LIST.METHOD](
    APIConfig.USER_ROLE_LIST.URL,
    { ignoreAdminRole: true },
  );
};

const getPollTypes = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.POLL_TYPES.METHOD](
    APIConfig.POLL_TYPES.URL,
    payload,
  );
};

const StaticDataService = {
  today,
  weekDays,
  yesNoOptions,
  customYesNoOptions,
  remainderTimeOptions,
  signUpOptions,
  resultOptions,
  unitsOfCare,
  visitorTypeOptions,
  visitTypeOptions,
  getConsultationDurationList,
  getGenderList,
  getPhoneTypeList,
  getEmployeeStatusList,
  getLanguageList,
  getRelationshipStatusList,
  GenderList,
  EventCategoryList,
  getUserRoleList,
  getPollTypes,
  InvoiceType,
  ApprovedInvoiceStatus,
  InvoiceSupplyType,
  indianStatesGST,
  InvoiceUnit,
  parkingLevelOptions,
  floorDetailsOptions,
  floorTypeOptions,
  towerOptions,
};
export default StaticDataService;
