import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const getInvoiceStatusCount = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_INVOICES_STATUS_COUNT.METHOD](
    APIConfig.GET_INVOICES_STATUS_COUNT.URL,
    payload,
  );
};

const invoiceListAPICall = () => {
  // @ts-ignore
  return ApiService[APIConfig.INVOICES_LIST.METHOD](
    APIConfig.INVOICES_LIST.URL,
  );
};

const GetInvoicesNameAPICall = () => {
  //@ts-ignore
  return ApiService[APIConfig.GET_INVOICE_NAMES.METHOD](
    APIConfig.GET_INVOICE_NAMES.URL,
  );
};

const CreateInvoiceAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.CREATE_INVOICE.METHOD](
    APIConfig.CREATE_INVOICE.URL,
    payload,
  );
};

const AddInvoiceDetailsAPICall = (invoiceId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_INVOICE_DETAILS.METHOD](
    APIConfig.ADD_INVOICE_DETAILS.URL(invoiceId),
    payload,
  );
};

const GetInvoiceDetailsAPICall = (invoiceId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_INVOICE_DETAILS.METHOD](
    APIConfig.GET_INVOICE_DETAILS.URL(invoiceId),
    payload,
  );
};

const cancelInvoiceAPICall = (invoiceId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.CANCEL_INVOICE.METHOD](
    APIConfig.CANCEL_INVOICE.URL(invoiceId),
    payload,
  );
};

const markAsPaidInvoiceAPICall = (invoiceId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.MARK_AS_PAID_INVOICE.METHOD](
    APIConfig.MARK_AS_PAID_INVOICE.URL(invoiceId),
    payload,
  );
};

const deleteInvoiceAPICall = (invoiceId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.DELETE_INVOICE.METHOD](
    APIConfig.DELETE_INVOICE.URL(invoiceId),
    payload,
  );
};

const sendInvoiceAPICall = (invoiceId: string) => {
  //@ts-ignore
  return ApiService[APIConfig.SEND_INVOICE.METHOD](
    APIConfig.SEND_INVOICE.URL(invoiceId),
  );
};
const approveInvoiceAPICall = (invoiceId: string) => {
  //@ts-ignore
  return ApiService[APIConfig.APPROVE_INVOICE.METHOD](
    APIConfig.APPROVE_INVOICE.URL(invoiceId),
  );
};
const generateInvoiceAPICall = (invoiceId: string) => {
  //@ts-ignore
  return ApiService[APIConfig.GENERATE_INVOICE.METHOD](
    APIConfig.GENERATE_INVOICE.URL(invoiceId),
  );
};

const getHistoryInvoiceAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.INVOICE_HISTORY.METHOD](
    APIConfig.INVOICE_HISTORY.URL,
    payload,
  );
};

const downloadInvoiceListAsExcel = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.INVOICES_LIST.METHOD](
    APIConfig.INVOICES_LIST.URL + `?download=true`,
    payload,
  );
};

const getInvoiceListAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.INVOICES_LIST.METHOD](
    APIConfig.INVOICES_LIST.URL,
    payload,
  );
};

const getHistoryInvoiceListAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.INVOICE_HISTORY.METHOD](
    APIConfig.INVOICE_HISTORY.URL,
    payload,
  );
};

const sentToClientBulkInvoicesAPICall = (payload: any[]) => {
  //@ts-ignore
  return ApiService[APIConfig.INVOICE_BULK_SENT_TO_CLIENT.METHOD](
    APIConfig.INVOICE_BULK_SENT_TO_CLIENT.URL,
    payload,
  );
};

const InvoiceService = {
  getInvoiceStatusCount,
  invoiceListAPICall,
  CreateInvoiceAPICall,
  GetInvoicesNameAPICall,
  AddInvoiceDetailsAPICall,
  GetInvoiceDetailsAPICall,
  cancelInvoiceAPICall,
  markAsPaidInvoiceAPICall,
  deleteInvoiceAPICall,
  approveInvoiceAPICall,
  generateInvoiceAPICall,
  getHistoryInvoiceAPICall,
  sendInvoiceAPICall,
  downloadInvoiceListAsExcel,
  getInvoiceListAPICall,
  getHistoryInvoiceListAPICall,
  sentToClientBulkInvoicesAPICall,
};

export default InvoiceService;
