import { IActionModel } from '../../shared/models/action.model';
import {
  GET_ALL_TENANT_LIST,
  GET_TENANT_LIST_LITE,
  SET_ALL_TENANT_LIST,
  SET_TENANT_LIST_LITE,
} from '../actions/tenant.action';

export interface ITenantReducerState {
  tenantList: any[];
  allTenantList: any[];
  isTenantListLoading: boolean;
  isTenantListLoaded: boolean;
}

const initialData: ITenantReducerState = {
  tenantList: [],
  allTenantList: [],
  isTenantListLoading: false,
  isTenantListLoaded: false,
};

const TenantReducer = (state = initialData, action: IActionModel) => {
  switch (action.type) {
    case GET_TENANT_LIST_LITE:
      state = {
        ...state,
        isTenantListLoading: true,
        isTenantListLoaded: false,
      };
      return state;
    case SET_TENANT_LIST_LITE:
      state = {
        ...state,
        isTenantListLoading: false,
        isTenantListLoaded: true,
        tenantList: action.payload.tenantList,
      };
      return state;
    case GET_ALL_TENANT_LIST:
      state = {
        ...state,
      };
      return state;
    case SET_ALL_TENANT_LIST:
      state = {
        ...state,
        allTenantList: action.payload.allTenantList,
      };
      return state;
    default:
      return state;
  }
};

export default TenantReducer;
