import './PropertyDetailsComponent.scss';
import * as Yup from 'yup';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import { CommonService } from '../../../../shared/services';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import FilePreviewThumbnailComponent from '../../../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent';
import { useDispatch } from 'react-redux';
import { getEntityListLite } from '../../../../store/actions/entity.action';

interface PropertyDetailsComponentProps {
  next: (propertyId: string, data: any) => void;
  prev: () => void;
  steps: any[];
  activeStepId: string;
  propertyDetails: any;
  mode: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Property Name should be at least 3 characters')
    .max(50, 'Property Name should not exceed 50 characters')
    .required('Property Name is required'),

  billingEntityId: Yup.string()
    .required('Billing Entity is required')
    .nullable(),
  addressDetails: Yup.object({
    addressLine1: Yup.string()
      .min(3, 'Address should be at least 3 characters')
      .max(50, 'Address should not exceed 50 characters')
      .required('Address line is required'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode should have a total of 6 characters')
      .max(6, 'Pincode should not exceed 6 characters')
      .required('Pincode is required'),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string()
      .min(1, 'City should be at least 1 character')
      .max(30, 'City should not exceed 30 characters')
      .required('City is required'),
    country: Yup.string()
      .min(1, 'Country should be at least 1 character')
      .max(30, 'Country should not exceed 30 characters')
      .required('Country is required'),
    location: Yup.string()
      .min(1, 'Location should be at least 1 character')
      .max(30, 'Location should not exceed 30 characters')
      .required('Location is required'),
    mapLink: Yup.string().url('Enter a valid URL!'),
    // .required('Map link is required')
  }),
  // image: Yup.mixed().required("Image is required"),
});

interface formInitialValuesFormSchema {
  name: string;
  billingEntityId: string;
  image: any;
  addressDetails: {
    addressLine1: string;
    pincode: any;
    state: string;
    city: string;
    country: string;
    location: string;
    mapLink: string;
  };
}

const formInitialValues: formInitialValuesFormSchema = {
  name: '',
  image: '',
  billingEntityId: '',
  addressDetails: {
    addressLine1: '',
    pincode: '',
    state: '',
    city: '',
    country: 'India',
    location: '',
    mapLink: '',
  },
};

const PropertyDetailsComponent = (props: PropertyDetailsComponentProps) => {
  const { next, prev, steps, activeStepId, propertyDetails, mode } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const { entityList } = useSelector(
    (state: IRootReducerState) => state.entity,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEntityListLite());
  }, [dispatch]);

  useEffect(() => {
    const fetchImage = async () => {
      if (
        propertyDetails?.name ||
        propertyDetails?.addressDetails ||
        propertyDetails?.profilePic?.url
      ) {
        let imageDetails: any = {};
        if (propertyDetails?.profilePic?.url) {
          const image = await CommonService.generateBlobFileFromUrl(
            propertyDetails?.profilePic?.url,
            propertyDetails?.profilePic?.name,
            propertyDetails?.profilePic?.mimetype,
          );
          imageDetails = {
            image: image,
          };
        }
        // Set the initial values only after fetching the image
        const basicDetails = {
          name: propertyDetails?.name,
          billingEntityId: propertyDetails?.billingEntityId,
          image: imageDetails.image ? imageDetails.image : '',
          addressDetails: {
            addressLine1: propertyDetails?.addressDetails?.addressLine1,
            pincode: propertyDetails?.addressDetails?.pincode,
            state: propertyDetails?.addressDetails?.state,
            city: propertyDetails?.addressDetails?.city,
            country: propertyDetails?.addressDetails?.country,
            location: propertyDetails?.addressDetails?.location,
            mapLink: propertyDetails?.addressDetails?.mapLink,
          },
        };
        setInitialValues(basicDetails);
      }
    };
    fetchImage();
  }, [propertyDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      if (values.name) {
        values.name = values.name.trim().toLowerCase();
      }
      const payload = {
        ...values,
      };
      const formData = CommonService.getFormDataFromJSON(payload);
      let apiCall: any = {};
      if (mode === 'edit') {
        apiCall = CommonService._property.EditPropertyAPICall(
          propertyDetails._id,
          formData,
        );
      } else {
        apiCall = CommonService._property.AddPropertyAPICall(formData);
      }
      apiCall
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          if (mode === 'add') {
            next(response.data._id, undefined);
          } else {
            next(response.data._id, response.data);
          }
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [next, mode, propertyDetails],
  );

  return (
    <div className={'property-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={false}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          touched,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form scroll-content' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <Field name={'name'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Property Name'}
                      placeholder={'Example: Phoenix Advance Business Hub'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      validationPattern={new RegExp(/^[aA-zZ\s]+$/)}
                    />
                  )}
                </Field>

                <Field name={'billingEntityId'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      label={'Billing Entity'}
                      placeholder={'Select Billing Entity'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      options={entityList}
                      displayWith={(option: any) => option.name}
                      valueExtractor={(option: any) => option._id}
                    />
                  )}
                </Field>

                <FormControlLabelComponent
                  label={'Address Details'}
                  level={4}
                  isBold={true}
                />
                <Field name={'addressDetails.addressLine1'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Address Line'}
                      placeholder={'Example: Road No. 1, Financial District'}
                      type={'text'}
                      // maxlength={50}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.city'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'City'}
                          placeholder={'Example: Hyderabad'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={
                            new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.state'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          formikField={field}
                          label={'State'}
                          placeholder={'Example: Telangana'}
                          fullWidth={true}
                          required={true}
                          searchMode={'clientSide'}
                          searchable={true}
                          isClearable={true}
                          options={CommonService._staticData.indianStatesGST}
                          displayWith={(option) => option.state}
                          valueExtractor={(option) => option.state}
                          onUpdate={(value: any) => {
                            if (value) {
                              const selectedState =
                                CommonService._staticData.indianStatesGST.find(
                                  (item: any) => item.state === value,
                                );
                              setFieldValue(
                                'addressDetails.state',
                                selectedState?.state,
                              );
                              setFieldValue(
                                'addressDetails.stateCode',
                                selectedState?.statecode,
                              );
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'addressDetails.country'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Country'}
                          placeholder={'Ex: India'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>

                  <div className='ts-col-6'>
                    <Field name={'addressDetails.pincode'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Pincode'}
                          placeholder={'Ex: 500004'}
                          // type={"number"}
                          required={true}
                          validationPattern={/^[0-9\b]+$/}
                          maxlength={6}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>

                <Field name={'addressDetails.location'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Location'}
                      placeholder={'Enter location'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>

                <Field name={'addressDetails.mapLink'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'maps link for the location'}
                      placeholder={'Paste maps link for the location'}
                      type={'text'}
                      required={false}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>

                <div className={'row-wrapper'}>
                  <FormControlLabelComponent
                    label={'Upload Image'}
                    isBold={true}
                    level={4}
                  />
                  <div className={'optional-text'}>Optional</div>
                </div>
                <div className='upload-property-image'>
                  {!values.image && (
                    <div className={'mrg-top-10 height-90'}>
                      {!values.image && (
                        <>
                          <FilePickerComponent
                            maxFileCount={1}
                            maxFileSizeInMB={3}
                            height={'90%'}
                            id={'sc_upload_btn'}
                            onFilesDrop={(acceptedFiles, rejectedFiles) => {
                              if (acceptedFiles && acceptedFiles.length > 0) {
                                const file = acceptedFiles[0];
                                setFieldValue('image', file);
                              }
                            }}
                            // maxFileSizeInMB={3}
                            acceptedFileTypes={['png', 'jpg', 'jpeg']}
                            uploadDescription={'(upload only one file)'}
                            // acceptedFilesText={'.jpg, .png, .jpeg max file size 3.0MB'}
                          />
                          {/*{*/}
                          {/*    (_.get(touched, "image") && !!(_.get(errors, "image"))) &&*/}
                          {/*    <ErrorComponent*/}
                          {/*        errorText={(_.get(errors, "image"))}/>*/}
                          {/*}*/}
                        </>
                      )}
                    </div>
                  )}
                  <>
                    {values.image && (
                      <>
                        <FilePreviewThumbnailComponent
                          file={values.image}
                          onRemove={() => {
                            setFieldValue('image', '');
                          }}
                        />
                      </>
                    )}
                  </>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PropertyDetailsComponent;
