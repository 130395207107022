import './EventBookingComponent.scss';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikDatePickerComponent from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikTimePickerComponent from '../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import { CommonService } from '../../../../shared/services';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import moment from 'moment';

interface PollTimingsComponentProps {
  eventDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  eventId: string;
  activeStepId: string;
}

const validationSchema = Yup.object({
  bookingDetails: Yup.object().shape({
    isFree: Yup.boolean().required('Required'),
    fee: Yup.string().when('isFree', {
      is: false,
      then: Yup.string().required('Event fee is Required'),
      otherwise: Yup.string().notRequired(),
    }),
    isBookingRequired: Yup.boolean()
      .when('isFree', {
        is: true,
        then: Yup.boolean().required('Required').nullable(),
      })
      .nullable(),
    startDate: Yup.string()
      .when(['isFree', 'isBookingRequired'], {
        is: (isFree: boolean, isBookingRequired: boolean) => {
          return !isFree || isBookingRequired;
        },
        then: Yup.string().required('Start date is required').nullable(),
      })
      .nullable(),
    endDate: Yup.string()
      .when(['isFree', 'isBookingRequired'], {
        is: (isFree: boolean, isBookingRequired: boolean) => {
          return !isFree || isBookingRequired;
        },
        then: Yup.string().required('End date is required').nullable(),
      })
      .nullable(),
    startTime: Yup.string()
      .when(['isFree', 'isBookingRequired'], {
        is: (isFree: boolean, isBookingRequired: boolean) => {
          return !isFree || isBookingRequired;
        },
        then: Yup.string().required('Start time is required').nullable(),
      })
      .nullable(),
    endTime: Yup.string()
      .when(['isFree', 'isBookingRequired'], {
        is: (isFree: boolean, isBookingRequired: boolean) => {
          return !isFree || isBookingRequired;
        },
        then: Yup.string().required('End time is required').nullable(),
      })
      .nullable(),
    noOfTickets: Yup.string()
      .when('isBookingRequired', {
        is: true,
        then: Yup.string()
          .required('Number of tickets available is Required')
          .nullable(),
      })
      .nullable(),
    selectReminder: Yup.string()
      .required('Select remainder is Required')
      .nullable(),
  }),
});

interface formInitialValuesFormSchema {
  bookingDetails: {
    isBookingRequired: boolean;
    isFree: boolean;
    fee?: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    noOfTickets: string;
    selectReminder: string;
  };
}

const formInitialValues: formInitialValuesFormSchema = {
  bookingDetails: {
    isBookingRequired: true,
    isFree: false,
    fee: undefined,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    noOfTickets: '',
    selectReminder: '',
  },
};

const EventBookingComponent = (props: PollTimingsComponentProps) => {
  const { eventDetails, prev, next, steps, eventId, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  // const {propertyList} = useSelector((state: IRootReducerState) => state.property);
  // const selectedDate = new Date(eventDetails?.date);
  // const previousDate = new Date(selectedDate);
  // previousDate.setDate(selectedDate.getDate() - 1);

  useEffect(() => {
    if (eventDetails) {
      setInitialValues({
        // isBookingRequired: eventDetails.isBookingRequired,
        bookingDetails: {
          isBookingRequired: eventDetails?.bookingDetails?.isBookingRequired,
          isFree: eventDetails?.bookingDetails?.isFree,
          fee: eventDetails?.bookingDetails?.fee,
          startDate: eventDetails?.bookingDetails?.startDate
            ? eventDetails?.bookingDetails?.startDate
            : '',
          endDate: eventDetails?.bookingDetails?.endDate
            ? eventDetails?.bookingDetails?.endDate
            : '',
          startTime: eventDetails?.bookingDetails?.startTime
            ? eventDetails?.bookingDetails?.startTime
            : '',
          endTime: eventDetails?.bookingDetails?.endTime
            ? eventDetails?.bookingDetails?.endTime
            : '',
          noOfTickets: eventDetails?.bookingDetails?.noOfTickets,
          selectReminder: eventDetails?.bookingDetails?.selectReminder,
        },
      });
    }
  }, [eventDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      if (values.bookingDetails.isFree) {
        delete values.bookingDetails.fee;
      } else {
        values.bookingDetails.fee = parseInt(values.bookingDetails.fee);
      }
      values.bookingDetails.noOfTickets = parseInt(
        values.bookingDetails.noOfTickets,
      );
      values.bookingDetails.startDate = moment(
        values.bookingDetails.startDate,
      ).format('YYYY-MM-DD');
      values.bookingDetails.endDate = moment(
        values.bookingDetails.endDate,
      ).format('YYYY-MM-DD');
      const payload = {
        ...values,
      };
      // next(payload);
      CommonService._events
        .AddEventBookingAPICall(eventId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next(response.data);
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [next, eventId],
  );

  return (
    <div className={'event-booking-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Back
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                <Field name={'bookingDetails.isFree'}>
                  {(field: FieldProps) => (
                    <FormikRadioButtonGroupComponent
                      label={'Is it a free event?'}
                      required={true}
                      formikField={field}
                      options={CommonService._staticData.yesNoOptions}
                      onChange={(val) => {
                        // setFieldValue('bookingDetails.startDate', '');
                        // setFieldValue('bookingDetails.endDate', '');
                        // setFieldValue('bookingDetails.startTime', '');
                        // setFieldValue('bookingDetails.endTime', '');
                        if (val === true) {
                          setFieldValue('bookingDetails.fee', undefined);
                          setFieldValue(
                            'bookingDetails.isBookingRequired',
                            undefined,
                          );
                          setFieldValue('bookingDetails.startDate', null);
                          setFieldValue('bookingDetails.endDate', null);
                          setFieldValue('bookingDetails.startTime', null);
                          setFieldValue('bookingDetails.endTime', null);
                        } else {
                          setFieldValue(
                            'bookingDetails.isBookingRequired',
                            true,
                          );
                          setFieldValue('bookingDetails.startDate', null);
                          setFieldValue('bookingDetails.endDate', null);
                          setFieldValue('bookingDetails.startTime', null);
                          setFieldValue('bookingDetails.endTime', null);
                        }
                      }}
                    />
                  )}
                </Field>
                {values?.bookingDetails?.isFree && (
                  <Field name={'bookingDetails.isBookingRequired'}>
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        label={'Is Booking Required?'}
                        required={true}
                        formikField={field}
                        options={CommonService._staticData.yesNoOptions}
                        onChange={(val) => {
                          if (val === true) {
                            setFieldValue('bookingDetails.startDate', null);
                            setFieldValue('bookingDetails.endDate', null);
                            setFieldValue('bookingDetails.startTime', null);
                            setFieldValue('bookingDetails.endTime', null);
                          } else {
                            setFieldValue('bookingDetails.startDate', null);
                            setFieldValue('bookingDetails.endDate', null);
                            setFieldValue('bookingDetails.startTime', null);
                            setFieldValue('bookingDetails.endTime', null);
                            setFieldValue('bookingDetails.noOfTickets', '');
                          }
                        }}
                      />
                    )}
                  </Field>
                )}
                {!values.bookingDetails.isFree && (
                  <Field name={'bookingDetails.fee'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Fee for the event'}
                        placeholder={'Ex : ₹120'}
                        type={'text'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                )}
                {values.bookingDetails.isBookingRequired !== false && (
                  <>
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'bookingDetails.startDate'}>
                          {(field: FieldProps) => (
                            <FormikDatePickerComponent
                              label={'Booking Start Date'}
                              placeholder={'DD/MM/YYYY'}
                              // required={true}
                              formikField={field}
                              fullWidth={true}
                              minDate={new Date()}
                              maxDate={new Date(eventDetails?.date)}
                              onChange={(date: any) => {
                                setFieldValue('bookingDetails.endDate', null);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      {/*{values?.bookingDetails?.startDate && */}
                      <div className='ts-col-6'>
                        <Field name={'bookingDetails.endDate'}>
                          {(field: FieldProps) => (
                            <FormikDatePickerComponent
                              label={'Booking End Date'}
                              placeholder={'DD/MM/YYYY'}
                              // required={true}
                              formikField={field}
                              fullWidth={true}
                              // maxDate={new Date(previousDate)}
                              minDate={
                                new Date(values?.bookingDetails?.startDate)
                              }
                              maxDate={new Date(eventDetails?.date)}
                            />
                          )}
                        </Field>
                      </div>
                      {/*}*/}
                    </div>
                    <FormControlLabelComponent
                      label={'Booking Timings Everyday'}
                      level={4}
                      isBold={true}
                    />
                    <div className='ts-row'>
                      <div className='ts-col-6'>
                        <Field name={'bookingDetails.startTime'}>
                          {(field: FieldProps) => (
                            <FormikTimePickerComponent
                              label={'Booking Start Time'}
                              placeholder={'00:00'}
                              use12Hours={true}
                              format={'HH:mm'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                      <div className='ts-col-6'>
                        <Field name={'bookingDetails.endTime'}>
                          {(field: FieldProps) => (
                            <FormikTimePickerComponent
                              label={'Booking End Time'}
                              placeholder={'00:00'}
                              use12Hours={true}
                              format={'HH:mm'}
                              required={true}
                              formikField={field}
                              fullWidth={true}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <FormControlLabelComponent
                      label={'Ticket Details'}
                      level={4}
                      isBold={true}
                    />
                    <Field name={'bookingDetails.noOfTickets'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'No. of Tickets available'}
                          placeholder={'Ex : 120'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </>
                )}
                <FormControlLabelComponent
                  label={'Remind Before'}
                  level={4}
                  isBold={true}
                />
                <div className={'event-booking-label-style'}>
                  Select the number of hours prior to the event that a user will
                  receive a reminder
                </div>
                <Field name={'bookingDetails.selectReminder'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      options={CommonService._staticData.remainderTimeOptions}
                      displayWith={(option: any) => option.name}
                      valueExtractor={(option: any) => option._id}
                      label={'Select Reminder'}
                      placeholder={'Select'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventBookingComponent;
