import { useCallback } from 'react';
import { CommonService } from '../../services';
import ButtonComponent from '../button/ButtonComponent';
import FormControlLabelComponent from '../form-control-label/FormControlLabelComponent';
import FormikInputComponent from '../form-controls/formik-input/FormikInputComponent';
import FormikRadioButtonGroupComponent from '../form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import InputComponent from '../form-controls/input/InputComponent';
import './FloorDetailsDrawerComponent.scss';
import { FieldProps, Field, FieldArray } from 'formik';
import SwitchComponent from '../form-controls/switch/SwitchComponent';

interface FloorDetailsDrawerComponentProps {
  values: any;
  setInitialValues?: any;
  setFieldValue: any;
  towerId: any;
  closeDrawer: () => void;
}

const FloorDetailsDrawerComponent = (
  props: FloorDetailsDrawerComponentProps,
) => {
  const { values, setFieldValue, towerId, closeDrawer } = props;

  const handleBasementToggle = useCallback(() => {
    if (values?.towers[towerId]?.basementToggle) {
      setFieldValue(`towers[${towerId}].basementToggle`, false);
    } else {
      setFieldValue(`towers[${towerId}].basementToggle`, true);
      setFieldValue(`towers[${towerId}].floorToggle`, false);
    }
  }, [values, setFieldValue, towerId]);

  const handleFloorToggle = useCallback(() => {
    if (values?.towers[towerId]?.floorToggle) {
      setFieldValue(`towers[${towerId}].floorToggle`, false);
    } else {
      setFieldValue(`towers[${towerId}].floorToggle`, true);
      setFieldValue(`towers[${towerId}].basementToggle`, false);
    }
  }, [values, setFieldValue, towerId]);

  const handleLeasableAreaSameForAllBasements = useCallback(() => {
    setFieldValue(`towers[${towerId}].numberOfBasements`, '');
    setFieldValue(`towers[${towerId}].basementLeasableArea`, '');
    setFieldValue(`towers[${towerId}].basements`, []);
  }, [setFieldValue, towerId]);

  const handleLeasableAreaSameForAllFloors = useCallback(() => {
    setFieldValue(`towers[${towerId}].numberOfFloors`, '');
    setFieldValue(`towers[${towerId}].floorLeasableArea`, '');
    setFieldValue(`towers[${towerId}].officeFloors`, []);
    setFieldValue(`towers[${towerId}].includeGroundFloor`, false);
    setFieldValue(`towers[${towerId}].groundFloorLeasableArea`, '');
  }, [setFieldValue, towerId]);

  return (
    <div className='floor-details-container'>
      <div className='floor-details-content'>
        <div className='mrg-top-10 details-wrapper'>
          <div className='flex-wrapper pdd-10'>
            <SwitchComponent
              label={`Office Floors ${
                !values?.towers[towerId]?.floorToggle ? '(Optional)' : ''
              }`}
              checked={values?.towers[towerId]?.floorToggle}
              onChange={handleFloorToggle}
            />
            <ButtonComponent
              size='small'
              className='mrg-top-5'
              variant='link'
              color='error'
              type='reset'
              disabled={!values?.towers[towerId]?.floorToggle}
              onClick={handleLeasableAreaSameForAllFloors}
            >
              Clear Office Floors
            </ButtonComponent>
          </div>
          {values?.towers[towerId]?.floorToggle && (
            <div className='floor-details-card'>
              <div className='floor-details-leasable'>
                <FormControlLabelComponent
                  label='Is leasable area same for all office floors?'
                  level={5}
                />
                <div className='mrg-top-20'>
                  <Field
                    name={`towers[${towerId}].isLeasableAreaSameForAllFloors`}
                  >
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        formikField={field}
                        options={CommonService._staticData.yesNoOptions}
                        required={true}
                        keyExtractor={(option: any) => option.title}
                        onChange={(value) =>
                          handleLeasableAreaSameForAllFloors()
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              {values?.towers[towerId]?.isLeasableAreaSameForAllFloors ? (
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={`towers[${towerId}].numberOfFloors`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          placeholder='Example: 5'
                          label='No of Floors*'
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={new RegExp(/^[0-9]{0,3}$/)}
                          onChange={(value: any) => {
                            setFieldValue(
                              `towers[${towerId}].numberOfFloors`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={`towers[${towerId}].floorLeasableArea`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          placeholder='Example: 1,00,000sqft'
                          label='Floor Area*'
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                          onChange={(value: any) => {
                            setFieldValue(
                              `towers[${towerId}].floorLeasableArea`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              ) : (
                <div>
                  <Field name={`towers[${towerId}].numberOfFloors`}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        placeholder='Example: 5'
                        label='No of Floors*'
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        validationPattern={new RegExp(/^[0-9]{0,3}$/)}
                        onChange={(value: any) => {
                          const numberOfFloors = value ? parseInt(value) : '';
                          setFieldValue(
                            `towers[${towerId}].numberOfFloors`,
                            numberOfFloors,
                          );
                          if (!numberOfFloors) {
                            setFieldValue(
                              `towers[${towerId}].officeFloors`,
                              [],
                            );
                          }
                        }}
                      />
                    )}
                  </Field>
                  <SwitchComponent
                    checked={values?.towers[towerId]?.includeGroundFloor}
                    label='Include Ground Floor'
                    onChange={(value) => {
                      setFieldValue(
                        `towers[${towerId}].includeGroundFloor`,
                        !values?.towers[towerId]?.includeGroundFloor,
                      );
                      if (!value) {
                        setFieldValue(
                          `towers[${towerId}].groundFloorLeasableArea`,
                          '',
                        );
                      }
                    }}
                  />
                  <div>
                    {!values?.towers[towerId]?.isLeasableAreaSameForAllFloors &&
                      values?.towers[towerId]?.numberOfFloors > 0 && (
                        <FieldArray
                          name={`towers[${towerId}].officeFloors`}
                          render={(arrayHelpers: any) => {
                            return (
                              <div>
                                {values?.towers[towerId]
                                  ?.includeGroundFloor && (
                                  <div className='ts-row mrg-top-10'>
                                    <div className='ts-col-6'>
                                      <InputComponent
                                        value={'Ground Floor'}
                                        label='Floors*'
                                        placeholder='Example : 1'
                                        fullWidth={true}
                                        readOnly={true}
                                      />
                                    </div>
                                    <div className='ts-col-6'>
                                      <Field
                                        name={`towers[${towerId}].groundFloorLeasableArea`}
                                      >
                                        {(field: FieldProps) => (
                                          <FormikInputComponent
                                            placeholder='Example: 1,00,000sqft'
                                            label='Floor Area*'
                                            validationPattern={
                                              new RegExp(/^[1-9][0-9]*$/)
                                            }
                                            required={true}
                                            formikField={field}
                                            fullWidth={true}
                                            onChange={(value) => {
                                              const floorLeasableArea = value
                                                ? parseInt(value)
                                                : '';
                                              setFieldValue(
                                                `towers[${towerId}].groundFloorLeasableArea`,
                                                floorLeasableArea,
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                )}
                                {Array.from(
                                  {
                                    length:
                                      values?.towers[towerId]?.numberOfFloors,
                                  },
                                  (_, index) => index,
                                ).map((index: number) => {
                                  return (
                                    <div key={index} className='ts-row'>
                                      <div className='ts-col-6'>
                                        <InputComponent
                                          value={JSON.stringify(index + 1)}
                                          label='Floors*'
                                          placeholder='Example : 1'
                                          fullWidth={true}
                                          readOnly={true}
                                        />
                                      </div>
                                      <div className='ts-col-6'>
                                        <Field
                                          name={`towers[${towerId}].officeFloors[${index}].leasableArea`}
                                        >
                                          {(field: FieldProps) => (
                                            <FormikInputComponent
                                              placeholder='Example: 1,00,000sqft'
                                              label='Floor Area*'
                                              validationPattern={
                                                new RegExp(/^[1-9][0-9]*$/)
                                              }
                                              required={true}
                                              formikField={field}
                                              fullWidth={true}
                                              onChange={(value: string) => {
                                                const floorLeasableArea = value
                                                  ? parseInt(value)
                                                  : '';
                                                setFieldValue(
                                                  `towers[${towerId}].officeFloors[${index}].leasableArea`,
                                                  floorLeasableArea,
                                                );
                                                if (floorLeasableArea) {
                                                  setFieldValue(
                                                    `towers[${towerId}].officeFloors[${index}].floorNumber`,
                                                    index + 1,
                                                  );
                                                  setFieldValue(
                                                    `towers[${towerId}].officeFloors[${index}].floorTitle`,
                                                    (index + 1).toString(),
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }}
                        />
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='mrg-top-10 details-wrapper'>
          <div className='flex-wrapper pdd-10'>
            <SwitchComponent
              label={`Basements ${
                !values?.towers[towerId]?.basementToggle ? '(Optional)' : ''
              }`}
              checked={values?.towers[towerId]?.basementToggle}
              onChange={handleBasementToggle}
            />
            <ButtonComponent
              disabled={!values?.towers[towerId]?.basementToggle}
              size='small'
              className='mrg-top-5'
              variant='link'
              color='error'
              onClick={handleLeasableAreaSameForAllBasements}
            >
              Clear Basements
            </ButtonComponent>
          </div>
          {values?.towers[towerId]?.basementToggle && (
            <div className='floor-details-card'>
              <div className='floor-details-leasable'>
                <FormControlLabelComponent
                  label='Is leasable area same for all basements?'
                  level={5}
                />
                <div className='mrg-top-20'>
                  <Field
                    name={`towers[${towerId}].isLeasableAreaSameForAllBasement`}
                  >
                    {(field: FieldProps) => (
                      <FormikRadioButtonGroupComponent
                        formikField={field}
                        options={CommonService._staticData.yesNoOptions}
                        required={true}
                        keyExtractor={(option: any) => option.title}
                        onChange={(value: any) =>
                          handleLeasableAreaSameForAllBasements()
                        }
                      />
                    )}
                  </Field>
                </div>
              </div>
              {values?.towers[towerId]?.isLeasableAreaSameForAllBasement ? (
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={`towers[${towerId}].numberOfBasements`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          placeholder='Example: 5'
                          label='No of Basements*'
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={new RegExp(/^[0-9]{0,3}$/)}
                          onChange={(value) => {
                            setFieldValue(
                              `towers[${towerId}].numberOfBasements`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={`towers[${towerId}].basementLeasableArea`}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          placeholder='Example: 1,00,000sqft'
                          label='Basement Area*'
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                          onChange={(value) => {
                            setFieldValue(
                              `towers[${towerId}].basementLeasableArea`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              ) : (
                <div>
                  <Field name={`towers[${towerId}].numberOfBasements`}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        placeholder='Example: 5'
                        label='No of Basements*'
                        required={true}
                        validationPattern={new RegExp(/^[0-9]{0,3}$/)}
                        formikField={field}
                        fullWidth={true}
                        onChange={(value: string) => {
                          const numberOfBasements = value ? parseInt(value) : 0;
                          setFieldValue(
                            `towers[${towerId}].numberOfBasements`,
                            numberOfBasements,
                          );
                          if (!numberOfBasements) {
                            setFieldValue(`towers[${towerId}].basements`, []);
                          }
                        }}
                      />
                    )}
                  </Field>
                  <div>
                    {!values?.towers[towerId]
                      ?.isLeasableAreaSameForAllBasement &&
                      values?.towers[towerId]?.numberOfBasements > 0 && (
                        <FieldArray
                          name={`towers[${towerId}].basements`}
                          render={(arrayHelpers: any) => {
                            return (
                              <div>
                                {Array.from(
                                  {
                                    length:
                                      values?.towers[towerId]
                                        ?.numberOfBasements,
                                  },
                                  (_, index) => index,
                                ).map((index: number) => {
                                  return (
                                    <div key={index} className='ts-row'>
                                      <div className='ts-col-6'>
                                        <InputComponent
                                          value={JSON.stringify(index + 1)}
                                          label='Basement*'
                                          placeholder='Example : 1'
                                          fullWidth={true}
                                          readOnly={true}
                                        />
                                      </div>
                                      <div className='ts-col-6'>
                                        <Field
                                          name={`towers[${towerId}].basements[${index}].leasableArea`}
                                        >
                                          {(field: FieldProps) => (
                                            <FormikInputComponent
                                              placeholder='Example: 1,00,000sqft'
                                              label='Basement Area*'
                                              validationPattern={
                                                new RegExp(/^[1-9][0-9]*$/)
                                              }
                                              required={true}
                                              formikField={field}
                                              fullWidth={true}
                                              onChange={(value: string) => {
                                                const basementLeasableArea =
                                                  value ? parseInt(value) : '';
                                                setFieldValue(
                                                  `towers[${towerId}].basements[${index}].leasableArea`,
                                                  basementLeasableArea,
                                                );
                                                if (basementLeasableArea) {
                                                  setFieldValue(
                                                    `towers[${towerId}].basements[${index}].basementNumber`,
                                                    index + 1,
                                                  );
                                                  setFieldValue(
                                                    `towers[${towerId}].basements[${index}].basementTitle`,
                                                    (index + 1).toString(),
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }}
                        />
                      )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className='floor-details-buttons-wrapper'>
        <ButtonComponent
          onClick={closeDrawer}
          fullWidth
          variant='outlined'
          color='error'
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent fullWidth onClick={closeDrawer}>
          Save Details
        </ButtonComponent>
      </div>
    </div>
  );
};

export default FloorDetailsDrawerComponent;
