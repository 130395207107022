import React, { useCallback } from 'react';
import './InvoiceFilterComponent.scss';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import DateRangePickerComponent from '../../../shared/components/form-controls/date-range-picker/DateRangePickerComponent';
import CheckBoxComponent from '../../../shared/components/form-controls/check-box/CheckBoxComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';
import {
  setInvoiceDateRangeInFilters,
  setInvoiceFilterCount,
  setInvoicedateFilterOnInFilters,
} from '../../../store/actions/invoice.action';

interface InvoiceFilterComponentProps {
  filterData: any;
  setFilterData: any;
  closeFilter: () => void;
  module: string;
}

const InvoiceFilterComponent = (props: InvoiceFilterComponentProps) => {
  const { filterData, setFilterData, closeFilter, module } = props;
  const dispatch = useDispatch();
  const { dateRange, dateFilterOn } = useSelector(
    (state: IRootReducerState) => state.invoice,
  );

  const MoreFilterCheckboxData = [
    {
      title:
        module === 'invoice'
          ? 'Invoice Generated Date'
          : 'Debit Note Generated Date',
      code: 'createdAt',
    },
    {
      title: 'GST Generated Date',
      code: 'generatedDate',
    },
    {
      title: module === 'invoice' ? 'Invoice Due Date' : 'Debit Note Due Date',
      code: 'invoiceDueDate',
    },
    {
      title: module === 'invoice' ? 'Invoice Paid On' : 'Debit Note Paid On',
      code: 'invoicePaidDate',
    },
  ];

  const handleFilterApply = useCallback(() => {
    let countValue = 0;
    if (dateRange[0] !== null) {
      countValue++;
    }
    if (dateFilterOn) {
      countValue++;
    }
    dispatch(setInvoiceFilterCount(countValue));

    if (dateRange[1] === null) {
      setFilterData({
        ...filterData,
        startDate: dateRange[0],
        endDate: new Date(),
        dateFilterOn: dateFilterOn,
        page: 1,
      });
    } else {
      setFilterData({
        ...filterData,
        startDate: dateRange[0],
        endDate: dateRange[1],
        dateFilterOn: dateFilterOn,
        page: 1,
      });
    }
    closeFilter();
  }, [
    dateRange,
    dateFilterOn,
    filterData,
    setFilterData,
    dispatch,
    closeFilter,
  ]);

  const getPastMonth = useCallback(() => {
    const today = new Date();
    const lastDayofMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    return lastDayofMonth;
  }, []);

  return (
    <div className='invoice-more-filter-component'>
      <div>
        <FormControlLabelComponent label='Date' level={4} isBold />
        <DateRangePickerComponent
          fullWidth
          clearable
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          maxDate={getPastMonth()}
          placeholder='Date / Date Range'
          onChange={(value: any) => {
            dispatch(setInvoiceDateRangeInFilters(value));
          }}
        />
      </div>
      <div className='invoice-more-filter-checkbox-wrapper'>
        <FormControlLabelComponent
          label='Apply date filter on'
          level={4}
          isBold
        />
        <div className='invoice-more-filter-checkboxes'>
          {MoreFilterCheckboxData?.map((data: any) => {
            return (
              <CheckBoxComponent
                checked={dateFilterOn === data.code}
                key={data.code}
                label={data.title}
                value={dateFilterOn}
                onChange={(value: any) => {
                  if (dateFilterOn === data.code) {
                    dispatch(setInvoicedateFilterOnInFilters(''));
                  } else {
                    dispatch(setInvoicedateFilterOnInFilters(data.code));
                  }
                }}
              />
            );
          })}
        </div>
      </div>
      <div className='invoice-more-filter-button-wrapper'>
        <ButtonComponent
          fullWidth
          type='submit'
          variant='contained'
          color='primary'
          onClick={handleFilterApply}
          disabled={dateRange[0] === null || dateFilterOn.length === 0}
        >
          Apply
        </ButtonComponent>
      </div>
    </div>
  );
};

export default InvoiceFilterComponent;
