import {Outlet} from "react-router-dom";

interface WallRouterComponentProps {

}

const WallRouterComponent = (props: WallRouterComponentProps) => {

    return (
        <Outlet/>
    );

};

export default WallRouterComponent;
