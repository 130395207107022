import "./AddEventsComponent.scss";
import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import EventMoreInfoComponent from "./event-moreInfo/EventMoreInfoComponent";
import EventDetailsComponent from "./event-details/EventDetailsComponent";
import EventBookingComponent from "./event-booking/EventBookingComponent";
import EventGalleryComponent from "./event-gallery/EventGalleryComponent";
import EventPerformerInfoComponent from "./event-performers/EventPerformerInfoComponent";

interface AddEventsComponentProps {

}

const EVENT_DETAILS = 'event-details';
const PERFORMER_DETAILS = 'performer-details';
const MORE_DETAILS = 'more-details';
const BOOKING_DETAILS = 'booking-details';
const GALLERY_DETAILS = 'gallery-details';

const STEPS = [
    {
        title: 'Event Details',
        id: EVENT_DETAILS,
    },
    {
        title: 'Performer Details',
        id: PERFORMER_DETAILS,
    },
    {
        title: 'Booking Details',
        id: BOOKING_DETAILS,
    },
    {
        title: 'More Details',
        id: MORE_DETAILS,
    },
    {
        title: 'Gallery Details',
        id: GALLERY_DETAILS,
    },
];

const AddEventsComponent = (props: AddEventsComponentProps) => {
    const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
    const navigate = useNavigate();
    // const [activeStepId, setActiveStepId] = useState<string>(steps[0].id);
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
    const params = useParams();
    const [eventId, setEventId] = useState<any>(params.eventId);
    const [eventDetails, setEventDetails] = useState<any>(undefined);
    const [isEventDetailsLoading, setIsEventDetailsLoading] = useState<boolean>(false);
    const location: any = useLocation();
    const path = location.pathname;

    const updateSteps = useCallback((currentStepIndex: number) => {
        setCurrentStep(steps[currentStepIndex]);
        searchParams.set("step", steps[currentStepIndex].id);
        setSearchParams(searchParams);
        // setActiveStepId(steps[currentStepIndex].id);
    }, [searchParams, setSearchParams, steps]);

    const getEventDetails = useCallback(() => {
        const payload = {};
        let eventDetails: any = {};
        setIsEventDetailsLoading(true);
        CommonService._events.GetEventDetailsAPICall(eventId, payload)
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    eventDetails = response.data;
                }
                setEventDetails(eventDetails);
                setIsEventDetailsLoading(false);
            })
            .catch((error: any) => {
                // setEventDetails(eventDetails);
                setIsEventDetailsLoading(false);
            });
    }, [eventId]);

    useEffect(() => {
        if (eventId) {
            getEventDetails();
        }
    }, [getEventDetails, eventId])

    useEffect(() => {
        const step: any = searchParams.get("step");
        // const stepperIndex = steps.findIndex(stepItem => stepItem.id === step);
        // setActiveStepId(steps[stepperIndex]?.id);
        const eventId = searchParams.get("eventId");
        if (eventId) setEventId(eventId);
        const stepIDS = steps.map(item => item.id);
        let stepIndex: number = 0;
        if (stepIDS.indexOf(step) > -1) {
            if (step !== EVENT_DETAILS) {
                stepIndex = stepIDS.indexOf(step);
            } else {
                stepIndex = 0;
            }
        } else {
            stepIndex = 0;
        }
        setCurrentStep(steps[stepIndex]);
    }, [searchParams, steps]);

    const handlePrevious = useCallback((currentStepIndex: number, currentStepId: string) => {
        if ([PERFORMER_DETAILS, BOOKING_DETAILS, MORE_DETAILS, GALLERY_DETAILS].includes(currentStepId)) {
            updateSteps(currentStepIndex);
        } else {
            // do nothing;
        }
    }, [updateSteps]);

    const handleBack = useCallback(() => {
        if (path.includes('/event/add')) {
            navigate(CommonService._routeConfig.EventsRoute());
        } else {
            navigate(CommonService._routeConfig.EventDetails(eventId));
        }
    }, [navigate, eventId,path])

    const handleNext = useCallback((currentStepIndex: number, currentStepId: string, data?: any) => {
        switch (currentStepId) {
            case EVENT_DETAILS :
                if (data) {
                    setEventId(data._id);
                    searchParams.set("eventId", data._id.toString());
                    setSearchParams(searchParams);
                    setEventDetails((oldSate: any) => {
                    return {
                        ...oldSate,
                        category: data.category,
                        name: data.name,
                        description: data.description,
                        agenda: data.agenda,
                        date: data.date,
                        time: data.time,
                        duration: data.duration,
                        propertyId: data.propertyId,
                        allowedProperties: data.allowedProperties,
                    }
                })
                }
                updateSteps(currentStepIndex);
                break;

            case MORE_DETAILS:
                if (data) {
                    setEventDetails((oldSate: any) => {
                        return {
                            ...oldSate,
                            termsAndConditions: data.termsAndConditions,
                            eventFAQ: data.eventFAQ,
                        }
                    })
                }
                updateSteps(currentStepIndex);
                break;

            case PERFORMER_DETAILS:
                if (data) {
                    setEventDetails((oldSate: any) => {
                        return {
                            ...oldSate,
                            isPerformerExists: data.isPerformerExists,
                            performers: data.performers,
                            // answerOptions: data.answerOptions
                        }
                    })
                }
                updateSteps(currentStepIndex);
                break;

            case BOOKING_DETAILS:
                if (data) {
                    setEventDetails((oldSate: any) => {
                        return {
                            ...oldSate,
                            isBookingRequired: data.isBookingRequired,
                            // isFree: data.isFree,
                            bookingDetails: data.bookingDetails
                        }
                    })
                }
                updateSteps(currentStepIndex);
                break;
            case GALLERY_DETAILS:
                handleBack();
                break;
            default:
                <></>
        }
    }, [updateSteps, handleBack, searchParams, setSearchParams]);

    const renderComponent = useCallback(() => {
        switch (currentStep && currentStep.id) {
            case EVENT_DETAILS:
                return <EventDetailsComponent
                    next={(data: any) => handleNext(1, EVENT_DETAILS, data)}
                    prev={handleBack}
                    steps={steps}
                    eventId={eventId}
                    activeStepId={currentStep.id}
                    eventDetails={eventDetails}
                />
            case PERFORMER_DETAILS:
                return <EventPerformerInfoComponent
                    eventDetails={eventDetails}
                    next={(data: any) => handleNext(2, PERFORMER_DETAILS, data)}
                    prev={() => handlePrevious(0, PERFORMER_DETAILS)}
                    steps={steps}
                    eventId={eventId}
                    activeStepId={currentStep.id}
                />
            case BOOKING_DETAILS:
                return <EventBookingComponent
                    eventDetails={eventDetails}
                    next={(data) => handleNext(3, BOOKING_DETAILS, data)}
                    prev={() => handlePrevious(1, BOOKING_DETAILS)}
                    steps={steps}
                    eventId={eventId}
                    activeStepId={currentStep.id}
                />
            case MORE_DETAILS:
                return <EventMoreInfoComponent
                    eventDetails={eventDetails}
                    next={(data: any) => handleNext(4, MORE_DETAILS, data)}
                    prev={() => handlePrevious(2, MORE_DETAILS)}
                    steps={steps}
                    eventId={eventId}
                    activeStepId={currentStep.id}
                />
            case GALLERY_DETAILS:
                return <EventGalleryComponent
                    eventDetails={eventDetails}
                    eventId={eventId}
                    next={() => handleBack()}
                    prev={() => handlePrevious(3, GALLERY_DETAILS)}
                    steps={steps}
                    activeStepId={currentStep.id}
                />
            default:
                <></>
        }
    }, [currentStep, eventId, handleNext, handlePrevious, eventDetails, handleBack, steps]);

    return (
        <div className={'add-polls-component add-screen'}>
            {
                isEventDetailsLoading ?
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div> : <>
                        {renderComponent()}
                    </>
            }
        </div>
    );

};

export default AddEventsComponent;
