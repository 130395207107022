export const GET_AMENITY_LIST_LITE = 'GET_AMENITY_LIST_LITE';
export const SET_AMENITY_LIST_LITE = 'SET_AMENITY_LIST_LITE';
export const GET_ALL_AMENITY_LIST = 'GET_ALL_AMENITY_LIST';
export const SET_ALL_AMENITY_LIST = 'SET_ALL_AMENITY_LIST';

export const getAmenityListLite = () => {
  return { type: GET_AMENITY_LIST_LITE };
};

export const setAmenityListLite = (amenityList: any[]) => {
  return {
    type: SET_AMENITY_LIST_LITE,
    payload: {
      amenityList,
    },
  };
};

export const getAllAmenityList = () => {
  return {
    type: GET_ALL_AMENITY_LIST,
  };
};

export const setAllAmenityList = (allAmenityList: any[]) => {
  return {
    type: SET_ALL_AMENITY_LIST,
    payload: {
      allAmenityList,
    },
  };
};
