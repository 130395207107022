import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const GetAmenitiesList = () => {
  // @ts-ignore
  return ApiService[APIConfig.GET_AMENITIES_LIST.METHOD](
    APIConfig.GET_AMENITIES_LIST.URL,
  );
};

const getAmenityListAPICall = () => {
  // @ts-ignore
  return ApiService[APIConfig.GET_AMENITIES_TYPES.METHOD](
    APIConfig.GET_AMENITIES_TYPES.URL,
  );
};

const AddAmenityDetails = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_AMENITIES_DETAILS.METHOD](
    APIConfig.ADD_AMENITIES_DETAILS.URL,
    payload,
  );
};

const EditAmenityDetails = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_AMENITIES_DETAILS.METHOD](
    APIConfig.EDIT_AMENITIES_DETAILS.URL(amenityId),
    payload,
  );
};

const addAmenityOperatorDetails = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_AMENITY_OPERATOR_DETAILS.METHOD](
    APIConfig.ADD_AMENITY_OPERATOR_DETAILS.URL(amenityId),
    payload,
  );
};

const GetAmenityDetailsAPICall = (amenityId: string, payload: any = {}) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_AMENITY_DETAILS.METHOD](
    APIConfig.GET_AMENITY_DETAILS.URL(amenityId),
    payload,
  );
};

const UpdateAmenityUnavailabilityAPICall = (
  amenityId: string,
  payload: any = {},
) => {
  // @ts-ignore
  return ApiService[APIConfig.UPDATE_AMENITY_UNAVAILABILITY.METHOD](
    APIConfig.UPDATE_AMENITY_UNAVAILABILITY.URL(amenityId),
    payload,
  );
};

const AddOperationDetails = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_OPERATION_DETAILS.METHOD](
    APIConfig.ADD_OPERATION_DETAILS.URL(amenityId),
    payload,
  );
};

const AddTimingsDetails = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TIMING_DETAILS.METHOD](
    APIConfig.ADD_TIMING_DETAILS.URL(amenityId),
    payload,
  );
};

const toggleAmenityAPICall = (amenityId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_AMENITY.METHOD](
    APIConfig.TOGGLE_AMENITY.URL(amenityId),
  );
};

const getAmenityReviewsListAPICall = (amenityId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_AMENITIES_REVIEWS_LIST.METHOD](
    APIConfig.GET_AMENITIES_REVIEWS_LIST.URL(amenityId),
  );
};

const getAmenityGalleryListAPICall = (amenityId: string, payload: any = {}) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_AMENITIES_GALLERY_LIST.METHOD](
    APIConfig.GET_AMENITIES_GALLERY_LIST.URL(amenityId),
    payload,
  );
};

const uploadAmenityGalleryAPICall = (amenityId: string, payload: any = {}) => {
  // @ts-ignore
  return ApiService[APIConfig.UPLOAD_AMENITIES_GALLERY.METHOD](
    APIConfig.UPLOAD_AMENITIES_GALLERY.URL(amenityId),
    payload,
  );
};

const uploadAmenityProfilePicAPICall = (
  amenityId: string,
  payload: any = {},
) => {
  // @ts-ignore
  return ApiService[APIConfig.UPLOAD_AMENITIES_PROFILE_PIC.METHOD](
    APIConfig.UPLOAD_AMENITIES_PROFILE_PIC.URL(amenityId),
    payload,
  );
};
const uploadAmenityMobilePicAPICall = (
  amenityId: string,
  payload: any = {},
) => {
  // @ts-ignore
  return ApiService[APIConfig.UPLOAD_AMENITIES_MOBILE_PIC.METHOD](
    APIConfig.UPLOAD_AMENITIES_MOBILE_PIC.URL(amenityId),
    payload,
  );
};

const removeAmenityGalleryImageAPICall = (
  amenityId: string,
  payload: any = {},
) => {
  // @ts-ignore
  return ApiService[APIConfig.REMOVE_AMENITIES_GALLERY_IMAGE.METHOD](
    APIConfig.REMOVE_AMENITIES_GALLERY_IMAGE.URL(amenityId),
    payload,
  );
};

const deleteAmenityAdminDetailsAPICall = (
  amenityId: string,
  adminId: string,
) => {
  return ApiService[APIConfig.DELETE_TENANT_ADMIN_DETAILS.METHOD](
    APIConfig.DELETE_TENANT_ADMIN_DETAILS.URL(amenityId, adminId),
  );
};

const AddAmenityTowerDetailsAPICall = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_AMENITY_TOWER_DETAILS.METHOD](
    APIConfig.ADD_AMENITY_TOWER_DETAILS.URL(amenityId),
    payload,
  );
};

const AddAmenityBillingDetailsAPICall = (amenityId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_AMENITY_BILLING_DETAILS.METHOD](
    APIConfig.ADD_AMENITY_BILLING_DETAILS.URL(amenityId),
    payload,
  );
};

const getAmenityBillingComponentsAPICall = (amenityId: string) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_AMENITY_BILLING_COMPONENTS.METHOD](
    APIConfig.GET_AMENITY_BILLING_COMPONENTS.URL(amenityId),
  );
};

const downloadAmenityListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.GET_AMENITIES_PAGINATED_LIST.METHOD](
    APIConfig.GET_AMENITIES_PAGINATED_LIST.URL + `?download=true`,
  );
};

const AmenityService = {
  GetAmenitiesList,
  getAmenityListAPICall,
  AddAmenityDetails,
  GetAmenityDetailsAPICall,
  AddOperationDetails,
  AddTimingsDetails,
  toggleAmenityAPICall,
  uploadAmenityGalleryAPICall,
  uploadAmenityProfilePicAPICall,
  getAmenityGalleryListAPICall,
  removeAmenityGalleryImageAPICall,
  getAmenityReviewsListAPICall,
  UpdateAmenityUnavailabilityAPICall,
  uploadAmenityMobilePicAPICall,
  EditAmenityDetails,
  deleteAmenityAdminDetailsAPICall,
  AddAmenityTowerDetailsAPICall,
  AddAmenityBillingDetailsAPICall,
  getAmenityBillingComponentsAPICall,
  addAmenityOperatorDetails,
  downloadAmenityListAsExcel,
};

export default AmenityService;
