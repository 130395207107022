import './AmenityDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import * as Yup from 'yup';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import FormikTextAreaComponent from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikCheckBoxComponent from '../../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent';
import BadgeComponent from '../../../../shared/components/badge/BadgeComponent';
import { getPropertyListLite } from '../../../../store/actions/property.action';
import { getEntityListLite } from '../../../../store/actions/entity.action';
import ModalComponent from '../../../../shared/components/modal/ModalComponent';
import IconButtonComponent from '../../../../shared/components/icon-button/IconButtonComponent';
import InputComponent from '../../../../shared/components/form-controls/input/InputComponent';

interface AmenityDetailsComponentProps {
  amenityDetails?: any;
  amenityId?: string;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
}

const validationSchema = Yup.object({
  type: Yup.string().required('Amenity Type is required'),
  entityId: Yup.string().required('Entity is Required').nullable(),
  propertyId: Yup.string().required('Property is Required').nullable(),
  name: Yup.string()
    .min(3, 'Amenity Operator Brand should be at least 3 characters')
    .max(255, 'Amenity Operator Brand should not exceed 255 characters')
    .required('Amenity Operator Brand is required')
    .nullable(),
  shortDescription: Yup.string()
    .min(20, 'Amenity description should be at least 20 characters')
    .max(60, 'Amenity description should not exceed 60 characters')
    .required('Amenity description is required'),
  websiteLink: Yup.string().url('Invalid URL').notRequired(),
  capacity: Yup.string().nullable(),
  isSezZone: Yup.string().required('Sez Zone is required').nullable(),
  igst: Yup.string().when('isSezZone', {
    is: 'true',
    then: Yup.string().required('IGST is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  vendorUrl: Yup.string()
    .url('Invalid vendor url')
    .required('Vendor Url is required'),
  numberOfParkingSpacesOccupied: Yup.string().when('step', {
    is: 'add',
    then: (schema) =>
      schema
        .required('Number of slots occupied is required')
        .test(
          'is-valid-number-or-empty',
          'Number of slots occupied must be a valid number or empty',
          function (value) {
            // If the field is empty, pass the validation
            if (value === undefined || value === '') {
              return true;
            }
            const numValue = Number(value);
            // Proceed with the validation only if the value is a number
            return !isNaN(numValue) && isFinite(numValue);
          },
        )
        .test(
          'is-less-than-available-slots',
          'Number of slots occupied cannot be greater than available slots',
          function (value) {
            // Skip this test if the value is empty
            if (value === undefined || value === '') {
              return true;
            }
            const availableSlots = this.parent.availableSlots || 0;
            return value <= availableSlots;
          },
        ),
    otherwise: (schema) => schema.notRequired(),
  }),

  additionalParkingSpaceOccupied: Yup.string().when(
    'isAdditionalSlotRequired',
    {
      is: true,
      then: (schema) =>
        schema
          .required('Number of slots occupied is required')
          .test(
            'is-valid-number-or-empty',
            'Number of slots occupied must be a valid number or empty',
            function (value) {
              // If the field is empty, pass the validation
              if (value === undefined || value === '') {
                return true;
              }
              const numValue = Number(value);
              // Proceed with the validation only if the value is a number
              return !isNaN(numValue) && isFinite(numValue);
            },
          )
          .test(
            'is-less-than-available-slots',
            'Number of slots occupied cannot be greater than available slots',
            function (value) {
              // Skip this test if the value is empty
              if (value === undefined || value === '') {
                return true;
              }
              const availableSlots = this.parent.availableSlots || 0;
              return value <= availableSlots;
            },
          ),
      otherwise: (schema) => schema.notRequired(),
    },
  ),
  amenityTagline: Yup.string()
    .min(3, 'Amenity Tagline should be at least 3 characters')
    .max(30, 'Amenity Tagline should not exceed 30 characters'),
  placeOfService: Yup.object({
    addressLine1: Yup.string()
      .min(3, 'Address should be at least 3 characters')
      .max(50, 'Address should not exceed 50 characters')
      .required('Address line is required'),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, 'Pincode should have a total of 6 characters')
      .max(6, 'Pincode should not exceed 6 characters')
      .required('Pincode is required'),
    state: Yup.string().required('State is required').nullable(),
    city: Yup.string()
      .min(1, 'City should be at least 1 character')
      .max(30, 'City should not exceed 30 characters')
      .required('City is required'),
    country: Yup.string()
      .min(1, 'Country should be at least 1 character')
      .max(30, 'Country should not exceed 30 characters')
      .required('Country is required'),
  }),
  billingAddress: Yup.object().when('isBillingAddressSameAsPlaceOfService', {
    is: false,
    then: Yup.object({
      addressLine1: Yup.string()
        .min(3, 'Address should be at least 3 characters')
        .max(50, 'Address should not exceed 50 characters')
        .required('Address line is required'),
      pincode: Yup.string()
        .matches(
          /^[1-9][0-9]{5}$/,
          'Pincode should have a total of 6 characters',
        )
        .max(6, 'Pincode should not exceed 6 characters')
        .required('Pincode is required'),
      state: Yup.string().required('State is required').nullable(),
      city: Yup.string()
        .min(1, 'City should be at least 1 character')
        .max(30, 'City should not exceed 30 characters')
        .required('City is required'),
      country: Yup.string()
        .min(1, 'Country should be at least 1 character')
        .max(30, 'Country should not exceed 30 characters')
        .required('Country is required'),
    }),
  }),
});

interface formInitialValuesFormSchema {
  type: string;
  entityId: string;
  propertyId: string;
  amenityId?: string;
  name: string;
  amenityTagline: string;
  shortDescription: string;
  websiteLink: string;
  capacity: number | null;
  vendorUrl: string;
  availableSlots: number;
  step: string;
  numberOfParkingSpacesOccupied: string;
  additionalParkingSpaceOccupied: string;
  isAdditionalSlotRequired: boolean;
  isSezZone: string;
  igst: string;
  placeOfService: {
    addressLine1: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    pincode: string;
  };
  isBillingAddressSameAsPlaceOfService: boolean;
  billingAddress: {
    addressLine1: string;
    city: string;
    state: string;
    stateCode: string;
    country: string;
    pincode: string;
  };
}

const formInitialValues: formInitialValuesFormSchema = {
  type: '',
  entityId: '',
  propertyId: '',
  name: '',
  amenityTagline: '',
  shortDescription: '',
  websiteLink: '',
  capacity: 0,
  vendorUrl: '',
  availableSlots: 0,
  step: 'add',
  numberOfParkingSpacesOccupied: '',
  additionalParkingSpaceOccupied: '',
  isAdditionalSlotRequired: false,
  isSezZone: '',
  igst: '',
  placeOfService: {
    addressLine1: '',
    city: '',
    state: '',
    stateCode: '',
    country: 'India',
    pincode: '',
  },
  isBillingAddressSameAsPlaceOfService: false,
  billingAddress: {
    addressLine1: '',
    city: '',
    state: '',
    stateCode: '',
    country: '',
    pincode: '',
  },
};

//igst select dropdown options
const igstOptions = [
  {
    code: '0',
    title: '0%',
  },
  {
    code: '18',
    title: '18%',
  },
];

const AmenityDetailsComponent = (props: AmenityDetailsComponentProps) => {
  const { amenityDetails, prev, next, steps, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const [amenityTypes, setAmenityTypes] = useState<any[] | undefined>(
    undefined,
  );
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );
  const { entityList } = useSelector(
    (state: IRootReducerState) => state.entity,
  );

  const dispatch = useDispatch();

  const [updatedPropertyList, setUpdatedPropertyList] = useState<any>([]);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  useEffect(() => {
    dispatch(getPropertyListLite());
    dispatch(getEntityListLite());
  }, [dispatch]);

  const handleUpdatedPropertyList = useCallback(
    (entityId: any) => {
      const filterData = propertyList?.filter(
        (property: any) => property?.billingEntityId === entityId,
      );
      setUpdatedPropertyList(filterData);
    },
    [propertyList],
  );

  const getAmenityListLite = useCallback(() => {
    CommonService._amenity
      .getAmenityListAPICall()
      .then((response: IAPIResponseType<any>) => {
        setAmenityTypes(response.data);
        // CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
      })
      .catch((error: any) => {
        CommonService._alert.showToast(
          error[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      });
  }, []);

  const calculateAvailableSlots = useCallback(
    (propertyId: string) => {
      let ParkingSpaces;
      const property = propertyList.find(
        (property: any) => property._id === propertyId,
      );
      if (property?.campusDetails?.numberOfRemainingParkingSpaces) {
        ParkingSpaces = property?.campusDetails?.numberOfRemainingParkingSpaces;
      }
      return ParkingSpaces;
    },
    [propertyList],
  );

  useEffect(() => {
    if (amenityDetails) {
      setInitialValues({
        amenityId: amenityDetails?._id,
        type: amenityDetails?.type,
        entityId: amenityDetails?.entityId,
        propertyId: amenityDetails?.propertyId,
        name: amenityDetails?.name,
        amenityTagline: amenityDetails?.amenityTagline,
        shortDescription: amenityDetails?.shortDescription,
        websiteLink: amenityDetails?.websiteLink,
        capacity: amenityDetails?.capacity || null,
        vendorUrl: amenityDetails?.vendorUrl,
        availableSlots:
          calculateAvailableSlots(amenityDetails?.propertyId) || 0,
        additionalParkingSpaceOccupied: '',
        isAdditionalSlotRequired: false,
        step: 'edit',
        numberOfParkingSpacesOccupied:
          amenityDetails?.numberOfParkingSpacesOccupied,
        isSezZone: amenityDetails?.isSezZone.toString(),
        igst: amenityDetails?.isSezZone && amenityDetails?.igst.toString(),
        placeOfService: {
          addressLine1: amenityDetails?.placeOfService?.addressLine1,
          city: amenityDetails?.placeOfService?.city,
          state: amenityDetails?.placeOfService?.state,
          stateCode: amenityDetails?.placeOfService?.stateCode,
          country: amenityDetails?.placeOfService?.country,
          pincode: amenityDetails?.placeOfService?.pincode,
        },
        isBillingAddressSameAsPlaceOfService:
          amenityDetails?.isBillingAddressSameAsPlaceOfService,
        billingAddress: {
          addressLine1: amenityDetails?.billingAddress?.addressLine1,
          city: amenityDetails?.billingAddress?.city,
          state: amenityDetails?.billingAddress?.state,
          stateCode: amenityDetails?.billingAddress?.stateCode,
          country: amenityDetails?.billingAddress?.country,
          pincode: amenityDetails?.billingAddress?.pincode,
        },
      });
      handleUpdatedPropertyList(amenityDetails?.entityId);
    } else {
      setInitialValues({
        ...formInitialValues,
        propertyId: currentUser?.propertyId,
      });
    }
  }, [
    amenityDetails,
    currentUser?.propertyId,
    calculateAvailableSlots,
    handleUpdatedPropertyList,
  ]);

  useEffect(() => {
    getAmenityListLite();
  }, [getAmenityListLite]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      const amenityId = values.amenityId;
      if (values.capacity === 0 || values.capacity === null) {
        delete values.capacity;
      } else {
        values.capacity = parseInt(values.capacity);
      }
      const payload = {
        ...values,
        numberOfParkingSpacesOccupied:
          values?.numberOfParkingSpacesOccupied +
          values?.additionalParkingSpaceOccupied,
        additionalParkingSpaceOccupied: '',
      };
      delete payload.amenityId;
      if (amenityId) {
        CommonService._amenity
          .EditAmenityDetails(amenityId, payload)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        CommonService._amenity
          .AddAmenityDetails(payload)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [next],
  );

  return (
    <div className={'amenity-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ ...initialValues }}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
          submitForm,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                  disabled={!isValid}
                  onClick={() => {
                    if (!amenityDetails?._id) {
                      setOpenWarningModal(true);
                    } else {
                      submitForm();
                    }
                  }}
                >
                  Next
                </ButtonComponent>
              </div>

              <ModalComponent
                className='confirmation-modal'
                isOpen={openWarningModal}
                onClose={() => setOpenWarningModal(false)}
                modalFooter={
                  <>
                    <ButtonComponent
                      onClick={() => {
                        setOpenWarningModal(false);
                      }}
                      color='default'
                      variant='outlined'
                      className={'pdd-left-60 pdd-right-60 mrg-right-20'}
                    >
                      Cancel
                    </ButtonComponent>
                    <ButtonComponent
                      color='primary'
                      variant='contained'
                      className={'pdd-left-60 pdd-right-60'}
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      onClick={() => {
                        submitForm();
                        setOpenWarningModal(false);
                      }}
                    >
                      Confirm
                    </ButtonComponent>
                  </>
                }
              >
                <div className='confirmation-dialog-container'>
                  <div className='confirmation-dialog-content-wrapper'>
                    <div className={'confirmation-dialog-title'}>
                      Finalize Property Selection
                    </div>
                    <div className='confirmation-dialog-sub-description'>
                      Property once selected cannot be edited. Are you sure you
                      want to proceed?
                    </div>
                  </div>
                </div>
              </ModalComponent>
              <div className='add-component-content'>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'entityId'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          options={entityList}
                          disabled={amenityDetails?._id}
                          displayWith={(option: any) => option.name}
                          valueExtractor={(option: any) => option._id}
                          label={'Entity'}
                          placeholder={'Select Entity'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          onUpdate={(value) => {
                            if (value) {
                              handleUpdatedPropertyList(value);
                            } else {
                              setFieldValue(`propertyId`, '');
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'propertyId'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          disabled={
                            currentUser?.roleDetails?.code === 'fm' ||
                            amenityDetails?._id
                          }
                          options={updatedPropertyList}
                          displayWith={(option: any) => option.name}
                          valueExtractor={(option: any) => option._id}
                          label={'Property'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          noDataMessage={'No property in the selected entity'}
                          onUpdate={(value: any) => {
                            if (value) {
                              const availableSlots =
                                calculateAvailableSlots(value);
                              setFieldValue('availableSlots', availableSlots);
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div>
                  <Field name={'type'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        options={amenityTypes}
                        displayWith={(option: any) => option.title}
                        valueExtractor={(option: any) => option._id}
                        label={'Amenity Type'}
                        // placeholder={'Select Amenity Type'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                </div>
                <Field name={'name'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Amenity Operator Brand'}
                      placeholder={'Example: Naturals Saloon'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <Field name={'amenityTagline'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={
                        'Operator Tagline(add a catchy tagline for the amenity)'
                      }
                      placeholder={'Example: Sizzling Food and Good vibes'}
                      type={'text'}
                      required={false}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <Field name={'shortDescription'}>
                  {(field: FieldProps) => (
                    <FormikTextAreaComponent
                      label={'Short Description'}
                      placeholder={'Write the amenity description here'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'websiteLink'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Operator Website'}
                          placeholder={'Paste the link here'}
                          type={'text'}
                          required={false}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'capacity'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Capacity'}
                          placeholder={'Ex: 100'}
                          type={'text'}
                          required={false}
                          validationPattern={new RegExp(/^[0-9]*$/)}
                          formikField={field}
                          fullWidth={true}
                          onChange={(value) => {
                            setFieldValue(
                              `capacity`,
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Field name={'vendorUrl'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={
                        'Vendor URL (for opening their application in the converz app)'
                      }
                      placeholder={'Paste the URL here'}
                      type={'text'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <Field name={'isSezZone'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      options={CommonService._staticData.customYesNoOptions}
                      label={'SEZ'}
                      placeholder={'SEZ'}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      displayWith={(option: any) => option.title}
                      valueExtractor={(option: any) => option.code}
                      onUpdate={(value: any) => {
                        if (value === 'false') {
                          setFieldValue(`igst`, '');
                        }
                      }}
                    />
                  )}
                </Field>
                {/*if the isSezZone is true, then show the IGST input*/}
                {values.isSezZone === 'true' && (
                  <Field name={'igst'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        options={igstOptions}
                        label={'IGST'}
                        placeholder={'IGST'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        displayWith={(option: any) => option.title}
                        valueExtractor={(option: any) => option.code}
                      />
                    )}
                  </Field>
                )}
                <div className='d-flex'>
                  <FormControlLabelComponent
                    className='pdd-bottom-10'
                    label={'Parking Details'}
                    level={6}
                    isBold={true}
                  />{' '}
                  &nbsp;
                  <BadgeComponent>
                    {values?.availableSlots || 0} Available Slots
                  </BadgeComponent>
                </div>
                {values?.step === 'add' ? (
                  <Field name={'numberOfParkingSpacesOccupied'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'No of Slots Occupied'}
                        placeholder={'EX: 20'}
                        type={'text'}
                        disabled={
                          !values.propertyId ||
                          (values?.step === 'edit' &&
                            values?.availableSlots === 0)
                        }
                        validationPattern={new RegExp(/^[0-9]*$/)}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        onChange={(value: any) => {
                          setFieldValue(
                            `numberOfParkingSpacesOccupied`,
                            value ? parseInt(value) : '',
                          );
                        }}
                      />
                    )}
                  </Field>
                ) : (
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <InputComponent
                        label='Parking slots occupied in this amenity'
                        value={values?.numberOfParkingSpacesOccupied || '0'}
                        fullWidth
                        readOnly
                        disabled
                      />
                    </div>
                    <div className='ts-col-6'>
                      {values?.isAdditionalSlotRequired ? (
                        <div className='d-flex'>
                          <Field name={'additionalParkingSpaceOccupied'}>
                            {(field: FieldProps) => (
                              <FormikInputComponent
                                label={'No of new slots to be occupied'}
                                placeholder={'EX: 20'}
                                type={'text'}
                                disabled={!values.propertyId}
                                validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                                onChange={(value: any) => {
                                  setFieldValue(
                                    `additionalParkingSpaceOccupied`,
                                    value ? parseInt(value) : '',
                                  );
                                }}
                              />
                            )}
                          </Field>
                          <IconButtonComponent
                            className='mrg-left-10 mrg-top-35'
                            color='error'
                            onClick={() => {
                              setFieldValue('isAdditionalSlotRequired', false);
                              setFieldValue(
                                'additionalParkingSpaceOccupied',
                                '',
                              );
                            }}
                          >
                            <ImageConfig.DeleteOutlineIcon />
                          </IconButtonComponent>
                        </div>
                      ) : (
                        <ButtonComponent
                          className='mrg-top-25'
                          fullWidth
                          variant='outlined'
                          prefixIcon={<ImageConfig.AddOutlinedIcon />}
                          onClick={() => {
                            setFieldValue('isAdditionalSlotRequired', true);
                          }}
                          disabled={values?.availableSlots === 0}
                        >
                          Add Extra Parking Space
                        </ButtonComponent>
                      )}
                    </div>
                  </div>
                )}
                <FormControlLabelComponent
                  className='pdd-bottom-10'
                  label={'Place of Service'}
                  level={6}
                  isBold={true}
                />
                <Field name={'placeOfService.addressLine1'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Address Line'}
                      placeholder={'Example: Road No. 1, Financial District'}
                      type={'text'}
                      // maxlength={50}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'placeOfService.city'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'City'}
                          placeholder={'Example: Hyderabad'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={
                            new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'placeOfService.state'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          formikField={field}
                          label={'State'}
                          placeholder={'Example: Telangana'}
                          fullWidth={true}
                          required={true}
                          searchMode={'clientSide'}
                          searchable={true}
                          isClearable={true}
                          options={CommonService._staticData.indianStatesGST}
                          displayWith={(option) => option.state}
                          valueExtractor={(option) => option.state}
                          onUpdate={(value: any) => {
                            if (value) {
                              const selectedState =
                                CommonService._staticData.indianStatesGST.find(
                                  (item) => item.state === value,
                                );
                              setFieldValue(
                                'placeOfService.state',
                                selectedState?.state,
                              );
                              setFieldValue(
                                'placeOfService.stateCode',
                                selectedState?.statecode,
                              );
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'placeOfService.country'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Country'}
                          placeholder={'Ex: India'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>

                  <div className='ts-col-6'>
                    <Field name={'placeOfService.pincode'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Pincode'}
                          placeholder={'Ex: 500004'}
                          // type={"number"}
                          required={true}
                          validationPattern={/^[0-9\b]+$/}
                          maxlength={6}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <FormControlLabelComponent
                  className='pdd-bottom-10'
                  label={'Billing Address'}
                  level={6}
                  isBold={true}
                />
                <Field name='isBillingAddressSameAsPlaceOfService'>
                  {(field: FieldProps) => (
                    <FormikCheckBoxComponent
                      label='Same as place of service'
                      formikField={field}
                      size={'small'}
                      disabled={
                        values?.placeOfService?.addressLine1.length === 0 ||
                        values?.placeOfService?.city.length === 0 ||
                        values?.placeOfService?.country.length === 0 ||
                        values?.placeOfService?.pincode.length === 0 ||
                        values?.placeOfService?.state.length === 0
                      }
                      onChange={(value: boolean) => {
                        setFieldValue(
                          'billingAddress.addressLine1',
                          value ? values?.placeOfService?.addressLine1 : '',
                        );
                        setFieldValue(
                          'billingAddress.city',
                          value ? values?.placeOfService?.city : '',
                        );
                        setFieldValue(
                          'billingAddress.state',
                          value ? values?.placeOfService?.state : '',
                        );
                        setFieldValue(
                          'billingAddress.stateCode',
                          value ? values?.placeOfService?.stateCode : '',
                        );
                        setFieldValue(
                          'billingAddress.pincode',
                          value ? values?.placeOfService?.pincode : '',
                        );
                        setFieldValue(
                          'billingAddress.country',
                          value ? values?.placeOfService?.country : '',
                        );
                      }}
                    />
                  )}
                </Field>
                <Field name={'billingAddress.addressLine1'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'Address Line'}
                      placeholder={'Example: Road No. 1, Financial District'}
                      type={'text'}
                      // maxlength={50}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      readOnly={values?.isBillingAddressSameAsPlaceOfService}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'billingAddress.city'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'City'}
                          placeholder={'Example: Hyderabad'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          validationPattern={
                            new RegExp(/^[a-zA-Z][a-zA-Z\s]*$/)
                          }
                          readOnly={
                            values?.isBillingAddressSameAsPlaceOfService
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'billingAddress.state'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          formikField={field}
                          label={'State'}
                          placeholder={'Example: Telangana'}
                          fullWidth={true}
                          required={true}
                          searchMode={'clientSide'}
                          searchable={true}
                          isClearable={true}
                          disabled={
                            values?.isBillingAddressSameAsPlaceOfService
                          }
                          options={CommonService._staticData.indianStatesGST}
                          displayWith={(option) => option.state}
                          valueExtractor={(option) => option.state}
                          onUpdate={(value: any) => {
                            if (value) {
                              const selectedState =
                                CommonService._staticData.indianStatesGST.find(
                                  (item) => item.state === value,
                                );
                              setFieldValue(
                                'billingAddress.state',
                                selectedState?.state,
                              );
                              setFieldValue(
                                'billingAddress.stateCode',
                                selectedState?.statecode,
                              );
                            }
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'billingAddress.country'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Country'}
                          placeholder={'Ex: India'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          readOnly={
                            values?.isBillingAddressSameAsPlaceOfService
                          }
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'billingAddress.pincode'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Pincode'}
                          placeholder={'Ex: 500004'}
                          required={true}
                          validationPattern={/^[0-9\b]+$/}
                          maxlength={6}
                          formikField={field}
                          fullWidth={true}
                          readOnly={
                            values?.isBillingAddressSameAsPlaceOfService
                          }
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AmenityDetailsComponent;
