import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const GetEntityListLiteAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ENTITY_LIST_LITE.METHOD](
    APIConfig.ENTITY_LIST_LITE.URL,
    payload,
  );
};

const AddEntityAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ENTITY_ADD.METHOD](
    APIConfig.ENTITY_ADD.URL,
    payload,
  );
};

const GetEntityAPICall = (entityId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.VIEW_ENTITY.METHOD](
    APIConfig.VIEW_ENTITY.URL(entityId),
    payload,
  );
};

const EditEntityAPICall = (entityId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_ENTITY.METHOD](
    APIConfig.EDIT_ENTITY.URL(entityId),
    payload,
  );
};

const ToggleEntityServiceAPICall = (entityId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_ENTITY.METHOD](
    APIConfig.TOGGLE_ENTITY.URL(entityId),
    payload,
  );
};

const ToggleShowPhoenixLogoAPICall = (entityId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_PHOENIX_LOGO.METHOD](
    APIConfig.TOGGLE_PHOENIX_LOGO.URL(entityId),
    payload,
  );
};

const ToggleShowEntityLogoAPICall = (entityId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_ENTITY_LOGO.METHOD](
    APIConfig.TOGGLE_ENTITY_LOGO.URL(entityId),
    payload,
  );
};

const downloadEntityListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.ENTITY_LIST.METHOD](
    APIConfig.ENTITY_LIST.URL + `?download=true`,
  );
};

const EntityService = {
  GetEntityListLiteAPICall,
  AddEntityAPICall,
  GetEntityAPICall,
  EditEntityAPICall,
  ToggleEntityServiceAPICall,
  ToggleShowPhoenixLogoAPICall,
  ToggleShowEntityLogoAPICall,
  downloadEntityListAsExcel,
};

export default EntityService;
