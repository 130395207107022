import React from 'react';
import { Outlet } from 'react-router-dom';
import BrandingComponent from '../../shared/components/layout/branding/BrandingComponent';
import SideMenuComponent from '../../shared/components/layout/side-menu/SideMenuComponent';
import HeaderComponent from '../../shared/components/layout/header/HeaderComponent';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../store/reducers';
// import { setSideMenuView } from '../../store/actions/navigation.action';
// import { ImageConfig } from '../../constants';

export interface AppLayoutProps {}

const AppLayout = (props: AppLayoutProps) => {
  const { sideMenuView } = useSelector(
    (state: IRootReducerState) => state.navigation,
  );

  //   const dispatch = useDispatch();

  // useEffect(() => {
  //     const ele = document.getElementById('page-content-holder');
  //     if (ele) {
  //         ele?.scrollTo(0, 0);
  //     }
  // }, [location]);

  return (
    <div className='app-layout'>
      <div className={`side-bar-holder ${sideMenuView}-view`}>
        <div className='logo-holder'>
          <BrandingComponent />
        </div>
        <div className={'side-menu-holder '}>
          <SideMenuComponent />
        </div>
        {/* <div
          className='side-menu-toggle-icon'
          onClick={() => {
            dispatch(
              setSideMenuView(
                sideMenuView === 'default' ? 'compact' : 'default',
              ),
            );
          }}
        >
          {sideMenuView === 'default' && <ImageConfig.LeftArrow />}
          {sideMenuView === 'compact' && <ImageConfig.RightArrow />}
        </div> */}
      </div>
      <div className='header-and-page-container'>
        <div className='header-holder'>
          <HeaderComponent />
        </div>
        <div className='page-content-holder' id={'page-content-holder'}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
