import { useCallback, useEffect, useState } from 'react';
import './NewInvoiceComponent.scss';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikDatePickerComponent from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FilePickerComponent from '../../../../shared/components/file-picker/FilePickerComponent';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import moment from 'moment';
import { ReactComponent as FileClearIcon } from '../../../../assets/icons/file-preview-thumbnail/cancel-file.svg';
import { getPropertyListLite } from '../../../../store/actions/property.action';
import { getTenantListLite } from '../../../../store/actions/tenant.action';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import { getAmenityListLite } from '../../../../store/actions/amenity.action';

interface NewInvoiceComponentProps {
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
  invoiceId?: any;
  invoiceDetails?: any;
}

const validationSchema = Yup.object({
  billingTo: Yup.string().required('Billing To is required'),
  category: Yup.string().required('Invoice Category is required'),
  utilityInvoiceType: Yup.string().when('category', {
    is: 'utilityInvoice',
    then: Yup.string().required('Utility Invoice Type is Required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  propertyId: Yup.string().required('Property is required').nullable(),
  tenantId: Yup.string().when('billingTo', {
    is: 'tenant',
    then: Yup.string().required('Tenant is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  amenityId: Yup.string().when('billingTo', {
    is: 'amenity',
    then: Yup.string().required('Amenity is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  billingStartDate: Yup.string()
    .required('Billing From date is required')
    .nullable(),
  billingEndDate: Yup.string()
    .required('Billing To date is required')
    .nullable()
    .test(
      'is-greater',
      'Billing To date must be greater than Billing From date',
      function (value) {
        const { billingStartDate } = this.parent;
        return moment(value).isAfter(moment(billingStartDate));
      },
    ),
  invoiceDueDate: Yup.string()
    .required('Payment Due Date is required')
    .nullable()
    .test(
      'is-greater',
      'Payment Due Date must be greater than Billing To date',
      function (value) {
        const { billingEndDate } = this.parent;
        return moment(value).isAfter(moment(billingEndDate));
      },
    ),
});

interface formInitialValuesFormSchema {
  billingTo: string;
  category: string;
  utilityInvoiceType: string;
  propertyId: string;
  tenantId: string;
  amenityId: string;
  billingStartDate: string;
  billingEndDate: string;
  invoiceDueDate: string;
  files?: any;
}

const formInitialValues: formInitialValuesFormSchema = {
  billingTo: 'tenant',
  category: 'regularInvoice',
  utilityInvoiceType: '',
  propertyId: '',
  tenantId: '',
  amenityId: '',
  billingStartDate: '',
  billingEndDate: '',
  invoiceDueDate: '',
  files: [],
};

const invoiceOptions = [
  {
    title: 'Tenant',
    code: 'tenant',
  },
  {
    title: 'Amenity',
    code: 'amenity',
  },
];

const invoiceCategory = [
  {
    title: 'Regular Invoice',
    code: 'regularInvoice',
  },
  {
    title: 'Utility Invoice',
    code: 'utilityInvoice',
  },
];

const utilityInvoiceType = [
  {
    title: 'GST',
    code: 'gst',
  },
  {
    title: 'Non - GST',
    code: 'nonGst',
  },
];

const NewInvoiceComponent = (props: NewInvoiceComponentProps) => {
  const { next, prev, steps, activeStepId, invoiceId, invoiceDetails } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const dispatch = useDispatch();
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { tenantList } = useSelector(
    (state: IRootReducerState) => state.tenant,
  );
  const { amenityList } = useSelector(
    (state: IRootReducerState) => state.amenity,
  );
  const [updatedList, setUpdatedList] = useState<any>([]);

  useEffect(() => {
    dispatch(getPropertyListLite());
    dispatch(getTenantListLite());
    dispatch(getAmenityListLite());
  }, [dispatch]);

  const handleFilterList = useCallback(
    (propertyId: any, billingTo: any) => {
      const list = billingTo === 'tenant' ? tenantList : amenityList;
      const filteredData = list?.filter(
        (item: any) => item.propertyId === propertyId,
      );
      setUpdatedList(filteredData);
    },
    [tenantList, amenityList],
  );

  useEffect(() => {
    if (invoiceDetails) {
      const fetchPDFFiles = async () => {
        let pdfDetails: any = [];
        if (
          invoiceDetails?.attachments &&
          invoiceDetails?.attachments.length > 0
        ) {
          const files = await Promise.all(
            invoiceDetails?.attachments.map(async (file: any) => {
              return await CommonService.generateBlobFileFromUrl(
                file.url,
                file.name,
                file.mimetype,
              );
            }),
          );
          pdfDetails = files;
        }

        const basicDetails = {
          billingTo: invoiceDetails?.billingTo,
          propertyId: invoiceDetails?.propertyId,
          tenantId:
            (invoiceDetails?.billingTo === 'tenant' &&
              invoiceDetails?.tenantId) ||
            '',
          amenityId:
            (invoiceDetails?.billingTo === 'amenity' &&
              invoiceDetails?.amenityId) ||
            '',
          billingStartDate: invoiceDetails?.billingStartDate,
          billingEndDate: invoiceDetails?.billingEndDate,
          invoiceDueDate: invoiceDetails?.invoiceDueDate,
          files: pdfDetails,
          category: invoiceDetails?.category,
          utilityInvoiceType: invoiceDetails?.utilityInvoiceType,
        };
        setInitialValues(basicDetails);
      };
      handleFilterList(invoiceDetails?.propertyId, invoiceDetails?.billingTo);
      fetchPDFFiles();
    } else {
      const basicDetails = {
        billingTo: 'tenant',
        category: 'regularInvoice',
        utilityInvoiceType: '',
        propertyId: '',
        tenantId: '',
        amenityId: '',
        billingStartDate: '',
        billingEndDate: '',
        invoiceDueDate: '',
        files: [],
      };
      setInitialValues(basicDetails);
    }
  }, [invoiceDetails, handleFilterList]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);

      values.billingStartDate = moment(values.billingStartDate).format(
        'YYYY-MM-DD',
      );
      values.billingEndDate = moment(values.billingEndDate).format(
        'YYYY-MM-DD',
      );
      values.invoiceDueDate = moment(values.invoiceDueDate).format(
        'YYYY-MM-DD',
      );

      const payload = {
        ...values,
      };

      const formData = CommonService.getFormDataFromJSON(payload);

      let apiCall: any = {};
      if (
        values?.category === 'regularInvoice' ||
        (values?.category === 'utilityInvoice' &&
          values?.utilityInvoiceType === 'gst')
      ) {
        if (invoiceId) {
          apiCall = CommonService._invoice.AddInvoiceDetailsAPICall(
            invoiceId,
            formData,
          );
          apiCall
            .then((response: IAPIResponseType<any>) => {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'success',
              );
              next(response.data);
            })
            .catch((error: any) => {
              CommonService.handleErrors(setErrors, error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          apiCall = CommonService._invoice.CreateInvoiceAPICall(formData);
          apiCall
            .then((response: IAPIResponseType<any>) => {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'success',
              );
              next(response.data);
            })
            .catch((error: any) => {
              CommonService.handleErrors(setErrors, error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }
      if (
        values?.category === 'utilityInvoice' &&
        values?.utilityInvoiceType === 'nonGst'
      ) {
        if (invoiceId) {
          apiCall = CommonService._debitnote.EditDebitNoteAPICall(
            invoiceId,
            formData,
          );
          apiCall
            .then((response: IAPIResponseType<any>) => {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'success',
              );
              next(response.data);
            })
            .catch((error: any) => {
              CommonService.handleErrors(setErrors, error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        } else {
          apiCall = CommonService._debitnote.AddDebitNoteAPICall(formData);
          apiCall
            .then((response: IAPIResponseType<any>) => {
              CommonService._alert.showToast(
                response[Misc.API_RESPONSE_MESSAGE_KEY],
                'success',
              );
              next(response.data);
            })
            .catch((error: any) => {
              CommonService.handleErrors(setErrors, error);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      }
    },
    [next, invoiceId],
  );

  return (
    <div className='add-screen new-invoice-component'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          isSubmitting,
          setFieldValue,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                <ButtonComponent
                  variant='text'
                  color='secondary'
                  onClick={prev}
                  prefixIcon={<ImageConfig.ArrowLeftIcon />}
                >
                  Exit
                </ButtonComponent>
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>
                <ButtonComponent
                  type='submit'
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                <Field name='category'>
                  {(field: FieldProps) => (
                    <FormikRadioButtonGroupComponent
                      options={invoiceCategory}
                      formikField={field}
                      keyExtractor={(option: any) => option.code}
                      valueExtractor={(option: any) => option.code}
                      displayWith={(option: any) => option.title}
                      onChange={() => {
                        setFieldValue(`utilityInvoiceType`, '');
                      }}
                      disabled={invoiceId}
                    />
                  )}
                </Field>
                {values?.category === 'utilityInvoice' && (
                  <Field name='utilityInvoiceType'>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        label='Utility Invoice Type'
                        placeholder='Select'
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        options={utilityInvoiceType}
                        displayWith={(option: any) => option.title}
                        valueExtractor={(option: any) => option.code}
                        disabled={invoiceId}
                      />
                    )}
                  </Field>
                )}

                <Field name='billingTo'>
                  {(field: FieldProps) => (
                    <FormikRadioButtonGroupComponent
                      options={invoiceOptions}
                      label='Billing To'
                      formikField={field}
                      keyExtractor={(option: any) => option.code}
                      valueExtractor={(option: any) => option.code}
                      onChange={(value) => {
                        setUpdatedList([]);
                        setFieldValue(`propertyId`, '');
                        if (value === 'tenant') {
                          setFieldValue(`amenityId`, '');
                        }
                        if (value === 'amenity') {
                          setFieldValue(`tenantId`, '');
                        }
                      }}
                    />
                  )}
                </Field>
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'propertyId'}>
                      {(field: FieldProps) => (
                        <FormikSelectDropdownComponent
                          label='Select Property'
                          placeholder='Select'
                          required={true}
                          formikField={field}
                          fullWidth={true}
                          options={propertyList}
                          displayWith={(option: any) => option.name}
                          valueExtractor={(option: any) => option._id}
                          onUpdate={(value: any) => {
                            handleFilterList(value, values?.billingTo);
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  {values?.billingTo === 'tenant' && (
                    <div className='ts-col-6'>
                      <Field name={'tenantId'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            label='Select Tenant'
                            placeholder='Select'
                            required={true}
                            formikField={field}
                            fullWidth
                            options={updatedList}
                            displayWith={(option: any) => option.name}
                            valueExtractor={(option: any) => option._id}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                  {values?.billingTo === 'amenity' && (
                    <div className='ts-col-6'>
                      <Field name={'amenityId'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            label='Select Amenity'
                            placeholder='Select'
                            required={true}
                            formikField={field}
                            fullWidth
                            options={updatedList}
                            displayWith={(option: any) => option.name}
                            valueExtractor={(option: any) => option._id}
                          />
                        )}
                      </Field>
                    </div>
                  )}
                </div>

                <FormControlLabelComponent
                  label={`When would you want to bill the ${
                    values?.billingTo === 'tenant' ? 'tenant' : 'amenity'
                  }`}
                  level={5}
                  isBold={true}
                />
                <div className='ts-row'>
                  <div className='ts-col-6'>
                    <Field name={'billingStartDate'}>
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          label='From'
                          placeholder='Select'
                          formikField={field}
                          fullWidth
                          required={true}
                          maxDate={new Date()}
                        />
                      )}
                    </Field>
                  </div>
                  <div className='ts-col-6'>
                    <Field name={'billingEndDate'}>
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          label='To'
                          placeholder='Select'
                          formikField={field}
                          fullWidth
                          required={true}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div>
                  <Field name={'invoiceDueDate'}>
                    {(field: FieldProps) => (
                      <FormikDatePickerComponent
                        label='Select Payment Due Date'
                        placeholder='Select'
                        formikField={field}
                        fullWidth
                        required={true}
                      />
                    )}
                  </Field>
                </div>

                <div className='row-wrapper'>
                  <FormControlLabelComponent
                    label='Upload Attachments if any'
                    isBold
                    level={5}
                  />
                  <div className={'optional-text'}>Optional</div>
                </div>
                <div className='height-90'>
                  <FilePickerComponent
                    maxFileSizeInMB={3}
                    maxFileCount={10}
                    multiple={true}
                    showDropZone={true}
                    height='100%'
                    id={'gallery'}
                    onFilesDrop={(acceptedFiles, rejectedFiles) => {
                      const fileAlreadyExists = acceptedFiles.some(
                        (file: any) =>
                          values.files.some(
                            (exisitingFiles: any) =>
                              exisitingFiles.name === file.name,
                          ),
                      );

                      if (fileAlreadyExists) {
                        CommonService._alert.showToast(
                          'Similar File Exists',
                          'warning',
                        );
                      } else {
                        setFieldValue('files', [
                          ...values.files,
                          ...acceptedFiles,
                        ]);
                      }
                    }}
                    acceptedFileTypes={['pdf']}
                    uploadDescription='(upload .PDF files only)'
                    acceptedFilesText={'.pdf max-size 3.0MB'}
                  />
                </div>
                {values.files && (
                  <div className='invoice-pdf-preview'>
                    {values.files?.map((file: any, index: number) => (
                      <div key={index} className='invoice-pdf-preview-card'>
                        <div className='invoice-pdf-filename'>{file.name}</div>
                        <div
                          className={'invoice-pdf-remove'}
                          onClick={() => {
                            const updatedFiles = values.files.filter(
                              (item: any, i: number) => i !== index,
                            );
                            setFieldValue('files', updatedFiles);
                          }}
                        >
                          <FileClearIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewInvoiceComponent;
