import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const AddTenantAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TENANT.METHOD](
    APIConfig.ADD_TENANT.URL,
    payload,
  );
};

const GetEventsList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EVENTS_LIST.METHOD](
    APIConfig.EVENTS_LIST.URL,
    payload,
  );
};

const removeAdminDetailsAPICall = (tenantId: string, adminId: any) => {
  // @ts-ignore
  return ApiService[APIConfig.DELETE_TENANT_ADMIN.METHOD](
    APIConfig.DELETE_TENANT_ADMIN.URL(tenantId, adminId),
  );
};

const AddTimingsDetails = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TENANT_TIMING.METHOD](
    APIConfig.ADD_TENANT_TIMING.URL(tenantId),
    payload,
  );
};

const AddTenantAdminAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TENANT_ADMIN.METHOD](
    APIConfig.ADD_TENANT_ADMIN.URL(tenantId),
    payload,
  );
};

const AddTowerDetailsAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TOWER_DETAILS.METHOD](
    APIConfig.ADD_TOWER_DETAILS.URL(tenantId),
    payload,
  );
};

const GetTowerDetailsAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_TENANT_TOWER_DETAILS.METHOD](
    APIConfig.GET_TENANT_TOWER_DETAILS.URL(propertyId),
    payload,
  );
};

const AddEventMoreInfoAPICall = (eventId: string, payload: any) => {
  console.log('eventId', eventId, payload);
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_MORE_INFO.METHOD](
    APIConfig.ADD_EVENT_MORE_INFO.URL(eventId),
    payload,
  );
};

const AddEventPerformersAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_PERFORMER.METHOD](
    APIConfig.ADD_EVENT_PERFORMER.URL,
    payload,
  );
};
const getEventGalleryImagesAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_EVENT_GALLERY.METHOD](
    APIConfig.GET_EVENT_GALLERY.URL,
    payload,
  );
};
const AddTenantProfileAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_TENANT_IMAGE.METHOD](
    APIConfig.ADD_TENANT_IMAGE.URL(tenantId),
    payload,
  );
};
const cancelEventAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.CANCEL_EVENT.METHOD](
    APIConfig.CANCEL_EVENT.URL(eventId),
    payload,
  );
};
const removeEventGalleryImageAPICall = (eventId: string, imageId: any) => {
  // @ts-ignore
  return ApiService[APIConfig.REMOVE_EVENT_GALLERY.METHOD](
    APIConfig.REMOVE_EVENT_GALLERY.URL(eventId, imageId),
  );
};

const setDefaultEventGalleryImageAPICall = (eventId: string, imageId: any) => {
  // @ts-ignore
  return ApiService[APIConfig.DEFAULT_IMAGE_EVENT_GALLERY.METHOD](
    APIConfig.DEFAULT_IMAGE_EVENT_GALLERY.URL(eventId, imageId),
  );
};

const editTenantDetailsAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_TENANT.METHOD](
    APIConfig.EDIT_TENANT.URL(tenantId),
    payload,
  );
};

const addAnnouncement = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_ANNOUNCEMENT.METHOD](
    APIConfig.ADD_EVENT_ANNOUNCEMENT.URL(payload),
  );
};

// const CancelPollAPICall = (pollId: any, payload: any) => {
//     // @ts-ignore
//     return ApiService[APIConfig.CANCEL_POLL.METHOD](APIConfig.CANCEL_POLL.URL(pollId), payload);
// }

const GetTenantsDetailsAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_TENANT_DETAILS.METHOD](
    APIConfig.GET_TENANT_DETAILS.URL(tenantId),
    payload,
  );
};

const toggleTenantAPICall = (tenantId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_TENANT.METHOD](
    APIConfig.TOGGLE_TENANT.URL(tenantId),
  );
};

const GetEventListAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_EVENT_LIST.METHOD](
    APIConfig.GET_EVENT_LIST.URL,
    payload,
  );
};

const AddBillingDetailsAPICall = (tenantId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_BILLING_DETAILS.METHOD](
    APIConfig.ADD_BILLING_DETAILS.URL(tenantId),
    payload,
  );
};
//
// const getPollStatsAPICall = (pollId: string, payload: any) => {
//     // @ts-ignore
//     return ApiService[APIConfig.GET_POLL_STATS.METHOD](APIConfig.GET_POLL_STATS.URL(pollId), payload);
// }

const GetTenantsListLiteAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_TENANT_LIST.METHOD](
    APIConfig.GET_TENANT_LIST.URL,
    payload,
  );
};

const getTenantBillingComponentsAPICall = (tenantId: string) => {
  //@ts-ignore
  return ApiService[APIConfig.GET_TENANT_BILLING_COMPONENTS.METHOD](
    APIConfig.GET_TENANT_BILLING_COMPONENTS.URL(tenantId),
  );
};

const getTenantEscalationAPICall = (tenantId: string, billingtype: string) => {
  return ApiService[APIConfig.GET_TENANT_ESCALATIONS.METHOD](
    APIConfig.GET_TENANT_ESCALATIONS.URL(tenantId, billingtype),
  );
};

const deleteTenantAdminDetailsAPICall = (tenantId: string, adminId: string) => {
  return ApiService[APIConfig.DELETE_TENANT_ADMIN_DETAILS.METHOD](
    APIConfig.DELETE_TENANT_ADMIN_DETAILS.URL(tenantId, adminId),
  );
};
const downloadTenantListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.TENANT_LIST.METHOD](
    APIConfig.TENANT_LIST.URL + `?download=true`,
  );
};

const TenantService = {
  AddTenantAPICall,
  GetEventsList,
  AddTenantAdminAPICall,
  AddTowerDetailsAPICall,
  AddEventMoreInfoAPICall,
  AddEventPerformersAPICall,
  GetTenantsDetailsAPICall,
  removeAdminDetailsAPICall,
  AddTimingsDetails,
  GetEventListAPICall,
  getEventGalleryImagesAPICall,
  AddTenantProfileAPICall,
  cancelEventAPICall,
  removeEventGalleryImageAPICall,
  editTenantDetailsAPICall,
  setDefaultEventGalleryImageAPICall,
  addAnnouncement,
  GetTowerDetailsAPICall,
  toggleTenantAPICall,
  AddBillingDetailsAPICall,
  GetTenantsListLiteAPICall,
  getTenantBillingComponentsAPICall,
  getTenantEscalationAPICall,
  deleteTenantAdminDetailsAPICall,
  downloadTenantListAsExcel,
};

export default TenantService;
