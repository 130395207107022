import React, { CSSProperties } from 'react';
import './LabelComponent.scss';
import ToolTipComponent from '../tool-tip/ToolTipComponent';
import { ImageConfig } from '../../../constants';

export interface LabelComponentProps {
  title: string;
  tooltip?: string;
  isOptional?: boolean;
  required?: boolean;
  hasError?: boolean;
  styles?: CSSProperties;
  size?: any;
}

const LabelComponent = (props: LabelComponentProps) => {
  const { title, required, tooltip, hasError, styles, size } = props;
  const isOptional = required === false ? true : props.isOptional;

  return (
    <div
      className={'label-component ' + (hasError ? 'has-error' : '')}
      style={styles}
    >
      <span className={size === 'small' ? 'small' : ''}>
        {title}
        {tooltip && (
          <ToolTipComponent tooltip={tooltip}>
            <span className={'tooltip-info'} data-testid='tooltip-icon'>
              {' '}
              <ImageConfig.HelpTooltipIcon />{' '}
            </span>
          </ToolTipComponent>
        )}
      </span>
      {isOptional && <span className={'label__optional'}>Optional</span>}
    </div>
  );
};

export default LabelComponent;
