import {
    GET_GENDER_LIST,
    GET_USER_ROLE_LIST,
    SET_GENDER_LIST,
    SET_USER_ROLE_LIST,
    GET_POLE_TYPES,
    SET_POLE_TYPES
} from "../actions/static-data.action";

import {IActionModel} from "../../shared/models/action.model";

export interface IStaticDataReducerState {
    isGenderListLoading: boolean,
    isGenderListLoaded: boolean,
    genderList: any[],
    isUserRoleListLoading: boolean,
    isUserRoleListLoaded: boolean,
    userRoleList: any[],

    isPollTypesLoading: boolean,
    isPollTypesLoaded: boolean,
    poleTypes: any,
}

const initialData: IStaticDataReducerState = {
    isGenderListLoading: false,
    isGenderListLoaded: false,
    genderList: [],
    isUserRoleListLoading: false,
    isUserRoleListLoaded: false,
    userRoleList: [],

    isPollTypesLoading: false,
    isPollTypesLoaded: false,
    poleTypes: {},
};

const StaticDataReducer = (state = initialData, action: IActionModel): IStaticDataReducerState => {
    switch (action.type) {
        case GET_GENDER_LIST:
            state = {
                ...state,
                isGenderListLoading: true,
                isGenderListLoaded: false,
            };
            return state;
        case SET_GENDER_LIST:
            state = {
                ...state,
                isGenderListLoading: false,
                isGenderListLoaded: true,
                genderList: action.payload.genderList
            };
            return state;
        case GET_USER_ROLE_LIST:
            state = {
                ...state,
                isUserRoleListLoading: true,
                isUserRoleListLoaded: false,
            };
            return state;
        case SET_USER_ROLE_LIST:
            state = {
                ...state,
                isUserRoleListLoading: false,
                isUserRoleListLoaded: true,
                userRoleList: action.payload.userRoleList,
            }
            return state;

        case GET_POLE_TYPES:
            state = {
                ...state,
                isPollTypesLoading: true,
                isPollTypesLoaded: false,
            };
            return state;
        case SET_POLE_TYPES:
            state = {
                ...state,
                isPollTypesLoading: false,
                isPollTypesLoaded: true,
                poleTypes: action.payload.poleTypes
            };
            return state;
        default:
            return state;
    }
};

export default StaticDataReducer;
