import {IGender, IUserRole,} from "../../shared/models/common.model";


export const GET_GENDER_LIST = 'GET_GENDER_LIST';
export const SET_GENDER_LIST = 'SET_GENDER_LIST';

export const GET_USER_ROLE_LIST = 'GET_USER_ROLE_LIST';
export const SET_USER_ROLE_LIST = 'SET_USER_ROLE_LIST';

export const GET_POLE_TYPES = 'GET_POLE_TYPES';
export const SET_POLE_TYPES = 'SET_POLE_TYPES';

export const getGenderList = () => {
    return {type: GET_GENDER_LIST};
};

export const setGenderList = (genderList: IGender[]) => {
    return {
        type: SET_GENDER_LIST, payload: {
            genderList
        }
    };
};

export const getUserRoleList = () => {
    return {type: GET_USER_ROLE_LIST};
};

export const setUserRoleList = (userRoleList: IUserRole[]) => {
    return {
        type: SET_USER_ROLE_LIST, payload: {
            userRoleList
        }
    }
};

export const getPollTypes = () => {
    return {type: GET_POLE_TYPES};
};

export const setPollTypes = (poleTypes: any) => {
    return {
        type: SET_POLE_TYPES, payload: {
            poleTypes
        }
    };
};