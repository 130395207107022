// Imports: Dependencies
import { all, fork } from 'redux-saga/effects';
// Imports: Redux Sagas
import accountSaga from './account.saga';
import staticDataSaga from './static-data.saga';
import userSaga from './user.saga';
import propertySaga from './property.saga';
import entitySaga from './entity.saga';
import tenantSaga from './tenant.saga';
import amenitySaga from './amenity.saga';

// Redux Saga: Root Saga
export default function* rootSaga() {
  yield all([
    fork(accountSaga),
    fork(staticDataSaga),
    fork(userSaga),
    fork(propertySaga),
    fork(entitySaga),
    fork(tenantSaga),
    fork(amenitySaga),
  ]);
}
