import './LoginScreen.scss';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikPasswordInputComponent from '../../../shared/components/form-controls/formik-password-input/FormikPasswordInputComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { CommonService } from '../../../shared/services';
import {
  setLoggedInUserData,
  setLoggedInUserToken,
} from '../../../store/actions/account.action';
import { IAPIResponseType } from '../../../shared/models/api.model';
import {
  IAccountLoginCredentials,
  ILoginResponse,
} from '../../../shared/models/account.model';
import { useDispatch } from 'react-redux';
import { ENV, ImageConfig, Misc } from '../../../constants';
import commonService from '../../../shared/services/common.service';

interface LoginScreenProps {}

const loginFormValidationSchema = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  password: Yup.string()
    .min(7, 'Password must be 7 characters')
    .max(16, 'Password must be max 16 characters')
    .required('Password is required'),
});

const LoginScreen = (props: LoginScreenProps) => {
  const dispatch = useDispatch(); // redux dispatch
  const [loginFormInitialValues, setLoginFormInitialValues] =
    useState<IAccountLoginCredentials>({
      email: '',
      password: '',
    });
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    if (ENV.ENV_MODE === 'dev') {
      setLoginFormInitialValues({
        email: 'krishna@gmail.com',
        password: 'Q7AmLLad',
      });
    }
    if (ENV.ENV_MODE === 'test') {
      setLoginFormInitialValues({
        email: 'superadmin@gmail.com',
        password: 'test12345678',
      });
    }
  }, []);

  const onSubmit = useCallback(
    (values: any, { setErrors }: FormikHelpers<any>) => {
      setIsLoggingIn(true);
      commonService._account
        .LoginAPICall(values)
        .then((response: IAPIResponseType<ILoginResponse>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          dispatch(setLoggedInUserData(response?.data?.userDetails));
          dispatch(setLoggedInUserToken(response?.data?.token));
        })
        .catch((error: any) => {
          // CommonService.handleErrors(setErrors, error);
          CommonService._alert.showToast(error.error, 'warning');
        })
        .finally(() => {
          setIsLoggingIn(false);
        });
    },
    [dispatch],
  );

  return (
    <div className='login-screen'>
      <div className='login-form-container'>
        <div className={'login-screen-header'}>
          <div className='login-screen-logo'>
            <img src={ImageConfig.Logo} alt={Misc.APP_NAME + 'Logo'} />
          </div>
          <div className='login-form-header-text'>Welcome Back</div>
          <div className='login-form-helper-text'>
            {
              'Sign in to seamlessly connect and optimize \n your staffing process.'
            }
          </div>
        </div>
        <Formik
          validationSchema={loginFormValidationSchema}
          initialValues={loginFormInitialValues}
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ values, validateForm }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              validateForm();
            }, [validateForm, values]);
            return (
              <Form className='t-form' noValidate={true}>
                <div className='t-form-controls'>
                  <Field name={'email'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        className={'t-form-input-wrapper'}
                        label={'Email ID'}
                        placeholder={'Enter Email'}
                        type={'email'}
                        labelStyles={{ fontSize: 16 }}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        id={'email_input'}
                      />
                    )}
                  </Field>
                  <Field name={'password'}>
                    {(field: FieldProps) => (
                      <FormikPasswordInputComponent
                        className={'t-form-input-wrapper'}
                        label={'Password'}
                        labelStyles={{ fontSize: 16 }}
                        placeholder={'Enter Password'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        canToggle={true}
                        id={'password_input'}
                      />
                    )}
                  </Field>
                </div>
                <div className='t-form-actions'>
                  <ButtonComponent
                    className={'t-form-submit-btn-wrapper'}
                    isLoading={isLoggingIn}
                    type={'submit'}
                    fullWidth={true}
                    id={'login_btn'}
                    disabled={isLoggingIn}
                  >
                    {isLoggingIn ? 'Logging in' : 'Login'}
                  </ButtonComponent>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default LoginScreen;
