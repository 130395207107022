import React from 'react';
import './ModuleCardComponent.scss';

interface ModuleCardComponentProps {
  icon: React.ReactNode;
  title: string;
  count: number;
}

const ModuleCardComponent = (props: ModuleCardComponentProps) => {
  const { icon, title, count } = props;
  return (
    <div className='module-card-component'>
      <div>
        <div className='module-card-title'>{title}</div>
        <div className='module-card-count'>{count || 0}</div>
      </div>
      <div className='module-card-icon-wrapper'>{icon}</div>
    </div>
  );
};

export default ModuleCardComponent;
