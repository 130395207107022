export const SET_TENANT_LIST_LITE = 'SET_TENANT_LIST_LITE';
export const GET_TENANT_LIST_LITE = 'GET_TENANT_LIST_LITE';
export const SET_ALL_TENANT_LIST = 'SET_ALL_TENANT_LIST';
export const GET_ALL_TENANT_LIST = 'GET_ALL_TENANT_LIST';

export const getTenantListLite = () => {
  return {
    type: GET_TENANT_LIST_LITE,
  };
};
export const setTenantListLite = (tenantList: any[]) => {
  return {
    type: SET_TENANT_LIST_LITE,
    payload: {
      tenantList,
    },
  };
};

export const getAllTenantList = () => {
  return {
    type: GET_ALL_TENANT_LIST,
  };
};

export const setAllTenantList = (allTenantList: any[]) => {
  return {
    type: SET_ALL_TENANT_LIST,
    payload: {
      allTenantList,
    },
  };
};
