import { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import _ from 'lodash';
import NewInvoiceComponent from './new-invoice/NewInvoiceComponent';
import InvoiceDetailsComponent from './invoice-details/InvoiceDetailsComponent';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import InvoicePreviewComponent from './invoice-preview/InvoicePreviewComponent';

interface InvoiceCreateComponentProps {}

const NEW_INVOICE = 'new-invoice';
const INVOICE_DETAILS = 'invoice-details';
const INVOICE_PREVIEW = 'invoice-preview';

const STEPS = [
  {
    title: 'New Invoice',
    id: NEW_INVOICE,
  },
  {
    title: 'Invoice Details',
    id: INVOICE_DETAILS,
  },
  {
    title: 'Invoice Preview',
    id: INVOICE_PREVIEW,
  },
];

const InvoiceCreateComponent = (props: InvoiceCreateComponentProps) => {
  const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<any | undefined>(steps[0]);
  const params = useParams();
  const [invoiceId, setInvoiceId] = useState<any>(params.invoiceId);
  const [invoiceDetails, setInvoiceDetails] = useState<any>(undefined);
  const [invoiceType, setInvoiceType] = useState<any>(undefined);
  const [isInvoiceDetailsLoading, setIsInvoiceDetailsLoading] =
    useState<boolean>(false);
  const location: any = useLocation();
  const path = location.pathname;

  const updateSteps = useCallback(
    (currentStepIndex: number) => {
      setCurrentStep(steps[currentStepIndex]);
      searchParams.set('step', steps[currentStepIndex].id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, steps],
  );

  const getInvoiceDetails = useCallback(() => {
    let payload = {};
    if (invoiceType === 'invoice') {
      setIsInvoiceDetailsLoading(true);
      CommonService._invoice
        .GetInvoiceDetailsAPICall(invoiceId, payload)
        .then((response: IAPIResponseType<any>) => {
          setInvoiceDetails(response?.data);
          setIsInvoiceDetailsLoading(false);
        })
        .catch((error: any) => {
          setIsInvoiceDetailsLoading(false);
        });
    }
    if (invoiceType === 'debitNote') {
      setIsInvoiceDetailsLoading(true);
      CommonService._debitnote
        .GetDebitNoteDetailsAPICall(invoiceId, payload)
        .then((response: IAPIResponseType<any>) => {
          setInvoiceDetails(response?.data);
          setIsInvoiceDetailsLoading(false);
        })
        .catch((error: any) => {
          setIsInvoiceDetailsLoading(false);
        });
    }
  }, [invoiceId, invoiceType]);

  useEffect(() => {
    if (invoiceId && invoiceType) {
      getInvoiceDetails();
    }
  }, [getInvoiceDetails, invoiceId, invoiceType]);

  useEffect(() => {
    const invoiceType = searchParams.get('invoiceType');
    if (invoiceType) {
      setInvoiceType(invoiceType);
    }
  }, [searchParams]);

  useEffect(() => {
    const step: any = searchParams.get('step');
    const invoiceId = searchParams.get('invoiceId');
    if (invoiceId) {
      setInvoiceId(invoiceId);
    }
    const stepIDS = steps.map((item) => item.id);
    let stepIndex: number = 0;
    if (stepIDS.indexOf(step) > -1) {
      if (step !== NEW_INVOICE) {
        stepIndex = stepIDS.indexOf(step);
      } else {
        stepIndex = 0;
      }
    } else {
      stepIndex = 0;
    }
    setCurrentStep(steps[stepIndex]);
  }, [searchParams, steps]);

  const handlePrevious = useCallback(
    (currentStepIndex: number, currentStepId: string) => {
      if ([INVOICE_DETAILS, INVOICE_PREVIEW].includes(currentStepId)) {
        updateSteps(currentStepIndex);
      } else {
        // do nothing
      }
    },
    [updateSteps],
  );

  const handleBack = useCallback(() => {
    if (path.includes('/invoice/create')) {
      navigate(CommonService._routeConfig.InvoicesListRoute());
    } else {
      // do nothing
    }
  }, [navigate, path]);

  const handleNext = useCallback(
    (currentStepIndex: number, currentStepId: string, data?: any) => {
      switch (currentStepId) {
        case NEW_INVOICE:
          if (data) {
            setInvoiceId(data?._id);
            searchParams.set('invoiceId', data._id.toString());
            searchParams.set(
              'invoiceType',
              data?.category === 'utilityInvoice' &&
                data?.utilityInvoiceType === 'nonGst'
                ? 'debitNote'
                : 'invoice',
            );
            setSearchParams(searchParams);
            setInvoiceDetails((oldState: any) => {
              return {
                ...oldState,
                tenantId: data?.tenantId,
                propertyId: data?.propertyId,
                billingStartDate: data?.billingStartDate,
                billingEndDate: data?.billingEndDate,
                invoiceDueDate: data?.invoiceDueDate,
                billingTo: data?.billingTo,
                amenityId: data?.amenityId,
                files: data?.attachments,
                category: data?.category,
                utilityInvoiceType: data?.utilityInvoiceType,
              };
            });
            // getInvoiceDetails();
          }
          updateSteps(currentStepIndex);
          break;
        case INVOICE_DETAILS:
          if (data) {
            setInvoiceDetails((oldState: any) => {
              return {
                ...oldState,
                invoiceNumber: data?.invoiceNumber,
                invoiceName: data?.invoiceName,
                totalAmount: data?.totalAmount,
                subTotal: data?.subTotal,
                igst: data?.igst,
                sgst: data?.sgst,
                utilityInvoiceType: data?.utilityInvoiceType,
                descriptionOfService: data?.descriptionOfService || '',
                invoiceItemList: data?.invoiceItemList,
              };
            });
            getInvoiceDetails();
          }
          updateSteps(currentStepIndex);
          break;
        case INVOICE_PREVIEW:
          if (data) {
            setInvoiceDetails((oldState: any) => {
              return {
                ...oldState,
              };
            });
          }
          handleBack();
          break;
        default:
          <></>;
      }
    },
    [searchParams, setSearchParams, updateSteps, handleBack, getInvoiceDetails],
  );

  const renderComponent = useCallback(() => {
    switch (currentStep && currentStep.id) {
      case NEW_INVOICE:
        return (
          <NewInvoiceComponent
            next={(data: any) => handleNext(1, NEW_INVOICE, data)}
            prev={handleBack}
            steps={steps}
            activeStepId={currentStep.id}
            invoiceId={invoiceId}
            invoiceDetails={invoiceDetails}
          />
        );

      case INVOICE_DETAILS:
        return (
          <InvoiceDetailsComponent
            next={(data: any) => handleNext(2, INVOICE_DETAILS, data)}
            prev={() => handlePrevious(0, INVOICE_DETAILS)}
            steps={steps}
            activeStepId={currentStep.id}
            invoiceId={invoiceId}
            invoiceDetails={invoiceDetails}
            invoiceType={invoiceType}
          />
        );
      case INVOICE_PREVIEW:
        return (
          <InvoicePreviewComponent
            prev={() => handlePrevious(1, INVOICE_PREVIEW)}
            steps={steps}
            invoiceId={invoiceId}
            activeStepId={currentStep.id}
            invoiceDetails={invoiceDetails}
            invoiceType={invoiceType}
          />
        );
      default:
        <></>;
    }
  }, [
    currentStep,
    handleNext,
    handlePrevious,
    handleBack,
    steps,
    invoiceId,
    invoiceDetails,
    invoiceType,
  ]);

  return (
    <div className='create-invoice-component'>
      {isInvoiceDetailsLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type='spinner' />
        </div>
      ) : (
        <>{renderComponent()}</>
      )}
    </div>
  );
};

export default InvoiceCreateComponent;
