import "./PlainAccordionComponent.scss";
import React, { useCallback, useState } from "react";
import { ImageConfig } from "../../../constants";

interface AccordionComponentProps {
  className?: string;
  disabled?: boolean;
  isExpand?: boolean;
  onClick?: (value: any) => void;
  disableGutters?: boolean;
  title: string;
  actionHeader?: React.ReactNode;
  actions?: React.ReactNode;
  isAccordionOpen?: boolean;
}

const PlainAccordionComponent = (
  props: React.PropsWithChildren<AccordionComponentProps>
) => {
  const {
    className,
    title,
    children,
    onClick,
    actions,
    actionHeader,
    isAccordionOpen,
  } = props;
  const [isActive, setIsActive] = useState(isAccordionOpen);

  const onAccordionClick = useCallback(() => {
    setIsActive(!isActive);
    if (onClick) {
      onClick(!isActive);
    }
  }, [isActive, onClick]);

  return (
    <div className={`plain-accordion-component ${className}`}>
      <div className="">
        <div className="plain-accordion-item" onClick={onAccordionClick}>
          <div className="plain-accordion-actions">
            {actions && (
              <div className={"plain-accordion-action-buttons"}>{actions}</div>
            )}
            <div className="plain-accordion-icons">
              {isActive ? (
                <ImageConfig.DownArrow />
              ) : (
                <ImageConfig.RightArrow />
              )}
            </div>
          </div>
          <div className="plain-accordion-action-header-title">
            {actionHeader && (
              <div className={"plain-accordion-action-header"}>
                {actionHeader}
              </div>
            )}
            <div className="plain-accordion-title">{title}</div>
          </div>
        </div>
        {isActive && <div className="plain-accordion-content">{children}</div>}
      </div>
    </div>
  );
};

export default PlainAccordionComponent;
