import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ImageConfig, Misc } from '../../constants';

export interface AddLayoutProps {}

const AddLayout = (props: AddLayoutProps) => {
  const location: any = useLocation();
  const path = location.pathname;
  let title = '';
  if (path.includes('add-visitor')) {
    title = 'Add Visitor';
  } else if (path.includes('/property/add')) {
    title = 'Add New Property';
  } else if (path.includes('/amenity/add')) {
    title = 'Add New Amenity';
  } else if (path.includes('/amenity/edit')) {
    title = 'Edit Amenity';
  } else if (path.includes('/announcement/addDefault')) {
    title = 'Add Default Announcement';
  } else if (path.includes('/announcement/add')) {
    title = 'Add New Announcement';
  } else if (path.includes('/announcement/editDefault')) {
    title = 'Edit Default Announcement';
  } else if (path.includes('/announcement/edit')) {
    title = 'Edit Announcement';
  } else if (path.includes('/property/edit')) {
    title = 'Edit Property';
  } else if (path.includes('/user/add')) {
    title = 'Add New User';
  } else if (path.includes('/tenant/add')) {
    title = 'Add New Tenant';
  } else if (path.includes('/user/edit')) {
    title = 'Edit User';
  } else if (path.includes('/user/bulk-upload')) {
    title = 'User Bulk Upload';
  } else if (path.includes('poll/add')) {
    title = 'Add New Poll';
  } else if (path.includes('event/add')) {
    title = 'Add New Event';
  } else if (path.includes('event/edit')) {
    title = 'Edit Event';
  } else if (path.includes('poll/edit')) {
    title = 'Edit Poll';
  } else if (path.includes('tenant/edit')) {
    title = 'Edit Tenant';
  } else if (path.includes('entity/edit')) {
    title = 'Edit Entity';
  } else if (path.includes('entity/add')) {
    title = 'Add Entity';
  } else if (path.includes('invoice/create')) {
    title = 'Create New Invoice';
  } else if (path.includes('/billing/edit')) {
    title = 'Edit Billing Preferences';
  } else {
    title = 'Add';
  }

  return (
    <div className='add-layout'>
      <div className='add-layout-header'>
        <div className='add-layout-branding'>
          <Link to={'/'} className={'branding-component'}>
            <img
              className={'logo-default'}
              src={ImageConfig.Logo}
              alt={Misc.APP_NAME + 'Logo'}
            />
            {/*<img className={"logo-sm"} src={ImageConfig.LogoSM} alt={Misc.APP_NAME + 'Logo'}/>*/}
          </Link>
        </div>
        <div className='add-layout-title'>{title}</div>
      </div>
      <div className='add-layout-content-holder'>
        <Outlet />
      </div>
    </div>
  );
};

export default AddLayout;
