import './AmenityViewScreen.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ImageConfig, Misc } from '../../../constants';
import { CommonService } from '../../../shared/services';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import CardComponent from '../../../shared/components/card/CardComponent';
import AvatarComponent from '../../../shared/components/avatar/AvatarComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import DataLabelValueComponent from '../../../shared/components/data-label-value/DataLabelValueComponent';
import _ from 'lodash';
import TableComponent from '../../../shared/components/table/TableComponent';
import moment from 'moment';
import ModalComponent from '../../../shared/components/modal/ModalComponent';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikTextAreaComponent from '../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import { CloseIcon } from '../../../constants/ImageConfig';
import FilePickerComponent from '../../../shared/components/file-picker/FilePickerComponent';
import FullCalendarComponent from '../../../shared/components/full-calendar/FullCalendarComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { ITableColumn } from '../../../shared/models/table.model';
import AccordionComponent from '../../../shared/components/accordion/AccordionComponent';
import PlainAccordionComponent from '../../../shared/components/plain-accordion/PlainAccordionComponent';

interface AmenityViewScreenProps {}

const MODULE = 'AMENITY';

const AmenityDetailsSteps = [
  {
    title: 'Amenity Details',
    id: 'amenityDetails',
  },
  {
    title: 'Gallery',
    id: 'gallery',
  },
  {
    title: 'Reviews',
    id: 'reviews',
  },
  {
    title: 'Invoices',
    id: 'invoices',
  },
];

const amenityUnavailabilitySchema = Yup.object({
  date: Yup.array(),
});

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Announcement Title should be at least 3 characters')
    .max(255, 'Announcement Title should not exceed 255 characters')
    .required('Announcement Title is required'),
  description: Yup.string()
    .required('Announcement Description is required')
    .max(500, 'Announcement Description should not exceed 500 characters'),
  image: Yup.mixed(),
});

//
// const getDatesArray = (startDate: string | number | Date, endDate: string | number | Date, steps = 1) => {
//     const dateArray = [];
//     let currentDate = new Date(startDate);
//
//     while (currentDate <= new Date(endDate)) {
//         const day = new Date(currentDate);
//         const date = day.getDate();
//         const month = day.getMonth();
//         dateArray.push((date <= 9 ? '0' : '') + date + '-' + (month <= 9 ? '0' : '') + month + '-' + day.getFullYear());
//         // Use UTC date to prevent problems with time zones and DST
//         currentDate.setUTCDate(currentDate.getUTCDate() + steps);
//     }
//
//     return dateArray;
// }

const AmenityViewScreen = (props: AmenityViewScreenProps) => {
  const location = useLocation();
  const { amenityId } = useParams();
  const [currentTab, setCurrentTab] = useState<
    'amenityDetails' | 'gallery' | 'reviews' | 'invoices'
  >('amenityDetails');
  const [searchParams] = useSearchParams();
  const [amenityDetails, setAmenityDetails] = useState<any>(null);
  const [isAmenityDetailsLoading, setIsAmenityDetailsLoading] =
    useState<boolean>(false);
  const [isAmenityDetailsLoadingFailed, setIsAmenityDetailsLoadingFailed] =
    useState<boolean>(false);
  const [isAmenityDetailsLoaded, setIsAmenityDetailsLoaded] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAmenityPublish, setShowAmenityPublish] = useState<boolean>(false);

  const galleryPageNumRef = useRef<number>(0);
  const totalGalleryResultsRef = useRef<number>(0);
  const galleryPageSizeRef = useRef<number>(10);
  const [addressDetails, setAddressDetails] = useState<any>([]);
  const [selectedTowerCategories, setSelectedTowerCategories] = useState<any>(
    {},
  );
  const [selectedRentTable, setSelectedRentTable] = useState<any>({});
  const [selectedCamTable, setSelectedCamTable] = useState<any>({});
  const [selectedCampusCamTable, setSelectedCampusCamTable] = useState<any>({});
  const [isInvoiceListLoading, setIsInvoiceListLoading] =
    useState<boolean>(false);
  const [isInvoiceListLoaded, setIsInvoiceListLoaded] =
    useState<boolean>(false);
  const [isInvoiceListLoadingFailed, setIsInvoiceListLoadingFailed] =
    useState<boolean>(false);
  const [invoiceList, setInvoiceList] = useState<any>(undefined);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const getAmenityDetails = useCallback(() => {
    setIsAmenityDetailsLoading(true);
    const payload = {};
    if (amenityId) {
      CommonService._amenity
        .GetAmenityDetailsAPICall(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          const placeOfService = response?.data?.placeOfService;
          const billingAddress = response?.data?.billingAddress;

          const addressDetails = [
            {
              addressType: 'Place Of Service',
              address:
                placeOfService.addressLine1 +
                ', ' +
                placeOfService.city +
                ', ' +
                placeOfService.state +
                ', ' +
                placeOfService.pincode,
            },
            {
              addressType: 'Billing Address',
              address:
                billingAddress.addressLine1 +
                ', ' +
                billingAddress.city +
                ', ' +
                billingAddress.state +
                ', ' +
                billingAddress.pincode,
            },
          ];
          setAddressDetails(addressDetails);
          setAmenityDetails(response.data);
          setIsAmenityDetailsLoaded(true);
          setIsAmenityDetailsLoadingFailed(false);
        })
        .catch((error: any) => {
          setIsAmenityDetailsLoaded(false);
          setIsAmenityDetailsLoadingFailed(true);
        })
        .finally(() => {
          setIsAmenityDetailsLoading(false);
        });
    }
  }, [amenityId]);

  useEffect((): void => {
    getAmenityDetails();
  }, [getAmenityDetails]);

  const getInvoicesList = useCallback(() => {
    let payload: any = {
      amenityIds: [amenityId],
      filter: {
        status: 'draft',
      },
      page: 1,
    };
    setIsInvoiceListLoading(true);
    CommonService._invoice
      .getHistoryInvoiceAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setInvoiceList(response?.data?.docs);
        setIsInvoiceListLoaded(true);
        setIsInvoiceListLoading(false);
      })
      .catch((error: any) => {
        setIsInvoiceListLoadingFailed(true);
        setIsInvoiceListLoaded(false);
        CommonService._alert.showToast(error, 'error');
      })
      .finally(() => {
        setIsInvoiceListLoadingFailed(false);
        setIsInvoiceListLoading(false);
      });
  }, [amenityId]);

  useEffect(() => {
    if (currentTab === 'invoices') {
      getInvoicesList();
    }
  }, [getInvoicesList, currentTab]);

  const [amenityGallery, setAmenityGallery] = useState([]);
  const getAmenityGallery = useCallback((amenityId: string, payload = {}) => {
    if (amenityId) {
      payload = _.cloneDeep({
        limit: galleryPageSizeRef.current,
        ...payload,
        page: galleryPageNumRef.current + 1,
      });
      CommonService._amenity
        .getAmenityGalleryListAPICall(amenityId, payload)
        .then((response: IAPIResponseType<any>) => {
          setAmenityGallery(response.data.docs);
          totalGalleryResultsRef.current = response?.data?.total;
          galleryPageNumRef.current = response?.data?.page - 1;
        })
        .catch((error: any) => {})
        .finally(() => {});
    }
  }, []);

  useEffect((): void => {
    if (amenityId != null) {
      getAmenityGallery(amenityId, { page: 1 });
    }
  }, [getAmenityGallery, amenityId]);

  const handleTabChange = useCallback((value: any) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentStep = searchParams.get('currentStep');
    if (currentStep) {
      setCurrentTab(currentStep as 'amenityDetails' | 'gallery' | 'reviews');
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('currentStep', currentTab);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  }, [currentTab, location]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = AmenityDetailsSteps.map((item: any) => {
      return item.id;
    });
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'amenityDetails';
      }
    } else {
      currentTab = 'amenityDetails';
    }
    setCurrentTab(currentTab);
  }, [searchParams]);

  const toggleAmenity = useCallback(
    (item: any) => {
      CommonService._amenity
        .toggleAmenityAPICall(item._id)
        .then((res) => {
          CommonService._alert.showToast(res.message, 'success');
          CommonService._communications.TableWrapperRefreshSubject.next({
            moduleName: MODULE,
          });
          getAmenityDetails();
        })
        .catch((error) => {
          CommonService._alert.showToast(error.error, 'error');
        });
    },
    [getAmenityDetails],
  );
  const onAmenityActivateDeactivate = useCallback(
    (item: any) => {
      CommonService.onConfirm({
        confirmationTitle: `${
          item?.isActive ? 'Deactivate' : 'Activate'
        }  Amenity`,
        image: item?.isActive
          ? ImageConfig.DeactivateIcon
          : ImageConfig.ActivateIcon,
        confirmationDescription: `Are you sure you want to ${
          item?.isActive ? 'deactivate' : 'activate'
        }  the 
selected amenity?`,
        no: {
          color: 'default',
          text: 'Cancel',
          variant: 'outlined',
        },
        yes: {
          color: 'error',
          text: 'Confirm',
          variant: 'contained',
        },
      }).then((result: any) => {
        toggleAmenity(item);
      });
    },
    [toggleAmenity],
  );

  const [showAmenityUnavailable, setShowAmenityUnavailable] = useState(false);
  const onAmenityUnavailable = useCallback(
    (
      amenityId: string,
      dates: string[],
      cb: (success: boolean, resp: any) => void,
    ) => {
      if (amenityId) {
        CommonService._amenity
          .UpdateAmenityUnavailabilityAPICall(amenityId, {
            unAvailableDates: dates,
          })
          .then((response: IAPIResponseType<any>) => {
            setShowAmenityUnavailable(false);
            getAmenityDetails();
            cb(true, response);
          })
          .catch((error: any) => {
            getAmenityDetails();
            cb(false, error);
          });
      }
    },
    [getAmenityDetails],
  );
  useEffect(() => {
    dispatch(
      setCurrentNavParams('Amenity Details', null, () => {
        navigate(CommonService._routeConfig.AmenityRoute());
      }),
    );
  }, [dispatch, navigate]);

  const handleBack = useCallback(() => {
    setShowAmenityPublish(false);
  }, []);
  const addAnnouncement = useCallback(
    (values: any, helpers: FormikHelpers<any>) => {
      const payload: any = {};
      payload['type'] = values.type;
      payload['title'] = values.title;
      payload['isDefault'] = false;
      payload['description'] = values.description;
      payload['linkedTo'] = values.linkedTo;

      const formData = CommonService.getFormDataFromJSON(payload);
      if (values.image) {
        formData.set('image', values.image);
      }
      CommonService._wall
        .addAnnouncement(formData)
        .then((response: IAPIResponseType<any>) => {
          // setAddAnnuncement(propertyDetails);
          helpers.setSubmitting(false);
          handleBack();
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
        })
        .catch((error: any) => {
          helpers.setSubmitting(false);
          CommonService._alert.showToast(error.error, 'error');
        });
    },
    [handleBack],
  );

  const TowerTab = useCallback(
    (tower: any, type?: string) => [
      {
        title: 'Office Floors',
        id: 'officeFloors',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.floorBreakdown?.length
              : tower?.officeFloors?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
      {
        title: 'Basements',
        id: 'basements',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.basementBreakdown?.length
              : tower?.basements?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
      {
        title: 'Hard Option',
        id: 'hardOptions',
        badge: {
          text:
            (type === 'towerDetails'
              ? tower?.hardOptionBreakdown?.length
              : tower?.hardOptions?.filter(
                  (item: any) => item?.isSelected === true,
                ).length) || '0',
          color: 'primary',
        },
      },
    ],
    [],
  );

  const InvoiceListTableColumn: ITableColumn[] = [
    {
      title: 'Invoice No',
      key: 'invoiceNo',
      dataIndex: 'invoiceNo',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.gstData?.DocDtls?.No ? (
              <div>{item?.gstData?.DocDtls?.No}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Invoice Generated On',
      key: 'invoiceGeneratedOn',
      dataIndex: 'invoiceGeneratedOn',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.generatedDate ? (
              <div>{moment(item.generatedDate).format('DD-MM-YYYY')}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Invoice Due Date',
      key: 'invoiceDueDate',
      dataIndex: 'invoiceDueDate',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.invoiceDueDate ? (
              <>{moment(item.invoiceDueDate).format('DD-MM-YYYY')}</>
            ) : (
              <>-</>
            )}
          </div>
        );
      },
    },

    {
      title: 'Total Amount',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.totalAmount ? <div>{item?.totalAmount}</div> : <div>-</div>}
          </div>
        );
      },
    },
    {
      title: 'Invoice Paid On',
      key: 'invoicePaidOn',
      dataIndex: 'invoicePaidOn',
      width: 120,
      render: (item: any) => {
        return (
          <div>
            {item?.invoicePaidDate ? (
              <div>{moment(item.invoicePaidDate).format('DD-MM-YYYY')}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.paymentStatus ? (
              <TagComponent
                customBg={
                  item?.status === 'cancelled'
                    ? '#FBE5E5'
                    : item?.paymentStatus === 'paid'
                    ? '#D7FFD3'
                    : item?.paymentStatus === 'pending'
                    ? '#FBFAE5'
                    : ''
                }
                customTextColor={'#636363'}
                showIndicator={true}
                label={
                  item?.status === 'cancelled'
                    ? 'Cancelled'
                    : item?.paymentStatus
                }
              />
            ) : (
              <>--</>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 60,
      render: (item: any) => {
        if (item?._id) {
          return (
            <div className='invoice-list-action-tablecolumn'>
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  navigate(
                    CommonService._routeConfig.InvoiceDetailsRoute(item?._id),
                    {
                      state: {
                        cameFrom: 'amenity',
                      },
                    },
                  );
                }}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </div>
            </div>
          );
        }
      },
    },
  ];

  const TowerColumns = useMemo(
    () =>
      (selectedTowerCategory: any): ITableColumn[] =>
        [
          {
            title:
              selectedTowerCategory === 'officeFloors'
                ? 'Floor'
                : selectedTowerCategory === 'basements'
                ? 'Basement'
                : selectedTowerCategory === 'hardOptions'
                ? 'Hard Option'
                : '',
            key: 'floorNumber',
            dataIndex: 'floorNumber',
            width: 205,
            render: (item: any) => {
              return <>{item?.title}</>;
            },
          },
          {
            title: 'Leased Area',
            key: 'occupiedArea',
            dataIndex: 'occupiedArea',
            render: (item: any) => {
              return <>{item?.occupiedArea || 0} sqft</>;
            },
          },
        ],
    [],
  );

  const TowerTableColumn = useMemo(
    () =>
      (selectedTable: any): ITableColumn[] =>
        [
          {
            title:
              selectedTable === 'officeFloors'
                ? 'Floor'
                : selectedTable === 'basements'
                ? 'Basement'
                : selectedTable === 'hardOptions' && 'Hard Option',
            key: 'number',
            dataIndex: 'number',
            width: 100,
            render: (item: any) => {
              return <div>{item.title}</div>;
            },
          },
          {
            title: 'Area Occupied',
            key: 'areaOccupied',
            dataIndex: 'areaOccupied',
            width: 150,
            render: (item: any) => {
              return <div>{item.occupiedArea + ' sft' || '--'}</div>;
            },
          },
          {
            title: 'Previous Value',
            key: 'previousValue',
            dataIndex: 'previousValue',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item?.previousValue ? (
                    <>{'Rs. ' + item.previousValue + ' /-' || '0'}</>
                  ) : (
                    '0'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Escalation Percentage',
            key: 'percentage',
            dataIndex: 'percentage',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.rateOfEscalation ? (
                    <>{item.rateOfEscalation + '%'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Current Value',
            key: 'ratePerSft',
            dataIndex: 'ratePerSft',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.ratePerSft ? (
                    <>{'Rs ' + item.ratePerSft + ' /-' || '--'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          // {
          //   title: 'Current Value',
          //   key: 'currentValue',
          //   dataIndex: 'currentValue',
          //   width: 120,
          //   render: (item: any) => {
          //     return <div>{'Rs. ' + item.currentValue + ' /-' || '0'}</div>;
          //   },
          // },
          {
            title: 'Escalation',
            key: 'escalation',
            dataIndex: 'escalation',
            width: 120,
            render: (item: any) => {
              return (
                <div>
                  {item.escalationStatus === 'applied' && (
                    <TagComponent
                      className={'mrg-top-10'}
                      label={item.escalationStatus}
                      showIndicator
                      customBg='#E6F3E9'
                      customTextColor='#45C962'
                    />
                  )}
                  {item.escalationStatus === 'upcoming' && (
                    <TagComponent
                      className={'mrg-top-10'}
                      label={item.escalationStatus}
                      showIndicator
                      customBg='#FBF1E5'
                      customTextColor='#FF5630'
                    />
                  )}
                </div>
              );
            },
          },
          {
            title: 'Escalation Applied On',
            key: 'escalationAppliedOn',
            dataIndex: 'escalationAppliedOn',
            width: 150,
            align: 'center',
            render: (item: any) => {
              return (
                <div>
                  {item.lastEscalationDate ? (
                    <>{moment(item.lastEscalationDate).format('DD-MM-YYYY')}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Next Escalation On',
            key: 'nextEscalationOn',
            dataIndex: 'nextEscalationOn',
            width: 150,
            render: (item: any) => {
              return (
                <div>
                  {item.nextEscalationDate ? (
                    <>{moment(item.nextEscalationDate).format('DD-MM-YYYY')}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
          {
            title: 'Updated Value',
            key: 'updatedValue',
            dataIndex: 'updatedValue',
            width: 120,
            render: (item: any) => {
              return (
                <div>
                  {item.updatedValue ? (
                    <>{'Rs. ' + item.updatedValue + ' /-' || '0'}</>
                  ) : (
                    '--'
                  )}
                </div>
              );
            },
          },
        ],
    [],
  );

  return (
    <div className={'amenity-view-screen '}>
      {showAmenityUnavailable && (
        <ModalComponent
          title={'Select Dates'}
          className={'amenity-view-datepicker'}
          closeOnBackDropClick={true}
          closeOnEsc={true}
          onClose={() => {
            setShowAmenityUnavailable(false);
          }}
          showClose={true}
          isOpen={showAmenityUnavailable}
        >
          <Formik
            validationSchema={amenityUnavailabilitySchema}
            initialValues={{
              date: amenityDetails.unAvailableDates || [],
            }}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={(values) => {
              // const dateRange = (values.date || []).map((v: any) => moment(v).format('YYYY-MM-DD'));
              if (amenityId != null) {
                onAmenityUnavailable(
                  amenityId,
                  values.date || [],
                  (success, resp) => {
                    if (success) {
                      CommonService._alert.showToast(
                        'Amenity Unavailability dates updated',
                        'success',
                      );
                    } else {
                      CommonService._alert.showToast(
                        'Unable to update Unavailability dates',
                        'error',
                      );
                    }
                  },
                );
              }
            }}
          >
            {({
              values,
              errors,
              validateForm,
              isValid,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form className='t-form scroll-content' noValidate={true}>
                  <Field name={'date'}>
                    {() => {
                      return (
                        <FullCalendarComponent
                          minDate={moment().format('YYYY-MM-DD')}
                          maxDate={moment().add(1, 'year').format('YYYY-MM-DD')}
                          disabledDates={[]}
                          datesSelected={(dates) => {
                            setFieldValue('date', dates);
                          }}
                          canSelect={true}
                          selectedDates={values.date || []}
                          startDay={moment().format('YYYY-MM-DD')}
                          showControls={true}
                        />
                      );
                    }}
                  </Field>

                  <div
                    className={
                      'display-flex ts-justify-content-center mrg-top-20'
                    }
                  >
                    <ButtonComponent
                      type={'button'}
                      color={'secondary'}
                      size={'medium'}
                      variant={'outlined'}
                      onClick={(event) => {
                        setFieldValue('date', []);
                      }}
                    >
                      Clear
                    </ButtonComponent>
                    &nbsp;&nbsp;
                    <ButtonComponent
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      type={'submit'}
                      color={'primary'}
                      size={'medium'}
                      variant={'contained'}
                    >
                      Confirm
                    </ButtonComponent>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalComponent>
      )}
      {showAmenityPublish && (
        <ModalComponent
          className={'amenity-view-publish'}
          closeOnBackDropClick={true}
          closeOnEsc={true}
          onClose={() => {
            setShowAmenityPublish(false);
          }}
          isOpen={showAmenityPublish}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              title: '',
              description: '',
              image: null,
              type: 'Amenity',
              linkedTo: amenityId,
            }}
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={addAnnouncement}
          >
            {({
              values,
              errors,
              validateForm,
              isValid,
              setFieldValue,
              isSubmitting,
            }) => {
              return (
                <Form className='t-form scroll-content ' noValidate={true}>
                  {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                  <div className='add-component-header '>
                    <div className='add-layout-header-text'>
                      Publish Announcement
                    </div>
                  </div>

                  <div className='add-component-content'>
                    <Field name={'title'}>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          label={'Announcement Title'}
                          placeholder={'Enter Announcement Title'}
                          type={'text'}
                          required={true}
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>

                    <Field name={'description'}>
                      {(field: FieldProps) => (
                        <FormikTextAreaComponent
                          label={'Announcement Description'}
                          placeholder={
                            'Enter the announcement description here'
                          }
                          formikField={field}
                          fullWidth={true}
                        />
                      )}
                    </Field>
                    <br />

                    <div className={'row-wrapper'}>
                      <FormControlLabelComponent label={'Upload Image'} />
                      <div className={'optional-text'}>Optional</div>
                    </div>

                    {!!values.image && (
                      <div className='image-preview'>
                        <div
                          className='close-btn'
                          onClick={(event) => {
                            event.preventDefault();
                            setFieldValue('image', undefined);
                          }}
                        >
                          <CloseIcon />
                        </div>
                        <img src={URL.createObjectURL(values.image)} alt='' />
                      </div>
                    )}
                    <FilePickerComponent
                      acceptedFileTypes={['png', 'jpeg', 'jpg']}
                      showDropZone={true}
                      onFilesDrop={(acceptedFiles) => {
                        setFieldValue('image', acceptedFiles[0]);
                      }}
                      acceptedFilesText={'(Upload Only one image)'}
                    />
                  </div>
                  <div className='d-flex ts-justify-content-center mrg-bottom-20'>
                    <ButtonComponent
                      variant={'outlined'}
                      type={'button'}
                      color={'secondary'}
                      onClick={handleBack}
                      // suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                    >
                      Cancel
                    </ButtonComponent>
                    &nbsp;&nbsp;
                    <ButtonComponent
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      type={'submit'}
                      // suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                    >
                      Confirm
                    </ButtonComponent>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </ModalComponent>
      )}
      {isAmenityDetailsLoading && (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      )}
      {isAmenityDetailsLoadingFailed && (
        <div className='h-v-center'>
          <StatusCardComponent title={'Failed to Load'} />
        </div>
      )}
      {isAmenityDetailsLoaded && (
        <div className='details-screen'>
          <CardComponent>
            <div className={'details-screen-header'}>
              <div className='details-screen-title-with-image-wrapper'>
                <div className={'details-screen-image'}>
                  <AvatarComponent
                    type='circle'
                    url={amenityDetails?.profilePic?.url}
                    size={'xl'}
                    title={CommonService.getTwoLetterInitials(
                      amenityDetails?.name,
                    )}
                  />
                </div>
                <div className={'details-screen-title-wrapper'}>
                  <div className={'details-screen-title'}>
                    {CommonService.capitalizeFirstLetterOfEachWord(
                      amenityDetails?.name || '',
                    ) || 'N/A'}
                  </div>
                  <div className={'details-screen-description'}>
                    {amenityDetails.propertyDetails?.name}
                  </div>
                </div>
              </div>
              <div className='details-screen-options'>
                <SwitchComponent
                  color={'primary'}
                  onChange={onAmenityActivateDeactivate.bind(
                    null,
                    amenityDetails,
                  )}
                  checked={amenityDetails?.isActive}
                  label={
                    !amenityDetails?.isActive
                      ? 'Activate Amenity'
                      : 'Deactivate Amenity'
                  }
                />
                <ButtonComponent
                  color={'primary'}
                  onClick={setShowAmenityUnavailable.bind(null, true)}
                  variant={'outlined'}
                >
                  Set Unavailability
                </ButtonComponent>
                <ButtonComponent
                  color={'primary'}
                  onClick={(event) => {
                    setShowAmenityPublish(true);
                    // CommonService._alert.showToast('Coming Soon', 'info');
                  }}
                  variant={'outlined'}
                >
                  Publish Announcement
                </ButtonComponent>
                {amenityDetails.websiteLink && (
                  <ButtonComponent
                    color={'primary'}
                    onClick={(event) => {
                      window.open(amenityDetails.websiteLink, '_blank');
                    }}
                    variant={'outlined'}
                  >
                    View Website
                  </ButtonComponent>
                )}
                {amenityId && (
                  <LinkComponent
                    route={
                      CommonService._routeConfig.AmenityEditRoute(amenityId) +
                      `?step=amenity-details`
                    }
                  >
                    <ButtonComponent color={'primary'}>
                      Edit Amenity Details
                    </ButtonComponent>
                  </LinkComponent>
                )}
              </div>
            </div>

            <br />

            <div className={'unavailability-heading'}>Unavailable On</div>
            <div className='unavailability-dates-wrapper'>
              {(amenityDetails?.unAvailableDates || []).map(
                (a: string, i: number) => {
                  return (
                    <ChipComponent
                      onDelete={() => {
                        if (amenityId != null) {
                          const oldDates = [
                            ...amenityDetails?.unAvailableDates,
                          ];
                          oldDates.splice(i, 1);
                          onAmenityUnavailable(
                            amenityId,
                            oldDates,
                            (success, resp) => {
                              if (success) {
                                CommonService._alert.showToast(
                                  'Amenity Unavailability dates removed',
                                  'success',
                                );
                              } else {
                                CommonService._alert.showToast(
                                  'Unable to remove Unavailability date',
                                  'error',
                                );
                              }
                            },
                          );
                        }
                      }}
                      className={'unavailability-date-chip'}
                      label={a}
                      key={i}
                    />
                  );
                },
              )}
            </div>

            <ButtonGroupComponent
              selected={currentTab}
              onChange={(value) => handleTabChange(value)}
              buttons={AmenityDetailsSteps}
            />
            {currentTab === 'amenityDetails' && (
              <div className=' mrg-top-20'>
                <CardComponent>
                  <div className='ts-row ts-no-gutters'>
                    <div className={'ts-col-lg-12'}>
                      <DataLabelValueComponent
                        label={
                          'Amenity Tagline(add a catchy tagline for the amenity)'
                        }
                      >
                        {amenityDetails?.amenityTagline || '-'}
                      </DataLabelValueComponent>
                    </div>

                    <div className={'ts-col-lg-12'}>
                      <DataLabelValueComponent label={'Amenity Description'}>
                        {amenityDetails?.shortDescription || '-'}
                      </DataLabelValueComponent>
                    </div>
                  </div>
                  <div className='mrg-top-15 mrg-bottom-15'>
                    <HorizontalLineComponent />
                  </div>
                  <div className='ts-row ts-no-gutters'>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Amenity Type'}>
                        {amenityDetails?.type || '-'}
                      </DataLabelValueComponent>
                    </div>

                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Property'}>
                        {amenityDetails?.propertyDetails?.name || '-'}
                      </DataLabelValueComponent>
                    </div>
                    {/*{amenityDetails?.location && <div className={'ts-col-lg-3'}>*/}
                    {/*    <DataLabelValueComponent label={'Location of the property'}>*/}
                    {/*        {amenityDetails?.location || '-'}*/}
                    {/*    </DataLabelValueComponent>*/}
                    {/*</div>}*/}
                    <div className={'ts-col-lg-2'}>
                      <DataLabelValueComponent label={'Capacity'}>
                        {amenityDetails?.capacity || '-'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-4'}>
                      <DataLabelValueComponent
                        label={'Operator Website'}
                        className={'text-overflow'}
                      >
                        {amenityDetails?.websiteLink || '-'}
                      </DataLabelValueComponent>
                    </div>
                    <DataLabelValueComponent
                      label={
                        'Vendor URL (for opening their application in the converz app)'
                      }
                      className={'text-overflow'}
                    >
                      {amenityDetails?.vendorUrl || '-'}
                    </DataLabelValueComponent>
                  </div>
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Tower Details'}
                    isBold={true}
                    className={'mrg-left-20'}
                    level={4}
                  />
                  {amenityDetails?.towers?.towerBreakdown ? (
                    <div className='towers-list-wrapper'>
                      {amenityDetails?.towers?.towerBreakdown?.map(
                        (item: any, index: number) => {
                          const selectedTowerCategory =
                            selectedTowerCategories[item.name] ||
                            'officeFloors';
                          return (
                            <div
                              key={index}
                              className='pdd-left-25 pdd-right-25'
                            >
                              <AccordionComponent key={index} title={item.name}>
                                {item?.isTowerSelected &&
                                  (!item?.isTotalTowerOccupied ? (
                                    <div className='flex-col-wrapper'>
                                      <div>
                                        <ButtonGroupComponent
                                          buttons={TowerTab(
                                            item,
                                            'towerDetails',
                                          )}
                                          selected={selectedTowerCategory}
                                          onChange={(value: any) => {
                                            setSelectedTowerCategories(
                                              (prevState: any) => ({
                                                ...prevState,
                                                [item.name]: value,
                                              }),
                                            );
                                          }}
                                        />
                                      </div>
                                      <TableComponent
                                        data={
                                          selectedTowerCategory ===
                                          'officeFloors'
                                            ? item?.floorBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : selectedTowerCategory ===
                                              'basements'
                                            ? item?.basementBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : selectedTowerCategory ===
                                              'hardOptions'
                                            ? item?.hardOptionBreakdown.sort(
                                                (a: any, b: any) =>
                                                  a.number - b.number,
                                              )
                                            : []
                                        }
                                        noDataText={
                                          selectedTowerCategory ===
                                          'officeFloors'
                                            ? 'No floors are added to this tower'
                                            : selectedTowerCategory ===
                                              'basements'
                                            ? 'No basements are added to this tower'
                                            : selectedTowerCategory ===
                                              'hardOptions'
                                            ? 'No hard options are added to this tower'
                                            : 'No data'
                                        }
                                        // columns={TowerColumns}
                                        columns={TowerColumns(
                                          selectedTowerCategory,
                                        )}
                                        autoHeight
                                        className={'tower-table-wrapper'}
                                      />
                                    </div>
                                  ) : (
                                    <div>Occupied full tower</div>
                                  ))}
                              </AccordionComponent>
                            </div>
                          );
                        },
                      )}
                    </div>
                  ) : (
                    <StatusCardComponent title='No Tower details present' />
                  )}
                </CardComponent>

                <CardComponent wrapper={'card-component-padding'}>
                  <FormControlLabelComponent
                    label={'Address Details'}
                    isBold={true}
                    level={4}
                    className={'mrg-left-20'}
                  />
                  <TableComponent
                    noDataText={'No Admin details added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Address Type',
                        key: 'addressType',
                        dataIndex: 'addressType',
                        width: '40',
                        render: (item: any) => {
                          return <>{item?.addressType || '-'}</>;
                        },
                      },
                      {
                        title: 'Address',
                        key: 'address',
                        dataIndex: 'address',
                        width: '300',
                        render: (item: any) => {
                          return <>{item?.address || '-'}</>;
                        },
                      },
                    ]}
                    data={addressDetails}
                  />
                </CardComponent>

                <CardComponent>
                  <FormControlLabelComponent
                    label={'Amenity Admin Details'}
                    isBold={true}
                  />
                  <TableComponent
                    noDataText={'No Admin Details added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Admin Name',
                        key: 'name',
                        dataIndex: 'name',
                        width: 100,
                        render: (item: any) => {
                          return (
                            <div className='text-transform-capitalize'>
                              {item?.adminName || '-'}
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Phone Number',
                        key: 'phone',
                        dataIndex: 'phone',
                        width: 50,
                        render: (item: any) => {
                          return (
                            <>
                              {CommonService.convertPhoneNumber(
                                item?.phoneNumber,
                              ) || '-'}
                            </>
                          );
                        },
                      },
                      {
                        title: 'Email Type',
                        key: 'emailType',
                        dataIndex: 'emailType',
                        width: 20,
                        render: (item: any) => {
                          return (
                            <div className='text-transform-capitalize'>
                              {item?.emailType || '-'}
                            </div>
                          );
                        },
                      },
                      {
                        title: 'Designation',
                        key: 'designation',
                        dataIndex: 'designation',
                        width: 60,
                        render: (item: any) => {
                          return <>{item?.designation || '--'}</>;
                        },
                      },
                      {
                        title: 'Email',
                        key: 'email',
                        dataIndex: 'email',
                        render: (item: any) => {
                          return <>{item?.email || '-'}</>;
                        },
                      },
                    ]}
                    data={amenityDetails?.adminDetails || []}
                  />
                </CardComponent>

                <CardComponent>
                  <FormControlLabelComponent label={'Timings'} isBold={true} />
                  <TableComponent
                    noDataText={'No Timings added yet'}
                    className={'mrg-top-15'}
                    autoHeight={true}
                    columns={[
                      {
                        title: 'Day',
                        key: 'day',
                        dataIndex: 'day',
                      },
                      {
                        title: 'Timings',
                        key: 'timings',
                        width: '80%',
                        dataIndex: 'timings',
                        render: (item: any) => {
                          return (
                            <>
                              {moment(item.from, 'hh:mm').format('hh:mm A') +
                                ' - ' +
                                moment(item.to, 'hh:mm').format('hh:mm A')}
                            </>
                          );
                        },
                      },
                      {
                        title: '',
                        key: 'actions',
                        dataIndex: 'actions',
                      },
                    ]}
                    data={
                      amenityDetails.isSameEveryDay
                        ? [
                            {
                              day: 'All Days',
                              from: amenityDetails?.timings.from,
                              to: amenityDetails?.timings.to,
                            },
                          ]
                        : amenityDetails?.timings || []
                    }
                  />
                </CardComponent>

                <div>
                  <FormControlLabelComponent
                    label='Billing Details'
                    isBold
                    level={5}
                  />
                  <div className={'ts-row mrg-top-20'}>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'GST Number'}>
                        {amenityDetails?.billingPreferences?.gstNumber || '--'}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Pan Number'}>
                        {amenityDetails?.billingPreferences?.panNumber ||
                          ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Rent Commencement Date'}>
                        {amenityDetails?.billingPreferences
                          ?.rentCommencementDate || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent
                        label={'Lease Commencement Date'}
                      >
                        {amenityDetails?.billingPreferences
                          ?.leaseCommencementDate || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    <div className={'ts-col-lg-3'}>
                      <DataLabelValueComponent label={'Billing Type'}>
                        {(amenityDetails?.billingPreferences?.billingType ===
                        'profitSharing'
                          ? 'Profit Sharing'
                          : amenityDetails?.billingPreferences?.billingType ===
                            'revenueSharing'
                          ? 'Revenue Sharing'
                          : 'Other') || ' -- '}
                      </DataLabelValueComponent>
                    </div>
                    {amenityDetails?.billingPreferences?.billingType !==
                      'other' && (
                      <div className={'ts-col-lg-3'}>
                        <DataLabelValueComponent
                          label={
                            amenityDetails?.billingPreferences?.billingType ===
                            'profitSharing'
                              ? 'Profit Sharing Value'
                              : 'Revenue Sharing Value'
                          }
                        >
                          {(amenityDetails?.billingPreferences?.billingType ===
                          'profitSharing'
                            ? amenityDetails?.billingPreferences
                                ?.profitPercentage
                            : amenityDetails?.billingPreferences
                                ?.revenuePercentage) || ' -- '}
                        </DataLabelValueComponent>
                      </div>
                    )}
                  </div>
                  <HorizontalLineComponent className='mrg-bottom-20' />
                </div>
                <div className='flex-wrapper'>
                  <FormControlLabelComponent
                    label={'Billing Preferences'}
                    isBold={true}
                    level={5}
                  />
                  <LinkComponent
                    route={CommonService._routeConfig.editAmenityBillingRoute(
                      amenityId,
                    )}
                  >
                    <ButtonComponent
                      disabled={!amenityDetails?.billingPreferences}
                    >
                      Edit Billing Preferences
                    </ButtonComponent>
                  </LinkComponent>
                </div>

                {amenityDetails?.billingPreferences && (
                  <>
                    {amenityDetails?.billingPreferences?.billingType ===
                      'other' && (
                      <PlainAccordionComponent
                        title={`Rent`}
                        isExpand={true}
                        isAccordionOpen={true}
                      >
                        <div>
                          <div className='list-screen-header-wrapper'>
                            {!amenityDetails?.billingPreferences
                              ?.isRentSelected ? (
                              <div className='no-data-found'>
                                No Rent data found
                              </div>
                            ) : (
                              <>
                                {amenityDetails?.billingPreferences?.billingComponents?.rent?.towers?.map(
                                  (tower: any, index: number) => {
                                    const selectedTable =
                                      selectedRentTable[tower?.towername] ||
                                      'officeFloors';
                                    const tableButtons = TowerTab(tower);
                                    return (
                                      <AccordionComponent
                                        key={tower?.towerId}
                                        title={tower?.towername}
                                      >
                                        <ButtonGroupComponent
                                          buttons={tableButtons}
                                          selected={selectedTable}
                                          onChange={(value: any) =>
                                            setSelectedRentTable(
                                              (prevState: any) => ({
                                                ...prevState,
                                                [tower.towername]: value,
                                              }),
                                            )
                                          }
                                        />
                                        <div className='mrg-top-20'>
                                          <TableComponent
                                            autoHeight={true}
                                            data={
                                              selectedTable === 'officeFloors'
                                                ? tower?.officeFloors?.filter(
                                                    (item: any) =>
                                                      item?.isSelected === true,
                                                  )
                                                : selectedTable === 'basements'
                                                ? tower?.basements?.filter(
                                                    (item: any) =>
                                                      item?.isSelected === true,
                                                  )
                                                : selectedTable ===
                                                  'hardOptions'
                                                ? tower?.hardOptions?.filter(
                                                    (item: any) =>
                                                      item?.isSelected === true,
                                                  )
                                                : []
                                            }
                                            columns={TowerTableColumn(
                                              selectedTable,
                                            )}
                                            noDataText={
                                              selectedTable === 'officeFloors'
                                                ? 'No floors are added to this tower'
                                                : selectedTable === 'basements'
                                                ? 'No basements are added to this tower'
                                                : selectedTable ===
                                                  'hardOptions'
                                                ? 'No hard options are added to this tower'
                                                : 'No data'
                                            }
                                            className={'tower-table-wrapper'}
                                          />
                                        </div>
                                      </AccordionComponent>
                                    );
                                  },
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </PlainAccordionComponent>
                    )}
                    <PlainAccordionComponent
                      title={`Building CAM`}
                      isExpand={true}
                      isAccordionOpen={false}
                    >
                      <div>
                        <div className='list-screen-header-wrapper'>
                          {!amenityDetails?.billingPreferences
                            ?.isCamSelected ? (
                            <div className='no-data-found'>
                              No Building CAM data found
                            </div>
                          ) : (
                            <>
                              {amenityDetails?.billingPreferences?.billingComponents?.cam?.towers?.map(
                                (tower: any, index: number) => {
                                  const selectedTable =
                                    selectedCamTable[tower?.towername] ||
                                    'officeFloors';
                                  const tableButtons = TowerTab(tower);
                                  return (
                                    <AccordionComponent
                                      key={tower?.towerId}
                                      title={tower?.towername || 'N/A'}
                                    >
                                      <ButtonGroupComponent
                                        buttons={tableButtons}
                                        selected={selectedTable}
                                        onChange={(value: any) =>
                                          setSelectedCamTable(
                                            (prevState: any) => ({
                                              ...prevState,
                                              [tower.towername]: value,
                                            }),
                                          )
                                        }
                                      />
                                      <div className='mrg-top-20'>
                                        <TableComponent
                                          autoHeight={true}
                                          data={
                                            selectedTable === 'officeFloors'
                                              ? tower?.officeFloors?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : selectedTable === 'basements'
                                              ? tower?.basements?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : selectedTable === 'hardOptions'
                                              ? tower?.hardOptions?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : []
                                          }
                                          columns={TowerTableColumn(
                                            selectedTable,
                                          )}
                                          noDataText={
                                            selectedTable === 'officeFloors'
                                              ? 'No floors are added to this tower'
                                              : selectedTable === 'basements'
                                              ? 'No basements are added to this tower'
                                              : selectedTable === 'hardOptions'
                                              ? 'No hard options are added to this tower'
                                              : 'No data'
                                          }
                                          className={'tower-table-wrapper'}
                                        />
                                      </div>
                                    </AccordionComponent>
                                  );
                                },
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </PlainAccordionComponent>

                    <PlainAccordionComponent
                      title={`Campus CAM`}
                      isExpand={true}
                      isAccordionOpen={false}
                    >
                      <div>
                        <div className='list-screen-header-wrapper'>
                          {!amenityDetails?.billingPreferences
                            ?.isCampusCamSelected ? (
                            <div className='no-data-found'>
                              No Campus CAM data found
                            </div>
                          ) : (
                            <>
                              {amenityDetails?.billingPreferences?.billingComponents?.campusCam?.towers?.map(
                                (tower: any, index: number) => {
                                  const selectedTable =
                                    selectedCampusCamTable[tower?.towername] ||
                                    'officeFloors';
                                  const tableButtons = TowerTab(tower);
                                  return (
                                    <AccordionComponent
                                      key={tower?.towerId}
                                      title={tower?.towername || 'N/A'}
                                    >
                                      <ButtonGroupComponent
                                        buttons={tableButtons}
                                        selected={selectedTable}
                                        onChange={(value: any) =>
                                          setSelectedCampusCamTable(
                                            (prevState: any) => ({
                                              ...prevState,
                                              [tower.towername]: value,
                                            }),
                                          )
                                        }
                                      />
                                      <div className='mrg-top-20'>
                                        <TableComponent
                                          autoHeight={true}
                                          data={
                                            selectedTable === 'officeFloors'
                                              ? tower?.officeFloors?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : selectedTable === 'basements'
                                              ? tower?.basements?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : selectedTable === 'hardOptions'
                                              ? tower?.hardOptions?.filter(
                                                  (item: any) =>
                                                    item?.isSelected === true,
                                                )
                                              : []
                                          }
                                          columns={TowerTableColumn(
                                            selectedTable,
                                          )}
                                          noDataText={
                                            selectedTable === 'officeFloors'
                                              ? 'No floors are added to this tower'
                                              : selectedTable === 'basements'
                                              ? 'No basements are added to this tower'
                                              : selectedTable === 'hardOptions'
                                              ? 'No hard options are added to this tower'
                                              : 'No data'
                                          }
                                          className={'tower-table-wrapper'}
                                        />
                                      </div>
                                    </AccordionComponent>
                                  );
                                },
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </PlainAccordionComponent>

                    <PlainAccordionComponent
                      title={'Parking'}
                      isExpand={true}
                      isAccordionOpen={false}
                    >
                      {amenityDetails?.billingPreferences?.isParkingSelected ? (
                        <div>
                          <div className={'ts-row'}>
                            <div className={'ts-col-lg-3'}>
                              <DataLabelValueComponent
                                label={'Total Parking Slots'}
                              >
                                {amenityDetails?.billingPreferences
                                  ?.billingComponents?.parking?.numberOfSlots ||
                                  '0'}
                              </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col-lg-3'}>
                              <DataLabelValueComponent label={'Price per slot'}>
                                Rs.
                                {amenityDetails?.billingPreferences
                                  ?.billingComponents?.parking?.perSlotPrice ||
                                  '0'}{' '}
                                /-
                              </DataLabelValueComponent>
                            </div>
                            <div className={'ts-col'} />
                          </div>
                          {amenityDetails?.billingPreferences?.billingComponents
                            ?.parking?.enableAdditionalParkingSlots && (
                            <>
                              <FormControlLabelComponent
                                label={'Additional Slots'}
                                isBold={true}
                                level={6}
                              />
                              <TableComponent
                                noDataText={'No Additional Slots'}
                                className={'mrg-top-15'}
                                autoHeight={true}
                                columns={[
                                  {
                                    title: 'Parking Slots',
                                    key: 'additional-slot',
                                    dataIndex: 'additional-slot',
                                    render: (item: any) => {
                                      return <>{item?.numberOfSlots || '-'}</>;
                                    },
                                  },
                                  {
                                    title: 'Price Per Slot',
                                    key: 'pricePerSlot',
                                    dataIndex: 'pricePerSlot',
                                    render: (item: any) => {
                                      return <>{item?.perSlotPrice || '-'}</>;
                                    },
                                  },
                                  {
                                    title: 'Invoice Start Date',
                                    key: 'invoiceStartDate',
                                    dataIndex: 'invoiceStartDate',
                                    render: (item: any) => {
                                      return (
                                        <>
                                          {moment(item.invoiceStartDate).format(
                                            'DD-MM-YYYY',
                                          ) || '--'}
                                        </>
                                      );
                                    },
                                  },
                                ]}
                                data={
                                  amenityDetails?.billingPreferences
                                    ?.billingComponents?.parking
                                    ?.additionalParkingSlots
                                }
                              />
                            </>
                          )}
                        </div>
                      ) : (
                        <div className='no-data-found'>No Parking Selected</div>
                      )}
                    </PlainAccordionComponent>
                  </>
                )}
              </div>
            )}

            {currentTab === 'gallery' && (
              <div className=' mrg-top-20'>
                <CardComponent className={'ts-row gallery-image-holder'}>
                  {amenityGallery.length === 0 && (
                    <StatusCardComponent
                      title={'No Gallery images added yet'}
                    />
                  )}
                  {amenityGallery &&
                    amenityGallery.map((value: any, index) => {
                      return (
                        <div
                          key={index}
                          className={'gallery-image'}
                          style={{ backgroundImage: 'url(' + value.url + ')' }}
                        ></div>
                      );
                    })}
                </CardComponent>
                {/*<PaginationComponent paginationOptions={[10, 25, 100]}*/}
                {/*                     totalResults={totalGalleryResultsRef.current}*/}
                {/*                     limit={galleryPageSizeRef.current}*/}
                {/*                     page={galleryPageNumRef.current}*/}
                {/*                     onPageChange={handleGalleryPageNumberChange}*/}
                {/*                     onRowsPerPageChange={handleGalleryPageSizeChange}*/}
                {/*                     id={"gallery_pagination"}*/}
                {/*/>*/}
                {amenityDetails?.mobilePic && (
                  <CardComponent className={'gallery-image-holder mrg-top-20'}>
                    {amenityDetails?.mobilePic && (
                      <div className={'display-flex flex-direction-column'}>
                        <div className={'unavailability-heading'}>
                          Mobile Representation Image
                        </div>
                        <br />
                        <div
                          className='upload-gallery-profile'
                          style={{
                            backgroundImage:
                              'url(' +
                              (amenityDetails?.mobilePic
                                ? amenityDetails?.mobilePic.url
                                : ImageConfig.PlaceHolderImage) +
                              ')',
                          }}
                        ></div>
                      </div>
                    )}
                  </CardComponent>
                )}
              </div>
            )}

            {currentTab === 'reviews' && (
              <div className='mrg-top-20'>
                <CardComponent>
                  <StatusCardComponent className='' title={'Coming Soon'} />
                  {/*<TableWrapperComponent*/}
                  {/*    url={APIConfig.GET_AMENITIES_REVIEWS_LIST.URL(amenityDetails?._id)}*/}
                  {/*    method={APIConfig.GET_AMENITIES_REVIEWS_LIST.METHOD}*/}
                  {/*    autoHeight={true}*/}
                  {/*    isPaginated={true}*/}
                  {/*    noDataText={'no data found'}*/}
                  {/*    columns={[]}/>*/}
                </CardComponent>
              </div>
            )}
            {currentTab === 'invoices' && (
              <>
                {isInvoiceListLoading && (
                  <div className='h-v-center'>
                    <LoaderComponent type={'spinner'} />
                  </div>
                )}
                {isInvoiceListLoadingFailed && (
                  <div className='h-v-center'>
                    <StatusCardComponent title={'Failed to Load'} />
                  </div>
                )}
                {isInvoiceListLoaded && (
                  <CardComponent className='mrg-top-20'>
                    <FormControlLabelComponent
                      label='Invoice List'
                      isBold={true}
                      level={4}
                      className='mrg-bottom-20'
                    />
                    <TableComponent
                      columns={InvoiceListTableColumn}
                      data={invoiceList}
                      noDataText='No Invoices in this amenity'
                    />
                  </CardComponent>
                )}
              </>
            )}
          </CardComponent>
        </div>
      )}
    </div>
  );
};

export default AmenityViewScreen;
