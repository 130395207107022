import "./ButtonGroupComponent.scss";
import {useCallback, useEffect, useState} from "react";
import BadgeComponent from "../badge/BadgeComponent";


export interface TabButtonType {
    title: string;
    id: string;
    disabled?: boolean;
    badge?: any;
    prefixIcon?: any;
    suffixIcon?: any;
}

interface ButtonGroupComponentProps {
    buttons: TabButtonType[];
    selected?: any;
    onChange?: (selected: string) => void;
    height?: number;
}

const ButtonGroupComponent = (props: ButtonGroupComponentProps) => {
    const {buttons, height, selected, onChange} = props;
    const [selectedButton, setSelectedButton] = useState<string>();

    useEffect(() => {
        if (selected) {
            setSelectedButton(selected);
        }
    }, [selected]);

    const toggleButton = useCallback((select: string) => {
        setSelectedButton(select);
        if (onChange) {
            onChange(select);
        }
    }, [onChange]);

    return (
        <div className={'button-group-component'} style={{height}}>
            {buttons.length &&
                buttons.map((button, index) => {
                    return (
                        <div style={{height}}
                             className={'button-groups-wrapper ' + (button?.disabled ? 'disabled' : '') + ' ' + (selectedButton === button.id ? 'active-button' : 'in-active-button')}
                             key={'ButtonGroupsComponent-Button-' + button?.id + index}
                             onClick={() => {
                                 if (!button.disabled) {
                                     toggleButton(button.id);
                                 }
                             }}
                        >
                            {/*<Icon width={iconSize} color={color} height={iconSize} />*/}
                            {button.prefixIcon &&
                                <div className="mrg-right-10">{button.prefixIcon}</div>
                            }
                            <div className={'button-text ' + (selectedButton === button.id
                                ? 'active-button-text'
                                : 'in-active-button-text')}>
                                {button.title}
                            </div>
                            {button.suffixIcon &&
                                <div className="mrg-left-10">{button.suffixIcon}</div>
                            }
                            {!!button.badge && (
                                <div className="mrg-left-10">
                                    <BadgeComponent color={button?.badge?.color}>
                                        {button?.badge?.text}
                                    </BadgeComponent>
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );

};

export default ButtonGroupComponent;
