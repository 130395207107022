import {Outlet} from "react-router-dom";

interface PollsComponentProps {

}

const PollsComponent = (props: PollsComponentProps) => {

    return (
        <Outlet/>
    );

};

export default PollsComponent;
