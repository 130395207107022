import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const AddPollAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_POLL.METHOD](APIConfig.ADD_POLL.URL, payload);
};
const editPollAPICall = (pollId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_POLL.METHOD](
    APIConfig.EDIT_POLL.URL(pollId),
    payload,
  );
};
const CancelPollAPICall = (pollId: any, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.CANCEL_POLL.METHOD](
    APIConfig.CANCEL_POLL.URL(pollId),
    payload,
  );
};

const GetPollDetailsAPICall = (pollId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_POLL_DETAILS.METHOD](
    APIConfig.GET_POLL_DETAILS.URL(pollId),
    payload,
  );
};

const GetPollListAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_POLL_LIST.METHOD](
    APIConfig.GET_POLL_LIST.URL,
    payload,
  );
};

const getPollStatsAPICall = (pollId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_POLL_STATS.METHOD](
    APIConfig.GET_POLL_STATS.URL(pollId),
    payload,
  );
};

const downloadPollListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.POLLS_LIST.METHOD](
    APIConfig.POLLS_LIST.URL + `?download=true`,
  );
};

const PollService = {
  AddPollAPICall,
  editPollAPICall,
  CancelPollAPICall,
  GetPollDetailsAPICall,
  getPollStatsAPICall,
  GetPollListAPICall,
  downloadPollListAsExcel,
};

export default PollService;
