import "./FormControlLabelComponent.scss";

interface FormControlLabelComponentProps {
    label: string;
    isBold?: boolean;
    level?: 1 | 2 | 3 | 4 | 5 | 6;
    required?: boolean;
    className?: string;
    id?: string;
}

const FormControlLabelComponent = (props: FormControlLabelComponentProps) => {

    const {label, className, required, id} = props;
    const level = props.level || 3;
    const isBold = props.isBold || false;

    return (
        <div
            className={`form-control-label-component level-${level} ${className} ${isBold ? 'font-weight-medium' : 'font-weight-normal'} ${required === false ? "ts-justify-content-between" : ""}`}>
            <div id={id} className="form-control-label">{label}</div>
            {required && <span className="form-control-required"> *</span>}
            {/*{!required && <div className="d-flex ts-justify-content-between">*/}
            {/*    <div className='label__optional'>Optional</div>*/}
            {/*</div>}*/}
        </div>
    );

};

export default FormControlLabelComponent;
