import "./DataLabelValueComponent.scss";
import React from "react";
import ToolTipComponent from "../tool-tip/ToolTipComponent";
import {ImageConfig} from "../../../constants";
import IconButtonComponent from "../icon-button/IconButtonComponent";

interface DataLabelValueComponentProps {
    label: string | React.ReactNode;
    direction?: "row" | "column";
    className?: any;
    childrenClassName?: any;
    id?: string;
    tooltip?: React.ReactNode;
}

const DataLabelValueComponent = (props: React.PropsWithChildren<DataLabelValueComponentProps>) => {

    const {label, children, className, id, childrenClassName, tooltip} = props;
    const direction = props.direction || "column";

    return (
        <div className={`data-label-value ${direction} ${className}`} data-testid="data-label-value">
            <div className={"data-label"}>
                <div className='table-tooltip-wrapper'>
                    <div>{label}</div>
                    {tooltip &&
                        <ToolTipComponent
                            position={'right'}
                            textColor={'#183258'}
                            backgroundColor={'#EBF1FD'}
                            tooltip={
                                <>
                                    {tooltip}
                                </>
                            }>
                            <div className={'d-flex'}>
                                <IconButtonComponent color='primary'>
                                    <ImageConfig.CircleInfo/>
                                </IconButtonComponent>
                            </div>
                        </ToolTipComponent>
                    }
                </div>
            </div>
            <div className={`data-value  ${childrenClassName}`} id={id} data-testid="data-value">
                {children}
            </div>
        </div>
    );

};

export default DataLabelValueComponent;

// ****************************** USAGE ****************************** //
// <DataLabelValueComponent label={"Facility Name"}>
//     Kinergy sports medicine and Performance
// </DataLabelValueComponent>

// const address = {
//     fNo: "101",
//     city: "Las Vegas",
//     state: "NV 89123 USA"
// }
//
// <DataLabelValueComponent label={"Address"}>
//     { address.fNo }, {address.city}, <br/> {address.state}
// </DataLabelValueComponent>

// ****************************** USAGE ****************************** //
