import './AddAmenityComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import AmenityDetailsComponent from './amenity-details/AmenityDetailsComponent';
import OperationDetailsComponent from './operator-details/OperationDetailsComponent';
import AmenityTimingDetailsComponent from './amenity-timings/AmenityTimingDetailsComponent';
import AmenityGalleryDetailsComponent from './amenity-gallery/AmenityGalleryDetailsComponent';
import AmenityCampusDetailsComponent from './amenity-campus-details/AmenityCampusDetailsComponent';
import AmenityBillingPreferenceComponent from './amenity-billing-preference/AmenityBillingPreferenceComponent';

interface AddAmenityComponentProps {}

const AMENITY_DETAILS = 'amenity-details';
const OPERATOR_DETAILS = 'operator-details';
const AMENITY_TIMINGS = 'amenity-timings';
const UPLOAD_GALLERY = 'upload-gallery';
const CAMPUS_DETAILS = 'campus-details';
const BILLING_PREFERENCES = 'billing-preferences';

const STEPS = [
  {
    title: 'Amenity Details',
    id: AMENITY_DETAILS,
  },
  {
    title: 'Operator Details',
    id: OPERATOR_DETAILS,
  },
  {
    title: 'Timings',
    id: AMENITY_TIMINGS,
  },
  {
    title: 'Upload Gallery',
    id: UPLOAD_GALLERY,
  },
  {
    title: 'Campus Details',
    id: CAMPUS_DETAILS,
  },
  {
    title: 'Billing Preferences',
    id: BILLING_PREFERENCES,
  },
];

const AddAmenityComponent = (props: AddAmenityComponentProps) => {
  const [steps] = useState<any[]>([..._.cloneDeep(STEPS)]);
  const navigate = useNavigate();
  // const [activeStepId, setActiveStepId] = useState<string>(STEPS[0].id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState<any | undefined>(STEPS[0]);
  const params = useParams();
  const [amenityId, setAmenityId] = useState<any>(params.amenityId);
  const [amenityDetails, setAmenityDetails] = useState<any>(undefined);
  const [isAmenityDetailsLoading, setIsAmenityDetailsLoading] =
    useState<boolean>(false);
  const location: any = useLocation();
  const path = location.pathname;

  const updateSteps = useCallback(
    (currentStepIndex: number) => {
      setCurrentStep(steps[currentStepIndex]);
      searchParams.set('step', steps[currentStepIndex].id);
      setSearchParams(searchParams);
      // setActiveStepId(steps[currentStepIndex].id);
    },
    [searchParams, setSearchParams, steps],
  );

  const getAmenityDetails = useCallback(() => {
    const payload = {};
    let amenityDetails: any = {};
    setIsAmenityDetailsLoading(true);
    CommonService._amenity
      .GetAmenityDetailsAPICall(amenityId, payload)
      .then((response: IAPIResponseType<any>) => {
        if (response?.data) {
          amenityDetails = response.data;
        }
        setAmenityDetails(amenityDetails);
        setIsAmenityDetailsLoading(false);
      })
      .catch((error: any) => {
        // setAmenityDetails(amenityDetails);
        setIsAmenityDetailsLoading(false);
      });
  }, [amenityId]);

  useEffect(() => {
    if (amenityId) {
      getAmenityDetails();
    }
  }, [getAmenityDetails, amenityId]);

  useEffect(() => {
    const step: any = searchParams.get('step');
    // const stepperIndex = steps.findIndex(stepItem => stepItem.id === step);

    // if (stepperIndex >= 0) {
    // setActiveStepId(steps[stepperIndex]?.id);
    const amenityId = searchParams.get('amenityId');
    if (amenityId) setAmenityId(amenityId);
    const stepIDS = steps.map((item) => item.id);
    let stepIndex: number = 0;
    if (stepIDS.indexOf(step) > -1) {
      if (step !== AMENITY_DETAILS) {
        stepIndex = stepIDS.indexOf(step);
      } else {
        stepIndex = 0;
      }
    } else {
      stepIndex = 0;
    }
    setCurrentStep(steps[stepIndex]);
    // }
  }, [searchParams, steps]);

  const handlePrevious = useCallback(
    (currentStepIndex: number, currentStepId: string) => {
      if (
        [
          OPERATOR_DETAILS,
          AMENITY_TIMINGS,
          UPLOAD_GALLERY,
          CAMPUS_DETAILS,
          BILLING_PREFERENCES,
        ].includes(currentStepId)
      ) {
        updateSteps(currentStepIndex);
      } else {
        // do nothing;
      }
    },
    [updateSteps],
  );

  const handleBack = useCallback(() => {
    if (path.includes('/amenity/add')) {
      navigate(CommonService._routeConfig.AmenityRoute());
    } else {
      navigate(CommonService._routeConfig.AmenityViewRoute(amenityId));
    }
  }, [navigate, amenityId, path]);

  const handleNext = useCallback(
    (currentStepIndex: number, currentStepId: string, data?: any) => {
      switch (currentStepId) {
        case AMENITY_DETAILS:
          if (data) {
            setAmenityId(data._id);
            searchParams.set('amenityId', data._id.toString());
            setSearchParams(searchParams);
            setAmenityDetails((oldSate: any) => {
              return {
                ...oldSate,
                type: data.type,
                propertyId: data.propertyId,
                name: data.name,
                description: data.description,
                websiteLink: data.websiteLink,
                capacity: data.capacity,
                vendorUrl: data.vendorUrl,
                isSezZone: data.isSezZone,
                igst: data.igst,
                placeOfService: data.placeOfService,
                billingAddress: data.billingAddress,
                isBillingAddressSameAsPlaceOfService:
                  data.isBillingAddressSameAsPlaceOfService,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;

        case OPERATOR_DETAILS:
          if (data) {
            setAmenityDetails((oldSate: any) => {
              return {
                ...oldSate,
                adminDetails: data.adminDetails,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case AMENITY_TIMINGS:
          if (data) {
            setAmenityDetails((oldSate: any) => {
              return {
                ...oldSate,
                isSameEveryDay: data?.isSameEveryDay,
                timings: data?.timings,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case UPLOAD_GALLERY:
          if (data) {
            getAmenityDetails();
            setAmenityDetails((oldState: any) => {
              return {
                ...oldState,
                profilePic: data.profilePic,
                mobileRepPic: data.mobileRepPic,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case CAMPUS_DETAILS:
          if (data) {
            getAmenityDetails();
            setAmenityDetails((oldState: any) => {
              return {
                ...oldState,
                towers: data.amenityOccupancy,
              };
            });
          }
          updateSteps(currentStepIndex);
          break;
        case BILLING_PREFERENCES:
          getAmenityDetails();
          handleBack();
          break;
        default:
          <></>;
      }
    },
    [updateSteps, handleBack, searchParams, setSearchParams, getAmenityDetails],
  );

  const renderComponent = useCallback(() => {
    switch (currentStep && currentStep.id) {
      case AMENITY_DETAILS:
        return (
          <AmenityDetailsComponent
            steps={steps}
            amenityId={amenityId}
            activeStepId={currentStep.id}
            amenityDetails={amenityDetails}
            next={(data: any) => handleNext(1, AMENITY_DETAILS, data)}
            prev={handleBack}
          />
        );
      case OPERATOR_DETAILS:
        return (
          <OperationDetailsComponent
            steps={steps}
            amenityId={amenityId}
            activeStepId={currentStep.id}
            amenityDetails={amenityDetails}
            next={(data: any) => handleNext(2, OPERATOR_DETAILS, data)}
            prev={() => handlePrevious(0, OPERATOR_DETAILS)}
          />
        );
      case AMENITY_TIMINGS:
        return (
          <AmenityTimingDetailsComponent
            steps={steps}
            activeStepId={currentStep.id}
            amenityId={amenityId}
            amenityDetails={amenityDetails}
            next={(data: any) => handleNext(3, AMENITY_TIMINGS, data)}
            prev={() => handlePrevious(1, AMENITY_TIMINGS)}
          />
        );
      case UPLOAD_GALLERY:
        return (
          <AmenityGalleryDetailsComponent
            steps={steps}
            amenityId={amenityId}
            activeStepId={currentStep.id}
            amenityDetails={amenityDetails}
            next={(data: any) => handleNext(4, UPLOAD_GALLERY, data)}
            prev={() => handlePrevious(2, UPLOAD_GALLERY)}
          />
        );
      case CAMPUS_DETAILS:
        return (
          <AmenityCampusDetailsComponent
            steps={steps}
            amenityId={amenityId}
            activeStepId={currentStep.id}
            amenityDetails={amenityDetails}
            next={(data: any) => handleNext(5, CAMPUS_DETAILS, data)}
            prev={() => handlePrevious(3, CAMPUS_DETAILS)}
            propertyId={amenityDetails?.propertyId}
          />
        );
      case BILLING_PREFERENCES:
        return (
          <AmenityBillingPreferenceComponent
            steps={steps}
            amenityId={amenityId}
            activeStepId={currentStep.id}
            amenityDetails={amenityDetails}
            next={() => handleBack()}
            prev={() => handlePrevious(4, UPLOAD_GALLERY)}
          />
        );
      default:
        <></>;
    }
  }, [
    currentStep,
    handleNext,
    handlePrevious,
    amenityDetails,
    amenityId,
    handleBack,
    steps,
  ]);

  return (
    <div className={'add-amenities-component add-screen'}>
      {isAmenityDetailsLoading ? (
        <div className='h-v-center'>
          <LoaderComponent type={'spinner'} />
        </div>
      ) : (
        <>{renderComponent()}</>
      )}
    </div>
  );
};

export default AddAmenityComponent;
