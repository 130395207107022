import "./TimePickerComponent.scss";
import TimePicker from 'rc-time-picker';
import {ITimePickerProps} from "../../../models/form-controls.model";
import React, {useCallback, useEffect, useState} from "react";
import LabelComponent from "../../label/LabelComponent";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import 'rc-time-picker/assets/index.css';
import {ImageConfig} from "../../../../constants";
import moment from "moment";
import {parseISO} from "date-fns";

interface TimePickerComponentProps extends ITimePickerProps {
    name?: string;
    value?: string;
    hasError?: boolean;
    errorMessage?: any;
}

const TimePickerComponent = (props: TimePickerComponentProps) => {

    const {
        className,
        hasError,
        required,
        fullWidth,
        disabled,
        label,
        onChange,
        placeholder,
        errorMessage,
        disabledHours,
        disabledMinutes,
        disabledSeconds,
    } = props;

    const format = props.format || "hh:mm a";
    const [value, setValue] = useState<any>(props.value ? moment(parseISO(props.value), format) : null);
    const showSeconds = props.showSeconds || false;
    const use12Hours = props.use12Hours || true;
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);

    const handleChange = useCallback((newValue: any) => {
        setValue(newValue);
        if (showSeconds) {
            if (newValue && newValue.hours() !== null && newValue.minutes() !== null && newValue.seconds() !== null) {
                if (onChange) {
                    onChange(newValue && newValue.format(format));
                }
            }
        } else {
            if (newValue && newValue.hours() !== null && newValue.minutes() !== null) {
                if (onChange) {
                    onChange(newValue && newValue.format(format));
                }
            }
        }
    }, [onChange, format, showSeconds]);

    const handleTimePickerOpen = useCallback(() => {
        setIsTimePickerOpen(true);
        document.documentElement.style.overflow = "hidden"; // Prevent scrolling
    }, []);

    const handleTimePickerClose = useCallback(() => {
        setIsTimePickerOpen(false);
        document.documentElement.style.overflow = "auto"; // Restore scrolling
    }, []);

    useEffect(() => {
        if (props.value && moment(props.value, format).isValid()) {
            setValue(moment(props.value, format));
        }
    }, [props.value, format]);

    const closeTimePicker = useCallback(() => {
        setIsTimePickerOpen(false);
    }, []);

    return (
        <div
            className={`time-picker-component ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''} ${hasError ? 'has-error' : ''}`}>
            {label && <LabelComponent title={label || ''} required={required}/>}
            <div className={"time-picker-container"}>
                <TimePicker
                    placeholder={placeholder}
                    className={`time-picker-field ${className}`}
                    onChange={handleChange}
                    showSecond={showSeconds}
                    open={isTimePickerOpen}
                    onOpen={handleTimePickerOpen}
                    onClose={handleTimePickerClose}
                    value={value}
                    disabled={disabled}
                    format={format}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    disabledSeconds={disabledSeconds}
                    use12Hours={use12Hours}
                    clearIcon={<span>
                    </span>}
                    clearText={""}
                    inputReadOnly
                    addon={() => <div className="time-picker-close" onClick={closeTimePicker}>
                        Close
                    </div>
                    }
                />
                {/*{!value &&*/}
                <ImageConfig.CalendarTimePicker className="time-picker-icon" onClick={handleTimePickerOpen}/>
                {/*}*/}
            </div>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );
};

export default TimePickerComponent;
