import {Outlet} from "react-router-dom";

interface EventsComponentProps {

}

const EventsComponent = (props: EventsComponentProps) => {

    return (
        <Outlet/>
    );

};

export default EventsComponent;
