import { call, put, takeEvery } from 'redux-saga/effects';
import { CommonService } from '../../shared/services';
import {
  GET_ALL_ENTITY_LIST,
  // GET_ENTITY_FM_LIST,
  GET_ENTITY_LIST_LITE,
  setAllEntityList,
  // setEntityFMList,
  setEntityListLite,
} from '../actions/entity.action';

function* getAllEntitiesList() {
  try {
    // @ts-ignore
    const resp = yield call(CommonService._entity.GetEntityListLiteAPICall, {
      isActive: true,
    });
    const entityList = resp?.data;
    yield put(setEntityListLite(entityList));
  } catch (error: any) {
    yield put(setEntityListLite([]));
  }
}

function* getEntitiesList() {
  try {
    // @ts-ignore
    const resp = yield call(CommonService._entity.GetEntityListLiteAPICall);
    const entityList = resp?.data;
    yield put(setAllEntityList(entityList));
  } catch (error: any) {
    yield put(setAllEntityList([]));
  }
}

// function* getEntityFMsList(action: any) {
//     try {
//         // @ts-ignore
//         const resp = yield call(CommonService._entity.GetEntityFMList, action?.payload?.entityId);
//         yield put(setEntityFMList(resp?.data));
//     } catch (error: any) {
//         yield put(setEntityFMList([]));
//     }
// }

export default function* entitySaga() {
  yield takeEvery(GET_ENTITY_LIST_LITE, getAllEntitiesList);
  yield takeEvery(GET_ALL_ENTITY_LIST, getEntitiesList);
  // yield takeEvery(GET_ENTITY_FM_LIST, getEntityFMsList);
}
