import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const AddVisitorAPICall = (payload: any)  => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_VISITOR.METHOD](APIConfig.ADD_VISITOR.URL, payload);
}

const EditVisitorAPICall = (visitorId: any, payload: any)  => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_VISITOR.METHOD](APIConfig.EDIT_VISITOR.URL(visitorId), payload);
}

const VMSService = {
    AddVisitorAPICall,
    EditVisitorAPICall,
}

export default VMSService;
