import './EventDetailsComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import * as Yup from 'yup';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikTextAreaComponent from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import FormikTimePickerComponent from '../../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import FormikDatePickerComponent from '../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikSelectDropdownComponent from '../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../../../store/reducers';
import moment from 'moment';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';

interface EventDetailsComponentProps {
  eventDetails?: any;
  eventId?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
}

const validationSchema = Yup.object({
  category: Yup.string().required('Event Category is required').nullable(),
  name: Yup.string()
    .min(3, 'Event name should be at least 3 characters')
    .max(30, 'Event name should not exceed 30 characters')
    .required('Event name is required'),
  description: Yup.string()
    .min(100, 'Event description should be at least 100 characters')
    .max(500, 'Event description should not exceed 500 characters')
    .required('Event description is required'),
  agenda: Yup.string()
    .min(20, 'Event agenda should be at least 20 characters')
    .max(300, 'Event agenda should not exceed 300 characters'),
  date: Yup.string().required('Event date is required').nullable(),
  time: Yup.string().required('Required'),
  duration: Yup.string().required('Event duration is Required'),
  propertyId: Yup.string().required('Event location is required').nullable(),
  allowedProperties: Yup.array()
    .min(1, 'At least one property is required')
    .nullable(),
});

interface formInitialValuesFormSchema {
  category: string;
  name: string;
  description: string;
  agenda: string;
  date: string;
  time: string;
  duration: string;
  propertyId: string;
  allowedProperties: any[];
}

const formInitialValues: formInitialValuesFormSchema = {
  category: '',
  name: '',
  description: '',
  agenda: '',
  date: '',
  time: '',
  duration: '',
  propertyId: '',
  allowedProperties: [],
};

const EventDetailsComponent = (props: EventDetailsComponentProps) => {
  const { eventDetails, prev, next, steps, activeStepId, eventId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  // const [filteredPropertyList, setFilteredPropertyList] = useState<any[]>(propertyList);

  // const getFilteredPropertyList = useCallback((propertyId: string) => {
  //     setFilteredPropertyList(propertyList.filter((property: any) => property._id !== propertyId));
  // }, []);

  useEffect(() => {
    if (eventDetails) {
      setInitialValues({
        category: eventDetails?.category,
        name: eventDetails?.name,
        description: eventDetails?.description,
        agenda: eventDetails?.agenda,
        date: eventDetails?.date,
        time: eventDetails?.time,
        duration: eventDetails?.duration,
        propertyId: eventDetails?.propertyId,
        allowedProperties: eventDetails?.allowedProperties || [],
      });
    }
  }, [eventDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      values.date = moment(values.date).format('YYYY-MM-DD');
      const payload = {
        ...values,
      };
      delete values.eventId;
      if (eventId) {
        CommonService._events
          .editEventDetailsAPICall(eventId, payload)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        CommonService._events
          .AddEventAPICall(payload)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next(response.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [next, eventId],
  );

  return (
    <div className={'event-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Exit
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <div className=''>
                  <Field name={'category'}>
                    {(field: FieldProps) => (
                      // <FormikInputComponent
                      //     label={'Event Category'}
                      //     placeholder={'Example: entertainment'}
                      //     type={"text"}
                      //     required={true}
                      //     formikField={field}
                      //     fullWidth={true}
                      // />
                      <FormikSelectDropdownComponent
                        options={CommonService._staticData.EventCategoryList}
                        label={'Event Category'}
                        // placeholder={'Select'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>

                  <Field name={'name'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Event Name'}
                        placeholder={'Example: holy event'}
                        type={'text'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>

                  <Field name={'description'}>
                    {(field: FieldProps) => (
                      <FormikTextAreaComponent
                        label={'Event Description'}
                        placeholder={'Write the event description here'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>

                  <Field name={'agenda'}>
                    {(field: FieldProps) => (
                      <FormikTextAreaComponent
                        label={'Event Agenda (Optional)'}
                        placeholder={'Ex :  12:24 pm - event starts'}
                        required={false}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <div className='ts-row'>
                    <div className='ts-col-6'>
                      <Field name={'date'}>
                        {(field: FieldProps) => (
                          <FormikDatePickerComponent
                            label={'Event Date'}
                            minDate={new Date()}
                            placeholder={'Select'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='ts-col-6'>
                      <Field name={'time'}>
                        {(field: FieldProps) => (
                          <FormikTimePickerComponent
                            label={'Event Time'}
                            placeholder={'Select'}
                            use12Hours={true}
                            format={'HH:mm'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <Field name={'duration'}>
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        label={'Event Duration (min)'}
                        placeholder={'Ex : 60'}
                        type={'text'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <FormControlLabelComponent
                    label={'Location Details'}
                    level={4}
                    isBold={true}
                  />
                  <Field name={'propertyId'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        options={propertyList}
                        displayWith={(option: any) => option.name}
                        valueExtractor={(option: any) => option._id}
                        label={'Select Property'}
                        placeholder={'Select Property'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                        onUpdate={(value: any) => {
                          setFieldValue('allowedProperties', [value]);
                          // getFilteredPropertyList(value);
                        }}
                      />
                    )}
                  </Field>
                  <FormControlLabelComponent
                    label={'Additional Event Attendees'}
                    level={4}
                    isBold={true}
                  />

                  <Field name={'allowedProperties'}>
                    {(field: FieldProps) => (
                      <FormikSelectDropdownComponent
                        options={propertyList}
                        displayWith={(option: any) => option.name}
                        valueExtractor={(option: any) => option._id}
                        label={'Select Property(s)'}
                        placeholder={'Select Property(s)'}
                        required={true}
                        multiple={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventDetailsComponent;
