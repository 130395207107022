import React, { useCallback, useEffect, useState } from 'react';
import './RegularInvoiceComponent.scss';
import { ImageConfig, Misc } from '../../../../constants';
import HorizontalLineComponent from '../../horizontal-line/horizontal-line/HorizontalLineComponent';
import { Field, FieldProps } from 'formik';
import FormikSelectDropdownComponent from '../../form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { CommonService } from '../../../services';
import FormikInputComponent from '../../form-controls/formik-input/FormikInputComponent';
import { IAPIResponseType } from '../../../models/api.model';

interface RegularInvoiceComponentProps {
  invoiceDetails: any;
  addressDetails: any;
  category: any;
  values: any;
  setFieldValue: any;
}

//igst select dropdown options
const igstOptions = [
  {
    code: 0,
    title: '0%',
  },
  {
    code: 18,
    title: '18%',
  },
];

const RegularInvoiceComponent = (props: RegularInvoiceComponentProps) => {
  const { invoiceDetails, addressDetails, values, setFieldValue } = props;
  const [invoiceNames, setInvoiceNames] = useState<any[] | undefined>(
    undefined,
  );
  const getInvoiceNames = useCallback(() => {
    const type = invoiceDetails?.billingTo;
    let apiCall: any;
    if (type === 'tenant') {
      apiCall = CommonService._tenants.getTenantBillingComponentsAPICall(
        invoiceDetails?.tenantId,
      );
    }
    if (type === 'amenity') {
      apiCall = CommonService._amenity.getAmenityBillingComponentsAPICall(
        invoiceDetails?.amenityId,
      );
    }

    if (invoiceDetails) {
      apiCall
        .then((response: IAPIResponseType<any>) => {
          setInvoiceNames(response?.data?.billingComponentsAmounts);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    getInvoiceNames();
  }, [getInvoiceNames]);

  // Function for calcuating sub total
  const calculateSubTotalAmount = useCallback(
    (unitPrice: number, quantity: number) => {
      const subTotal = unitPrice * quantity;
      return subTotal.toFixed(2);
    },
    [],
  );

  // Function for calculating Total Amount
  const calculateTotalAmount = useCallback(
    (
      sgst: number,
      igst: number,
      cgst: number,
      unitPrice: number,
      quantity: number,
    ) => {
      const subTotal = unitPrice * quantity;
      const totalAmount =
        subTotal +
        (subTotal * sgst) / 100 +
        (subTotal * igst) / 100 +
        (subTotal * cgst) / 100;
      return totalAmount.toFixed(2);
    },

    [],
  );

  return (
    <div className='regular-invoice-component'>
      <div className='new-invoice-details-top-section'>
        <div className='new-invoice-details-image'>
          <img src={ImageConfig.NewPhoenixLogo} alt='phoenix-logo' />
        </div>
        <div>
          <div className='new-invoice-top-text'>
            {invoiceDetails?.billingEntity?.gstin ? (
              <>{invoiceDetails?.billingEntity?.gstin}</>
            ) : (
              <>--</>
            )}
          </div>
          <div className='new-invoice-top-text'>
            {invoiceDetails?.billingEntity?.name ? (
              <>{invoiceDetails?.billingEntity?.name}</>
            ) : (
              <>--</>
            )}
          </div>
        </div>
      </div>
      <HorizontalLineComponent />
      <div className='new-invoice-details-middle-section'>
        {invoiceDetails?.billingEntity?.addressDetails && (
          <div className='new-invoice-details-billing-wrapper'>
            <span className='new-invoice-details-heading'>Billed By</span>
            <div className='new-invoice-details-billing-info'>
              <p>
                {invoiceDetails?.billingEntity?.addressDetails?.addressLine1}
              </p>
              <p> {invoiceDetails?.billingEntity?.addressDetails?.city}</p>
              <p>
                {invoiceDetails?.billingEntity?.addressDetails?.state} ,{' '}
                {invoiceDetails?.billingEntity?.addressDetails?.pincode}
              </p>
              <p>{invoiceDetails?.billingEntity?.addressDetails?.country}</p>
            </div>
          </div>
        )}
        {addressDetails?.billingAddress && (
          <div className='new-invoice-details-billing-wrapper'>
            <span className='new-invoice-details-heading'>Billed To</span>
            <div className='new-invoice-details-billing-info'>
              <p>{addressDetails?.billingAddress?.addressLine1}</p>
              <p>{addressDetails?.billingAddress?.city}</p>
              <p>
                {addressDetails?.billingAddress?.state} ,{' '}
                {addressDetails?.billingAddress?.pincode}
              </p>
              <p>{addressDetails?.billingAddress?.country}</p>
            </div>
          </div>
        )}
      </div>
      <HorizontalLineComponent />
      <div className='ts-row mrg-top-15'>
        <div className='ts-col-6'>
          <Field name={'supplyType'}>
            {(field: FieldProps) => (
              <FormikSelectDropdownComponent
                label='Supply Type Code'
                placeholder='Select'
                required={true}
                formikField={field}
                fullWidth={true}
                options={CommonService._staticData.InvoiceSupplyType}
                displayWith={(option) => option}
                valueExtractor={(option) => option}
              />
            )}
          </Field>
        </div>
        <div className='ts-col-6'>
          <Field name={'HSNCode'}>
            {(field: FieldProps) => (
              <FormikInputComponent
                className='new-invoice-input-field'
                label='HSN Code'
                placeholder='Ex : 997212'
                required
                formikField={field}
                fullWidth
                validationPattern={new RegExp(/^[1-9][0-9]{0,5}$/)}
              />
            )}
          </Field>
        </div>
      </div>
      <div className='ts-row'>
        <div className={values?.unit?.length === 0 ? 'ts-col-12' : 'ts-col-6'}>
          <Field name={'unitDesc'}>
            {(field: FieldProps) => (
              <FormikSelectDropdownComponent
                label='Unit Description'
                formikField={field}
                options={CommonService._staticData.InvoiceUnit}
                displayWith={(option) => option.des}
                valueExtractor={(option) => option.code}
                fullWidth
                required
                searchable
                searchMode='clientSide'
                onUpdate={(value: any) => {
                  if (value) {
                    const selectedUnitDes =
                      CommonService._staticData.InvoiceUnit.find(
                        (item) => item.code === value,
                      );
                    if (selectedUnitDes) {
                      setFieldValue('unit', selectedUnitDes.unit);
                    }
                  } else {
                    setFieldValue('unit', '');
                  }
                }}
              />
            )}
          </Field>
        </div>
        {values?.unit?.length !== 0 && (
          <div className='ts-col-6'>
            <Field name={'unit'} className='new-invoice-details-item-dropdown'>
              {(field: FieldProps) => (
                <FormikInputComponent
                  label='Unit'
                  placeholder='--'
                  formikField={field}
                  fullWidth
                  readOnly
                />
              )}
            </Field>
          </div>
        )}
        <div className='new-invoice-details-table-wrapper'>
          <div className='new-invoice-details-table-header'>
            <span>Item</span>
            <span>Unit Price</span>
            <span>Quantity</span>
            <span>Amount</span>
          </div>
          <div className='new-invoice-details-table-body'>
            <div className='new-invoice-details-table-dropdown ts-row'>
              <div
                className={`${
                  values.invoiceName === 'Others' ? 'ts-col-6' : 'ts-col-12'
                } `}
              >
                <Field name={'invoiceName'}>
                  {(field: FieldProps) => (
                    <FormikSelectDropdownComponent
                      placeholder='Select'
                      formikField={field}
                      fullWidth
                      required={true}
                      options={invoiceNames}
                      displayWith={(option: any) => option.component}
                      valueExtractor={(option: any) => option.component}
                      onUpdate={(value: any) => {
                        const selectedInvoice = invoiceNames?.find(
                          (invoice) => invoice.component === value,
                        );
                        if (selectedInvoice) {
                          setFieldValue(
                            'invoiceName',
                            selectedInvoice.component,
                          );
                          setFieldValue('unitPrice', selectedInvoice.unitPrice);
                          setFieldValue('quantity', selectedInvoice.quantity);
                        }
                      }}
                    />
                  )}
                </Field>
              </div>

              {values.invoiceName === 'Others' && (
                <div className='ts-col-6'>
                  <Field
                    name={'newInvoiceName'}
                    className='new-invoice-details-item-dropdown'
                  >
                    {(field: FieldProps) => (
                      <FormikInputComponent
                        placeholder='Enter name of the item'
                        formikField={field}
                        fullWidth
                        type='text'
                        required={true}
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
            <div>
              <Field name={'unitPrice'}>
                {(field: FieldProps) => (
                  <FormikInputComponent
                    id='invoice-input'
                    className='new-invoice-input-field'
                    placeholder='Ex : 1000'
                    formikField={field}
                    fullWidth
                    required
                    validationPattern={new RegExp(/^[0-9]*$/)}
                    onChange={(value: any) => {
                      if (value) {
                        setFieldValue('unitPrice', parseInt(value));
                      } else {
                        setFieldValue('unitPrice', 0);
                      }
                    }}
                  />
                )}
              </Field>
            </div>
            <div>
              <Field name={'quantity'}>
                {(field: FieldProps) => (
                  <FormikInputComponent
                    id='invoice-input1'
                    className='new-invoice-input-field'
                    placeholder='Ex : 400 sq ft'
                    formikField={field}
                    fullWidth
                    validationPattern={new RegExp(/^[0-9]*$/)}
                    required
                    onChange={(value: any) => {
                      if (value) {
                        setFieldValue('quantity', parseInt(value));
                      } else {
                        setFieldValue('quantity', 0);
                      }
                    }}
                  />
                )}
              </Field>
            </div>

            <div>
              <Field
                name={'subTotal'}
                className='new-invoice-details-item-dropdown'
              >
                {(field: FieldProps) => (
                  <FormikInputComponent
                    placeholder='--'
                    formikField={field}
                    fullWidth
                    readOnly
                    disabled
                    value={
                      values.quantity && values.unitPrice
                        ? (values.unitPrice * values.quantity).toFixed(2)
                        : ''
                    }
                  />
                )}
              </Field>
            </div>
          </div>
        </div>
        <div className='new-invoice-details-bottom-section'>
          <div className='new-invoice-details-info-header'>
            <span>Item</span>
            <span>Total Amount</span>
          </div>
          <div className='new-invoice-details-info-row'>
            <span>SGST %</span>
            <div className='new-invoice-details-input-wrapper'>
              <Field name={'sgst'}>
                {(field: FieldProps) => (
                  <FormikInputComponent
                    placeholder='0%'
                    required
                    formikField={field}
                    fullWidth
                    validationPattern={new RegExp(/^[0-9]*$/)}
                    onChange={(value: any) => {
                      if (value) {
                        setFieldValue('sgst', parseInt(value));
                      } else {
                        setFieldValue('sgst', 0);
                      }
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='new-invoice-details-info-row'>
            <span>iGST %</span>
            <div className='new-invoice-details-input-wrapper'>
              <Field name={'igst'}>
                {(field: FieldProps) => (
                  <FormikSelectDropdownComponent
                    options={igstOptions}
                    placeholder={'Ex : 0%'}
                    value={values.igst}
                    required={true}
                    formikField={field}
                    fullWidth={true}
                    isClearable={false}
                    valueExtractor={(option: any) => option.code}
                    displayWith={(option: any) => option.title}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='new-invoice-details-info-row'>
            <span>CGST %</span>
            <div className='new-invoice-details-input-wrapper'>
              <Field name={'cgst'}>
                {(field: FieldProps) => (
                  <FormikInputComponent
                    placeholder='14%'
                    required
                    formikField={field}
                    fullWidth
                    validationPattern={new RegExp(/^[0-9]*$/)}
                    onChange={(value: any) => {
                      if (value) {
                        setFieldValue('cgst', parseInt(value));
                      } else {
                        setFieldValue('cgst', 0);
                      }
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='new-invoice-details-info-row-one'>
            <span>Sub Total</span>
            <span className='new-invoice-details-subtotal'>
              {values.unitPrice && values.quantity ? (
                <span>
                  {calculateSubTotalAmount(values.unitPrice, values.quantity)}{' '}
                  Rs
                </span>
              ) : (
                <>--</>
              )}
            </span>
          </div>
          <div className='new-invoice-details-info-row-one'>
            <span>Total Amount to be paid</span>
            {values.unitPrice && values.quantity ? (
              <span className='new-invoice-details-subtotal'>
                {calculateTotalAmount(
                  values.sgst,
                  values.igst,
                  values.cgst,
                  values.unitPrice,
                  values.quantity,
                )}{' '}
                Rs
              </span>
            ) : (
              <span className='new-invoice-details-subtotal'>--</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegularInvoiceComponent;
