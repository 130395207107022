import React, { useCallback } from 'react';
import ReactECharts from 'echarts-for-react';
import './InvoiceSummaryChartComponent.scss';

interface InvoiceSummaryChartComponentProps {
  data: any;
}

const InvoiceSummaryChartComponent = (
  props: InvoiceSummaryChartComponentProps,
) => {
  const { data } = props;

  const getOption = useCallback(() => {
    const totalSum = data.reduce((a: any, b: any) => a + b.value, 0);
    return {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          const { value, seriesName } = params;
          return `
            <div>
              <div style="font-weight:700 ; color: #a0aec0; width:100px">${seriesName}</div>
              <div style="font-weight: 700;
              font-size: 20px;
              color: #2d3748;">${value}</div>
            </div>
          `;
        },
        borderColor: '#fff',
      },
      grid: {
        left: '-3%',
        right: '0%',
        bottom: '-2%',
        top: '0%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        show: false,
        max: totalSum,
      },
      yAxis: {
        type: 'category',
        show: false,
        data: ['Total'],
      },
      series: data?.map((item: any, index: any) => {
        // let borderRadius = [0, 0, 0, 0];
        // if (index === 0) {
        //   borderRadius = [10, 0, 0, 10];
        // }
        // if (index === data?.length - 1) {
        //   borderRadius = [0, 10, 10, 0];
        // }
        return {
          name: item?.name,
          type: 'bar',
          stack: 'total',
          itemStyle: {
            color: item?.color,
          },
          label: {
            show: false,
          },
          emphasis: {
            focus: 'none',
            itemStyle: {
              color: item.color,
            },
          },
          data: [item.value],
        };
      }),
    };
  }, [data]);

  return (
    <div className='invoice-summary-chart-component'>
      <ReactECharts
        option={getOption()}
        style={{ height: '70px', width: '100%' }}
      />
    </div>
  );
};

export default InvoiceSummaryChartComponent;
