import { IActionModel } from '../../shared/models/action.model';
import {
  GET_ALL_AMENITY_LIST,
  GET_AMENITY_LIST_LITE,
  SET_ALL_AMENITY_LIST,
  SET_AMENITY_LIST_LITE,
} from '../actions/amenity.action';

export interface IAmenityReducerState {
  amenityList: any[];
  isAmenityListLoading: boolean;
  isAmenityListLoaded: boolean;
  allAmenityList: any[];
}

const initialData: IAmenityReducerState = {
  amenityList: [],
  isAmenityListLoading: false,
  isAmenityListLoaded: false,
  allAmenityList: [],
};

const AmenityReducer = (
  state = initialData,
  action: IActionModel,
): IAmenityReducerState => {
  switch (action.type) {
    case GET_AMENITY_LIST_LITE:
      state = {
        ...state,
        isAmenityListLoading: true,
        isAmenityListLoaded: false,
      };
      return state;
    case SET_AMENITY_LIST_LITE:
      state = {
        ...state,
        isAmenityListLoading: false,
        isAmenityListLoaded: true,
        amenityList: action.payload.amenityList,
      };
      return state;
    case GET_ALL_AMENITY_LIST:
      state = {
        ...state,
      };
      return state;
    case SET_ALL_AMENITY_LIST:
      state = {
        ...state,
        allAmenityList: action.payload.allAmenityList,
      };
      return state;
    default:
      return state;
  }
};

export default AmenityReducer;
