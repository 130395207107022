import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const GetPropertyListLiteAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.PROPERTY_LIST_LITE.METHOD](
    APIConfig.PROPERTY_LIST_LITE.URL,
    payload,
  );
};

const PropertyDetailsAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.PROPERTY_DETAILS.METHOD](
    APIConfig.PROPERTY_DETAILS.URL(propertyId),
    payload,
  );
};

const TogglePropertyServiceAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_PROPERTY.METHOD](
    APIConfig.TOGGLE_PROPERTY.URL(propertyId),
    payload,
  );
};

const AddPropertyAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_PROPERTY.METHOD](
    APIConfig.ADD_PROPERTY.URL,
    payload,
  );
};

const AddPropertyCampusAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_CAMPUS_DETAILS.METHOD](
    APIConfig.ADD_CAMPUS_DETAILS.URL(propertyId),
    payload,
  );
};

const AddPropertyTowerAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_PROPERTY_TOWER_DETAILS.METHOD](
    APIConfig.ADD_PROPERTY_TOWER_DETAILS.URL(propertyId),
    payload,
  );
};

const EditPropertyAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_PROPERTY.METHOD](
    APIConfig.EDIT_PROPERTY.URL(propertyId),
    payload,
  );
};

const AddPropertyImageAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_PROPERTY_IMAGE.METHOD](
    APIConfig.ADD_PROPERTY_IMAGE.URL(propertyId),
    payload,
  );
};

const GetPropertyFMList = (propertyId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.PROPERTY_FM_LIST.METHOD](
    APIConfig.PROPERTY_FM_LIST.URL(propertyId),
  );
};

const removeTowerDetailsAPICall = (propertyId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.DELETE_TOWER_DETAILS.METHOD](
    APIConfig.DELETE_TOWER_DETAILS.URL(propertyId),
    payload,
  );
};

const getTowersListAPICall = (propertyId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_TOWERS_LIST.METHOD](
    APIConfig.GET_TOWERS_LIST.URL(propertyId),
  );
};

const AddTowerAPICall = (propertyId: string, payload: any) => {
  return ApiService[APIConfig.ADD_PROPERTY_TOWER.METHOD](
    APIConfig.ADD_PROPERTY_TOWER.URL(propertyId),
    payload,
  );
};
const EditTowerAPICall = (propertyId: string, payload: any) => {
  return ApiService[APIConfig.EDIT_PROPERTY_TOWER.METHOD](
    APIConfig.EDIT_PROPERTY_TOWER.URL(propertyId),
    payload,
  );
};
const viewTowerAPICall = (propertyId: string, towerId: string) => {
  return ApiService[APIConfig.VIEW_PROPERTY_TOWER.METHOD](
    APIConfig.VIEW_PROPERTY_TOWER.URL(propertyId, towerId),
  );
};

const getPropertyListAPICall = () => {
  return ApiService[APIConfig.PROPERTY_LIST.METHOD](
    APIConfig.PROPERTY_LIST.URL,
  );
};

const downloadPropertyListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.PROPERTY_LIST.METHOD](
    APIConfig.PROPERTY_LIST.URL + `?download=true`,
  );
};
const PropertyService = {
  GetPropertyListLiteAPICall,
  PropertyDetailsAPICall,
  TogglePropertyServiceAPICall,
  AddPropertyAPICall,
  AddPropertyCampusAPICall,
  AddPropertyTowerAPICall,
  AddPropertyImageAPICall,
  EditPropertyAPICall,
  GetPropertyFMList,
  removeTowerDetailsAPICall,
  getTowersListAPICall,
  AddTowerAPICall,
  EditTowerAPICall,
  viewTowerAPICall,
  getPropertyListAPICall,
  downloadPropertyListAsExcel,
};

export default PropertyService;
