import './TenantsListComponent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { ITableColumn } from '../../../shared/models/table.model';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import { IRootReducerState } from '../../../store/reducers';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface EventsListComponentProps {}

const TenantsListComponent = (props: EventsListComponentProps) => {
  const [count, setCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({
    search: '',
    // sort:{},
  });
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const TenantsListColumn: ITableColumn[] = [
    {
      title: 'Tenant ID',
      key: 'tenantId',
      dataIndex: 'name',
      // fixed: 'left',
      width: 150,
      render: (item: any) => {
        return (
          <div>
            {item?.tenantCode ? <div>{item?.tenantCode}</div> : <div>-</div>}
          </div>
        );
      },
    },
    {
      title: 'Tenant company',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (item: any) => {
        return (
          <div className='text-transform-capitalize'>
            {item?.name ? <div>{item?.name}</div> : <div>-</div>}
          </div>
        );
      },
    },
    {
      title: 'Property Name',
      key: 'propertyName',
      dataIndex: 'name',
      width: 200,
      render: (item: any) => {
        return (
          <div>
            {item?.propertyDetails ? (
              <div className='text-transform-capitalize'>
                {item?.propertyDetails?.name}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Tenant Admin',
      key: 'admin',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 200,
      render: (item: any) => {
        return (
          <div>
            {(item?.adminDetails || []).length > 0 ? (
              <>
                {item?.adminDetails?.length === 1 ? (
                  <div className='text-transform-capitalize'>
                    {item?.adminDetails[0]?.name}
                  </div>
                ) : (
                  <ToolTipComponent
                    // backgroundColor={'#fff'}
                    position={'right'}
                    textColor={'#FFFFFF'}
                    tooltip={
                      <>
                        {item?.adminDetails.map(
                          (value1: any, index1: number) => {
                            return (
                              <div
                                className={
                                  'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                }
                                key={index1}
                              >
                                {value1.name}
                              </div>
                            );
                          },
                        )}
                      </>
                    }
                  >
                    <div className={'d-flex'}>
                      <ChipComponent
                        className={'scheduled cursor-pointer'}
                        size={'small'}
                        color={'primary'}
                        label={
                          item?.adminDetails[0].name +
                          ((item?.adminDetails || []).length > 1
                            ? ' (+' +
                              ((item?.adminDetails || []).length - 1) +
                              ')'
                            : '')
                        }
                      />
                    </div>
                  </ToolTipComponent>
                )}
              </>
            ) : (
              'N/A'
            )}
          </div>
        );
      },
    },
    {
      title: 'Tenant Admin Phone No.',
      key: 'phone',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 180,
      render: (item: any) => {
        return (
          <div>
            {/*{item?.phone ? <div>{item?.phone}</div> : <div>-</div>}*/}
            {(item?.adminDetails || []).length > 0 ? (
              <>
                {item?.adminDetails?.length === 1 ? (
                  <>
                    {CommonService.convertPhoneNumber(
                      item?.adminDetails[0]?.phoneNumber,
                    )}
                  </>
                ) : (
                  <ToolTipComponent
                    // backgroundColor={'#fff'}
                    position={'right'}
                    textColor={'#FFFFFF'}
                    tooltip={
                      <>
                        {item?.adminDetails.map(
                          (value1: any, index1: number) => {
                            return (
                              <div
                                className={
                                  'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                }
                                key={index1}
                              >
                                {CommonService.convertPhoneNumber(
                                  value1.phoneNumber,
                                )}
                              </div>
                            );
                          },
                        )}
                      </>
                    }
                  >
                    <div className={'d-flex'}>
                      <ChipComponent
                        className={'scheduled cursor-pointer'}
                        size={'small'}
                        color={'primary'}
                        label={
                          CommonService.convertPhoneNumber(
                            item?.adminDetails[0].phoneNumber,
                          ) +
                          ((item?.adminDetails || []).length > 1
                            ? ' (+' +
                              ((item?.adminDetails || []).length - 1) +
                              ')'
                            : '')
                        }
                      />
                    </div>
                  </ToolTipComponent>
                )}
              </>
            ) : (
              'N/A'
            )}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      width: 180,
      render: (item: any) => {
        return (
          <>
            <TagComponent
              color={CommonService.getTenantStatusTagColor(item?.isActive)}
              showIndicator={true}
              label={item?.isActive === true ? 'Active' : 'Inactive'}
            />
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: 80,
      fixed: 'right',
      render: (item: any) => {
        if (item?._id) {
          return (
            <LinkComponent
              route={CommonService._routeConfig.TenantDetails(item?._id)}
            >
              <ImageConfig.ArrowRightOutlinedIcon />
            </LinkComponent>
          );
        }
      },
    },
  ];

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._tenants
        .downloadTenantListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'tenant_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Tenant List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'events-list-component list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Tenant</div>
            <div className='list-screen-data-count'>
              <TagComponent color={'primary'} label={JSON.stringify(count)} />
            </div>
          </div>
        </div>
        <div className='list-options'>
          <SearchComponent
            placeholder='Search Tenant company'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          <div className={'user-list-dropdown'}>
            <SelectDropdownComponent
              placeholder={'Property'}
              options={propertyList}
              isClearable={true}
              displayWith={(option) => option.name}
              valueExtractor={(option) => option._id}
              multiple={true}
              value={filterData.propertyIds}
              onUpdate={(value: any) => {
                setFilterData({
                  ...filterData,
                  propertyIds: value,
                  page: 1,
                });
              }}
            />
          </div>
          <LinkComponent route={CommonService._routeConfig.AddTenantsRoute()}>
            <ButtonComponent
              color={'primary'}
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
            >
              Add New Tenant
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.TENANT_LIST.URL}
          method={APIConfig.TENANT_LIST.METHOD}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          noDataText={
            filterData?.search
              ? 'No such tenant exist'
              : filterData?.propertyIds
              ? 'No tenants added to this property(s)'
              : 'No Tenants have been added yet'
          }
          columns={TenantsListColumn}
        />
      </div>
    </div>
  );
};

export default TenantsListComponent;
