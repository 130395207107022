import React, { useCallback, useEffect, useState } from 'react';
import './PropertyStatsComponent.scss';
import ModuleCardComponent from '../../../shared/components/dashboard/module-card/ModuleCardComponent';
import { ImageConfig } from '../../../constants';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { CommonService } from '../../../shared/services';
import SkeletonModuleCardComponent from '../../../shared/components/dashboard/module-card/skeleton-module-card/SkeletonModuleCardComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import DonutChartComponent from '../../../shared/components/charts/donut/DonutChartComponent';

interface PropertyStatsComponentProps {
  entityList: any;
  propertyList: any;
}

const PropertyStatsComponent = (props: PropertyStatsComponentProps) => {
  const { entityList, propertyList } = props;
  const [dashboardStats, setDashboardStats] = useState<any>(undefined);
  const [dashboardStatsLoading, setDashboardStatsLoading] =
    useState<boolean>(false);
  const [occupanyStatsLoading, setOccupanyStatsLoading] =
    useState<boolean>(false);
  const [occupanyData, setOccupanyData] = useState<any[]>([]);
  const [towerStatsLoading, setTowerStatsLoading] = useState<boolean>(false);
  const [towerStatsData, setTowerStatsData] = useState<any[]>([]);
  const [tenantStatsLoading, setTenantStatsLoading] = useState<boolean>(false);
  const [tenantStatsData, setTenantStatsData] = useState<any[]>([]);
  const [occupancyEntityId, setOccupancyEntityId] = useState<string>('');
  const [occupancyPropertyId, setOccupancyPropertyId] = useState<string>('');
  const [occupancyTowerId, setOccupancyTowerId] = useState<string>('');
  const [occupancyTowerData, setOccupancyTowerData] = useState<any[]>([]);
  const [occupancyPropertyList, setOccupancyPropertyList] = useState<any[]>([]);
  const [towerEntityId, setTowerEntityId] = useState<string>('');
  const [towerPropertyId, setTowerPropertyId] = useState<string>('');
  const [towerStatsTowerId, setTowerStatsTowerId] = useState<string>('');
  const [towerPropertyList, setTowerPropertyList] = useState<any[]>([]);
  const [towerStatsTowerData, setTowerStatsTowerData] = useState<any[]>([]);
  const [tenantEntityId, setTenantEntityId] = useState<string>('');
  const [tenantPropertyId, setTenantPropertyId] = useState<string>('');
  const [tenantTowerId, setTenantTowerId] = useState<string>('');
  const [tenantPropertyList, setTenantPropertyList] = useState<any[]>([]);
  const [tenantTowerData, setTenantTowerData] = useState<any[]>([]);

  const getDashboardStatistics = useCallback(() => {
    setDashboardStatsLoading(true);
    CommonService._dashboard
      .GetDashboardStatistics()
      .then((response: IAPIResponseType<any>) => {
        const data = response?.data;

        const stats = [
          {
            icon: <ImageConfig.EntityDashboard />,
            title: 'Entities',
            count: data?.entities,
          },
          {
            icon: <ImageConfig.PropertyDashboard />,
            title: 'Properties',
            count: data?.properties,
          },
          {
            icon: <ImageConfig.TenantDashboard />,
            title: 'Tenants',
            count: data?.tenants,
          },
          {
            icon: <ImageConfig.AmenityDashboard />,
            title: 'Amenities',
            count: data?.amenities,
          },
          {
            icon: <ImageConfig.EventDashboard />,
            title: 'Events',
            count: data?.events,
          },
          {
            icon: <ImageConfig.PollDashboard />,
            title: 'Polls',
            count: data?.polls,
          },
        ];
        setDashboardStats(stats);
        setDashboardStatsLoading(false);
      })
      .catch((error: any) => {
        CommonService._alert.showToast(error.message, 'error');
        setDashboardStatsLoading(false);
      })
      .finally(() => {
        setDashboardStatsLoading(false);
      });
  }, [setDashboardStats]);

  useEffect(() => {
    getDashboardStatistics();
  }, [getDashboardStatistics]);

  const getOccupanyStats = useCallback(() => {
    setOccupanyStatsLoading(true);
    let payload: any = {
      entityId: occupancyEntityId,
      propertyId: occupancyPropertyId,
      towerId: occupancyTowerId,
    };
    CommonService._dashboard
      .OccupanyStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setOccupanyData(response?.data);
      })
      .catch((error) => {
        console.log('Failed to load occupany', error);
        setOccupanyStatsLoading(false);
      })
      .finally(() => {
        setOccupanyStatsLoading(false);
      });
  }, [occupancyEntityId, occupancyPropertyId, occupancyTowerId]);

  useEffect(() => {
    getOccupanyStats();
  }, [getOccupanyStats]);

  const getTowerStats = useCallback(() => {
    setTowerStatsLoading(true);
    let payload: any = {
      entityId: towerEntityId,
      propertyId: towerPropertyId,
      towerId: towerStatsTowerId,
    };
    CommonService._dashboard
      .TowerStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setTowerStatsLoading(false);
        setTowerStatsData(response?.data);
      })
      .catch((error) => {
        console.log('Failed to load tower stats', error);
        setTowerStatsLoading(false);
      })
      .finally(() => {
        setTowerStatsLoading(false);
      });
  }, [towerEntityId, towerPropertyId, towerStatsTowerId]);

  useEffect(() => {
    getTowerStats();
  }, [getTowerStats]);

  const getTenantStats = useCallback(() => {
    setTenantStatsLoading(true);
    let payload: any = {
      entityId: tenantEntityId,
      propertyId: tenantPropertyId,
      towerId: tenantTowerId,
    };
    CommonService._dashboard
      .TenantStatsAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        setTenantStatsLoading(false);
        setTenantStatsData(response?.data);
      })
      .catch((error) => {
        console.log('Failed to load tenant stats', error);

        setTenantStatsLoading(false);
      })
      .finally(() => {
        setTenantStatsLoading(false);
      });
  }, [tenantEntityId, tenantPropertyId, tenantTowerId]);

  useEffect(() => {
    getTenantStats();
  }, [getTenantStats]);

  const getTowerDetails = useCallback((propertyId: any, statsType: string) => {
    let payload: any;
    CommonService._tenants
      .GetTowerDetailsAPICall(propertyId, payload)
      .then((response: IAPIResponseType<any>) => {
        const data = response?.data?.towers?.map((tower: any) => ({
          name: tower.name,
          id: tower._id,
        }));
        if (statsType === 'occupany') {
          setOccupancyTowerData(data);
        }
        if (statsType === 'tower') {
          setTowerStatsTowerData(data);
        }
        if (statsType === 'tenant') {
          setTenantTowerData(data);
        }
      })
      .catch((error) => {
        console.log('Failed to load tower list', error);
      });
  }, []);

  return (
    <div className='property-stats-component'>
      {/* ALL MODULES LIST COUNT */}
      <div
        className='property-module-stats-wrapper'
        aria-busy={dashboardStatsLoading}
      >
        {dashboardStatsLoading ? (
          Array(6)
            .fill(null)
            .map((_, index) => <SkeletonModuleCardComponent key={index} />)
        ) : (
          <>
            {dashboardStats?.map((stats: any, index: number) => (
              <ModuleCardComponent
                key={index}
                icon={stats?.icon}
                title={stats?.title}
                count={stats?.count}
              />
            ))}
          </>
        )}
      </div>

      {/* OCCUPANY CHART */}
      <div className='occupany-stats-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label='Occupany'
            isBold
            level={4}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={occupancyEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setOccupancyEntityId(value ? value : '');
                const updatedPropertyList = propertyList?.filter(
                  (list: any) => list.billingEntityId === value,
                );
                setOccupancyPropertyList(updatedPropertyList);
                setOccupancyPropertyId('');
                setOccupancyTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Property'
              options={occupancyPropertyList}
              value={occupancyPropertyId}
              disabled={!occupancyEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setOccupancyPropertyId(value ? value : '');
                if (value) {
                  getTowerDetails(value, 'occupany');
                }
                setOccupancyTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Tower'
              options={occupancyTowerData}
              value={occupancyTowerId}
              disabled={!occupancyPropertyId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setOccupancyTowerId(value ? value : '');
              }}
            />
          </div>
        </div>
        <div className='occupany-chart-wrapper'>
          <DonutChartComponent
            apiResponse={occupanyData}
            loading={occupanyStatsLoading}
            type='occupany'
          />
        </div>
        <div className='occupany-chart-legends'>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dotone'></div>
            <div>Vacant Area</div>
          </div>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dottwo'></div>
            <div>Tenants</div>
          </div>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dotthree'></div>
            <div>Amenities</div>
          </div>
        </div>
      </div>

      {/* Tower Stats */}
      <div className='occupany-stats-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label='Tower Stats'
            isBold
            level={4}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={towerEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setTowerEntityId(value ? value : '');
                const updatedPropertyList = propertyList?.filter(
                  (list: any) => list.billingEntityId === value,
                );
                setTowerPropertyList(updatedPropertyList);
                setTowerPropertyId('');
                setTowerStatsTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Property'
              options={towerPropertyList}
              value={towerPropertyId}
              disabled={!towerEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setTowerPropertyId(value ? value : '');
                if (value) {
                  getTowerDetails(value, 'tower');
                }
                setTowerStatsTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Tower'
              options={towerStatsTowerData}
              value={towerStatsTowerId}
              disabled={!towerPropertyId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setTowerStatsTowerId(value ? value : '');
              }}
            />
          </div>
        </div>
        <div className='occupany-chart-wrapper'>
          <DonutChartComponent
            apiResponse={towerStatsData}
            loading={towerStatsLoading}
            type='tower'
          />
        </div>
        <div className='occupany-chart-legends'>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dotone'></div>
            <div>Vacant Area</div>
          </div>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dottwo'></div>
            <div>Office Floors</div>
          </div>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dotthree'></div>
            <div>Hard Options</div>
          </div>
          <div className='occupany-chart-legend'>
            <div className='occupany-chart-dotfour'></div>
            <div>Basements</div>
          </div>
        </div>
      </div>

      {/* Tenant Stats */}
      <div className='occupany-stats-wrapper'>
        <div className='d-flex-between'>
          <FormControlLabelComponent
            label='Tenant Stats'
            isBold
            level={4}
            className='mrg-top-10 nowrap'
          />
          <div className='d-flex-between'>
            <SelectDropdownComponent
              placeholder='Entity'
              options={entityList}
              value={tenantEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setTenantEntityId(value ? value : '');
                const updatedPropertyList = propertyList?.filter(
                  (list: any) => list.billingEntityId === value,
                );
                setTenantPropertyList(updatedPropertyList);
                setTenantPropertyId('');
                setTenantTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Property'
              options={tenantPropertyList}
              value={tenantPropertyId}
              disabled={!tenantEntityId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option._id}
              onUpdate={(value) => {
                setTenantPropertyId(value ? value : '');
                if (value) {
                  getTowerDetails(value, 'tenant');
                }
                setTenantTowerId('');
              }}
            />
            <SelectDropdownComponent
              placeholder='Tower'
              options={tenantTowerData}
              value={tenantTowerId}
              disabled={!tenantPropertyId}
              displayWith={(option: any) => option.name}
              valueExtractor={(option: any) => option.id}
              onUpdate={(value) => {
                setTenantTowerId(value ? value : '');
              }}
            />
          </div>
        </div>
        <div className='occupany-chart-wrapper'>
          <DonutChartComponent
            apiResponse={tenantStatsData}
            loading={tenantStatsLoading}
            type='tenant'
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyStatsComponent;
