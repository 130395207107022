import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { IRootReducerState } from '../../store/reducers';
import { ImageConfig } from '../../constants';

interface ViewLayoutProps {}

const ViewLayout = (props: ViewLayoutProps) => {
  const location: any = useLocation();
  const path = location.pathname;
  const [title, setTitle] = useState<string>('');
  const { onNavigateBack, currentNavParams } = useSelector(
    (state: IRootReducerState) => state.navigation,
  );
  const [hideBackBtn, setHideBackBtn] = useState<boolean>(false);

  const status = currentNavParams.title;
  const type = currentNavParams.meta;

  useEffect(() => {
    if (type === 'invoice') {
      if (status === 'draft') {
        setTitle('Draft Invoice');
      } else if (status === 'approved') {
        setTitle('GST Approved Invoice');
      } else if (status === 'overdue') {
        setTitle('OverDue Invoice');
      } else if (status === 'cancelled') {
        setTitle('Cancelled Invoice');
      } else if (status === 'sent_to_client') {
        setTitle('Invoice Generated To Client');
      }
    }
    if (type === 'debitNote') {
      setTitle('Utility Invoice');
    }
    if (path.includes('/invoice/verify')) {
      setHideBackBtn(true);
      setTitle('Draft Invoice');
    } else {
      setHideBackBtn(false);
    }
  }, [status, path, setHideBackBtn, type]);

  const handleNavigateBack = useCallback(() => {
    if (onNavigateBack) {
      onNavigateBack();
    }
  }, [onNavigateBack]);

  return (
    <div className='view-layout'>
      <div className='view-layout-header'>
        {!hideBackBtn && (
          <div onClick={handleNavigateBack} className='view-layout-navigator'>
            <ImageConfig.NavigateBack />
            <span>Back</span>
          </div>
        )}
        <div className='view-layout-title'>{title}</div>
      </div>
      <div className='view-layout-content-holder'>
        <Outlet />
      </div>
    </div>
  );
};

export default ViewLayout;
