import "./DesignSystemScreen.scss";
import * as Yup from "yup";
import React, {useCallback, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from 'formik';
import FormikPasswordInputComponent
    from "../../shared/components/form-controls/formik-password-input/FormikPasswordInputComponent";
import FormikInputComponent from "../../shared/components/form-controls/formik-input/FormikInputComponent";
import {Login} from "@mui/icons-material";
import ButtonComponent from "../../shared/components/button/ButtonComponent";
import {CommonService} from "../../shared/services";
import FormikRadioButtonGroupComponent
    from "../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent";
import FormikCheckBoxComponent from "../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";
import FormikSelectDropdownComponent
    from "../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import FormikDatePickerComponent
    from "../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import TagComponent from "../../shared/components/tag/TagComponent";
import _ from "lodash";
import FormikTimePickerComponent
    from "../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
// import ButtonGroupComponent from "../../shared/components/button-group/ButtonGroupComponent";
import {ImageConfig} from "../../constants";
import AccordionComponent from "../../shared/components/accordion/AccordionComponent";
import FilePickerComponent from "../../shared/components/file-picker/FilePickerComponent";
import FilePreviewThumbnailComponent
    from "../../shared/components/file-preview-thumbnail/FilePreviewThumbnailComponent";
import ErrorComponent from "../../shared/components/error/ErrorComponent";

interface DesignSystemScreenProps {

}

//
// const buttons = [
//     {title: 'Unplanned Requests', badge: 0, id: 'unplanned'},
//     {
//         title: 'Planned Visitors',
//         id: 'planned',
//         suffixIcon: <ImageConfig.AmenitiesIcon/>
//     },
//     {title: 'Visitors Arrived', id: 'arrived'},
//     {
//         title: 'History',
//         id: 'history',
//         prefixIcon: <ImageConfig.AmenitiesIcon/>,
//         badge: {
//             text: '50',
//             color: 'primary'
//         }
//     }
// ]

const designSystemFormValidationSchema = Yup.object({
    username: Yup.string()
        .required("Username is required"),
    image: Yup.mixed().required(),
    password: Yup.string()
        .min(8, "Password must contain at least 8 characters")
        .required("Password is required"),
    isSelected: Yup.boolean().required("Please select an option"),
    isAccepted: Yup.boolean().required("Please select an option"),
    date: Yup.date().required("Date is required"),
});

const DesignSystemScreen = (props: DesignSystemScreenProps) => {

    // const [mode, setMode] = useState<'unplanned' | 'planned' | 'arrived' | 'history'>('planned')
    const [isAccordionOpen] = useState<boolean>(true);

    const [designSystemFormInitialValues] = useState({
        username: "terrill@gmail.com",
        password: "123455",
        isSelected: true,
        isAccepted: false,
        image: null,
        date: new Date(),
        isAdmin: {
            title: "Yes",
            code: true
        }
    });

    const onSubmit = useCallback((values: any, {setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 500);
    }, [])

    return (
        <div className="mrg-20">
            <AccordionComponent
                title={'title'}
                isAccordionOpen={isAccordionOpen}
                actions={
                    <div className="accordion-icon-action" onClick={(e: any) => {
                        e.stopPropagation()
                    }}>
                        <ImageConfig.WarnIcon/>
                    </div>
                }

            >
                <Formik
                    validationSchema={designSystemFormValidationSchema}
                    initialValues={designSystemFormInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {(formik) => {
                        const { values, errors, setFieldValue, touched } = formik;
                        return (
                            <Form className={"login-holder"} noValidate={true}>
                                <TagComponent label={'Signup to enjoy the benefits'} color={'primary'}/>
                                <Field name={'username'} className="t-form-control">
                                    {
                                        (field: FieldProps) => (
                                            <FormikInputComponent
                                                label={'Email'}
                                                placeholder={'Enter Email'}
                                                type={"email"}
                                                required={true}
                                                formikField={field}
                                                fullWidth={true}
                                                id={"email_input"}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name={'password'} className="t-form-control">
                                    {
                                        (field: FieldProps) => (
                                            <FormikPasswordInputComponent
                                                label={'Password'}
                                                placeholder={'Enter Password'}
                                                required={true}
                                                formikField={field}
                                                fullWidth={true}
                                                canToggle={true}
                                                id={"password_input"}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name={`isSelected`}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikRadioButtonGroupComponent
                                                label={'Is Selected'}
                                                options={CommonService._staticData.yesNoOptions}
                                                displayWith={(option) => option.title}
                                                valueExtractor={(option) => option.code}
                                                required={true}
                                                formikField={field}
                                            />
                                        )
                                    }
                                </Field>
                                <Field name={`isAccepted`}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikCheckBoxComponent
                                                label={'I accept to TnC'}
                                                required={true}
                                                formikField={field}
                                            />
                                        )
                                    }
                                </Field>

                                <Field name={'isAdmin'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikSelectDropdownComponent
                                                label={'Is Admin'}
                                                required={true}
                                                formikField={field}
                                                fullWidth={true}
                                                options={CommonService._staticData.yesNoOptions}
                                                displayWith={(option) => option.title}
                                                valueExtractor={(option) => option}
                                                id={"password_input"}
                                            />
                                        )
                                    }
                                </Field>

                                <Field name={'date'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikDatePickerComponent
                                                label={'Date'}
                                                formikField={field}
                                                required={true}
                                                fullWidth={true}
                                                maxDate={new Date()}
                                            />
                                        )
                                    }
                                </Field>

                                <Field name={'time'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikTimePickerComponent
                                                label={'Time'}
                                                placeholder={'Select Time'}
                                                formikField={field}
                                                required={true}
                                                fullWidth={true}
                                            />
                                        )
                                    }
                                </Field>

                                <Field name={'language'}>
                                    {
                                        (field: FieldProps) => (
                                            <FormikSelectDropdownComponent
                                                formikField={field}
                                                fullWidth={true}
                                                required={true}
                                                isClearable={true}
                                                searchable={true}
                                                placeholder={"Search Language"}
                                                options={['1', '2', '3', '4', '5', '6']}
                                                displayWith={(option) => option}
                                                valueExtractor={(option) => option}
                                                id={"password_input"}
                                            />
                                        )
                                    }
                                </Field>
                                <>
                                    {
                                        (!values.image) && <>
                                            <FilePickerComponent maxFileCount={1}
                                                                 id={"sc_upload_btn"}
                                                                 onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                                                     if (acceptedFiles && acceptedFiles.length > 0) {
                                                                         const file = acceptedFiles[0];
                                                                         setFieldValue('image', file);
                                                                     }
                                                                 }}
                                                                 uploadDescription={"(upload only one file)"}
                                                                 acceptedFilesText={"PNG, JPG and JPEG files are allowed"}
                                                                 acceptedFileTypes={["png", "jpg", "jpeg"]}
                                            />
                                            {
                                                (_.get(touched, "image") && !!(_.get(errors, "image"))) &&
                                                <ErrorComponent
                                                    errorText={(_.get(errors, "image"))}/>
                                            }
                                        </>
                                    }
                                </>
                                <>
                                    {
                                        (values.image) && <>
                                            <FilePreviewThumbnailComponent
                                                file={values.image}
                                                removeButtonId={"sc_delete_img"}
                                                onRemove={() => {
                                                    setFieldValue('image', '');
                                                }}
                                            />
                                        </>
                                    }
                                </>

                                <ButtonComponent
                                    suffixIcon={<Login/>}
                                    isLoading={formik.isSubmitting}
                                    type={"submit"}
                                    fullWidth={true}
                                    id={"login_btn"}
                                >
                                    {formik.isSubmitting ? "Submitting" : "Submit"}
                                </ButtonComponent>

                                {/*<ButtonComponent*/}
                                {/*    prefixIcon={<Login/>}*/}
                                {/*    isLoading={formik.isSubmitting}*/}
                                {/*    type={"submit"}*/}
                                {/*    variant={'outlined'}*/}
                                {/*    fullWidth={true}*/}
                                {/*    id={"login_btn"}*/}
                                {/*>*/}
                                {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
                                {/*</ButtonComponent>*/}

                                {/*<ButtonComponent*/}
                                {/*    prefixIcon={<Login/>}*/}
                                {/*    isLoading={formik.isSubmitting}*/}
                                {/*    type={"submit"}*/}
                                {/*    color={'secondary'}*/}
                                {/*    fullWidth={true}*/}
                                {/*    id={"login_btn"}*/}
                                {/*>*/}
                                {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
                                {/*</ButtonComponent>*/}

                                {/*<ButtonComponent*/}
                                {/*    suffixIcon={<Login/>}*/}
                                {/*    isLoading={formik.isSubmitting}*/}
                                {/*    type={"submit"}*/}
                                {/*    fullWidth={true}*/}
                                {/*    color={'secondary'}*/}
                                {/*    variant={'outlined'}*/}
                                {/*    id={"login_btn"}*/}
                                {/*>*/}
                                {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
                                {/*</ButtonComponent>*/}

                                {/*<ButtonComponent*/}
                                {/*    suffixIcon={<Login/>}*/}
                                {/*    isLoading={formik.isSubmitting}*/}
                                {/*    type={"submit"}*/}
                                {/*    fullWidth={true}*/}
                                {/*    id={"login_btn"}*/}
                                {/*    color={'error'}*/}
                                {/*    variant={'outlined'}*/}
                                {/*>*/}
                                {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
                                {/*</ButtonComponent>*/}
                            </Form>
                        )
                    }}
                </Formik>
            </AccordionComponent>
        </div>
        // <div className="design-system-screen screen">
        //     <div className="design-system-form-container">
        //         <CardComponent title={"Sign up"}>
        //             <Formik
        //                 validationSchema={designSystemFormValidationSchema}
        //                 initialValues={designSystemFormInitialValues}
        //                 validateOnChange={false}
        //                 validateOnBlur={true}
        //                 enableReinitialize={true}
        //                 validateOnMount={true}
        //                 onSubmit={onSubmit}
        //             >
        //                 {(formik) => {
        //                     return (
        //                         <Form className={"login-holder"} noValidate={true}>
        //                             <TagComponent label={'Signup to enjoy the benefits'} color={'primary'}/>
        //                             <Field name={'username'} className="t-form-control">
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikInputComponent
        //                                             label={'Email'}
        //                                             placeholder={'Enter Email'}
        //                                             type={"email"}
        //                                             required={true}
        //                                             formikField={field}
        //                                             fullWidth={true}
        //                                             id={"email_input"}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //                             <Field name={'password'} className="t-form-control">
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikPasswordInputComponent
        //                                             label={'Password'}
        //                                             placeholder={'Enter Password'}
        //                                             required={true}
        //                                             formikField={field}
        //                                             fullWidth={true}
        //                                             canToggle={true}
        //                                             id={"password_input"}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //                             <Field name={`isSelected`}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikRadioButtonGroupComponent
        //                                             options={CommonService._staticData.yesNoOptions}
        //                                             displayWith={(option) => option.title}
        //                                             valueExtractor={(option) => option.code}
        //                                             required={true}
        //                                             formikField={field}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <Field name={`isAccepted`}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikCheckBoxComponent
        //                                             label={'I accept to TnC'}
        //                                             required={true}
        //                                             formikField={field}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <Field name={'isAdmin'}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikSelectDropdownComponent
        //                                             label={'Is Admin'}
        //                                             required={true}
        //                                             formikField={field}
        //                                             fullWidth={true}
        //                                             options={CommonService._staticData.yesNoOptions}
        //                                             displayWith={(option) => option.title}
        //                                             valueExtractor={(option) => option}
        //                                             id={"password_input"}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <Field name={'date'}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikDatePickerComponent
        //                                             label={'Date'}
        //                                             formikField={field}
        //                                             required={true}
        //                                             fullWidth={true}
        //                                             maxDate={new Date()}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <Field name={'time'}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikTimePickerComponent
        //                                             label={'Time'}
        //                                             placeholder={'Select Time'}
        //                                             formikField={field}
        //                                             required={true}
        //                                             fullWidth={true}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <Field name={'language'}>
        //                                 {
        //                                     (field: FieldProps) => (
        //                                         <FormikSelectDropdownComponent
        //                                             formikField={field}
        //                                             fullWidth={true}
        //                                             required={true}
        //                                             isClearable={true}
        //                                             searchable={true}
        //                                             searchMode={"serverSide"}
        //                                             multiple={false}
        //                                             dataListKey={"data"}
        //                                             url={APIConfig.LANGUAGE_LIST.URL}
        //                                             method={APIConfig.LANGUAGE_LIST.METHOD}
        //                                             displayWith={(option: any) => option?.zipcode}
        //                                             placeholder={"Search Language"}
        //                                             options={[]}
        //                                         />
        //                                     )
        //                                 }
        //                             </Field>
        //
        //                             <ButtonComponent
        //                                 suffixIcon={<Login/>}
        //                                 isLoading={formik.isSubmitting}
        //                                 type={"submit"}
        //                                 fullWidth={true}
        //                                 id={"login_btn"}
        //                             >
        //                                 {formik.isSubmitting ? "Submitting" : "Submit"}
        //                             </ButtonComponent>
        //
        //                             {/*<ButtonComponent*/}
        //                             {/*    prefixIcon={<Login/>}*/}
        //                             {/*    isLoading={formik.isSubmitting}*/}
        //                             {/*    type={"submit"}*/}
        //                             {/*    variant={'outlined'}*/}
        //                             {/*    fullWidth={true}*/}
        //                             {/*    id={"login_btn"}*/}
        //                             {/*>*/}
        //                             {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
        //                             {/*</ButtonComponent>*/}
        //
        //                             {/*<ButtonComponent*/}
        //                             {/*    prefixIcon={<Login/>}*/}
        //                             {/*    isLoading={formik.isSubmitting}*/}
        //                             {/*    type={"submit"}*/}
        //                             {/*    color={'secondary'}*/}
        //                             {/*    fullWidth={true}*/}
        //                             {/*    id={"login_btn"}*/}
        //                             {/*>*/}
        //                             {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
        //                             {/*</ButtonComponent>*/}
        //
        //                             {/*<ButtonComponent*/}
        //                             {/*    suffixIcon={<Login/>}*/}
        //                             {/*    isLoading={formik.isSubmitting}*/}
        //                             {/*    type={"submit"}*/}
        //                             {/*    fullWidth={true}*/}
        //                             {/*    color={'secondary'}*/}
        //                             {/*    variant={'outlined'}*/}
        //                             {/*    id={"login_btn"}*/}
        //                             {/*>*/}
        //                             {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
        //                             {/*</ButtonComponent>*/}
        //
        //                             {/*<ButtonComponent*/}
        //                             {/*    suffixIcon={<Login/>}*/}
        //                             {/*    isLoading={formik.isSubmitting}*/}
        //                             {/*    type={"submit"}*/}
        //                             {/*    fullWidth={true}*/}
        //                             {/*    id={"login_btn"}*/}
        //                             {/*    color={'error'}*/}
        //                             {/*    variant={'outlined'}*/}
        //                             {/*>*/}
        //                             {/*    {formik.isSubmitting ? "Submitting" : "Submit"}*/}
        //                             {/*</ButtonComponent>*/}
        //                         </Form>
        //                     )
        //                 }}
        //             </Formik>
        //         </CardComponent>
        //     </div>
        //     <ButtonGroupComponent
        //         selected={mode}
        //         onChange={
        //             (selected: any) => {
        //                 setMode(selected);
        //             }
        //         }
        //         buttons={buttons}
        //     />
        //
        // </div>
    )
        ;
};


export default DesignSystemScreen;
