import {Outlet} from "react-router-dom";

interface TenantsComponentProps {

}

const TenantsComponent = (props: TenantsComponentProps) => {

    return (
        <Outlet/>
    );

};

export default TenantsComponent;
