import React, { useCallback, useEffect, useState } from 'react';
import './InvoiceDetailsComponent.scss';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import { ImageConfig, Misc } from '../../../../constants';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import RegularInvoiceComponent from '../../../../shared/components/invoice/regular-invoice/RegularInvoiceComponent';
import UtilityInvoiceComponent from '../../../../shared/components/invoice/utility-invoice/UtilityInvoiceComponent';

interface InvoiceDetailsComponentProps {
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  activeStepId: string;
  invoiceId: string;
  invoiceDetails?: any;
  invoiceType: string;
}

const validationSchema = Yup.object({
  invoiceName: Yup.string().required('Invoice name is required').nullable(),
  newInvoiceName: Yup.string().when('invoiceName', {
    is: (value: string) => value === 'Others',
    then: Yup.string().required('Invoice name is required'),
  }),

  sgst: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .typeError('SGST is required')
      .required('SGST is required')
      .min(0, ' SGST must be greater than or equal to 0')
      .max(100, 'SGST must be less than or equal to 100')
      .nullable(),
    otherwise: Yup.number().notRequired(),
  }),
  igst: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .typeError('iGST is required')
      .required('iGST is Required')
      .min(0, ' iGST must be greater than or equal to 0')
      .max(100, 'iGST must be less than or equal to 100')
      .nullable(),
    otherwise: Yup.number().notRequired(),
  }),
  cgst: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .typeError('CGST is required')
      .required('CGST is Required')
      .min(0, ' CGST must be greater than or equal to 0')
      .max(100, 'CGST must be less than or equal to 100'),
    otherwise: Yup.number().notRequired(),
  }),
  supplyType: Yup.string().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.string().required('Supply type code is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  unitDesc: Yup.string().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.string().required('Unit Description is required').nullable(),
    otherwise: Yup.string().notRequired(),
  }),
  unitPrice: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .required('Unit Price is Required')
      .min(1, 'Unit Price is Required')
      .nullable(),
    otherwise: Yup.number().notRequired(),
  }),
  quantity: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .required('Quantity is Required')
      .min(1, 'Quantity is Required')
      .nullable(),
    otherwise: Yup.number().notRequired(),
  }),
  HSNCode: Yup.number().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'regularInvoice' ||
      (category === 'utilityInvoice' && utilityInvoiceType === 'gst'),
    then: Yup.number()
      .required('HSN Code is Required')
      .min(1, 'HSN Code is Required')
      .nullable(),
    otherwise: Yup.number().notRequired(),
  }),
  invoiceItemList: Yup.array().when(['category', 'utilityInvoiceType'], {
    is: (category: string, utilityInvoiceType: string) =>
      category === 'utilityInvoice' && utilityInvoiceType === 'nonGst',
    then: Yup.array().of(
      Yup.object().shape({
        itemName: Yup.string().required('Required').nullable(),
        consumption: Yup.string().required('Required').nullable(),
        unitDesc: Yup.string().required('Required').nullable(),
        ratePerUnit: Yup.string().required('Required'),
      }),
    ),
  }),
});

interface formInitialValuesFormSchema {
  invoiceName: string;
  newInvoiceName?: string;
  sgst: number;
  igst: number;
  cgst: number;
  subTotal: number;
  supplyType: string;
  unit: string;
  unitDesc: string;
  unitPrice: number;
  HSNCode: string;
  quantity: number;
  descriptionOfService: string;
  invoiceItemList: [
    {
      itemName: string;
      consumption: string;
      ratePerUnit: string;
      unit: string;
      unitDesc: string;
      amount: string;
    },
  ];
}

const formInitialValues: formInitialValuesFormSchema = {
  invoiceName: '',
  newInvoiceName: '',
  sgst: 0,
  igst: 0,
  cgst: 0,
  subTotal: 0,
  supplyType: '',
  unit: '',
  unitDesc: '',
  unitPrice: 0,
  HSNCode: '',
  quantity: 0,
  descriptionOfService: '',
  invoiceItemList: [
    {
      itemName: '',
      consumption: '',
      ratePerUnit: '',
      unit: '',
      unitDesc: '',
      amount: '',
    },
  ],
};

const InvoiceDetailsComponent = (props: InvoiceDetailsComponentProps) => {
  const {
    next,
    prev,
    steps,
    activeStepId,
    invoiceId,
    invoiceDetails,
    invoiceType,
  } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const [invoiceNames, setInvoiceNames] = useState<any[] | undefined>(
    undefined,
  );
  const [igstValue, setIgstValue] = useState<number>(0);
  const [addressDetails, setAddressDetails] = useState<any>(undefined);

  // Removing scroll behavior for input type number
  useEffect(() => {
    document
      .getElementById('invoice-input')
      ?.addEventListener('wheel', function (e) {
        e.preventDefault();
      });
    document
      .getElementById('invoice-input1')
      ?.addEventListener('wheel', function (e) {
        e.preventDefault();
      });
  }, []);

  // Fetching igst value using tenant id
  const getIgstValue = useCallback(() => {
    const type = invoiceDetails?.billingTo;
    const payload = {};
    let apiCall: any;
    if (type === 'tenant') {
      apiCall = CommonService._tenants.GetTenantsDetailsAPICall(
        invoiceDetails?.tenantId,
        payload,
      );
    }
    if (type === 'amenity') {
      apiCall = CommonService._amenity.GetAmenityDetailsAPICall(
        invoiceDetails?.amenityId,
        payload,
      );
    }
    if (invoiceDetails) {
      apiCall
        .then((response: IAPIResponseType<any>) => {
          setAddressDetails(response?.data);
          const value = response?.data?.igst;
          setIgstValue(value);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(error, 'error');
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    getIgstValue();
  }, [getIgstValue]);

  // Setting initialValues
  useEffect(() => {
    const basicDetails = {
      invoiceName: '',
      newInvoiceName: '',
      sgst: invoiceDetails?.sgst || 0,
      igst: invoiceDetails?.igst || igstValue || 0,
      cgst: invoiceDetails?.cgst || 0,
      subTotal: invoiceDetails?.subTotal || 0,
      supplyType: invoiceDetails?.supplyType || '',
      unit: invoiceDetails?.unit || '',
      unitDesc: invoiceDetails?.unitDesc || '',
      unitPrice: invoiceDetails?.unitPrice || 0,
      HSNCode: invoiceDetails?.HSNCode || '',
      quantity: invoiceDetails?.quantity || 0,
      category: invoiceDetails?.category,
      utilityInvoiceType: invoiceDetails?.utilityInvoiceType,
      descriptionOfService: invoiceDetails?.descriptionOfService || '',
      invoiceItemList:
        invoiceDetails?.invoiceItemList?.length !== 0
          ? invoiceDetails?.invoiceItemList?.map((list: any) => ({
              itemName: list.itemName || '',
              consumption: parseInt(list.consumption) || '',
              ratePerUnit: parseInt(list.ratePerUnit) || '',
              unit: list.unit || '',
              unitDesc: list.unitDesc || '',
              amount: parseInt(list.consumption) * parseInt(list.ratePerUnit),
            }))
          : [
              {
                itemName: '',
                consumption: '',
                ratePerUnit: '',
                unit: '',
                unitDesc: '',
                amount: '',
              },
            ],
    };

    if (invoiceType === 'invoice') {
      if (invoiceDetails?.invoiceName && invoiceNames) {
        const selectedInvoice = invoiceNames.find(
          (invoice) => invoice.component === invoiceDetails?.invoiceName,
        );
        if (selectedInvoice) {
          basicDetails.invoiceName = selectedInvoice.component;
          basicDetails.newInvoiceName = '';
        } else {
          basicDetails.invoiceName = 'Others';
          basicDetails.newInvoiceName = invoiceDetails?.invoiceName;
        }
      }
    } else {
      basicDetails.invoiceName = invoiceDetails?.invoiceName;
    }

    setInitialValues(basicDetails);
  }, [invoiceDetails, invoiceNames, invoiceType, igstValue]);

  // Fetching invoice names from api based on tenant ID
  const getInvoiceNames = useCallback(() => {
    const type = invoiceDetails?.billingTo;
    let apiCall: any;
    if (type === 'tenant') {
      apiCall = CommonService._tenants.getTenantBillingComponentsAPICall(
        invoiceDetails?.tenantId,
      );
    }
    if (type === 'amenity') {
      apiCall = CommonService._amenity.getAmenityBillingComponentsAPICall(
        invoiceDetails?.amenityId,
      );
    }

    if (invoiceDetails) {
      apiCall
        .then((response: IAPIResponseType<any>) => {
          setInvoiceNames(response?.data?.billingComponentsAmounts);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        });
    }
  }, [invoiceDetails]);

  useEffect(() => {
    getInvoiceNames();
  }, [getInvoiceNames]);

  // Function for calcuating sub total
  const calculateRegularInvoiceSubTotalAmount = useCallback(
    (unitPrice: number, quantity: number) => {
      const subTotal = unitPrice * quantity;
      return subTotal.toFixed(2);
    },
    [],
  );

  // Function for calculating Total Amount
  const calculateRegularInvoiceTotalAmount = useCallback(
    (
      sgst: number,
      igst: number,
      cgst: number,
      unitPrice: number,
      quantity: number,
    ) => {
      const subTotal = unitPrice * quantity;
      const totalAmount =
        subTotal +
        (subTotal * sgst) / 100 +
        (subTotal * igst) / 100 +
        (subTotal * cgst) / 100;
      return totalAmount.toFixed(2);
    },

    [],
  );

  const calculateUtilityInvoiceTotalAmount = useCallback(
    (invoiceItemList: any[], sgst: number, igst: number, cgst: number) => {
      const subTotal = invoiceItemList?.reduce(
        (total: any, item: any) => total + item.amount,
        0,
      );
      const totalAmount =
        subTotal +
        (subTotal * sgst) / 100 +
        (subTotal * igst) / 100 +
        (subTotal * cgst) / 100;

      return totalAmount.toFixed(2);
    },
    [],
  );

  const calculateUtilityInvoiceSubTotalAmount = useCallback(
    (invoiceItemList: any) => {
      const result = invoiceItemList?.reduce(
        (total: any, item: any) => total + item.consumption * item.ratePerUnit,
        0,
      );
      return result;
    },
    [],
  );

  // Function to send payload on submit
  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);

      let totalAmount;
      let subTotal;
      let sgstAmount;
      let igstAmount;
      let cgstAmount;

      if (invoiceType === 'invoice') {
        totalAmount = calculateRegularInvoiceTotalAmount(
          values.sgst,
          values.igst,
          values.cgst,
          values.unitPrice,
          values.quantity,
        );
        subTotal = calculateRegularInvoiceSubTotalAmount(
          values.unitPrice,
          values.quantity,
        );
        sgstAmount = (values.quantity * values.unitPrice * values.sgst) / 100;
        igstAmount = (values.quantity * values.unitPrice * values.igst) / 100;
        cgstAmount = (values.quantity * values.unitPrice * values.cgst) / 100;
        delete values?.invoiceItemList;
      }

      if (invoiceType === 'debitNote') {
        totalAmount = calculateUtilityInvoiceTotalAmount(
          values?.invoiceItemList,
          values?.sgst,
          values?.igst,
          values?.cgst,
        );

        subTotal = calculateUtilityInvoiceSubTotalAmount(
          values?.invoiceItemList,
        );
        sgstAmount = (subTotal * values.sgst) / 100;
        igstAmount = (subTotal * values.igst) / 100;
        cgstAmount = (subTotal * values.cgst) / 100;
      }

      if (values.newInvoiceName.length <= 0) {
        delete values.newInvoiceName;
      }
      if (values.invoiceName === 'Others' && values.newInvoiceName) {
        values.invoiceName = values.newInvoiceName;
        delete values.newInvoiceName;
      }

      const payload = {
        ...values,
        isCompleted: true,
        totalAmount: totalAmount,
        subTotal: subTotal,
        sgstAmt: sgstAmount,
        igstAmt: igstAmount,
        cgstAmt: cgstAmount,
      };

      const formData = CommonService.getFormDataFromJSON(payload);

      if (invoiceType === 'invoice') {
        CommonService._invoice
          .AddInvoiceDetailsAPICall(invoiceId, formData)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next && next(response?.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
      if (invoiceType === 'debitNote') {
        CommonService._debitnote
          .EditDebitNoteAPICall(invoiceId, formData)
          .then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(
              response[Misc.API_RESPONSE_MESSAGE_KEY],
              'success',
            );
            next && next(response?.data);
          })
          .catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    [
      calculateRegularInvoiceTotalAmount,
      invoiceId,
      next,
      calculateRegularInvoiceSubTotalAmount,
      calculateUtilityInvoiceSubTotalAmount,
      calculateUtilityInvoiceTotalAmount,
      invoiceType,
    ],
  );

  return (
    <div className='add-screen new-invoice-details-component'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          isSubmitting,
          setFieldValue,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              /> */}
              <div className='add-component-header'>
                <ButtonComponent
                  variant='text'
                  color='secondary'
                  onClick={prev}
                  prefixIcon={<ImageConfig.ArrowLeftIcon />}
                >
                  Back
                </ButtonComponent>
                <div>
                  <StepperComponent steps={steps} activeStepId={activeStepId} />
                </div>
                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  Save Invoice
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                {invoiceDetails?.category === 'regularInvoice' ||
                (invoiceDetails?.category === 'utilityInvoice' &&
                  invoiceDetails?.utilityInvoiceType === 'gst') ? (
                  <RegularInvoiceComponent
                    invoiceDetails={invoiceDetails}
                    addressDetails={addressDetails}
                    category={invoiceDetails?.category}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                ) : null}
                {invoiceDetails?.category === 'utilityInvoice' &&
                  invoiceDetails?.utilityInvoiceType === 'nonGst' && (
                    <UtilityInvoiceComponent
                      invoiceDetails={invoiceDetails}
                      addressDetails={addressDetails}
                      category={invoiceDetails?.category}
                      utilityInvoiceType={invoiceDetails?.utilityInvoiceType}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvoiceDetailsComponent;
