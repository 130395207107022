import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const getDebitNoteStatusCount = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_DEBIT_NOTE_STATUS_COUNT.METHOD](
    APIConfig.GET_DEBIT_NOTE_STATUS_COUNT.URL,
    payload,
  );
};

const GetDebitNoteDetailsAPICall = (debitNoteId: any, payload: any) => {
  return ApiService[APIConfig.GET_DEBITNOTE_DETAILS.METHOD](
    APIConfig.GET_DEBITNOTE_DETAILS.URL(debitNoteId),
    payload,
  );
};

const downloadDebitNoteListAsExcel = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.DEBITNOTE_LIST.METHOD](
    APIConfig.DEBITNOTE_LIST.URL + `?download=true`,
    payload,
  );
};

const cancelDebitNoteAPICall = (debitNoteId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.CANCEL_DEBITNOTE.METHOD](
    APIConfig.CANCEL_DEBITNOTE.URL(debitNoteId),
    payload,
  );
};

const markAsPaidDebitNoteAPICall = (debitNoteId: string, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.MARK_AS_PAID_DEBITNOTE.METHOD](
    APIConfig.MARK_AS_PAID_DEBITNOTE.URL(debitNoteId),
    payload,
  );
};

const sentToClientBulkDebitNoteAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.DEBITNOTE_BULK_SENT_TO_CLIENT.METHOD](
    APIConfig.DEBITNOTE_BULK_SENT_TO_CLIENT.URL,
    payload,
  );
};

const getDebitNoteListAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.DEBITNOTE_LIST.METHOD](
    APIConfig.DEBITNOTE_LIST.URL,
    payload,
  );
};

const getHistoryDebitNoteListAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.DEBITNOTE_HISTORY.METHOD](
    APIConfig.DEBITNOTE_HISTORY.URL,
    payload,
  );
};

const AddDebitNoteAPICall = (payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.ADD_DEBITNOTE.METHOD](
    APIConfig.ADD_DEBITNOTE.URL,
    payload,
  );
};

const EditDebitNoteAPICall = (debitNoteId: any, payload: any) => {
  //@ts-ignore
  return ApiService[APIConfig.EDIT_DEBITNOTE.METHOD](
    APIConfig.EDIT_DEBITNOTE.URL(debitNoteId),
    payload,
  );
};

const DebitNoteService = {
  getDebitNoteStatusCount,
  downloadDebitNoteListAsExcel,
  GetDebitNoteDetailsAPICall,
  cancelDebitNoteAPICall,
  markAsPaidDebitNoteAPICall,
  sentToClientBulkDebitNoteAPICall,
  getDebitNoteListAPICall,
  getHistoryDebitNoteListAPICall,
  AddDebitNoteAPICall,
  EditDebitNoteAPICall,
};

export default DebitNoteService;
