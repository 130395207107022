import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './DonutChartComponent.scss';

interface DonutChartComponentProps {
  apiResponse: any[];
  loading: boolean;
  type: string;
}

const DonutChartComponent = (props: DonutChartComponentProps) => {
  const { apiResponse, loading, type } = props;
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartSize, setChartSize] = useState({ width: 0, height: 0 });
  const [data, setData] = useState<any[]>([]);

  //dynamic width based on the donut data
  useEffect(() => {
    const updateChartSize = () => {
      if (chartRef.current) {
        setChartSize({
          width: chartRef.current.clientWidth,
          height: chartRef.current.clientHeight,
        });
      }
    };

    window.addEventListener('resize', updateChartSize);
    updateChartSize();

    return () => {
      window.removeEventListener('resize', updateChartSize);
    };
  }, []);

  useEffect(() => {
    if (apiResponse.length > 0) {
      const formattedData = apiResponse
        ?.map((item: any) => {
          if (type === 'occupany') {
            return {
              value: item.percentage,
              name: item.name,
              itemStyle: { color: item.color },
              emphasis: { itemStyle: { color: item.color } },
              area: item.area,
            };
          }
          if (type === 'tenant') {
            return {
              value: item.area,
              name: item.name,
              itemStyle: { color: item.color },
              emphasis: { itemStyle: { color: item.color } },
              area: item.area,
            };
          }
          if (type === 'tower') {
            return {
              value: item.percentage,
              name: item.name,
              itemStyle: { color: item.color },
              emphasis: { itemStyle: { color: item.color } },
            };
          }
          if (type === 'invoice') {
            return {
              value: item.count,
              itemStyle: { color: item.color },
              emphasis: { itemStyle: { color: item.color } },
              name: item.name,
              invoiceType: item.invoiceType,
              type: item.type,
            };
          }
          return null;
        })
        .filter(Boolean);
      setData(formattedData);
    }
  }, [type, apiResponse]);

  const textToDisplay = useCallback((data: any) => {
    const { invoiceType, name, type, value } = data;
    let text = name === 'generated' ? 'have been generated' : 'to be generated';
    let invoiceName =
      invoiceType === 'automated' ? 'Automated Invoices' : 'Utility Invoices';
    let module =
      type.length === 0
        ? 'Tenant + Amenities'
        : type === 'tenant'
        ? 'Tenant'
        : type === 'amenity'
        ? 'Amenities'
        : '';

    return `${value} ${invoiceName} ${text} \n ${module}`;
  }, []);

  const labelFormatter = useCallback(
    (params: any) => {
      const { value, name, data } = params;
      if (type === 'occupany') {
        const formattedSft = new Intl.NumberFormat('en-IN').format(data?.area);
        return `${value}%\n(${formattedSft} sqft)`;
      }
      if (type === 'tenant') {
        const formattedSft = new Intl.NumberFormat('en-IN').format(data?.area);
        return `${name}\n(${formattedSft} sqft)`;
      }
      if (type === 'tower') {
        return `${value} % \n (${name})`;
      }
      if (type === 'invoice') {
        return textToDisplay(data);
      }
      return '';
    },
    [type, textToDisplay],
  );

  const option = {
    tooltip: {
      trigger: 'item',
      show: false,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '60%'],
        avoidLabelOverlap: false,
        data: loading ? [] : data,
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        label: {
          show: true,
          formatter: (params: any) => labelFormatter(params),
        },
        labelLine: {
          show: true,
          length: 50,
          length2: 30,
          lineStyle: {
            color: '#333',
            width: 1,
            type: 'solid',
          },
        },
      },
    ],
    grid: {
      top: '10%',
      bottom: '10%',
      left: '10%',
      right: '10%',
    },
  };
  return (
    <div className='donut-chart-component mrg-bottom-20' ref={chartRef}>
      <ReactECharts
        option={option}
        opts={{ renderer: 'svg' }}
        style={{ height: chartSize.height, width: chartSize.width }}
      />
    </div>
  );
};

export default DonutChartComponent;
