import { useCallback, useEffect, useState } from 'react';
import './InvoicesListLayoutComponent.scss';
import { useSearchParams } from 'react-router-dom';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import InvoicesListComponent from '../invoices-list/InvoicesListComponent';
import DrawerComponent from '../../../shared/components/drawer/DrawerComponent';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import BadgeComponent from '../../../shared/components/badge/BadgeComponent';
import InvoiceFilterComponent from '../invoice-filter/InvoiceFilterComponent';
import {
  setInvoiceDateRangeInFilters,
  setInvoiceFilterCount,
  setInvoicedateFilterOnInFilters,
} from '../../../store/actions/invoice.action';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import { getAllAmenityList } from '../../../store/actions/amenity.action';
import { getAllTenantList } from '../../../store/actions/tenant.action';
import { getAllPropertyList } from '../../../store/actions/property.action';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface InvoicesListLayoutComponentProps {}

const InvoicesSteps = [
  {
    title: 'Draft Invoices',
    id: 'draftInvoices',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Approved Invoices',
    id: 'approvedInvoices',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Sent to client',
    id: 'sent_to_client',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Overdue',
    id: 'overdue',
    badge: {
      text: '0',
      color: 'warning',
    },
  },
  {
    title: 'Cancelled',
    id: 'cancelled',
    badge: {
      text: '0',
      color: 'warning',
    },
  },
];

const InvoicesListLayoutComponent = (
  props: InvoicesListLayoutComponentProps,
) => {
  const [isInvoiceStatsLoading, setIsInvoiceStatsLoading] =
    useState<boolean>(false);
  const [isInvoiceStatsLoaded, setIsInvoiceStatsLoaded] =
    useState<boolean>(false);
  const [isInvoiceStatsFailed, setIsInvoiceStatsFailed] =
    useState<boolean>(false);
  const [title, setTitle] = useState<any | null>('Draft Invoices');
  const [currentTab, setCurrentTab] = useState<
    | 'draftInvoices'
    | 'approvedInvoices'
    | 'sent_to_client'
    | 'overdue'
    | 'cancelled'
    | 'history'
  >('draftInvoices');
  const [isMoreFilterDrawerOpen, setIsMoreFilterDrawerOpen] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<any[]>([]);
  const [invoiceList, setInvoiceList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [billingTo, setBillingTo] = useState<string>('tenant');
  const { allTenantList } = useSelector(
    (state: IRootReducerState) => state.tenant,
  );

  const { allPropertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { allAmenityList } = useSelector(
    (state: IRootReducerState) => state.amenity,
  );
  const { count, dateFilterOn, dateRange } = useSelector(
    (state: IRootReducerState) => state.invoice,
  );
  const [filterData, setFilterData] = useState<any>({
    billingTo: 'tenant',
  });

  useEffect(() => {
    dispatch(getAllAmenityList());
    dispatch(getAllPropertyList());
    dispatch(getAllTenantList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value);
  }, []);

  const getInvoiceStats = useCallback(() => {
    const payload = {
      billingTo: filterData?.billingTo,
    };
    setIsInvoiceStatsLoading(true);
    setIsInvoiceStatsLoaded(false);
    setIsInvoiceStatsFailed(false);
    CommonService._invoice
      .getInvoiceStatusCount(payload)
      .then((response: any) => {
        const stats = response?.data;
        InvoicesSteps?.forEach((step: any) => {
          step.id === 'draftInvoices' && (step.badge.text = stats?.draft);
          step.id === 'approvedInvoices' && (step.badge.text = stats?.approved);
          step.id === 'sent_to_client' &&
            (step.badge.text = stats?.sent_to_client);
          step.id === 'overdue' && (step.badge.text = stats?.overdue);
          step.id === 'cancelled' && (step.badge.text = stats?.cancelled);
        });
        setIsInvoiceStatsLoaded(true);
        setIsInvoiceStatsFailed(false);
      })
      .catch((err: any) => {
        setIsInvoiceStatsLoaded(false);
        setIsInvoiceStatsFailed(true);
        CommonService._alert.showToast(
          err[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      })
      .finally(() => {
        setIsInvoiceStatsLoading(false);
      });
  }, [filterData?.billingTo]);

  useEffect(() => {
    getInvoiceStats();
  }, [getInvoiceStats]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = InvoicesSteps.map((item: any) => {
      return item.id;
    });
    buttonsGroupArray.push('history');
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'draftInvoices';
      }
    } else {
      currentTab = 'draftInvoices';
      searchParams.set('currentStep', currentTab);
      setSearchParams(searchParams);
    }
    setCurrentTab(currentTab);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    dispatch(setInvoiceDateRangeInFilters([null, null]));
    dispatch(setInvoiceFilterCount(0));
    dispatch(setInvoicedateFilterOnInFilters(''));
    const updateFilterData = (status: any) => {
      setFilterData((oldState: any) => {
        const newState = {
          ...oldState,
          filter: {
            status: status,
          },
        };
        setSelectedInvoiceIds([]);
        setBillingTo('tenant');
        delete newState.propertyIds;
        delete newState.tenantIds;
        delete newState.amenityIds;
        delete newState.paymentStatus;
        delete newState.invoiceType;
        return newState;
      });
    };
    if (currentTab === 'draftInvoices') {
      setTitle('Draft Invoices');
      updateFilterData('draft');
    } else if (currentTab === 'approvedInvoices') {
      setTitle('Approved Invoices');
      updateFilterData('approved');
    } else if (currentTab === 'sent_to_client') {
      setTitle('Sent to client');
      updateFilterData('sent_to_client');
    } else if (currentTab === 'overdue') {
      setTitle('Invoices Overdue');
      updateFilterData('overdue');
    } else if (currentTab === 'cancelled') {
      setTitle('Cancelled Invoices');
      updateFilterData('cancelled');
    } else if (currentTab === 'history') {
      setTitle('Invoices History');
      updateFilterData('draft');
    }
  }, [searchParams, dispatch]);

  const handleTabChange = useCallback(
    (value: any) => {
      setCurrentTab(value);
      dispatch(setInvoiceDateRangeInFilters([null, null]));
      dispatch(setInvoiceFilterCount(0));
      dispatch(setInvoicedateFilterOnInFilters(''));
      searchParams.set('currentStep', value);
      setSearchParams(searchParams);
      const updateFilterData = (status: any) => {
        setFilterData((oldState: any) => {
          const newState = {
            ...oldState,
            filter: {
              status: status,
            },
          };
          setSelectedInvoiceIds([]);
          setBillingTo('tenant');
          delete newState.propertyIds;
          delete newState.tenantIds;
          delete newState.amenityIds;
          delete newState.paymentStatus;
          delete newState.invoiceType;
          return newState;
        });
      };
      if (currentTab === 'draftInvoices') {
        setTitle('Draft Invoices');
        getInvoiceStats();
        updateFilterData('draft');
      } else if (currentTab === 'approvedInvoices') {
        setTitle('Approved Invoices');
        getInvoiceStats();
        updateFilterData('approved');
      } else if (currentTab === 'sent_to_client') {
        setTitle('Sent To Client');
        getInvoiceStats();
        updateFilterData('sent_to_client');
      } else if (currentTab === 'overdue') {
        setTitle('Invoices Overdue');
        getInvoiceStats();
        updateFilterData('overdue');
      } else if (currentTab === 'cancelled') {
        setTitle('Cancelled Invoices');
        getInvoiceStats();
        updateFilterData('cancelled');
      } else if (currentTab === 'history') {
        setTitle('Invoices History');
        getInvoiceStats();
        updateFilterData('draft');
      }
    },
    [searchParams, setSearchParams, getInvoiceStats, currentTab, dispatch],
  );

  const openMoreFilterDrawerComponent = useCallback(() => {
    setIsMoreFilterDrawerOpen(true);
  }, []);

  const closeMoreFilterDrawerComponent = useCallback(() => {
    setIsMoreFilterDrawerOpen(false);
  }, []);

  const handleMoreFilterClearAll = useCallback(() => {
    dispatch(setInvoiceDateRangeInFilters([]));
    dispatch(setInvoicedateFilterOnInFilters(''));
    dispatch(setInvoiceFilterCount(0));
    setFilterData({
      ...filterData,
      filter: {
        status: 'draft',
      },
      dateFilterOn: '',
      dateRange: [],
      page: 1,
    });
  }, [dispatch, filterData]);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    let status: any;
    switch (currentTab) {
      case 'draftInvoices':
        status = 'draft';
        break;
      case 'approvedInvoices':
        status = 'approved';
        break;
      case 'sent_to_client':
        status = 'sent_to_client';
        break;
      case 'overdue':
        status = 'overdue';
        break;
      case 'cancelled':
        status = 'cancelled';
        break;
      case 'history':
        status = 'draft';
        break;
      default:
        status = 'draft';
    }

    const payload = {
      billingTo: filterData?.billingTo,
      filter: {
        status: status,
      },
    };

    try {
      CommonService._invoice
        .downloadInvoiceListAsExcel(payload)
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'invoice_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Invoice List', 'error');
          }
        })
        .catch((error: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, [currentTab, filterData]);

  const getInvoiceList = useCallback(() => {
    const payload = {
      billingTo: billingTo,
      filter: {
        status: 'approved',
      },
    };
    CommonService._invoice
      .getInvoiceListAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        const data = response?.data?.docs;
        setInvoiceList(data);
      })
      .catch((error) => {
        console.log('ERROR WHILE FETCHING INVOICE LIST');
      });
  }, [billingTo]);

  useEffect(() => {
    if (currentTab === 'approvedInvoices') {
      getInvoiceList();
    }
  }, [getInvoiceList, currentTab]);

  const handleMultipleInvoiceSelect = useCallback(() => {
    const allIds = invoiceList?.map((invoice: any) => invoice?._id);
    if (selectedInvoiceIds?.length === invoiceList?.length) {
      setSelectedInvoiceIds([]);
    } else {
      setSelectedInvoiceIds(allIds);
    }
  }, [invoiceList, selectedInvoiceIds]);

  const handleSingleInvoiceSelect = useCallback((invoiceId: string) => {
    setSelectedInvoiceIds((prevSelectedIds) =>
      prevSelectedIds.includes(invoiceId)
        ? prevSelectedIds.filter((id) => id !== invoiceId)
        : [...prevSelectedIds, invoiceId],
    );
  }, []);

  useEffect(() => {
    setSelectedInvoiceIds([]);
  }, [pageNumber]);

  const handleBulkApprove = useCallback(() => {
    const payload: any = {
      selectedIds: selectedInvoiceIds,
      isManualTrigger: true,
    };
    CommonService._invoice
      .sentToClientBulkInvoicesAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        getInvoiceStats();
        CommonService._alert.showToast(
          response[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
      })
      .catch((error: any) => {
        CommonService._alert.showToast(error, 'error');
      });
  }, [selectedInvoiceIds, getInvoiceStats]);

  return (
    <div className='invoices-list-layout-component'>
      {isInvoiceStatsLoading && (
        <div>
          <LoaderComponent />
        </div>
      )}
      {isInvoiceStatsFailed && (
        <StatusCardComponent title={'Failed to fetch Invoices List'} />
      )}
      {isInvoiceStatsLoaded && (
        <>
          <div className='invoices-list-button-group-wrappers'>
            <ButtonGroupComponent
              selected={searchParams.get('currentStep')}
              onChange={(value) => handleTabChange(value)}
              buttons={InvoicesSteps}
            />
            <div className='invoices-list-screen-options'>
              <ButtonComponent
                onClick={() => handleTabChange('history')}
                color='secondary'
                variant={currentTab === 'history' ? 'contained' : 'outlined'}
              >
                History
              </ButtonComponent>
              <LinkComponent
                route={CommonService._routeConfig.CreateInvoiceRoute()}
              >
                <ButtonComponent prefixIcon={<ImageConfig.AddOutlinedIcon />}>
                  Create Manual Invoice
                </ButtonComponent>
              </LinkComponent>
            </div>
          </div>
          <div className='list-screen'>
            <div className='list-screen-header-wrapper'>
              <div className='list-screen-header d-flex'>
                <div className='list-screen-header-title-and-count mrg-top-6 mrg-right-20'>
                  <div className='list-screen-header-title'>{title}</div>
                </div>
                <SwitchComponent
                  checked={filterData?.billingTo === 'amenity'}
                  label='Switch to Amenities'
                  onChange={(value) => {
                    if (value) {
                      setFilterData({
                        ...filterData,
                        billingTo: 'amenity',
                      });
                      setBillingTo('amenity');
                    } else {
                      setFilterData({
                        ...filterData,
                        billingTo: 'tenant',
                      });
                      setBillingTo('tenant');
                    }
                  }}
                />
              </div>
              <div className='list-options'>
                <SelectDropdownComponent
                  url={APIConfig.PROPERTY_LIST_LITE.URL}
                  method={APIConfig.PROPERTY_LIST_LITE.METHOD}
                  dataListKey='data'
                  searchable={true}
                  searchMode={'serverSide'}
                  multiple={true}
                  placeholder='Property'
                  options={allPropertyList}
                  isClearable={false}
                  displayWith={(option) => option.name}
                  valueExtractor={(option) => option._id}
                  value={filterData.propertyIds}
                  onUpdate={(value: any) => {
                    setFilterData({
                      ...filterData,
                      propertyIds: value,
                      page: 1,
                    });
                  }}
                />
                {filterData?.billingTo === 'tenant' && (
                  <SelectDropdownComponent
                    url={APIConfig.GET_TENANT_LIST.URL}
                    method={APIConfig.GET_TENANT_LIST.METHOD}
                    dataListKey='data'
                    searchable={true}
                    searchMode={'serverSide'}
                    multiple={true}
                    placeholder='Tenant'
                    options={allTenantList}
                    isClearable={false}
                    displayWith={(option) => option.name}
                    valueExtractor={(option) => option._id}
                    value={filterData.tenantIds}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        tenantIds: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {filterData?.billingTo === 'amenity' && (
                  <SelectDropdownComponent
                    url={APIConfig.GET_AMENITIES_LIST.URL}
                    method={APIConfig.GET_AMENITIES_LIST.METHOD}
                    dataListKey='data'
                    searchable={true}
                    searchMode={'serverSide'}
                    multiple={true}
                    placeholder='Amenity'
                    options={allAmenityList}
                    isClearable={false}
                    displayWith={(option) => option.name}
                    valueExtractor={(option) => option._id}
                    value={filterData.amenityIds}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        amenityIds: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {(currentTab === 'approvedInvoices' ||
                  currentTab === 'sent_to_client') && (
                  <SelectDropdownComponent
                    searchable
                    searchMode='clientSide'
                    placeholder='Status'
                    options={CommonService._staticData.ApprovedInvoiceStatus}
                    valueExtractor={(item) => item.code}
                    value={filterData.paymentStatus}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        paymentStatus: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {currentTab === 'sent_to_client' && (
                  <SelectDropdownComponent
                    searchable
                    searchMode='clientSide'
                    placeholder='Invoice Type'
                    options={CommonService._staticData.InvoiceType}
                    value={filterData.type}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        type: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {currentTab === 'history' && (
                  <ButtonComponent
                    color='secondary'
                    onClick={openMoreFilterDrawerComponent}
                    type='button'
                    className='invoice-filter-btn'
                    suffixIcon={
                      count > 0 && <BadgeComponent>{count}</BadgeComponent>
                    }
                  >
                    <div>More Filters</div>
                  </ButtonComponent>
                )}
                <ButtonComponent
                  variant='contained'
                  color='secondary'
                  prefixIcon={<ImageConfig.DownloadIcon />}
                  onClick={downloadExcel}
                >
                  {downloading ? 'Downloading....' : 'Download'}
                </ButtonComponent>
                {currentTab === 'approvedInvoices' && (
                  <ButtonComponent
                    variant='contained'
                    color='secondary'
                    onClick={handleBulkApprove}
                    disabled={selectedInvoiceIds?.length === 0}
                  >
                    Bulk Sent to Client
                  </ButtonComponent>
                )}
              </div>
            </div>
            <div className='list-screen-content'>
              <InvoicesListComponent
                selectedTab={currentTab}
                filterData={filterData}
                handleMultipleInvoiceSelect={handleMultipleInvoiceSelect}
                handleSingleInvoiceSelect={handleSingleInvoiceSelect}
                selectedInvoiceIds={selectedInvoiceIds}
                invoiceList={invoiceList}
                handlePageNumberChange={handlePageNumberChange}
              />
            </div>
          </div>
          <DrawerComponent
            closeOnEsc={false}
            closeOnBackDropClick={false}
            showClose
            isOpen={isMoreFilterDrawerOpen}
            onClose={closeMoreFilterDrawerComponent}
            title='More Filters'
            headerActions={
              <div>
                <ButtonComponent
                  onClick={handleMoreFilterClearAll}
                  type='reset'
                  variant='link'
                  color='error'
                  disabled={dateRange[0] === null || dateFilterOn.length === 0}
                >
                  Clear All
                </ButtonComponent>
              </div>
            }
          >
            <InvoiceFilterComponent
              filterData={filterData}
              setFilterData={setFilterData}
              closeFilter={closeMoreFilterDrawerComponent}
              module='invoice'
            />
          </DrawerComponent>
        </>
      )}
    </div>
  );
};

export default InvoicesListLayoutComponent;
