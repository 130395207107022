import {Outlet} from "react-router-dom";

interface EntityComponentProps {

}

const EntityComponent = (props: EntityComponentProps) => {

    return (
           <Outlet/>
    );

};

export default EntityComponent;