import "./TagComponent.scss";

interface TagComponentProps {
  label: string;
  color?: "success" | "danger" | "warning" | "default" | "info" | "primary";
  customBg?: string;
  customTextColor?: string;
  showIndicator?: boolean;
  className?: any;
}

const TagComponent = (props: TagComponentProps) => {
  const { className, customBg, customTextColor } = props;
  const { label, showIndicator } = props;
  const color = props.color || "primary";

  return (
    <div
      style={{ backgroundColor: customBg, color: customTextColor }}
      className={`tag-component ${className} ${color} }`}
    >
      {showIndicator && (
        <div
          style={{ backgroundColor: customTextColor }}
          className={`indicator ${color}`}
        />
      )}
      <div className={`label ${color}`}>{label}</div>
    </div>
  );
};

export default TagComponent;

// #####************** START USAGE **************#####
// import TagComponent from "../../shared/components/tag/TagComponent";
// <TagComponent label={'Checked In'} showIndicator={true} color={'success'}/>
// #####************** END USAGE **************#####
