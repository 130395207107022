export const GET_ENTITY_LIST_LITE = 'GET_ENTITY_LIST_LITE';
export const SET_ENTITY_LIST_LITE = 'SET_ENTITY_LIST_LITE';
export const GET_ALL_ENTITY_LIST = 'GET_ALL_ENTITY_LIST';
export const SET_ALL_ENTITY_LIST = 'SET_ALL_ENTITY_LIST';

// export const GET_ENTITY_FM_LIST = 'GET_ENTITY_FM_LIST';
// export const SET_ENTITY_FM_LIST = 'SET_ENTITY_FM_LIST';

export const getEntityListLite = () => {
  return { type: GET_ENTITY_LIST_LITE };
};

export const setEntityListLite = (entityList: any[]) => {
  return {
    type: SET_ENTITY_LIST_LITE,
    payload: {
      entityList,
    },
  };
};

export const getAllEntityList = () => {
  return { type: GET_ALL_ENTITY_LIST };
};

export const setAllEntityList = (allEntityList: any[]) => {
  return {
    type: SET_ALL_ENTITY_LIST,
    payload: {
      allEntityList,
    },
  };
};

// export const getEntityFMList = (propertyId: string) => {
//     return {
//         type: GET_ENTITY_FM_LIST, payload: {
//             propertyId
//         }
//     };
// };
//
// export const setEntityFMList = (propertyFMsList: any[]) => {
//     return {
//         type: SET_ENTITY_FM_LIST, payload: {
//             propertyFMsList
//         }
//     };
// };
