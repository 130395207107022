import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { IRootReducerState } from '../../../store/reducers';
import { getAllAmenityList } from '../../../store/actions/amenity.action';
import { getAllTenantList } from '../../../store/actions/tenant.action';
import { getAllPropertyList } from '../../../store/actions/property.action';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { CommonService } from '../../../shared/services';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import {
  setInvoiceDateRangeInFilters,
  setInvoiceFilterCount,
  setInvoicedateFilterOnInFilters,
} from '../../../store/actions/invoice.action';
import './DebitNoteListLayoutComponent.scss';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import DebitNoteListComponent from '../debit-note-list/DebitNoteListComponent';
import SwitchComponent from '../../../shared/components/form-controls/switch/SwitchComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import BadgeComponent from '../../../shared/components/badge/BadgeComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import DrawerComponent from '../../../shared/components/drawer/DrawerComponent';
import InvoiceFilterComponent from '../../invoices/invoice-filter/InvoiceFilterComponent';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';

const DebitNoteSteps = [
  {
    title: 'Approved',
    id: 'approved',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Sent to client',
    id: 'sent_to_client',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Overdue',
    id: 'overdue',
    badge: {
      text: '0',
      color: 'warning',
    },
  },
  {
    title: 'Cancelled',
    id: 'cancelled',
    badge: {
      text: '0',
      color: 'warning',
    },
  },
];

const DebitNoteListLayoutComponent = () => {
  const [isDebitNoteStatsLoading, setIsDebitNoteStatsLoading] =
    useState<boolean>(false);
  const [isDebitNoteStatsLoaded, setIsDebitNoteStatsLoaded] =
    useState<boolean>(false);
  const [isDebitNoteStatsFailed, setIsDebitNoteStatsFailed] =
    useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [title, setTitle] = useState<any | null>('Approved');
  const [currentTab, setCurrentTab] = useState<
    'approved' | 'sent_to_client' | 'overdue' | 'cancelled' | 'history'
  >('approved');
  const [isMoreFilterDrawerOpen, setIsMoreFilterDrawerOpen] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDebitNoteIds, setSelectedDebitNoteIds] = useState<string[]>(
    [],
  );
  const [debitNoteList, setDebitNoteList] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [billingTo, setBillingTo] = useState<string>('tenant');
  const dispatch = useDispatch();
  const { allTenantList } = useSelector(
    (state: IRootReducerState) => state.tenant,
  );

  const { allPropertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { allAmenityList } = useSelector(
    (state: IRootReducerState) => state.amenity,
  );
  const { count, dateFilterOn, dateRange } = useSelector(
    (state: IRootReducerState) => state.invoice,
  );
  const [filterData, setFilterData] = useState<any>({
    billingTo: 'tenant',
  });

  useEffect(() => {
    getAllAmenityList();
    getAllPropertyList();
    getAllTenantList();
  }, []);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value);
  }, []);

  const getDebitNoteStats = useCallback(() => {
    const payload = {
      billingTo: filterData?.billingTo,
    };
    setIsDebitNoteStatsLoading(true);
    setIsDebitNoteStatsLoaded(false);
    setIsDebitNoteStatsFailed(false);
    CommonService._debitnote
      .getDebitNoteStatusCount(payload)
      .then((response: any) => {
        const stats = response?.data;
        DebitNoteSteps?.forEach((step: any) => {
          step.id === 'approved' && (step.badge.text = stats?.approved);
          step.id === 'sent_to_client' &&
            (step.badge.text = stats?.sent_to_client);
          step.id === 'overdue' && (step.badge.text = stats?.overdue);
          step.id === 'cancelled' && (step.badge.text = stats?.cancelled);
        });
        setIsDebitNoteStatsLoaded(true);
        setIsDebitNoteStatsFailed(false);
      })
      .catch((err: any) => {
        setIsDebitNoteStatsLoaded(false);
        setIsDebitNoteStatsFailed(true);
        CommonService._alert.showToast(
          err[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      })
      .finally(() => {
        setIsDebitNoteStatsLoading(false);
      });
  }, [filterData?.billingTo]);

  useEffect(() => {
    getDebitNoteStats();
  }, [getDebitNoteStats]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = DebitNoteSteps.map((item: any) => {
      return item.id;
    });
    buttonsGroupArray.push('history');
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'approved';
      }
    } else {
      currentTab = 'approved';
      searchParams.set('currentStep', currentTab);
      setSearchParams(searchParams);
    }
    setCurrentTab(currentTab);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    dispatch(setInvoiceDateRangeInFilters([null, null]));
    dispatch(setInvoiceFilterCount(0));
    dispatch(setInvoicedateFilterOnInFilters(''));
    const updateFilterData = (status: any) => {
      setFilterData((oldState: any) => {
        const newState = {
          ...oldState,
          filter: {
            status: status,
          },
        };
        setSelectedDebitNoteIds([]);
        setBillingTo('tenant');
        delete newState.propertyIds;
        delete newState.tenantIds;
        delete newState.amenityIds;
        delete newState.paymentStatus;
        return newState;
      });
    };
    if (currentTab === 'approved') {
      setTitle('Approved');
      updateFilterData('approved');
    } else if (currentTab === 'sent_to_client') {
      setTitle('Sent to client');
      updateFilterData('sent_to_client');
    } else if (currentTab === 'overdue') {
      setTitle('Debit Note Overdue');
      updateFilterData('overdue');
    } else if (currentTab === 'cancelled') {
      setTitle('Cancelled Debit Notes');
      updateFilterData('cancelled');
    } else if (currentTab === 'history') {
      setTitle('Debit Note History');
      updateFilterData('draft');
    }
  }, [searchParams, dispatch]);

  const handleTabChange = useCallback(
    (value: any) => {
      dispatch(setInvoiceDateRangeInFilters([null, null]));
      dispatch(setInvoiceFilterCount(0));
      dispatch(setInvoicedateFilterOnInFilters(''));
      searchParams.set('currentStep', value);

      setSearchParams(searchParams);
      const updateFilterData = (status: any) => {
        setFilterData((oldState: any) => {
          const newState = {
            ...oldState,
            filter: {
              status: status,
            },
          };
          setSelectedDebitNoteIds([]);
          setBillingTo('tenant');
          delete newState.propertyIds;
          delete newState.tenantIds;
          delete newState.amenityIds;
          delete newState.paymentStatus;
          return newState;
        });
      };
      if (currentTab === 'approved') {
        setTitle('Approved');
        getDebitNoteStats();
        updateFilterData('approved');
      } else if (currentTab === 'sent_to_client') {
        setTitle('Sent To Client');
        getDebitNoteStats();
        updateFilterData('sent_to_client');
      } else if (currentTab === 'overdue') {
        setTitle('Debit Note Overdue');
        getDebitNoteStats();
        updateFilterData('overdue');
      } else if (currentTab === 'cancelled') {
        setTitle('Cancelled Debit Notes');
        getDebitNoteStats();
        updateFilterData('cancelled');
      } else if (currentTab === 'history') {
        setTitle('Debit Note History');
        getDebitNoteStats();
        updateFilterData('draft');
      }
      setCurrentTab(value);
    },
    [searchParams, setSearchParams, getDebitNoteStats, currentTab, dispatch],
  );

  const openMoreFilterDrawerComponent = useCallback(() => {
    setIsMoreFilterDrawerOpen(true);
  }, []);

  const closeMoreFilterDrawerComponent = useCallback(() => {
    setIsMoreFilterDrawerOpen(false);
  }, []);

  const handleMoreFilterClearAll = useCallback(() => {
    dispatch(setInvoiceDateRangeInFilters([]));
    dispatch(setInvoicedateFilterOnInFilters(''));
    dispatch(setInvoiceFilterCount(0));
    setFilterData({
      ...filterData,
      filter: {
        status: 'draft',
      },
      dateFilterOn: '',
      dateRange: [],
      page: 1,
    });
  }, [dispatch, filterData]);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    let status: any;
    switch (currentTab) {
      case 'approved':
        status = 'approved';
        break;
      case 'sent_to_client':
        status = 'sent_to_client';
        break;
      case 'overdue':
        status = 'overdue';
        break;
      case 'cancelled':
        status = 'cancelled';
        break;
      case 'history':
        status = 'draft';
        break;
      default:
        status = 'draft';
    }

    const payload = {
      billingTo: filterData?.billingTo,
      filter: {
        status: status,
      },
    };

    try {
      CommonService._debitnote
        .downloadDebitNoteListAsExcel(payload)
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'debit_note_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Debit Note List', 'error');
          }
        })
        .catch((error: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, [currentTab, filterData]);

  const getDebitNoteList = useCallback(() => {
    const payload = {
      billingTo: billingTo,
      filter: {
        status: 'approved',
      },
    };
    CommonService._debitnote
      .getDebitNoteListAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        const data = response?.data?.docs;
        setDebitNoteList(data);
      })
      .catch((error) => {
        console.log('ERROR WHILE FETCHING DEBIT NOTE LIST');
      });
  }, [billingTo]);

  useEffect(() => {
    if (currentTab === 'approved') {
      getDebitNoteList();
    }
  }, [getDebitNoteList, currentTab]);

  const handleMultipleDebitNoteSelect = useCallback(() => {
    const allIds = debitNoteList?.map((debitNote: any) => debitNote?._id);
    if (selectedDebitNoteIds?.length === debitNoteList?.length) {
      setSelectedDebitNoteIds([]);
    } else {
      setSelectedDebitNoteIds(allIds);
    }
  }, [debitNoteList, selectedDebitNoteIds]);

  useEffect(() => {
    setSelectedDebitNoteIds([]);
  }, [pageNumber]);

  const handleSingleDebitNoteSelect = useCallback((debitNoteId: string) => {
    setSelectedDebitNoteIds((prevSelectedIds) =>
      prevSelectedIds.includes(debitNoteId)
        ? prevSelectedIds.filter((id) => id !== debitNoteId)
        : [...prevSelectedIds, debitNoteId],
    );
  }, []);

  const handleBulkApprove = useCallback(() => {
    const payload: any = {
      selectedIds: selectedDebitNoteIds,
      isManualTrigger: true,
    };
    CommonService._debitnote
      .sentToClientBulkDebitNoteAPICall(payload)
      .then((response: IAPIResponseType<any>) => {
        getDebitNoteStats();
        CommonService._alert.showToast(
          response[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
      })
      .catch((error: any) => {
        CommonService._alert.showToast(error, 'error');
      });
  }, [selectedDebitNoteIds, getDebitNoteStats]);

  return (
    <div className='debitnote-list-layout-component'>
      {isDebitNoteStatsLoading && (
        <div>
          <LoaderComponent />
        </div>
      )}
      {isDebitNoteStatsFailed && (
        <StatusCardComponent title={'Failed to fetch Debit Notes'} />
      )}
      {isDebitNoteStatsLoaded && (
        <>
          <div className='d-flex-between mrg-bottom-15'>
            <ButtonGroupComponent
              selected={searchParams.get('currentStep')}
              onChange={(value) => handleTabChange(value)}
              buttons={DebitNoteSteps}
            />
            <ButtonComponent
              onClick={() => handleTabChange('history')}
              color='secondary'
              variant={currentTab === 'history' ? 'contained' : 'outlined'}
            >
              History
            </ButtonComponent>
          </div>
          <div className='list-screen'>
            <div className='list-screen-header-wrapper'>
              <div className='list-screen-header d-flex'>
                <div className='list-screen-header-title-and-count mrg-top-6 mrg-right-20'>
                  <div className='list-screen-header-title'>{title}</div>
                </div>
                <SwitchComponent
                  checked={filterData?.billingTo === 'amenity'}
                  label='Switch to Amenities'
                  onChange={(value) => {
                    if (value) {
                      setFilterData({
                        ...filterData,
                        billingTo: 'amenity',
                      });
                    } else {
                      setFilterData({
                        ...filterData,
                        billingTo: 'tenant',
                      });
                    }
                  }}
                />
              </div>

              <div className='list-options'>
                <SelectDropdownComponent
                  url={APIConfig.PROPERTY_LIST_LITE.URL}
                  method={APIConfig.PROPERTY_LIST_LITE.METHOD}
                  dataListKey='data'
                  searchable={true}
                  searchMode={'serverSide'}
                  multiple={true}
                  placeholder='Property'
                  options={allPropertyList}
                  isClearable={false}
                  displayWith={(option) => option.name}
                  valueExtractor={(option) => option._id}
                  value={filterData.propertyIds}
                  onUpdate={(value: any) => {
                    setFilterData({
                      ...filterData,
                      propertyIds: value,
                      page: 1,
                    });
                  }}
                />
                {filterData?.billingTo === 'tenant' && (
                  <SelectDropdownComponent
                    url={APIConfig.GET_TENANT_LIST.URL}
                    method={APIConfig.GET_TENANT_LIST.METHOD}
                    dataListKey='data'
                    searchable={true}
                    searchMode={'serverSide'}
                    multiple={true}
                    placeholder='Tenant'
                    options={allTenantList}
                    isClearable={false}
                    displayWith={(option) => option.name}
                    valueExtractor={(option) => option._id}
                    value={filterData.tenantIds}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        tenantIds: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {filterData?.billingTo === 'amenity' && (
                  <SelectDropdownComponent
                    url={APIConfig.GET_AMENITIES_LIST.URL}
                    method={APIConfig.GET_AMENITIES_LIST.METHOD}
                    dataListKey='data'
                    searchable={true}
                    searchMode={'serverSide'}
                    multiple={true}
                    placeholder='Amenity'
                    options={allAmenityList}
                    isClearable={false}
                    displayWith={(option) => option.name}
                    valueExtractor={(option) => option._id}
                    value={filterData.amenityIds}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        amenityIds: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {currentTab === 'sent_to_client' && (
                  <SelectDropdownComponent
                    searchable
                    searchMode='clientSide'
                    placeholder='Status'
                    options={CommonService._staticData.ApprovedInvoiceStatus}
                    valueExtractor={(item) => item.code}
                    value={filterData.paymentStatus}
                    onUpdate={(value: any) => {
                      setFilterData({
                        ...filterData,
                        paymentStatus: value,
                        page: 1,
                      });
                    }}
                  />
                )}
                {currentTab === 'history' && (
                  <ButtonComponent
                    color='secondary'
                    onClick={openMoreFilterDrawerComponent}
                    type='button'
                    className='invoice-filter-btn'
                    suffixIcon={
                      count > 0 && <BadgeComponent>{count}</BadgeComponent>
                    }
                  >
                    <div>More Filters</div>
                  </ButtonComponent>
                )}
                <ButtonComponent
                  variant='contained'
                  color='secondary'
                  prefixIcon={<ImageConfig.DownloadIcon />}
                  onClick={downloadExcel}
                >
                  {downloading ? 'Downloading....' : 'Download'}
                </ButtonComponent>
                {currentTab === 'approved' && (
                  <ButtonComponent
                    variant='contained'
                    color='secondary'
                    onClick={handleBulkApprove}
                    disabled={selectedDebitNoteIds?.length === 0}
                  >
                    Bulk Sent to Client
                  </ButtonComponent>
                )}
              </div>
            </div>
            <div className='list-screen-content'>
              <DebitNoteListComponent
                selectedTab={currentTab}
                filterData={filterData}
                handleMultipleDebitNoteSelect={handleMultipleDebitNoteSelect}
                handleSingleDebitNoteSelect={handleSingleDebitNoteSelect}
                selectedDebitNoteIds={selectedDebitNoteIds}
                debitNoteList={debitNoteList}
                handlePageNumberChange={handlePageNumberChange}
              />
            </div>
          </div>
        </>
      )}

      <DrawerComponent
        closeOnEsc={false}
        closeOnBackDropClick={false}
        showClose
        isOpen={isMoreFilterDrawerOpen}
        onClose={closeMoreFilterDrawerComponent}
        title='More Filters'
        headerActions={
          <div>
            <ButtonComponent
              onClick={handleMoreFilterClearAll}
              type='reset'
              variant='link'
              color='error'
              disabled={dateRange[0] === null || dateFilterOn.length === 0}
            >
              Clear All
            </ButtonComponent>
          </div>
        }
      >
        <InvoiceFilterComponent
          filterData={filterData}
          setFilterData={setFilterData}
          closeFilter={closeMoreFilterDrawerComponent}
          module='debitNote'
        />
      </DrawerComponent>
    </div>
  );
};

export default DebitNoteListLayoutComponent;
