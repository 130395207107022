import './VisitorListComponent.scss';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import moment from 'moment';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import React, { useCallback, useMemo, useState } from 'react';
import { ITableColumn } from '../../../shared/models/table.model';
import TagComponent from '../../../shared/components/tag/TagComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { CommonService } from '../../../shared/services';
import DrawerComponent from '../../../shared/components/drawer/DrawerComponent';
import VisitorDetailsComponent from '../visitor-details/VisitorDetailsComponent';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import VisitorEditComponent from '../visitor-edit/VisitorEditComponent';
import { useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';

interface VisitorListComponentProps {
  selectedTab: string;
  filterData: any;
  onDataLoad: (data: any) => void;
  onActionClick: () => void;
}

const VisitorListComponent = (props: VisitorListComponentProps) => {
  const { selectedTab, filterData, onDataLoad, onActionClick } = props;
  const [isVisitorDetailsDrawerOpen, setIsVisitorDetailsDrawerOpen] =
    useState<boolean>(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any | null>(null);
  const [refreshToken, setRefreshToken] = useState<number>(Math.random() * 10);
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );
  const [visitFor, setVisitFor] = useState('');

  const openVisitorDetailsDrawer = useCallback((visitor: any) => {
    setSelectedVisitor(visitor);
    setIsVisitorDetailsDrawerOpen(true);
  }, []);

  const [toggleDetails, setToggleDetails] = useState<'View' | 'Edit'>('View');
  const closeVisitorDetailsDrawer = useCallback(() => {
    // setSelectedVisitor(undefined);
    setIsVisitorDetailsDrawerOpen(false);
    setToggleDetails('View');
  }, []);

  const VisitorListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Guest Name and UID',
        key: 'uid',
        dataIndex: 'uid',
        width: 200,
        // sortable: true,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              <div className='text-transform-capitalize'>
                <>
                  {(item?.name).length > 20 ? (
                    <ToolTipComponent
                      tooltip={<div>{item?.name || 'N/A'}</div>}
                      showArrow={true}
                      position={'top'}
                    >
                      <div
                        className={
                          'ellipses-for-table-data text-transform-capitalize'
                        }
                      >
                        {item?.name}
                      </div>
                    </ToolTipComponent>
                  ) : (
                    <div>{item?.name || 'N/A'}</div>
                  )}
                </>
                <div className='content-subtext'>
                  {item?.visitCode || 'N/A'}
                </div>
              </div>
            </>
          );
        },
      },
      {
        title: 'Check In Time',
        key: 'checkin',
        dataIndex: 'checkin',
        width: 170,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item.checkIn ? (
                <div className={'date-time-card'}>
                  <div className='date-text'>
                    {moment.utc(item?.checkIn).format('YYYY-MM-DD | hh:mm A')}
                  </div>
                </div>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      },
      {
        title: 'Check Out Time',
        key: 'checkout',
        dataIndex: 'checkout',
        width: 170,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item.checkOut ? (
                <div className={'date-time-card'}>
                  <div className='date-text'>
                    {moment.utc(item?.checkOut).format('YYYY-MM-DD | hh:mm A')}
                  </div>
                </div>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      },
      {
        title: 'Host Organisation',
        key: 'tenant',
        dataIndex: 'tenant',
        width: 150,
        // fixed: "left",
        render: (item: any) => {
          return <>{item?.tenantData ? item?.tenantData?.name : 'Phoenix'}</>;
        },
      },
      {
        title: 'Guest Organisation',
        key: 'organisation',
        dataIndex: 'org',
        width: 200,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item.organization ? (
                <div className={'date-time-card'}>
                  <div className='date-text'>{item?.organization}</div>
                </div>
              ) : (
                'N/A'
              )}
            </>
          );
        },
      },
      {
        title: 'Parking',
        key: 'parking',
        dataIndex: 'parking',
        width: 100,
        // sortable: true,
        // fixed: "left",
        render: (item: any) => {
          return <div>{item?.includeParkingSpace ? 'Yes' : 'No'}</div>;
        },
      },
      {
        title: 'Guest Phone No.',
        key: 'phone',
        dataIndex: 'phone',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item?.phone ? (
                <>{CommonService.convertPhoneNumber(item?.phone)}</>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        title: 'Visitor Type',
        key: 'typeOfVisit',
        dataIndex: 'typeOfVisit',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return <>{item?.typeOfVisit}</>;
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 150,
        align: 'center',
        render: (item: any) => {
          return (
            <>
              <TagComponent
                color={CommonService.getStatusTagColor(item?.status)}
                showIndicator={true}
                label={item?.status}
              />
            </>
          );
        },
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 40,
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <div
                onClick={() => {
                  openVisitorDetailsDrawer(item);
                }}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </div>
            );
          }
        },
      },
    ];
  }, [openVisitorDetailsDrawer]);

  const RequestListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Guest Name and UID',
        key: 'uid',
        dataIndex: 'uid',
        width: 200,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              <div className=''>
                <div className='table-cell-heading text-transform-capitalize'>
                  {(item?.name).length > 20 ? (
                    <ToolTipComponent
                      tooltip={<div>{item?.name || 'N/A'}</div>}
                      showArrow={true}
                      position={'top'}
                    >
                      <div
                        className={
                          'ellipses-for-table-data text-transform-capitalize'
                        }
                      >
                        {item?.name}
                      </div>
                    </ToolTipComponent>
                  ) : (
                    <div>{item?.name || 'N/A'}</div>
                  )}
                </div>
                <div className='content-subtext'>{item?.visitCode || ''}</div>
              </div>
            </>
          );
        },
      },
      {
        title: 'Request Raised Time',
        key: 'requestRaisedTime',
        dataIndex: 'requestRaisedTime',
        width: 170,
        render: (item: any) => {
          return (
            <>
              {item?.date
                ? moment(item?.date).format('DD-MM-YYYY') + ' | ' + item.time
                : '-'}
            </>
          );
        },
      },
      {
        title: 'Host Organisation',
        key: 'tenant',
        dataIndex: 'tenant',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return <>{item?.tenantData ? item?.tenantData?.name : 'Phoenix'}</>;
        },
      },

      {
        title: 'Parking',
        key: 'parking',
        dataIndex: 'parking',
        width: 100,
        // fixed: "left",
        render: (item: any) => {
          return <div>{item?.includeParkingSpace ? 'Yes' : 'No'}</div>;
        },
      },
      {
        title: 'Guest Phone No.',
        key: 'phone',
        dataIndex: 'phone',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item?.phone ? (
                <>{CommonService.convertPhoneNumber(item?.phone)}</>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 150,
        align: 'center',
        render: (item: any) => {
          return (
            <>
              <TagComponent
                color={CommonService.getStatusTagColor(item?.status)}
                showIndicator={true}
                label={item?.status}
              />
            </>
          );
        },
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 40,
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <div
                onClick={() => {
                  openVisitorDetailsDrawer(item);
                }}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </div>
            );
          }
        },
      },
    ];
  }, [openVisitorDetailsDrawer]);

  const ScheduledListTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Guest Name and UID',
        key: 'uid',
        dataIndex: 'uid',
        width: 200,
        // sortable: true,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              <div className=''>
                <div className='table-cell-heading text-transform-capitalize'>
                  {(item?.name).length > 20 ? (
                    <ToolTipComponent
                      tooltip={<div>{item?.name || 'N/A'}</div>}
                      showArrow={true}
                      position={'top'}
                    >
                      <div
                        className={
                          'ellipses-for-table-data text-transform-capitalize'
                        }
                      >
                        {item?.name}
                      </div>
                    </ToolTipComponent>
                  ) : (
                    <div>{item?.name || 'N/A'}</div>
                  )}
                </div>
                <div className='content-subtext'>
                  {item?.visitCode || 'N/A'}
                </div>
              </div>
            </>
          );
        },
      },
      {
        title: 'Visit Date',
        key: 'visitDate',
        dataIndex: 'visitDate',
        width: 170,
        // sortable: true,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item?.date
                ? moment(item?.date).format('DD-MM-YYYY') + ' | ' + item.time
                : '-'}
            </>
          );
        },
      },
      {
        title: 'Tenant Company',
        key: 'tenant',
        dataIndex: 'tenant',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return <>{item?.tenantData ? item?.tenantData?.name : 'Phoenix'}</>;
        },
      },
      {
        title: 'Parking',
        key: 'parking',
        dataIndex: 'parking',
        width: 70,
        // sortable: true,
        // fixed: "left",
        render: (item: any) => {
          return <div>{item?.includeParkingSpace ? 'Yes' : 'No'}</div>;
        },
      },
      {
        title: 'Guest Phone No.',
        key: 'phone',
        dataIndex: 'phone',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {item?.phone ? (
                <>{CommonService.convertPhoneNumber(item?.phone)}</>
              ) : (
                '-'
              )}
            </>
          );
        },
      },

      {
        title: 'Guest Organization',
        key: 'organization',
        dataIndex: 'organization',
        // sortable: true,
        width: 150,
        // fixed: "left",
        render: (item: any) => {
          return (
            <>
              {(item?.organization).length > 30 ? (
                <ToolTipComponent
                  tooltip={<div>{item?.organization || '-'}</div>}
                  showArrow={true}
                  position={'top'}
                >
                  <div className={'ellipses-for-table-data'}>
                    {item?.organization}
                  </div>
                </ToolTipComponent>
              ) : (
                <div>{item?.organization || '-'}</div>
              )}
            </>
          );
        },
      },

      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        width: 40,
        fixed: 'right',
        render: (item: any) => {
          if (item?._id) {
            return (
              <div
                onClick={() => {
                  openVisitorDetailsDrawer(item);
                }}
              >
                <ImageConfig.ArrowRightOutlinedIcon />
              </div>
            );
          }
        },
      },
    ];
  }, [openVisitorDetailsDrawer]);

  const SuperAdminAdditionalTableColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Property Name',
        key: 'property',
        dataIndex: 'property',
        width: 120,
        // fixed: "left",
        render: (item: any) => {
          return <>{item?.propertyData ? item?.propertyData?.name : '-'}</>;
        },
      },
    ];
  }, []);

  const handleResendRequest = useCallback(() => {
    CommonService._visitor
      .handleResendRequest(selectedVisitor?._id, {})
      .then((response) => {
        CommonService._alert.showToast(
          response[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
      })
      .catch((error: any) => {
        CommonService._alert.showToast(
          error.error || 'Error deleting provider',
          'error',
        );
      });
  }, [selectedVisitor]);

  // console.log(filterData.typeOfVisit, refreshToken, 'type of visit')

  const onResendRequest = useCallback(() => {
    setIsVisitorDetailsDrawerOpen(false);
    CommonService.onConfirm({
      confirmationTitle: 'Resend Invitation',
      image: ImageConfig.ResendRequest,
      confirmationDescription: `Are you sure you want to resend the invitation
to this guest?`,
      no: {
        color: 'default',
        text: 'Cancel',
        variant: 'outlined',
      },
      yes: {
        color: 'primary',
        text: 'Confirm',
        variant: 'contained',
      },
    }).then((result: any) => {
      handleResendRequest();
    });
  }, [handleResendRequest]);

  const onActionSuccess = useCallback(() => {
    onActionClick();
  }, [onActionClick]);

  return (
    <div className={'visitor-list-component'}>
      <div className='list-table-wrapper'>
        <TableWrapperComponent
          url={APIConfig.VISITORS_LIST.URL}
          method={APIConfig.VISITORS_LIST.METHOD}
          id={'visit-requests'}
          refreshToken={refreshToken}
          onDataLoad={onDataLoad}
          onRowClick={(row) => {
            openVisitorDetailsDrawer(row);
          }}
          isPaginated={true}
          extraPayload={filterData}
          columns={
            selectedTab === 'requests'
              ? currentUser?.roleDetails?.code === 'super-admin'
                ? SuperAdminAdditionalTableColumns.concat(
                    RequestListTableColumns,
                  )
                : RequestListTableColumns
              : selectedTab === 'scheduled'
              ? currentUser?.roleDetails?.code === 'super-admin'
                ? SuperAdminAdditionalTableColumns.concat(
                    ScheduledListTableColumns,
                  )
                : ScheduledListTableColumns
              : currentUser?.roleDetails?.code === 'super-admin'
              ? SuperAdminAdditionalTableColumns.concat(VisitorListTableColumns)
              : VisitorListTableColumns
          }
          noDataText={
            filterData?.search || filterData?.typeOfVisit
              ? 'No such visitor exist'
              : filterData?.dateRange?.length
              ? 'No Visitors found in the selected date range'
              : selectedTab === 'todayVisitors'
              ? 'No visitor for today as of now'
              : selectedTab === 'requests'
              ? 'No visitor is waiting at the access points :)'
              : selectedTab === 'scheduled'
              ? 'No visitors scheduled'
              : selectedTab === 'alerts'
              ? 'All visitors have been checked out'
              : 'No visitor in the past'
          }
        />
      </div>
      <DrawerComponent
        isOpen={selectedVisitor && isVisitorDetailsDrawerOpen}
        onClose={closeVisitorDetailsDrawer}
        className={
          selectedTab !== 'scheduled'
            ? 't-drawer-details'
            : 't-drawer-details-with-action-header'
        }
        showClose={true}
        headerActions={
          <>
            {
              toggleDetails === 'View' &&
                visitFor === 'property' &&
                ((selectedTab === 'todayVisitors' &&
                  selectedVisitor?.status === 'Yet to Arrive') ||
                  (selectedTab === 'requests' &&
                    selectedVisitor?.status === 'Pending')) && (
                  // <LinkComponent route={CommonService._routeConfig.EditVisitorRoute(selectedVisitor._id)}>
                  <div
                    className={'visitor-edit-icon-wrapper-style'}
                    onClick={() => {
                      setToggleDetails('Edit');
                    }}
                  >
                    <ImageConfig.EditIcon color={'#FFFFFF'} />
                  </div>
                )
              // </LinkComponent>
            }
            {selectedTab === 'scheduled' &&
              selectedVisitor?.status !== 'Cancelled' && (
                <ButtonComponent
                  color={'default'}
                  onClick={onResendRequest}
                  variant={'outlined'}
                >
                  Resend Invitation
                </ButtonComponent>
              )}
          </>
        }
        title={'Visitor Details'}
      >
        {toggleDetails === 'View' && (
          <VisitorDetailsComponent
            setRefreshToken={() => {
              setRefreshToken(Math.random());
            }}
            selectedTab={selectedTab}
            visitor={selectedVisitor}
            hereToVisitType={(type) => {
              setVisitFor(type);
            }}
            onClose={closeVisitorDetailsDrawer}
            onActionSuccess={() => {
              onActionSuccess();
            }}
          />
        )}
        {toggleDetails === 'Edit' && (
          <VisitorEditComponent
            setRefreshToken={() => {
              setRefreshToken(Math.random());
            }}
            selectedTab={selectedTab}
            visitor={selectedVisitor}
            onClose={closeVisitorDetailsDrawer}
            onActionSuccess={() => {
              onActionSuccess();
            }}
          />
        )}
      </DrawerComponent>
    </div>
  );
};

export default VisitorListComponent;
