import "./BadgeComponent.scss";

interface BadgeComponentProps {
    color?: 'success' | 'danger' | 'warning' | 'default' | 'info' | 'primary';
    variant?: 'filled' | 'outlined';
}

const BadgeComponent = (props: React.PropsWithChildren<BadgeComponentProps>) => {

    const {children} = props;
    const color = props.color || "primary";
    const variant = props.variant || "outlined";

    return (
        <div className={`badge-component ${color} ${variant}`}>
            {children}
        </div>
    );

};

export default BadgeComponent;
