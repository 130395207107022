import React, { useCallback, useEffect, useState } from 'react';
import FormControlLabelComponent from '../../shared/components/form-control-label/FormControlLabelComponent';
import { useLocation } from 'react-router-dom';
import ButtonGroupComponent from '../../shared/components/button-group/ButtonGroupComponent';
import PropertyStatsComponent from './property-stats/PropertyStatsComponent';
import InvoiceStatsComponent from './invoice-stats/InvoiceStatsComponent';
import ForecastingStatsComponent from './forecasting-stats/ForecastingStatsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../store/reducers';
import { getAllEntityList } from '../../store/actions/entity.action';
import { getAllPropertyList } from '../../store/actions/property.action';
import { getAllTenantList } from '../../store/actions/tenant.action';
import { getAllAmenityList } from '../../store/actions/amenity.action';

const dashboardTabs = [
  {
    title: 'Property Stats',
    id: 'propertyStats',
  },
  {
    title: 'Invoices',
    id: 'invoices',
  },
  {
    title: 'Forecasting',
    id: 'foreCasting',
  },
];

const DashboardScreen = () => {
  const [currentTab, setCurrentTab] = useState<
    'propertyStats' | 'invoices' | 'foreCasting'
  >('propertyStats');
  const location = useLocation();
  const { allEntityList } = useSelector(
    (state: IRootReducerState) => state.entity,
  );
  const { allPropertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { allTenantList } = useSelector(
    (state: IRootReducerState) => state.tenant,
  );

  const { allAmenityList } = useSelector(
    (state: IRootReducerState) => state.amenity,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEntityList());
    dispatch(getAllPropertyList());
    dispatch(getAllAmenityList());
    dispatch(getAllTenantList());
  }, [dispatch]);

  const handleTabChange = useCallback((value: any) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let currentStep = searchParams.get('currentStep');

    const validTabs = dashboardTabs?.map((item) => item.id);

    if (currentStep && validTabs.includes(currentStep)) {
      setCurrentTab(
        currentStep as 'propertyStats' | 'invoices' | 'foreCasting',
      );
    } else {
      setCurrentTab('propertyStats');
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('currentStep', currentTab);
    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  }, [currentTab, location]);

  return (
    <div className='dashboard-component'>
      <FormControlLabelComponent
        label='Dashboard'
        isBold
        level={2}
        className='mrg-bottom-20'
      />
      <ButtonGroupComponent
        selected={currentTab}
        onChange={(value) => handleTabChange(value)}
        buttons={dashboardTabs}
      />
      {currentTab === 'propertyStats' && (
        <div className='mrg-top-20'>
          <PropertyStatsComponent
            entityList={allEntityList}
            propertyList={allPropertyList}
          />
        </div>
      )}
      {currentTab === 'invoices' && (
        <div className='mrg-top-20'>
          <InvoiceStatsComponent
            entityList={allEntityList}
            propertyList={allPropertyList}
            tenantList={allTenantList}
            amenityList={allAmenityList}
          />
        </div>
      )}
      {currentTab === 'foreCasting' && (
        <div className='mrg-top-20'>
          <ForecastingStatsComponent
            entityList={allEntityList}
            propertyList={allPropertyList}
            tenantList={allTenantList}
            amenityList={allAmenityList}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardScreen;
