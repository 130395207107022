import ENV from './ENV';

interface IAPIConfig {
  [k: string]: {
    URL: string | Function | any;
    METHOD: 'get' | 'post' | 'delete' | 'put';
  };
}

const APIConfig: IAPIConfig = {
  //meta start
  GENDER_LIST: {
    URL: ENV.API_URL + '/gender',
    METHOD: 'get',
  },
  LANGUAGE_LIST: {
    URL: ENV.API_URL + '/language',
    METHOD: 'get',
  },
  USER_ROLE_LIST: {
    URL: ENV.API_URL + '/userRole',
    METHOD: 'get',
  },
  //meta end

  // authentication start
  LOGIN: {
    URL: ENV.API_URL + '/login',
    METHOD: 'post',
  },
  CHECK_LOGIN: {
    URL: ENV.API_URL + '/checkLogin',
    METHOD: 'get',
  },
  LOGOUT: {
    URL: ENV.API_URL + '/login',
    METHOD: 'delete',
  },
  VERIFY_PASSWORD: {
    URL: ENV.API_URL + '/verifyPassword',
    METHOD: 'post',
  },
  // authentication end

  //Entity Apis start
  ENTITY_ADD: {
    URL: ENV.API_URL + '/billingEntity/add',
    METHOD: 'post',
  },
  ENTITY_LIST: {
    URL: ENV.API_URL + '/billingEntity/paginatedList',
    METHOD: 'post',
  },
  VIEW_ENTITY: {
    URL: (entityId: string) => ENV.API_URL + '/billingEntity/' + entityId,
    METHOD: 'get',
  },
  EDIT_ENTITY: {
    URL: (entityId: string) => ENV.API_URL + '/billingEntity/' + entityId,
    METHOD: 'put',
  },
  TOGGLE_ENTITY: {
    URL: (entityId: string) =>
      ENV.API_URL + '/billingEntity/toggle/' + entityId,
    METHOD: 'put',
  },
  ENTITY_LIST_LITE: {
    URL: ENV.API_URL + '/billingEntity/listLite',
    METHOD: 'get',
  },

  TOGGLE_PHOENIX_LOGO: {
    URL: (entityId: string) =>
      ENV.API_URL + '/billingEntity/phoenixLogoToggle/' + entityId,
    METHOD: 'put',
  },
  TOGGLE_ENTITY_LOGO: {
    URL: (entityId: string) =>
      ENV.API_URL + '/billingEntity/entityLogoToggle/' + entityId,
    METHOD: 'put',
  },
  //Entity Apis end

  // property start
  ADD_PROPERTY: {
    URL: ENV.API_URL + '/property',
    METHOD: 'post',
  },
  GET_TENANT_LIST: {
    URL: ENV.API_URL + '/tenant',
    METHOD: 'get',
  },

  ADD_CAMPUS_DETAILS: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/addCampusDetails   ',
    METHOD: 'post',
  },

  ADD_PROPERTY_TOWER_DETAILS: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/tower/bulkAdd',
    METHOD: 'post',
  },

  ADD_PROPERTY_IMAGE: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/addProfilePic',
    METHOD: 'post',
  },

  EDIT_PROPERTY: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/edit',
    METHOD: 'put',
  },

  PROPERTY_LIST: {
    URL: ENV.API_URL + '/property/paginatedList',
    METHOD: 'post',
  },
  PROPERTY_LIST_LITE: {
    URL: ENV.API_URL + '/property/list',
    METHOD: 'get',
  },
  PROPERTY_DETAILS: {
    URL: (propertyId: string) => ENV.API_URL + '/property/' + propertyId,
    METHOD: 'get',
  },
  GET_TOWER_DETAILS: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/tower/paginatedList',
    METHOD: 'post',
  },
  GET_AMENITIES_DETAILS: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/amenities/paginatedList',
    METHOD: 'post',
  },

  // GET_TENANTS_DETAILS: {
  //     URL: (propertyId: string) => ENV.API_URL + '/property/' + propertyId + '/tenants/paginatedList',
  //     METHOD: 'post'
  // },

  TOGGLE_PROPERTY: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/toggle',
    METHOD: 'put',
  },
  PROPERTY_FM_LIST: {
    URL: (propertyId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/getDefaultEmails?role=fm',
    METHOD: 'get',
  },
  USER_LIST_LITE: {
    URL: ENV.API_URL + '/user',
    METHOD: 'get',
  },
  DELETE_TOWER_DETAILS: {
    URL: (propertyId: any) =>
      ENV.API_URL + '/property/' + propertyId + '/tower',
    METHOD: 'delete',
  },
  // property end

  // visitor start
  ADD_VISITOR: {
    URL: ENV.API_URL + '/vms',
    METHOD: 'post',
  },
  EDIT_VISITOR: {
    URL: (visitor_id: any) => ENV.API_URL + '/vms/' + visitor_id,
    METHOD: 'put',
  },
  GET_TODAY_VISITOR_COUNT: {
    URL: ENV.API_URL + '/vms/stats/getTodayVisitCount',
    METHOD: 'get',
  },
  VISITORS_LIST: {
    URL: ENV.API_URL + '/vms/paginatedVisitRequests',
    METHOD: 'post',
  },
  GET_VISITOR_DETAILS: {
    URL: (visitor_id: any) => ENV.API_URL + '/vms/' + visitor_id,
    METHOD: 'get',
  },
  APPROVE_REQUEST: {
    URL: (visitor_id: any) => ENV.API_URL + '/vms/' + visitor_id + '/Approved',
    METHOD: 'put',
  },
  REJECT_REQUEST: {
    URL: (visitor_id: any) => ENV.API_URL + '/vms/' + visitor_id + '/Rejected',
    METHOD: 'put',
  },
  CANCEL_REQUEST: {
    URL: (visitor_id: any) => ENV.API_URL + '/vms/' + visitor_id + '/Cancelled',
    METHOD: 'put',
  },
  CHECK_IN_REQUEST: {
    URL: (visitor_id: any) =>
      ENV.API_URL + '/vms/auth/' + visitor_id + '/visitorCheckInWithId',
    METHOD: 'put',
  },
  CHECK_OUT_REQUEST: {
    URL: (visitor_id: any) =>
      ENV.API_URL + '/vms/auth/' + visitor_id + '/visitorCheckOutWithId',
    METHOD: 'put',
  },
  RESEND_REQUEST: {
    URL: (visitor_id: any) =>
      ENV.API_URL + '/vms/' + visitor_id + '/resendCode',
    METHOD: 'get',
  },
  DOWNLOAD_VISITOR_CSV: {
    URL: ENV.API_URL + '/vms/downloadVisitRequests',
    METHOD: 'post',
  },
  BULK_UPLOAD_USERS: {
    URL: ENV.API_URL + '/user/uploadCsv',
    METHOD: 'post',
  },
  ADD_USER: {
    URL: ENV.API_URL + '/user',
    METHOD: 'post',
  },
  EDIT_USER: {
    URL: (userId: string) => ENV.API_URL + '/user/' + userId,
    METHOD: 'put',
  },
  TOGGLE_USER: {
    URL: (userId: string) => ENV.API_URL + '/user/' + userId + '/toggle',
    METHOD: 'put',
  },
  USER_LIST: {
    URL: ENV.API_URL + '/user/paginatedList',
    METHOD: 'post',
  },
  FEEDBACK_LIST: {
    URL: ENV.API_URL + '/feedback/getPaginatedList',
    METHOD: 'post',
  },
  POLLS_LIST: {
    URL: ENV.API_URL + '/polls/paginatedList',
    METHOD: 'post',
  },
  ADD_POLL: {
    URL: ENV.API_URL + '/polls',
    METHOD: 'post',
  },
  GET_POLL_DETAILS: {
    URL: (poll_id: any) => ENV.API_URL + '/polls/' + poll_id + '/view',
    METHOD: 'get',
  },

  GET_POLL_LIST: {
    URL: ENV.API_URL + '/polls',
    METHOD: 'get',
  },
  EDIT_POLL: {
    URL: (poll_id: any) => ENV.API_URL + '/polls/' + poll_id + '/edit',
    METHOD: 'put',
  },
  CANCEL_POLL: {
    URL: (poll_id: any) => ENV.API_URL + '/polls/' + poll_id + '/cancel',
    METHOD: 'put',
  },
  POLL_TYPES: {
    URL: ENV.API_URL + '/pollTypes',
    METHOD: 'get',
  },
  GET_POLL_STATS: {
    URL: (poll_id: any) => ENV.API_URL + '/polls/' + poll_id + '/getStats',
    METHOD: 'post',
  },
  USER_DETAILS: {
    URL: (userId: string) => ENV.API_URL + '/user/' + userId,
    METHOD: 'get',
  },

  // events

  ADD_EVENT: {
    URL: ENV.API_URL + '/events',
    METHOD: 'post',
  },

  EVENTS_LIST: {
    URL: ENV.API_URL + '/events',
    METHOD: 'get',
  },
  ADD_EVENT_BOOKING: {
    URL: (event_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/addBookingDetails',
    METHOD: 'post',
  },
  ADD_EVENT_MORE_INFO: {
    URL: (event_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/addMoreDetails',
    METHOD: 'post',
  },
  ADD_EVENT_PERFORMER: {
    URL: ENV.API_URL + '/events/addPerformer',
    METHOD: 'post',
  },
  EVENT_LIST: {
    URL: ENV.API_URL + '/events/paginatedList',
    METHOD: 'post',
  },
  GET_EVENT_DETAILS: {
    URL: (event_id: any) => ENV.API_URL + '/events/' + event_id,
    METHOD: 'get',
  },
  GET_EVENT_GALLERY: {
    URL: ENV.API_URL + '/events/galleryPaginatedList',
    METHOD: 'post',
  },
  ADD_EVENT_GALLERY: {
    URL: (event_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/addEventGalleryImage',
    METHOD: 'post',
  },
  CANCEL_EVENT: {
    URL: (event_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/cancelEvent',
    METHOD: 'put',
  },
  REMOVE_EVENT_GALLERY: {
    URL: (event_id: any, image_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/deleteGalleryImage/' + image_id,
    METHOD: 'delete',
  },
  EDIT_EVENT: {
    URL: (event_id: any) => ENV.API_URL + '/events/' + event_id,
    METHOD: 'put',
  },
  ADD_EVENT_ANNOUNCEMENT: {
    URL: ENV.API_URL + 'walls',
    METHOD: 'post',
  },
  DEFAULT_IMAGE_EVENT_GALLERY: {
    URL: (event_id: any, image_id: any) =>
      ENV.API_URL + '/events/' + event_id + '/markAsDefaultPic/' + image_id,
    METHOD: 'put',
  },

  //tenants

  ADD_TENANT: {
    URL: ENV.API_URL + '/tenant',
    METHOD: 'post',
  },

  ADD_TOWER_DETAILS: {
    URL: (tenant_id: any) =>
      ENV.API_URL + '/tenant/' + tenant_id + '/addTowerOccupancy',
    METHOD: 'post',
  },

  ADD_TENANT_TIMING: {
    URL: (tenant_id: any) =>
      ENV.API_URL + '/tenant/' + tenant_id + '/addTimings',
    METHOD: 'post',
  },

  EDIT_TENANT: {
    URL: (tenant_id: any) => ENV.API_URL + '/tenant/' + tenant_id,
    METHOD: 'put',
  },

  ADD_TENANT_ADMIN: {
    URL: (tenant_id: any) =>
      ENV.API_URL + '/tenant/' + tenant_id + '/addAdminDetails',
    METHOD: 'post',
  },

  TOGGLE_TENANT: {
    URL: (tenant_id: any) => ENV.API_URL + '/tenant/' + tenant_id + '/toggle',
    METHOD: 'put',
  },

  ADD_TENANT_IMAGE: {
    URL: (tenant_id: any) =>
      ENV.API_URL + '/tenant/' + tenant_id + '/addProfilePic',
    METHOD: 'post',
  },

  DELETE_TENANT_ADMIN: {
    URL: (tenant_id: any, admin_id: any) =>
      ENV.API_URL + '/tenant/' + tenant_id + '/deleteAdminDetails/' + admin_id,
    METHOD: 'delete',
  },

  TENANT_LIST: {
    URL: ENV.API_URL + '/tenant/paginatedList',
    METHOD: 'post',
  },

  GET_TENANT_DETAILS: {
    URL: (tenant_id: any) => ENV.API_URL + '/tenant/' + tenant_id,
    METHOD: 'get',
  },

  GET_TENANT_TOWER_DETAILS: {
    URL: (propertyId: any) =>
      ENV.API_URL + '/property/' + propertyId + '/tower',
    METHOD: 'get',
  },

  GET_TENANT_BILLING_COMPONENTS: {
    URL: (tenantId: any) =>
      ENV.API_URL + '/tenant/' + tenantId + '/billingComponents',
    METHOD: 'get',
  },

  // wall start
  ANNOUNCEMENT_PAGINATED_LIST: {
    URL: ENV.API_URL + '/walls/paginatedList',
    METHOD: 'post',
  },
  ANNOUNCEMENT_DELETE: {
    URL: (announcement_id: string) =>
      ENV.API_URL + '/walls/' + announcement_id + '/delete',
    METHOD: 'delete',
  },
  MARK_ANNOUNCEMENT_RESOLVED: {
    URL: (announcement_id: string) =>
      ENV.API_URL + '/walls/' + announcement_id + '/markWallIssueResolved',
    METHOD: 'put',
  },
  GET_ANNOUNCEMENT: {
    URL: (announcementId: string) =>
      ENV.API_URL + '/walls/' + announcementId + '/view',
    METHOD: 'get',
  },

  ADD_ANNOUNCEMENT: {
    URL: ENV.API_URL + '/walls',
    METHOD: 'post',
  },
  EDIT_ANNOUNCEMENT: {
    URL: (announcementId: string) =>
      ENV.API_URL + '/walls/' + announcementId + '/edit',
    METHOD: 'put',
  },
  GET_ANNOUNCEMENT_TYPES: {
    URL: ENV.API_URL + '/announcementTypes',
    METHOD: 'get',
  },

  GET_AMENITIES_LIST: {
    URL: ENV.API_URL + '/amenity/list',
    METHOD: 'get',
  },
  GET_AMENITIES_TYPES: {
    URL: ENV.API_URL + '/getAmenityTypes',
    METHOD: 'get',
  },
  ADD_AMENITIES_DETAILS: {
    URL: ENV.API_URL + '/amenity',
    METHOD: 'post',
  },
  EDIT_AMENITIES_DETAILS: {
    URL: (amenityId: string) => ENV.API_URL + '/amenity/' + amenityId,
    METHOD: 'put',
  },

  ADD_AMENITY_OPERATOR_DETAILS: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addOperatorDetails',
    METHOD: 'post',
  },

  UPDATE_AMENITY_UNAVAILABILITY: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/updateUnAvailableDates',
    METHOD: 'post',
  },
  GET_AMENITY_DETAILS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/amenityDetails',
    METHOD: 'get',
  },

  ADD_OPERATION_DETAILS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addOperatorDetails',
    METHOD: 'post',
  },
  ADD_TIMING_DETAILS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/setTImings',
    METHOD: 'post',
  },
  GET_AMENITIES_PAGINATED_LIST: {
    URL: ENV.API_URL + '/amenity/paginatedList',
    METHOD: 'post',
  },
  GET_AMENITIES_REVIEWS_LIST: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/paginatedlist',
    METHOD: 'post',
  },
  GET_AMENITIES_GALLERY_LIST: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/getGalleryImagesPaginatedList',
    METHOD: 'post',
  },
  UPLOAD_AMENITIES_GALLERY: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addBulkGalleryImage',
    METHOD: 'post',
  },
  UPLOAD_AMENITIES_PROFILE_PIC: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addProfilePic',
    METHOD: 'post',
  },
  UPLOAD_AMENITIES_MOBILE_PIC: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addMobilePic',
    METHOD: 'post',
  },

  REMOVE_AMENITIES_GALLERY_IMAGE: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/deleteAmenityGalleryImage',
    METHOD: 'delete',
  },
  TOGGLE_AMENITY: {
    URL: (amenityId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/toggle',
    METHOD: 'put',
  },
  DELETE_USER: {
    URL: (userId: string) => ENV.API_URL + '/user/' + userId,
    METHOD: 'delete',
  },
  ADD_BILLING_DETAILS: {
    URL: (tenantId: any) =>
      ENV.API_URL + '/tenant/' + tenantId + '/addBillingComponents',
    METHOD: 'post',
  },

  // INVOICES
  GET_INVOICES_STATUS_COUNT: {
    URL: ENV.API_URL + '/invoices/statusCounts',
    METHOD: 'get',
  },
  INVOICES_LIST: {
    URL: ENV.API_URL + '/invoices/paginatedList',
    METHOD: 'post',
  },
  GET_INVOICE_NAMES: {
    URL: ENV.API_URL + '/invoices/names',
    METHOD: 'get',
  },
  CREATE_INVOICE: {
    URL: ENV.API_URL + '/invoices/add',
    METHOD: 'post',
  },
  ADD_INVOICE_DETAILS: {
    URL: (invoiceId: string) => ENV.API_URL + '/invoices/' + invoiceId,
    METHOD: 'put',
  },

  GET_INVOICE_DETAILS: {
    URL: (invoiceId: string) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/view',
    METHOD: 'get',
  },
  CANCEL_INVOICE: {
    URL: (invoiceId: any) => ENV.API_URL + '/invoices/' + invoiceId + '/cancel',
    METHOD: 'put',
  },
  MARK_AS_PAID_INVOICE: {
    URL: (invoiceId: any) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/markAsPaid',
    METHOD: 'put',
  },

  DELETE_INVOICE: {
    URL: (invoiceId: any) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/deleteInvoice',
    METHOD: 'delete',
  },
  INVOICE_HISTORY: {
    URL: ENV.API_URL + '/invoices/history',
    METHOD: 'post',
  },
  APPROVE_INVOICE: {
    URL: (invoiceId: any) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/approve',
    METHOD: 'put',
  },
  SEND_INVOICE: {
    URL: (invoiceId: any) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/sendInvoice',
    METHOD: 'put',
  },
  GENERATE_INVOICE: {
    URL: (invoiceId: any) =>
      ENV.API_URL + '/invoices/' + invoiceId + '/generateInvoice',
    METHOD: 'put',
  },

  INVOICE_BULK_SENT_TO_CLIENT: {
    URL: ENV.API_URL + '/invoices/bulk-sentToClient',
    METHOD: 'post',
  },

  GET_TENANT_ESCALATIONS: {
    URL: (tenantId: string, billingtype: string) =>
      `${ENV.API_URL}/invoices/${tenantId}/tenantEscalationsList?billingType=${billingtype}`,
    METHOD: 'get',
  },

  GET_TOWERS_LIST: {
    URL: (propertyId: any) =>
      ENV.API_URL + '/property/' + propertyId + '/tower',
    METHOD: 'get',
  },
  ADD_PROPERTY_TOWER: {
    URL: (propertyId: any) =>
      ENV.API_URL + '/property/' + propertyId + '/tower',
    METHOD: 'post',
  },
  EDIT_PROPERTY_TOWER: {
    URL: (propertyId: any) =>
      ENV.API_URL + '/property/' + propertyId + '/tower',
    METHOD: 'put',
  },
  DELETE_TENANT_ADMIN_DETAILS: {
    URL: (tenantId: string, adminId: string) =>
      ENV.API_URL + '/tenant/' + tenantId + '/deleteAdminDetails/' + adminId,
    METHOD: 'delete',
  },
  VIEW_PROPERTY_TOWER: {
    URL: (propertyId: string, towerId: string) =>
      ENV.API_URL + '/property/' + propertyId + '/tower/' + towerId,
    METHOD: 'get',
  },
  DELETE_AMENITY_ADMIN_DETAILS: {
    URL: (amenityId: string, adminId: string) =>
      ENV.API_URL + '/amenity/' + amenityId + '/deleteAdminDetails/' + adminId,
    METHOD: 'delete',
  },
  ADD_AMENITY_TOWER_DETAILS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addOccupancy',
    METHOD: 'post',
  },
  ADD_AMENITY_BILLING_DETAILS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/addBillingComponents',
    METHOD: 'post',
  },
  GET_AMENITY_BILLING_COMPONENTS: {
    URL: (amenityId: any) =>
      ENV.API_URL + '/amenity/' + amenityId + '/amenityBillingComponents',
    METHOD: 'get',
  },
  // DASHBOARD
  GET_DASHBOARD_STATISTICS: {
    URL: ENV.API_URL + '/dashboardStatistics',
    METHOD: 'get',
  },
  GET_OCCUPANY_STATS: {
    URL: ENV.API_URL + '/dashboard/occupancy',
    METHOD: 'post',
  },

  GET_TOWER_STATS: {
    URL: ENV.API_URL + '/dashboard/towerStats',
    METHOD: 'post',
  },

  GET_TENANT_STATS: {
    URL: ENV.API_URL + '/dashboard/tenantStats',
    METHOD: 'post',
  },

  GET_INVOICE_STATISTICS: {
    URL: ENV.API_URL + '/invoices/invoiceDashboard',
    METHOD: 'get',
  },

  AUTOMATED_INVOICE_STATS: {
    URL: ENV.API_URL + '/invoices/automaticInvoicesPie',
    METHOD: 'post',
  },
  MANUAL_INVOICE_STATS: {
    URL: ENV.API_URL + '/invoices/manualInvoicesPie',
    METHOD: 'post',
  },
  INVOICE_NOT_GENERATED_STATS: {
    URL: ENV.API_URL + '/invoices/invoicesNotGeneratedList',
    METHOD: 'post',
  },

  FORECAST_STATS: {
    URL: ENV.API_URL + '/invoices/forecasting',
    METHOD: 'post',
  },

  //DEBIT NOTE
  ADD_DEBITNOTE: {
    URL: ENV.API_URL + '/debitnote/add',
    METHOD: 'post',
  },
  GET_DEBITNOTE_DETAILS: {
    URL: (debitnoteId: string) =>
      ENV.API_URL + '/debitnote/' + debitnoteId + '/view',
    METHOD: 'get',
  },
  EDIT_DEBITNOTE: {
    URL: (debitNoteId: any) => ENV.API_URL + '/debitnote/' + debitNoteId,
    METHOD: 'put',
  },
  GET_DEBIT_NOTE_STATUS_COUNT: {
    URL: ENV.API_URL + '/debitnote/statusCounts',
    METHOD: 'get',
  },
  DEBITNOTE_LIST: {
    URL: ENV.API_URL + '/debitnote/paginatedList',
    METHOD: 'post',
  },
  DEBITNOTE_HISTORY: {
    URL: ENV.API_URL + '/debitnote/history',
    METHOD: 'post',
  },
  CANCEL_DEBITNOTE: {
    URL: (debitNoteId: any) =>
      ENV.API_URL + '/debitnote/' + debitNoteId + '/cancel',
    METHOD: 'put',
  },
  MARK_AS_PAID_DEBITNOTE: {
    URL: (debitNoteId: any) =>
      ENV.API_URL + '/debitnote/' + debitNoteId + '/markAsPaid',
    METHOD: 'put',
  },
  DEBITNOTE_BULK_SENT_TO_CLIENT: {
    URL: ENV.API_URL + '/debitnote/bulk-sentToClient',
    METHOD: 'post',
  },
};

export default APIConfig;
