import './AnnouncementsScreen.scss';
import React, { useCallback, useEffect, useState } from 'react';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { AddCircleOutline } from '@mui/icons-material';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, List, ListItemButton } from '@mui/material';
import { CommonService } from '../../../shared/services';
import { ImageConfig } from '../../../constants';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import { BuildingMaintenanceAndOperations } from '../../../shared/services/common.service';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounceTime, fromEvent } from 'rxjs';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';

interface AnnouncementsScreenProps {}

const AnnouncementsScreen = (props: AnnouncementsScreenProps) => {
  const navigation = useNavigate();
  const [currentTab, setCurrentTab] = useState<'all' | 'default'>('all');

  const [announcements, setAnnouncements] = useState<any[] | null>(null);
  const [defaultAnnouncements, setDefaultAnnouncements] = useState<
    any[] | null
  >(null);
  const [currentContextFor, setCurrentContextFor] = React.useState<
    null | number
  >(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const updateSteps = useCallback(
    (currentStep: string) => {
      searchParams.set('tab', currentStep);
      setSearchParams(searchParams);
      // @ts-ignore
      setCurrentTab(currentStep);
    },
    [searchParams, setSearchParams],
  );
  useEffect(() => {
    const tab: any = searchParams.get('tab');
    // if (stepperIndex >= 0) {
    updateSteps(tab || 'all');
    // }
  }, [searchParams, updateSteps]);

  const [isNormalAnnouncementLoading, setIsNormalAnnouncementLoading] =
    useState<boolean>(false);
  const [isDefaultAnnouncementLoading, setIsDefaultAnnouncementLoading] =
    useState<boolean>(false);
  const getAnnouncements = useCallback(
    (payload: any, cb: (resp: any) => void) => {
      CommonService._wall
        .getAnnouncementPaginatedList({ ...payload })
        .then((response: any) => {
          cb(response.data);
        })
        .catch((err: any) => {
          cb(null);
        })
        .finally(() => {});
    },
    [],
  );

  const [normalPagination, setNormalPagination] = useState<any>(null);
  const [defaultPagination, setDefaultPagination] = useState<any>(null);

  const loadNormalAnnouncement = useCallback(
    (payload = {}) => {
      setIsNormalAnnouncementLoading(true);
      getAnnouncements({ page: 1, ...payload, isDefault: false }, (resp) => {
        if (resp) {
          const { page, limit, pages, total } = resp;
          setAnnouncements((prevState) => {
            return page === 1
              ? resp.docs
              : [...(prevState || []), ...resp.docs];
          });
          setNormalPagination({ page, limit, pages, total });
        }
        setIsNormalAnnouncementLoading(false);
      });
    },
    [getAnnouncements],
  );

  const loadDefaultAnnouncement = useCallback(
    (payload = {}) => {
      setIsDefaultAnnouncementLoading(true);
      getAnnouncements({ page: 1, ...payload, isDefault: true }, (resp) => {
        if (resp) {
          const { page, limit, pages, total } = resp;
          setDefaultAnnouncements((prevState) => {
            return page === 1
              ? resp.docs
              : [...(prevState || []), ...resp.docs];
          });
          setDefaultPagination({ page, limit, pages, total });
        }
        setIsDefaultAnnouncementLoading(false);
      });
    },
    [getAnnouncements],
  );

  // todo: API not working. need to do infinite loading with pagination
  useEffect(() => {
    loadNormalAnnouncement();
  }, [loadNormalAnnouncement]);

  useEffect(() => {
    loadDefaultAnnouncement();
  }, [loadDefaultAnnouncement]);

  const reloadCurrentState = useCallback(() => {
    if (currentTab === 'all') {
      loadNormalAnnouncement({ page: normalPagination?.page || 1 });
    } else {
      loadDefaultAnnouncement({ page: defaultPagination?.page || 1 });
    }
  }, [
    currentTab,
    normalPagination,
    defaultPagination,
    loadDefaultAnnouncement,
    loadNormalAnnouncement,
  ]);

  const loadMoreNormalAnnouncements = useCallback(() => {
    if (normalPagination && normalPagination.pages > normalPagination.page) {
      loadNormalAnnouncement({ page: (normalPagination?.page || 1) + 1 });
    }
  }, [normalPagination, loadNormalAnnouncement]);
  const loadMoreDefaultAnnouncements = useCallback(() => {
    if (defaultPagination && defaultPagination.pages > defaultPagination.page) {
      loadDefaultAnnouncement({ page: (defaultPagination?.page || 1) + 1 });
    }
  }, [defaultPagination, loadDefaultAnnouncement]);

  useEffect(() => {
    const trackScrolling = (e: any) => {
      const rect = e.target.getBoundingClientRect();
      if (rect.height + e.target.scrollTop >= rect.bottom) {
        if (currentTab === 'all') {
          loadMoreNormalAnnouncements();
        } else {
          loadMoreDefaultAnnouncements();
        }
      }
      // console.log(rect.height + e.target.scrollTop >= rect.bottom, 'scrolling');
    };

    const el = document.getElementById('page-content-holder') as HTMLElement;

    const sub = fromEvent(el, 'scroll')
      .pipe(debounceTime(200))
      .subscribe(trackScrolling);
    return () => {
      sub.unsubscribe();
    };
  }, [currentTab, loadMoreDefaultAnnouncements, loadMoreNormalAnnouncements]);

  useEffect(() => {
    const checkOutSide = (e: any) => {
      const targetElements = document.getElementsByClassName(
        'footer-status-more-btn',
      );
      let isIn = false;
      for (let i = 0; i < targetElements.length; i++) {
        const targetElement = targetElements.item(i) as HTMLDivElement;
        if (targetElement.contains(e.target)) {
          isIn = true;
        }
      }
      if (!isIn) {
        setCurrentContextFor(null);
      }
    };

    const sub = fromEvent(document, 'mousedown').subscribe(checkOutSide);
    return () => {
      sub.unsubscribe();
    };
  }, [currentTab]);

  const deleteAnnouncement = useCallback(
    (announcementId: any) => {
      CommonService._wall
        .deleteAnnouncement(announcementId)
        .then((response: any) => {
          reloadCurrentState();
          CommonService._alert.showToast(response.message, 'success');
        })
        .catch((err: any) => {
          CommonService._alert.showToast(err.error, 'error');
        });
    },
    [reloadCurrentState],
  );

  const markAnnouncementResolved = useCallback(
    (announcementId: any) => {
      CommonService._wall
        .markAnnouncementAsResolved(announcementId)
        .then((response: any) => {
          // reloadCurrentState();
          loadNormalAnnouncement({ page: 1 });
          CommonService._alert.showToast(response.message, 'success');
        })
        .catch((err: any) => {
          CommonService._alert.showToast(err.error, 'error');
        });
    },
    [loadNormalAnnouncement],
  );

  const handleClick = (value: any) => {
    setCurrentContextFor(value);
  };
  const handleClose = useCallback(
    (mode: string, item: any) => {
      switch (mode) {
        case 'edit':
          if (currentTab === 'all') {
            navigation(
              CommonService._routeConfig.EditAnnouncementRoute(item._id),
            );
          } else {
            navigation(
              CommonService._routeConfig.EditDefaultAnnouncementRoute(item._id),
            );
          }
          break;
        case 'markResolved':
          markAnnouncementResolved(item._id);
          break;
        case 'delete':
          CommonService.onConfirm({
            yes: { color: 'error', text: 'Confirm' },
            no: { color: 'default', variant: 'outlined', text: 'Cancel' },
            confirmationTitle: 'Delete Announcement',
            image: ImageConfig.DeleteAnnouncementIcon,
            confirmationDescription:
              'Are you sure you want to delete the \r\nselected announcement?',
          })
            .then(() => {
              deleteAnnouncement(item._id);
            })
            .catch(() => {});
          break;
      }
      setTimeout((args) => {
        setCurrentContextFor(null);
      }, 50);
    },
    [currentTab, deleteAnnouncement, markAnnouncementResolved, navigation],
  );

  return (
    <div className={'announcements-screen'}>
      <div className='announcements-layout-header'>
        <ButtonGroupComponent
          selected={currentTab}
          onChange={(value: any) => {
            if (value === 'all') {
              loadNormalAnnouncement({ page: 1 });
            } else {
              loadDefaultAnnouncement({ page: 1 });
            }
            updateSteps(value);
          }}
          buttons={[
            {
              title: 'All Announcements',
              id: 'all',
            },
            {
              title: 'Default Announcements',
              id: 'default',
            },
          ]}
        />
        <div className='flex-0 '>
          <LinkComponent
            route={
              currentTab === 'all'
                ? CommonService._routeConfig.AddAnnouncementRoute()
                : CommonService._routeConfig.AddDefaultAnnouncementRoute()
            }
          >
            <ButtonComponent prefixIcon={<AddCircleOutline />}>
              {' '}
              Add {currentTab === 'all' ? 'New' : 'Default'} Announcement
            </ButtonComponent>
          </LinkComponent>
        </div>
      </div>
      <div className='announcements-list-holder mrg-top-20'>
        {currentTab === 'all' && (
          <>
            {(!announcements || announcements.length === 0) &&
              !isNormalAnnouncementLoading && (
                <div className='announcement-item'>
                  <StatusCardComponent
                    title={'No Announcements have been added yet'}
                  />
                </div>
              )}
            {announcements &&
              announcements.map((value, index) => {
                return (
                  <div className='announcement-item' key={index}>
                    {!!value.image && (
                      <div className='announcement-item-image'>
                        <img
                          alt={''}
                          className={'announcement-item-image-preview'}
                          src={value.image.url}
                        />
                      </div>
                    )}
                    <div className='announcement-item-content'>
                      <div className='announcement-item-body'>
                        <div className='announcement-item-title'>
                          {value.title}
                        </div>
                        <div className='announcement-item-description'>
                          {value.description}
                        </div>
                      </div>
                      <div className='announcement-item-footer'>
                        <div className='footer-chips'>
                          <ChipComponent
                            className={'scheduled'}
                            size={'small'}
                            color={'primary'}
                            label={value.type}
                          />
                          {(value.propertyDetails || []).length > 0 && (
                            <ToolTipComponent
                              // backgroundColor={'#fff'}
                              position={'right'}
                              textColor={'#FFFFFF'}
                              tooltip={
                                <>
                                  {value.propertyDetails.map(
                                    (value1: any, index1: number) => {
                                      return (
                                        <div
                                          className={
                                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                          }
                                          key={index1}
                                        >
                                          {value1.name}
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              }
                            >
                              <div className={'d-flex'}>
                                <ChipComponent
                                  className={'scheduled cursor-pointer'}
                                  size={'small'}
                                  color={'primary'}
                                  label={
                                    value.propertyDetails[0].name +
                                    ((value.propertyDetails || []).length > 1
                                      ? ' (+' +
                                        ((value.propertyDetails || []).length -
                                          1) +
                                        ')'
                                      : '')
                                  }
                                />
                              </div>
                            </ToolTipComponent>
                          )}

                          <ChipComponent
                            className={'scheduled font-weight-medium'}
                            size={'small'}
                            color={'primary'}
                            label={
                              'Total Interactions : ' +
                              (value.interactionsCount || 0)
                            }
                          />
                          {value.type === BuildingMaintenanceAndOperations && (
                            <ChipComponent
                              size={'small'}
                              color={value.isResolved ? 'success' : 'error'}
                              label={
                                value.isResolved ? 'Resolved' : 'Unresolved'
                              }
                            />
                          )}
                        </div>
                        <div className='footer-status'>
                          {/*<div>Last Updated on</div>*/}
                          <div className={'date-text-style'}>
                            {`Last Updated on ${moment(value.updatedAt).format(
                              'llll',
                            )}`}
                          </div>

                          <div className='footer-status-more-btn'>
                            <IconButton
                              className={'footer-menu-trigger'}
                              id='long-button'
                              onClick={handleClick.bind(null, value)}
                            >
                              <MoreVertIcon />
                              {!!currentContextFor &&
                                currentContextFor === value && (
                                  <div className='footer-menu-holder'>
                                    <List
                                      className={'footer-menu'}
                                      id={'menu-item-' + index}
                                      sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                      }}
                                    >
                                      <ListItemButton
                                        onClick={handleClose.bind(
                                          null,
                                          'edit',
                                          value,
                                        )}
                                      >
                                        Edit
                                      </ListItemButton>
                                      {!value.isResolved &&
                                        value.type ===
                                          BuildingMaintenanceAndOperations && (
                                          <ListItemButton
                                            onClick={handleClose.bind(
                                              null,
                                              'markResolved',
                                              value,
                                            )}
                                          >
                                            Mark as Resolved
                                          </ListItemButton>
                                        )}
                                      <ListItemButton
                                        className={'warn-color'}
                                        onClick={handleClose.bind(
                                          null,
                                          'delete',
                                          value,
                                        )}
                                      >
                                        Delete
                                      </ListItemButton>
                                    </List>
                                  </div>
                                )}
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className='pagination-holder mrg-top-20 mrg-bottom-20'>
              {isNormalAnnouncementLoading && (
                <LoaderComponent size={'sm'} type={'spinner'} />
              )}
              {/*    <ButtonComponent isLoading={isNormalAnnouncementLoading}*/}
              {/*                     disabled={!(normalPagination && normalPagination.pages > normalPagination.page)}*/}
              {/*                     onClick={loadMoreNormalAnnouncements}>Load More</ButtonComponent>*/}
            </div>
          </>
        )}
        {currentTab === 'default' && (
          <>
            {(!defaultAnnouncements || defaultAnnouncements.length === 0) &&
              !isDefaultAnnouncementLoading && (
                <div className='announcement-item'>
                  <StatusCardComponent
                    title={'No Default Announcements have been added yet'}
                  />
                </div>
              )}
            {defaultAnnouncements &&
              defaultAnnouncements.map((value, index) => {
                return (
                  <div className='announcement-item' key={index}>
                    {!!value.image && (
                      <div className='announcement-item-image'>
                        <img
                          alt={''}
                          className={'announcement-item-image-preview'}
                          src={value.image.url}
                        />
                      </div>
                    )}
                    <div className='announcement-item-content'>
                      <div className='announcement-item-body'>
                        <div className='announcement-item-title'>
                          {value.title}
                        </div>
                        <div className='announcement-item-description'>
                          {value.description}
                        </div>
                      </div>
                      <div className='announcement-item-footer'>
                        <div className='footer-chips'>
                          <ChipComponent
                            className={'scheduled'}
                            size={'small'}
                            color={'primary'}
                            label={value.type}
                          />

                          {(value.propertyDetails || []).length > 0 && (
                            <ToolTipComponent
                              // backgroundColor={'#fff'}
                              position={'right'}
                              textColor={'#FFFFFF'}
                              tooltip={
                                <>
                                  {value.propertyDetails.map(
                                    (value1: any, index1: number) => {
                                      return (
                                        <div
                                          className={
                                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                                          }
                                          key={index1}
                                        >
                                          {value1.name}
                                        </div>
                                      );
                                    },
                                  )}
                                </>
                              }
                            >
                              <div className={'d-flex'}>
                                <ChipComponent
                                  className={'scheduled cursor-pointer'}
                                  size={'small'}
                                  color={'primary'}
                                  label={
                                    value.propertyDetails[0].name +
                                    ((value.propertyDetails || []).length > 1
                                      ? ' (+' +
                                        ((value.propertyDetails || []).length -
                                          1) +
                                        ')'
                                      : '')
                                  }
                                />
                              </div>
                            </ToolTipComponent>
                          )}

                          <ChipComponent
                            className={'scheduled'}
                            size={'small'}
                            color={'primary'}
                            label={
                              'Total Interactions : ' +
                              (value.interactionsCount || 0)
                            }
                          />
                        </div>
                        <div className='footer-status'>
                          {moment(value.updatedAt).format('llll')}

                          <div className='footer-status-more-btn'>
                            <IconButton
                              className={'footer-menu-trigger'}
                              id='long-button'
                              onClick={handleClick.bind(null, value)}
                            >
                              <MoreVertIcon />
                              {!!currentContextFor &&
                                currentContextFor === value && (
                                  <div className='footer-menu-holder'>
                                    <List
                                      className={'footer-menu'}
                                      id={'menu-item-' + index}
                                      sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                      }}
                                    >
                                      <ListItemButton
                                        onClick={handleClose.bind(
                                          null,
                                          'edit',
                                          value,
                                        )}
                                      >
                                        Edit
                                      </ListItemButton>
                                      <ListItemButton
                                        className={'warn-color'}
                                        onClick={handleClose.bind(
                                          null,
                                          'delete',
                                          value,
                                        )}
                                      >
                                        Delete
                                      </ListItemButton>
                                    </List>
                                  </div>
                                )}
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className='pagination-holder mrg-top-20 mrg-bottom-20'>
              {isDefaultAnnouncementLoading && (
                <LoaderComponent size={'sm'} type={'spinner'} />
              )}
              {/*    <ButtonComponent isLoading={isDefaultAnnouncementLoading}*/}
              {/*                     disabled={!(defaultPagination && defaultPagination.pages > defaultPagination.page)}*/}
              {/*                     onClick={loadMoreDefaultAnnouncements}>Load More</ButtonComponent>*/}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnnouncementsScreen;
