import './EventsListComponent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { ITableColumn } from '../../../shared/models/table.model';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import { IRootReducerState } from '../../../store/reducers';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface EventsListComponentProps {}

const EventsListComponent = (props: EventsListComponentProps) => {
  const [filterData, setFilterData] = useState<any>({
    search: '',
    // sort:{},
  });
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const EventsListColumn: ITableColumn[] = [
    {
      title: 'Category',
      key: 'category',
      dataIndex: 'name',
      // fixed: 'left',
      width: 160,
      render: (item: any) => {
        return (
          <div>
            {item?.category ? (
              <div className='text-transform-capitalize'>{item?.category}</div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Event Name',
      key: 'name',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 160,
      render: (item: any) => {
        return (
          <div>
            {/* {item?.name ? <div>{item?.name}</div> : <div>-</div>} */}
            {item?.name ? (
              <>
                {item?.name?.length > 20 ? (
                  <ToolTipComponent
                    position={'right'}
                    textColor={'#FFFFFF'}
                    tooltip={
                      <div className='text-transform-capitalize'>
                        {item?.name}
                      </div>
                    }
                  >
                    <div className='d-flex'>
                      <ChipComponent
                        className={'scheduled cursor-pointer'}
                        size={'small'}
                        color={'primary'}
                        label={item?.name?.slice(0, 18) + '....'}
                      />
                    </div>
                  </ToolTipComponent>
                ) : (
                  <>
                    {CommonService.capitalizeFirstLetterOfEachWord(item?.name)}
                  </>
                )}
              </>
            ) : (
              '--'
            )}
          </div>
        );
      },
    },
    {
      title: 'Event Date',
      key: 'date',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 160,
      render: (item: any) => {
        return <div>{item?.date ? <div>{item?.date}</div> : <div>-</div>}</div>;
      },
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 160,
      render: (item: any) => {
        return (
          <div>
            {item?.location ? (
              <div className='text-transform-capitalize'>
                {item?.location?.name}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Fee',
      key: 'fee',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 160,
      render: (item: any) => {
        return (
          <div>
            {item?.bookingDetails ? (
              item?.bookingDetails?.isFree &&
              item?.bookingDetails?.isFree === true ? (
                <div>Free</div>
              ) : (
                <div>{item?.bookingDetails?.fee}</div>
              )
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Event is for',
      key: 'event_is_for',
      dataIndex: 'name',
      // fixed: 'left',
      // sortable: true,
      width: 160,
      render: (item: any) => {
        return (
          <>
            {(item?.allowedProperties || []).length > 0 && (
              <ToolTipComponent
                // backgroundColor={'#fff'}
                position={'right'}
                textColor={'#FFFFFF'}
                tooltip={
                  <>
                    {item?.allowedProperties.map(
                      (value1: any, index1: number) => {
                        return (
                          <div
                            className={
                              'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                            }
                            key={index1}
                          >
                            {value1.name}
                          </div>
                        );
                      },
                    )}
                  </>
                }
              >
                <div className={'d-flex'}>
                  <ChipComponent
                    className={'scheduled cursor-pointer'}
                    size={'small'}
                    color={'primary'}
                    label={
                      item?.allowedProperties[0].name +
                      ((item?.allowedProperties || []).length > 1
                        ? ' (+' +
                          ((item?.allowedProperties || []).length - 1) +
                          ')'
                        : '')
                    }
                  />
                </div>
              </ToolTipComponent>
            )}
          </>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      width: 160,
      render: (item: any) => {
        return (
          <>
            <TagComponent
              color={CommonService.getEventStatusTagColor(item?.status)}
              showIndicator={true}
              label={item?.status}
            />
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: 100,
      fixed: 'right',
      render: (item: any) => {
        if (item?._id) {
          return (
            <LinkComponent
              route={CommonService._routeConfig.EventDetails(item?._id)}
            >
              <ImageConfig.ArrowRightOutlinedIcon />
            </LinkComponent>
          );
        }
      },
    },
  ];

  const handleDataLoad = useCallback((data: any) => {
    // setCount(data?.total);
  }, []);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._events
        .downloadEventListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'event_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Event List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'events-list-component list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Events</div>
            {/*<div className="list-screen-data-count">*/}
            {/*    <TagComponent color={'primary'}*/}
            {/*                  label={count + ' Polls'}/>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className='list-options'>
          <SearchComponent
            placeholder='Search Event name'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          <div className={'user-list-dropdown'}>
            <SelectDropdownComponent
              placeholder={'Property'}
              options={propertyList}
              isClearable={true}
              displayWith={(option) => option.name}
              valueExtractor={(option) => option._id}
              multiple={true}
              value={filterData.propertyIds}
              onUpdate={(value: any) => {
                setFilterData({
                  ...filterData,
                  propertyIds: value,
                  page: 1,
                });
              }}
            />
          </div>
          <LinkComponent route={CommonService._routeConfig.AddEventsRoute()}>
            <ButtonComponent
              color={'primary'}
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
            >
              Add New Event
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.EVENT_LIST.URL}
          method={APIConfig.EVENT_LIST.METHOD}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          noDataText={
            filterData?.search
              ? 'No such Event exist'
              : filterData?.propertyIds
              ? 'No Events available for the selected Property(s)'
              : 'No Events have been added yet'
          }
          columns={EventsListColumn}
        />
      </div>
    </div>
  );
};

export default EventsListComponent;
