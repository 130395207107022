import React from 'react';
import { Skeleton } from '@mui/material';

const SkeletonModuleCardComponent = () => {
  return (
    <div className='module-card-component'>
      <div>
        <Skeleton
          variant='rectangular'
          width={80}
          height={10}
          className='mrg-bottom-20'
        ></Skeleton>
        <Skeleton variant='rectangular' width={120} height={10}></Skeleton>
      </div>
      <Skeleton
        variant='rounded'
        width={50}
        height={50}
        sx={{
          borderRadius: '10px',
        }}
      ></Skeleton>
    </div>
  );
};

export default SkeletonModuleCardComponent;
