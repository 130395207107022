import React from 'react';
import './InvoiceNotGeneratedCardComponent.scss';
import CardComponent from '../../card/CardComponent';
import FormControlLabelComponent from '../../form-control-label/FormControlLabelComponent';
import TableComponent from '../../table/TableComponent';
import ToolTipComponent from '../../tool-tip/ToolTipComponent';
import BadgeComponent from '../../badge/BadgeComponent';

interface InvoiceNotGeneratedCardComponentProps {
  data: any[];
  title: string;
  type: string;
}

const InvoiceNotGeneratedCardComponent = (
  props: InvoiceNotGeneratedCardComponentProps,
) => {
  const { title, data, type } = props;
  return (
    <CardComponent
      className='invoice-not-generated-card'
      wrapper={'card-component-padding'}
    >
      <div className='d-flex pdd-6'>
        <FormControlLabelComponent
          label={title}
          isBold
          level={5}
          className='pdd-left-10 mrg-right-10'
        />
        <BadgeComponent>{data.length || 0}</BadgeComponent>
      </div>
      <TableComponent
        data={data || []}
        columns={[
          {
            title:
              type === 'tenant'
                ? 'Tenant Name'
                : type === 'amenity'
                ? 'Amenity Name'
                : 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (item: any) => {
              return (
                <div className={'entity-details-wrapper'}>
                  <div className={'entity-name-wrapper'}>
                    {item?.tenantName?.length > 20 ? (
                      <ToolTipComponent
                        tooltip={item?.name}
                        showArrow={true}
                        position={'top'}
                        textColor={'#fff'}
                      >
                        <div
                          className={
                            'ellipses-for-table-data billingEntityData-name'
                          }
                        >
                          {item?.name}
                        </div>
                      </ToolTipComponent>
                    ) : (
                      <div className='property-name'>{item?.name || '-'}</div>
                    )}
                  </div>
                </div>
              );
            },
          },
          {
            title: 'Property Name',
            key: 'propertyName',
            dataIndex: 'propertyName',
            render: (item: any) => {
              return (
                <div className={'entity-details-wrapper'}>
                  <div className={'entity-name-wrapper'}>
                    {item?.propertyName?.length > 20 ? (
                      <ToolTipComponent
                        tooltip={item?.propertyName}
                        showArrow={true}
                        position={'top'}
                        textColor={'#fff'}
                      >
                        <div
                          className={
                            'ellipses-for-table-data billingEntityData-name'
                          }
                        >
                          {item?.propertyName}
                        </div>
                      </ToolTipComponent>
                    ) : (
                      <div className='property-name'>
                        {item?.propertyName || '-'}
                      </div>
                    )}
                  </div>
                </div>
              );
            },
          },
        ]}
        className={'invoice-table'}
        noDataText={`No ${title}`}
      />
    </CardComponent>
  );
};

export default InvoiceNotGeneratedCardComponent;
