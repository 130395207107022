import "./IconButtonComponent.scss";
import {useCallback} from "react";

interface IconButtonComponentProps {
    type?: "button" | "submit" | "reset";
    className?: string;
    color?: 'primary' | 'secondary' | 'error' | 'default';
    disabled?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium' | 'large';
    id?: string;
    position?: "start" | "end" | false;
    onClick?: () => void;
}

const IconButtonComponent = (props: React.PropsWithChildren<IconButtonComponentProps>) => {

    const {
        className,
        id,
        onClick,
        disabled,
        children,
    } = props;
    const color = props.color || "primary";
    // const size = props.size || "medium";
    // const type = props.type || "button";

    const handleOnClick = useCallback(() => {
        if (!disabled && onClick) {
            onClick();
        }
    }, [onClick,disabled]);

    return (
        <div
            id={id}
            className={`icon-button ${className} ${color} ${disabled ? "disabled" : ""}`}
            // size={size}
            // type={type}
            onClick={handleOnClick}
            // edge={position}
            data-testid="icon-button"
        >
            {children}
        </div>
    );

};

export default IconButtonComponent;
