import './TowerDetailsComponent.scss';
import AccordionComponent from '../accordion/AccordionComponent';
import ButtonComponent from '../button/ButtonComponent';
import { Field, FieldProps } from 'formik';
import TableComponent from '../table/TableComponent';
import SwitchComponent from '../form-controls/switch/SwitchComponent';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITableColumn } from '../../models/table.model';
import IconButtonComponent from '../icon-button/IconButtonComponent';
import { ImageConfig } from '../../../constants';
import FormikSelectDropdownComponent from '../form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import FormikInputComponent from '../form-controls/formik-input/FormikInputComponent';
import FormikRadioButtonGroupComponent from '../form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import _ from 'lodash';
import CommonService from '../../services/common.service';
import DrawerComponent from '../drawer/DrawerComponent';

interface TowerDetailsComponentProps {
  tower: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  index: number;
  type: string;
}

const TowerDetailsComponent = (props: TowerDetailsComponentProps) => {
  const { tower, index, setFieldValue, type } = props;
  const [tableTitle, setTableTitle] = useState<'Floor' | 'Basement' | any>(
    'Floor',
  );
  const [totalAvailableArea, setTotalAvailableArea] = useState<number>(0);
  const [openTowerDrawer, setOpenTowerDrawer] = useState<boolean>(false);
  const [fillBreakdown, setFillBreakdown] = useState<any[]>([]);

  const calculateTotalAvailableAreaInTower = useCallback(
    (tower: any, rowIndex?: any, type?: any) => {
      const floorArea = (tower.floorList || []).reduce(
        (sum: number, floor: any) => sum + (floor.vacantArea || 0),
        0,
      );
      const basementArea = (tower.basementList || []).reduce(
        (sum: number, basement: any) => sum + (basement.vacantArea || 0),
        0,
      );
      let totalAreaInTower = floorArea + basementArea || 0;
      if (type === 'add') {
        if (rowIndex !== undefined) {
          const breakdownItem = tower.breakdown[rowIndex];
          if (breakdownItem && breakdownItem.occupiedArea) {
            totalAreaInTower -= breakdownItem.occupiedArea;
          }
        } else {
          tower?.breakdown?.forEach((floor: any) => {
            if (floor.occupiedArea) {
              totalAreaInTower -= floor.occupiedArea;
            }
          });
        }
      }
      if (type === 'delete') {
        const deletedFloor = tower.breakdown[rowIndex];
        if (deletedFloor && deletedFloor.occupiedArea) {
          totalAreaInTower += deletedFloor.occupiedArea;
        }
      }
      return totalAreaInTower;
    },
    [],
  );

  useEffect(() => {
    const totalArea = calculateTotalAvailableAreaInTower(tower);
    setTotalAvailableArea(totalArea);
  }, [tower, calculateTotalAvailableAreaInTower]);

  const handleFillBreakdown = useCallback(
    (type: string) => {
      const filledBreakdown: any[] = _.cloneDeep(
        type === 'officeFloor' ? tower?.floorList : tower?.basementList,
      )?.map((value: any) => ({
        number:
          type === 'officeFloor' ? value.floorNumber : value.basementNumber,
        title: type === 'officeFloor' ? value.floorTitle : value.basementTitle,
      }));
      setFillBreakdown(filledBreakdown);
    },
    [tower],
  );

  const handleDrawerOpen = useCallback(() => {
    setOpenTowerDrawer(true);
    setFieldValue(`towerBreakdown[${index}].isDrawerOpen`, true);
  }, [setFieldValue, index]);

  const handleDrawerClose = useCallback(() => {
    setOpenTowerDrawer(false);
    setFieldValue(`towerBreakdown[${index}].isDrawerOpen`, false);
  }, [setFieldValue, index]);

  const handleClear = useCallback(() => {
    setFieldValue(`towerBreakdown[${index}].type`, '');
    setFieldValue(`towerBreakdown[${index}].number`, '');
    setFieldValue(`towerBreakdown[${index}].title`, '');
    setFieldValue(`towerBreakdown[${index}].vacantArea`, '');
    setFieldValue(`towerBreakdown[${index}].occupiedArea`, '');
    setFieldValue(`towerBreakdown[${index}].isHardArea`, '');
  }, [setFieldValue, index]);

  const handleSave = useCallback(() => {
    // ADDING BREAKDOWN DATA TO BREAKDOWN ARRAY
    const breakdown = {
      type: tower?.type,
      number: tower?.number,
      title: tower?.title,
      vacantArea: tower?.vacantArea,
      occupiedArea: tower?.occupiedArea,
      isHardArea: tower?.isHardArea,
    };
    const currentBreakdown = tower?.breakdown;
    const updatedBreakdown = [...currentBreakdown, breakdown];
    setFieldValue(`towerBreakdown.${index}.breakdown`, updatedBreakdown);

    //UPDATING THE OCCUPIED AREA AND VACANT AREA

    const occupiedArea = parseInt(tower?.occupiedArea);
    if (tower?.type === 'officeFloor') {
      const floorIndex = tower?.floorList.findIndex(
        (floor: any) => floor.floorNumber === tower?.number,
      );
      if (floorIndex !== -1) {
        const updatedFloorList = _.cloneDeep(tower?.floorList);
        updatedFloorList[floorIndex].vacantArea -= occupiedArea;
        updatedFloorList[floorIndex].occupiedArea += occupiedArea;
        setFieldValue(`towerBreakdown.${index}.floorList`, updatedFloorList);
      }
    } else if (tower?.type === 'basement') {
      const basementIndex = tower.basementList.findIndex(
        (basement: any) => basement.basementNumber === tower?.number,
      );
      if (basementIndex !== -1) {
        const updatedBasementList = _.cloneDeep(tower?.basementList);
        updatedBasementList[basementIndex].vacantArea -= occupiedArea;
        updatedBasementList[basementIndex].occupiedArea += occupiedArea;
        setFieldValue(
          `towerBreakdown.${index}.basementList`,
          updatedBasementList,
        );
      }
    }
    setFieldValue(`type`, undefined);
    setTotalAvailableArea(
      calculateTotalAvailableAreaInTower(
        { ...tower, breakdown: updatedBreakdown },
        undefined,
        'add',
      ),
    );
    handleClear();
    handleDrawerClose();
  }, [
    setFieldValue,
    tower,
    handleClear,
    index,
    calculateTotalAvailableAreaInTower,
    handleDrawerClose,
  ]);

  const handleDeleteFloor = useCallback(
    (rowIndex: any) => {
      const breakdownItem = tower.breakdown[rowIndex];
      const occupiedArea = parseInt(breakdownItem.occupiedArea);

      if (breakdownItem?.type === 'officeFloor') {
        const floorIndex = tower.floorList.findIndex(
          (floor: any) => floor.floorNumber === breakdownItem.number,
        );
        if (floorIndex !== -1) {
          const updatedFloorList = _.cloneDeep(tower?.floorList);
          updatedFloorList[floorIndex].vacantArea += occupiedArea;
          updatedFloorList[floorIndex].occupiedArea -= occupiedArea;
          setFieldValue(`towerBreakdown.${index}.floorList`, updatedFloorList);
        }
      }
      if (breakdownItem?.type === 'basement') {
        const basementIndex = tower.basementList.findIndex(
          (basement: any) => basement.basementNumber === breakdownItem.number,
        );
        if (basementIndex !== -1) {
          const updatedBasementList = _.cloneDeep(tower?.basementList);
          updatedBasementList[basementIndex].vacantArea += occupiedArea;
          updatedBasementList[basementIndex].occupiedArea -= occupiedArea;
          setFieldValue(
            `towerBreakdown.${index}.basementList`,
            updatedBasementList,
          );
        }
      }
      const updatedBreakdown = _.cloneDeep(tower?.breakdown);
      updatedBreakdown.splice(rowIndex, 1);
      setFieldValue(`towerBreakdown[${index}].breakdown`, updatedBreakdown);
      setTotalAvailableArea(
        calculateTotalAvailableAreaInTower(
          { ...tower, breakdown: updatedBreakdown },
          rowIndex,
          'delete',
        ),
      );
    },
    [setFieldValue, tower, calculateTotalAvailableAreaInTower, index],
  );

  const TowerColumn = useMemo<ITableColumn[]>(() => {
    return [
      {
        title: 'Type',
        key: 'floor-type',
        dataIndex: 'floor-type',
        render: (item: any) => {
          return (
            <div>
              {item.type === 'officeFloor' ? 'Office Floor' : 'Basement'}
            </div>
          );
        },
      },
      {
        title: 'Number',
        key: 'number',
        dataIndex: 'number',
        render: (item: any) => {
          return <div>{item.title}</div>;
        },
      },
      {
        title: 'Vacant Area',
        key: 'vacant-area',
        dataIndex: 'vacant-area',
        render: (item: any) => {
          return <div>{item.vacantArea}</div>;
        },
      },
      {
        title:
          type === 'tenant'
            ? 'Area occupied by tenant'
            : 'Area occupied by amenity',
        key: 'occupied-area',
        dataIndex: 'occupied-area',
        width: 180,
        render: (item: any) => {
          return <div>{item.occupiedArea}</div>;
        },
      },
      {
        title: 'Hard Option',
        key: 'hard-option',
        dataIndex: 'hard-option',
        width: 100,
        render: (item: any) => {
          return <div>{item?.isHardArea ? 'Yes' : 'No'}</div>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        render: (item: any, rowIndex: number) => {
          return (
            <div>
              <IconButtonComponent
                color='error'
                onClick={handleDeleteFloor.bind(null, rowIndex)}
              >
                <ImageConfig.CircleCancel />
              </IconButtonComponent>
            </div>
          );
        },
      },
    ];
  }, [handleDeleteFloor, type]);

  return (
    <div className={'tower-details-component'}>
      <AccordionComponent
        disabled={tower?.isTotalTowerOccupied}
        actionHeader={
          <div>
            <SwitchComponent
              color={'primary'}
              onChange={(value: any) => {
                setFieldValue(
                  `towerBreakdown.${index}.isTowerSelected`,
                  !tower?.isTowerSelected,
                );
              }}
              checked={tower?.isTowerSelected}
              label={''}
              className='tower-switch'
            />
          </div>
        }
        title={tower?.name}
        // actions={
        //   <div className='accordion-action'>
        //     {tower?.isTowerSelected && (
        //       <Field name={`towerBreakdown.${index}.isTotalTowerOccupied`}>
        //         {(field: FieldProps) => (
        //           <FormikCheckBoxComponent
        //             formikField={field}
        //             label={'Occupied Full tower'}
        //             onChange={(value: any) => {
        //               if (value) {
        //                 setFieldValue(
        //                   `towerBreakdown.${index}.totalArea`,
        //                   value
        //                     ? calculateTotalAvailableAreaInTower(tower)
        //                     : '',
        //                 );
        //               } else {
        //                 setTotalAvailableArea(
        //                   calculateTotalAvailableAreaInTower(tower),
        //                 );
        //               }
        //             }}
        //           />
        //         )}
        //       </Field>
        //     )}
        //   </div>
        // }
      >
        {tower?.isTowerSelected && !tower?.isTotalTowerOccupied && (
          <div className='tower-wrapper'>
            <div className='tower-occupied-button-wrapper'>
              <div className='tower-occupied-wrapper'>
                <div className='tower-occupied-wrapper-title'>
                  Total area Available in the Towers
                </div>
                <div className='tower-occupied-wrapper-value'>
                  <>{totalAvailableArea} </>
                  <>sq.ft</>
                </div>
              </div>
              <div>
                <ButtonComponent
                  variant='outlined'
                  color='primary'
                  onClick={handleDrawerOpen}
                >
                  Add More
                </ButtonComponent>
              </div>
            </div>
            <div className='tower-table-view scroll-content'>
              <TableComponent
                columns={TowerColumn}
                data={tower?.breakdown}
                noDataText='No Tower Breakdown'
              />
            </div>
          </div>
        )}
      </AccordionComponent>
      <DrawerComponent
        isOpen={openTowerDrawer}
        onClose={handleDrawerClose}
        showClose={true}
        closeOnBackDropClick={false}
        closeOnEsc={false}
        title='Tower Breakdown'
      >
        <div className='drawer-body-height'>
          <div className='mrg-top-20'>
            <Field name={`towerBreakdown.${index}.type`}>
              {(field: FieldProps) => (
                <FormikSelectDropdownComponent
                  label='Type'
                  fullWidth
                  formikField={field}
                  options={CommonService._staticData.floorTypeOptions}
                  valueExtractor={(option: any) => option.id}
                  onUpdate={(value: any) => {
                    setFieldValue(
                      `towerBreakdown[${index}].type`,
                      value ? value : '',
                    );
                    if (value) {
                      handleFillBreakdown(value);
                      const title =
                        value === 'officeFloor'
                          ? 'Office Floors'
                          : value === 'basement'
                          ? 'Basements'
                          : 'Office Floors';
                      setTableTitle(title);
                      setFieldValue(`towerBreakdown[${index}].number`, '');
                      setFieldValue(`towerBreakdown[${index}].title`, '');
                      setFieldValue(`towerBreakdown[${index}].vacantArea`, '');
                    }
                  }}
                />
              )}
            </Field>
          </div>
          <div className='mrg-top-20'>
            <Field name={`towerBreakdown[${index}].number`}>
              {(field: FieldProps) => (
                <FormikSelectDropdownComponent
                  label={tableTitle}
                  fullWidth
                  formikField={field}
                  options={fillBreakdown}
                  displayWith={(option: any) => option.title}
                  valueExtractor={(option: any) => option.number}
                  disabled={!tower?.type}
                  onUpdate={(value) => {
                    if (value) {
                      setFieldValue(`towerBreakdown[${index}].vacantArea`, '');
                      setFieldValue(
                        `towerBreakdown[${index}].occupiedArea`,
                        '',
                      );
                      const updatedTitle = fillBreakdown?.find(
                        (item: any) => item.number === value,
                      );
                      if (tableTitle === 'Office Floors') {
                        setFieldValue(`towerBreakdown[${index}].number`, value);
                        setFieldValue(
                          `towerBreakdown[${index}].title`,
                          updatedTitle.title,
                        );
                        const floorObj = tower.floorList.find(
                          (floor: any) => floor.floorNumber === value,
                        );
                        setFieldValue(
                          `towerBreakdown[${index}].vacantArea`,
                          floorObj.vacantArea,
                        );
                      } else {
                        setFieldValue(`towerBreakdown[${index}].number`, value);
                        setFieldValue(
                          `towerBreakdown[${index}].title`,
                          updatedTitle.title,
                        );
                        const basementObj = tower.basementList.find(
                          (floor: any) => floor.basementNumber === value,
                        );
                        setFieldValue(
                          `towerBreakdown[${index}].vacantArea`,
                          basementObj.vacantArea,
                        );
                      }
                    }
                  }}
                />
              )}
            </Field>
          </div>
          <div className='mrg-top-20'>
            <Field name={`towerBreakdown[${index}].vacantArea`}>
              {(field: FieldProps) => (
                <FormikInputComponent
                  label='Vacant Area'
                  fullWidth
                  formikField={field}
                  readOnly
                  placeholder=''
                />
              )}
            </Field>
          </div>
          <div className='mrg-top-20'>
            <Field name={`towerBreakdown[${index}].occupiedArea`}>
              {(field: FieldProps) => (
                <FormikInputComponent
                  label={
                    type === 'tenant'
                      ? 'Area Occupied by tenant'
                      : 'Area Occupied by amenity'
                  }
                  fullWidth
                  formikField={field}
                  disabled={tower?.vacantArea === 0}
                  validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                  onChange={(value) => {
                    setFieldValue(
                      `towerBreakdown[${index}].occupiedArea`,
                      value ? parseInt(value) : '',
                    );
                  }}
                />
              )}
            </Field>
          </div>
          <div className='mrg-top-20'>
            <Field name={`towerBreakdown[${index}].isHardArea`}>
              {(field: FieldProps) => (
                <FormikRadioButtonGroupComponent
                  label='Is Hard Option'
                  options={CommonService._staticData.yesNoOptions}
                  formikField={field}
                  keyExtractor={(option: any) => option.title}
                  className='mrg-top-20'
                />
              )}
            </Field>
          </div>
        </div>
        <div className='d-flex-between'>
          <ButtonComponent
            fullWidth
            variant='outlined'
            color='error'
            onClick={handleClear}
          >
            Clear
          </ButtonComponent>
          <ButtonComponent
            fullWidth
            variant='contained'
            onClick={handleSave}
            disabled={
              !tower?.type ||
              !tower?.number ||
              !tower?.title ||
              !tower?.occupiedArea ||
              tower?.occupiedArea > tower?.vacantArea ||
              (tower?.isHardArea !== true && tower?.isHardArea !== false) ||
              !tower?.vacantArea
            }
          >
            Save
          </ButtonComponent>
        </div>
      </DrawerComponent>
    </div>
  );
};

export default TowerDetailsComponent;
