import './VmsListLayoutComponent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ButtonGroupComponent from '../../../shared/components/button-group/ButtonGroupComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../constants';
import VisitorListComponent from '../visitor-list/VisitorListComponent';
import LoaderComponent from '../../../shared/components/loader/LoaderComponent';
import StatusCardComponent from '../../../shared/components/status-card/StatusCardComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import moment from 'moment';
import TagComponent from '../../../shared/components/tag/TagComponent';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import DateRangePickerComponent from '../../../shared/components/form-controls/date-range-picker/DateRangePickerComponent';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { IRootReducerState } from '../../../store/reducers';

interface VmsListLayoutComponentProps {}

const VisitorDetailsSteps = [
  {
    title: 'Today’s Visitors',
    id: 'todayVisitors',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Today’s Requests',
    id: 'requests',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
  {
    title: 'Pending Checkouts',
    id: 'alerts',
    badge: {
      text: '0',
      color: 'primary',
    },
  },
];

const VmsListLayoutComponent = (props: VmsListLayoutComponentProps) => {
  const [isVisitorStatsLoading, setIsVisitorStatsLoading] =
    useState<boolean>(false);
  const [isVisitorStatsLoaded, setIsVisitorStatsLoaded] =
    useState<boolean>(false);
  const [isVisitorStatsFailed, setIsVisitorStatsFailed] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<
    'todayVisitors' | 'requests' | 'alerts' | 'scheduled' | 'history'
  >('todayVisitors');
  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<any | null>('Visitor Management');
  const [count, setCount] = useState<number>(0);
  const [isCSVDownloading, setIsCSVDownloading] = useState<boolean>(false);
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const [filterData, setFilterData] = useState<any>({
    dateRange: [],
    search: '',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    typeOfVisit: null,
  });

  const getVisitorStats = useCallback(() => {
    const payload = {};
    setIsVisitorStatsLoading(true);
    setIsVisitorStatsLoaded(false);
    setIsVisitorStatsFailed(false);
    CommonService._visitor
      .getTodayVisitorCount(payload)
      .then((response: any) => {
        const stats = response?.data;
        VisitorDetailsSteps?.forEach((step: any) => {
          step.id === 'todayVisitors' && (step.badge.text = stats?.totalCount);
          step.id === 'requests' && (step.badge.text = stats?.pendingCount);
          step.id === 'alerts' && (step.badge.text = stats?.alertCount);
        });
        setIsVisitorStatsLoaded(true);
        setIsVisitorStatsFailed(false);
      })
      .catch((err: any) => {
        setIsVisitorStatsLoaded(false);
        setIsVisitorStatsFailed(true);
        CommonService._alert.showToast(
          err[Misc.API_RESPONSE_MESSAGE_KEY],
          'error',
        );
      })
      .finally(() => {
        setIsVisitorStatsLoading(false);
      });
  }, []);

  const downloadCSV = useCallback(() => {
    const payload = { ...filterData };
    setIsCSVDownloading(true);
    CommonService._visitor
      .downloadVisitorCSV(payload)
      .then((response: IAPIResponseType<any>) => {
        setIsCSVDownloading(false);
        if (response && response?.data && response?.data?.url) {
          CommonService.downloadFile(response?.data?.url, 'visitor.csv', 'csv');
        }
      })
      .catch((error: any) => {
        setIsCSVDownloading(false);
      });
  }, [filterData]);

  useEffect(() => {
    getVisitorStats();
  }, [getVisitorStats]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    const buttonsGroupArray = VisitorDetailsSteps.map((item: any) => {
      return item.id;
    });
    buttonsGroupArray.push('scheduled', 'history');
    if (currentTab) {
      if (!buttonsGroupArray.includes(currentTab)) {
        currentTab = 'todayVisitors';
      }
    } else {
      currentTab = 'todayVisitors';
      searchParams.set('currentStep', currentTab);
      setSearchParams(searchParams);
    }
    setCurrentTab(currentTab);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    let currentTab: any = searchParams.get('currentStep');
    if (currentTab === 'todayVisitors') {
      setTitle('Visitor Management');
      setFilterData((oldState: any) => {
        const newState = { ...oldState };
        newState['ignorePending'] = true;
        return newState;
      });
    } else if (currentTab === 'requests') {
      setTitle("Today's Visitor Requests");
      setFilterData((oldState: any) => {
        const newState = { ...oldState };
        newState['status'] = 'Pending';
        return newState;
      });
    } else if (currentTab === 'alerts') {
      setTitle('Pending Checkouts');
      setFilterData((oldState: any) => {
        const newState = { ...oldState };
        newState['showAlerted'] = true;
        delete newState.startDate;
        delete newState.endDate;
        delete newState.dateRange;
        return newState;
      });
    } else if (currentTab === 'scheduled') {
      setTitle('Scheduled Visits');
      setFilterData((oldState: any) => {
        const newState = { ...oldState };
        newState['scheduledVisit'] = true;
        delete newState.startDate;
        delete newState.endDate;
        delete newState.dateRange;
        return newState;
      });
    } else if (currentTab === 'history') {
      setTitle('Visitor History');
      setFilterData((oldState: any) => {
        const newState = { ...oldState };
        newState['showHistory'] = true;
        delete newState.startDate;
        delete newState.endDate;
        delete newState.dateRange;
        return newState;
      });
    }
  }, [searchParams]);

  const handleTabChange = useCallback(
    (value: any) => {
      setCurrentTab(value);
      searchParams.set('currentStep', value);
      setSearchParams(searchParams);
      if (value === 'todayVisitors') {
        setTitle('Visitor Management');
        getVisitorStats();
        setFilterData((oldState: any) => {
          const newState = { ...oldState };
          newState['startDate'] = moment().format('YYYY-MM-DD');
          newState['endDate'] = moment().format('YYYY-MM-DD');
          newState['ignorePending'] = true;
          newState['typeOfVisit'] = null;
          delete newState.status;
          delete newState.showAlerted;
          delete newState.scheduledVisit;
          delete newState.dateRange;
          delete newState.showHistory;
          delete newState.search;
          return newState;
        });
      } else if (value === 'requests') {
        setTitle('Visitor Requests');
        getVisitorStats();
        setFilterData((oldState: any) => {
          const newState = { ...oldState };
          newState['startDate'] = moment().format('YYYY-MM-DD');
          newState['endDate'] = moment().format('YYYY-MM-DD');
          newState['status'] = 'Pending';
          delete newState.showAlerted;
          delete newState.scheduledVisit;
          delete newState.dateRange;
          delete newState.ignorePending;
          delete newState.showHistory;
          delete newState.search;
          return newState;
        });
      } else if (value === 'alerts') {
        setTitle('Alerts');
        getVisitorStats();
        setFilterData((oldState: any) => {
          const newState = { ...oldState };
          newState['showAlerted'] = true;
          newState['typeOfVisit'] = null;
          delete newState.status;
          delete newState.startDate;
          delete newState.endDate;
          delete newState.scheduledVisit;
          delete newState.showHistory;
          delete newState.dateRange;
          delete newState.ignorePending;
          delete newState.search;
          return newState;
        });
      } else if (value === 'scheduled') {
        setTitle('Scheduled Visits');
        getVisitorStats();
        setFilterData((oldState: any) => {
          const newState = { ...oldState };
          newState['scheduledVisit'] = true;
          newState['typeOfVisit'] = null;
          delete newState.status;
          delete newState.showHistory;
          delete newState.startDate;
          delete newState.endDate;
          delete newState.showAlerted;
          delete newState.dateRange;
          delete newState.ignorePending;
          delete newState.search;
          return newState;
        });
      } else if (value === 'history') {
        setTitle('Visitor History');
        getVisitorStats();
        setFilterData((oldState: any) => {
          const newState = { ...oldState };
          newState['showHistory'] = true;
          newState['typeOfVisit'] = null;
          delete newState.status;
          delete newState.showAlerted;
          delete newState.scheduledVisit;
          delete newState.ignorePending;
          delete newState.dateRange;
          delete newState.startDate;
          delete newState.endDate;
          delete newState.search;
          return newState;
        });
      }
    },
    [searchParams, setSearchParams, getVisitorStats],
  );

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  return (
    <div className={'vms-list-layout-component'}>
      {isVisitorStatsLoading && (
        <div>
          <LoaderComponent />
        </div>
      )}
      {isVisitorStatsFailed && (
        <StatusCardComponent title={'Failed to fetch Property List'} />
      )}
      {isVisitorStatsLoaded && (
        <>
          <div className='list-screen-button-group-wrappers'>
            <ButtonGroupComponent
              selected={searchParams.get('currentStep')}
              onChange={(value) => handleTabChange(value)}
              buttons={VisitorDetailsSteps}
            />
            <div className='list-screen-options'>
              <ButtonComponent
                onClick={() => handleTabChange('scheduled')}
                prefixIcon={<ImageConfig.CalenderOutlinedCheckIcon />}
                variant={currentTab === 'scheduled' ? 'contained' : 'outlined'}
              >
                Scheduled Visits
              </ButtonComponent>
              <ButtonComponent
                onClick={() => handleTabChange('history')}
                prefixIcon={<ImageConfig.ClockOutlinedBackWordIcon />}
                variant={currentTab === 'history' ? 'contained' : 'outlined'}
              >
                History
              </ButtonComponent>
              <LinkComponent
                route={CommonService._routeConfig.AddVisitorRoute()}
              >
                <ButtonComponent prefixIcon={<ImageConfig.AddOutlinedIcon />}>
                  Add Visitor
                </ButtonComponent>
              </LinkComponent>
              <ButtonComponent
                color={'secondary'}
                onClick={downloadCSV}
                disabled={isCSVDownloading}
                prefixIcon={<ImageConfig.DownloadIcon />}
              >
                CSV
              </ButtonComponent>
            </div>
          </div>
          <div className='list-screen'>
            <div className='list-screen-header-wrapper'>
              <div className='list-screen-header'>
                <div className='list-screen-header-title-and-count'>
                  <div className='list-screen-header-title'>{title}</div>
                  <div className='list-screen-data-count'>
                    <TagComponent
                      color={'primary'}
                      label={count + ' visitors'}
                    />
                  </div>
                </div>
                <div className='list-screen-header-description'>
                  See the{' '}
                  {currentTab === 'todayVisitors' || currentTab === 'requests'
                    ? 'list of today’s'
                    : 'history of'}{' '}
                  visitors in your facility.
                </div>
              </div>
              <div className='list-options'>
                <SearchComponent
                  placeholder='Search Guest'
                  value={filterData.search || ''}
                  onSearchChange={(value) => {
                    setFilterData({ ...filterData, search: value, page: 1 });
                  }}
                />
                {currentTab !== 'requests' && (
                  <>
                    {currentTab === 'history' && (
                      <DateRangePickerComponent
                        placeholder={'Date Range'}
                        value={filterData.dateRange}
                        clearable={true}
                        maxDate={moment().subtract(1, 'day').toDate()}
                        onChange={(value: any) => {
                          if (value) {
                            setFilterData({
                              ...filterData,
                              startDate:
                                value[0] &&
                                moment(value[0]).format('YYYY-MM-DD'),
                              endDate:
                                value[1] &&
                                moment(value[1]).format('YYYY-MM-DD'),
                              dateRange: value,
                              page: 1,
                            });
                          }
                        }}
                        size={'medium'}
                      />
                    )}
                    {currentTab !== 'scheduled' && (
                      <SelectDropdownComponent
                        options={CommonService._staticData.visitorTypeOptions}
                        placeholder={'Visitor Type'}
                        value={filterData.typeOfVisit || null}
                        valueExtractor={(item) => item.code}
                        onUpdate={(value: any) => {
                          setFilterData({
                            ...filterData,
                            typeOfVisit: value,
                            page: 1,
                          });
                        }}
                      />
                    )}
                    <div className={'user-list-small-dropdown'}>
                      <SelectDropdownComponent
                        options={CommonService._staticData.visitTypeOptions}
                        placeholder={'Type'}
                        value={filterData.hereToVisitFor || null}
                        valueExtractor={(item) => item.code}
                        onUpdate={(value: any) => {
                          setFilterData({
                            ...filterData,
                            hereToVisitFor: value,
                            page: 1,
                          });
                        }}
                      />
                    </div>
                    <div className={'user-list-dropdown'}>
                      <SelectDropdownComponent
                        placeholder={'Property'}
                        // searchMode={'clientSide'}
                        // searchable={true}
                        options={propertyList}
                        isClearable={false}
                        displayWith={(option) => option.name}
                        valueExtractor={(option) => option._id}
                        // required={true}
                        multiple={true}
                        value={filterData.propertyIds}
                        // fullWidth={true}
                        onUpdate={(value: any) => {
                          setFilterData({
                            ...filterData,
                            propertyIds: value,
                            page: 1,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='list-screen-content'>
              <VisitorListComponent
                onDataLoad={handleDataLoad}
                selectedTab={currentTab}
                filterData={filterData}
                onActionClick={() => {
                  getVisitorStats();
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VmsListLayoutComponent;
