import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const getUserList = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_LIST.METHOD](
    APIConfig.USER_LIST.URL,
    payload,
  );
};

const getUserListLite = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_LIST_LITE.METHOD](
    APIConfig.USER_LIST_LITE.URL,
    payload,
  );
};

const getUserAvailableDatesList = (providerId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_AVAILABLE_DATES_LIST.METHOD](
    APIConfig.USER_AVAILABLE_DATES_LIST.URL(providerId),
  );
};

const getUserAvailableTimesList = (providerId: string, date: string) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_AVAILABLE_TIMES_LIST.METHOD](
    APIConfig.USER_AVAILABLE_TIMES_LIST.URL(providerId, date),
  );
};

const bulkUploadUsersAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.BULK_UPLOAD_USERS.METHOD](
    APIConfig.BULK_UPLOAD_USERS.URL,
    payload,
    {
      'Content-Type': 'multipart/form-data',
    },
  );
};

const addUserAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_USER.METHOD](APIConfig.ADD_USER.URL, payload);
};

const editUserAPICall = (payload: any, userId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_USER.METHOD](
    APIConfig.EDIT_USER.URL(userId),
    payload,
  );
};

const toggleUserAPICall = (userId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.TOGGLE_USER.METHOD](
    APIConfig.TOGGLE_USER.URL(userId),
  );
};

const userDetailsAPICall = (userId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.USER_DETAILS.METHOD](
    APIConfig.USER_DETAILS.URL(userId),
    payload,
  );
};

const deleteUserAPICall = (userId: string) => {
  // @ts-ignore
  return ApiService[APIConfig.DELETE_USER.METHOD](
    APIConfig.DELETE_USER.URL(userId),
  );
};

const downloadUserListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.USER_LIST.METHOD](
    APIConfig.USER_LIST.URL + `?download=true`,
  );
};

const UserService = {
  getUserList,
  getUserListLite,
  getUserAvailableDatesList,
  getUserAvailableTimesList,
  bulkUploadUsersAPICall,
  addUserAPICall,
  toggleUserAPICall,
  userDetailsAPICall,
  editUserAPICall,
  deleteUserAPICall,
  downloadUserListAsExcel,
};

export default UserService;
