import "./PaginationComponent.scss";
import Pagination from '@mui/material/Pagination';

interface PaginationComponentProps {
    id?: string;
    paginationOptions: number[];
    totalResults: number;
    page: number;
    limit: number;
    onPageChange: any;
    onRowsPerPageChange: any;
}

const PaginationComponent = (props: PaginationComponentProps) => {

    const { page, limit, totalResults, onPageChange} = props;

    // const id = props.id || "table-pagination";
    const count = Math.ceil(totalResults / limit);

    return (
        <div className={"table-pagination-wrapper"}>
                <div className={"table-pagination-info-text"}>
                    Showing <span className={'table-pagination-info-highlight-text'}>{(page - 1) * limit + 1}</span> to <span className={'table-pagination-info-highlight-text'}>{(page - 1) * limit + limit}</span> of <span className={'table-pagination-info-highlight-text'}>{totalResults}</span> Results
            </div>
            <Pagination
                count={count}
                shape="rounded"
                onChange={onPageChange}
                variant={'outlined'}
                page={page}
                // getItemAriaLabel={(page) => `Go to page ${page}`}
                // showFirstButton={true}
            />
        </div>
        // </div>
)
    ;

};

export default PaginationComponent;
