import {call, put, takeEvery} from "redux-saga/effects";

import {
    GET_GENDER_LIST,
    GET_USER_ROLE_LIST,
    setGenderList,
    setUserRoleList,
    GET_POLE_TYPES,
    setPollTypes
} from "../actions/static-data.action";

import {CommonService} from "../../shared/services";


function* getGenderList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getGenderList);
        yield put(setGenderList(resp?.data));
    } catch (error: any) {
        yield put(setGenderList([]));
    }
}

function* getUserRoleList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getUserRoleList);
        yield put(setUserRoleList(resp?.data));
    } catch (error: any) {
        yield put(setUserRoleList([]));
    }
}

function* getPollTypes() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getPollTypes);
        const pollTypeList = resp?.data;
        const Types = Object.keys(pollTypeList);
        const LinearTypes = pollTypeList['Linear Scale']
        const Rating = pollTypeList['Rating']

        const poleTypesList = {
            'answerTypes': Types,
            'LinearTypes': LinearTypes,
            'Rating': Rating,
        }
        yield put(setPollTypes(poleTypesList));
    } catch (error: any) {
        yield put(setPollTypes([]));
    }
}

export default function* staticDataSaga() {
    yield takeEvery(GET_GENDER_LIST, getGenderList);
    yield takeEvery(GET_USER_ROLE_LIST, getUserRoleList);
    yield takeEvery(GET_POLE_TYPES, getPollTypes);
}


