import * as React from 'react';
import Popover from '@mui/material/Popover';
import {IconButton} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useEffect} from "react";
import commonService from "../../services/common.service";

export interface PopOverComponentProps {
}

const PopOverComponent = (props: React.PropsWithChildren<PopOverComponentProps>) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const sub = commonService._communications.ClosePopOverSubject.subscribe(()=>{
            setAnchorEl(null);
        });
        return () => {
            sub.unsubscribe();
        }
    }, []);

    const open = Boolean(anchorEl);

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVertIcon/>
            </IconButton>
            <div className="footer-menu-holder">
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {props.children}
                </Popover>
            </div>
        </div>
    );
}

export default PopOverComponent;
