import './AddBillingCardComponent.scss';
import { Field, FieldArray, FieldProps } from 'formik';
import FormikInputComponent from '../form-controls/formik-input/FormikInputComponent';
import HorizontalLineComponent from '../horizontal-line/horizontal-line/HorizontalLineComponent';
import TableComponent from '../table/TableComponent';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ITableColumn } from '../../models/table.model';
import FormikCheckBoxComponent from '../form-controls/formik-check-box/FormikCheckBoxComponent';
import FormikDatePickerComponent from '../form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikSelectDropdownComponent from '../form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import AccordionComponent from '../accordion/AccordionComponent';
import ButtonGroupComponent from '../button-group/ButtonGroupComponent';
import ButtonComponent from '../button/ButtonComponent';
import FormControlLabelComponent from '../form-control-label/FormControlLabelComponent';
import CheckBoxComponent from '../form-controls/check-box/CheckBoxComponent';
import SwitchComponent from '../form-controls/switch/SwitchComponent';
import IconButtonComponent from '../icon-button/IconButtonComponent';
import { ImageConfig } from '../../../constants';
import ModalComponent from '../modal/ModalComponent';
import moment from 'moment';
import _ from 'lodash';
import StatusCardComponent from '../status-card/StatusCardComponent';

interface AddBillingCardComponentProps {
  setFieldValue: any;
  billingObject: any;
  type: string;
  towersForBilling: any;
  towerList?: any;
  towerDetails?: any;
  values?: any;
}

const escalationsStartsWithOptions = [
  {
    title: 'Lease Commencement Date',
    code: 'leaseCommencementDate',
  },
  {
    title: 'Rent Commencement Date',
    code: 'rentCommencementDate',
  },
  {
    title: 'Custom Date',
    code: 'custom',
  },
];

const recurrentYearOptions = [
  {
    title: '1 year',
    code: 1,
  },
  {
    title: '2 years',
    code: 2,
  },
  {
    title: '3 years',
    code: 3,
  },
];

const escalationType = [
  {
    title: 'Office Floor',
    code: 'officeFloor',
  },
  {
    title: 'Basement',
    code: 'basement',
  },
  {
    title: 'Hard Option',
    code: 'hardOption',
  },
];

const AddBillingCardComponent = (props: AddBillingCardComponentProps) => {
  const { setFieldValue, billingObject, type, towerList, values } = props;
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [focusedInputIndex, setFocusedInputIndex] = useState<any>({});
  const [selectedTable, setSelectedTable] = useState<any>({});
  const [selectedEscalation, setSelectedEscalation] = useState<any>({});
  const [noOfFloors, setNoOfFloors] = useState<any>(undefined);
  const [noOfBasements, setNoOfBasements] = useState<any>(undefined);
  const [noOfHardOptions, setNoOfHardOptions] = useState<any>(undefined);
  const [selectedType, setSelectedType] = useState<any>(undefined);
  const [openRatePreferenceModal, setOpenRatePreferenceModal] =
    useState<boolean>(false);
  const [selectedAllChecked, setSelectedAllChecked] = useState<any>({});
  const [currentTowerIndex, setCurrentTowerIndex] = useState<any>(null);
  const [currentSelectedTabForSameRate, setCurrentSelectedTabForSameRate] =
    useState<string>('');

  const TowerButtons = useCallback(
    (tower?: any, type?: string) => [
      {
        title: 'Office Floors',
        id: 'officeFloor',
        badge: {
          text:
            (type === 'escalation'
              ? billingObject?.escalations.escalationList?.filter(
                  (item: any) => item.type === 'officeFloor',
                ).length
              : tower?.officeFloors?.length) || 0,
          color: 'primary',
        },
      },
      {
        title: 'Basements',
        id: 'basement',
        badge: {
          text:
            (type === 'escalation'
              ? billingObject?.escalations.escalationList?.filter(
                  (item: any) => item.type === 'basement',
                ).length
              : tower?.basements?.length) || 0,
          color: 'primary',
        },
      },
      {
        title: 'Hard Options',
        id: 'hardOption',
        badge: {
          text:
            (type === 'escalation'
              ? billingObject?.escalations.escalationList?.filter(
                  (item: any) => item.type === 'hardOption',
                ).length
              : tower?.hardOptions?.length) || 0,
          color: 'primary',
        },
      },
    ],
    [billingObject],
  );

  const renderTowerInput = useCallback(
    (selectedTab: any, rowIndex: number, index: number) => {
      const activate =
        selectedTab === 'officeFloor'
          ? billingObject?.towers[index].officeFloors[rowIndex].isSelected
          : selectedTab === 'basement'
          ? billingObject?.towers[index].basements[rowIndex].isSelected
          : selectedTab === 'hardOption' &&
            billingObject?.towers[index].hardOptions[rowIndex].isSelected;

      const handleInputChange = (value: any) => {
        let path;
        if (selectedTab === 'officeFloor') {
          path = `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].ratePerSft`;
        } else if (selectedTab === 'basement') {
          path = `billingComponents.${type}.towers[${index}].basements[${rowIndex}].ratePerSft`;
        } else if (selectedTab === 'hardOption') {
          path = `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].ratePerSft`;
        }

        setFieldValue(path, value ? parseInt(value) : '');
      };
      return (
        <div>
          <Field
            name={
              selectedTab === 'officeFloor'
                ? `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].ratePerSft`
                : selectedTab === 'basement'
                ? `billingComponents.${type}.towers[${index}].basements[${rowIndex}].ratePerSft`
                : selectedTab === 'hardOption' &&
                  `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].ratePerSft`
            }
            key={`tower-input-${index}-${selectedTab}-${rowIndex}`}
          >
            {(field: FieldProps) => (
              <FormikInputComponent
                className='tower-rate-price-input'
                placeholder='Ex: 3000 Rs'
                formikField={field}
                validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                fullWidth={true}
                disabled={!activate}
                autoFocus={
                  rowIndex === focusedInputIndex['input' + index + rowIndex]
                }
                ref={(ref: HTMLInputElement | null) =>
                  (inputRefs.current[rowIndex] = ref)
                }
                onChange={(value) => {
                  setFocusedInputIndex((prev: any) => ({
                    ...prev,
                    ['input' + index + rowIndex]: rowIndex,
                  }));
                  handleInputChange(value);
                }}
                onBlur={() => {
                  setFocusedInputIndex({});
                }}
              />
            )}
          </Field>
        </div>
      );
    },
    [type, setFieldValue, focusedInputIndex, billingObject],
  );

  // Function for selecting all checkbox
  const handleAllCheckboxSelection = useCallback(
    (value: boolean, selectedTab: string, index: number) => {
      const getFieldArrayFieldName = () => {
        switch (selectedTab) {
          case 'officeFloor':
            return `officeFloors`;
          case 'basement':
            return `basements`;
          case 'hardOption':
            return `hardOptions`;
          default:
            return '';
        }
      };
      const fieldName = getFieldArrayFieldName();
      const path = `billingComponents.${type}.towers[${index}].${fieldName}`;
      const items = billingObject.towers[index][fieldName];
      const updatedItems = items.map((item: any) => ({
        ...item,
        isSelected: value,
      }));
      setFieldValue(path, updatedItems);
      setSelectedAllChecked((prevState: any) => ({
        ...prevState,
        [index + selectedTab]: value,
      }));
    },
    [billingObject, setFieldValue, type],
  );

  const renderTowerDropDown = useCallback(
    (selectedTab: any, rowIndex: number, index: number) => {
      const activate =
        selectedTab === 'officeFloor'
          ? billingObject?.towers[index].officeFloors[rowIndex].isSelected
          : selectedTab === 'basement'
          ? billingObject?.towers[index].basements[rowIndex].isSelected
          : selectedTab === 'hardOption' &&
            billingObject?.towers[index].hardOptions[rowIndex].isSelected;
      return (
        <Field
          name={
            selectedTab === 'officeFloor'
              ? `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].rentStartFrom`
              : selectedTab === 'basement'
              ? `billingComponents.${type}.towers[${index}].basements[${rowIndex}].rentStartFrom`
              : selectedTab === 'hardOption' &&
                `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].rentStartFrom`
          }
        >
          {(field: FieldProps) => (
            <FormikSelectDropdownComponent
              className='tower-rent-start-from'
              placeholder='Select'
              formikField={field}
              options={escalationsStartsWithOptions}
              displayWith={(option: any) => option.title}
              fullWidth={true}
              disabled={!activate}
              onUpdate={(value) => {
                if (!value) {
                  const selected =
                    selectedTab === 'officeFloor'
                      ? `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].rentStartFromDate`
                      : selectedTab === 'basement'
                      ? `billingComponents.${type}.towers[${index}].basements[${rowIndex}].rentStartFromDate`
                      : selectedTab === 'hardOption' &&
                        `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].rentStartFromDate`;
                  setFieldValue(selected, '');
                }
              }}
            />
          )}
        </Field>
      );
    },
    [type, billingObject, setFieldValue],
  );

  const towerColumns = useMemo(
    () =>
      (selectedTab: any, index: any): ITableColumn[] => {
        const isChecked = selectedAllChecked[index + selectedTab] || false;
        return [
          {
            title: (
              <>
                <CheckBoxComponent
                  checked={isChecked}
                  onChange={(value) => {
                    handleAllCheckboxSelection(value, selectedTab, index);
                  }}
                />
                <div>
                  {selectedTab === 'officeFloor'
                    ? 'Floors'
                    : selectedTab === 'basement'
                    ? 'Basements'
                    : selectedTab === 'hardOption'
                    ? 'Hard Options'
                    : ''}
                </div>
              </>
            ),
            key: 'checkbox',
            dataIndex: 'checkbox',
            width: 120,
            render: (item: any, rowIndex: any) => {
              return (
                <div className={'pdd-top-20'}>
                  <Field
                    name={
                      selectedTab === 'officeFloor'
                        ? `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].isSelected`
                        : selectedTab === 'basement'
                        ? `billingComponents.${type}.towers[${index}].basements[${rowIndex}].isSelected`
                        : selectedTab === 'hardOption' &&
                          `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].isSelected`
                    }
                  >
                    {(field: FieldProps) => {
                      return (
                        <FormikCheckBoxComponent
                          label={item?.title || 'N/A'}
                          formikField={field}
                          disabled={!item?.title}
                          onChange={(value) => {
                            if (!value) {
                              setFieldValue(
                                `billingComponents.${type}.towers[${index}].${selectedTab}[${rowIndex}].ratePerSft`,
                                '',
                              );
                              setFieldValue(
                                `billingComponents.${type}.towers[${index}].${selectedTab}[${rowIndex}].rentStartFrom`,
                                '',
                              );
                              setFieldValue(
                                `billingComponents.${type}.towers[${index}].${selectedTab}[${rowIndex}].rentStartFrom`,
                                '',
                              );
                            }
                          }}
                        />
                      );
                    }}
                  </Field>
                </div>
              );
            },
          },
          {
            title: 'Area Occupied',
            key: 'areaOccupied',
            dataIndex: 'areaOccupied',
            width: 80,
            render: (item: any) => {
              return (
                <div>
                  {item?.occupiedArea ? item?.occupiedArea + ' sqft' : 'N/A'}
                </div>
              );
            },
          },
          {
            title: 'Rate per Sqft',
            key: 'ratePerSqft',
            dataIndex: 'ratePerSqft',
            width: 100,
            render: (item: any, rowIndex: any) => {
              return renderTowerInput(selectedTab, rowIndex, index);
            },
          },
          {
            title:
              type === 'rent'
                ? 'Rent start from'
                : type === 'cam'
                ? 'Building CAM start from'
                : 'Campus CAM start from',
            key: 'rentStartFrom',
            dataIndex: 'rentStartFrom',
            width: 150,
            render: (item: any, rowIndex: any) => {
              return (
                <div className='rent-start-from-wrapper'>
                  {renderTowerDropDown(selectedTab, rowIndex, index)}
                </div>
              );
            },
          },
          {
            title:
              type === 'rent'
                ? 'Rent Custom Date'
                : type === 'cam'
                ? 'Building CAM Custom Date'
                : 'Campus CAM Custom Date',
            key: 'rentCustomDate',
            dataIndex: 'rentCustomDate',
            width: 150,
            render: (item: any, rowIndex: any) => {
              const selected =
                selectedTab === 'officeFloor'
                  ? billingObject?.towers[index]?.officeFloors[rowIndex]
                      ?.rentStartFrom
                  : selectedTab === 'basement'
                  ? billingObject?.towers[index]?.basements[rowIndex]
                      ?.rentStartFrom
                  : selectedTab === 'hardOption'
                  ? billingObject?.towers[index]?.hardOptions[rowIndex]
                      ?.rentStartFrom
                  : '';
              return (
                <div className='rent-start-custom-date'>
                  {selected === 'custom' && (
                    <Field
                      name={
                        selectedTab === 'officeFloor'
                          ? `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].rentStartFromDate`
                          : selectedTab === 'basement'
                          ? `billingComponents.${type}.towers[${index}].basements[${rowIndex}].rentStartFromDate`
                          : selectedTab === 'hardOption' &&
                            `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].rentStartFromDate`
                      }
                    >
                      {(field: FieldProps) => (
                        <FormikDatePickerComponent
                          formikField={field}
                          minDate={new Date()}
                          className='rent-start-from-custom-date'
                          placeholder='Select'
                          fullWidth={true}
                        />
                      )}
                    </Field>
                  )}
                </div>
              );
            },
          },
        ];
      },
    [
      renderTowerDropDown,
      renderTowerInput,
      type,
      billingObject,
      handleAllCheckboxSelection,
      selectedAllChecked,
      setFieldValue,
    ],
  );

  const handleClearEscalation = useCallback(() => {
    setFieldValue(`billingComponents.${type}.escalations.towerName`, '');
    setFieldValue(`billingComponents.${type}.escalations.towerId`, '');
    setFieldValue(`billingComponents.${type}.escalations.recurrentSpan`, '');
    setFieldValue(`billingComponents.${type}.escalations.rateOfEscalation`, '');
    setFieldValue(`billingComponents.${type}.escalations.number`, '');
    setFieldValue(`billingComponents.${type}.escalations.type`, '');
    setFieldValue(
      `billingComponents.${type}.escalations.escalationsStartsWith`,
      '',
    );
    setFieldValue(
      `billingComponents.${type}.escalations.escalationCustomDate`,
      '',
    );
    setFieldValue(`billingComponents.${type}.escalations.id`, '');
  }, [setFieldValue, type]);

  const handleEscalationList = useCallback(() => {
    const currentEscalations = billingObject?.escalations?.escalationList || [];
    const updatedEscalationList = _.cloneDeep(currentEscalations);

    const towerIndex = billingObject.towers?.findIndex(
      (tower: any) => tower.towerId === billingObject?.escalations?.towerId,
    );
    const list = billingObject?.escalations?.type + 's';

    billingObject?.escalations?.number.forEach((item: any) => {
      const newEscalation = {
        id: item.id,
        number: item.number,
        occupiedArea: item.occupiedArea,
        isSelected: item.isSelected,
        escalationApplied: true,
        isHardArea: item.isHardArea,
        title: item.title,
        ratePerSft: item.ratePerSft,
        type: billingObject.escalations.type,
        towerId: billingObject.escalations.towerId,
        towerName: billingObject.escalations.towerName,
        rateOfEscalation: billingObject.escalations.rateOfEscalation,
        escalationsStartsWith: billingObject.escalations.escalationsStartsWith,
        escalationCustomDate: billingObject.escalations.escalationCustomDate,
        recurrentSpan: billingObject.escalations.recurrentSpan,
      };
      updatedEscalationList.push(newEscalation);
      const rowIndex = billingObject?.towers[towerIndex]?.[list]?.findIndex(
        (obj: any) => obj.id === item.id,
      );
      setFieldValue(
        `billingComponents.${type}.towers[${towerIndex}].${list}[${rowIndex}].escalationApplied`,
        true,
      );
    });

    setFieldValue(
      `billingComponents.${type}.escalations.escalationList`,
      updatedEscalationList,
    );
    handleClearEscalation();
  }, [billingObject, setFieldValue, type, handleClearEscalation]);

  const handleDeleteEscalation = useCallback(
    (deleteIndex: any) => {
      // delete escalation
      const currentEscalations =
        billingObject?.escalations?.escalationList || [];
      const updatedEscalations = currentEscalations?.filter(
        (a: any, index: any) => index !== deleteIndex,
      );
      setFieldValue(
        `billingComponents.${type}.escalations.escalationList`,
        updatedEscalations,
      );

      //Code to make escalationApplied back to false;
      const escalation = currentEscalations?.find(
        (a: any, index: any) => index === deleteIndex,
      );
      const towerIndex = billingObject?.towers?.findIndex(
        (tower: any) => tower?.towerId === escalation?.towerId,
      );
      const list = escalation?.type + 's';
      const rowIndex = billingObject?.towers[towerIndex]?.[list]?.findIndex(
        (obj: any) => obj.id === escalation?.id,
      );
      setFieldValue(
        `billingComponents.${type}.towers[${towerIndex}].${list}[${rowIndex}].escalationApplied`,
        false,
      );
      handleClearEscalation();
    },
    [setFieldValue, type, billingObject, handleClearEscalation],
  );

  const escalationColumns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Tower',
        key: 'tower',
        dataIndex: 'tower',
        width: '100',
        render: (item: any) => {
          return <div>{item?.towerName || 'N/A'}</div>;
        },
      },
      {
        title: 'Floor',
        key: 'floor',
        dataIndex: 'floor',
        width: '100',
        render: (item: any) => {
          return <div>{item?.title || 'N/A'}</div>;
        },
      },
      {
        title: 'Rate (%)',
        key: 'rate',
        dataIndex: 'rate',
        width: '100',
        render: (item: any) => {
          return <div>{item?.rateOfEscalation}</div>;
        },
      },
      {
        title: 'Escalation Start From',
        key: 'escalationStartFrom',
        dataIndex: 'escalationStartFrom',
        width: '170',
        render: (item: any, rowIndex: any) => {
          return (
            <div>
              {item.escalationsStartsWith === 'leaseCommencementDate' ? (
                <>
                  {moment(values?.leaseCommencementDate).format('DD-MM-YYYY')}
                  <span className='italic-text-lite'>(LCD)</span>
                </>
              ) : item.escalationsStartsWith === 'rentCommencementDate' ? (
                <>
                  {moment(values?.rentCommencementDate).format('DD-MM-YYYY')}
                  <span className='italic-text-lite'>(RCD)</span>
                </>
              ) : item.escalationsStartsWith === 'custom' ? (
                <>
                  {moment(item.escalationCustomDate).format('DD-MM-YYYY')}
                  <span className='italic-text-lite'>(Custom)</span>
                </>
              ) : (
                'N/A'
              )}
            </div>
          );
        },
      },
      {
        title: 'Recur Every',
        key: 'recurEvery',
        dataIndex: 'recurEvery',
        width: '80',

        render: (item: any) => {
          return <div>{item?.recurrentSpan || 'N/A'}</div>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        width: '30',
        align: 'center',
        render: (item: any, rowIndex) => {
          return (
            <div>
              <IconButtonComponent
                color='error'
                onClick={() => handleDeleteEscalation(rowIndex)}
              >
                <ImageConfig.DeleteIcon />
              </IconButtonComponent>
            </div>
          );
        },
      },
    ];
  }, [handleDeleteEscalation, values]);

  const applySameRatePerSft = useCallback(
    (selectedTab: any, towerIndex: any) => {
      const rateValue = parseInt(
        billingObject?.towers[towerIndex]?.sameRatePerSft,
      );
      billingObject?.towers.forEach((tower: any, index: number) => {
        if (selectedTab === 'officeFloor') {
          tower?.officeFloors?.forEach((floor: any, rowIndex: number) => {
            if (floor.isSelected) {
              setFieldValue(
                `billingComponents.${type}.towers[${index}].officeFloors[${rowIndex}].ratePerSft`,
                rateValue,
              );
            }
          });
        } else if (selectedTab === 'basement') {
          tower?.basements?.forEach((basement: any, rowIndex: any) => {
            if (basement.isSelected) {
              setFieldValue(
                `billingComponents.${type}.towers[${index}].basements[${rowIndex}].ratePerSft`,
                rateValue,
              );
            }
          });
        } else if (selectedTab === 'hardOption') {
          tower?.hardOptions?.forEach((option: any, rowIndex: any) => {
            if (option.isSelected) {
              setFieldValue(
                `billingComponents.${type}.towers[${index}].hardOptions[${rowIndex}].ratePerSft`,
                rateValue,
              );
            }
          });
        }
      });
      setFieldValue(
        `billingComponents.${type}.towers[${towerIndex}].sameRatePerSft`,
        '',
      );
      setOpenRatePreferenceModal(false);
    },
    [billingObject, setFieldValue, type],
  );

  const EscalationDetails = useCallback(
    (type: string) => {
      let filterData: any = [];
      filterData = billingObject?.escalations?.escalationList?.filter(
        (item: any) => item.type === type,
      );
      return filterData;
    },
    [billingObject],
  );

  const handleEscalationTower = useCallback(
    (id: string) => {
      if (id) {
        const towers = billingObject?.towers;
        const getTower = towers?.find((tower: any) => tower.towerId === id);
        const floors = getTower.officeFloors
          .filter((floor: any) => floor.isSelected && !floor.escalationApplied)
          .map((floor: any) => ({
            number: floor.number,
            occupiedArea: floor.occupiedArea,
            ratePerSft: floor.ratePerSft,
            id: floor.id,
            escalationApplied: floor.escalationApplied,
            title: floor.title,
            isSelected: floor.isSelected,
          }))
          .sort((a: any, b: any) => a - b.number);
        setNoOfFloors(floors);
        const basements = getTower.basements
          .filter(
            (basement: any) =>
              basement.isSelected && !basement.escalationApplied,
          )
          .map((basement: any) => ({
            number: basement.number,
            occupiedArea: basement.occupiedArea,
            ratePerSft: basement.ratePerSft,
            id: basement.id,
            escalationApplied: basement.escalationApplied,
            title: basement.title,
            isSelected: basement.isSelected,
          }))
          .sort((a: any, b: any) => a - b.number);
        setNoOfBasements(basements);
        const hardOptions = getTower.hardOptions
          .filter(
            (hardOption: any) =>
              hardOption.isSelected && !hardOption.escalationApplied,
          )
          .map((hardOption: any) => ({
            number: hardOption.number,
            occupiedArea: hardOption.occupiedArea,
            ratePerSft: hardOption.ratePerSft,
            id: hardOption.id,
            escalationApplied: hardOption.escalationApplied,
            title: hardOption.title,
            isSelected: hardOption.isSelected,
          }))
          .sort((a: any, b: any) => a - b.number);
        setNoOfHardOptions(hardOptions);
        setFieldValue(
          `billingComponents.${type}.escalations.towerName`,
          getTower.towername,
        );
        setFieldValue(`billingComponents.${type}.escalations.type`, '');
        setFieldValue(`billingComponents.${type}.escalations.number`, '');
      } else {
        setNoOfFloors(undefined);
        setNoOfBasements(undefined);
        setNoOfHardOptions(undefined);
        setFieldValue(`billingComponents.${type}.escalations.towerName`, '');
        setFieldValue(`billingComponents.${type}.escalations.type`, '');
        setFieldValue(`billingComponents.${type}.escalations.number`, '');
      }
    },
    [setFieldValue, billingObject, type],
  );

  const handleEscalationOff = useCallback(() => {
    // Clear the escalation list
    setFieldValue(`billingComponents.${type}.escalations.escalationList`, []);

    // Reset escalationApplied to false for all related towers
    billingObject?.escalations?.escalationList?.forEach((escalation: any) => {
      const towerIndex = billingObject?.towers?.findIndex(
        (tower: any) => tower?.towerId === escalation?.towerId,
      );
      if (towerIndex !== -1) {
        const list = escalation?.type + 's';
        const rowIndex = billingObject?.towers[towerIndex]?.[list]?.findIndex(
          (obj: any) => obj.id === escalation?.id,
        );
        if (rowIndex !== -1) {
          setFieldValue(
            `billingComponents.${type}.towers[${towerIndex}].${list}[${rowIndex}].escalationApplied`,
            false,
          );
        }
      }
    });
  }, [billingObject, setFieldValue, type]);

  return (
    <>
      <div className={'add-billing-card-component'}>
        <div className='billing-component-card'>
          {/* ALL TOWERS */}
          {billingObject?.towers?.length > 0 ? (
            <>
              <FieldArray
                name={`billingOject.towers`}
                render={() => (
                  <>
                    {billingObject?.towers?.map((item: any, index: any) => {
                      const selectedTab =
                        selectedTable[item.towername] || 'officeFloor';
                      const buttons = TowerButtons(item);
                      return (
                        <AccordionComponent
                          key={item?.towerId}
                          title={item?.towername || 'N/A'}
                          isAccordionOpen={true}
                          isExpand={true}
                        >
                          <ButtonGroupComponent
                            buttons={buttons}
                            selected={selectedTab}
                            onChange={(value: any) => {
                              setSelectedTable((prevState: any) => ({
                                ...prevState,
                                [item.towername]: value,
                              }));
                            }}
                          />
                          <HorizontalLineComponent className='mrg-bottom-20 mrg-top-20' />
                          <div className='flex-wrapper mrg-bottom-20'>
                            <FormControlLabelComponent
                              label={`${
                                selectedTab === 'officeFloor'
                                  ? 'Floors'
                                  : selectedTab === 'basement'
                                  ? 'Basements'
                                  : selectedTab === 'hardOption' &&
                                    'Hard Option'
                              }`}
                              level={5}
                              isBold={false}
                            />
                            <ButtonComponent
                              variant='outlined'
                              onClick={() => {
                                setOpenRatePreferenceModal(true);
                                setCurrentTowerIndex(index);
                                setCurrentSelectedTabForSameRate(selectedTab);
                              }}
                            >
                              Add Rate Preferences
                            </ButtonComponent>
                          </div>
                          <TableComponent
                            rowKey={index}
                            columns={towerColumns(selectedTab, index)}
                            data={
                              selectedTab === 'officeFloor'
                                ? item?.officeFloors
                                : selectedTab === 'basement'
                                ? item?.basements
                                : selectedTab === 'hardOption'
                                ? item?.hardOptions
                                : []
                            }
                            className={'tower-table-component'}
                            noDataText={
                              item.officeFloors?.length === 0
                                ? 'No Floors'
                                : item.basements?.length === 0
                                ? 'No Basements'
                                : item.hardOptions?.length === 0
                                ? 'No Hard Options'
                                : 'No Data'
                            }
                          />
                          {currentTowerIndex !== null && (
                            <ModalComponent
                              className='confirmation-modal'
                              isOpen={openRatePreferenceModal}
                              onClose={() => setOpenRatePreferenceModal(false)}
                              id={
                                billingObject.towers[currentTowerIndex].towerId
                              }
                              modalFooter={
                                <>
                                  <ButtonComponent
                                    onClick={() => {
                                      setOpenRatePreferenceModal(false);
                                      setFieldValue(
                                        `billingComponent.${type}.towers[${currentTowerIndex}].sameRatePerSft`,
                                        '',
                                      );
                                    }}
                                    color='default'
                                    variant='outlined'
                                    className={
                                      'pdd-left-60 pdd-right-60 mrg-right-20'
                                    }
                                  >
                                    Cancel
                                  </ButtonComponent>
                                  <ButtonComponent
                                    color='primary'
                                    variant='contained'
                                    className={'pdd-left-60 pdd-right-60'}
                                    onClick={() =>
                                      applySameRatePerSft(
                                        currentSelectedTabForSameRate,
                                        currentTowerIndex,
                                      )
                                    }
                                  >
                                    Confirm
                                  </ButtonComponent>
                                </>
                              }
                            >
                              <div className='confirmation-dialog-container'>
                                <div className='confirmation-dialog-content-wrapper'>
                                  <div className={'confirmation-dialog-title'}>
                                    Add Rate Preferences
                                  </div>
                                  <div className='confirmation-dialog-sub-description'>
                                    Note that all the floors selected will have
                                    the same area.
                                  </div>
                                  <div className={'mrg-top-20'}>
                                    <Field
                                      name={`billingComponents.${type}.towers[${currentTowerIndex}].sameRatePerSft`}
                                    >
                                      {(field: FieldProps) => (
                                        <FormikInputComponent
                                          formikField={field}
                                          placeholder='Ex: 3000 Rs'
                                          fullWidth={true}
                                          validationPattern={
                                            new RegExp(/^[0-9]*$/)
                                          }
                                          onChange={(value: any) => {
                                            setFieldValue(
                                              `billingComponents.${type}.towers[${currentTowerIndex}].sameRatePerSft`,
                                              value ? parseInt(value) : '',
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </div>
                            </ModalComponent>
                          )}
                        </AccordionComponent>
                      );
                    })}
                  </>
                )}
              />
              <SwitchComponent
                label='Escalations'
                labelPlacement='start'
                checked={billingObject?.isEscalationsEnabled}
                onChange={(value: boolean) => {
                  setFieldValue(
                    `billingComponents.${type}.isEscalationsEnabled`,
                    value,
                  );
                  if (!value) {
                    handleEscalationOff();
                  }
                }}
              />
              {billingObject?.isEscalationsEnabled && (
                <AccordionComponent title='Escalations' isAccordionOpen={true}>
                  <div className='escalation-card'>
                    <div className='escalations-wrapper'>
                      <Field
                        name={`billingComponents.${type}.escalations.towerId`}
                      >
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label='Tower'
                            fullWidth
                            isClearable={true}
                            options={towerList}
                            displayWith={(option: any) => option.title}
                            valueExtractor={(option: any) => option.id}
                            onUpdate={(value) => {
                              handleEscalationTower(value);
                            }}
                          />
                        )}
                      </Field>
                      <Field
                        name={`billingComponents.${type}.escalations.type`}
                      >
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label='Type'
                            fullWidth
                            isClearable={true}
                            options={escalationType}
                            onUpdate={(value) => {
                              setSelectedType(value);
                              if (value) {
                                setFieldValue(
                                  `billingComponents.${type}.escalations.type`,
                                  value,
                                );
                                setFieldValue(
                                  `billingComponents.${type}.escalations.number`,
                                  '',
                                );
                              } else {
                                setFieldValue(
                                  `billingComponents.${type}.escalations.type`,
                                  '',
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                      <Field
                        name={`billingComponents.${type}.escalations.number`}
                      >
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            className='escalations-drop-down'
                            formikField={field}
                            label={
                              selectedType === 'officeFloor'
                                ? 'Floor'
                                : selectedType === 'basement'
                                ? 'Basement'
                                : selectedType === 'hardOption'
                                ? 'Hard Option'
                                : 'Floor'
                            }
                            multiple
                            isClearable={true}
                            fullWidth
                            displayWith={(option: any) =>
                              selectedType === 'officeFloor'
                                ? option.title + ` - ${option.occupiedArea} sft`
                                : selectedType === 'basement'
                                ? option.title + ` - ${option.occupiedArea} sft`
                                : selectedType === 'hardOption'
                                ? option.title + ` - ${option.occupiedArea} sft`
                                : ''
                            }
                            options={
                              selectedType === 'officeFloor'
                                ? noOfFloors
                                : selectedType === 'basement'
                                ? noOfBasements
                                : selectedType === 'hardOption'
                                ? noOfHardOptions
                                : []
                            }
                            valueExtractor={(option: any) => option}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='escalations-wrapper'>
                      <Field
                        name={`billingComponents.${type}.escalations.rateOfEscalation`}
                      >
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            formikField={field}
                            label={'Rate of escalation (%)'}
                            placeholder={'Enter Rate of escalation (%)'}
                            validationPattern={new RegExp(/^[1-9][0-9]*$/)}
                            required={true}
                            fullWidth={true}
                            className='mrg-right-10'
                            onChange={(value) => {
                              setFieldValue(
                                `billingComponents.${type}.escalations.rateOfEscalation`,
                                value ? parseInt(value) : '',
                              );
                            }}
                          />
                        )}
                      </Field>
                      <Field
                        name={`billingComponents.${type}.escalations.escalationsStartsWith`}
                      >
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label={'Escalation Start From'}
                            placeholder={'Enter Escalation Start From'}
                            options={escalationsStartsWithOptions}
                            valueExtractor={(option: any) => option.code}
                            displayWith={(option: any) => option.title}
                            required={true}
                            fullWidth={true}
                            isClearable={true}
                          />
                        )}
                      </Field>
                      {billingObject.escalations?.escalationsStartsWith ===
                        'custom' && (
                        <>
                          <Field
                            name={`billingComponents.${type}.escalations.escalationCustomDate`}
                          >
                            {(field: FieldProps) => (
                              <FormikDatePickerComponent
                                formikField={field}
                                label={'Escalation Start From'}
                                placeholder={'Select'}
                                fullWidth={true}
                                // clearable={true}
                              />
                            )}
                          </Field>
                        </>
                      )}
                      <Field
                        name={`billingComponents.${type}.escalations.recurrentSpan`}
                      >
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            formikField={field}
                            label={'Recur Every'}
                            placeholder={'Enter Recur Every'}
                            options={recurrentYearOptions}
                            valueExtractor={(option: any) => option.code}
                            displayWith={(option: any) => option.title}
                            required={true}
                            fullWidth={true}
                            isClearable={true}
                          />
                        )}
                      </Field>
                    </div>
                    <div className='d-flex-center'>
                      <ButtonComponent
                        variant='outlined'
                        color='error'
                        onClick={handleClearEscalation}
                      >
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        variant='contained'
                        color='primary'
                        onClick={handleEscalationList}
                        disabled={
                          !billingObject?.escalations?.towerId ||
                          !billingObject?.escalations?.type ||
                          billingObject?.escalations.number?.length === 0 ||
                          !billingObject?.escalations?.recurrentSpan ||
                          !billingObject?.escalations.rateOfEscalation ||
                          !billingObject?.escalations?.escalationsStartsWith ||
                          (billingObject?.escalations?.escalationsStartsWith ===
                            'custom' &&
                            !billingObject?.escalations?.escalationCustomDate)
                        }
                      >
                        Save Details
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className='mrg-top-20'>
                    <FormControlLabelComponent label='Escalations' level={5} />
                    <ButtonGroupComponent
                      buttons={TowerButtons(undefined, 'escalation')}
                      selected={selectedEscalation[type]}
                      onChange={(value: any) => {
                        setSelectedEscalation((prev: any) => ({
                          ...prev,
                          [type]: value,
                        }));
                      }}
                    />
                    <div className='mrg-top-20'>
                      <TableComponent
                        columns={escalationColumns}
                        data={EscalationDetails(selectedEscalation[type])}
                        className={'tower-table-component'}
                        autoHeight
                      />
                    </div>
                  </div>
                </AccordionComponent>
              )}
            </>
          ) : (
            <StatusCardComponent title='No Towers' />
          )}
        </div>
      </div>
    </>
  );
};

export default AddBillingCardComponent;
