import './SideMenuComponent.scss';
import { ImageConfig } from '../../../../constants';
import { NavLink, useNavigate } from 'react-router-dom';
import { IMenuGroup, IMenuItem } from '../../../models/menu.model';
import { CommonService } from '../../../services';
import { useCallback } from 'react';
import { logout } from '../../../../store/actions/account.action';
import { useDispatch } from 'react-redux';

interface SideMenuComponentProps {}

const MENU: IMenuGroup[] = [
  {
    title: 'Admin',
    id: 'admin',
    menu_items: [
      {
        path: CommonService._routeConfig.Dashboard(),
        title: 'Dashboard',
        icon: ImageConfig.DashboardIcon,
      },
      {
        path: CommonService._routeConfig.EntityRoute(),
        title: 'Entity',
        icon: ImageConfig.EntityIcon,
      },
      {
        path: CommonService._routeConfig.PropertyRoute(),
        title: 'Property',
        icon: ImageConfig.PropertySideIcon,
      },
      {
        path: CommonService._routeConfig.AmenityRoute(),
        title: 'Amenities',
        icon: ImageConfig.AmenitiesIcon,
      },
      {
        path: CommonService._routeConfig.AnnouncementRoute(),
        title: 'Wall',
        icon: ImageConfig.WallIcon,
      },
      // {
      //     path: "",
      //     title: 'Settings',
      //     icon: ImageConfig.SettingsIcon
      // }
    ],
  },
  {
    title: 'Management',
    id: 'management',
    menu_items: [
      {
        path: CommonService._routeConfig.TenantsRoute(),
        title: 'Tenant',
        icon: ImageConfig.TenantIcon,
      },
      {
        path: CommonService._routeConfig.VisitorListRoute(),
        title: 'Visitor',
        icon: ImageConfig.VisitorIcon,
      },
      {
        path: CommonService._routeConfig.UserListRoute(),
        title: 'User',
        icon: ImageConfig.UserIcon,
      },
    ],
  },
  {
    title: 'Activity',
    id: 'activity',
    menu_items: [
      {
        path: CommonService._routeConfig.EventsRoute(),
        title: 'Events',
        icon: ImageConfig.EventsIcon,
      },
      {
        path: CommonService._routeConfig.PollsRoute(),
        title: 'Polls',
        icon: ImageConfig.PollsIcon,
      },
      {
        path: CommonService._routeConfig.FeedbackRoute(),
        title: 'Feedback',
        icon: ImageConfig.FeedbackIcon,
      },
    ],
  },
  {
    title: 'Accounting',
    id: 'accounting',
    menu_items: [
      {
        path: CommonService._routeConfig.InvoicesListRoute(),
        title: 'Invoices',
        icon: ImageConfig.InvoiceSidemenuIcon,
      },
      {
        path: CommonService._routeConfig.DebitNoteListRoute(),
        title: 'Debit Note',
        icon: ImageConfig.DebitNoteIcon,
      },
    ],
  },
  {
    title: '',
    id: 'space',
    is_separator: true,
  },
  {
    title: '',
    id: 'help',
    menu_items: [
      // {
      //     path: "",
      //     title: 'Help',
      //     icon: ImageConfig.HelpIcon
      // },
      {
        path: '',
        title: 'Logout',
        icon: ImageConfig.LogoutIcon,
        type: 'action',
      },
    ],
  },
];

const SideMenuComponent = (props: SideMenuComponentProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuClick = useCallback(
    (menu: string) => {
      if (menu === 'Logout') {
        CommonService._alert.showToast('Logged out', 'success');
        navigate(CommonService._routeConfig.LoginRoute());
        dispatch(logout());
      }
    },
    [dispatch, navigate],
  );

  return (
    <div className='menu-list'>
      {MENU.map((menu, index) => {
        return (
          <div
            className={`menu-group ${menu.is_separator ? 'separator' : ''}`}
            key={menu.id}
          >
            <div className='menu-group-title'>{menu.title}</div>
            <div className='menu-group-items'>
              {menu?.menu_items?.map((item: IMenuItem) => {
                return item.type === 'action' ? (
                  <div
                    key={item.title}
                    onClick={() => {
                      handleMenuClick(item.title);
                    }}
                    className='menu-item logout-menu'
                    id={`${item.title.toLowerCase().split(' ').join('_')}_menu`}
                    {...props}
                  >
                    <span className='menu-item-icon'>
                      <item.icon />
                    </span>
                    <span className='menu-item-title'>{item.title}</span>
                  </div>
                ) : (
                  <NavLink
                    key={item.title}
                    to={item.path}
                    onClick={(event) => {
                      if (item.path === '') {
                        event.preventDefault();
                        CommonService.ComingSoon();
                        return false;
                      }
                    }}
                    className='menu-item'
                    id={`${item.title.toLowerCase().split(' ').join('_')}_menu`}
                    {...props}
                  >
                    <span className='menu-item-icon'>
                      <item.icon />
                    </span>
                    <span className='menu-item-title'>{item.title}</span>
                  </NavLink>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default SideMenuComponent;
