import './PollsListComponent.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { ITableColumn } from '../../../shared/models/table.model';
import ToolTipComponent from '../../../shared/components/tool-tip/ToolTipComponent';
import TagComponent from '../../../shared/components/tag/TagComponent';
import { CommonService } from '../../../shared/services';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { APIConfig, ImageConfig } from '../../../constants';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import { useDispatch } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import ChipComponent from '../../../shared/components/chip/ChipComponent';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface PollsListComponentProps {}

const PollsListComponent = (props: PollsListComponentProps) => {
  const [count, setCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({
    search: '',
    // sort:{},
  });
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const PollsListColumn: ITableColumn[] = [
    {
      title: 'Polls Name',
      key: 'polls_name',
      dataIndex: 'name',
      fixed: 'left',
      // sortable: true,
      width: 200,
      render: (item: any) => {
        return (
          <div className={'polls-details-wrapper'}>
            <div className={'polls-name-tower-wrapper'}>
              {(item?.title).length > 20 ? (
                <ToolTipComponent
                  tooltip={item?.title}
                  textColor={'#FFFFFF'}
                  showArrow={true}
                  position={'top'}
                >
                  <div className={'ellipses-for-table-data polls-title'}>
                    {item?.title}
                  </div>
                </ToolTipComponent>
              ) : (
                <div className='polls-title text-transform-capitalize'>
                  {item?.title || 'N/A'}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Property Name',
      key: 'property_name',
      dataIndex: 'name',
      fixed: 'left',
      // sortable: true,
      width: 200,
      render: (item: any) => {
        return (
          <>
            {(item?.properties || []).length > 0 && (
              <ToolTipComponent
                // backgroundColor={'#fff'}
                position={'right'}
                textColor={'#FFFFFF'}
                tooltip={
                  <>
                    {item?.properties.map((value1: any, index1: number) => {
                      return (
                        <div
                          className={
                            'mrg-top-5 mrg-bottom-5 text-transform-capitalize'
                          }
                          key={index1}
                        >
                          {value1.name}
                        </div>
                      );
                    })}
                  </>
                }
              >
                <div className={'d-flex'}>
                  <ChipComponent
                    className={'scheduled cursor-pointer'}
                    size={'small'}
                    color={'primary'}
                    label={
                      item?.properties[0].name +
                      ((item?.properties || []).length > 1
                        ? ' (+' + ((item?.properties || []).length - 1) + ')'
                        : '')
                    }
                  />
                </div>
              </ToolTipComponent>
            )}
          </>
        );
        // if (item?.properties && item?.properties?.length === 1) {
        //     return <>
        //         {
        //             (item?.properties[0]?.name).length > 20 ?
        //                 <ToolTipComponent
        //                     tooltip={item?.properties[0]?.name}
        //                     showArrow={true}
        //                     position={"top"}
        //                 >
        //                     <div className={"ellipses-for-table-data property-name"}>
        //                         {item?.properties[0]?.name}
        //                     </div>
        //                 </ToolTipComponent> :
        //                 <div className="property-name">
        //                     {item?.properties[0]?.name || 'N/A'}
        //                 </div>
        //         }
        //     </>
        // } else if (item?.properties && item?.properties?.length > 1) {
        //     return <>
        //         {
        //             (item?.properties[0]?.name).length > 1 ?
        //                 <ToolTipComponent
        //                     tooltip={item?.properties[0]?.name}
        //                     showArrow={true}
        //                     position={"top"}
        //                 >
        //                     <div className={"ellipses-for-table-data property-name"}>
        //                         {item?.properties[0]?.name}
        //                     </div>
        //                 </ToolTipComponent> :
        //                 <div className="property-name">
        //                     {item?.properties[0]?.name || 'N/A'}
        //                 </div>
        //         }
        //         ( <span
        //         className={'properties-length'}>+{item?.properties?.length}</span> )</>
        // } else {
        //     return <>N/A</>
        // }
      },
    },

    {
      title: 'Start Date and Time',
      key: 'startTimeAndDate',
      dataIndex: 'startTimeAndDate',
      width: 170,
      // fixed: "left",
      render: (item: any) => {
        return (
          <>
            <div>
              {item.startDate ? <div>{item.startDate}</div> : <div>-</div>}
              {item.startTime ? <div>{item.startTime}</div> : <div>-</div>}
            </div>
          </>
        );
      },
    },
    {
      title: 'End Date and Time',
      key: 'endTimeAndDate',
      dataIndex: 'endTimeAndDate',
      width: 170,
      // fixed: "left",
      render: (item: any) => {
        return (
          <>
            <div>
              {item.endDate ? <div>{item.endDate}</div> : <div>-</div>}
              {item.endTime ? <div>{item.endTime}</div> : <div>-</div>}
            </div>
          </>
        );
      },
    },

    {
      title: 'No of People Voted',
      key: 'noOfPeopleVoted',
      dataIndex: 'noOfPeopleVoted',
      width: 100,
      // sortable: true,
      // fixed: "left",
      render: (item: any) => {
        return <div>{item?.totalVotes || 'N/A'}</div>;
      },
    },

    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      render: (item: any) => {
        return (
          <>
            <TagComponent
              color={CommonService.getPollsStatusTagColor(item?.status)}
              showIndicator={true}
              label={item?.status}
            />
          </>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center',
      key: 'actions',
      width: 100,
      fixed: 'right',
      render: (item: any) => {
        if (item?._id) {
          return (
            <LinkComponent
              route={CommonService._routeConfig.PollsDetails(item?._id)}
            >
              <ImageConfig.ArrowRightOutlinedIcon />
            </LinkComponent>
          );
        }
      },
    },
  ];

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._poll
        .downloadPollListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'poll_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No Poll List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'polls-list-component list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Polls</div>
            <div className='list-screen-data-count'>
              <TagComponent color={'primary'} label={count + ' Polls'} />
            </div>
          </div>
        </div>
        <div className='list-options'>
          <SearchComponent
            placeholder='Search title'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          {/*<ButtonComponent color={'primary'}*/}
          {/*                 variant={'outlined'}*/}
          {/*    // prefixIcon={<ImageConfig.AddOutlinedIcon/>}*/}
          {/*>*/}
          {/*    Filters*/}
          {/*</ButtonComponent>*/}
          <LinkComponent route={CommonService._routeConfig.AddPollsRoute()}>
            <ButtonComponent
              color={'primary'}
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
            >
              Add New Poll
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.POLLS_LIST.URL}
          method={APIConfig.POLLS_LIST.METHOD}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          noDataText={
            filterData.search
              ? 'No such poll exist'
              : 'No polls have been added yet'
          }
          columns={PollsListColumn}
        />
      </div>
    </div>
  );
};

export default PollsListComponent;
