import { call, put, takeEvery } from 'redux-saga/effects';
import { CommonService } from '../../shared/services';
import {
  GET_ALL_AMENITY_LIST,
  GET_AMENITY_LIST_LITE,
  setAllAmenityList,
  setAmenityListLite,
} from '../actions/amenity.action';

function* getAllAmenitiesList() {
  try {
    // @ts-ignore
    const resp = yield call(CommonService._amenity.GetAmenitiesList, {
      isActive: true,
    });
    const amenityList = resp?.data;
    const amenityListLite = amenityList.map((amenity: any) => {
      return {
        _id: amenity?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(amenity?.name),
        propertyId: amenity?.propertyId,
      };
    });
    yield put(setAmenityListLite(amenityListLite));
  } catch (error: any) {
    yield put(setAmenityListLite([]));
  }
}

function* getAllAmenities() {
  try {
    // @ts-ignore
    const resp = yield call(CommonService._amenity.GetAmenitiesList, {});
    const amenityList = resp?.data?.map((amenity: any) => {
      return {
        _id: amenity?._id,
        name: CommonService.capitalizeFirstLetterOfEachWord(amenity?.name),
        propertyId: amenity?.propertyId,
      };
    });
    yield put(setAllAmenityList(amenityList));
  } catch (error: any) {
    yield put(setAllAmenityList([]));
  }
}

export default function* propertySaga() {
  yield takeEvery(GET_AMENITY_LIST_LITE, getAllAmenitiesList);
  yield takeEvery(GET_ALL_AMENITY_LIST, getAllAmenities);
}
