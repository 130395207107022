import './UserListScreen.scss';
import { APIConfig, ImageConfig, Misc } from '../../../constants';
import TagComponent from '../../../shared/components/tag/TagComponent';
import SearchComponent from '../../../shared/components/search/SearchComponent';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ITableColumn } from '../../../shared/models/table.model';
import TableWrapperComponent from '../../../shared/components/table-wrapper/TableWrapperComponent';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import LinkComponent from '../../../shared/components/link/LinkComponent';
import { CommonService } from '../../../shared/services';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentNavParams } from '../../../store/actions/navigation.action';
import { IRootReducerState } from '../../../store/reducers';
import SelectDropdownComponent from '../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent';
import PopOverComponent from '../../../shared/components/pop-over/PopOverComponent';
import commonService from '../../../shared/services/common.service';
import { IAPIResponseType } from '../../../shared/models/api.model';

interface UserListScreenProps {}

const MODULE = 'user';

const UserListScreen = (props: UserListScreenProps) => {
  const [count, setCount] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({});
  const dispatch = useDispatch();
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const [downloading, setDownloading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setCurrentNavParams(' ') as any);
  }, [dispatch]);

  const handleDataLoad = useCallback((data: any) => {
    setCount(data?.total);
  }, []);

  const toggleUser = useCallback((item: any) => {
    CommonService._user
      .toggleUserAPICall(item._id)
      .then((response) => {
        CommonService._alert.showToast(
          response[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
        CommonService._communications.TableWrapperRefreshSubject.next({
          moduleName: MODULE,
        });
      })
      .catch((err) => {
        CommonService._alert.showToast(
          err[Misc.API_RESPONSE_MESSAGE_KEY],
          'success',
        );
      });
  }, []);

  const onUserActivateDeactivate = useCallback(
    (item: any) => {
      CommonService.onConfirm({
        confirmationTitle: `${
          item?.isActive ? 'Deactivate' : 'Activate'
        }  User`,
        image: item?.isActive
          ? ImageConfig.DeactivateUserIcon
          : ImageConfig.ActivateUserIcon,
        confirmationDescription: `Are you sure you want to ${
          item?.isActive ? 'deactivate' : 'activate'
        }  the 
selected user?`,
        no: {
          color: 'default',
          text: 'Cancel',
          variant: 'outlined',
        },
        yes: {
          color: 'error',
          text: 'Confirm',
          variant: 'contained',
        },
      }).then((result: any) => {
        toggleUser(item);
      });
    },
    [toggleUser],
  );

  const Columns: ITableColumn[] = useMemo(() => {
    return [
      {
        title: 'Phoenix / PEFS ID',
        key: 'id',
        dataIndex: 'id',
        fixed: 'left',
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.userCode || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'User Name',
        key: 'name',
        dataIndex: 'name',
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.name || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Property Name',
        key: 'property',
        dataIndex: 'property',
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.propertyDetails?.name || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Entity Name',
        key: 'entity',
        dataIndex: 'entity',
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.billingEntityDetails?.name || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Phone Number',
        key: 'phone',
        dataIndex: 'phone',
        width: 140,
        render: (item: any) => {
          return <> + 91 {item?.phoneNumber || 'N/A'}</>;
        },
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
        width: 100,
        render: (item: any) => {
          return (
            <div className={'text-transform-capitalize'}>
              {item?.roleDetails?.role || 'N/A'}
            </div>
          );
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (item: any) => {
          return (
            <div className='mrg-top-10'>
              <TagComponent
                color={item?.isActive ? 'success' : 'danger'}
                showIndicator={true}
                label={item?.isActive ? 'Active' : 'Inactive'}
              />
            </div>
          );
        },
      },
      {
        title: 'Action',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        width: 60,
        render: (item: any) => {
          return (
            <>
              <PopOverComponent>
                <LinkComponent
                  route={CommonService._routeConfig.UserEditRoute(item._id)}
                >
                  <ButtonComponent variant='text' color={'primary'}>
                    Edit
                  </ButtonComponent>
                </LinkComponent>
                <ButtonComponent
                  variant='text'
                  color={item?.isActive ? 'error' : 'primary'}
                  onClick={() => {
                    onUserActivateDeactivate(item);
                    commonService._communications.ClosePopOverSubject.next();
                  }}
                >
                  {item?.isActive ? 'Deactivate' : 'Activate'}
                </ButtonComponent>
              </PopOverComponent>
            </>
          );
        },
      },
    ];
  }, [onUserActivateDeactivate]);

  const downloadExcel = useCallback(() => {
    setDownloading(true);
    try {
      CommonService._user
        .downloadUserListAsExcel()
        .then((response: IAPIResponseType<any>) => {
          setDownloading(false);
          if (response?.data?.url) {
            CommonService.downloadFile(
              response?.data?.url,
              'user_list',
              'xlsx',
            );
          } else {
            CommonService._alert.showToast('No user List', 'error');
          }
        })
        .catch((err: any) => {
          CommonService._alert.showToast('Error While downloading...', 'error');
          setDownloading(false);
        })
        .finally(() => {
          setDownloading(false);
        });
    } catch (err) {
      console.log('Error which dowloading excel', err);
    }
  }, []);

  return (
    <div className={'list-screen user-list-screen'}>
      <div className='list-screen-header-wrapper'>
        <div className='list-screen-header'>
          <div className='list-screen-header-title-and-count'>
            <div className='list-screen-header-title'>Users</div>
            <div className='list-screen-data-count'>
              <TagComponent color={'primary'} label={count + ' Users'} />
            </div>
          </div>
        </div>
        <div className='list-options'>
          <div className={'user-list-dropdown'}>
            <SelectDropdownComponent
              placeholder={'Property'}
              // searchMode={'clientSide'}
              // searchable={true}
              // size={'medium'}
              options={propertyList}
              isClearable={true}
              displayWith={(option) => option.name}
              valueExtractor={(option) => option._id}
              // required={true}
              multiple={true}
              value={filterData.propertyIds}
              // fullWidth={true}
              onUpdate={(value: any) => {
                setFilterData({
                  ...filterData,
                  propertyIds: value,
                  page: 1,
                });
              }}
            />
          </div>
          <SearchComponent
            placeholder='Search user name'
            value={filterData.search}
            onSearchChange={(value) => {
              setFilterData({ ...filterData, search: value, page: 1 });
            }}
          />
          <LinkComponent route={CommonService._routeConfig.UserBulkUpload()}>
            <ButtonComponent
              prefixIcon={<ImageConfig.FilePickerUploadFileIcon />}
              color={'secondary'}
            >
              Bulk Upload Users
            </ButtonComponent>
          </LinkComponent>
          <LinkComponent route={CommonService._routeConfig.UserAddRoute()}>
            <ButtonComponent
              prefixIcon={<ImageConfig.AddOutlinedIcon />}
              color={'primary'}
            >
              Add New User
            </ButtonComponent>
          </LinkComponent>
          <ButtonComponent
            variant='contained'
            color='secondary'
            prefixIcon={<ImageConfig.DownloadIcon />}
            onClick={downloadExcel}
          >
            {downloading ? 'Downloading....' : 'Download'}
          </ButtonComponent>
        </div>
      </div>
      <div className='list-screen-content'>
        <TableWrapperComponent
          url={APIConfig.USER_LIST.URL}
          method={APIConfig.USER_LIST.METHOD}
          id={'users'}
          moduleName={MODULE}
          onDataLoad={handleDataLoad}
          isPaginated={true}
          extraPayload={filterData}
          columns={Columns}
          noDataText={
            filterData.search
              ? 'No such user exist'
              : filterData?.propertyIds
              ? 'No users added to this property'
              : 'No Users have been added yet'
          }
        />
      </div>
    </div>
  );
};

export default UserListScreen;
