import "./InputComponent.scss";
import React, {useCallback} from "react";
import {IInputFieldProps} from "../../../models/form-controls.model";
import {CommonService} from "../../../services";
import ErrorTextComponent from "../../error-text/ErrorTextComponent";
import LabelComponent from "../../label/LabelComponent";
import commonService from "../../../services/common.service";
import ToolTipComponent from "../../tool-tip/ToolTipComponent";
import IconButtonComponent from "../../icon-button/IconButtonComponent";
import {ImageConfig} from "../../../../constants";

export interface InputComponentProps extends IInputFieldProps {
    name?: string;
    value?: string;
    errorMessage?: any;
    labelStyles?: any;
    hasError?: boolean;
    maxlength?: number;
    max?: number;
    classWrapper?: string;
    isCapitalize?: boolean;
    tooltip?: React.ReactNode;
}

const InputComponent = (props: InputComponentProps) => {

    const {
        label,
        titleCase,
        errorMessage,
        hasError,
        className,
        classWrapper,
        inputProps,
        disabled,
        required,
        value,
        validationPattern,
        fullWidth,
        size,
        maxlength,
        max,
        onChange,
        onClick,
        suffix,
        ref,
        prefix,
        labelStyles,
        labelTooltip,
        isCapitalize,
        tooltip,
        ...otherProps
    } = props;

    const inputSize = props.size || "medium";
    const type = props.type || "text";
    const placeholder = props.placeholder || label;


    const handleOnChange = useCallback((event: any) => {
        let nextValue = event.target.value;
        if (titleCase) {
            nextValue = CommonService.Capitalize(nextValue);
        }
        if (isCapitalize) {
            nextValue = commonService.convertUpperCase(nextValue);
        }
        if (onChange) {
            if (validationPattern) {
                const reg = RegExp(validationPattern);
                if (nextValue === "" || reg.test(nextValue)) {
                    onChange(nextValue);
                } else {
                    // console.log(nextValue, reg, reg.test(nextValue), "regex failed");
                }
            } else {
                onChange(nextValue);
            }
        }
    }, [titleCase, validationPattern, onChange, isCapitalize]);


    return (
        <div className={`input-component ${fullWidth ? "fullWidth" : ''} ${disabled ? 'disabled' : ''}` + classWrapper}>
            <div className='d-flex'>
                {label &&
                    <LabelComponent title={label} required={required} tooltip={labelTooltip} styles={labelStyles}/>}
                {tooltip && <div className='mrg-left-5'>
                    <ToolTipComponent
                        position={'right'}
                        textColor={'#183258'}
                        backgroundColor={'#FFFFFF'}
                        tooltip={
                            <>
                                {tooltip}
                            </>
                        }>
                        <div className={'d-flex'}>
                            <IconButtonComponent color='primary'>
                                <ImageConfig.CircleInfo/>
                            </IconButtonComponent>
                        </div>
                    </ToolTipComponent>
                </div>
                }
            </div>
            <input type={type}
                   maxLength={maxlength}
                   placeholder={placeholder}
                   required={required}
                   onClick={onClick}
                   ref={ref}
                   data-testid={props.id}
                   value={value || value?.toString() === "0" ? value : ''}
                   max={max}
                   disabled={disabled}
                   className={'input-field ' + (prefix ? ' has-prefix ' : '') + (suffix ? ' has-suffix ' : '') + (hasError ? "has-error" : '') + ' ' + className + ' ' + inputSize}
                   onChange={(event) => {
                       handleOnChange(event);
                   }}
                   {...inputProps}
                   {...otherProps}
            />
            <>
                {
                    prefix && <span className="input-prefix" onClick={onClick}>{prefix}</span>
                }
                {
                    suffix && <span className="input-suffix" onClick={onClick}>{suffix}</span>
                }
            </>
            {(errorMessage && hasError) && (
                <ErrorTextComponent error={errorMessage}/>
            )}
        </div>
    );

};

export default InputComponent;
