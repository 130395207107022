import './EventMoreInfoComponent.scss';
import { useCallback, useEffect, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import { ImageConfig, Misc } from '../../../../constants';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import * as Yup from 'yup';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import { CommonService } from '../../../../shared/services';
import AccordionComponent from '../../../../shared/components/accordion/AccordionComponent';
import FormikTextAreaComponent from '../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent';
import { IAPIResponseType } from '../../../../shared/models/api.model';

interface EventMoreInfoComponentProps {
  eventDetails?: any;
  prev: () => void;
  next: (data: any) => void;
  steps: any[];
  eventId: string;
  activeStepId: string;
}

const faqsDetailsInfoSchema = Yup.object({
  question: Yup.string()
    .min(3, 'question should be at least 3 characters')
    .max(50, 'question cannot exceed 50 characters'),
  // .required('faq is required'),
  description: Yup.string()
    .min(20, 'description should be at least 20 characters')
    .max(100, 'description cannot exceed 100 characters'),
  // .required('description is required'),
});

const faqsDetailsObject = {
  question: '',
  description: '',
};

export interface IfaqsOptions {
  question: string;
  description: string;
}

const validationSchema = Yup.object().shape({
  // isPerformerAvailable: Yup.boolean(),
  // performers: Yup.array(performerDetailsInfoSchema),
  eventFAQ: Yup.array(faqsDetailsInfoSchema),
  termsAndConditions: Yup.string(),
});

interface formInitialValuesFormSchema {
  // isPerformerAvailable: boolean;
  // performers: IPerformerOptions[];
  eventFAQ: IfaqsOptions[];
  termsAndConditions: string;
}

const formInitialValues: formInitialValuesFormSchema = {
  // isPerformerAvailable: false,
  // performers: [performerDetailsObject],
  eventFAQ: [faqsDetailsObject],
  termsAndConditions: '',
};

const EventMoreInfoComponent = (props: EventMoreInfoComponentProps) => {
  const { eventDetails, prev, next, steps, eventId, activeStepId } = props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  // const {poleTypes} = useSelector((state: IRootReducerState) => state.staticData);

  useEffect(() => {
    if (eventDetails) {
      setInitialValues({
        eventFAQ:
          eventDetails?.eventFAQ && eventDetails?.eventFAQ.length > 0
            ? eventDetails?.eventFAQ
            : [faqsDetailsObject],
        termsAndConditions: eventDetails?.termsAndConditions || '',
      });
    }
  }, [eventDetails]);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      values.eventFAQ.forEach((faq: any, index: number) => {
        if (
          (!faq.question || faq.question === '') &&
          (!faq.description || faq.description === '')
        ) {
          values.eventFAQ.splice(index, 1);
        }
      });
      const payload = {
        ...values,
      };
      CommonService._events
        .AddEventMoreInfoAPICall(eventId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next(response.data);
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [next, eventId],
  );

  return (
    <div className={'more-info-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({ values, errors, validateForm, isValid, setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              {/* <FormDebuggerComponent values={values} errors={errors} showDebugger={false}/> */}
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Back
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={!isValid}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>
              <div className='add-component-content'>
                <div className='scroll-content'>
                  <div className={'row-wrapper-style'}>
                    <div className={'row-wrapper'}>
                      <FormControlLabelComponent
                        className='pdd-top-15 pdd-bottom-5'
                        label={'Terms and Conditions'}
                        level={4}
                        isBold={true}
                      />
                      <div className={'optional-text'}>Optional</div>
                    </div>
                  </div>
                  <Field name={'termsAndConditions'}>
                    {(field: FieldProps) => (
                      <FormikTextAreaComponent
                        label={'T&C'}
                        placeholder={'Enter the terms and conditions here'}
                        required={true}
                        formikField={field}
                        fullWidth={true}
                      />
                    )}
                  </Field>
                  <div className={'row-wrapper-style'}>
                    <div className={'row-wrapper'}>
                      <FormControlLabelComponent
                        className='pdd-top-15 pdd-bottom-5'
                        label={"FAQ's"}
                        level={4}
                        isBold={true}
                      />

                      <div className={'optional-text'}>Optional</div>
                    </div>
                  </div>
                  <FieldArray
                    name='eventFAQ'
                    render={(arrayHelpers) => (
                      <div className='field-array-container'>
                        {values?.eventFAQ &&
                          values?.eventFAQ?.map((item: any, index: any) => {
                            return (
                              <div key={index} className='field-array-item'>
                                <AccordionComponent
                                  title={`FAQ ${index + 1}`}
                                  isExpand={true}
                                  isAccordionOpen={true}
                                  actions={
                                    values.eventFAQ.length > 1 && (
                                      <div
                                        className='accordion-icon-action'
                                        onClick={(e: any) => {
                                          e.stopPropagation();
                                          arrayHelpers.remove(index);
                                        }}
                                      >
                                        <ImageConfig.WarnIcon />
                                      </div>
                                    )
                                  }
                                >
                                  <Field name={`eventFAQ[${index}].question`}>
                                    {(field: FieldProps) => (
                                      <FormikInputComponent
                                        label={'Question'}
                                        placeholder={'Enter the question here'}
                                        type={'text'}
                                        required={true}
                                        formikField={field}
                                        fullWidth={true}
                                      />
                                    )}
                                  </Field>
                                  <Field
                                    name={`eventFAQ[${index}].description`}
                                  >
                                    {(field: FieldProps) => (
                                      <FormikTextAreaComponent
                                        label={'Description'}
                                        placeholder={
                                          'Enter the description here'
                                        }
                                        required={true}
                                        formikField={field}
                                        fullWidth={true}
                                      />
                                    )}
                                  </Field>
                                </AccordionComponent>

                                {index === values.eventFAQ.length - 1 && (
                                  <ButtonComponent
                                    color={'primary'}
                                    variant={'outlined'}
                                    prefixIcon={<ImageConfig.AddOutlinedIcon />}
                                    onClick={() => {
                                      arrayHelpers.push(faqsDetailsObject);
                                    }}
                                    className={'add-more-tower-button'}
                                  >
                                    Add FAQ
                                  </ButtonComponent>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventMoreInfoComponent;
