import { ApiService } from '../index';
import { APIConfig } from '../../../constants';

const AddEventAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT.METHOD](
    APIConfig.ADD_EVENT.URL,
    payload,
  );
};

const GetEventsList = () => {
  // @ts-ignore
  return ApiService[APIConfig.EVENTS_LIST.METHOD](APIConfig.EVENTS_LIST.URL);
};

const AddEventBookingAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_BOOKING.METHOD](
    APIConfig.ADD_EVENT_BOOKING.URL(eventId),
    payload,
  );
};

const AddEventMoreInfoAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_MORE_INFO.METHOD](
    APIConfig.ADD_EVENT_MORE_INFO.URL(eventId),
    payload,
  );
};

const AddEventPerformersAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_PERFORMER.METHOD](
    APIConfig.ADD_EVENT_PERFORMER.URL,
    payload,
  );
};
const getEventGalleryImagesAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_EVENT_GALLERY.METHOD](
    APIConfig.GET_EVENT_GALLERY.URL,
    payload,
  );
};
const uploadEventGalleryAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_GALLERY.METHOD](
    APIConfig.ADD_EVENT_GALLERY.URL(eventId),
    payload,
  );
};
const cancelEventAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.CANCEL_EVENT.METHOD](
    APIConfig.CANCEL_EVENT.URL(eventId),
    payload,
  );
};

const removeEventGalleryImageAPICall = (eventId: string, imageId: any) => {
  // @ts-ignore
  return ApiService[APIConfig.REMOVE_EVENT_GALLERY.METHOD](
    APIConfig.REMOVE_EVENT_GALLERY.URL(eventId, imageId),
  );
};

const setDefaultEventGalleryImageAPICall = (eventId: string, imageId: any) => {
  // @ts-ignore
  return ApiService[APIConfig.DEFAULT_IMAGE_EVENT_GALLERY.METHOD](
    APIConfig.DEFAULT_IMAGE_EVENT_GALLERY.URL(eventId, imageId),
  );
};

const editEventDetailsAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.EDIT_EVENT.METHOD](
    APIConfig.EDIT_EVENT.URL(eventId),
    payload,
  );
};

const addAnnouncement = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.ADD_EVENT_ANNOUNCEMENT.METHOD](
    APIConfig.ADD_EVENT_ANNOUNCEMENT.URL(payload),
  );
};

// const CancelPollAPICall = (pollId: any, payload: any) => {
//     // @ts-ignore
//     return ApiService[APIConfig.CANCEL_POLL.METHOD](APIConfig.CANCEL_POLL.URL(pollId), payload);
// }

const GetEventDetailsAPICall = (eventId: string, payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_EVENT_DETAILS.METHOD](
    APIConfig.GET_EVENT_DETAILS.URL(eventId),
    payload,
  );
};

const GetEventListAPICall = (payload: any) => {
  // @ts-ignore
  return ApiService[APIConfig.GET_EVENT_LIST.METHOD](
    APIConfig.GET_EVENT_LIST.URL,
    payload,
  );
};
//
// const getPollStatsAPICall = (pollId: string, payload: any) => {
//     // @ts-ignore
//     return ApiService[APIConfig.GET_POLL_STATS.METHOD](APIConfig.GET_POLL_STATS.URL(pollId), payload);
// }

const downloadEventListAsExcel = () => {
  //@ts-ignore
  return ApiService[APIConfig.EVENT_LIST.METHOD](
    APIConfig.EVENT_LIST.URL + `?download=true`,
  );
};

const EventsService = {
  AddEventAPICall,
  GetEventsList,
  AddEventBookingAPICall,
  AddEventMoreInfoAPICall,
  AddEventPerformersAPICall,
  GetEventDetailsAPICall,
  GetEventListAPICall,
  getEventGalleryImagesAPICall,
  uploadEventGalleryAPICall,
  cancelEventAPICall,
  removeEventGalleryImageAPICall,
  editEventDetailsAPICall,
  setDefaultEventGalleryImageAPICall,
  addAnnouncement,
  downloadEventListAsExcel,
};

export default EventsService;
