import "./StepperComponent.scss";
import BadgeComponent from "../badge/BadgeComponent";

interface StepperComponentProps {
    activeStepId: string;
    steps: { id: string, title: string }[];
}

const StepperComponent = (props: StepperComponentProps) => {

    const {
        activeStepId,
        steps,
    } = props;

    return (
        <div className={'stepper-component'}>
            <div className={'stepper-container'}>
                {
                    steps.map((step, index: number) => {
                        const isCurrentStep = activeStepId === step.id;
                        const stepId = step.id;
                        const stepTitle = step.title;
                        const stepNumber = index + 1;
                        const stepBadgeVariant = isCurrentStep ? 'filled' : 'outlined';
                        const stepClass = isCurrentStep ? 'stepper-item active' : 'stepper-item';
                        return (
                            <div className={`stepper-item ${stepClass}`} key={stepId}>
                                <div className={'stepper-number'}>
                                    <BadgeComponent variant={stepBadgeVariant}>
                                        {stepNumber}
                                    </BadgeComponent>
                                </div>
                                {
                                    isCurrentStep && <div className={'stepper-item-title'}>
                                        {stepTitle}
                                    </div>
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );

};

export default StepperComponent;


// #####************** START USAGE **************#####
// import StepperComponent from "../../shared/components/stepper/StepperComponent";
// <StepperComponent
//     activeStepId={'visit_details'}
//     steps={[
//         {
//             title: 'Visit Details',
//             id: 'visit_details',
//         },
//         {
//             title: 'Patient Details',
//             id: 'patient_details',
//         },
//         {
//             title: 'Payment',
//             id: 'payment',
//         }
//     ]}
// />
// #####************** END USAGE **************#####
