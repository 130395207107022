import './CampusDetailsComponent.scss';
import * as Yup from 'yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { CommonService } from '../../../../shared/services';
import { IAPIResponseType } from '../../../../shared/models/api.model';
import { ImageConfig, Misc } from '../../../../constants';
import ButtonComponent from '../../../../shared/components/button/ButtonComponent';
import StepperComponent from '../../../../shared/components/stepper/StepperComponent';
import FormikInputComponent from '../../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormControlLabelComponent from '../../../../shared/components/form-control-label/FormControlLabelComponent';
import FormikRadioButtonGroupComponent from '../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import HorizontalLineComponent from '../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import AccordionComponent from '../../../../shared/components/accordion/AccordionComponent';
import DrawerComponent from '../../../../shared/components/drawer/DrawerComponent';
import FloorDetailsDrawerComponent from '../../../../shared/components/property-drawer/FloorDetailsDrawerComponent';
import ButtonGroupComponent from '../../../../shared/components/button-group/ButtonGroupComponent';
import TableComponent from '../../../../shared/components/table/TableComponent';
import FormDebuggerComponent from '../../../../shared/components/form-debugger/FormDebuggerComponent';

interface CampusDetailsComponentProps {
  prev: () => void;
  next: (data: any) => void;
  propertyId: any;
  propertyDetails: any;
  steps: any[];
  activeStepId: string;
}

interface BasementsSchema {
  basementNumber: any;
  leasableArea: string;
}

interface FloorsSchema {
  floorNumber: any;
  leasableArea: string;
}

interface TowerSchema {
  _id?: any;
  name: any;
  stiltArea: string;
  totalParkingSlots: string;
  totalTowerParkingSlots: string;
  isLeasableAreaSameForAllBasement: boolean;
  isLeasableAreaSameForAllFloors: boolean;
  numberOfFloors: string;
  numberOfBasements: string;
  basementLeasableArea: string;
  floorLeasableArea: string;
  includeGroundFloor: boolean;
  groundFloorLeasableArea: string;
  basements: BasementsSchema[];
  officeFloors: FloorsSchema[];
}

interface formInitialValuesFormSchema {
  numberOfAccessPoints: string;
  parkingLevel: string;
  previousParkingLevel: string;
  totalParkingSlots: string;
  totalPropertyParkingSlots: string;
  towers: TowerSchema[];
}

const formInitialValues: formInitialValuesFormSchema = {
  numberOfAccessPoints: '',
  parkingLevel: '',
  previousParkingLevel: '',
  totalParkingSlots: '',
  totalPropertyParkingSlots: '',
  towers: [],
};

const CampusDetailsComponent = (props: CampusDetailsComponentProps) => {
  const { prev, next, propertyId, propertyDetails, steps, activeStepId } =
    props;
  const [initialValues, setInitialValues] =
    useState<formInitialValuesFormSchema>(formInitialValues);
  const [openFloorDrawer, setOpenFloorDrawer] = useState<boolean>(false);
  const [towerId, setTowerId] = useState<any>(undefined);
  const [towers, setTowers] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<any>({});
  const FormikRef: any = useRef();

  useEffect(() => {
    if (propertyDetails?.campusDetails) {
      setInitialValues((prev: any) => ({
        ...prev,
        numberOfAccessPoints:
          propertyDetails?.campusDetails?.numberOfAccessPoints,
        parkingLevel: propertyDetails?.campusDetails?.parkingLevel,
        previousParkingLevel: propertyDetails?.campusDetails?.parkingLevel,
        totalParkingSlots: propertyDetails?.campusDetails?.totalParkingSlots,
        previousPropertyParkingSlots:
          propertyDetails?.campusDetails?.totalParkingSlots,
        totalPropertyParkingSlots:
          propertyDetails?.campusDetails?.parkingLevel === 'propertyLevel'
            ? propertyDetails?.campusDetails?.totalParkingSlots
            : '',
      }));
    }
  }, [propertyDetails]);

  const getTowersList = useCallback(() => {
    if (propertyId) {
      CommonService._property
        .getTowersListAPICall(propertyId)
        .then((response: IAPIResponseType<any>) => {
          setTowers(response?.data?.towers);
        })
        .catch((error: any) => {
          console.log('ERROR');
        });
    }
  }, [propertyId]);

  useEffect(() => {
    getTowersList();
  }, [getTowersList]);

  useEffect(() => {
    if (towers) {
      setInitialValues((prev) => ({
        ...prev,
        towers: towers?.map((tower: any) => ({
          ...tower,
          previousFloorLeasableArea: tower.floorLeasableArea,
          previousBasementLeasableArea: tower.basementLeasableArea,
          previousGroundFloorLeasableArea: tower.groundFloorLeasableArea,
          totalTowerParkingSlots: tower.totalParkingSlots,
          previousTowerParkingSlots: prev.totalParkingSlots,
          basements: tower?.basements?.map((basement: any) => ({
            basementNumber: basement.basementNumber,
            basementTitle: basement.basementTitle,
            leasableArea: basement.leasableArea,

            occupiedArea: basement.occupiedArea,
            towerId: tower._id,
            previousLeasableArea: basement.leasableArea,
          })),
          officeFloors: tower?.officeFloors?.map((floor: any) => ({
            floorNumber: floor.floorNumber,
            floorTitle: floor.floorTitle,
            leasableArea: floor.leasableArea,
            vacantArea: floor.vacantArea,
            occupiedArea: floor.occupiedArea,
            towerId: tower._id,
            previousLeasableArea: floor.leasableArea,
          })),
        })),
      }));
    }
  }, [towers]);

  const validationSchema = Yup.object({
    numberOfAccessPoints: Yup.number()
      .min(1, 'Number of access points should be at least 1')
      .max(100, 'Number of access points cannot exceed 100')
      .integer('Number of access points must be an integer')
      .required('Number of access points is required'),
    parkingLevel: Yup.string().required('Parking level is required'),
    totalPropertyParkingSlots: Yup.string().when(
      ['$parkingLevel', 'previousPropertyParkingSlots'],
      {
        is: (
          parkingLevel: string,
          previousPropertyParkingSlots: number | undefined,
        ) => parkingLevel === 'propertyLevel',
        then: Yup.string()
          .min(1, 'Total parking slots should be at least 1')
          .required('Total parking slots is required')
          .test(
            'greater-or-equal',
            'Parking Slots cannot be less than the entered value',
            function (value) {
              const { previousPropertyParkingSlots } = this.parent;
              if (
                previousPropertyParkingSlots !== undefined &&
                value !== undefined
              ) {
                return value >= previousPropertyParkingSlots;
              }
              return true;
            },
          )
          .nullable(),
        otherwise: Yup.string().notRequired(),
      },
    ),
    towers: Yup.array()
      .of(
        Yup.object({
          name: Yup.string()
            .min(3, 'Tower Name should be at least 3 characters')
            .max(50, 'Tower Name cannot exceed 50 characters')
            .required('Tower Name is required')
            .test(
              'unique-name',
              'Tower Name must be unique',
              function (value: any) {
                const towers = FormikRef?.current?.values?.towers;
                // @ts-ignore
                const currentIndex = this.options.index;
                return towers?.every((tower: any, index: any) => {
                  if (index === currentIndex) return true;
                  return tower?.name?.toLowerCase() !== value.toLowerCase();
                });
              },
            ),
          stiltArea: Yup.number()
            .min(1, 'Stilt area must be atleast 1')
            .required('Stilts Area is required'),
          totalTowerParkingSlots: Yup.number().when(
            ['$parkingLevel', 'previousTowerParkingSlots'],
            {
              is: (parkingLevel: string, previousTowerParkingSlots: number) =>
                parkingLevel === 'towerLevel',
              then: Yup.number()
                .required('Total parking slots is required')
                .min(1, 'Total parking slots should be at least 1')
                .test(
                  'greater-or-equal',
                  'Parking Slots cannot be less than the entered value',
                  function (value) {
                    const { previousTowerParkingSlots } = this.parent;
                    if (
                      previousTowerParkingSlots !== undefined &&
                      value !== undefined
                    ) {
                      return value >= previousTowerParkingSlots;
                    }
                    return true;
                  },
                ),
              otherwise: Yup.number().notRequired(),
            },
          ),
          isLeasableAreaSameForAllBasement: Yup.boolean().when(
            'basementToggle',
            {
              is: true,
              then: Yup.boolean().required('Leasable area is required'),
            },
          ),
          isLeasableAreaSameForAllFloors: Yup.boolean().when('floorToggle', {
            is: true,
            then: Yup.boolean().required('Leasable area is required'),
          }),
          numberOfFloors: Yup.number().when('floorToggle', {
            is: true,
            then: Yup.number()
              .required('Number of floors is required')
              .min(1, 'Number of floors must be atleast 1')
              .max(100, 'Number of floors must not exceed 100'),
          }),
          numberOfBasements: Yup.number().when('basementToggle', {
            is: true,
            then: Yup.number()
              .required('Number of basements is required')
              .min(1, 'Number of floors must be atleast 1')
              .max(100, 'Number of floors must not exceed 100'),
          }),
          basementLeasableArea: Yup.number().when(
            [
              'isLeasableAreaSameForAllBasement',
              'basementToggle',
              '_id',
              'previousBasementLeasableArea',
            ],
            {
              is: (
                isLeasableAreaSameForAllBasement: boolean,
                basementToggle: boolean,
                _id: string | undefined,
                previousBasementLeasableArea: number | undefined,
              ) => isLeasableAreaSameForAllBasement && basementToggle,
              then: Yup.number()
                .min(1, 'Basement leasable area must be atleast 1')
                .required('Basement leasable area is required')
                .test(
                  'greater-or-equal',
                  'Basement leasable area cannot be less than the entered value',
                  function (value) {
                    const { _id, previousBasementLeasableArea } = this.parent;
                    if (
                      _id &&
                      previousBasementLeasableArea !== undefined &&
                      value !== undefined
                    ) {
                      return value >= previousBasementLeasableArea;
                    }
                    return true;
                  },
                ),
            },
          ),
          floorLeasableArea: Yup.number().when(
            [
              'isLeasableAreaSameForAllFloors',
              'floorToggle',
              '_id',
              'previousFloorLeasableArea',
            ],
            {
              is: (
                isLeasableAreaSameForAllFloors: boolean,
                floorToggle: boolean,
                _id: string | undefined,
                previousFloorLeasableArea: number | undefined,
              ) => isLeasableAreaSameForAllFloors && floorToggle,
              then: Yup.number()
                .min(1, 'Floor leasable area must be atleast 1')
                .required('Floor leasable area is required')
                .test(
                  'greater-or-equal',
                  'Floor leasable area cannot be less than the entered value',
                  function (value) {
                    const { _id, previousFloorLeasableArea } = this.parent;
                    if (
                      _id &&
                      previousFloorLeasableArea !== undefined &&
                      value !== undefined
                    ) {
                      return value >= previousFloorLeasableArea;
                    }
                    return true;
                  },
                ),
              otherwise: Yup.number().notRequired(),
            },
          ),
          groundFloorLeasableArea: Yup.number().when(
            ['includeGroundFloor', '_id', 'previousGroundFloorLeasableArea'],
            {
              is: (
                includeGroundFloor: boolean,
                _id: string | undefined,
                previousGroundFloorLeasableArea: number | undefined,
              ) => includeGroundFloor === true,
              then: Yup.number()
                .required('Ground floor leasable area is required')
                .min(1, 'Floor leasable area must be atleast 1')
                .test(
                  'greater-or-equal',
                  'Floor leasable area cannot be less than the entered value',
                  function (value) {
                    const { _id, previousGroundFloorLeasableArea } =
                      this.parent;
                    if (
                      _id &&
                      previousGroundFloorLeasableArea !== undefined &&
                      value !== undefined
                    ) {
                      return value >= previousGroundFloorLeasableArea;
                    }
                    return true;
                  },
                ),
            },
          ),
          basements: Yup.array().when(
            [
              'isLeasableAreaSameForAllBasement',
              'basementToggle',
              'towerId',
              'previousLeasableArea',
            ],
            {
              is: (
                isLeasableAreaSameForAllBasement: boolean,
                basementToggle: boolean,
                towerId: string | undefined,
                previousLeasableArea: number | undefined,
              ) => !isLeasableAreaSameForAllBasement && basementToggle,
              then: Yup.array().of(
                Yup.object({
                  leasableArea: Yup.number()
                    .required('leasable area is required')
                    .test(
                      'greater-or-equal',
                      'Leasable area cannot be less than the entered value',
                      function (value) {
                        const { previousLeasableArea, towerId } = this.parent;

                        if (
                          towerId &&
                          previousLeasableArea !== undefined &&
                          value !== undefined
                        ) {
                          return value >= previousLeasableArea;
                        }
                        return true;
                      },
                    ),
                }),
              ),
              otherwise: Yup.array().of(
                Yup.object({
                  leasableArea: Yup.number().notRequired(),
                }),
              ),
            },
          ),
          officeFloors: Yup.array().when(
            [
              'isLeasableAreaSameForAllFloors',
              'floorToggle',
              'towerId',
              'previousLeasableArea',
            ],
            {
              is: (
                isLeasableAreaSameForAllFloors: boolean,
                floorToggle: boolean,
                towerId: string | undefined,
                previousLeasableArea: number | undefined,
              ) => !isLeasableAreaSameForAllFloors && floorToggle,
              then: Yup.array().of(
                Yup.object({
                  leasableArea: Yup.number()
                    .required('Leasable area is required')
                    .test(
                      'greater-or-equal',
                      'Leasable area cannot be less than the entered value',
                      function (value) {
                        const { previousLeasableArea, towerId } = this.parent;

                        if (
                          towerId &&
                          previousLeasableArea !== undefined &&
                          value !== undefined
                        ) {
                          return value >= previousLeasableArea;
                        }
                        return true;
                      },
                    ),
                }),
              ),
              otherwise: Yup.array().of(
                Yup.object({
                  leasableArea: Yup.number().notRequired(),
                }),
              ),
            },
          ),
        }),
      )
      .min(1, 'At least one tower is required'),
  });

  const deleteTenantAdminHandler = useCallback(
    (towerId: string, arrayHelpers: any) => {
      const payload = { towerId: towerId };
      CommonService._property
        .removeTowerDetailsAPICall(propertyId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          getTowersList();
          arrayHelpers.remove(towerId);
        })
        .catch((error: any) => {
          CommonService._alert.showToast(
            error[Misc.API_RESPONSE_MESSAGE_KEY],
            'error',
          );
        })
        .finally(() => {});
    },
    [propertyId, getTowersList],
  );

  const TowerTab = useCallback(
    (tower: any) => [
      {
        title: 'Office Floors',
        id: 'officeFloors',
        badge: {
          text: tower?.officeFloors?.length || '0',
          color: 'primary',
        },
      },
      {
        title: 'Basements',
        id: 'basements',
        badge: {
          text: tower?.basements?.length || '0',
          color: 'primary',
        },
      },
    ],
    [],
  );

  const calculateTotalParkingSlots = useCallback((values: any) => {
    const totalParkingSlots = values?.towers?.reduce(
      (sum: number, tower: any) => {
        return sum + (tower?.totalTowerParkingSlots || 0);
      },
      0,
    );
    return totalParkingSlots;
  }, []);

  const onSubmit = useCallback(
    (values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
      setSubmitting(true);
      let parkingSlots: any;
      if (values?.parkingLevel === 'propertyLevel') {
        parkingSlots = values?.totalPropertyParkingSlots;
      }
      if (values?.parkingLevel === 'towerLevel') {
        parkingSlots = calculateTotalParkingSlots(values);
      }
      const payload = {
        campusDetails: {
          numberOfAccessPoints: parseInt(values?.numberOfAccessPoints),
          parkingLevel: values?.parkingLevel,
          totalParkingSlots: parkingSlots,
          totalPropertyParkingSlots: values?.totalPropertyParkingSlots,
        },
      };
      CommonService._property
        .AddPropertyCampusAPICall(propertyId, payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          next(response.data);
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [propertyId, next, calculateTotalParkingSlots],
  );

  const handleOpenFloorDetailsDrawer = useCallback((id: any) => {
    setOpenFloorDrawer(true);
    setTowerId(id);
  }, []);

  const addCampusDetails = useCallback(
    (values: any) => {
      let parkingSlots: any;
      if (values?.parkingLevel === 'propertyLevel') {
        parkingSlots = values?.totalPropertyParkingSlots;
      }
      if (values?.parkingLevel === 'towerLevel') {
        parkingSlots = calculateTotalParkingSlots(values);
      }
      const payload = {
        campusDetails: {
          numberOfAccessPoints: parseInt(values?.numberOfAccessPoints),
          parkingLevel: values?.parkingLevel,
          totalParkingSlots: parkingSlots,
          totalPropertyParkingSlots: values?.totalPropertyParkingSlots,
        },
      };
      try {
        CommonService._property
          .AddPropertyCampusAPICall(propertyId, payload)
          .then((response: IAPIResponseType<any>) => {
            setInitialValues((prev: any) => ({
              ...prev,
              numberOfAccessPoints: parseInt(values?.numberOfAccessPoints),
              parkingLevel: values?.parkingLevel,
              totalParkingSlots: parkingSlots,
              totalPropertyParkingSlots: values?.totalPropertyParkingSlots,
            }));
          })
          .catch((error: any) => {
            // CommonService.handleErrors(setErrors, error);
          })
          .finally(() => {});
      } catch (err: any) {
        console.log('ERROR WHILE ADDING CAMPUS DETAILS');
      }
    },
    [calculateTotalParkingSlots, propertyId, setInitialValues],
  );

  const handleSaveDetails = useCallback(
    (values: any, towerId: any) => {
      const payload = { ...values.towers[towerId] };

      payload.totalParkingSlots = payload.totalTowerParkingSlots;
      const updateBasements = (
        numberOfBasements: any,
        basementLeasableArea: any,
        basements: any,
      ) => {
        const updatedBasements = (basements || [])
          .slice(0, numberOfBasements)
          .map((basement: any) => ({
            basementNumber: basement.basementNumber,
            basementTitle: basement.basementTitle,
            leasableArea: basementLeasableArea,
            vacantArea: basement.vacantArea,
            occupiedArea: basement.occupiedArea,
          }));

        for (let i = updatedBasements.length; i < numberOfBasements; i++) {
          updatedBasements.push({
            basementNumber: i + 1,
            basementTitle: (i + 1).toString(),
            leasableArea: basementLeasableArea,
            vacantArea: 0,
            occupiedArea: 0,
          });
        }

        return updatedBasements;
      };

      const updateFloors = (
        numberOfFloors: any,
        floorLeasableArea: any,
        floors: any,
      ) => {
        const updatedFloors = (floors || [])
          .slice(0, numberOfFloors)
          .map((floor: any) => ({
            floorNumber: floor.floorNumber,
            floorTitle: floor.floorTitle,
            leasableArea: floorLeasableArea,
            vacantArea: floor.vacantArea,
            occupiedArea: floor.occupiedArea,
          }));

        for (let i = updatedFloors.length; i < numberOfFloors; i++) {
          updatedFloors.push({
            floorNumber: i + 1,
            floorTitle: (i + 1).toString(),
            leasableArea: floorLeasableArea,
            vacantArea: 0,
            occupiedArea: 0,
          });
        }

        return updatedFloors;
      };

      if (payload._id) {
        if (payload.isLeasableAreaSameForAllBasement) {
          payload.basements = updateBasements(
            payload.numberOfBasements,
            payload.basementLeasableArea,
            payload.basements,
          );
        }
        if (payload.isLeasableAreaSameForAllFloors) {
          payload.officeFloors = updateFloors(
            payload.numberOfFloors,
            payload.floorLeasableArea,
            payload.officeFloors,
          );
        } else {
          payload.officeFloors = (payload.officeFloors || []).filter(
            (floor: any) => floor.floorNumber !== 99999,
          );
        }
      } else {
        if (payload.isLeasableAreaSameForAllBasement) {
          payload.basements = Array.from(
            { length: payload.numberOfBasements },
            (_, index) => ({
              basementNumber: index + 1,
              basementTitle: (index + 1).toString(),
              leasableArea: payload.basementLeasableArea,
            }),
          );
        }
        if (payload.isLeasableAreaSameForAllFloors) {
          payload.officeFloors = Array.from(
            { length: payload.numberOfFloors },
            (_, index) => ({
              floorNumber: index + 1,
              floorTitle: (index + 1).toString(),
              leasableArea: payload.floorLeasableArea,
            }),
          );
        } else {
          payload.officeFloors = (payload.officeFloors || []).filter(
            (floor: any) => floor.floorNumber !== 99999,
          );
        }
      }

      // if (payload.includeGroundFloor) {
      //   const officeFloors = [...(payload.officeFloors || [])];
      //   officeFloors.unshift({
      //     floorNumber: 99999,
      //     floorTitle: 'Ground Floor',
      //     leasableArea: payload.groundFloorLeasableArea,
      //   });

      //   payload.officeFloors = officeFloors;
      // }
      if (payload.includeGroundFloor) {
        const officeFloors = [...(payload.officeFloors || [])];
        const existingGroundFloor = officeFloors?.find(
          (floor) => floor.floorNumber === 99999,
        );

        officeFloors?.unshift({
          floorNumber: 99999,
          floorTitle: 'Ground Floor',
          leasableArea: payload.groundFloorLeasableArea,
          vacantArea: existingGroundFloor?.vacantArea || 0,
          occupiedArea: existingGroundFloor?.occupiedArea || 0,
        });

        payload.officeFloors = officeFloors;
      }

      let apiCall;
      if (values?.towers[towerId]?._id) {
        payload.towerId = values?.towers[towerId]?._id;
        apiCall = CommonService._property.EditTowerAPICall(propertyId, payload);
      } else {
        apiCall = CommonService._property.AddTowerAPICall(propertyId, payload);
      }

      apiCall
        .then((response) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          addCampusDetails(values);
          getTowersList();
        })
        .catch((error) => {
          CommonService._alert.showToast('Error while adding towers', 'error');
        })
        .finally(() => {});
    },
    [propertyId, getTowersList, addCampusDetails],
  );

  const handleDeleteDrawer = useCallback(
    (id: string, arrayHelpers: any) => {
      CommonService.onConfirm({
        confirmationTitle: 'Delete Tower',
        confirmationDescription:
          'Deleting this tower will effect the linked tenants and amenities.',
        no: {
          color: 'default',
          text: 'Cancel',
          variant: 'outlined',
        },
        yes: {
          color: 'error',
          text: 'Confirm',
          variant: 'contained',
        },
      }).then((result: any) => {
        deleteTenantAdminHandler(id, arrayHelpers);
      });
    },
    [deleteTenantAdminHandler],
  );

  return (
    <div className={'campus-details-component add-screen'}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={onSubmit}
        innerRef={FormikRef}
      >
        {({
          values,
          errors,
          validateForm,
          isValid,
          setFieldValue,
          isSubmitting,
          validateField,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            validateForm();
          }, [validateForm, values]);
          return (
            <Form className='t-form' noValidate={true}>
              <FormDebuggerComponent
                values={values}
                errors={errors}
                showDebugger={false}
              />
              <div className='add-component-header'>
                {
                  <ButtonComponent
                    variant={'text'}
                    color={'secondary'}
                    onClick={prev}
                    prefixIcon={<ImageConfig.ArrowLeftIcon />}
                  >
                    Back
                  </ButtonComponent>
                }
                <div>
                  <StepperComponent activeStepId={activeStepId} steps={steps} />
                </div>

                <ButtonComponent
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  type={'submit'}
                  suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                >
                  Next
                </ButtonComponent>
              </div>

              <div className='add-component-content'>
                <Field name={'numberOfAccessPoints'}>
                  {(field: FieldProps) => (
                    <FormikInputComponent
                      label={'No. of Access Points'}
                      placeholder={'Example: 4'}
                      type={'text'}
                      validationPattern={new RegExp(/^[0-9]{0,3}$/)}
                      required={true}
                      formikField={field}
                      fullWidth={true}
                      onChange={(value) => {
                        setFieldValue(
                          'numberOfAccessPoints',
                          value ? parseInt(value) : '',
                        );
                      }}
                    />
                  )}
                </Field>
                <div>
                  <FormControlLabelComponent
                    label='Should parking be provided at the property level or the tower level ?'
                    level={5}
                  />
                  <div className='d-flex'>
                    <Field name='parkingLevel'>
                      {(field: FieldProps) => (
                        <FormikRadioButtonGroupComponent
                          className='mrg-top-10'
                          formikField={field}
                          options={
                            CommonService._staticData.parkingLevelOptions
                          }
                          displayWith={(option: any) => option.title}
                          keyExtractor={(option: any) => option.title}
                          onChange={(value) => {
                            setFieldValue(`parkingLevel`, value);
                            // if (value === 'propertyLevel') {
                            //   values?.towers?.forEach((tower, index) => {
                            //     setFieldValue(
                            //       `towers[${index}].totalTowerParkingSlots`,
                            //       '',
                            //     );
                            //   });
                            // } else if (value === 'towerLevel') {
                            //   setFieldValue('totalPropertyParkingSlots', '');
                            // }
                          }}
                        />
                      )}
                    </Field>
                    {values?.parkingLevel === 'towerLevel' &&
                      values?.towers?.some(
                        (tower) => !tower.totalTowerParkingSlots,
                      ) && (
                        <div className='mrg-left-5 mrg-top-10 italic-text-lite'>
                          (Please fill parking slots in each tower)
                        </div>
                      )}
                  </div>
                </div>
                {values?.parkingLevel === 'propertyLevel' && (
                  <div className='mrg-bottom-10'>
                    <Field name='totalPropertyParkingSlots'>
                      {(field: FieldProps) => (
                        <FormikInputComponent
                          formikField={field}
                          required={true}
                          placeholder='Example: 20'
                          fullWidth={true}
                          label={`Total Parking Slots* ${
                            values?.previousParkingLevel === 'towerLevel'
                              ? '(Already Present Slots - ' +
                                values?.totalParkingSlots +
                                ')'
                              : ''
                          }`}
                          onChange={(value) => {
                            setFieldValue(
                              'totalPropertyParkingSlots',
                              value ? parseInt(value) : '',
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}
                {values?.parkingLevel === 'towerLevel' && (
                  <div>
                    <div className='property-parking-title'>
                      Total Parking Slots*
                    </div>
                    <div className={'parking-badge'}>
                      {calculateTotalParkingSlots(values)}
                    </div>
                  </div>
                )}
                <HorizontalLineComponent />
                <FieldArray
                  name='towers'
                  render={(arrayHelpers) => (
                    <div>
                      <div className='mrg-top-20 mrg-bottom-20 flex-wrapper'>
                        <FormControlLabelComponent
                          className='mrg-top-10'
                          label='Tower Details'
                          level={5}
                          isBold
                        />
                        <ButtonComponent
                          variant='outlined'
                          prefixIcon={<ImageConfig.AddOutlinedIcon />}
                          disabled={
                            values?.parkingLevel?.length === 0 ||
                            (values.parkingLevel === 'propertyLevel' &&
                              values.totalPropertyParkingSlots.length === 0) ||
                            (values.towers.length > 0 &&
                              values.towers[values.towers.length - 1].name
                                .length < 3)
                          }
                          onClick={() => {
                            const towers = {
                              name: '',
                              stiltArea: '',
                              totalParkingSlots:
                                values.parkingLevel === 'propertyLevel'
                                  ? values?.totalParkingSlots
                                  : '',
                              totalTowerParkingSlots: '',
                              isLeasableAreaSameForAllBasement: true,
                              isLeasableAreaSameForAllFloors: true,
                              numberOfFloors: '',
                              numberOfBasements: '',
                              basementLeasableArea: '',
                              floorLeasableArea: '',
                              includeGroundFloor: false,
                              groundFloorLeasableArea: '',
                              basementToggle: false,
                              floorToggle: false,
                              basements: [],
                              officeFloors: [],
                            };

                            arrayHelpers.push(towers);
                          }}
                        >
                          Add Tower
                        </ButtonComponent>
                      </div>
                      {values?.towers &&
                        values?.towers?.map((item: any, index: any) => {
                          const selectedTab =
                            currentTab[index + 1] || 'officeFloors';
                          return (
                            <div key={index}>
                              <AccordionComponent
                                title={`Tower ${index + 1}`}
                                isAccordionOpen={!values?.towers[index]?._id}
                                actions={
                                  <div className='flex-wrapper'>
                                    <ButtonComponent
                                      size='small'
                                      disabled={
                                        isSubmitting ||
                                        !isValid ||
                                        (!values?.towers[index].name &&
                                          !values?.towers[index].stiltArea)
                                      }
                                      onClick={handleSaveDetails.bind(
                                        null,
                                        values,
                                        index,
                                      )}
                                    >
                                      Save
                                    </ButtonComponent>
                                    <div
                                      className='accordion-icon-action mrg-left-20 mrg-right-20'
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (item?._id) {
                                          handleDeleteDrawer(
                                            item?._id,
                                            arrayHelpers,
                                          );
                                          // deleteTenantAdminHandler(
                                          //   item?._id,
                                          //   arrayHelpers,
                                          // );
                                        } else {
                                          arrayHelpers.remove(index);
                                        }
                                      }}
                                    >
                                      <ImageConfig.WarnIcon />
                                    </div>
                                  </div>
                                }
                              >
                                <Field name={`towers[${index}].name`}>
                                  {(field: FieldProps) => (
                                    <FormikInputComponent
                                      label={'Tower Name / Tower Number'}
                                      placeholder={'Example: Tower A'}
                                      type={'text'}
                                      required={true}
                                      formikField={field}
                                      fullWidth={true}
                                    />
                                  )}
                                </Field>
                                <div className='ts-row'>
                                  <div
                                    className={` ${
                                      values?.parkingLevel === 'towerLevel'
                                        ? 'ts-col-6'
                                        : 'ts-col-12'
                                    }`}
                                  >
                                    <Field name={`towers[${index}].stiltArea`}>
                                      {(field: FieldProps) => (
                                        <FormikInputComponent
                                          label={'Stilt Area'}
                                          placeholder={'Example: 1,00,000sqft'}
                                          type={'text'}
                                          validationPattern={
                                            new RegExp(/^[0-9]*$/)
                                          }
                                          required={true}
                                          formikField={field}
                                          fullWidth={true}
                                          onChange={(value) => {
                                            setFieldValue(
                                              `towers[${index}].stiltArea`,
                                              value ? parseInt(value) : '',
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  {values?.parkingLevel === 'towerLevel' && (
                                    <div className='ts-col-6'>
                                      <Field
                                        name={`towers[${index}].totalTowerParkingSlots`}
                                      >
                                        {(field: FieldProps) => (
                                          <FormikInputComponent
                                            label={`Total Parking Slots* ${
                                              values?.previousParkingLevel ===
                                              'propertyLevel'
                                                ? '(Already Present Slots - ' +
                                                  values?.totalParkingSlots +
                                                  ')'
                                                : ''
                                            }`}
                                            placeholder={'Example: 20'}
                                            type={'text'}
                                            validationPattern={
                                              new RegExp(/^[0-9]*$/)
                                            }
                                            required={true}
                                            formikField={field}
                                            fullWidth={true}
                                            readOnly={
                                              values.parkingLevel ===
                                              'propertyLevel'
                                            }
                                            onChange={(value) => {
                                              setFieldValue(
                                                `towers[${index}].totalTowerParkingSlots`,
                                                value ? parseInt(value) : '',
                                              );
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  )}
                                </div>
                                {values?.towers[index]?._id ? (
                                  <AccordionComponent
                                    title='Floor Details'
                                    actions={
                                      <div className='mrg-right-20'>
                                        <ButtonComponent
                                          variant='outlined'
                                          size='small'
                                          onClick={handleOpenFloorDetailsDrawer.bind(
                                            null,
                                            index,
                                          )}
                                        >
                                          Edit Floor Details
                                        </ButtonComponent>
                                      </div>
                                    }
                                  >
                                    <ButtonGroupComponent
                                      selected={selectedTab}
                                      buttons={TowerTab(item)}
                                      onChange={(value) => {
                                        setCurrentTab((prev: any) => ({
                                          ...prev,
                                          [index + 1]: value,
                                        }));
                                      }}
                                    />
                                    <div className='mrg-top-20'>
                                      <TableComponent
                                        key={index}
                                        className={'floor-details-table'}
                                        columns={[
                                          {
                                            title: 'Floor',
                                            key: 'floor',
                                            dataIndex: 'floor',
                                            render: (item: any) => {
                                              return (
                                                <>
                                                  {selectedTab ===
                                                  'officeFloors'
                                                    ? item?.floorTitle
                                                    : item?.basementTitle ||
                                                      '-'}
                                                </>
                                              );
                                            },
                                          },
                                          {
                                            title: 'Leasable Area',
                                            key: 'leasableArea',
                                            dataIndex: 'leasableArea',
                                            render: (item: any) => {
                                              return (
                                                <>{item?.leasableArea || '-'}</>
                                              );
                                            },
                                          },
                                        ]}
                                        data={
                                          selectedTab === 'officeFloors'
                                            ? values?.towers[index]
                                                ?.officeFloors
                                            : values?.towers[index]?.basements
                                        }
                                      />
                                    </div>
                                  </AccordionComponent>
                                ) : (
                                  <ButtonComponent
                                    variant='outlined'
                                    prefixIcon={<ImageConfig.AddOutlinedIcon />}
                                    fullWidth={true}
                                    onClick={handleOpenFloorDetailsDrawer.bind(
                                      null,
                                      index,
                                    )}
                                    disabled={
                                      !values?.towers[index]?.name ||
                                      !values.towers[index]?.stiltArea
                                    }
                                  >
                                    Add Floor Details
                                  </ButtonComponent>
                                )}
                              </AccordionComponent>
                            </div>
                          );
                        })}
                    </div>
                  )}
                />
              </div>
              <DrawerComponent
                isOpen={openFloorDrawer}
                onClose={() => setOpenFloorDrawer(false)}
                title={`Add Floor Details | ${
                  values?.towers[towerId]?.name || ''
                }`}
                showClose={true}
                closeOnBackDropClick={false}
                closeOnEsc={false}
                className='drawer-max-width'
              >
                <FloorDetailsDrawerComponent
                  values={values}
                  setInitialValues={setInitialValues}
                  setFieldValue={setFieldValue}
                  towerId={towerId}
                  closeDrawer={() => setOpenFloorDrawer(false)}
                />
              </DrawerComponent>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CampusDetailsComponent;
