import './AddVisitorScreen.scss';
import { useCallback, useEffect, useState } from 'react';
import ButtonComponent from '../../../shared/components/button/ButtonComponent';
import StepperComponent from '../../../shared/components/stepper/StepperComponent';
import * as Yup from 'yup';
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { CommonService } from '../../../shared/services';
import { IAPIResponseType } from '../../../shared/models/api.model';
import { ImageConfig, Misc } from '../../../constants';
import FormikInputComponent from '../../../shared/components/form-controls/formik-input/FormikInputComponent';
import FormikRadioButtonGroupComponent from '../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent';
import FormikDatePickerComponent from '../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent';
import FormikTimePickerComponent from '../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent';
import FormControlLabelComponent from '../../../shared/components/form-control-label/FormControlLabelComponent';
import HorizontalLineComponent from '../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent';
import IconButtonComponent from '../../../shared/components/icon-button/IconButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from '../../../store/reducers';
import FormikSelectDropdownComponent from '../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getPropertyListLite } from '../../../store/actions/property.action';

interface IVisitor {
  name: string;
  email: string;
  phone: string;
}

interface IAddVisitorFormValues {
  includeParkingSpace: boolean;
  visitors: IVisitor[];
  hereToVisitName: string;
  purposeOfVisit: string;
  typeOfVisit: string;
  organization: string;
  date: any;
  time: any;
  vehicleNo: string;
  propertyId: string;
  towerId: string;
  floorNo: string;
}

const AddVisitorFormValidationSchema = Yup.object({
  includeParkingSpace: Yup.boolean().required('Required'),
  visitors: Yup.array().of(
    Yup.object()
      .shape({
        name: Yup.string()
          .min(3, 'Guest Name should be at least 3 characters')
          .max(50, 'Guest Name should not exceed 50 characters')
          .required('Guest Name is required'),
        phone: Yup.string()
          .matches(/^[5-9][0-9]{0,9}$/, 'Invalid phone number')
          .required('Guest phone number is required'),
        email: Yup.string().email('Enter valid email'),
        // .required('Guest phone number is required'),
      })
      .required('Required'),
  ),
  purposeOfVisit: Yup.string()
    .min(3, 'Purpose of visit should be at least 3 characters')
    .max(50, 'Purpose of visit should not exceed 50 characters')
    .required('Purpose of visit is required'),
  hereToVisitName: Yup.string()
    .min(3, 'Host Name should be at least 3 characters')
    .max(50, 'Host Name should not exceed 50 characters')
    .required('Host Name is required'),
  typeOfVisit: Yup.string().required('Required'),
  organization: Yup.string()
    .max(50, 'Guest Organisation should not exceed 50 characters')
    .required('Guest Organisation is required'),
  // noOfPeople: Yup.number().nullable(),
  date: Yup.string().required('Visit date is required').nullable(),
  time: Yup.string().required('Required'),
  propertyId: Yup.string().required('Required').nullable(),
  towerId: Yup.string().nullable(),
  floorNo: Yup.string().nullable(),
});

const Steps = [
  {
    title: 'Visitor Details',
    id: 'visitor-details-1',
    fields: [
      'purposeOfVisit',
      'includeParkingSpace',
      'hereToVisit',
      'typeOfVisit',
      'organization',
      'date',
      'time',
      'vehicleNo',
      'propertyId',
    ],
  },
  {
    title: 'Visitor Details',
    id: 'visitor-details-2',
  },
];

interface AddVisitorComponentProps {}

const AddVisitorScreen = (props: AddVisitorComponentProps) => {
  const { propertyList } = useSelector(
    (state: IRootReducerState) => state.property,
  );
  const { currentUser } = useSelector(
    (state: IRootReducerState) => state.account,
  );
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState<IAddVisitorFormValues>({
    includeParkingSpace: null as any as boolean,
    visitors: [
      {
        name: '',
        email: '',
        phone: '',
      },
    ],
    purposeOfVisit: '',
    hereToVisitName: '',
    typeOfVisit: 'Planned',
    organization: '',
    date: '',
    time: '',
    vehicleNo: '',
    propertyId: '',
    towerId: '',
    floorNo: '',
  });

  // Use useEffect to update the initial values when currentUser changes
  useEffect(() => {
    if (currentUser?.propertyId) {
      setInitialValues((oldState: any) => {
        return {
          ...oldState,
          propertyId: currentUser.propertyId,
        };
      });
    }
  }, [currentUser]);
  // console.log(initialValues, 'came here')

  const navigator = useNavigate();
  // const [addVisitorFormInitialValues] = useState<IAddVisitorFormValues>(_.cloneDeep(initialValues));
  const [isAddVisitorInProgress, setIsAddVisitorInProgress] = useState(false);
  const [activeStepId, setActiveStepId] = useState<string>(Steps[0].id);

  useEffect(() => {
    dispatch(getPropertyListLite());
  }, [dispatch]);

  const handleNext = useCallback(() => {
    const activeStepIndex = Steps.findIndex((step) => step.id === activeStepId);
    if (activeStepIndex !== -1 && activeStepIndex < Steps.length - 1) {
      setActiveStepId(Steps[activeStepIndex + 1].id);
    } else {
      // exit
    }
  }, [activeStepId]);

  const handleBack = useCallback(() => {
    const activeStepIndex = Steps.findIndex((step) => step.id === activeStepId);
    if (activeStepIndex !== -1 && activeStepIndex > 0) {
      setActiveStepId(Steps[activeStepIndex - 1].id);
    } else {
      navigator(CommonService._routeConfig.VisitorListRoute());
    }
  }, [navigator, activeStepId]);

  const onSubmit = useCallback(
    (values: any, { setErrors }: FormikHelpers<any>) => {
      setIsAddVisitorInProgress(true);
      const payload = {
        ...values,
        date: moment(values?.date).format('YYYY-MM-DD'),
        time: moment(values.time, ['h:mm A']).format('HH:mm'),
        hereToVisitFor: 'property',
      };
      CommonService._vms
        .AddVisitorAPICall(payload)
        .then((response: IAPIResponseType<any>) => {
          CommonService._alert.showToast(
            response[Misc.API_RESPONSE_MESSAGE_KEY],
            'success',
          );
          navigator(CommonService._routeConfig.VisitorListRoute());
        })
        .catch((error: any) => {
          CommonService.handleErrors(setErrors, error);
        })
        .finally(() => {
          setIsAddVisitorInProgress(false);
        });
    },
    [navigator],
  );

  return (
    <div className='add-screen'>
      <div className={'t-form-screen add-visitor-component'}>
        <Formik
          validationSchema={AddVisitorFormValidationSchema}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ values, errors, validateForm, isValid, setFieldValue }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              validateForm();
            }, [validateForm, values]);
            return (
              <Form className='t-form' noValidate={true}>
                {/* <FormDebuggerComponent
                  values={values}
                  errors={errors}
                  showDebugger={false}
                /> */}
                <div className='add-visitor-component-header'>
                  {
                    <ButtonComponent
                      variant={'text'}
                      color={'secondary'}
                      onClick={handleBack}
                      prefixIcon={<ImageConfig.ArrowLeftIcon />}
                    >
                      {activeStepId === 'visitor-details-1' ? 'Exit' : 'Back'}
                    </ButtonComponent>
                  }
                  <div>
                    <StepperComponent
                      activeStepId={activeStepId}
                      steps={Steps}
                    />
                  </div>
                  {activeStepId !== Steps[Steps.length - 1].id && (
                    <ButtonComponent
                      disabled={
                        isAddVisitorInProgress ||
                        Object.keys(errors).some((key) =>
                          Steps.find(
                            (step) => step.id === activeStepId,
                          )?.fields?.includes(key),
                        )
                      }
                      onClick={handleNext}
                      type={'button'}
                      suffixIcon={<ImageConfig.ArrowRightCircleIcon />}
                    >
                      Next
                    </ButtonComponent>
                  )}
                  {activeStepId === Steps[Steps.length - 1].id && (
                    <ButtonComponent
                      disabled={
                        isAddVisitorInProgress ||
                        !isValid ||
                        !values.visitors.length
                      }
                      isLoading={isAddVisitorInProgress}
                      type={'submit'}
                    >
                      Submit
                    </ButtonComponent>
                  )}
                </div>
                <div className='add-visitor-component-content'>
                  {activeStepId === 'visitor-details-1' && (
                    <div>
                      <FormControlLabelComponent
                        label={'Visit Details'}
                        level={4}
                        isBold={true}
                      />
                      <FormControlLabelComponent
                        label={
                          'Please enter the details of the visit of the respective visitor(s) coming to this facility'
                        }
                        level={5}
                      />

                      <Field name={'purposeOfVisit'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Purpose of Visit'}
                            placeholder={'Example: Came for a business meet'}
                            type={'text'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>

                      <div className='ts-row'>
                        <div className='ts-col-6'>
                          <Field name={'hereToVisitName'}>
                            {(field: FieldProps) => (
                              <FormikInputComponent
                                label={'Host Name'}
                                placeholder={'Example: Mr. XYZ'}
                                type={'text'}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>

                        <div className='ts-col-6'>
                          <Field name={'organization'}>
                            {(field: FieldProps) => (
                              <FormikInputComponent
                                label={'Guest Organisation'}
                                placeholder={'Example: Google'}
                                type={'text'}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <div className='ts-row'>
                        <div className='ts-col-6'>
                          <Field name={'date'}>
                            {(field: FieldProps) => (
                              <FormikDatePickerComponent
                                label={'Visit Date'}
                                minDate={new Date()}
                                placeholder={'Select Date'}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>
                        <div className='ts-col-6'>
                          <Field name={'time'}>
                            {(field: FieldProps) => (
                              <FormikTimePickerComponent
                                label={'Visit Time'}
                                placeholder={'Select Time'}
                                required={true}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      <Field name={'vehicleNo'}>
                        {(field: FieldProps) => (
                          <FormikInputComponent
                            label={'Vehicle Numbers'}
                            placeholder={'Example: TS76YD3456, AP98JA7484'}
                            labelTooltip={
                              'Enter comma separated vehicle numbers'
                            }
                            type={'text'}
                            formikField={field}
                            fullWidth={true}
                            required={false}
                          />
                        )}
                      </Field>
                      <Field name={'includeParkingSpace'}>
                        {(field: FieldProps) => (
                          <FormikRadioButtonGroupComponent
                            label={'Include Parking Space?'}
                            required={true}
                            formikField={field}
                            options={CommonService._staticData.yesNoOptions}
                          />
                        )}
                      </Field>
                      <FormControlLabelComponent
                        label={'Property Details'}
                        level={4}
                        isBold={true}
                      />
                      <FormControlLabelComponent
                        label={
                          'Please enter the details of the property the visitor(s) is visiting to.'
                        }
                        level={5}
                      />
                      <Field name={'propertyId'}>
                        {(field: FieldProps) => (
                          <FormikSelectDropdownComponent
                            disabled={currentUser?.roleDetails?.code === 'fm'}
                            options={propertyList}
                            displayWith={(option: any) => option.name}
                            valueExtractor={(option: any) => option._id}
                            onUpdate={(option: any) => {
                              setFieldValue('towerId', '');
                              setFieldValue('floorNo', '');
                            }}
                            label={'Property'}
                            placeholder={'Select Property'}
                            required={true}
                            formikField={field}
                            fullWidth={true}
                          />
                        )}
                      </Field>
                      <div className='ts-row'>
                        <div className='ts-col-6'>
                          <Field name={'towerId'}>
                            {(field: FieldProps) => (
                              <FormikSelectDropdownComponent
                                options={
                                  propertyList.find(
                                    (property) =>
                                      property._id === values.propertyId,
                                  )?.towers
                                }
                                displayWith={(option: any) => option.name}
                                valueExtractor={(option: any) => option._id}
                                disabled={!values.propertyId}
                                onUpdate={(option: any) => {
                                  setFieldValue('floorNo', '');
                                }}
                                label={'Tower'}
                                placeholder={'Select Tower'}
                                required={false}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>
                        <div className='ts-col-6'>
                          <Field name={'floorNo'}>
                            {(field: FieldProps) => (
                              <FormikSelectDropdownComponent
                                options={
                                  propertyList
                                    ?.find(
                                      (property) =>
                                        property?._id === values.propertyId,
                                    )
                                    ?.towers.find(
                                      (tower: any) =>
                                        tower?._id === values.towerId,
                                    )?.officeFloors
                                }
                                label={'Floor'}
                                displayWith={(option: any) =>
                                  option.floorNumber
                                }
                                valueExtractor={(option: any) =>
                                  option.floorNumber
                                }
                                placeholder={'Select Floor'}
                                disabled={!values.towerId}
                                required={false}
                                formikField={field}
                                fullWidth={true}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeStepId === 'visitor-details-2' && (
                    <div>
                      <FormControlLabelComponent
                        label={'Visitor Details'}
                        level={3}
                        isBold={true}
                      />
                      <FormControlLabelComponent
                        label={'Please enter the details of the visitors.'}
                        level={5}
                      />
                      <FieldArray name={'visitors'}>
                        {(arrayHelpers: FieldArrayRenderProps) => (
                          <div>
                            {values.visitors.map((visitor, index) => (
                              <div key={index} className='pdd-bottom-20'>
                                <div className='ts-row ts-align-items-center'>
                                  <div className='ts-col'>
                                    <Field name={`visitors.${index}.name`}>
                                      {(field: FieldProps) => (
                                        <FormikInputComponent
                                          label={'Guest Name'}
                                          placeholder={'Enter Guest Name'}
                                          type={'text'}
                                          required={true}
                                          formikField={field}
                                          fullWidth={true}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className='ts-col'>
                                    <Field name={`visitors.${index}.phone`}>
                                      {(field: FieldProps) => (
                                        <FormikInputComponent
                                          label={'Guest Phone Number'}
                                          placeholder={
                                            'Enter Guest Phone Number'
                                          }
                                          type={'text'}
                                          validationPattern={/^[0-9\b]+$/}
                                          maxlength={10}
                                          required={true}
                                          formikField={field}
                                          fullWidth={true}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  <div className='ts-col'>
                                    <Field name={`visitors.${index}.email`}>
                                      {(field: FieldProps) => (
                                        <FormikInputComponent
                                          label={'Guest Email ID'}
                                          placeholder={'Enter Guest Email ID'}
                                          type={'text'}
                                          required={false}
                                          formikField={field}
                                          fullWidth={true}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                  {values.visitors.length > 1 && (
                                    <div className='ts-col-0'>
                                      <IconButtonComponent
                                        color={'error'}
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <ImageConfig.DeleteIcon />
                                      </IconButtonComponent>
                                    </div>
                                  )}
                                </div>
                                <HorizontalLineComponent />
                              </div>
                            ))}
                            <div className='ts-row'>
                              <div className='ts-col-12'>
                                <ButtonComponent
                                  color={'primary'}
                                  variant={'outlined'}
                                  // disabled={Number(values.noOfPeople) === values.visitors.length}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      name: '',
                                      phone: '',
                                      email: '',
                                    })
                                  }
                                  prefixIcon={<ImageConfig.AddOutlinedIcon />}
                                >
                                  Add Visitor
                                </ButtonComponent>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default AddVisitorScreen;
